import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import {
	getLocalStorageVariables,
	isEmptyVariable,
	isEmptyArray,
	SplitJoinString,
	getStringWithSpaceAfterComma
} from "../../../common/Global/commonFunctions";
import { Modal, Tabs, Tab } from "react-bootstrap";
import moment from "moment";
const activeStatus = "Active";
const inActiveStatus = "Inactive";
const requestChangeCheckText = "Request Change";

class FollowUpDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			assignmentReviewMatrix: this.props.assignmentMatrix,
		};
	}

	componentDidMount() {
    }

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showDetailsDialog) {
			this.setState({
				componentDidMountFlag: false,
				assignmentReviewMatrix: this.props.assignmentMatrix,
				showDetailsDialog: false,
			}); 
		}
	}

	
	render() {
		return (
			<Modal className="custom-dialog" show={this.props.showDetailsDialog} onHide={this.props.handleDetailsDialogClose}>
				<Modal.Header>
					<h5>
						{this.props.selectedItem.referenceNo}
					</h5>
					<button onClick={this.props.handleDetailsDialogClose} type="button" data-dismiss="modal">
						<span class="material-icons">close</span>
					</button>
				</Modal.Header>
				<hr />
                <div className="modal-body">
                    <Tabs className="report-details-tab" defaultActiveKey="details" id="ongoingDetails">
                        <Tab eventKey="details" title="Details">
                            <div class="modal-body">
                                <div className="details-table-layout">
                                    <div className="row custom-row">
                                        <div className="col-md-12 custom-col">
                                            <table>
                                                <tbody>
                                                    {
                                                        this.props.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                        <tr>
                                                            <td width={"24%"}>Client</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.props.selectedItem.companyName}</td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td width={"24%"}>Units</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{getStringWithSpaceAfterComma(this.props.selectedItem.unitNameCityList)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Assignment Type</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.props.selectedItem.assignmentTypeName} ({this.props.selectedItem.assignmentTypeShortName})</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Scope</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>
                                                            {
                                                                isEmptyVariable(this.props.selectedItem.plannedAssignmentArea)?
                                                                this.props.selectedItem.assignmentArea:
                                                                this.props.selectedItem.plannedAssignmentArea
                                                            }
                                                        </td>
                                                    </tr>
                                                    {this.props.selectedItem.plannedAssignmentArea === "Process" && (
                                                        <tr>
                                                            <td width={"24%"}>Process</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{SplitJoinString(this.props.selectedItem.processList, ",", ":-:", ", ")}</td>
                                                        </tr>
                                                    )}
                                                    {this.props.selectedItem.plannedAssignmentArea === "Function" && (
                                                        <tr>
                                                            <td width={"24%"}>Function</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{SplitJoinString(this.props.selectedItem.functionList, ",", ":-:", ", ")}</td>
                                                        </tr>
                                                    )}
                                                    {
                                                        !isEmptyVariable(this.props.selectedItem.planDate) &&
                                                        <tr>
                                                            <td width={"24%"}>Plan Date</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{moment(this.props.selectedItem.planDate).format("DD/MM/YYYY")}</td>
                                                        </tr>
                                                    }
                                                    {
                                                        isEmptyVariable(this.props.selectedItem.planDate) &&
                                                        <tr>
                                                            <td width={"24%"}>Create Date</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{moment(this.props.selectedItem.createDate).format("DD/MM/YYYY")}</td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td width={"24%"}>Report Format</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.props.selectedItem.reportFormat}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Status</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.props.selectedItem.status}</td>
                                                    </tr>
                                                    {!isEmptyVariable(this.props.selectedItem.unplannedReason) && (
                                                        <tr>
                                                            <td width={"24%"}>Unplanned Reason</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.props.selectedItem.unplannedReason}</td>
                                                        </tr>
                                                    )}
                                                    {!isEmptyVariable(this.props.selectedItem.unplannedReason) && 
                                                    !isEmptyVariable(this.props.selectedItem.unplannedRemark) && (
                                                        <tr>
                                                            <td width={"24%"}>Unplanned Remarks</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.props.selectedItem.unplannedRemark}</td>
                                                        </tr>
                                                    )}
                                                    {this.props.selectedItem.status !== activeStatus && this.props.selectedItem.status !== inActiveStatus && (
                                                        <tr>
                                                            <td width={"24%"}>Review Status</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.props.selectedItem.currentReviewStatus}</td>
                                                        </tr>
                                                    )}
                                                    {this.props.selectedItem.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW &&
                                                        this.props.selectedItem.currentReviewStatus.includes(requestChangeCheckText) && (
                                                            <tr>
                                                                <td width={"24%"}>Review Remarks</td>
                                                                <td width={"4%"}>:</td>
                                                                <td width={"72%"}>{this.props.selectedItem.lastReviewRemark}</td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            <p className="schedule-assignment-heading m-t-10">Timeline</p>
                                                <div className="row custom-row">
                                                    <div className="col-md-4 custom-col">
                                                        <p className="schedule-assignment-details-sub-heading">Start Date</p>
                                                        <p className="schedule-assignment-details-text">{moment(this.props.selectedItem.startDate).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                    <div className="col-md-4 custom-col">
                                                        <p className="schedule-assignment-details-sub-heading">Report Date</p>
                                                        <p className="schedule-assignment-details-text">{moment(this.props.selectedItem.endDate).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                    <div className="col-md-4 custom-col">
                                                        <p className="schedule-assignment-details-sub-heading">End Date</p>
                                                        <p className="schedule-assignment-details-text">{moment(this.props.selectedItem.reportDate).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                </div>
                                                
                                                <p className="schedule-assignment-heading">Sample Period</p>
                                                <div className="row custom-row">
                                                    <div className="col-md-6 custom-col">
                                                        <p className="schedule-assignment-details-sub-heading">From </p>
                                                        <p className="schedule-assignment-details-text">{moment(this.props.selectedItem.periodFromDate).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                    <div className="col-md-6 custom-col">
                                                        <p className="schedule-assignment-details-sub-heading">To</p>
                                                        <p className="schedule-assignment-details-text">{moment(this.props.selectedItem.periodToDate).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="resources" title="Execute Resources">
                            <div class="modal-body">
                                <div className="details-table-layout">
                                    <div className="row custom-row">
                                        <div className="col-md-12 custom-col">
                                            <table>
                                                <tbody>
                                                    {!isEmptyArray(this.props.assignmentMatrix) && (
                                                        <tr>
                                                            <td width={"24%"}>Team Members</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>
                                                                {this.props.assignmentMatrix.map((item, index) => {
                                                                    if(item.position === "Team Member") {
                                                                        return <span>{(index ? ", " : "") + item.label + " (" + item.designation + ")"}</span>;
                                                                    }
                                                                })}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {/* Add Team Leader row if level 1 */}
                                                    {!isEmptyArray(this.props.assignmentMatrix) &&
                                                        this.props.assignmentMatrix.map(item => {
                                                            if (item.position === "Review Level 1") {
                                                                return (
                                                                    <tr>
                                                                        <td width={"24%"}>Team Leader</td>
                                                                        <td width={"4%"}>:</td>
                                                                        <td width={"72%"}>{item.label+" ("+item.designation+")"}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                        })
                                                    }
                                                    {!isEmptyArray(this.props.assignmentMatrix) &&
                                                        this.props.assignmentMatrix.map(item => {
                                                            if (item.position !== "Review Level 1" && item.position !== "Team Member") {
                                                                return(
                                                                    <tr>
                                                                        <td width={"24%"}>{item.position}</td>
                                                                        <td width={"4%"}>:</td>
                                                                        <td width={"72%"}>{item.label+" ("+item.designation+")"}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
			</Modal>
		);
	}
}

export default FollowUpDetails;
