import React, { Component } from 'react'
import * as Constants from "../../../common/Global/constants";
import { getLocalStorageVariables, getThemeSettingsVariables, isEmptyVariable, isEmptyArray, } from "../../../common/Global/commonFunctions"; 
import Pagination from "../../../common/Global/pagination";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import { SearchSpan } from "../../../common/Global/globalStyles.style";


let userDetails = getLocalStorageVariables();
let themeSettings = getThemeSettingsVariables();

export default class SummaryContent extends Component {
  constructor (props){
    super(props);
    this.state ={
      dataList:[],
      componentDidMountFlag:false,
      totalCount: 0,
      resultSize: Constants.RESULT_SIZE,
      currentPageNo: 1,
      resultSizePlaceholder: Constants.RESULT_SIZE,
      resultSizeArr: Constants.RESULT_SIZE_DD_ARR,
      searchkey:""
    }
  }
  componentDidMount() {
    this.getInitData();
  }

  getInitData = () =>{
    userDetails = getLocalStorageVariables();
    themeSettings = getThemeSettingsVariables();
    this.getSummaryData(this.state.currentPageNo)
  }

  onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
      this.onSearchIconClick()  
		}
	};


  getSummaryData = (pageNo)=>{
    
    this.props.showLoader(true)
    fetch(Constants.GetAssignmentSummary,{
      method: "POST",
      mode: "cors",
      body: new URLSearchParams({
        email: userDetails.email,
        accessToken: userDetails.accessToken,
        pageNo: pageNo,
        resultSize:this.state.resultSizePlaceholder,
        searchKey:this.state.searchkey,

      }),
    }).then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
        localStorage.clear();
        window.location = "/";
      } else if (data.responseCode === Constants.CODE_SUCCESS) {
        this.setState({
          dataList:data.data,
          componentDidMountFlag:true,
          totalCount:data.totalCount,
          currentPageNo:pageNo
        })

      }
    })

    this.props.showLoader(false)
  }
  
  onChangePage = (page) =>{
    if (page !== this.state.currentPageNo) {
    this.getSummaryData(page)
    }
  }

  resultSizeDropdownClick = (item, selectedIndex) =>{
    let tempArr = this.state.resultSizeArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label ;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				resultSizeArr: tempArr,
				resultSize: item.label,
				resultSizePlaceholder: placeholder,
			},
			() => {
				this.getSummaryData(this.state.currentPageNo);
			}
    )

  }

  handleChangeSearch = (e) => {
		const { name, value } = e.target;

		this.setState({
			[name]: value,
		});
	};

  onSearchIconClick = () => {
			this.getSummaryData(1);
	};


  
  render() {
    return (this.state.componentDidMountFlag &&
          <div className="card-container">
              <div className="card-body">
                <div className="search-addnew-bg">
                    <div className="search-addnew">
                      <div className="search-filter-layout">
                        <FilterDropDown
                          placeholder={this.state.resultSizePlaceholder}
                          dropdownArr={this.state.resultSizeArr}
                          onDropDownItemClick={this.resultSizeDropdownClick}
                          themeSettings={themeSettings}
                          mleft={0}
                        />
                        <div className="search-col m-l-10">
                          <input
                            name="searchkey"
                            type="text"
                            onChange={this.handleChangeSearch}
                            placeholder="Search"
                            onKeyPress={this.onEnterBtnPress}
                            value={this.state.searchkey}
                          />
                          <SearchSpan themeSettings={themeSettings} onClick={this.onSearchIconClick} className="material-icons">
                            {" "}
                            search{" "}
                          </SearchSpan>
                        </div>
                      </div>
                    </div>
                  </div>
                { this.state.dataList.length>0 && 
                <div className="common-table" style={{ width: "100%" }} >
                  <table className="table">
                    <thead>
                      <tr style={{ cursor: 'default' }}>
                        <th className="text-center"> No.</th>
                        <th className="text-center"> Assignments</th>
                        <th className="text-center">End Date</th>
                        <th className="text-center"> Task Review Process</th>
                        <th className="text-center">Execute<br/>(Name & PendingTask )</th>
                        <th className="text-center">Reports <br/>(Name & ReviewLevel)</th>
                        <th className="text-center">Draft <br/>(Name & ReviewLevel)</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(this.state.dataList).map((item,index)=>{
                        return(
                          <tr className={'text-center'} style={{background:index%2!=0?"#f5f5f5":"#fff",cursor:"default"}}>
                            <td>{((this.state.currentPageNo-1)*this.state.resultSize)+1+index}</td>
                            <td className={'text-left'}>{item.assignmentName}</td>
                            <td>{item.endDate}</td>
                            <td>{item.taskReviewProcess}</td>
                            <td>
                              <table className='w-100'>
                                
                                { Object.values(item.executeData).length>0?
                                  Object.values(item.executeData).map((arr)=>{
                                    return <tr style={{background:index%2!=0?"#f5f5f5":"#fff",cursor:"default"}}>
                                      <td className={'text-left'}>{arr}</td></tr>
                                  }):"-"
                                }
                               
                              </table>
                            </td>
                            <td className={'text-left'}>{Object.values(item.draftData).length==0?item.reviewData[0]:"Completed"}</td>
                            <td className={'text-left'}>{Object.values(item.reviewData).length==0?item.draftData[0]:"-"}</td>
                          </tr>

                      )
                      })}
                    </tbody>
                  </table>
                </div>
                }
                {this.state.componentDidMountFlag && isEmptyArray(this.state.dataList) && (
                    <div class="no-items-layout">
                      <div class="no-items-card">
                        <h6>
                          {isEmptyVariable(this.state.searchkey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
                        </h6>
                      </div>
                    </div>
									)}
                  <div className="pagination-layout">
                    <div className="row custom-row">
                      <div className="col-md-9 custom-col">
                        {this.state.totalCount > this.state.dataList.length && (
                          <Pagination
                            totalLength={this.state.totalCount}
                            items={this.state.dataList}
                            onChangePage={this.onChangePage}
                            pageSize={this.state.resultSize}
                            currentPageNo={this.state.currentPageNo}
                            initialPage={this.state.currentPageNo}
                            themeSettings={themeSettings}
                          />
                        )}
                      </div>
                      <div className="col-md-3 custom-col">
                        <p>{"Total Records: " + this.state.totalCount}</p>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
    )
  }
}
