import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { GetOrderedMasterTabMenuExecute, getLocalStorageVariables, getThemeSettingsVariables, isJsonString } from "../../../common/Global/commonFunctions";
import Topbar from "../../../common/Topbar";
import MasterMenuTabs from "../Common/topmenu";
import Sidebar from "../../../common/Sidebar";
import * as Constants from '../../../common/Global/constants';
import OverallAssignmentComponent from '../../../common/OverallAssignment';

const themeSettings  = getThemeSettingsVariables();
const userDetails = getLocalStorageVariables();

export default class OverallAssignment extends React.PureComponent {
    constructor(props) {
		super(props);
		this.state = {
            assignmentId: this.props.match.params.id,
            showLoader: false,
            componentDidMountFlag: false,
            menus: [],
            assignmentDetails:{}
        }
    }

    componentDidMount() {
        console.log("this.props.match.params.id,::",this.props.match.params.id)
		this.getInitData();
	}

    getInitData = () => {
        this.setState({
            showLoader: true,
        });
        Promise.all([
            fetch(Constants.GetUserMenu, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                }),
            }),
            fetch(Constants.GetAssignmentsDetails, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                }),
            }),
        ])
        .then(([menuRes, assignmentRes]) => {
            return Promise.all([menuRes.json(), assignmentRes.json()]);
        })
        .then(([menuRes, assignmentRes]) => {
            let assignmentDetails = {}, menusTemp = {};
            if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                menusTemp = menuRes.data.menus;
            }

            if (
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                assignmentDetails = assignmentRes.data.assignmentDetails;
            }

            let menusTemp2 = GetOrderedMasterTabMenuExecute(menusTemp,assignmentDetails.observationTemplateId);
            
            this.setState({
                showLoader: false,
                componentDidMountFlag: true,
                menus: menusTemp2,
                assignmentDetails:assignmentDetails
            });
        });
    }

    render () {
        return (
            <Router>
                <main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />
                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                    assignmentId={this.state.assignmentId}
                                    assignmentLevelTemplateId={this.state.assignmentDetails?.assignmentLevelTemplateId}
                                />
                                {
                                    this.state.showLoader &&
                                    <div className="loading-layout"
                                        style={{background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`}}>
                                        <div className="loading-text-layout">
                                            <p style={{color:`rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`}}>Loading....</p>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.componentDidMountFlag &&
                                    <OverallAssignmentComponent
                                        assignmentId={this.state.assignmentId}
                                        isEditable={true}
                                        getDatalistApi={Constants.GetAssignmentLevelMasterData}
                                        saveDatalistApi={Constants.SaveAssignmentLevelTemplateValues}
                                    />
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </Router>
        )
    }
}
