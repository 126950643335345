import React, { Component } from "react";
import * as Constants from "../../../../common/Global/constants";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray } from "../../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import TreeNodeDropdown from "../../../../common/DropdownMenus/TreeNodeDropdown";
// import AddIndustryDialog from './addIndustrydialog'
import AlertDialog from "../../../../common/AlertDialog";
import AlertWithRadioDialog from "../../../../common/AlertDialog/alertwithradio";
import { ExpandCollapseWrapper } from "../../../../common/Global/globalStyles.style";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-navigations/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";

const userDetails = getLocalStorageVariables();
const activeStatus = "Active";
const createdStatus = "Created";
const updatedStatus = "Updated";

const AddChildDropdownObj = {
	icon: "add_circle",
	label: "Add Child",
};
const AddSiblingDropdownObj = {
	icon: "add_box",
	label: "Add Sibling",
};
const editDropdownObj = {
	icon: "edit",
	label: "Edit",
};
const delDropdownObj = {
	icon: "delete",
	label: "Delete",
};

class IndustryTreeDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,

			industryMap: {},
			actionArr: [],
			syncFusionData: [],

			showCreateDialog: false,
			isEdit: false,
			createNodeType: "",
			selectedId: "",
			industryName: "",
			relativeIndustryId: "",
			clearAndResetIndustryTree: true,
			idToBeExpandedAfterAddingChild: "",

			operationType: "",
			deleteReactivateId: "",
			showAlertWithRadioDialog: false,
			alertWithRadioDialogMessage: "",
			showRadio: true,

			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
		};
		this.fields = {};
		this.industryObj = {};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showIndustryTreeDialog) {
			this.fields = {
				dataSource: [],
				id: "industryId",
				text: "industryName",
				parentID: "parentId",
				child: "newChildren",
				expanded: "isExpanded",
			};

			this.setState(
				{
					showLoader: false,

					industryMap: {},
					actionArr: [],
					syncFusionData: [],

					showCreateDialog: false,
					isEdit: false,
					createNodeType: "",
					selectedId: "",
					industryName: "",
					relativeIndustryId: "",
					clearAndResetIndustryTree: true,
					idToBeExpandedAfterAddingChild: "",

					operationType: "",
					deleteReactivateId: "",
					showAlertWithRadioDialog: false,
					alertWithRadioDialogMessage: "",

					showAlertDialogInfo: false,
					alertDialogMessageInfo: "",
				},
				() => {
					this.getIndustryTree();
				}
			);
		}
	}

	handleAddEditDialogClose = (reloadFlag) => {
		let id = "";
		if (this.state.createNodeType === "child") id = this.state.relativeIndustryId;
		this.setState(
			{
				showCreateDialog: false,
				clearAndResetIndustryTree: false,
				isEdit: false,
				createNodeType: "",
				selectedId: "",
				industryName: "",
				relativeIndustryId: "",
				idToBeExpandedAfterAddingChild: id,
			},
			() => {
				if (reloadFlag) this.getIndustryTree();
			}
		);
	};

	onDropDownItemClick = (item, industryObj) => {
		if (item.label === "Add Child") {
			this.setState({
				showCreateDialog: true,
				createNodeType: "child",
				relativeIndustryId: industryObj.industryId,

				isEdit: false,
			});
		} else if (item.label === "Add Sibling") {
			this.setState({
				showCreateDialog: true,
				createNodeType: "sibling",
				relativeIndustryId: industryObj.industryId,

				isEdit: false,
			});
		} else if (item.label === "Delete") {
			let showRadio = true;
			if (isEmptyArray(this.state.industryMap[industryObj.industryId].children)) {
				showRadio = false;
			}

			this.setState({
				operationType: item.label,
				deleteReactivateId: industryObj.industryId,
				showAlertWithRadioDialog: true,
				showRadio: showRadio,
				alertWithRadioDialogMessage: "Are you sure you want to delete " + industryObj.industryName + "?",
			});
		} else if (item.label === "Edit") {
			this.setState({
				showCreateDialog: true,
				createNodeType: "sibling",
				relativeIndustryId: industryObj.industryId,

				isEdit: true,
				selectedId: industryObj.industryId,
				industryName: industryObj.industryName,
			});
		}
	};

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleAlertWithDialogDialogClose = () => {
		this.setState({
			showAlertWithRadioDialog: false,
			alertWithRadioDialogMessage: "",
		});
	};

	handleAlertDialogCloseInfo = () => {
		this.setState({
			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
		});
	};

	getRootLevelIndustries = (industryMapTemp) => {
		let rootIndustryArr = [];
		Object.values(industryMapTemp).map((item) => {
			//this is backend level, it starts from 0, but front end level starts from 1
			if (item.level === 0) {
				rootIndustryArr.push(item);
			}
		});
		return rootIndustryArr;
	};

	//No need of return value since the array itself is passed and updated.
	//Javascript maintains single array with pointers. it will not deep copy the array
	addParentIdandHasChildrenFlag = (tempArr, industryId, level) => {
		tempArr[industryId].level = level;

		if (!isEmptyArray(tempArr[industryId].children)) {
			tempArr[industryId].children.map((childNode) => {
				this.addParentIdandHasChildrenFlag(tempArr, childNode.industryId, level + 1);
			});
		} else {
			tempArr[industryId].hasChildren = false;
		}
	};

	IndustryTree = (industryObj) => {
		return (
			<div className="dialog-tree-structure-sync">
				{!isEmptyVariable(industryObj) && (
					<div className="tree-node-sync">
						<div className="tree-text-layout">
							<p className={industryObj.fontbold ? "font-bold" : ""}>{industryObj.industryName}</p>
							{(industryObj.status === createdStatus || industryObj.status === updatedStatus) && <span className="new-tree-tag">New</span>}
						</div>
					</div>
				)}
			</div>
		);
	};

	createSyncHRFusionData = (parentId, industryId, industryMap, isExpanded) => {
		//Now create an object
		let industryObj = industryMap[industryId];
		industryObj.newChildren = [];
		industryObj.isExpanded = isExpanded;
		if (parentId !== industryId) {
			industryObj.parentId = parentId;
		}
		//now add children recursively
		industryObj.children.map((item) => {
			industryObj.newChildren.push(this.createSyncHRFusionData(industryId, item.industryId, industryMap, false));
		});
		return industryObj;
	};

	updateSyncHRFusionData = (parentId, industryId, industryMap) => {
		//Now create an object
		let industryObj = industryMap[industryId];
		industryObj.newChildren = [];

		//Check the old industry map
		if (!isEmptyVariable(this.state.industryMap[industryId])) {
			industryObj.isExpanded = this.state.industryMap[industryId].isExpanded;
			industryMap[industryId].isExpanded = this.state.industryMap[industryId].isExpanded;
		}

		if (parentId !== industryId) {
			industryObj.parentId = parentId;
		}
		//now add children recursively
		industryObj.children.map((item) => {
			industryObj.newChildren.push(this.updateSyncHRFusionData(industryId, item.industryId, industryMap));
		});
		return industryObj;
	};

	/************************API CALLS **************************/

	getIndustryTree = () => {
		this.setState({
			showLoader: true,
		});

		fetch(Constants.GetIndustriesSubtree, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				rootIndustryId: this.props.rootIndustryId,
				departmentId:this.props.rootNode.departmentId,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					let temp = data.result.industryMap;
					let rootIndustryArr = this.getRootLevelIndustries(temp);

					rootIndustryArr.map((rootNode) => {
						this.addParentIdandHasChildrenFlag(temp, rootNode.industryId, 1);
					});

					let syncFusionData = [];
					//clearAndResetIndustryTree - if it is false, then copy the isShowingChildren param
					//from old state array, this is required because whenever the user adds a new node at the nth level
					//all the nodes are collapsed and shows only first level nodes since the API is called again
					if (!isEmptyArray(Object.keys(this.state.industryMap)) && !this.state.clearAndResetIndustryTree) {
						let industryObj = this.updateSyncHRFusionData(this.props.rootIndustryId, this.props.rootIndustryId, temp);
						industryObj.fontbold = true;
						syncFusionData.push(industryObj);
					} else {
						//create datastructure and Expand root level node's children by default
						let industryObj = this.createSyncHRFusionData(this.props.rootIndustryId, this.props.rootIndustryId, temp, true);
						temp[this.props.rootIndustryId].isExpanded = true;
						industryObj.fontbold = true;
						syncFusionData.push(industryObj);
					}

					//Action item arrays based on allowEdit flag
					let actionArrTemp = [];
					let actionArrRootTemp = [];
					if (this.props.rootNode.allowEdit === "Y") {
						actionArrTemp = [AddChildDropdownObj, AddSiblingDropdownObj, editDropdownObj, delDropdownObj];
						actionArrRootTemp = [AddChildDropdownObj, editDropdownObj];
					}

					console.log(JSON.stringify(syncFusionData));

					//set the tree fields
					this.fields = {
						dataSource: syncFusionData,
						id: "industryId",
						text: "industryName",
						parentID: "parentId",
						child: "newChildren",
						expanded: "isExpanded",
					};

					this.setState(
						{
							industryMap: temp,
							actionArr: actionArrTemp,
							actionArrRoot: actionArrRootTemp,
							idToBeExpandedAfterAddingChild: "",
							syncFusionData: syncFusionData,
							showLoader: false,
							clearAndResetIndustryTree: false,
						},
						() => {
							this.reference.refresh();
						}
					);
				} else {
					this.setState({
						industryMap: {},
						showLoader: false,
						syncFusionData: [],
						clearAndResetIndustryTree: false,
					});
				}
			});
	};

	moveTask = (draggedNodeId, draggedNodePid, droppedNodePid, droppedPos) => {
		fetch(Constants.MoveIndustry, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				rootIndustryId: this.props.rootIndustryId,
				industryId: draggedNodeId,
				fromIndustryId: draggedNodePid,
				toIndustryId: droppedNodePid,
				toChildOrder: droppedPos,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else {
					this.getIndustryTree();
				}
			});
	};

	expandAll = () => {
		this.reference.expandAll();
	};

	collapseAll = () => {
		this.reference.collapseAll();
	};

	dragStop = (args) => {
		// console.log("Drag Node: "+JSON.stringify(args.draggedNodeData))
		// console.log("Drop Node: "+JSON.stringify(args.droppedNodeData))
		// console.log("Drop Level: "+args.dropLevel)

		//if the node is dropped above the parent node then cancel the drag
		if (args.dropLevel === 1) {
			args.cancel = true;
		}
	};
	nodeExpanded = (args) => {
		this.state.industryMap[args.nodeData.id].isExpanded = true;
	};
	nodeCollapsed = (args) => {
		this.state.industryMap[args.nodeData.id].isExpanded = false;
	};
	nodeDrop = (args) => {
		console.log("Drag Node: " + JSON.stringify(args.draggedNodeData));
		console.log("Drop Node: " + JSON.stringify(args.droppedNodeData));
		console.log("Drop Index: " + args.dropIndex);
		console.log("Drop Level: " + args.dropLevel);

		let draggedNodeId = "";
		let draggedNodePid = "";

		let droppedNodeid = "";
		let droppedNodePid = "";
		let droppedPos = "";
		let dropLevel = "";

		if (!isEmptyVariable(args.draggedNodeData) && !isEmptyVariable(args.droppedNodeData)) {
			draggedNodeId = args.draggedNodeData.id;
			draggedNodePid = args.draggedNodeData.parentID;

			droppedNodeid = args.droppedNodeData.id;
			droppedNodePid = args.droppedNodeData.parentID;
			droppedPos = args.dropIndex + 1;
			dropLevel = args.dropLevel;

			// now check what is the level of drop id
			let dropRelativeNodeLevel = this.state.industryMap[droppedNodeid].level;
			console.log("Drop R Level: " + dropRelativeNodeLevel);
			//dropped level is greater than relative node, then it is a child of that relative node
			//hence parent id is relative node id
			if (dropLevel > dropRelativeNodeLevel) {
				droppedNodePid = droppedNodeid;
			}

			this.moveTask(draggedNodeId, draggedNodePid, droppedNodePid, droppedPos);
		}
	};

	deleteActivateIndustryNode = (deleteChildren) => {
		let url = "";

		if (this.state.operationType === "Delete") {
			url = Constants.DeleteIndustry;
		}

		this.setState({
			showLoader: true,
		});

		fetch(url, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				industryId: this.state.deleteReactivateId,
				deleteChildren: deleteChildren,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState(
						{
							deleteReactivateId: "",
							operationType: "",
							alertWithRadioDialogMessage: "",
							showAlertWithRadioDialog: false,
							clearAndResetIndustryTree: false,
						},
						() => {
							this.getIndustryTree();
						}
					);
				} else {
					this.setState({
						showLoader: false,
						deleteReactivateId: "",
						operationType: "",
						alertWithRadioDialogMessage: "",
						showAlertWithRadioDialog: false,
						clearAndResetIndustryTree: false,

						showAlertDialogInfo: true,
						alertDialogMessageInfo: data.responseMessage,
					});
				}
			});
	};

	render() {
		return (
			<div>
				<Modal className="show-industry-tree-dialog custom-dialog" show={this.props.showIndustryTreeDialog} onHide={this.props.handleIndustryTreeDialogClose}>
					<Modal.Header>
						<h5>Industry Tree</h5>
						<button onClick={this.props.handleIndustryTreeDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						{this.state.showLoader && (
							<div className="modal-loading-text">
								<p
									style={{
										background: `rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
										color: `rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`,
									}}
								>
									Loading....
								</p>
							</div>
						)}
						<ExpandCollapseWrapper themeSettings={this.props.themeSettings}>
							<div className="expand-collapse-layout" onClick={this.expandAll} style={{ marginRight: 10 }} type="button">
								<span class="material-icons">unfold_more</span>
								<p>Expand All</p>
							</div>
							<div className="expand-collapse-layout" onClick={this.collapseAll} style={{ marginLeft: 10 }} type="button">
								<span class="material-icons">unfold_less</span>
								<p>Collapse All</p>
							</div>
						</ExpandCollapseWrapper>
						<TreeViewComponent
							fields={this.fields}
							allowDragAndDrop={false}
							nodeTemplate={this.IndustryTree}
							ref={(treeNode) => {
								this.reference = treeNode;
							}}
							nodeDragStop={this.dragStop}
							nodeDropped={this.nodeDrop}
							nodeExpanded={this.nodeExpanded}
							nodeCollapsed={this.nodeCollapsed}
						/>
					</div>
				</Modal>

			</div>
		);
	}
}

export default IndustryTreeDialog;
