import React, { Component } from 'react'
import * as Constants from "../../../common/Global/constants";
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import {AddNewButtonLayout,FilterCheckBox} from '../../../common/Global/globalStyles.style';
import RegularDD from '../../../common/DropdownMenus/RegularDropdown';
import AlertDialog from '../../../common/AlertDialog';
import { getLocalStorageVariables, getThemeSettingsVariables,
	      isEmptyVariable, isEmptyArray, } from "../../../common/Global/commonFunctions";  

import SelectUnitGroupingDialog from './selectUnitGrouping';
import { Modal } from 'react-bootstrap';
import { dashboardDisplayArr, figureAndTable } from '..';
import { toast } from 'react-toastify';
let themeSettings = getThemeSettingsVariables();
let userDetails = getLocalStorageVariables();

//Display Filter Name
const unitGroupingFilter ="Unit Grouping";
const auditCycleFilter ="Audit Cycle";
const employeeFilter ="Employee";
const processFilter ="Process";
const clauseFilter ="Clause";
const functionFilter ="Function";
const unitTypeFilter ="Unit Type";
const complianceFilter ="Compliance Status";
const nextAuditCycleFilter ="Next Audit Cycle";


// Fixed Filter Names
const AuditCycleBase = "AuditCycleBase";
const ProcessAndClauseBase= "ProcessAndClauseBase"; 
const ProcessAndFunctionBase = "ProcessAndFunctionBase";
const BUBased = "BUBased";  
const BUAndNCBased = "BUAndNCBased";
const BUAndOBSBased = "BUAndObsBased";
const AuditCycleWithNC = "AuditWithNC";
const ProcessBase = "ProcessBase";
const clauseWithNcrTable = "clauseWithNcrTable";
const areaOfImpovementTable = "areaOfImpovementTable";
const masterSheet = "masterSheet";
const reviewEffectiveness = "reviewEffectiveness";

class ReportTableRow extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isEditMode: false,
      newReportName: props.row[props.nameKey],
    }
  }

  componentDidMount(){
    themeSettings = getThemeSettingsVariables();
  }
  
  toggleEditMode = (isEditMode) => {
    this.setState({
      isEditMode,
      newReportName: this.props.row[this.props.nameKey]
    });
  }

  handleChangeReportName = (e) => {
    this.setState({
      newReportName: e.target.value,
    });
  }

  onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
      this.handleClickSave();
		}
	};

  handleClickEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.toggleEditMode(!this.state.isEditMode);
  }

  handleClickSave = (event) => {
    event?.preventDefault && event.preventDefault();
    event?.stopPropagation && event.stopPropagation();
    this.toggleEditMode(false);
    if (this.state.newReportName !== this.props.row[this.props.nameKey]) {
      const updatedCriteria = { ...this.props.row };
      updatedCriteria.criteriaName = this.state.newReportName;
      this.props.onSave(updatedCriteria);
    }
  }

  render () {
    const { onClick, row, nameKey } = this.props;
    return (
      <tr onClick={onClick}>
        <td>
          {this.state.isEditMode
            ? <input
                name="reportName"
                type="text"
                className='form-control'
                onChange={this.handleChangeReportName}
                placeholder="Report Name"
                onKeyPress={this.onEnterBtnPress}
                value={this.state.newReportName}
                onClick={event => event.stopPropagation()}
                style={{ fontSize: 12 }}
                autoFocus
              />
            : row[nameKey]
          }
        </td>
        <td></td>
        <td className='text-center'>
          {this.state.isEditMode
            ? <a onClick={this.handleClickSave} className='d-inline-block color-theme' href="#">
              <span className="material-icons-outlined" style={{ fontSize: 20, verticalAlign: "middle" }}>save</span>
            </a>
            : <a onClick={this.handleClickEdit} className='d-inline-block color-theme' href="#">
              <span className="material-icons-outlined" style={{ fontSize: 20, verticalAlign: "middle" }}>edit</span>
            </a>
          }
        </td>
      </tr>
    )
  }
}

export default class DashboardHeader extends Component {
  constructor(props){
      super(props)
      this.state={
          showLoader:false,
          componentDidMountFlag:false,

          unitGroupingRootList:[],
          unitGroupingRootPlaceholder:"",
          selectedUnitGroupRootId:"",
          selectedUnitGroupingList:[],
          displayFilterList:[],

          showUnitGroupingDialog:false,
          selectedDisplayType:"",
          selectedChartDisplayType:"",

          processList:[],
          processPlaceholder:"",
          selectedProcessList:[],

          functionList:[],
          functionPlaceholder:"",
          selectedFunctionList:[],

          clauseList:[],
          clausePlaceholder:"",
          selectedClauseList:[],

          auditCycleList:[],
          auditCyclePlaceholder:"",
          selectedAuditCycleList:[],

          employeeList:[],
          employeePlaceholder:"",
          selectedEmployeeList:[],

          nextAuditCycleList:[],
          nextAuditCyclePlaceholder:"",
          SelectedNextAuditCycleList:[],

          complianceList:[],
          compliancePlaceholder:'',
          selectedComplianceList:[],


          unitTypeList:[],
          unitTypePlaceholder:"",
          selectedUnitTypeList:[],


          qmsList:[],
          qmsPlaceholder:"",
          selectedQmsList:[],

          selectedFilterList:[],
          criteriaId:"",
          criteria: [],

          datalist:[],
          tableHeader:[],
          fixedName:"",
          formErrors:{},
          showUpdateDialog:false,
          updateDialogMsg: "",

          showSelectReportDialog: false,
      }
  }

  componentDidMount(){
    themeSettings = getThemeSettingsVariables();
    if (this.props.showBody) {
      this.getInitData();
    }
    else {
      this.setState({
        componentDidMountFlag: true,
      });
    }
    this.setState({
      criteria: this.props.criteriaList,
    });
  }

  componentDidUpdate(prevProps) {
    const clonePrevProps = { ...prevProps };
    const cloneProps = { ...this.props };
    delete clonePrevProps.rightContent;
    delete clonePrevProps.nameContent;
    delete cloneProps.rightContent;
    delete cloneProps.nameContent;

    if(JSON.stringify(clonePrevProps) !== JSON.stringify(cloneProps)) {
      if (this.props.showBody) {
        this.getInitData();
        this.criteriaChangeCall(this.props.selectedItemList);
      }
      this.setState({
        criteria: this.props.criteriaList,
      });
    }
  }


 

  criteriaChangeCall = (selectedItemList) => {

    const tempselectedFilterList = selectedItemList.filterList? selectedItemList.filterList:[];
    const tempselectedProcessList = selectedItemList.processList ? selectedItemList.processList:[];
    const tempselectedFunctionList = selectedItemList.functionList ?selectedItemList.functionList:[];
    const tempselectedClauseList = selectedItemList.clauseList ? selectedItemList.clauseList:[];
    const tempselectedQmsList = selectedItemList.qmsList?selectedItemList.qmsList:[];
    const tempselectedAuditCycleList = selectedItemList.auditCycleList?selectedItemList.auditCycleList:[];
    const tempselectedEmployeeList = selectedItemList.employeeList?selectedItemList.employeeList:[];
    const tempSelectedUnitGroupingList = selectedItemList.unitGroupingList ?selectedItemList.unitGroupingList:[];
    const tempselectedUnitGroupRootId = selectedItemList.rootUnitGroupId || "";
    const tempSelectedUnitTypeList = selectedItemList.unitTypeList?selectedItemList.unitTypeList:[] 
    const tempSelectedNextAuditCycleList = selectedItemList.nextAuditCycleList?selectedItemList.nextAuditCycleList:[];
    const tempSelectedComplianceList = selectedItemList.complianceList?selectedItemList.complianceList:[];

    let tempunitGroupingRootPlaceholder = "";
    let newClauseList =[];

    let tmpNextAuditCycleList =[];
    if(selectedItemList.finalName==reviewEffectiveness){
      tmpNextAuditCycleList = this.state.auditCycleList.filter(e=>parseInt(e.Id) > tempselectedAuditCycleList[0])||[]
    }


    if(tempselectedProcessList.length>0){
      this.state.processList
      .filter(item =>tempselectedProcessList.includes(item.Id))
      .forEach(item => {
        item.clauseList.forEach(ele => {
          if (!newClauseList.some(element => element.value === ele.value)) {
            newClauseList.push(ele);
          }
        });
      });
    }
    
    const unitGroupingRoot = Object.values(this.state.unitGroupingRootList).find(
      item => item.Id === tempselectedUnitGroupRootId
    );
    if (unitGroupingRoot) {
      tempunitGroupingRootPlaceholder = unitGroupingRoot.value;
      tempunitGroupingRootPlaceholder = unitGroupingRoot.value;
    }
    
    this.setState({
      selectedFilterList: tempselectedFilterList || [],
      selectedProcessList: tempselectedProcessList || [],
      selectedFunctionList: tempselectedFunctionList || [],
      selectedClauseList: tempselectedClauseList || [],
      selectedQmsList: tempselectedQmsList || [],
      selectedAuditCycleList: tempselectedAuditCycleList || [],
      selectedEmployeeList: tempselectedEmployeeList || [],
      selectedUnitGroupRootId: tempselectedUnitGroupRootId,
      selectedUnitGroupingList: tempSelectedUnitGroupingList || [],
      selectedUnitTypeList:tempSelectedUnitTypeList ||[],
      SelectedNextAuditCycleList:tempSelectedNextAuditCycleList || [],
      selectedComplianceList:tempSelectedComplianceList || [],
      nextAuditCycleList:tmpNextAuditCycleList,
      unitGroupingRootPlaceholder: tempunitGroupingRootPlaceholder,
      selectedDisplayType: selectedItemList.displayType,
      selectedChartDisplayType: selectedItemList.chartType,
      criteriaId: selectedItemList.criteriaId,
      fixedName: selectedItemList.finalName,
      componentDidMountFlag: true,
      formErrors:{},
      datalist:[],
      clauseList:newClauseList
      
    });
  }

  getInitData =()=>{
    this.props.showLoader(true)
    userDetails = getLocalStorageVariables();
    let url =  Constants.GetMultiLevelFilterData;
    let postParam ={
      email: userDetails.email,
      accessToken: userDetails.accessToken,
    }

    fetch(url, {
        method: "POST",
        mode: "cors",
        body: new URLSearchParams(postParam),
      }).then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
            localStorage.clear();
            window.location = "/";
        } else if (data.responseCode === Constants.CODE_SUCCESS) {
          this.setState({
              unitGroupingRootList:data.data.unitGrouping,
              processList:data.data.process,
              functionList:data.data.function,
              auditCycleList:data.data.AuditCycle,
              employeeList:data.data.employeeList,
              unitTypeList:data.data.unitType,
              complianceList:data.data.complianceList
          },()=>{
            this.criteriaChangeCall(this.props.selectedItemList)
            this.props.showLoader(false)
          })
        } else{
          this.props.showLoader(false)
        }     
    })
  }

  unitGroupingDropdownItemClick = (selectedItem)=>{
    this.setState({
        selectedUnitGroupRootId:selectedItem.Id,
        unitGroupingRootPlaceholder:selectedItem.value,
        selectedUnitGroupingList:[]
    })
  }

  handleSelectUnitGroupingDialogShow = () =>{
    this.setState({
        showUnitGroupingDialog:true,
    })
  }

  handleSelectUnitGroupingDialogClose = () =>{
    this.setState({
        showUnitGroupingDialog:false
    })
  }
  
  handleSelectUnitGroupingDialogSaveClose =(unitGroupingObj)=>{
    let tempSelectedUnitGroupingList = [];
    Object.values(unitGroupingObj).map((item)=>{
        tempSelectedUnitGroupingList.push(item.unitGroupId)
    })
    this.setState({
    showUnitGroupingDialog:false,
    selectedUnitGroupingList:tempSelectedUnitGroupingList,
    formErrors:{}
    })
  }

  addProcess =(args)=>{
		let tempSelectedProcessList = this.state.selectedProcessList;
    let tempFromError = this.state.formErrors
    tempFromError["selectedProcessList"]=""
		tempSelectedProcessList.push(args.itemData.Id)

    let newClauseList =[];
    this.state.processList
    .filter(item => tempSelectedProcessList.includes(item.Id))
    .forEach(item => {
      item.clauseList.forEach(ele => {
        if (!newClauseList.some(element => element.value === ele.value)) {
          newClauseList.push(ele);
        }
      });
    });
		this.setState({
      clauseList:newClauseList,
			selectedProcessList:tempSelectedProcessList,
      tempselectedClauseList:this.state.selectedClauseList, 
			formErrors:tempFromError,
		})
	}

	removeProcess = (args) => {
		let tempSelectedProcessList = this.state.selectedProcessList;
		tempSelectedProcessList = tempSelectedProcessList.filter(function(item) {
			return item !== args.itemData.Id
		})

    let newClauseList =[];
    this.state.processList
    .filter(item =>tempSelectedProcessList.includes(item.Id))
    .forEach(item => {
      item.clauseList.forEach(ele => {
        if (!newClauseList.some(element => element.value === ele.value)) {
           newClauseList.push(ele);
        }
      });
    });

    let tempSelectedClauseList = this.state.selectedClauseList.filter(item => {
      return newClauseList.some(ele => ele.value === item);
    });
  
		this.setState({
      selectedClauseList:tempSelectedClauseList,
			selectedProcessList:tempSelectedProcessList,
      clauseList:newClauseList,
      tempselectedClauseList:this.state.selectedClauseList, 
		})
	}

  addFunction =(args)=>{
		let tempSelectedFunctionList = this.state.selectedFunctionList;
    let tempFromError = this.state.formErrors
    tempFromError["selectedFunctionList"]=""
		tempSelectedFunctionList.push(args.itemData.Id)
		this.setState({
			selectedFunctionList:tempSelectedFunctionList,
			formErrors:tempFromError
		})
	}

	removeFunction = (args) => {
		let tempSelectedFunctionList = this.state.selectedFunctionList;
		tempSelectedFunctionList = tempSelectedFunctionList.filter(function(item) {
			return item !== args.itemData.Id
		})
		this.setState({
			selectedFunctionList:tempSelectedFunctionList
		})
	}

  addClause =(args)=>{
		let tempSelectedClauseList = this.state.selectedClauseList;
    let tempFromError = this.state.formErrors
    tempFromError["selectedClauseList"]=""
		tempSelectedClauseList.push(args.itemData.value)
		this.setState({
			selectedClauseList:tempSelectedClauseList,
			formErrors:tempFromError
		})
	}

	removeClause = (args) => {
		let tempSelectedClauseList = this.state.selectedClauseList;
		tempSelectedClauseList = tempSelectedClauseList.filter(function(item) {
			return item !== args.itemData.value
		})
		this.setState({
			selectedClauseList:tempSelectedClauseList,
		})
	}

  addUnitType =(args)=>{
		let tempSelectedUnitTypeList = this.state.selectedUnitTypeList;
    let tempFromError = this.state.formErrors
    tempFromError["selectedUnitTypeList"]=""
		tempSelectedUnitTypeList.push(args.itemData.value)
		this.setState({
			selectedUnitTypeList:tempSelectedUnitTypeList,
			formErrors:tempFromError
		})
	}

	removeUnitType = (args) => {
		let tempSelectedUnitTypeList = this.state.selectedUnitTypeList;
		tempSelectedUnitTypeList = tempSelectedUnitTypeList.filter(function(item) {
			return item !== args.itemData.value
		})
		this.setState({
			selectedUnitTypeList:tempSelectedUnitTypeList,
		})
	}

  addComplianceStatus =(args)=>{
		let tempSelectedList = this.state.selectedComplianceList;
    let tempFromError = this.state.formErrors
    tempFromError["selectedComplianceList"]=""
		tempSelectedList.push(args.itemData.value)
		this.setState({
			selectedComplianceList:tempSelectedList,
			formErrors:tempFromError
		})
	}

	removeComplianceStatus = (args) => {
		let tempSelectedList = this.state.selectedComplianceList;
		tempSelectedList = tempSelectedList.filter(function(item) {
			return item !== args.itemData.value
		})
		this.setState({
			selectedComplianceList:tempSelectedList,
		})
	}

  addAuditCycle =(args)=>{
		let tempSelectedAuditCycleList = this.state.selectedAuditCycleList;
    let tempFromError = this.state.formErrors
    tempFromError["selectedAuditCycleList"]=""
		tempSelectedAuditCycleList.push(args.itemData.Id)
    
    let nextAuditCycleData =[];
    if(this.state.fixedName==reviewEffectiveness){
      nextAuditCycleData = this.state.auditCycleList.filter(e=>e.Id >args.itemData.Id)||[]
    }


		this.setState({
			selectedAuditCycleList:tempSelectedAuditCycleList,
      formErrors:tempFromError,
      nextAuditCycleList:nextAuditCycleData
		})

	}

	removeAuditCycle = (args) => {
		let tempSelectedAuditCycleList = this.state.selectedAuditCycleList;
		tempSelectedAuditCycleList = tempSelectedAuditCycleList.filter(function(item) {
			return item !== args.itemData.Id
		})

    let nextAuditCycleData =[];
    if(this.state.fixedName==reviewEffectiveness && tempSelectedAuditCycleList.length>0){
      nextAuditCycleData = this.state.auditCycleList.filter(e=>e.Id>args.itemData.Id)||[]
    }

		this.setState({
			selectedAuditCycleList:tempSelectedAuditCycleList,
      nextAuditCycleList:nextAuditCycleData,
      SelectedNextAuditCycleList:[]
		})
	}

  addEmployee =(args)=>{
		let tempSelectedEmployeeList = this.state.selectedEmployeeList;
		tempSelectedEmployeeList.push(args.itemData.Id)

		this.setState({
			selectedEmployeeList:tempSelectedEmployeeList,
		})
	}

	removeEmployee = (args) => {
		let tempSelectedEmployeeList = this.state.selectedEmployeeList;
		tempSelectedEmployeeList = tempSelectedEmployeeList.filter(function(item) {
			return item !== args.itemData.Id
		})

		this.setState({
			selectedEmployeeList:tempSelectedEmployeeList,
		})
	}

  addNextAuditCycle =(args)=>{
		let tempSelectedAuditCycleList = this.state.SelectedNextAuditCycleList;
    let tempFromError = this.state.formErrors
    tempFromError["SelectedNextAuditCycleList"]=""
		tempSelectedAuditCycleList.push(args.itemData.Id)

		this.setState({
			SelectedNextAuditCycleList:tempSelectedAuditCycleList,
      formErrors:tempFromError,
		})

	}

	removeNextAuditCycle = (args) => {
		let tempSelectedAuditCycleList = this.state.SelectedNextAuditCycleList;
		tempSelectedAuditCycleList = tempSelectedAuditCycleList.filter(function(item) {
			return item !== args.itemData.Id
		})


		this.setState({
			SelectedNextAuditCycleList:tempSelectedAuditCycleList,
		})
	}

  displayTypeDropdownItemClick = (selectedItem) =>{
      this.setState({
        selectedDisplayType:selectedItem.label
      },()=>{
        let Obj={
          datalist:this.state.datalist,
          tableHeader:this.state.tableHeader,
          selectedChartDisplayType:this.state.selectedChartDisplayType,
          selectedDisplayType:this.state.selectedDisplayType
        };
    
        this.props.handleMultilevelObj(Obj)
      })
  }


  applyFilterOnClick = () => {
    this.props.showLoader(true)
    let Error = false;
    let FormErrorsTemp = {};
    const errorCodes = [];
  
    if ([AuditCycleBase, ProcessAndClauseBase, ProcessAndFunctionBase,clauseWithNcrTable,areaOfImpovementTable,masterSheet,
         BUBased,BUAndNCBased,  BUAndOBSBased, AuditCycleWithNC, ProcessBase,reviewEffectiveness].includes(this.state.fixedName)) {
        errorCodes.push({ variable: "selectedAuditCycleList", errorMessage: 'Please select the Audit Cycle',type:"Array" });
    } if ([ProcessAndClauseBase,ProcessAndFunctionBase,ProcessBase].includes(this.state.fixedName)) {
      errorCodes.push({ variable: "selectedProcessList", errorMessage: 'Please select the Process',type:"Array" });
    } if ([ProcessAndClauseBase].includes(this.state.fixedName)) {
      errorCodes.push({ variable: "selectedClauseList", errorMessage: 'Please select the Clause',type:"Array" });
    } if ([ProcessAndFunctionBase].includes(this.state.fixedName)) {
      errorCodes.push({ variable: "selectedFunctionList", errorMessage: 'Please select the Function',type:"Array" });
    } if ([BUBased, BUAndNCBased,BUAndOBSBased].includes(this.state.fixedName)) {
      console.log("selectedUnitGroupingList ::",this.state.selectedUnitGroupingList)
      errorCodes.push({ variable: "selectedUnitGroupingList", errorMessage: 'Please select the UnitGroup',type:"Array" });
    } if ([clauseWithNcrTable,masterSheet,ProcessAndClauseBase,areaOfImpovementTable,AuditCycleBase].includes(this.state.fixedName)) {
      errorCodes.push({ variable: "selectedUnitTypeList", errorMessage: 'Please select the UnitType',type:"Array" });
    }

    for (const { variable, errorMessage } of errorCodes) {
      if (isEmptyVariable(this.state[variable]) || isEmptyArray(this.state[variable])) {
        FormErrorsTemp[variable] = errorMessage;
        Error = true;
      }
    }

    if(Error) {
      let Obj={
        datalist:[],
        tableHeader:[],
        selectedChartDisplayType:this.state.selectedChartDisplayType,
        selectedDisplayType:this.state.selectedDisplayType
      };
      this.props.handleMultilevelObj(Obj)
      this.setState({
        formErrors:FormErrorsTemp,
      });
      this.props.showLoader(false)
    } else {

      let criteriaFilters ={};

      function handleProperty(propertyName, stateArray) {
        if (stateArray.length>0) {
          criteriaFilters[propertyName] = JSON.stringify(stateArray);
        } 
      }

      handleProperty('filterList', this.state.selectedFilterList);
      handleProperty('unitGroupingList', this.state.selectedUnitGroupingList);
      handleProperty('processList', this.state.selectedProcessList);
      handleProperty('functionList', this.state.selectedFunctionList);
      handleProperty('auditCycleList', this.state.selectedAuditCycleList);
      handleProperty('employeeList', this.state.selectedEmployeeList);
      handleProperty('clauseList', this.state.selectedClauseList);   
      handleProperty('qmsList', this.state.selectedQmsList);   
      handleProperty('unitTypeList', this.state.selectedUnitTypeList);   
      handleProperty('complianceList', this.state.selectedComplianceList);   
      handleProperty('nextAuditCycleList', this.state.SelectedNextAuditCycleList);   
      handleProperty('unitGroupingList', this.state.selectedUnitGroupingList);   
      handleProperty('rootUnitGroupId', this.state.selectedUnitGroupRootId);   
      handleProperty('displayType', this.state.selectedDisplayType);   
      handleProperty('chartType', this.state.selectedChartDisplayType);   

      let postParam = {
          email: userDetails.email,
          accessToken: userDetails.accessToken,
          criteriaId:this.state.criteriaId,
          departmentId:this.props.departmentId,
          ...criteriaFilters
      };

      fetch(Constants.GetDashboardData,{
          method: "POST",
          mode:'cors',
          body: new URLSearchParams(postParam)
      }).then(response => { return response.json()} )
      .then(data => {
        if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
            localStorage.clear();
            window.location="/";
        }else if(data.responseCode === Constants.CODE_SUCCESS){
          let Obj={
            datalist:data.data,
            tableHeader:data.colList,
            selectedChartDisplayType:this.state.selectedChartDisplayType,
            selectedDisplayType:this.state.selectedDisplayType
          };
          this.props.handleMultilevelObj(Obj)
          this.setState({
            datalist:data.data,
            tableHeader:data.colList,
            formErrors:{},
            showLoader:false
          });
          this.props.showLoader(false)
        }else{
          this.setState({
            showLoader:false
          })
          this.props.showLoader(false)
          toast.error(data.responseMessage);
        }
      })
    }
  }

  updateCriteria = (updatedCriteria = null) => {
      let FormErrorsTemp = this.state.formErrors
      let url =  Constants.UpdateCriteria;
      let criteriaFilters ={};

      function handleProperty(propertyName, updatedCriteria, stateArray) {
        if (updatedCriteria && updatedCriteria[propertyName]) {
          criteriaFilters[propertyName] = updatedCriteria[propertyName];
        } else if (stateArray.length > 0) {
          criteriaFilters[propertyName] = stateArray;
        }
      }

      handleProperty('filterList', updatedCriteria, this.state.selectedFilterList);
      handleProperty('unitGroupingList', updatedCriteria, this.state.selectedUnitGroupingList);
      handleProperty('processList', updatedCriteria, this.state.selectedProcessList);
      handleProperty('functionList', updatedCriteria, this.state.selectedFunctionList);
      handleProperty('auditCycleList', updatedCriteria, this.state.selectedAuditCycleList);
      handleProperty('employeeList', updatedCriteria, this.state.selectedEmployeeList);
      handleProperty('clauseList', updatedCriteria, this.state.selectedClauseList);   
      handleProperty('qmsList', updatedCriteria, this.state.selectedQmsList);   
      handleProperty('unitTypeList', updatedCriteria, this.state.selectedUnitTypeList);   
      handleProperty('complianceList', updatedCriteria, this.state.selectedComplianceList);   
      handleProperty('nextAuditCycleList', updatedCriteria, this.state.SelectedNextAuditCycleList);  
      handleProperty('unitGroupingList', updatedCriteria, this.state.selectedUnitGroupingList);   
      handleProperty('rootUnitGroupId', updatedCriteria, this.state.selectedUnitGroupRootId);   
      handleProperty('displayType', updatedCriteria, this.state.selectedDisplayType);   
      handleProperty('chartType', updatedCriteria, this.state.selectedChartDisplayType);   

      let postParam ={
        email: userDetails.email,
        accessToken: userDetails.accessToken,
        criteriaId: updatedCriteria?.criteriaId || this.state.criteriaId,
        criteriaFilters:JSON.stringify(criteriaFilters),
        criteriaName : updatedCriteria?.criteriaName || this.props.editCriteriaName,
      }
    
      fetch(url, {
        method: "POST",
        mode: "cors",
        body: new URLSearchParams(postParam),
      }).then((response) => {
        return response.json();
      }) .then((data) => {
        if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
          localStorage.clear();
          window.location = "/";
        } else if (data.responseCode === Constants.CODE_SUCCESS) {
            this.getAllCriteria();
            FormErrorsTemp={}
            this.setState({
              showUpdateDialog: true,
              updateDialogMsg: postParam.criteriaName + " is updated",
            });
        } else{
          toast.error(data.responseMessage);
          // FormErrorsTemp["responseMessage"]= data.responseMessage       
        }
        
      })
    this.setState({
      showLoader:false,
      formErrors:FormErrorsTemp,
    })
  }

  getAllCriteria =  () =>{
    this.setState({
      showLoader:true,
    })

    let url =  Constants.GetAllCriteria;
    let postParam ={
      email: userDetails.email,
      accessToken: userDetails.accessToken,
    }

    fetch(url, {
      method: "POST",
      mode: "cors",
      body: new URLSearchParams(postParam),
    }).then((response) => {
      return response.json();
    })
    .then((data) => {
      let criteria =[];
      if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
        localStorage.clear();
        window.location = "/";
      } else if (data.responseCode === Constants.CODE_SUCCESS) {
        criteria = data.data
      }
      this.setState({
        criteria:criteria,
      });
      this.props.updateCriteria(criteria);

      if(!isEmptyVariable(this.props.criteriaPlaceholder)) {
          Object.values(criteria).map((item)=>{
            if(item.criteriaId == this.state.criteriaId) {
              this.setState({
                showLoader:false,
                formErrors:{},
              },()=>{
                this.props.handleUpdateClick(criteria,item.criteriaName);
                this.criteriaChangeCall(item);
              })
            }
          })
        } else{
          this.setState({
            showLoader:false,
            criteria:criteria,
            formErrors:{}
          })
        }
    })
  }

  closeUpdateDialog = () =>{
    this.setState({
      showUpdateDialog:false
    })
  }

  selectReport = (event) => {
    event.preventDefault();
    this.setState({
      showSelectReportDialog: true,
    });
  }

  hideSelectReportDialog = () => {
    this.setState({
      showSelectReportDialog: false,
    });
  }

  onClickSelectReport = (callback) => {
    this.hideSelectReportDialog();
    callback();
  }
    
  render() {
    let fields = {text:"value",value:"Id"}; 
    let clause_fields = {text:"value",value:"value"}; 
    return (
        <>
          {this.state.componentDidMountFlag &&
            <div className="card-1">
              <div className={"card-heading theme-border-color" + (!this.props.showBody ? " border-0 mb-0 pb-0" : "")}>
                <div className="d-flex align-items-center">
                  <div className='flex-fill'>
                    <AddNewButtonLayout themeSettings={themeSettings}>
                      <a onClick={this.selectReport} href="#" style={{ width: "fit-content" }}>
                        <p className='m-0'>Select Report</p>
                      </a>
                    </AddNewButtonLayout>
                  </div>
                  <div className='d-flex flex-fill align-items-center justify-content-center flex-wrap'>
                    <strong style={{ fontSize: 16 }}>{this.props.criteriaPlaceholder}</strong>
                    {this.props.nameContent}
                  </div>
                  <div className='d-flex flex-fill align-items-center justify-content-end flex-wrap' style={{ gap: 15 }}>
                    <div>
                      {this.props.rightContent}
                    </div>
                    {this.props.showSelectedUsersApproval &&
                      <AddNewButtonLayout themeSettings={themeSettings}>
                        <a href="#" className='ml-auto' onClick={this.props.selectedUsersApproval} style={{ width: "fit-content" }}>
                          <span className="material-icons">account_box</span>
                          <p style={{fontSize: "11.5px"}}>Pending User Approval</p>
                        </a>
                      </AddNewButtonLayout>
                    }
                  </div>
                </div>
              </div>

              {this.props.showBody &&
                <div className="card-body">
                  <div className="search-filter-layout">
                    <div>
                        <div className='d-flex flex-wrap' style={{ gap: 15 }}>
                          { Object.values(this.state.selectedFilterList).includes(unitGroupingFilter) &&
                          <>                          <div  style={{width:"200px",marginTop:5}}>
                                    <span class="text-muted font-weight-medium">Unit Grouping</span>
                                    <RegularDD
                                    placeholder={this.state.unitGroupingRootPlaceholder}
                                    dropdownArr={this.state.unitGroupingRootList}
                                    labelParam="value"
                                    onDropDownItemClick={this.unitGroupingDropdownItemClick}
                                    />
                                </div>

                              { !isEmptyVariable(this.state.selectedUnitGroupRootId) && !isEmptyVariable(this.state.unitGroupingRootPlaceholder) &&
                                <div  style={{width:"200px",marginTop:26}}>
                                  <div className="dummy-dropdown" onClick={this.handleSelectUnitGroupingDialogShow}>
                                    <p class="placeholder-text">
                                        {isEmptyArray(this.state.selectedUnitGroupingList)
                                        ?"Select UnitGrouping"
                                        :this.state.selectedUnitGroupingList.length > 0 &&
                                        this.state.selectedUnitGroupingList.length+" UnitGrouping Selected"
                                        }
                                    </p>
                                    <span class="material-icons">keyboard_arrow_right</span>
                                  </div>
                                  </div>
                                  
                              }
                            </>
                          }
                            {Object.values(this.state.selectedFilterList).includes(auditCycleFilter) &&
                              <div className=' mt-1' style={{width:"180px"}}>
                                <span class="text-muted font-weight-medium">Audit Cycle</span>
                                <MultiSelectComponent 
                                    id="clauseCheckbox"
                                    dataSource={this.state.auditCycleList}
                                    fields={fields}
                                    mode="CheckBox"
                                    closePopupOnSelect={false}
                                    allowFiltering={true}
                                    select={this.addAuditCycle}
                                    value={this.state.selectedAuditCycleList}
                                    removed={this.removeAuditCycle}
                                    cssClass="clientsChecklist dialogcl"
                                    showDropDownIcon={true}
                                    showClearButton={false}
                                    showSelectAll={true}
                                    maximumSelectionLength={[reviewEffectiveness,areaOfImpovementTable].includes(this.state.fixedName)?1:100}
                                    filterType='Contains'
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div>}
                            {Object.values(this.state.selectedFilterList).includes(employeeFilter) &&
                              <div className='mt-1' style={{width:"180px"}}>
                                <span class="text-muted font-weight-medium">Employee</span>
                                <MultiSelectComponent 
                                    id="clauseCheckbox"
                                    dataSource={this.state.employeeList}
                                    fields={fields}
                                    mode="CheckBox"
                                    closePopupOnSelect={false}
                                    allowFiltering={true}
                                    select={this.addEmployee}
                                    value={this.state.selectedEmployeeList}
                                    removed={this.removeEmployee}
                                    cssClass="clientsChecklist dialogcl"
                                    showDropDownIcon={true}
                                    showClearButton={false}
                                    showSelectAll={true}
                                    filterType='Contains'
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div>}
                          {Object.values(this.state.selectedFilterList).includes(processFilter) &&
                            <div className='ml-2 mt-1' style={{width:"230px"}}>
                                <span class="text-muted font-weight-medium">Process</span>
                                <MultiSelectComponent 
                                    id="clauseCheckbox"
                                    dataSource={this.state.processList}
                                    fields={fields}
                                    mode="CheckBox"
                                    closePopupOnSelect={false}
                                    allowFiltering={true}
                                    select={this.addProcess}
                                    value={this.state.selectedProcessList}
                                    removed={this.removeProcess}
                                    cssClass="clientsChecklist dialogcl"
                                    showDropDownIcon={true}
                                    showClearButton={false}
                                    showSelectAll={true}
                                    filterType='Contains'
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div> }

                          {Object.values(this.state.selectedFilterList).includes(functionFilter) &&
                            <div className='ml-2 mt-1' style={{width:"220px"}}>
                                <span class="text-muted font-weight-medium">Function</span>
                                <MultiSelectComponent 
                                    id="clauseCheckbox"
                                    dataSource={this.state.functionList}
                                    fields={fields}
                                    mode="CheckBox"
                                    closePopupOnSelect={false}
                                    allowFiltering={true}
                                    select={this.addFunction}
                                    value={this.state.selectedFunctionList}
                                    removed={this.removeFunction}
                                    cssClass="clientsChecklist dialogcl"
                                    showDropDownIcon={true}
                                    showClearButton={false}
                                    showSelectAll={true}
                                    filterType='Contains'
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div> }

                          {Object.values(this.state.selectedFilterList).includes(clauseFilter) &&
                            <div className='ml-2 mt-1' style={{width:"200px"}}>
                                <span class="text-muted font-weight-medium">Clause</span>
                                <MultiSelectComponent 
                                    id="clauseCheckbox"
                                    dataSource={this.state.clauseList}
                                    fields={clause_fields}
                                    mode="CheckBox"
                                    closePopupOnSelect={false}  
                                    allowFiltering={true}
                                    select={this.addClause}
                                    value={this.state.selectedClauseList}
                                    removed={this.removeClause}
                                    cssClass="clientsChecklist dialogcl"
                                    showDropDownIcon={true}
                                    showClearButton={false}
                                    showSelectAll={true}
                                    filterType='Contains'
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div> 
                            } 
                          {Object.values(this.state.selectedFilterList).includes(unitTypeFilter) &&
                            <div className='ml-2 mt-1' style={{width:"200px"}}>
                                <span class="text-muted font-weight-medium">Unit Type</span>
                                <MultiSelectComponent 
                                    id="clauseCheckbox"
                                    dataSource={this.state.unitTypeList}
                                    fields={clause_fields}
                                    mode="CheckBox"
                                    closePopupOnSelect={false}  
                                    allowFiltering={true}
                                    select={this.addUnitType}
                                    value={this.state.selectedUnitTypeList}
                                    removed={this.removeUnitType}
                                    cssClass="clientsChecklist dialogcl"
                                    showDropDownIcon={true}
                                    showClearButton={false}
                                    showSelectAll={true}
                                    filterType='Contains'
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div> 
                            } 
                          {Object.values(this.state.selectedFilterList).includes(complianceFilter) &&
                            <div className='ml-2 mt-1' style={{width:"200px"}}>
                                <span class="text-muted font-weight-medium">Compliance Status</span>
                                <MultiSelectComponent 
                                    id="clauseCheckbox"
                                    dataSource={this.state.complianceList}
                                    fields={clause_fields}
                                    mode="CheckBox"
                                    closePopupOnSelect={false}  
                                    allowFiltering={true}
                                    select={this.addComplianceStatus}
                                    value={this.state.selectedComplianceList}
                                    removed={this.removeComplianceStatus}
                                    cssClass="clientsChecklist dialogcl"
                                    showDropDownIcon={true}
                                    showClearButton={false}
                                    showSelectAll={true}
                                    filterType='Contains'
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div> 
                            } 

                            {Object.values(this.state.selectedFilterList).includes(nextAuditCycleFilter) &&
                              <div className=' mt-1' style={{width:"180px"}}>
                                <span class="text-muted font-weight-medium">Next Audit Cycle</span>
                                <MultiSelectComponent 
                                    id="clauseCheckbox"
                                    dataSource={this.state.nextAuditCycleList}
                                    fields={fields}
                                    mode="CheckBox"
                                    closePopupOnSelect={false}
                                    allowFiltering={true}
                                    select={this.addNextAuditCycle}
                                    value={this.state.SelectedNextAuditCycleList}
                                    removed={this.removeNextAuditCycle}
                                    cssClass="clientsChecklist dialogcl"
                                    showDropDownIcon={true}
                                    showClearButton={false}
                                    showSelectAll={true}
                                    maximumSelectionLength={4}
                                    filterType='Contains'
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div>}
                            <div style={{width:"150px"}} className="mt-1 ml-2">
                            <span class="text-muted font-weight-medium">Display Type</span>
                                <RegularDD
                                    placeholder={this.state.selectedDisplayType}
                                    dropdownArr={this.props.displayTypeArr}
                                    labelParam="label"
                                    onDropDownItemClick={this.displayTypeDropdownItemClick}
                                />
                            </div>
                        </div>
                        { 
                          Object.entries(this.state.formErrors).map(([key, value]) => {
                            if (!isEmptyVariable(value)) {
                              return <span className="cm-error">{value}</span>;
                            }
                          })
                        }
                        <div className='d-flex' style={{ gap: 15 }}>
                          <AddNewButtonLayout themeSettings={themeSettings} style={{ marginTop:10,width:"75px" }}>
                            <a onClick={this.applyFilterOnClick} href="javascript:void(0);">                       
                              <p>Apply</p>
                            </a>
                          </AddNewButtonLayout>
                          <AddNewButtonLayout themeSettings={themeSettings} style={{marginTop:10,width:"85px"}}>
                            <a onClick={this.updateCriteria} href="javascript:void(0);">                       
                              <p>Update</p>
                            </a>
                          </AddNewButtonLayout>
                        </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
          
          <AlertDialog 
            showAlertDialog={this.state.showUpdateDialog}
            handleAlertDialogClose={this.closeUpdateDialog}
            type= {Constants.ALERT_TYPE_ALERT}
            alertDialogMessage={this.state.updateDialogMsg}
            proceedBtnClick={this.closeUpdateDialog}
            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
            themeSettings={themeSettings}
          />

          <SelectUnitGroupingDialog
              showUnitGroupingDialog={this.state.showUnitGroupingDialog}
              handleSelectUnitGroupingDialogClose={this.handleSelectUnitGroupingDialogClose}
              handleSelectUnitGroupingDialogSaveClose={this.handleSelectUnitGroupingDialogSaveClose}
              selectedUnitGroupingObjArr={this.state.selectedUnitGroupingList}
              rootUnitGroupId={this.state.selectedUnitGroupRootId}
              themeSettings = {themeSettings}
          />

          <Modal className="custom-dialog" show={this.state.showSelectReportDialog} onHide={this.hideSelectReportDialog}>
            <Modal.Header>
              <h5>Select Report</h5>
              <button onClick={this.hideSelectReportDialog} type="button" data-dismiss="modal">
                <span class="material-icons">close</span>
              </button>
            </Modal.Header>
            <hr />
            <div className="modal-body show-scrollbar" style={{ maxHeight: 370, overflow: "auto" }}>
              <div className="common-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Report Name</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardDisplayArr.map(row => {
                      if (row.label !== figureAndTable) {
                        return (
                          <tr key={row.label} onClick={() => this.onClickSelectReport(() => this.props.displayDropdownItemClick(row))}>
                            <td>{row.label}</td>
                            <td></td>
                            <td></td>
                          </tr>
                        )
                      }
                    })}
                    {this.state.criteria.map(row => (
                      <ReportTableRow
                        key={row.criteriaId}
                        onClick={() => this.onClickSelectReport(() => this.props.criteriaDropdownItemClick(row))}
                        row={row}
                        nameKey="criteriaName"
                        onSave={this.updateCriteria}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal>
      </>
    )
  }
}
