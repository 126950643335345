import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {
    isEmptyVariable, isAlphaNumericCharacter, getLocalStorageVariables,} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import {DialogProceedButton, DialogSaveButton} from '../../../common/Global/globalStyles.style';
import * as TemplateConstants from "../../../common/Global/templateConstants";
import DatePickerComponent from "../../Tasks/Common/dateSelectComponent";
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import moment from "moment";
import { DatePicker } from 'antd';

const userDetails = getLocalStorageVariables();

class AddFieldDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            formErrors:{},
            showAlertDialog:false,
            nextFollowUpDate:"",
            followUpTemplateValue:{},
            templateValues:[]
        }
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            if(this.props.isEdit){
                let tempTemplateValues = {};
                this.props.value.map(item => {
                    tempTemplateValues[item.fieldId] = item.value
                })

                let tempTemplateValueArr = []
                let tempTemplateValue = {};
                this.props.value.forEach(item => {
                    tempTemplateValue = {
                        templateValueId:item.templateValueId,
                        value:item.value
                    };
                    tempTemplateValueArr.push(tempTemplateValue)
                })
                this.setState({
                    followUpTemplateValue:tempTemplateValues,
                    templateValues:tempTemplateValueArr
                })
            }
        }
    }

    handleFollowUpTemplateValueDialogClose = () =>{
        this.props.handleFollowUpTemplateValueDialogClose(false);
        this.setState({
            followUpTemplateValue:{}
        })
    }

    handleFollowUpDateDialogClose = () => {
        this.setState({
            showAlertDialog:false,
            nextFollowUpDate:""
        })
    }

    handleEdit = (value,fieldItemId) => {
        let templateValueId = "";
        this.props.value.map(item => {
            if(item.fieldId == fieldItemId){
                templateValueId = item.templateValueId;
            }
        });

        if(this.props.isEdit){
            let tempTemplateValueArr = [];
            tempTemplateValueArr = this.state.templateValues.map(item => {
                if(item.templateValueId === templateValueId){
                    item.value = value
                }
                return item;
            })
            this.setState({
                templateValues:tempTemplateValueArr
            })
        }
    }
    
    handleChange = (e,fieldItem) => {
        let fieldItemId = JSON.parse(fieldItem.fieldId);

        if(this.props.isEdit){
            this.handleEdit(e.target.value,fieldItemId)
        }

        this.setState({
            followUpTemplateValue:{
                ...this.state.followUpTemplateValue,
                [fieldItemId]:e.target.value,
            }
        })
    }

    onExecuteDateFieldChange = (dateValue, fieldItem) => {
        let fieldItemId = JSON.parse(fieldItem.fieldId);

        if(this.props.isEdit){
            this.handleEdit(dateValue,fieldItemId)
        }

        this.setState({
            followUpTemplateValue:{
                ...this.state.followUpTemplateValue,
                [fieldItemId]:dateValue,
            }
        })
    };

    onNextFollowUpDateChange = (date) => {
		this.setState({
            nextFollowUpDate:isEmptyVariable(date)?"":date.format("YYYY-MM-DD"),
        })
    }

    onDropDownItemClick = (item,fieldItem) => {
        let fieldItemId = JSON.parse(fieldItem.fieldId);

        if(this.props.isEdit){
            this.handleEdit(item.value,fieldItemId)
        }

        this.setState({
            followUpTemplateValue:{
                ...this.state.followUpTemplateValue,
                [fieldItemId]:item.value,
            }
        })
    }

    addMultiSelectDropdownChange = (item,fieldItem) => {
        let fieldItemId = JSON.parse(fieldItem.fieldId);
        let multiSelectArray = [];
        if(!this.state.followUpTemplateValue[fieldItemId]){
            multiSelectArray.push(item.itemData.value)
        }else{
            multiSelectArray = JSON.parse(this.state.followUpTemplateValue[fieldItemId]);
            multiSelectArray.push(item.itemData.value)
        }
        if(this.props.isEdit){
            this.handleEdit(JSON.stringify(multiSelectArray),fieldItemId)
        }
        this.setState({
            followUpTemplateValue:{
                ...this.state.followUpTemplateValue,
                [fieldItemId]:JSON.stringify(multiSelectArray),
            }
        })
    }

    removeMultiSelectDropdownValue = (item,fieldItem) => {
        let fieldItemId = JSON.parse(fieldItem.fieldId);
        let multiSelectArray = JSON.parse(this.state.followUpTemplateValue[fieldItemId]);

        multiSelectArray = multiSelectArray.filter(i => i != item.itemData.value);
        if(this.props.isEdit){
            this.handleEdit(JSON.stringify(multiSelectArray),fieldItemId)
        }
        this.setState({
            followUpTemplateValue:{
                ...this.state.followUpTemplateValue,
                [fieldItemId]:JSON.stringify(multiSelectArray),
            }
        })
    }

    getExecTaskDisplayTableData = (assignmentTaskId,fieldItem,index) => {
        let fields = {text:"value",value:"value"};
        let fieldsWithDesc = {text:"valueNdesc",value:"value"};
        let isFieldIdCheck = isEmptyVariable(this.state.followUpTemplateValue[fieldItem.fieldId]);
        let fieldIdValue = this.state.followUpTemplateValue[fieldItem.fieldId];
        
        switch (fieldItem.fieldType) { 
            case TemplateConstants.FIELD_TYPE_TEXT:
                return (
                    <div class="addnew-modal-form-group">
                        <p>
                            {
                                fieldItem.fieldName}{fieldItem.isMandatorySelected === "Y"?"*":""
                            }
                        </p>
                        <input type="text" 
                            placeholder={fieldItem.placeholderValue}
                            name="fieldName" 
                            value={isFieldIdCheck&&!this.props.isEdit?"":fieldIdValue} 
                            onChange={e=>this.handleChange(e,fieldItem)}
                        />
                        {
                            fieldItem.isMandatorySelected==="Y"?(!isEmptyVariable(this.state.formErrors[fieldItem.fieldId]) &&
                            <span class="cm-error">{this.state.formErrors[fieldItem.fieldId]}</span>):""
                        }
                    </div>
                );
            case TemplateConstants.FIELD_TYPE_DATE:
                return (
                    <div class="addnew-modal-form-group">
                         <p>{fieldItem.fieldName}{fieldItem.isMandatorySelected === "Y"?"*":""}</p>
                        <DatePickerComponent
                            templateItemIndex={assignmentTaskId}
                            fieldItem={fieldItem}
                            format={"DD/MM/YYYY"}
                            onDateChange={(date) => this.onExecuteDateFieldChange(date,fieldItem)}
                            placeholderValue={fieldItem.placeholderValue}
                            fieldValue={isFieldIdCheck&&!this.props.isEdit?"":fieldIdValue}
                        />
                        {
                            fieldItem.isMandatorySelected==="Y"?(!isEmptyVariable(this.state.formErrors[fieldItem.fieldId]) &&
                            <span class="cm-error">{this.state.formErrors[fieldItem.fieldId]}</span>):""
                        }
                    </div>
                );
            case TemplateConstants.FIELD_TYPE_DROPDOWN:
                return(
                    <div class="addnew-modal-form-group">
                        <p>{fieldItem.fieldName}{fieldItem.isMandatorySelected === "Y"?"*":""}</p>
                        <RegularDropdown 
                            placeholder={isFieldIdCheck&&!this.props.isEdit?fieldItem.placeholderValue:fieldIdValue}
                            dropdownArr={fieldItem.dropdownValues}
                            labelParam="value"
                            onDropDownItemClick={(item)=>this.onDropDownItemClick(item,fieldItem)}
                            defaultPlaceholderDropDown={fieldItem.placeholderValue}
                        />  
                        {
                            fieldItem.isMandatorySelected==="Y"?(!isEmptyVariable(this.state.formErrors[fieldItem.fieldId]) &&
                            <span class="cm-error">{this.state.formErrors[fieldItem.fieldId]}</span>):""
                        }
                    </div>
                );
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                return(
                    <div class="addnew-modal-form-group">
                        <p>{fieldItem.fieldName}{fieldItem.isMandatorySelected === "Y"?"*":""}</p>
                        <MultiSelectComponent 
                            id={"multi"+index}
                            dataSource={fieldItem.dropdownValues}
                            fields={fieldItem.showDescription === "Y"?fieldsWithDesc:fields}
                            placeholder={fieldItem.placeholderValue} 
                            mode="CheckBox"
                            select={(item)=>this.addMultiSelectDropdownChange(item,fieldItem)}
                            removed={(item)=>this.removeMultiSelectDropdownValue(item,fieldItem)}
                            closePopupOnSelect={false}
                            allowFiltering={false}
                            readOnly={true}
                            cssClass="clientsChecklist dialogcl"
                            showDropDownIcon={true}
                            showClearButton={false}
                          >
                          <Inject services={[CheckBoxSelection]} />
                          </MultiSelectComponent>
                          {
                            fieldItem.isMandatorySelected==="Y"?(!isEmptyVariable(this.state.formErrors[fieldItem.fieldId]) &&
                            <span class="cm-error">{this.state.formErrors[fieldItem.fieldId]}</span>):""
                        }
                    </div>
                );
        }
    };

    handleTemplateValueField = () => {
        let isError = false;
        let FormErrorsTemp = {};
        
        this.props.followUpTaskTemplateList.map(item => {
            let fieldValue = this.state.followUpTemplateValue[item.fieldId];
            if(item.isMandatorySelected==="Y"){
                switch (item.fieldType) { 
                    case TemplateConstants.FIELD_TYPE_TEXT:
                        if(isEmptyVariable(fieldValue)){
                            isError = true;
                            FormErrorsTemp[item.fieldId] = "Please enter Field name";
                        }
                        if(!isEmptyVariable(fieldValue)){
                            if(!isAlphaNumericCharacter(fieldValue)){
                                isError = true;
                                FormErrorsTemp[item.fieldId] = "Field name should be Alphanumeric";
                            }
                        }
                    case TemplateConstants.FIELD_TYPE_DATE:
                        if(isEmptyVariable(fieldValue)){
                            isError = true;
                            FormErrorsTemp[item.fieldId] = "Please select a date";
                        }
                    case TemplateConstants.FIELD_TYPE_DROPDOWN:
                        if(isEmptyVariable(fieldValue)){
                            isError = true;
                            FormErrorsTemp[item.fieldId] = "Please select a value";
                        }
                    case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                        if(isEmptyVariable(fieldValue)){
                            isError = true;
                            FormErrorsTemp[item.fieldId] = "Please select a value";
                        }
                }
           }
        })       

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

            setTimeout(() => {
                this.setState({
                    formErrors:{},
                });
            },Constants.WRNG_MSG_TIMEOUT);
        }else{
            if(this.props.selectFollowUpTemplate==="Checked & Open"){
                this.setState({
                    showAlertDialog:true,
                })
            }else{
                this.setState({
                    showAlertDialog: false,
                });
                if(this.props.isEdit){
                    this.EditFollowUpTaskTemplateValues();
                }else{
                    this.MarkFollowUpTaskComplete();
                }
            }
        }
    }

    handleProceedButtonClick = () => {
        let isError = false;
        if(isEmptyVariable(this.state.nextFollowUpDate)){
            isError = true;
        }
        if(isError){
            this.setState({
                errorMsg:"Please select a date",
                showLoader:false
            });

            setTimeout(() => {
                this.setState({
                    formErrors:{},
                });
            },Constants.WRNG_MSG_TIMEOUT);
        }else{
            this.setState({
                showAlertDialog: false,
            })
            this.SaveFollowUpTaskTemplateValues();
        }
    }
    /*******************API CALLS*******************/

    SaveFollowUpTaskTemplateValues = () => {
        this.setState({
            showLoader: true,
        });
        let url = "";
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
            ...this.state.followUpTemplateValue,
            nextFollowUpDate:this.props.selectFollowUpTemplate==="Checked & Open"?this.state.nextFollowUpDate:""
        };

        if(!isEmptyVariable(this.props.observationId)){
            url = Constants.SaveFollowUpObservationTemplateValues
            postParam.observationId = this.props.observationId
        }else{
            url = Constants.SaveFollowUpTaskTemplateValues
            postParam.assignmentTaskId = this.props.assignmentTaskId
        }

        fetch(url, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postParam),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                this.handleUpdateCloseOpenStatus();
                this.setState({
                    showLoader: false,
                    followUpTemplateValue:{},
                    nextFollowUpDate:""
                })  
            } else {
                this.setState({
                    showLoader: false,
                });
            }
        });
    }

    MarkFollowUpTaskComplete = () => {
        this.setState({
            showLoader:true
        })
        let url = "";
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
            ...this.state.followUpTemplateValue,
            nextFollowUpDate:this.props.selectFollowUpTemplate==="Checked & Open"?this.state.nextFollowUpDate:""
        };
        
        if(!isEmptyVariable(this.props.observationId)){
            url = Constants.MarkFollowUpObservationComplete
            postParam.observationId = this.props.observationId
        }else{
            url = Constants.MarkFollowUpTaskComplete
            postParam.assignmentTaskId = this.props.assignmentTaskId
        }

        fetch(url, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postParam),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                this.handleUpdateCloseOpenStatus();
                this.setState({
                    showLoader: false,
                    followUpTemplateValue:{}
                })
            } else {
                this.setState({
                    showLoader: false,
                });
            }
        });
    }

    EditFollowUpTaskTemplateValues = () => {
        this.setState({
            showLoader:true
        })
        
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
            assignmentId:this.props.assignmentId,
            templateValues:JSON.stringify(this.state.templateValues)
        };  

        if(!isEmptyVariable(this.props.observationId)){
            postParam.observationId = this.props.observationId
        }else{
            postParam.assignmentTaskId = this.props.assignmentTaskId
        }

        fetch(Constants.EditFollowUpTaskTemplateValues, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postParam),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                this.setState({
                    showLoader: false,
                })
                this.props.handleFollowUpTemplateValueDialogClose(true);
            } else {
                this.setState({
                    showLoader: false,
                });
            }
        });
    }

    handleUpdateCloseOpenStatus = () => {
        let placeholder = (this.props.selectFollowUpTemplate==="Checked & Open")?"Open":this.props.selectFollowUpTemplate;
        
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
            closeOpenStatus: placeholder
        };
        
        if(!isEmptyVariable(this.props.observationId)){
            postParam.observationId = this.props.observationId
        }else{
            postParam.assignmentTaskId = this.props.assignmentTaskId
        }
        this.props.handleFollowUpTemplateValueDialogClose(false);
    }
    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <>
                <Modal className="custom-dialog" show={this.props.showFollowUpTemplateValueDialog} backdrop="static">
                    <Modal.Header>
                        <h5>{this.props.title}</h5>
                        <button 
                            onClick={this.handleFollowUpTemplateValueDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        {this.props.followUpTaskTemplateList.map((item,index) => {
                            return this.getExecTaskDisplayTableData(this.props.assignmentTaskId,item,index);
                        })}
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                {!this.props.isEdit &&
                                    <button onClick={this.handleFollowUpTemplateValueDialogClose} type="button" class="modal-close">Cancel</button>
                                }
                                <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.handleTemplateValueField} type="button">{this.props.isEdit && this.props.selectFollowUpTemplate==="Checked & Open"?"Next":"Save"}</DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal className="custom-dialog" show={this.state.showAlertDialog} backdrop="static">
                    <Modal.Header>
                        <h5>{"Next Follow Up Date"}</h5>
                        <button 
                            onClick={this.handleFollowUpDateDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <div class="modal-body">
                        <div class="addnew-modal-form-group">
                            <DatePicker
                                onChange={this.onNextFollowUpDateChange}
                                defaultValue={!isEmptyVariable(this.state.nextFollowUpDate)?moment(this.state.nextFollowUpDate):""}
                                format={"DD/MM/YYYY"}
                                placeholder="Select Date"
                                disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                                selectedItem={!isEmptyVariable(this.state.nextFollowUpDate)?moment(this.state.nextFollowUpDate):""}
                            />
                        </div>
                        {
                            this.state.errorMsg &&
                            <span className="cm-error dialog-error">{this.state.errorMsg}</span>
                        }

                        <div class="dialog-btn-layout">
                            <a class="alert-cancel" href="javascript:void(0);"
                                onClick={this.handleFollowUpDateDialogClose}
                            >Cancel</a>
                            <DialogProceedButton
                                themeSettings={this.props.themeSettings}
                                class="alert-proceed"
                                href="javascript:void(0);"
                                onClick={this.handleProceedButtonClick}
                                style={{
                                    marginLeft:"10px"
                                }}
                            >
                            {"Save"}
                            </DialogProceedButton>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default AddFieldDialog;