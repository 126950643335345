import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {DialogSaveButton, AddNewButtonLayout} from '../../common/Global/globalStyles.style';
import {getLocalStorageVariables,isEmptyVariable, getThemeSettingsVariables} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import { AsyncPaginate } from "react-select-async-paginate";
import AlertDialog from '../../common/AlertDialog';

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();

const countryPlaceholder = "Select Country";
const statePlaceholder = "Select State";

const sortCountry = "country";
const sortState = "state";

const resultSize = 50;
const pageNo = 1;

class CitiesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            formErrors:{},

            totalCount:0,
            resultSize:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"asc",
            

            city:"",
            datalist:[],
            countryList:[],
            statesList:[],
            selectedCountry:countryPlaceholder,
            selectedState:statePlaceholder,

            countryTextBoxFlag:false,
            stateTextBoxFlag:false,
            showAddCountryButtonFlag:true,
            showAddStateButtonFlag:true,

            showAlertDialog:false,
            operationType:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){
            if(this.props.isEdit){
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    formErrors:{},
                    countryList:[],
                    statesList:[],

                    city:this.props.city,
                    selectedCountry:this.props.selectedCountry,
                    selectedState:this.props.selectedState,
                    countryTextBoxFlag:false,
                    stateTextBoxFlag:false,

                    showAddCountryButtonFlag:false,
                    showAddStateButtonFlag:false
                    
                })
            }else{
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    formErrors:{},

                    city:"",
                    countryList:[],
                    statesList:[],
                    selectedCountry:countryPlaceholder,
                    selectedState:statePlaceholder,
                    countryTextBoxFlag:false,
                    stateTextBoxFlag:false,

                    showAddCountryButtonFlag:true,
                    showAddStateButtonFlag:true,

                    showAlertDialog:false,
                    operationType:"",
                    alertDialogMessage:"",
                    alertDialogHeading:"",
                    proceedBtnLabel:"",

                })
            }
        }
    }

    handleCreateDialogClose = () => {
        this.setState({
            selectedCountry:"",
            selectedState:"",
            city:"",
        },()=>{
            this.props.handleCreateDialogClose(false);
        });
    }

    handleOnChangeSelectedCountry = (e) => {
		console.log(e);
		this.setState({ 
            selectedCountry:e.country
         },()=>{
             this.getStatesList("","",{page:pageNo});
         });
	};

    handleOnChangeSelectedState = (e) => {
		console.log(e);
		this.setState({ 
            selectedState:e.state
         });
	};

    handleAlertDialogClose = () => {
        this.setState({
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
        })
    }

    handleChange = (e) =>{
       const {name, value} = e.target;
       this.setState({ 
            [name]: value,
        });
    }

    handleAddNewCountryDialog = () =>{
        this.setState({
            operationType:"Add Country",
            alertDialogHeading:"Add Country",
            proceedBtnLabel:"Add",
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to add new country?"
        })
    }

    handleAddNewStateDialog = () =>{
        this.setState({
            operationType:"Add State",
            alertDialogHeading:"Add State",
            proceedBtnLabel:"Add",
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to add new State?"
        })
    }

    handleAddNewCountry = () =>{
        this.setState({
            countryTextBoxFlag:true,
            stateTextBoxFlag:true,
            showAddCountryButtonFlag:false,
            showAddStateButtonFlag:false,

            selectedCountry:"",
            selectedState:"",

            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
        })
    }

    handleAddNewState = () =>{
        this.setState({
            stateTextBoxFlag:true,
            showAddStateButtonFlag:false,

            selectedState:"",

            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
        })
    }

    /*******************API CALLS*******************/

    getCountriesList = (inputValue,loadedOptions, { page }) => {
        this.setState({
            showLoader:true,
        });

        return fetch(Constants.GetCountries,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:page,
                country:inputValue,
                resultsize:this.state.resultSize,
                sort:sortCountry,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    countryList:data.data.result,
                    totalCount:data.data.count,
                });
                return {
                    options: data.data.result,
                    hasMore: (data.data.start+resultSize) <= (data.data.count),
                    additional: {
                      page: page + 1,
                    },
                  };
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                });
            }
        });
    }

    getStatesList = (inputValue,loadedOptions, { page }) => {
        this.setState({
            showLoader:true,
        });
        let country = "";
        if(this.state.selectedCountry !== countryPlaceholder){
            country = this.state.selectedCountry;
        }
        return fetch(Constants.GetStates,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:page,
                state:inputValue,
                country:country,
                resultsize:resultSize,
                sort:sortState,

            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    statesList:data.data.result,
                    totalCount:data.data.count,
                });
                return {
                    options: data.data.result,
                    hasMore: (data.data.start+resultSize) <= (data.data.count),
                    additional: {
                      page: page + 1,
                    },
                  };
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                });
            }
        });
    }

    getCitiesList = (inputValue,loadedOptions, { page }) => {
        this.setState({
            showLoader:true,
        });

        return fetch(Constants.GetCities,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email: userDetails.email,
				accessToken: userDetails.accessToken,
				city: inputValue,
				resultsize: resultSize,
                pageNo:page,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                });
                return {
                    options: data.data.result,
                    hasMore: (data.data.start+resultSize) <= (data.data.count),
                    additional: {
                      page: page + 1,
                    },
                  };
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                });
            }
        });
    }
    
    handleCreateCity = () => {
        this.setState({
            showLoader:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

       

        if(isEmptyVariable(this.state.city)){
            isError = true;
            FormErrorsTemp['city'] = "Please enter city name";
        }

        if(this.state.selectedCountry === countryPlaceholder || isEmptyVariable(this.state.selectedCountry)){
            isError = true;
            FormErrorsTemp['selectedCountry'] = "Please select a Country";
        }
        if(this.state.selectedState === statePlaceholder || isEmptyVariable(this.state.selectedState)){
            isError = true;
            FormErrorsTemp['selectedState'] = "Please select a State";
        }


        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let postParam = {};
            

            if(this.props.isEdit){
                url = Constants.UpdateCity;
                postParam = {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    cityId:this.props.cityId,
                    city:this.state.city,
                    country:this.state.selectedCountry,
                    state:this.state.selectedState,
                }
            }else{
                url = Constants.CreateCity;
                postParam = {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    city:this.state.city,
                    country:this.state.selectedCountry,
                    state:this.state.selectedState,
                }
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        
                    },() => {
                        this.props.handleCreateDialogClose(true);
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        city:"",
                        selectedState:"",
                        selectedCountry:"",
                    })
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <Modal enforceFocus={false} className="custom-dialog add-city-dialog" show={this.props.showCreateDialog} backdrop="static">
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit City":"Add New City"}</h5>
                    <button 
                        onClick={this.handleCreateDialogClose}>
                        <span className="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />

                <div className="modal-body">
                    <div className="addnew-modal-form">
                        {/* <div className="row custom-row "> */}
                        {/* <div className="col-md-12 custom-col"> */}
                            <div className="addnew-modal-form-group">
                                <p>Country*</p>
                                <div className="flex-center-layout">
                                    {
                                        !this.state.countryTextBoxFlag && 
                                        <div className="react-select-dd">
                                        <AsyncPaginate
                                        loadOptions={this.getCountriesList}
                                        defaultOptions
                                        cacheOptions
                                        placeholder={this.state.selectedCountry}
                                        getOptionLabel={(e) => e.country}
                                        getOptionValue={(e) => e.country}
                                        onChange={this.handleOnChangeSelectedCountry}
                                        additional={{
                                            page: pageNo,
                                        }}
                                        />
                                        </div>
                                    }
                                    {
                                        this.state.countryTextBoxFlag && !this.props.isEdit &&
                                        <input 
                                            type="text" 
                                            placeholder="Enter Country Name"
                                            name="selectedCountry" 
                                            value={this.state.selectedCountry} 
                                            onChange={this.handleChange}
                                        />
                                    }
                                
                                
                                    {
                                        // (!this.props.isEdit || !this.state.countryTextBoxFlag) &&
                                        this.state.showAddCountryButtonFlag &&
                                            <AddNewButtonLayout themeSettings = {themeSettings}>
                                                <a onClick={this.handleAddNewCountryDialog} href="javascript:void(0);">
                                                    <span className="material-icons">add_circle</span>
                                                    <p>Add</p>
                                                </a>
                                            </AddNewButtonLayout>
                                    }
                                </div>

                                {
                                    !isEmptyVariable(this.state.formErrors['selectedCountry']) &&
                                    <span className="cm-error">{this.state.formErrors['selectedCountry']}</span>
                                }
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                        {/* <div className="row custom-row"> */}
                            {/* <div className="col-md-12 custom-col"> */}
                                <div className="addnew-modal-form-group">
                                    <p>State*</p>
                                    <div className="flex-center-layout">
                                    {
                                        !this.state.stateTextBoxFlag &&
                                        <div className="react-select-dd"> 
                                        <AsyncPaginate
                                            loadOptions={this.getStatesList}
                                            defaultOptions
                                            cacheOptions
                                            placeholder={this.state.selectedState}
                                            getOptionLabel={(e) => e.state}
                                            getOptionValue={(e) => e.state}
                                            onChange={this.handleOnChangeSelectedState}
                                            additional={{
                                                page: pageNo,
                                            }}
                                            // key is added to call getStatesList everytime a country is selected
                                            key={`Country-selected-${this.state.selectedCountry}`}
                                        />
                                        </div>
                                    }
                                    {
                                        this.state.stateTextBoxFlag && !this.props.isEdit &&
                                        // <div className="col-md-8 custom-col">
                                         <input 
                                            type="text" 
                                            placeholder="Enter State Name"
                                            name="selectedState" 
                                            value={this.state.selectedState} 
                                            onChange={this.handleChange}
                                        />
                                        // </div>
                                    }
                                    
                            {
                                this.state.showAddStateButtonFlag &&
                                <AddNewButtonLayout themeSettings = {themeSettings}>
                                    <a onClick={this.handleAddNewStateDialog} href="javascript:void(0);">
                                        <span className="material-icons">add_circle</span>
                                        <p>Add</p>
                                    </a>
                                </AddNewButtonLayout>
                            }
                            
                        </div>
                        {
                            !isEmptyVariable(this.state.formErrors['selectedState']) &&
                            <span className="cm-error">{this.state.formErrors['selectedState']}</span>
                        }
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                        <div className="addnew-modal-form-group">
                            <p>City*</p>
                            <input type="text" placeholder="Enter City Name"
                            name="city" value={this.state.city} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['city']) &&
                                <span className="cm-error">{this.state.formErrors['city']}</span>
                            }
                        </div>
                    </div>
                    
                    <div className="modal-close-save">
                        <div className="close-save-col">
                            <button onClick={this.handleCreateDialogClose} type="button" className="modal-close">Cancel</button>
                            <DialogSaveButton
                            themeSettings = {themeSettings}
                            onClick={this.handleCreateCity} type="button">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>

                <AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={Constants.ALERT_TYPE_INFO}
                    alertDialogHeading={this.state.alertDialogHeading}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={
                        (this.state.operationType === "Add Country")
                        ?
                        this.handleAddNewCountry
                        :
                        this.handleAddNewState
                    }
                    proceedBtnLabel={this.state.proceedBtnLabel}
                    themeSettings={themeSettings}
                />

            </Modal>

        );
    }
}

export default CitiesList;
