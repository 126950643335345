import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { TableIcon, DialogSaveButton, AddNewButtonLayout,FilterCheckBox } from "../../../common/Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray, truncateString,
	getOnlyFileNameFromPath,getThemeSettingsVariables } from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import UploadAnnuxureSample from "./uploadAnnexureSample"
import AlertDialog from '../../../common/AlertDialog';

const themeSettings = getThemeSettingsVariables();

class AssertionAssignmentList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// uploadAnnexureErr: "",
			showUploadAnnSampleDialog: false,
			datalist:[],
			annexure_NR:"",
		};
	}

	openUploadAnnSampleDialog = () => {
		this.setState({
			showUploadAnnSampleDialog:true
		})
	}

	closeUploadAnnSampleDialog = () => {
		this.setState({
			showUploadAnnSampleDialog:false
		})
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showUploadAnnexureDialog) {
			this.setState({
				datalist:this.props.uploadedAnnexureArr,
				annexure_NR:this.props.annexure_NR,
			})
		}
	}

	downloadAttachment = (documentPath,e) => {
        e.stopPropagation();
        fetch(Constants.API_SERVER_PATH+documentPath)
        .then(response => { return response.blob(); } )
        .then(blob =>
        {
            let fileName = getOnlyFileNameFromPath(documentPath);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

	onTableDropDownItemClick = (item, dropdownItem) => {
		let arr = dropdownItem.split(":-:");
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Annexure",
                proceedBtnLabel:"Delete",
                deleteReactivateId:arr[0],
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete annexure "+getOnlyFileNameFromPath(arr[1])+"?"
            })
        }
    }

	handleAlertDialogClose = () => {
		this.setState({
			operationType:"",
			alertDialogHeading:"",
			proceedBtnLabel:"",
			deleteReactivateId:"",
			showAlertDialog:false,
			alertDialogMessage:""
		})
	}

	handleRecordOperation = () => {
		if(!isEmptyVariable(this.state.deleteReactivateId)){
			let newDataList = this.state.datalist;
			console.log(this.state.deleteReactivateId)
			console.log(newDataList)
			newDataList[this.state.deleteReactivateId].isDeleted = true;

			this.setState({
				datalist:newDataList,

				operationType:"",
				alertDialogHeading:"",
				proceedBtnLabel:"",
				deleteReactivateId:"",
				showAlertDialog:false,
				alertDialogMessage:""
			})
		}
	}

	//Callback from upload annexure sample
	returnUploadedArray = (uploadedFileArray) => {
		let newArray = [...this.state.datalist,...uploadedFileArray]

		this.setState({
			datalist:newArray,
			showUploadAnnSampleDialog:false
		})
	}

	handleSelectUploadAnnexureDialogSaveClose = () => {
		this.props.handleSelectUploadAnnexureDialogSaveClose(this.state.datalist,this.state.annexure_NR);
	}

	handleCheck = (e) =>{
        let isChecked = e.target.checked;
        let name = e.target.name;

        if(isChecked){
            this.setState({
                [name]:"Y",
				datalist:"",
            })
        }else{
            this.setState({
                [name]:"N"
            })
        }
    }

	/*****************************API*********************************/
	render() {
		const delDropdownObj = {
            icon:"delete",
            label:"Delete"
        }
        let actionObjAllowEdit = [delDropdownObj];
        let actionObjDisallowEdit = [];

		return (
			<section>
				<Modal
					className="task-select-dept-dialog custom-dialog"
					show={this.props.showUploadAnnexureDialog} backdrop="static"
				>
					<Modal.Header>
						<h5>Upload Annexure</h5>
						<button onClick={this.props.handleSelectUploadAnnexureDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						<div className="body-wrapper">
							<div className="search-addnew-bg m-t-0">
								<div className="search-addnew" style={{alignItems:"center"}}>
									<div className="search-filter-layout" style={{position:"relative"}}>
										<AddNewButtonLayout themeSettings={themeSettings}>
											<a href="javascript:void(0);" onClick={this.openUploadAnnSampleDialog}>
												<span className="material-icons">add_circle</span>
												<p>Add Annexure</p>
											</a>
										</AddNewButtonLayout>
										<div className="overlay-nr" style={{display:this.state.annexure_NR === "Y"?"block":"none"}}></div>
									</div>
									<FilterCheckBox themeSettings={this.props.themeSettings}
									style={{marginLeft:0}}>
										<input 
											type="checkbox"
											name="annexure_NR"
											id="annexure_NR"
											onChange={this.handleCheck}
											checked={this.state.annexure_NR === "Y"?true:false}
										/>
										<label for="annexure_NR">Not Required</label>
									</FilterCheckBox>
								</div>
							</div>

							<div style={{position:"relative"}}>
							{
								!isEmptyArray(this.state.datalist) && 
								<div className="common-tab-bg">
									<div className="common-table" style={{width:"100%"}}>
										<table className="table">
											<thead>
												<tr>
													<th className="text-center" width={"5%"}>No.</th>
													<th width={"5%"}>File Name</th>
													<th width={"5%"}>Remarks</th>
													<th className="text-center" width={"5%"}>Download</th>
													<th className="text-center"  width={"5%"}></th>
												</tr>
											</thead>
											<tbody>
											{
												!isEmptyArray(this.state.datalist) && 
												this.state.datalist.map((item,idx) => {
													return !item.isDeleted?<tr>
														<td className="text-center">{(idx+1)}</td>
														<td>{getOnlyFileNameFromPath(item.fileName)}</td>
														<td>{truncateString(item.remark,Constants.REMARKS_STRING_LENGTH)}</td>
														<TableIcon themeSettings={this.props.themeSettings} className="text-center">
															<span class="material-icons" onClick={this.downloadAttachment.bind(this, item.fileName)}>get_app</span>
														</TableIcon>
														<td className="text-center">
															{
																<TableDropDown 
																	actionArr={actionObjAllowEdit}
																	onDropDownItemClick = {this.onTableDropDownItemClick}
																	dropDownId = {idx+":-:"+item.fileName}
																	dropdownWidth={"130px"}
																	themeSettings={this.props.themeSettings}
																/>
															}
														</td>
													</tr>
													:
													null
												})
											}
											</tbody>
										</table>
									</div>
								</div>
							}
							{
								isEmptyArray(this.state.datalist) &&
								<div class="no-items-layout">
									<div class="no-items-card">
										<h6>
										{
											isEmptyVariable(this.state.apiSearchKey)?
											Constants.NO_RECORDS_WARNING
											:
											Constants.EMPTY_SEARCH_WARNING
										}
										</h6>
									</div>
								</div>
							}
							<div className="overlay-nr" style={{display:this.state.annexure_NR === "Y"?"block":"none"}}></div>
							</div>


							{/* {!isEmptyVariable(this.state.uploadAnnexureErr) && <span className="cm-error">{this.state.uploadAnnexureErr}</span>} */}
							<div className="modal-close-save m-t-15">
								<div className="close-save-col">
									<button onClick={this.props.handleSelectUploadAnnexureDialogClose} type="button" className="modal-close m-b-0">
										Cancel
									</button>
									<DialogSaveButton
										themeSettings={this.props.themeSettings}
										onClick={this.handleSelectUploadAnnexureDialogSaveClose}
										type="button"
										className="modal-save m-b-0"
									>
										Save
									</DialogSaveButton>
								</div>
							</div>
						</div>
					</div>
				</Modal>

				<UploadAnnuxureSample
					showUploadDocSampleDialog = {this.state.showUploadAnnSampleDialog}
					handleUploadSampleDocDialogClose = {this.closeUploadAnnSampleDialog}
					themeSettings={themeSettings}
					returnUploadedArray={this.returnUploadedArray}
					type={"Annexure"}
					isDraftOnward = {this.props.isDraftOnward}
				/>

				<AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={
                        (this.state.operationType === "Delete")
                        ?
                        Constants.ALERT_TYPE_WARNING
                        :
                        Constants.ALERT_TYPE_INFO
                    }
                    alertDialogHeading={this.state.alertDialogHeading}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={this.handleRecordOperation}
                    proceedBtnLabel={this.state.proceedBtnLabel}
                    themeSettings={this.props.themeSettings}
                />
			</section>
		);
	}
}

export default AssertionAssignmentList;
