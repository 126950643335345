import { isEmptyVariable, isEmptyArray, ifEmptyReturnEmptyStr, removeHtmlTags, SplitJoinString, getStringWithSpaceAfterComma,isJsonString } from "../../../../common/Global/commonFunctions";
import moment from "moment";

export const getFirstPageContent = (
	// clientNameTemp,
	unitNameCityListTemp,
	clientAddrTemp,
	companyNameTemp,
	companyAddr1Temp,
	companyAddr2Temp,
	companyCityTemp,
	companyStateTemp,
	companyPincodeTemp,
	companyLogo
) => {
    let firstPageContent = {};

	// let clientName = ifEmptyReturnEmptyStr(clientNameTemp, "");
    // firstPageContent["clientName"] =clientName;
	
	let unitNameCityList = "";
	if (!isEmptyVariable(unitNameCityListTemp)) {
		unitNameCityList = getStringWithSpaceAfterComma(unitNameCityListTemp);
	}
	firstPageContent["unitNameCityList"] =unitNameCityList;

	let clientAddr = "";
	if (!isEmptyVariable(clientAddrTemp)) {
        clientAddr = SplitJoinString(clientAddrTemp, ";;", ":-:", ",", 1);
	}
    firstPageContent["clientAddress"] =clientAddr;
    
    if(!isEmptyVariable(companyLogo)){
        firstPageContent["companyLogo"] =companyLogo;
    }
    
	let companyName = ifEmptyReturnEmptyStr(companyNameTemp, "");
    firstPageContent["companyName"] =companyName;

    let companyAddrLine1 = ifEmptyReturnEmptyStr(companyAddr1Temp, "") +",";
    firstPageContent["companyAddressLine1"] =companyAddrLine1;

    let companyAddrLine2 ="";
    
    if(!isEmptyVariable(companyAddr2Temp)){
    companyAddrLine2 = 
        ifEmptyReturnEmptyStr(companyAddr2Temp, "") + 
        (isEmptyVariable(companyAddr2Temp)?"":",");

    firstPageContent["companyAddressLine2"] =companyAddrLine2;
    }

    let companyAddrLine3 = 
        ifEmptyReturnEmptyStr(companyCityTemp, "") +
        ", " +
        ifEmptyReturnEmptyStr(companyStateTemp, "") +
        ", " +
        ifEmptyReturnEmptyStr(companyPincodeTemp, "");

    firstPageContent["companyAddressLine3"] =companyAddrLine3;
    

	return firstPageContent
		
};

export const getSecondPageContent = (
    assignmentTypeNameTemp,
	unitListTemp,
	unitAddressListTemp,
	assignmentAreaTemp,
	processListTemp,
	functionListTemp,
	checkListTemp,
	memberListTemp,
	L1UserNameTemp,
	startDateTemp,
	endDateTemp,
	reportDateTemp,
	periodFromDateTemp,
	periodToDateTemp,
	reportRiskLevelTemp
) => {
    let secondPageContent = {};
    if(!isEmptyVariable(assignmentTypeNameTemp)){
        secondPageContent["assignmentTypeName"]= assignmentTypeNameTemp;
	}
	if(!isEmptyVariable(unitListTemp)){
        let unitList = SplitJoinString(unitListTemp, ";;", ":-:", ", ");
        secondPageContent["unitList"]= unitList;
	}
	if (!isEmptyVariable(unitAddressListTemp)) {
		let unitAddressList = SplitJoinString(unitAddressListTemp, ";;", ":-:", ",", 1);
        secondPageContent["unitAddressList"]= unitAddressList;
	}

	if (!isEmptyVariable(assignmentAreaTemp)) {
		let assignmentArea = "";
		if (assignmentAreaTemp === "Process") {
			assignmentArea = SplitJoinString(processListTemp, ",", ":-:", ", ");
		} else if (assignmentAreaTemp === "Function") {
			assignmentArea = SplitJoinString(functionListTemp, ",", ":-:", ", ");
		} else if (assignmentAreaTemp === "CheckList" && !isEmptyVariable(checkListTemp)) {
			assignmentArea = SplitJoinString(checkListTemp, ",", ":-:", ", ");
		}
        secondPageContent["assignmentAreaList"]= assignmentArea;
	}

	if (!isEmptyVariable(memberListTemp)) {
		let	memberList = SplitJoinString(memberListTemp, ",", ":", ", ", 1);
		if (!isEmptyVariable(L1UserNameTemp)) {
			memberList = memberList + ", " + L1UserNameTemp;
		}
        secondPageContent["memberList"]= memberList;
	}

	if (!isEmptyVariable(startDateTemp)) {
        secondPageContent["startDate"]= moment(startDateTemp).format("DD/MM/YYYY");
	}

	if (!isEmptyVariable(endDateTemp)) {
        secondPageContent["endDate"]= moment(endDateTemp).format("DD/MM/YYYY");
	}

	if (!isEmptyVariable(reportDateTemp)) {
        secondPageContent["reportDate"]= moment(reportDateTemp).format("DD/MM/YYYY");
	}

	if (!isEmptyVariable(periodFromDateTemp) && !isEmptyVariable(periodToDateTemp) ) {
        secondPageContent["samplePeriod"]= moment(periodFromDateTemp).format("DD/MM/YYYY") +" - "+ moment(periodToDateTemp).format("DD/MM/YYYY");
	}

	if (!isEmptyVariable(reportRiskLevelTemp) ) {
        secondPageContent["reportRiskLevel"]= reportRiskLevelTemp;
		
	}
    return secondPageContent;
}

export const getThirdPageContent = () => {}
export const getFourthPageContent = () => {}
export const getFifthPageContent = (newObservationsArr) => {}

export const getHeaderFooterContent = (clientNameTemp,typeOfAuditTemp,companyNameTemp) => {
	let headerFooterContent = {};
	
	let clientName = ifEmptyReturnEmptyStr(clientNameTemp, "");
    headerFooterContent["clientName"] =clientName;
	
	let typeOfAudit = ifEmptyReturnEmptyStr(typeOfAuditTemp, "");
    headerFooterContent["typeOfAudit"] =typeOfAudit;

	let companyName = ifEmptyReturnEmptyStr(companyNameTemp, "");
    headerFooterContent["companyName"] =companyName;
	return headerFooterContent;
}