import React, { Component } from 'react'
import html2canvas from 'html2canvas';
import RegularDD from '../../../common/DropdownMenus/RegularDropdown';
import { isEmptyVariable } from '../../../common/Global/commonFunctions';
import RechartsBarChart from '../../../common/Recharts/BarChart';
import RechartsPieChart from '../../../common/Recharts/PieChart';
import RechartsLineChart from '../../../common/Recharts/LineChart';
import RechartsScatterChart from '../../../common/Recharts/ScatterChart';
let fixedName = '';

 

class ChartWrapper extends Component {
  render () {
    return (
      <div className='mt-4' style={{ height: 350 }}>{this.props.children}</div>
    )
  }
}

export default class DashboardDisplayChart extends Component {
  constructor(props) {
      super(props);
      this.state = {
        componentDidMountFlag: false,
        isShowPercentage: ["ProcessAndClauseBase","ProcessAndFunctionBase","BUBased","ProcessBase","auditCycleCompliance"].includes(this.props.fixedName),
        chartName:"",
        options: {},
        barChartData: {
          complianceLevel: {},
          auditLevel: {},
        },
        pieChartData: [],
    }
  }

  componentDidMount() {
    this.generateChart(this.props.datalist);
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.generateChart(this.props.datalist);
    }
  }

  saveCanvas = async() => {
    const element = document.getElementById('chart'),
    canvas = await html2canvas(element),
    data = canvas.toDataURL(),
    link = document.createElement('a');
    link.href = data;
    link.download = String(this.props.criteriaPlaceholder).replace(" ","_")+'.png';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  generateChart = (datalist)=>{
    // bar chart data
    const barChartData = {
      complianceLevel: {
        xAxixDataKey: "name",
        yAxisKeys: new Set(),
        data: [],
      },
      auditLevel: {
        xAxixDataKey: "name",
        yAxisKeys: new Set(),
        data: [],
      },
    };

    // pie chart data
    const pieChartData = [];

    if(datalist.length>0){
      fixedName = this.props.fixedName;
      if(!["clauseWithNcrTable","areaOfImpovementTable","masterSheet"].includes(fixedName)){
        this.setState({
          componentDidMountFlag:false
        })

        datalist.forEach(item => {
          const barAuditData = {
            name: item.name,
          };

          item.result.forEach(res => {
            barChartData.auditLevel.yAxisKeys.add(res.value);
            barAuditData[res.value] = res.total;
          });
          barChartData.auditLevel.data.push(barAuditData);
          pieChartData.push({
            name: item.name,
            data: item.result,
            dataKey: "total",
            nameKey: "value",
          });

          // complianceLevel data
          if (item.complianceLevel) {
            const barComplianceData = {
              name: item.name,
            };

            item.complianceLevel.forEach(res => {
              barChartData.complianceLevel.yAxisKeys.add(res.value);
              barComplianceData[res.value] = res.total;
            });
            
            barChartData.complianceLevel.data.push(barComplianceData);
          }
        });

        barChartData.auditLevel.yAxisKeys = Array.from(barChartData.auditLevel.yAxisKeys).map(key => ({ key }));
        if (barChartData.complianceLevel.yAxisKeys) barChartData.complianceLevel.yAxisKeys = Array.from(barChartData.complianceLevel.yAxisKeys).map(key => ({ key }));
      }
    }
    barChartData.auditLevel.data = this.sortTableRows(barChartData.auditLevel.data, this.props.sortColumnNameAudit, this.props.sortOrderAudit);
    barChartData.complianceLevel.data = this.sortTableRows(barChartData.complianceLevel.data, this.props.sortColumnNameAuditCompliance, this.props.sortOrderAuditCompliance);
    
    this.setState({
      componentDidMountFlag:true,
      chartName:this.props.chartName,
      barChartData: barChartData,
      pieChartData: pieChartData,
    });
  }
  
  sortTableRows = (data, columnName, sortOrder) => {
    data.sort((a, b) => {
      if (a[columnName] > b[columnName]) {
        return sortOrder === "asc" ? 1 : -1;
      }
      else {
        return sortOrder === "asc" ? -1 : 1
      }
    });
    if (this.props.formulaArrPlaceholder === "Top" ) {
      const uniqueKeys = [...new Set(data.flatMap(obj => Object.keys(obj)).filter(key => key !== 'name'))];
      data.sort((a, b) => b[uniqueKeys[0]] - a[uniqueKeys[0]]);
      
      if(!isEmptyVariable(this.props.datalisTopBottomCount)){
        data = data.slice(0, this.props.datalisTopBottomCount);
      }
      
    }
    else if (this.props.formulaArrPlaceholder === "Bottom" ) {
      const uniqueKeys = [...new Set(data.flatMap(obj => Object.keys(obj)).filter(key => key !== 'name'))];
      data.sort((a, b) => a[uniqueKeys[0]] - b[uniqueKeys[0]]);
      
      if(!isEmptyVariable(this.props.datalisTopBottomCount)){
        data = data.slice(0, this.props.datalisTopBottomCount);

      }

      
    }
    return data;
  }

  render() {
    return (
      this.props.isDisplay &&
     <div className="card-1 h-100 overflow-auto" style={{ maxHeight: "calc(100vh - 315px)", }}>
        <div className="card-body">
          <div className="d-flex" style={{ gap: 15 }}>
            <div className='ml-auto' style={{ width:"150px" }}>
              <RegularDD
                  placeholder={this.props.selectedChartDisplayType}
                  dropdownArr={this.props.displayChartTypeArr}
                  labelParam="label"
                  onDropDownItemClick={this.props.displayChartTypeDropdownItemClick}
              />
            </div>
            {
                fixedName!=="masterSheet"
                ? <div className='d-inline-block'>
                    <a onClick={this.saveCanvas} className='btn-download-icon line-height-1'>
                      <span className="material-icons" style={{ fontSize: 20 }}>download</span>
                    </a>
                  </div>
                : null
              }
          </div>
          
          <div id="chart"align='center' className='mt-2' >
            { this.state.chartName === "ColumnChart" 
              ? 
                <React.Fragment>
                  { this.state.barChartData.auditLevel?.data?.length > 0 &&
                    <ChartWrapper>
                      <RechartsBarChart
                        data={this.state.barChartData.auditLevel.data}
                        xAxixDataKey={this.state.barChartData.auditLevel.xAxixDataKey}
                        yAxisKeys={this.state.barChartData.auditLevel.yAxisKeys}
                        isShowPercentage={this.state.isShowPercentage}
                      />
                    </ChartWrapper> 
                  }
                  { this.state.barChartData.complianceLevel?.data?.length > 0 &&
                    <ChartWrapper>
                      <RechartsBarChart
                        data={this.state.barChartData.complianceLevel.data}
                        xAxixDataKey={this.state.barChartData.complianceLevel.xAxixDataKey}
                        yAxisKeys={this.state.barChartData.complianceLevel.yAxisKeys}
                        isShowPercentage={true}
                      />
                    </ChartWrapper> 
                  }
                </React.Fragment>
              : null
            }

            { this.state.chartName === "LineChart" 
              ? 
                <React.Fragment>
                  { this.state.barChartData.auditLevel?.data?.length > 0 &&
                    <ChartWrapper>
                      <RechartsLineChart
                        data={this.state.barChartData.auditLevel.data}
                        xAxixDataKey={this.state.barChartData.auditLevel.xAxixDataKey}
                        yAxisKeys={this.state.barChartData.auditLevel.yAxisKeys}
                        isShowPercentage ={this.state.isShowPercentage}
                      />
                    </ChartWrapper> 
                  }
                  { this.state.barChartData.complianceLevel?.data?.length > 0 &&
                    <ChartWrapper>
                      <RechartsLineChart
                        data={this.state.barChartData.complianceLevel.data}
                        xAxixDataKey={this.state.barChartData.complianceLevel.xAxixDataKey}
                        yAxisKeys={this.state.barChartData.complianceLevel.yAxisKeys}
                        isShowPercentage={true}
                      />
                    </ChartWrapper> 
                  }
                </React.Fragment>
              : null
            }

            { this.state.chartName === "ScatterChart" 
              ? 
                <React.Fragment>
                  { this.state.barChartData.auditLevel?.data?.length > 0 &&
                    <ChartWrapper>
                      <RechartsScatterChart
                        data={this.state.barChartData.auditLevel.data}
                        xAxixDataKey={this.state.barChartData.auditLevel.xAxixDataKey}
                        yAxisKeys={this.state.barChartData.auditLevel.yAxisKeys}
                      />
                    </ChartWrapper> 
                  }
                  { this.state.barChartData.complianceLevel?.data?.length > 0 &&
                    <ChartWrapper>
                      <RechartsScatterChart
                        data={this.state.barChartData.complianceLevel.data}
                        xAxixDataKey={this.state.barChartData.complianceLevel.xAxixDataKey}
                        yAxisKeys={this.state.barChartData.complianceLevel.yAxisKeys}
                        isShowPercentage={true}
                      />
                    </ChartWrapper> 
                  }
                </React.Fragment>
              : null
            }

            { this.props.fixedName === "AuditCycleBase" && this.state.chartName === "PieChart" && this.state.pieChartData.length > 0
              ? this.state.pieChartData.map((pieChart, i) => (
                  <React.Fragment key={pieChart.name}>
                    <ChartWrapper>
                      <RechartsPieChart chartData={pieChart} />
                    </ChartWrapper>
                    <p>{pieChart.name}</p>
                  </React.Fragment>
                ))
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}
