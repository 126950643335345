import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { GetOrderedMasterTabMenuExecute, getLocalStorageVariables, getThemeSettingsVariables } from "../../../../common/Global/commonFunctions";
import Topbar from "../../../../common/Topbar";
import MasterMenuTabs from "../../Common/topmenu";
import Sidebar from "../../../../common/Sidebar";
import * as Constants from '../../../../common/Global/constants';
import OverallAssignmentComponent from '../../../../common/OverallAssignment';
import TopmenuReports from "../../../../common/TopmenuReports";

const themeSettings  = getThemeSettingsVariables();
const userDetails = getLocalStorageVariables();

export default class FinalizedOverallAssignment extends React.PureComponent {
    constructor(props) {
		super(props);
		this.state = {
            assignmentId: this.props.match.params.id,
            showLoader: false,
            componentDidMountFlag: false,
            menus: [],
        }
    }

    componentDidMount() {
		this.getInitData();
	}

    getInitData = () => {
        this.setState({
            showLoader: true,
        });
        Promise.all([
            fetch(Constants.GetUserMenu, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                }),
            }),
        ])
        .then(([menuRes]) => {
            return Promise.all([menuRes.json()]);
        })
        .then(([menuRes]) => {
            let menusTemp = {};
            if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                menusTemp = menuRes.data.menus;
            }

            let menusTemp2 = GetOrderedMasterTabMenuExecute(menusTemp);
            
            this.setState({
                showLoader: false,
                componentDidMountFlag: true,
                menus: menusTemp2,
            });
        });
    }

    handleBackBtnClick = () => {
		this.props.history.push(Constants.URL_FINAL_OBSERVATIONS+ "/" + this.state.assignmentId);
	}

    render () {
        return (
            <Router>
                <main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />
                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <div className="d-flex align-items-center">
									<div className="flex-fill">
										<MasterMenuTabs
											assignmentId={this.state.assignmentId}
											masterMenus={this.state.menus}
											themeSettings={themeSettings}
										/>
									</div>
								</div>
                                {
                                    this.state.showLoader &&
                                    <div className="loading-layout"
                                        style={{background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`}}>
                                        <div className="loading-text-layout">
                                            <p style={{color:`rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`}}>Loading....</p>
                                        </div>
                                    </div>
                                }
                                <div className="search-addnew-bg">
									<div className="search-addnew">
                                        <div className="flex-center-layout secondary-top-bar" style={{marginTop:0}}>
                                            <div className="back-btn-layout" onClick={this.handleBackBtnClick}>
                                                <span class="material-icons-outlined">arrow_back_ios</span>
                                            </div>
                                            <h6>Overall</h6>
                                        </div>
									</div>
								</div>
                                {
                                    this.state.componentDidMountFlag &&
                                    <OverallAssignmentComponent
                                        assignmentId={this.state.assignmentId}
                                        isEditable={false}
                                        getDatalistApi={Constants.GetDraftAssignmentLevelMasterData}
                                        saveDatalistApi={Constants.SaveDraftAssignmentLevelTemplateValues}
                                    />
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </Router>
        )
    }
}
