import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, 
    isEmptyArray,
    ifEmptyReturnEmptyStr} from '../../../common/Global/commonFunctions';
import {DialogSaveButton,TreeNodeCheckBox} from '../../../common/Global/globalStyles.style';
import {Modal} from 'react-bootstrap';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import AlertDialog from '../../../common/AlertDialog';
import ReactSelectDropdown from '../../../common/DropdownMenus/ReactSelectDropdownWithSearch';
import { toast } from 'react-toastify';
const selectedUserPlaceholder = "Select User";

const userDetails  = getLocalStorageVariables();
class AssignResources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            datalist:[],
            totalCount:0,
            currentPageNo: 1,
            // formErrors:{},
            reviewCycleReq:"Y",

            selectedL0Ids:[],
            selectedViewIds:[],
            
            selectedL1User:"Select User",
            selectedL2User:"Select User",
            selectedL3User:"Select User",
            selectedL4User:"Select User",
            selectedL5User:"Select User",
            selectedL6User:"Select User",
            selectedL7User:"Select User",
            createResourceResponse:"",
            
        }
    }

    componentDidMount(){
        if(this.props.isEdit){
            this.setState({
                componentDidMountFlag:false,
                showLoader:false,
                datalist:[],
                totalCount:0,
                currentPageNo: 1,
                // formErrors:{},

                selectedL0Ids:[],
                selectedViewIds:[],
                
                selectedL1User:"Select User",
                selectedL2User:"Select User",
                selectedL3User:"Select User",
                selectedL4User:"Select User",
                selectedL5User:"Select User",
                selectedL6User:"Select User",
                selectedL7User:"Select User",
                createResourceResponse:"",
                reviewCycleReq:ifEmptyReturnEmptyStr(this.props.reviewAssignmentTask,"Y")
            },()=>{
                this.getEditInitData();
            })
        }else{
            this.setState({
                componentDidMountFlag:false,
                showLoader:false,
                datalist:[],
                totalCount:0,
                currentPageNo: 1,
                // formErrors:{},

                selectedL0Ids:[],
                selectedViewIds:[],
                
                selectedL1User:"Select User",
                selectedL2User:"Select User",
                selectedL3User:"Select User",
                selectedL4User:"Select User",
                selectedL5User:"Select User",
                selectedL6User:"Select User",
                selectedL7User:"Select User",
                createResourceResponse:"",
                reviewCycleReq:ifEmptyReturnEmptyStr(this.props.reviewAssignmentTask,"Y")
            },()=>{
                this.getInitData();
            })
        }
    }

    componentDidUpdate(prevProps){
        // if(JSON.stringify(prevProps) !== JSON.stringify(this.props) ){

        //     if(this.props.isEdit){
        //         this.setState({
        //             componentDidMountFlag:false,
        //             showLoader:false,
        //             datalist:[],
        //             totalCount:0,
        //             currentPageNo: 1,
        //             formErrors:{},
    
        //             selectedL0Ids:[],
        //             selectedViewIds:[],
    
        //             selectedL1User:"Select User",
        //             selectedL2User:"Select User",
        //             selectedL3User:"Select User",
        //             selectedL4User:"Select User",
        //             selectedL5User:"Select User",
        //             selectedL6User:"Select User",
        //             selectedL7User:"Select User",
        //         },()=>{
        //             this.getEditInitData();
        //         })
        //     }else{
        //         this.setState({
        //             componentDidMountFlag:false,
        //             showLoader:false,
        //             datalist:[],
        //             totalCount:0,
        //             currentPageNo: 1,
        //             formErrors:{},
    
        //             selectedL0Ids:[],
        //             selectedViewIds:[],
    
        //             selectedL1User:"Select User",
        //             selectedL2User:"Select User",
        //             selectedL3User:"Select User",
        //             selectedL4User:"Select User",
        //             selectedL5User:"Select User",
        //             selectedL6User:"Select User",
        //             selectedL7User:"Select User",
        //         },()=>{
        //             this.getInitData();
        //         })
        //     }
        // }
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    getEditInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetFollowUpAssignedResources,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                })
            }),
            fetch(Constants.AssignResourceMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                })
            })
        ])
        .then(([assignedRes,masterRes]) => { 
            return Promise.all([assignedRes.json(),masterRes.json()]) 
        })
        .then(([assignedRes,masterRes]) => { 
            let datalist =[];
            let assignmentReviewMatrix =[];
            let assignmentMemberMatrix =[];
            let assignmentViewMatrix =[];

            if(assignedRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignedRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(assignedRes.responseCode === Constants.CODE_SUCCESS){
                assignmentMemberMatrix = assignedRes.data.assignmentMemberMatrix;
                assignmentReviewMatrix = assignedRes.data.assignmentReviewMatrix;
                assignmentViewMatrix = assignedRes.data.assignmentViewMatrix;
            }else{
            }

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                datalist = masterRes.data.users;
            }else{
            }

            //Lets modify the datalist
            let selectedL0Ids = assignmentMemberMatrix.map(resItem => {
                return parseInt(resItem.userId);
            }) 

            let datalistNew = datalist.map(item=>{
                let itemNew = item;
                for (const [index, value] of assignmentMemberMatrix.entries()) {
                    if(value.userId+"" === item.userId+""){
                        itemNew.isSelected = true;
                        itemNew.position = "TM";
                    }
                }
                return itemNew;
            })
            //Lets modify the datalist
            let selectedViewIds = assignmentViewMatrix.map(resItem => {
                return parseInt(resItem.userId);
            }) 
            let datalistNew2 = datalistNew.map(item=>{
                let itemNew = item;
                for (const [index, value] of assignmentViewMatrix.entries()) {
                    if(value.userId+"" === item.userId+""){
                        itemNew.isSelected = true;
                        itemNew.position = "View";
                    }
                }
                return itemNew;
            })

            let selectedReviewUsers = {}

            let finalDataList = [];

            if(isEmptyArray(assignmentReviewMatrix)){
                finalDataList = datalistNew2;
            }else{
                for(const [index,value] of assignmentReviewMatrix.entries()){
                    selectedReviewUsers["selectedL"+value.level+"User"] = value.fullName;
                    finalDataList = this.changeMainArray(datalistNew2,true,"L"+value.level,value.userId)
                }
            }
            
            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:finalDataList,
                selectedL0Ids:selectedL0Ids,
                selectedViewIds:selectedViewIds,
                ...selectedReviewUsers
            })
        })
    }

    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.AssignResourceMasterData,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(
            {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                assignmentId:this.props.assignmentId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    datalist:data.data.users,
                    showLoader:false,
                    componentDidMountFlag:true
                })
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    componentDidMountFlag:true
                })
            }
        });
    }

    handleSelectionReviewCycle = (e) => {
        let isChecked = e.target.checked;

        this.setState({
            reviewCycleReq:isChecked?"Y":"N",
        },()=>{
            //TODO API CALL - updatereviewassignmenttask

            fetch(Constants.UpdateReviewAssignmentTask,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        reviewAssignmentTask:this.state.reviewCycleReq,
                        assignmentId:this.props.assignmentId
                    })
                })
                .then(response => { return response.json(); } )
                .then(data =>
                {
                    if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                        localStorage.clear();
                        window.location="/";
                    }else if(data.responseCode === Constants.CODE_SUCCESS){
                    }else{
                        this.setState({
                            showAlertDialogInfo:true,
                            alertDialogMessageInfo:data.responseMessage
                        })
                    }
                });
        })
    }

    handleCreateMaster = () => {
        let assignmentReviewMatrix = {};
        let assignmentMemberMatrix = [];
        let assignmentViewMatrix = [];
        let atLeastOneUserSelected  = false;
        // let formErrorsTemp = {}

        // console.log(JSON.stringify(this.state.datalist))

        this.state.datalist.forEach(item=>{
            if(item.isSelected && item.position === "TM"){
                atLeastOneUserSelected  = true;
                let obj = {
                    userId:item.userId,
                    userName:item.firstName+" "+item.lastName,
                    designation:item.designation
                }
                assignmentMemberMatrix.push(obj);
            }else if(item.isSelected && item.position === "View"){
                atLeastOneUserSelected  = true;
                let obj = {
                    userId:item.userId,
                    userName:item.firstName+" "+item.lastName,
                    designation:item.designation
                }
                assignmentViewMatrix.push(obj);
            }else if(item.isSelected){
                atLeastOneUserSelected  = true;
                let obj = {
                    userId:item.userId,
                    userName:item.firstName+" "+item.lastName,
                    designation:item.designation
                }
                assignmentReviewMatrix[item.position] = obj;
            }
        })

        if(!atLeastOneUserSelected){
            toast.warning("Please select atleast one user");
            // formErrorsTemp['atLeastOneUserSelected'] = "Please select atleast one user";
            // this.setState({
            //     formErrors:formErrorsTemp
            // })
        }else{
            // console.log(JSON.stringify(assignmentReviewMatrix))
            // console.log(JSON.stringify(assignmentMemberMatrix))
            // console.log(JSON.stringify(assignmentViewMatrix))

            fetch(Constants.AssignFollowUpResources,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        assignmentId:this.props.assignmentId,
                        followUpAssignmentReviewMatrix:JSON.stringify(assignmentReviewMatrix),
                        followUpAssignmentMemberMatrix:JSON.stringify(assignmentMemberMatrix),
                    })
                })
                .then(response => { return response.json(); } )
                .then(data =>
                {
                    if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                        localStorage.clear();
                        window.location="/";
                    }else if(data.responseCode === Constants.CODE_SUCCESS){
                        if(this.props.isEdit){
                            // this.setState({
                            //     createResourceResponse:"Resources updated successfully",
                            // })
                            toast.success("Resources updated successfully");
                            this.props.handleAllResourcesDialogClose();
                        }else{
                            // this.setState({
                            //     createResourceResponse:"Resources added successfully"
                            // })
                            toast.success("Resources added successfully");
                        }
                        this.props.handleAllResourcesDialogClose();
                    }else{
                        toast.error(data.responseMessage);
                        // formErrorsTemp['atLeastOneUserSelected'] = data.responseMessage;
                        // this.setState({
                        //     formErrors:formErrorsTemp
                        // })
                    }
                });
        }
    }

    // addSelection = (args) => {
    //     let selectedL0Ids = this.state.selectedL0Ids;
    //     let datalist  = [];
    //     if(!isEmptyVariable(args.itemData.userId))
    //     {
    //         selectedL0Ids.push(args.itemData.userId)
    //         datalist = this.changeMainArray(this.state.datalist,true,"TM",args.itemData.userId)
    //     }
    //     this.setState({
    //         selectedL0Ids:selectedL0Ids,
    //         datalist:datalist,
    //     })
    // }
    teamMemberSelection = (selectedItem) => {
        let selectedL0Ids =[];
        let datalist  = [];
        Object.values(this.state.datalist).map((item)=>{
            if(Object.values(selectedItem).includes(item))
            {
                selectedL0Ids.push(item)
                datalist=this.changeMainArray(this.state.datalist,true,"TM",item.userId)
            }
            else if((!isEmptyVariable(item.placeholder)&& item.placeholder=="TM")|| 
                    (!selectedL0Ids.includes(item) && Object.values(this.state.selectedL0Ids).includes(item)))
            {
                
                datalist=this.changeMainArray(this.state.datalist,false,"",item.userId)
            }
        })
       
        this.setState({
            selectedL0Ids:selectedL0Ids,
            datalist:datalist,
        })
    }
    viewMemberSelection = (selectedItem) => {
        let selectedViewIds =[];
        let datalist  = [];
        Object.values(this.state.datalist).map((item)=>{
            if(Object.values(selectedItem).includes(item))
            {
                selectedViewIds.push(item)
                datalist=this.changeMainArray(this.state.datalist,true,"View",item.userId)
            }
            else if((!isEmptyVariable(item.placeholder)&& item.placeholder=="View")||
                 (!selectedViewIds.includes(item) && Object.values(this.state.selectedViewIds).includes(item)))
            {
                datalist=this.changeMainArray(this.state.datalist,false,"",item.userId)
            }
        })
       
        this.setState({
            selectedViewIds:selectedViewIds,
            datalist:datalist,
        })
    }

    // addSelectionView = (args) => {
    //     let selectedViewIds = this.state.selectedViewIds;
    //     let datalist  = [];
    //     if(!isEmptyVariable(args.itemData.userId))
    //     {
    //         selectedViewIds.push(args.itemData.userId)
    //         datalist = this.changeMainArray(this.state.datalist,true,"View",args.itemData.userId)
    //         // console.log(JSON.stringify(datalist))
    //     }
    //     this.setState({
    //         selectedViewIds:selectedViewIds,
    //         datalist:datalist,
    //     })
    // }



    // removeSelection = (args) => {
    //     let selectedL0Ids = this.state.selectedL0Ids;
    //     let datalist  = [];

    //     if(!isEmptyVariable(args.itemData.userId)){
    //         let idx = selectedL0Ids.indexOf(args.itemData.userId);
    //         if(idx !== -1){
    //             selectedL0Ids.splice(idx,1);
    //         }
    //         console.log("Before::"+JSON.stringify(this.state.datalist))
    //         datalist = this.changeMainArray(this.state.datalist,false,"",args.itemData.userId)
    //     }
    //     this.setState({
    //         selectedL0Ids:selectedL0Ids,
    //         datalist:datalist,
    //     })
    // }

    // removeSelectionView = (args) => {
    //     let selectedViewIds = this.state.selectedViewIds;
    //     let datalist  = [];

    //     if(!isEmptyVariable(args.itemData.userId)){
    //         let idx = selectedViewIds.indexOf(args.itemData.userId);
    //         if(idx !== -1){
    //             selectedViewIds.splice(idx,1);
    //         }

    //         datalist = this.changeMainArray(this.state.datalist,false,"",args.itemData.userId)
    //     }
    //     this.setState({
    //         selectedViewIds:selectedViewIds,
    //         datalist:datalist,
    //     })
    // }

    changeMainArray = (arr, flag, position,targetUserId) => {
        let newArr = arr.map((item)=>{
            if(item.userId === targetUserId){
                item.isSelected = flag;
                item.position = position;
            }else{
                if(item.position !== "" &&
                item.position === position &&
                item.position !== "View" &&
                item.position !== "TM"){
                    item.isSelected = !flag;
                    item.position = "";
                }
            }
            return item;
        })
        console.log(JSON.stringify(newArr))
        return newArr;
    }

    l1DropdownClick = (item) => {
        let datalist = [];
        if(!isEmptyVariable(item)){
            datalist=this.changeMainArray(this.state.datalist,true,"L1",item.userId)
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L1",'')
        }
        this.setState({
            datalist:datalist,
            selectedL1User:item?item.fullName:'',
        })
    }

    l2DropdownClick = (item) => {
        let datalist = [];
        if(!isEmptyVariable(item)){
            datalist=this.changeMainArray(this.state.datalist,true,"L2",item.userId)
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L2",'')
        }
        this.setState({
            datalist:datalist,
            selectedL2User:item?item.fullName:'',
        })
    }

    l3DropdownClick = (item) => {
        let datalist = [];
        if(!isEmptyVariable(item)){
            datalist = this.changeMainArray(this.state.datalist,true,"L3",item.userId)
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L3",'')
        }
        this.setState({
            datalist:datalist,
            selectedL3User:item?item.fullName:'',
        })
    }

    l4DropdownClick = (item) => {
        let datalist = [];
        if(!isEmptyVariable(item)){
            datalist = this.changeMainArray(this.state.datalist,true,"L4",item.userId)
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L4",'')
        }
        this.setState({
            datalist:datalist,
            selectedL4User:item?item.fullName:'',
        })
    }

    l5DropdownClick = (item) => {
        let datalist = [];
        if(!isEmptyVariable(item)){
            datalist = this.changeMainArray(this.state.datalist,true,"L5",item.userId)
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L5",'')
        }
        this.setState({
            datalist:datalist,
            selectedL5User:item?item.fullName:'',
        })
    }

    l6DropdownClick = (item) => {
        let datalist = this.changeMainArray(this.state.datalist,true,"L6",item.userId)
        this.setState({
            datalist:datalist,
            selectedL6User:item.fullName,
        })
    }

    l7DropdownClick = (item) => {
        let datalist = this.changeMainArray(this.state.datalist,true,"L7",item.userId)
        this.setState({
            datalist:datalist,
            selectedL7User:item.fullName,
        })
    }

    getL0Users = () => {
        let datalist = this.state.datalist;
        //filter
        let filteredArr = datalist.filter(item => item.isSelected !== true);
        return filteredArr;
    }

    getOtherUsers = (position) => {
        let datalist = this.state.datalist;
        //filter
        let filteredArr = datalist.filter(item => item.isSelected !== true 
            || (item.isSelected === true && item.position === position));
        return filteredArr;
    }

    render() {
        let fields = {text:"fullName",value:"userId"}

        // console.log(JSON.stringify(this.state.datalist))
        let TeamMembersList  = this.state.datalist.filter(item=>item.isSelected !== true
            || (item.isSelected === true && item.position === "TM"))
        // console.log("TM"+JSON.stringify(TeamMembersList))

        let ViewList  = this.state.datalist.filter(item=>item.isSelected !== true
            || (item.isSelected === true && item.position === "View"))
        // console.log(JSON.stringify(ViewList))

        let L1List  = this.getOtherUsers("L1");
        let L2List  = this.getOtherUsers("L2");
        let L3List  = this.getOtherUsers("L3");
        let L4List  = this.getOtherUsers("L4");
        let L5List  = this.getOtherUsers("L5");
        let L6List  = this.getOtherUsers("L6");
        let L7List  = this.getOtherUsers("L7");
        

        return(
            
            <div className="modal-body">
                {
                    this.state.showLoader &&
                    <div className="modal-loading-text">
                        <p>Loading....</p>
                    </div>
                }
                
                {
                    this.state.componentDidMountFlag &&
                    <div class="addnew-modal-form">
                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Team Members</p>
                                    {/* <MultiSelectComponent 
                                        id="unitsCheckbox"
                                        dataSource={TeamMembersList}
                                        fields={fields}
                                        placeholder="Select Users"
                                        mode="CheckBox"
                                        closePopupOnSelect={false}
                                        allowFiltering={false}
                                        select={this.addSelection}
                                        removed={this.removeSelection}
                                        cssClass="clientsChecklist dialogcl"
                                        value={this.state.selectedL0Ids}
                                        showDropDownIcon={true}
                                        showClearButton={false}
                                    >
                                        <Inject services={[CheckBoxSelection]} />
                                    </MultiSelectComponent> */}

                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.teamMemberSelection}
                                        selectedDDObj= {this.state.selectedL0Ids}
                                        selectDropdownArr={TeamMembersList}
                                        label={"fullName"}
                                        value={"userId"}
                                        isMultiselect = {true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Team Leader</p>
                                    {/* <RegularDropdown 
                                        placeholder={this.state.selectedL1User}
                                        dropdownArr={L1List}
                                        labelParam="fullName"
                                        onDropDownItemClick={this.l1DropdownClick}
                                        defaultPlaceholderDropDown={selectedUserPlaceholder}
                                    /> */}
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.l1DropdownClick}
                                        selectDropdownArr={L1List}
                                        label={"fullName"}
                                        value={"userId"}
                                        placeholder={this.state.selectedL1User}
                                        isClearSelect={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Review L1</p>
                                    {/* <RegularDropdown 
                                        placeholder={this.state.selectedL2User}
                                        dropdownArr={L2List}
                                        labelParam="fullName"
                                        onDropDownItemClick={this.l2DropdownClick}
                                        defaultPlaceholderDropDown={selectedUserPlaceholder}
                                    /> */}
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.l2DropdownClick}
                                        selectDropdownArr={L2List}
                                        label={"fullName"}
                                        value={"userId"}
                                        placeholder={this.state.selectedL2User}
                                        isClearSelect={true}
                                    />
                                </div>

                            </div>
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Review L2</p>
                                    {/* <RegularDropdown 
                                        placeholder={this.state.selectedL3User}
                                        dropdownArr={L3List}
                                        labelParam="fullName"
                                        onDropDownItemClick={this.l3DropdownClick}
                                        defaultPlaceholderDropDown={selectedUserPlaceholder}
                                    /> */}
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.l3DropdownClick}
                                        selectDropdownArr={L3List}
                                        label={"fullName"}
                                        value={"userId"}
                                        placeholder={this.state.selectedL3User}
                                        isClearSelect={true}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Review L3</p>
                                    {/* <RegularDropdown 
                                        placeholder={this.state.selectedL4User}
                                        dropdownArr={L4List}
                                        labelParam="fullName"
                                        onDropDownItemClick={this.l4DropdownClick}
                                        defaultPlaceholderDropDown={selectedUserPlaceholder}
                                    /> */}
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.l4DropdownClick}
                                        selectDropdownArr={L4List}
                                        label={"fullName"}
                                        value={"userId"}
                                        placeholder={this.state.selectedL4User}
                                        isClearSelect={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Review L4</p>
                                    {/* <RegularDropdown 
                                        placeholder={this.state.selectedL5User}
                                        dropdownArr={L5List}
                                        labelParam="fullName"
                                        onDropDownItemClick={this.l5DropdownClick}
                                        defaultPlaceholderDropDown={selectedUserPlaceholder}
                                    /> */}
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.l5DropdownClick}
                                        selectDropdownArr={L5List}
                                        label={"fullName"}
                                        value={"userId"}
                                        placeholder={this.state.selectedL5User}
                                        isClearSelect={true}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Review L5</p>
                                    <RegularDropdown 
                                        placeholder={this.state.selectedL6User}
                                        dropdownArr={L6List}
                                        labelParam="fullName"
                                        onDropDownItemClick={this.l6DropdownClick}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Review L6</p>
                                    <RegularDropdown 
                                        placeholder={this.state.selectedL7User}
                                        dropdownArr={L7List}
                                        labelParam="fullName"
                                        onDropDownItemClick={this.l7DropdownClick}
                                    />
                                </div>
                            </div>
                        </div> */}
                        
                        <div className="addnew-modal-form-group">
                            <p>View</p>
                            {/* <MultiSelectComponent 
                                id="unitsCheckbox"
                                dataSource={ViewList}
                                fields={fields}
                                placeholder="Select Users"
                                mode="CheckBox"
                                closePopupOnSelect={false}
                                allowFiltering={false}
                                select={this.addSelectionView}
                                removed={this.removeSelectionView}
                                cssClass="clientsChecklist dialogcl"
                                value={this.state.selectedViewIds}
                                showDropDownIcon={true}
                                showClearButton={false}
                            >
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent> */}
                             <ReactSelectDropdown
                                        handleReactSelectClose={this.viewMemberSelection}
                                        selectedDDObj= {this.state.selectedViewIds}
                                        selectDropdownArr={ViewList}
                                        label={"fullName"}
                                        value={"userId"}
                                        isMultiselect = {true}
                                    />
                        </div>

                        {
                            !isEmptyVariable(this.state.createResourceResponse) &&
                            <span class="cm-error">{this.state.createResourceResponse}</span>
                        }
                        {/* {
                            this.state.formErrors['atLeastOneUserSelected'] &&
                            <span class="cm-error">{this.state.formErrors["atLeastOneUserSelected"]}</span>
                        } */}
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.props.handleAllResourcesDialogClose} type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.handleCreateMaster} type="button">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>
                }
                <AlertDialog 
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={this.props.themeSettings}
                />
            </div>
        );
    }
}

export default AssignResources;