import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import * as Constants from "../../../../common/Global/constants";
import Sidebar from "../../../../common/SidebarCollapse";
import Topbar from "../../../../common/Topbar";
import { getLocalStorageVariables, getThemeSettingsVariables, isEmptyArray, isEmptyVariable } from "../../../../common/Global/commonFunctions";
import FilterDropDown from "../../../../common/DropdownMenus/FilterDropdown";
import RegularDD from '../../../../common/DropdownMenus/RegularDropdown';
import moment from "moment";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const filterOpenCloseDropDownArr = [
    {
        label: "Checked & Open",
        isSelected: true,
    },
    {
        label: "Close",
        isSelected: false,
    },
];

class CompletedFollowUpObservation extends Component {
    constructor(props) {
        super(props);
        const item = props.location.state;
        this.state = {
            assignmentId: props.match.params.id,
            departmentId: item.departmentId,
            observationTemplateId: item.observationTemplateId,
            observationTaskFilterDropDownArr: item.observationTaskFilterDropDownArr,
            observationTaskFilterPlaceholder: item.observationTaskFilterPlaceholder,

            componentDidMountFlag: false,
			showLoader: false,
			datalist: [],

            followUpObservation:[],
            filterOpenCloseDropDownArr:filterOpenCloseDropDownArr,
            filterOpenClosePlaceholder: "Open",
        }
    }

    componentDidMount() {
        this.getInitData();
	}

    observationFilterDropDownOnClicks = (selectedItem) => {
        let tempObservationFilterDataArray = this.state.observationTaskFilterDropDownArr;
        Object.values(tempObservationFilterDataArray).map((item) => {
            if (selectedItem.label === item.label) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });

        if(selectedItem.label === "Task"){
            this.props.history.replace(Constants.URL_COMPLETED_TASK + "/" + this.state.assignmentId,{
                departmentId:this.state.departmentId,
                observationTemplateId:this.state.observationTemplateId,
                observationTaskFilterDropDownArr:tempObservationFilterDataArray,
                observationTaskFilterPlaceholder:selectedItem.label,
            });
        }
    }

    getRootLevelFunctions = (functionMapTemp) => {
        let rootFunctionArr = [];
        Object.values(functionMapTemp).map((item) => {
            if (item.level === 0) {
                rootFunctionArr.push(item);
            }
        });
        return rootFunctionArr;
    };

    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader: true,
        });

        Promise.all([
            fetch(Constants.GetFollowUpDraftReportHeadings, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                    departmentId: this.state.departmentId,
                    donotReport: "N",
                }),
            }),
            fetch(Constants.GetAssignmentsDetails, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                }),
            }),
            fetch(Constants.GetAssignmentFollowUpObservations, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
            }),
        ])
        .then(([observationRes,assignmentRes, assignFollowUpObservation]) => {
            return Promise.all([observationRes.json(),assignmentRes.json(),assignFollowUpObservation.json()]);
        })
        .then(([observationRes,assignmentRes,assignFollowUpObservation]) => {
            let headingMap = [];
            let rootFunctionArr = [];
            let assignObservation = {};   
            let assignmentDetails = {};

            if (
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (observationRes.responseCode === Constants.CODE_SUCCESS) {
                headingMap = observationRes.result.headingMap;
                rootFunctionArr = this.getRootLevelFunctions(headingMap);
            } else {
            }

            if (
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                assignmentDetails = assignmentRes.data.assignmentDetails;
            } else {
            }

            let followUpObservationTemp = [];
            if(
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ){
                localStorage.clear();
                window.location = "/";
            } else if(assignFollowUpObservation.responseCode === Constants.CODE_SUCCESS) {
                followUpObservationTemp = assignFollowUpObservation.data.followUpObservations;
                followUpObservationTemp.forEach(item=>{
                    assignObservation[item.observationId] = item;
                })
                            
                let datalistTemp = {};  
                rootFunctionArr.map(item => {
                    let updatedTaskList = [];
                    item.obsList.forEach(observation=>{
                        if(!isEmptyVariable(assignObservation[observation.observationId])){
                            const key = observation.observationId;
                            observation.resourceName = isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName;
                            observation.resourceId = isEmptyVariable(assignObservation[key].userId)?"":assignObservation[key].userId;
                            observation.resourcefullName = (isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName)+"("+(isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation)+")";
                            observation.resourceDesignation = isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation;
                        }
                        updatedTaskList.push(observation);
                    });
                    item.obsList = updatedTaskList;
                    datalistTemp[item.nodeId] = item;
                })
            }

            this.setState({
                componentDidMountFlag: true,
                showLoader: false,
                datalist: rootFunctionArr,
                followUpObservation:followUpObservationTemp,
                assignmentDetails: assignmentDetails,
            });
        });
    }

    getObservationList = () => {
        this.setState({
			showLoader: true,
		});

        Promise.all([
            fetch(Constants.GetFollowUpDraftReportHeadings, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                    departmentId: this.state.departmentId,
                    donotReport: "N",
                }),
            }),
            fetch(Constants.GetAssignmentFollowUpObservations, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
            }),
        ])
        .then(([observationRes,assignFollowUpObservation]) => {
            return Promise.all([observationRes.json(),assignFollowUpObservation.json()]);
        })
        .then(([observationRes,assignFollowUpObservation]) => {
            let headingMap = [];
            let rootFunctionArr = [];
            let assignObservation = {};

            if (
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (observationRes.responseCode === Constants.CODE_SUCCESS) {
                headingMap = observationRes.result.headingMap;
                rootFunctionArr = this.getRootLevelFunctions(headingMap);
            } else {
            }

            let followUpObservationTemp = [];
            if(
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ){
                localStorage.clear();
                window.location = "/";
            } else if(assignFollowUpObservation.responseCode === Constants.CODE_SUCCESS) {
                followUpObservationTemp = assignFollowUpObservation.data.followUpObservations;
                followUpObservationTemp.forEach(item=>{
                    assignObservation[item.observationId] = item;
                })
                            
                let datalistTemp = {};
                rootFunctionArr.map(item => {
                    let updatedTaskList = [];
                    item.obsList.forEach(observation=>{
                        if(!isEmptyVariable(assignObservation[observation.observationId])){
                            const key = observation.observationId;
                            observation.resourceName = isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName;
                            observation.resourceId = isEmptyVariable(assignObservation[key].userId)?"":assignObservation[key].userId;
                            observation.resourcefullName = (isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName)+"("+(isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation)+")";
                            observation.resourceDesignation = isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation;
                        }
                        updatedTaskList.push(observation);
                    });
                    item.obsList = updatedTaskList;
                    datalistTemp[item.nodeId] = item;
                })
            }

            this.setState({
                datalist: rootFunctionArr,
                showLoader: false,
                followUpObservation:followUpObservationTemp,
            });
        })
    }
    
    /*******************API CALLS END HERE*******************/

     render() {
        let showEmptyCard = true;
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                        <div className="dashboard-right-bg-collpase">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.showLoader && (
                                    <div className="loading-layout" style={{ background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`, }}>
                                        <div className="loading-text-layout">
                                            <p style={{ color: `rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})` }}>
                                                Loading....
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <p className="m-b-0 font-bold" style={{marginRight:20}}>Follow Up</p>
                                {!isEmptyVariable(this.state.observationTemplateId) &&
                                    <div className="exe-followup-resource-dd flex-center-layout" style={{ marginRight: 10 }}>
                                        <FilterDropDown
                                            placeholder={this.state.observationTaskFilterPlaceholder}
                                            dropdownArr={this.state.observationTaskFilterDropDownArr}
                                            onDropDownItemClick={this.observationFilterDropDownOnClicks}
                                            themeSettings={themeSettings}
                                            name={"label"}
                                            dropdownWidth="200px"
                                        />
                                    </div>
                                }
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag && (
                                        <div className="body-wrapper common-table-scroll">
                                            {!isEmptyArray(this.state.datalist) && 
                                            this.state.datalist.map(item=>{
                                                return(
                                                    !isEmptyArray(item.obsList) &&
                                                    <div className="common-tab-bg mt-2">
                                                        <div className="common-table" style={{ width: "100%" }}>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr style={{height:"44px"}}>
                                                                        <th>Observation</th>
                                                                        <th style={{minWidth:"100px"}}>Resource</th> 
                                                                        <th style={{minWidth:"100px"}}>Follow Up Date</th>
                                                                        <th></th>
                                                                        <th width={"15%"}>Follow Up Risk</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {!isEmptyArray(item.obsList) &&
                                                                        item.obsList.map((observationItem, idx) => {
                                                                            showEmptyCard = false;
                                                                            let userDetails = this.state.followUpObservation.filter(e => e.observationId == observationItem.observationId); 
                                                                            return (
                                                                                <tr style={{ background: "#f5f5f5", height:"44px"}}>
                                                                                    <td>
                                                                                        {
                                                                                            !isEmptyVariable(observationItem.observationDetail)?<div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: observationItem.observationDetail}} />:""
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            isEmptyArray(userDetails)?"":(isEmptyVariable(userDetails[0].userName)+isEmptyVariable(userDetails[0].designation))?"":userDetails[0].userName+"("+userDetails[0].designation+")"
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            isEmptyArray(userDetails)?"":isEmptyVariable(userDetails[0].followUpDate)?"":moment(userDetails[0].followUpDate).format("DD/MM/YYYY")
                                                                                        }
                                                                                    </td>
                                                                                    <td style={{minWidth:"100px"}}>
                                                                                        <RegularDD
                                                                                            placeholder={observationItem.completionStatus==="Completed"&&(observationItem.allowReview!==Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS)?"Close":this.state.filterOpenClosePlaceholder}
                                                                                            dropdownArr={this.state.filterOpenCloseDropDownArr}
                                                                                            labelParam="label"
                                                                                            // onDropDownItemClick={this.filterOpenCloseDropDownOnClick.bind(this,observationItem.observationId)}
                                                                                            disabled={true}
                                                                                        />
                                                                                    </td>
                                                                                    {
                                                                                        !isEmptyVariable(this.state.assignmentDetails.riskId) &&
                                                                                        <td>
                                                                                            {
                                                                                                isEmptyVariable(observationItem.followUpRiskLevel)?
                                                                                                <div className="risk-level-color-wrapper" style={{ width: "110px",height:"30px", marginLeft:0 }}/>
                                                                                                :
                                                                                                <div>
                                                                                                    {isEmptyVariable(observationItem.followUpRiskLevel)?"":observationItem.followUpRiskLevel}
                                                                                                </div>
                                                                                            }
                                                                                        </td>
                                                                                    }
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {this.state.componentDidMountFlag && showEmptyCard && (
                                                <div class="no-items-layout">
                                                    <div class="no-items-card">
                                                        <h6>
                                                            {Constants.NO_RECORDS_WARNING}
                                                        </h6>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </Router>
        )
     }
}
export default CompletedFollowUpObservation;