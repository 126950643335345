import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import Pagination from '../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable, sortTable,
    getThemeSettingsVariables, isEmptyArray} from '../../common/Global/commonFunctions';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import AddEditState from "./addEditState";

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();

const sortState = "state";

class CitiesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            totalCount:0,
            datalist:[],
            resultSize:Constants.RESULT_SIZE,
            resultSizePlaceholder:Constants.RESULT_SIZE,
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            sort:"",
            sortDir:"asc",

            selectedState:"",

            // showAlertDialog:false,
            // operationType:"",
            // deleteReactivateId:"",
            // alertDialogMessage:"",

            // showAlertDialogInfo:false,
            // alertDialogMessageInfo:"",


            showCreateDialog:false,
            submitClickedAtleastOnce:false,
            isEdit:false,
            formErrors:{},


        }
    }

    componentDidMount(){
        this.getInitialData();
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    
    handleChange = (e) => {
        const { name, value } = e.target;
        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            if(name === "city"){
                FormErrorsTemp['city'] = isEmptyVariable(value)?"Please enter city name":"";
            }
        }

        this.setState({ 
            [name]: value,
            formErrors:FormErrorsTemp
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getStatesList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getStatesList();
            })
        }
    }

    handleCreateDialogClose = (reloadFlag) =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedState:"",
        },()=>{
            if(reloadFlag) this.getInitialData();
        });
    }

    // showCreateDialog = () => {
    //     this.setState({
    //         showCreateDialog:true,
    //         submitClickedAtleastOnce:false,
    //         isEdit:false,

    //         selectedCountry:"",
    //         selectedState:"",
    //         city:"",

    //         formErrors:{},
    //     });
    // }

    // handleAlertDialogClose = () => {
    //     this.setState({
    //         showAlertDialog:false,
    //         alertDialogMessage:""
    //     });
    // }

    // handleAlertDialogCloseInfo = () =>{
    //     this.setState({
    //         showAlertDialogInfo:false,
    //         alertDialogMessageInfo:""
    //     });
    // }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getStatesList();
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        // if(item.label === "Delete"){
        //     this.setState({
        //         operationType:item.label,
        //         deleteReactivateId:dropdownItem.cityId,
        //         showAlertDialog:true,
        //         alertDialogHeading:"Delete City",
        //         proceedBtnLabel:"Delete",
        //         alertDialogMessage:"Are you sure you want to delete City "+dropdownItem.city+"?"
        //     })
        // }else
         if(item.label === "Edit"){

            this.setState({
                showCreateDialog:true,
                submitClickedAtleastOnce:false,
                isEdit:true,

                selectedState:dropdownItem.state,
                // deleteReactivateId:dropdownItem.cityId,
                formErrors:{},
            });
        }
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getStatesList();
        })
    }

    /*******************API CALLS*******************/

    getInitialData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetStates,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    state:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            }),
            fetch(Constants.GetUserMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([stateListRes,masterRes]) => { 
            return Promise.all([stateListRes.json(),masterRes.json()]) 
        })
        .then(([stateListRes,masterRes]) => { 
            let dataList = [];
            let totalCount = 0;

            if(stateListRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                stateListRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(stateListRes.responseCode === Constants.CODE_SUCCESS){
                dataList = stateListRes.data.result;
                totalCount = stateListRes.data.count;
            }else{
                //TODO - show error message inside dialog if possible
            }

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                
            }else{
            }
            this.setState({

                datalist:dataList,
                totalCount:totalCount,
                showLoader:false,
                componentDidMountFlag:true,
            })

        });
    }

    getStatesList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetStates,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                state:this.state.searchkey,
                resultsize:this.state.resultSize,
                sort:this.state.sort,
                sortDir:this.state.sortDir
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetStates,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    state:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    // handleRecordOperation = () => {
    //     let url = "";
    //     let postParam = {
    //         email:userDetails.email,
    //         accessToken:userDetails.accessToken,
    //         cityId:this.state.deleteReactivateId,
    //     }

    //     // if(this.state.operationType === "Delete"){
    //     //     url = Constants.DeleteCity;
    //     // }

    //     this.setState({
    //         showLoader:true,
    //     });

    //     fetch(url,
    //     {
    //         method: "POST",
    //         mode:'cors',
    //         body: new URLSearchParams(postParam)
    //     })
    //     .then(response => { return response.json(); } )
    //     .then(data =>
    //     {
    //         if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
    //             data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
    //             localStorage.clear();
    //             window.location="/";
    //         }else if(data.responseCode === Constants.CODE_SUCCESS){
    //             this.setState({
    //                 deleteReactivateId:"",
    //                 operationType:"",
    //                 alertDialogHeading:"",
    //                 proceedBtnLabel:"",
    //                 alertDialogMessage:"",
    //                 showAlertDialog:false,
    //             },() => {
    //                 this.getStatesList();
    //             });
    //         }else{
    //             this.setState({
    //                 showLoader:false,
    //                 deleteReactivateId:"",
    //                 operationType:"",
    //                 alertDialogHeading:"",
    //                 proceedBtnLabel:"",
    //                 alertDialogMessage:"",
    //                 showAlertDialog:false,
    //                 showAlertDialogInfo:true,
    //                 alertDialogMessageInfo:data.responseMessage,
    //             })
    //         }
    //     });
    // }

    /*******************API CALLS END HERE*******************/

    render() {
        const actionArr = [
            {
                icon:"edit",
                label:"Edit"
            },
            
        ]
        
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.showLoader &&
                                    <div className="loading-layout"
                                    style={{background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`}}>
                                        <div className="loading-text-layout">
                                            <p
                                            style={{color:`rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`}}
                                            >Loading....</p>
                                        </div>
                                    </div>
                                }
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings = {themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                            className="material-icons"
                                                            themeSettings = {themeSettings}
                                                            onClick = {this.onSearchIconClick}>search</SearchSpan>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortState)}>
                                                                <div className="sort-header">State
                                                                <span className={(this.state.sort === sortState)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="text-center" width={"5%"}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {
                                                                return <tr >
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    <td>{item.state}</td>
                                                                    <td className="text-center">
                                                                        <TableDropDown 
                                                                            actionArr={actionArr}
                                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                            dropDownId = {item}
                                                                            themeSettings = {themeSettings}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div className="no-items-layout">
                                                <div className="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings = {themeSettings}
                                                    />
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete User"
                                :
                                "Activate User"
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete"
                                :
                                "Reactivate"
                            }
                            themeSettings = {themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings = {themeSettings}
                        /> */}

                        <AddEditState 
                            showCreateDialog={this.state.showCreateDialog}
                            handleCreateDialogClose={this.handleCreateDialogClose}
                            isEdit={this.state.isEdit}
                            themeSettings={themeSettings}
                            // city={this.state.city}
                            // selectedCountry= {this.state.selectedCountry}
                            selectedState= {this.state.selectedState}
                            // cityId={this.state.deleteReactivateId}
                        />

                    </section>
                </main>
            </Router>
        );
    }
}

export default CitiesList;