import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import { matchPath,withRouter } from 'react-router-dom';
import { isEmptyVariable } from '../../common/Global/commonFunctions';
import {TopTabsLayout} from '../../common/Global/globalStyles.style';
class ClientsTopMenu extends Component{
    constructor(props) {
        super(props);
    }

    usersOnClick = () => {
        this.props.usersTabItemOnClick(this.props.clientId)
    }

    clientsOnClick = () => {
        this.props.clientsTabItemOnClick();
    }

    render(){
        const {clientId,displayingClientUsers,displayingClients} = this.props;
        let width="12.5%";
        if(!isEmptyVariable(clientId)){
            width = "25%";
        }
        
        return(
            <TopTabsLayout themeSettings={this.props.themeSettings}>
            
                <div className="question-tabs"  style={{marginRight:0}}>
                    <ul style={{width:width}}>

                    {/* By default Clients must be displayed. if users tab is there then we need to
                    display both the tabls */}
                    <li className={displayingClients?"questiontab-active":""}>
                        <a onClick={this.clientsOnClick} href="javascript:void(0)">Clients</a>
                    </li>
                    {
                        !isEmptyVariable(clientId) && 
                        <li className={displayingClientUsers?"questiontab-active":""}>
                            <a onClick={this.usersOnClick} href="javascript:void(0)" >Users</a>
                        </li>
                    }
                    </ul>
                </div>

                <div onClick={this.props.toggleCardsDisplay}
                style={{opacity:this.props.hideInfo?"0":"1"}} className="infocard-showhide-col">
                    <div className="infocard-icon-col">
                        <img src="/assets/images/topbar-info.svg" />
                    </div>
                </div>
            </TopTabsLayout>
        )
    }
}

export default withRouter(ClientsTopMenu);