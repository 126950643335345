import { AlignmentType, Table, TableRow, TableCell, Paragraph, WidthType, BorderStyle, TextRun, ImageRun, VerticalAlign } from "docx";

import { isEmptyArray, isEmptyVariable } from "../../../common/Global/commonFunctions";
let fontName = "Times New Roman";
let commonFontSize= 17;
let tempTableRows =[]
const indexData = [
  "General Information",
  "Internal Audit Plan",
  "Internal Audit Summary Report",
  "Audit Conclusion",
  "Processes Audited",
  "Nonconformity Report",
];

  export const getgeneralInformationPageContent = (assignmentDetails,unitDetials,companyLogo,
    isLogoAvailable,finalEmployeeList,tempFunctionNameList,allAuditorList,allViwerList) => {
  let generalInformationContent = [];
  const cellBorderStyle={ style: BorderStyle.SINGLE, color: "000000", size: 3 };
  let unitName = String(assignmentDetails.unitAddressList).split(":-:")[0]+" ("+ JSON.stringify(assignmentDetails.unitNameCityList).split("(")[0].replace('"', "").trim()+")";  
  let fromDate = !isEmptyVariable(assignmentDetails.startDate)?localDateExtractor(assignmentDetails.startDate):"";
  let toDate =!isEmptyVariable(assignmentDetails.endDate)?localDateExtractor(assignmentDetails.endDate):"";
  let auditDays =  daysCalculatoin(assignmentDetails.startDate,assignmentDetails.endDate)+1;
  let auditNo = !isEmptyVariable(assignmentDetails.referenceNo)?assignmentDetails.referenceNo:" "
  let cycleNo =  assignmentDetails.groupSet1.split("$-$")[1];

  let auditorName = allAuditorList.map(e=>e.userName).join(", ")
  let viwerName = allViwerList.join(", ")



 /// logo & Interal Audit Report
  tempTableRows.push(
    new TableRow({
      children: [
        new TableCell({
          columnSpan:1,
          borders: {
            bottom: cellBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          children: [
            new Paragraph({
              children: [
                !isEmptyVariable(isLogoAvailable) &&
                new ImageRun({
                  data: companyLogo,
                  transformation: {
                    width: 100,
                    height: 30,
                  },
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          columnSpan:3,
          borders: {
            bottom: cellBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
            
          },
        
          children: [
            new Paragraph({
              alignment:AlignmentType.START,
              children: [
                new TextRun({
                  text: "\t\tInternal Audit Report",
                  allCaps: true,
                  font: fontName,
                  size: commonFontSize+2,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          columnSpan:2,
        
          borders: {
            bottom: cellBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
            
          },
        
          children: [
            
          ],
        }),
      ],
    })
  )

  /// Genearl Infromation
  tempTableRows.push(
    new TableRow({
      children: [
        new TableCell({
          borders: {
            bottom: cellBorderStyle
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          columnSpan: 12,

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "General Information",
                  font: fontName,
                  size: commonFontSize,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  )

  /// Project Title
  tempTableRows.push(
    new TableRow({
      children: [
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
           left: 100,
          },
         columnSpan:2,
          children: [
            new Paragraph({
            
              children: [
                new TextRun({
                  text: "Project:",
                  font: fontName,
                   size: commonFontSize,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          columnSpan:10,
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: unitName,
                  font: fontName,
                   size: commonFontSize,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  )

  /// Audtit Duration
  tempTableRows.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign:AlignmentType.CENTER,
          margins: {
            top: 50,
            bottom: 50,
           left: 100,
          },
         columnSpan:2,
          children: [
            new Paragraph({
            
              children: [
                new TextRun({
                  text: "Audit Duration:",
                  font: fontName,
                   size: commonFontSize,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
         
          columnSpan:10,
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              spacing:{
                after:20
              },
              children: [
                new TextRun({
                  text: `Days: ${auditDays}`,
                  font: fontName,
                   size: commonFontSize,
                }),
              ],
            }),
            new Paragraph({
              spacing:{
                after:20
              },
              children: [
                new TextRun({
                  text: `From: ${fromDate}`,
                  font: fontName,
                   size: commonFontSize,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `To: ${toDate}`,
                  font: fontName,
                   size: commonFontSize,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  )
  
  /// Cycle No
  tempTableRows.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign:AlignmentType.CENTER,
          margins: {
            top: 50,
            bottom: 50,
           left: 100,
          },
         columnSpan:2,
          children: [
            new Paragraph({
              
              children: [
                new TextRun({
                  text: "Cycle No:",
                  font: fontName,
                   size: commonFontSize,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
         
          columnSpan:10,
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: cycleNo,
                  font: fontName,
                   size: commonFontSize,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  )

  /// Audit No
  tempTableRows.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign:AlignmentType.CENTER,
          margins: {
            top: 50,
            bottom: 50,
           left: 100,
          },
         columnSpan:2,
          children: [
            new Paragraph({
            
              children: [
                new TextRun({
                  text: "Audit No:",
                  font: fontName,
                   size: commonFontSize,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
         
          columnSpan:10,
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: auditNo,
                  font: fontName,
                   size: commonFontSize,
                }),
              ],
            }),
              
          ],
        }),
      ],
    })
  )

  /// Audit Criteria 
  tempTableRows.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign:AlignmentType.CENTER,
          margins: {
            top: 50,
            bottom: 50,
           left: 100,
          },
         columnSpan:2,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Audit Criteria:",
                  font: fontName,
                   size: commonFontSize,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
         
          columnSpan:10,
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "ISO 9001:2015 & Afcons QMS Procedural Requirement",
                  font: fontName,
                   size: commonFontSize,
                }),
              ],
            }),
           
            
          ],
        }),
      ],
    })
  )

  /// Audit Objectives 
  tempTableRows.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign:AlignmentType.CENTER,
          margins: {
            top: 50,
            bottom: 50,
           left: 100,
          },
         columnSpan:2,
          children: [
            new Paragraph({
            
              children: [
                new TextRun({
                  text: "Audit Objectives:",
                  font: fontName,
                   size: commonFontSize,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
         
          columnSpan:10,
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              spacing:{
              after:50
              },
              children: [
                new TextRun({
                  text: "To check the conformance of ISO 9001:2015 and Organisations own requirement.",
                  font: fontName,
                   size: commonFontSize,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Input to Evaluate QMS performance and identify Opportunities for improvement.",
                  font: fontName,
                   size: commonFontSize,
                }),
              ],
            }),
           
            
          ],
        }),
      ],
    })
  ) 

    /// Audit scope
    tempTableRows.push(
      new TableRow({
        children: [
          new TableCell({
            verticalAlign:AlignmentType.CENTER,
            margins: {
              top: 50,
              bottom: 50,
             left: 100,
            },
           columnSpan:2,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Audit Scope:",
                    font: fontName,
                     size: commonFontSize,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
           
            columnSpan:10,
            margins: {
              top: 50,
              bottom: 50,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Applicable to all Departments & Project Sites of the Afcons covered under Quality Management System.",
                    font: fontName,
                     size: commonFontSize,
                  }),
                ],
              }),
            ],
          }),
        ],
      })
    )

    /// Auditor
    tempTableRows.push(
      new TableRow({
        children: [
          new TableCell({
            verticalAlign:AlignmentType.CENTER,
            margins: {
              top: 50,
              bottom: 50,
             left: 100,
            },
           columnSpan:2,
            children: [
              new Paragraph({
              
                children: [
                  new TextRun({
                    text: "Auditor:",
                    font: fontName,
                     size: commonFontSize,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
           
            columnSpan:10,
            margins: {
              top: 50,
              bottom: 50,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: auditorName,
                    font: fontName,
                     size: commonFontSize,
                  }),
                ],
              }),
            ],
          }),
        ],
      })
    )

    /// Trainee
    tempTableRows.push(
      new TableRow({
        children: [
          new TableCell({
            verticalAlign:AlignmentType.CENTER,
            margins: {
              top: 50,
              bottom: 50,
             left: 100,
            },
           columnSpan:2,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Trainee:",
                    font: fontName,
                     size: commonFontSize,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
           
            columnSpan:10,
            margins: {
              top: 50,
              bottom: 50,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: viwerName,
                    font: fontName,
                     size: commonFontSize,
                  }),
                ],
              }),
            ],
          }),
        ],
      })
    )

    /// Process Covered
    tempTableRows.push(
      new TableRow({
        children: [
          new TableCell({
            verticalAlign:AlignmentType.CENTER,
            margins: {
              top: 50,
              bottom: 50,
             left: 100,
            },
           columnSpan:2,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Process Covered:",
                    font: fontName,
                     size: commonFontSize,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
           
            columnSpan:10,
            margins: {
              top: 50,
              bottom: 50,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Auditee / Auditee Team",
                    font: fontName,
                     size: commonFontSize,
                    bold:true
                  }),
                ],
              }),
            ],
          }),
        ],
      })
      
    )

    /// Auditee 
    tempFunctionNameList.map((item)=>{
      let tempRowChildren =[]
      tempRowChildren.push(

        new TableCell({
          verticalAlign:AlignmentType.CENTER,
          margins: {
            top: 50,
            bottom: 50,
           left: 100,
          },
         columnSpan:2,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: item.functionName,
                  font: fontName,
                   size: commonFontSize,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        
      )
      let tempCellChildren =[]
      finalEmployeeList.map((emp)=>{
        if(emp.functionId==item.functionId) {
          tempCellChildren.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: emp.employeeName,
                  font: fontName,
                   size: commonFontSize,
                }),
              ],
            }),
          )
        }
      })
      tempRowChildren.push(

        new TableCell({
          verticalAlign:AlignmentType.CENTER,
          margins: {
            top: 50,
            bottom: 50,
           left: 100,
          },
         columnSpan:2,
          children: tempCellChildren
        }),
        
      )


      tempTableRows.push(
        new TableRow({
          children:tempRowChildren
        })
        
      )
       
    })


  generalInformationContent.push(
    new Table({
      margins: {
        bottom: 50,
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        bottom:{style:BorderStyle.NONE},
        top:{style:BorderStyle.NONE},
        right:{style:BorderStyle.NONE},
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: tempTableRows
    })
  );

  return [...generalInformationContent];
};


export const localDateExtractor=(date)=>{
  var date = new Date(date);
  return date.toLocaleDateString('en-GB');
}

export const daysCalculatoin = (fromDate,toDate) =>{

  var date1 = new Date(fromDate);
var date2 = new Date(toDate);
var Difference_In_Time = date2.getTime() - date1.getTime();
var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

return Difference_In_Days
}
