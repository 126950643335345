import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {DialogSaveButton,TableCheckBox} from '../../../common/Global/globalStyles.style';
import {Modal} from 'react-bootstrap';
import { toast } from 'react-toastify';

class SelectDropdownValues extends Component{
    
    constructor(props) {
        super(props);

        this.state = {
            assignments:[],
            selectAllCheckBox:"N"
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) 
        && this.props.showSelectAssignmentDialog) {
            let assignments = this.props.assignments;
            let assignmentsNew = [];

            assignments.forEach(item=>{
                let flag = this.props.updateAssignmentIds.some(assignmentId => assignmentId === item.assignmentId);
                if(flag){
                    item.isChecked = "Y";
                }
                assignmentsNew.push(item)
            })

            this.setState({
                assignments:assignmentsNew
            })
        }
    }

    handleSelectionCheckbox = (e) => {
        let isChecked = e.target.checked;
		let dropdownIndex = e.target.name;
        let newDropdownValues = this.state.assignments;
        newDropdownValues[dropdownIndex].isChecked = isChecked?"Y":"N";
        
        this.setState({
            assignments:newDropdownValues
        })
    }

    returnSelectedValues = () => {
        let selectedValues = [];
        this.state.assignments.forEach(item=>{
            if(item.isChecked === "Y"){
                selectedValues.push(item.assignmentId) 
            }
        })

        if(isEmptyArray(selectedValues)){
            // this.setState({
            //     assignmentErr:"Please select atleast one assignment"
            // })
            toast.warning("Please select atleast one assignment");
            // setTimeout(() => {
            //     this.setState({
            //         assignmentErr:{},
            //     });
            // },Constants.WRNG_MSG_TIMEOUT);
        }else{
            this.props.returnSelectedAssignmentIds(selectedValues);
            this.setState({
                selectAllCheckBox:"N"
            })
        }
    }

    processWithoutAssignments = () => {
        this.props.returnSelectedAssignmentIds([]);
    }
    handleSelectAllCheckBox =(e)=>{
        let isCheckedTemp = e.target.checked;
        let tempAssignments = this.state.assignments;
        tempAssignments.forEach(item=>{
            item.isChecked=isCheckedTemp?"Y":"N"
        })
        this.setState({
            selectAllCheckBox:isCheckedTemp?"Y":"N",
            assignments:tempAssignments
        })
    }

    handleSelectAssignmentDialogClose = () => {
        this.props.handleSelectAssignmentDialogClose();
        this.setState({
            selectAllCheckBox:"N"
        })
    }

    render(){
        return(
            <section>
                <Modal className="task-select-dept-dialog custom-dialog"
                    show={this.props.showSelectAssignmentDialog} 
                    backdrop="static">
                    <Modal.Header>
                        <h5>Update Ongoing Assignments</h5>
                        <button 
                            onClick={this.handleSelectAssignmentDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        <div className="body-wrapper">
                            {
                                !isEmptyArray(this.state.assignments) && 
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" width={"8%"}>
                                                        <TableCheckBox themeSettings={this.props.themeSettings}>
                                                            <input
                                                                type="checkbox"
                                                                name={"all"}
                                                                id={"all"}
                                                                onChange={this.handleSelectAllCheckBox}
                                                                checked={this.state.selectAllCheckBox === "Y"}
                                                                disabled={this.props.viewOnly === "Y"?true:false}
                                                            />
                                                            <label for={"all"} style={{ marginLeft: 6 }}></label>
                                                        </TableCheckBox>
                                                    </th>
                                                    <th className="text-center" width={"5%"}>No</th>
                                                    <th>Ref No.</th>
                                                    {this.props.companyType==Constants.USER_ENGAGEMENT_TYPE_SELF? 
                                                    <th>UnitName</th>:
                                                    <th>Client</th>
                                                    } 
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                !isEmptyArray(this.state.assignments) && 
                                                this.state.assignments.map((item,idx) => {
                                                    console.log("item::",item)
                                                    return <tr>
                                                        <td>
                                                            <TableCheckBox themeSettings={this.props.themeSettings}>
                                                                <input
                                                                    type="checkbox"
                                                                    name={idx}
                                                                    id={idx}
                                                                    onChange={this.handleSelectionCheckbox}
                                                                    checked={item.isChecked === "Y"}
                                                                    disabled={this.props.viewOnly === "Y"?true:false}
                                                                />
                                                                <label for={idx} style={{ marginLeft: 6 }}></label>
                                                            </TableCheckBox>
                                                        </td>
                                                        <td className="text-center">{(idx+1)}</td>
                                                        <td>{item.referenceNo}</td>
                                                        {this.props.companyType== Constants.USER_ENGAGEMENT_TYPE_SELF? 
                                                        <td>{String(item.unitAddressList).split(":-:")[0]
                                                            +" ("+item.unitCityList +")"}</td>:
                                                        <td>{item.companyName+" ("+String(item.unitAddressList).split(":-:")[0]+")"}</td>
                                                        }
                                                        <td>{item.assignmentStatus}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                isEmptyArray(this.state.assignments) &&
                                <div class="no-items-layout">
                                    <div class="no-items-card">
                                        <h6>{Constants.EMPTY_SEARCH_WARNING}</h6>
                                    </div>
                                </div>
                            }
                            {/* {
                                !isEmptyVariable(this.state.assignmentErr) &&
                                <span className="cm-error">{this.state.assignmentErr}</span>
                            } */}
                            <div className="modal-close-save m-t-15">
                                <div className="close-save-col">
                                    <button onClick={this.handleSelectAssignmentDialogClose} type="button" 
                                    className="modal-close m-b-0">Cancel</button>
                                    
                                    <DialogSaveButton  
                                    themeSettings={this.props.themeSettings} 
                                    onClick={this.processWithoutAssignments} 
                                    type="button" 
                                    className="modal-save m-b-0">
                                        Proceed without Assignments
                                    </DialogSaveButton>

                                    <DialogSaveButton  
                                    themeSettings={this.props.themeSettings} 
                                    onClick={this.returnSelectedValues} 
                                    type="button" 
                                    className="modal-save m-b-0">
                                        Proceed
                                    </DialogSaveButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
        )
    }

}

export default SelectDropdownValues;