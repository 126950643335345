import React from 'react';
import {Modal} from 'react-bootstrap';
import * as Constants from '../Global/constants';
import {isEmptyVariable} from '../Global/commonFunctions';

class AlertWithRadioDialog extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            deleteAllLinks:""
        }
    };

    handleCheck = (e) =>{
        let name = e.target.name;

        if(name === "deleteChildrenYes"){
            this.setState({
                deleteAllLinks:"Y"
            })
        }else if(name === "deleteChildrenNo"){
            this.setState({
                deleteAllLinks:"N"
            })
        }else{
            this.setState({
                deleteAllLinks:""
            })
        }
    }

    handleProceedButtonClick = () => {
        this.props.proceedBtnClick("",this.state.deleteAllLinks);
    }

    render() {
        return (
            <Modal show={this.props.showAlertDialog} backdrop="static">
                <Modal.Body>
                    <div class="dialog-layout">
                        <button 
                            onClick={this.props.handleAlertDialogClose}>
                            <span className="material-icons">close</span>
                        </button>
                        <span className="material-icons">warning</span>
                        {
                            // !this.props.type === Constants.ALERT_TYPE_ALERT &&
                            !isEmptyVariable(this.props.alertDialogHeading) &&
                            <h5>
                                {this.props.alertDialogHeading}
                            </h5>
                        }
                        <p style={{marginBottom:10}}>{this.props.alertDialogMessage}</p>

                        <div className="radio-group radio-group-block">
                            <div className="radio-btn">
                                <input 
                                    type="radio"
                                    name="deleteChildrenYes"
                                    id="deleteChildrenYes"
                                    onChange={this.handleCheck}
                                    checked={this.state.deleteAllLinks === "Y"?true:false}
                                />
                                <label for="deleteChildrenYes">Delete node along with its links</label>
                            </div>
                            <div className="radio-btn">
                                <input 
                                    type="radio"
                                    name="deleteChildrenNo"
                                    id="deleteChildrenNo"
                                    onChange={this.handleCheck}
                                    checked={this.state.deleteAllLinks === "N"?true:false}
                                />
                                <label for="deleteChildrenNo">Delete only this link</label>
                            </div>
                        </div>

                        <div class="dialog-btn-layout">
                            <a class="alert-cancel" href="javascript:void(0);"
                                onClick={this.props.handleAlertDialogClose}
                            >Cancel</a>
                            
                            <a 
                                class="alert-proceed alert-dialog-warning" href="javascript:void(0);"
                                onClick = {this.handleProceedButtonClick}
                                style={{
                                    marginLeft:"10px"
                                }}
                            >
                            {this.props.proceedBtnLabel}
                            </a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AlertWithRadioDialog;