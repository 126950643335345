import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {isEmptyVariable, SplitJoinString,ifEmptyReturnEmptyStr} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';

class ChecklistDetailsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(){
    }

    render() {
        const selectedItem = this.props.selectedItem;
        let clName= "custom-dialog";
        let clNameCol= "col-md-12 custom-col";
        if(selectedItem.checkListType === Constants.CL_TYPE_YN ||
            selectedItem.checkListType === Constants.CL_TYPE_R ||
            selectedItem.checkListType === Constants.CL_TYPE_WR){
                clName = "checklist-details-dialog custom-dialog"
                clNameCol= "col-md-6 custom-col";
        }

        return(
            <Modal className={clName} 
            show={this.props.showChecklistDetailsDialog} onHide={this.props.handleChecklistDetailsDialogClose}>
                <Modal.Header>
                    <h5>{selectedItem.checkListName+" Details"}</h5>
                    <button 
                        onClick={this.props.handleChecklistDetailsDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="body-wrapper">
                        {
                            !isEmptyVariable(selectedItem.checkListId) && 
                            <div className="details-table-layout">
                                <div className="row custom-row">
                                    <div className={clNameCol}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td width={"33%"}>Checklist Name</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{selectedItem.checkListName}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Version</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{selectedItem.version}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Checklist Type</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{Constants.ChecklistType[selectedItem.checkListType]}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Process</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{ifEmptyReturnEmptyStr(selectedItem.processNameList,"-")}</td>
                                                </tr>
                                                { this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                    <tr>
                                                        <td width={"33%"}>Company List</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"63%"}>
                                                            {
                                                                SplitJoinString(selectedItem.companyList,",",":-:",", ")
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    selectedItem.checkListType === Constants.CL_TYPE_YN &&
                                                    <tr>
                                                        <td width={"33%"}>Yes Color Code</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"63%"}>
                                                            {
                                                                isEmptyVariable(selectedItem.yesColorCode)
                                                                ?
                                                                "NA"
                                                                :
                                                                <div className="flex-center-layout">
                                                                    <div className="risk-level-color-wrapper">
                                                                        <div className="risk-level-color"
                                                                            style={{background:selectedItem.yesColorCode}}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    selectedItem.checkListType === Constants.CL_TYPE_YN &&
                                                    <tr>
                                                        <td width={"33%"}>No Color Code</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"63%"}>
                                                            {
                                                                isEmptyVariable(selectedItem.noColorCode)
                                                                ?
                                                                "NA"
                                                                :
                                                                <div className="flex-center-layout">
                                                                    <div className="risk-level-color-wrapper">
                                                                        <div className="risk-level-color"
                                                                            style={{background:selectedItem.noColorCode}}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    (selectedItem.checkListType === Constants.CL_TYPE_R ||
                                                    selectedItem.checkListType === Constants.CL_TYPE_WR) &&
                                                    <tr>
                                                        <td width={"33%"}>Min Ranking</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"63%"}>{selectedItem.minRank}</td>
                                                    </tr>
                                                }
                                                {
                                                    (selectedItem.checkListType === Constants.CL_TYPE_R ||
                                                    selectedItem.checkListType === Constants.CL_TYPE_WR) &&
                                                    <tr>
                                                        <td width={"33%"}>Max Ranking</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"63%"}>{selectedItem.maxRank}</td>
                                                    </tr>
                                                }
                                                {
                                                    (selectedItem.checkListType === Constants.CL_TYPE_WR) &&
                                                    <tr>
                                                        <td width={"33%"}>Min Weight</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"63%"}>{selectedItem.minWeight}</td>
                                                    </tr>
                                                }
                                                {
                                                    (selectedItem.checkListType === Constants.CL_TYPE_WR) &&
                                                    <tr>
                                                        <td width={"33%"}>Max Weight</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"63%"}>{selectedItem.maxWeight}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        (selectedItem.checkListType === Constants.CL_TYPE_YN ||
                                        selectedItem.checkListType === Constants.CL_TYPE_R ||
                                        selectedItem.checkListType === Constants.CL_TYPE_WR) &&
                                        <div className="col-md-6 custom-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width={"40%"}>Percentage Scoring 1</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"56%"}>
                                                            <div className="flex-center-layout">
                                                            {ifEmptyReturnEmptyStr(selectedItem.startPct1,"NA") + " - "+ifEmptyReturnEmptyStr(selectedItem.endPct1,"NA")}
                                                                {
                                                                    !isEmptyVariable(selectedItem.startPct1) &&
                                                                    <div className="risk-level-color-wrapper">
                                                                        <div className="risk-level-color"
                                                                            style={{background:selectedItem.colorCode1}}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"40%"}>Percentage Scoring 2</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"56%"}>
                                                            <div className="flex-center-layout">
                                                            {ifEmptyReturnEmptyStr(selectedItem.startPct2,"NA") + " - "+ifEmptyReturnEmptyStr(selectedItem.endPct2,"NA")}
                                                                {
                                                                    !isEmptyVariable(selectedItem.startPct2) &&
                                                                    <div className="risk-level-color-wrapper">
                                                                        <div className="risk-level-color"
                                                                            style={{background:selectedItem.colorCode2}}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"40%"}>Percentage Scoring 3</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"56%"}>
                                                            <div className="flex-center-layout">
                                                            {ifEmptyReturnEmptyStr(selectedItem.startPct3,"NA") + " - "+ifEmptyReturnEmptyStr(selectedItem.endPct3,"NA")}
                                                                {
                                                                    !isEmptyVariable(selectedItem.startPct3) &&
                                                                    <div className="risk-level-color-wrapper">
                                                                        <div className="risk-level-color"
                                                                            style={{background:selectedItem.colorCode3}}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"40%"}>Percentage Scoring 4</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"56%"}>
                                                            <div className="flex-center-layout">
                                                            {ifEmptyReturnEmptyStr(selectedItem.startPct4,"NA") + " - "+ifEmptyReturnEmptyStr(selectedItem.endPct4,"NA")}
                                                                {
                                                                    !isEmptyVariable(selectedItem.startPct4) &&
                                                                    <div className="risk-level-color-wrapper">
                                                                        <div className="risk-level-color"
                                                                            style={{background:selectedItem.colorCode4}}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"40%"}>Percentage Scoring 5</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"56%"}>
                                                            <div className="flex-center-layout">
                                                                {ifEmptyReturnEmptyStr(selectedItem.startPct5,"NA") + " - "+ifEmptyReturnEmptyStr(selectedItem.endPct5,"NA")}
                                                                {
                                                                    !isEmptyVariable(selectedItem.startPct5) &&
                                                                    <div className="risk-level-color-wrapper">
                                                                        <div className="risk-level-color"
                                                                            style={{background:selectedItem.colorCode5}}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            
                                        </table>
                                    </div>
                                }
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ChecklistDetailsDialog;