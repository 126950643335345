import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,getThemeSettingsVariables,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {DialogSaveButton,FilterCheckBox} from '../../../common/Global/globalStyles.style';
import { SketchPicker } from 'react-color';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import { toast } from 'react-toastify';

const departmentDropdownDefaultValue = "Select Department";
const riskLevelPlaceholderDefaultValue = "Select Risk Level";
const riskMatrixPlaceholderDefaultValue = "Enter Risk Matrix";
const risktTypePlaceholderDefaultValue = "Select Risk Type";
const assignmentTypeDefaultValue = "Enter Assignment Type";

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();

const riskDefaultColors = {
    RiskLevels_6Colors:["#c99cc8","#ee7977","#fec381","#ffe880","#78b3ed","#a6d590"],
    RiskLevels_5Colors:["#ee7977","#fec381","#ffe880","#78b3ed","#a6d590"],
    RiskLevels_4Colors:["#ee7977","#fec381","#ffe880","#a6d590"],
    RiskLevels_3Colors:["#ee7977","#fec381","#a6d590"],
    RiskLevels_2Colors:["#ee7977","#a6d590"],
}

class AddEditRisk extends Component{
    constructor(props){
        super(props);
        this.state = {
            showDialogLoader:false,
            selectedDepartmentName:departmentDropdownDefaultValue,
            selectedDepartmentId:"",
            name:"",
            shortName:"",
            description:"",
            formErrors:{},
            submitClickedAtleastOnce:false,
            showIsDefault:"N",

            riskLevelArr:Constants.RISK_LEVELS,
            noOfRiskLevels:riskLevelPlaceholderDefaultValue,
            riskType: "",
            riskTypes:Constants.RISK_TYPES,
            riskMatrixArr:[],
            assignmentType: { name: assignmentTypeDefaultValue },
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateRiskDialog){
            if(this.props.isEdit){
                this.getRiskDetails();
            }else{
                let selectedDepartmentId = "";
                let showIsDefaultTemp = "N";
                let selectedDepartmentName = departmentDropdownDefaultValue;
                if(!isEmptyVariable(this.props.departmentArr) && 
                this.props.departmentArr.length===1){
                    selectedDepartmentId = this.props.departmentArr[0].departmentId;
                    selectedDepartmentName = this.props.departmentArr[0].name;
                    showIsDefaultTemp = this.props.departmentArr[0].showIsDefault;
                }

                //lets reset all the values for Create risk
                this.setState({
                    selectedDepartmentId:selectedDepartmentId,
                    selectedDepartmentName:selectedDepartmentName,
                    name:"",
                    shortName:"",
                    description:"",
                    noOfRiskLevels:riskLevelPlaceholderDefaultValue,
                    matrixDefPlaceholder:riskMatrixPlaceholderDefaultValue,
                    riskType: risktTypePlaceholderDefaultValue,
                    assignmentType: { name: assignmentTypeDefaultValue },
                    riskMatrixArr:[],
                    isDefaultSelected:"N",
                    showIsDefault:showIsDefaultTemp,
                    submitClickedAtleastOnce:false,
                    formErrors:{}
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){

            if(name === "shortName"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['shortName'] = "Please enter master short name";
                }else{
                    FormErrorsTemp['shortName'] = "";
                }
            }else if(name === "name"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['name'] = "Please enter master name";
                }else{
                    FormErrorsTemp['name'] = "";
                }
            }else if(name === "description"){
                if(!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH){
                    FormErrorsTemp['description'] = "Description must be less then 500 characters";
                }else{
                    FormErrorsTemp['description'] = "";
                }
            }
        }

        this.setState({ 
            [name]: value ,
            formErrors:FormErrorsTemp
        });
    }

    handleCheck = (e) =>{
        let isChecked = e.target.checked;
        let name = e.target.name;
        
        if(name === "isDefaultValue"){
            if(isChecked){
                this.setState({
                    isDefaultSelected:"Y"
                })
            }else{
                this.setState({
                    isDefaultSelected:"N"
                })
            }
        }
    }

    deptDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['deparment'] = "";
        }
        this.setState({
            selectedDepartmentName:item.name,
            selectedDepartmentId:item.departmentId,
            showIsDefault:item.showIsDefault,
            formErrors:FormErrorsTemp
        })
    }

    riskLevelDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['riskLevels'] = "";
        }

        let riskMatrixArr = [];
        let riskMatrixObj = {}
        for(var i=0;i<item.riskLevel;i++){
            riskMatrixObj = {
                id:i,
                riskLevel:"",
                colorCode:"#000000",
                showColorPicker:false,
                formErrors:""
            };
            riskMatrixArr.push(riskMatrixObj);
        }

        if(item.riskLevel > 1 && item.riskLevel < 7){
            riskDefaultColors["RiskLevels_"+item.riskLevel+"Colors"].forEach((color,index) => {
                riskMatrixArr[index].colorCode = color;
            });
        }

        this.setState({
            noOfRiskLevels:item.riskLevel,
            formErrors:FormErrorsTemp,
            riskMatrixArr:riskMatrixArr,
            matrixDefPlaceholder:riskMatrixPlaceholderDefaultValue
        })
    }

    /***************** RISK LEVELS DIALOG **********************/
    handleMatrixDefDialogShow = () => {
        this.setState({
            showMatrixDefDialog:true,
        })
    }

    handleMatrixDefDialogClose = () => {
        this.setState({
            showMatrixDefDialog:false,
        })
    }

    handleChangeMatrixLevels = (e) => {
        const { name, value } = e.target;
        let riskMatrixArrTemp = this.state.riskMatrixArr;
        riskMatrixArrTemp[name].riskLevel = value;
        riskMatrixArrTemp[name].formErrors = "";

        this.setState({
            riskMatrixArr:riskMatrixArrTemp,
        })
    }

    openColorPicker = (idx) => {
        let riskMatrixArrTemp = this.state.riskMatrixArr;
        riskMatrixArrTemp[idx].showColorPicker = "Y";

        this.setState({
            riskMatrixArr:riskMatrixArrTemp,
            openColorPickerIdx:idx
        })
    }

    handleColorPickerClose = (idx) => {
        let riskMatrixArrTemp = this.state.riskMatrixArr;
        riskMatrixArrTemp[idx].showColorPicker = false;
        this.setState({
            riskMatrixArr:riskMatrixArrTemp,
            openColorPickerIdx:""
        })
    }

    handleColorPickerChange = (color) =>{
        let riskMatrixArrTemp = this.state.riskMatrixArr;
        riskMatrixArrTemp[this.state.openColorPickerIdx].colorCode = color.hex;
        this.setState({
            riskMatrixArr:riskMatrixArrTemp,
        })
    }

    resetToDefault = () => {
        let riskMatrixArr = this.state.riskMatrixArr;
        riskDefaultColors["RiskLevels_"+this.state.noOfRiskLevels+"Colors"].forEach((color,index) => {
            riskMatrixArr[index].colorCode = color;
        });

        this.setState({
            riskMatrixArr:riskMatrixArr
        })
    }
    /******************* API *****************/
    handleRiskMatrixDefSubmit = () => {
        let riskMatrixArrTemp = this.state.riskMatrixArr;

        let isError = false;
        riskMatrixArrTemp.map((item,idx)=>{
            if(isEmptyVariable(item.riskLevel)){
                isError = true;
                riskMatrixArrTemp[idx].formErrors = "Please enter risk value"
            }
        })

        if(isError){
            this.setState({
                riskMatrixArr:riskMatrixArrTemp
            })
        }else{
            this.setState({
                matrixDefPlaceholder:"Risk Matrix Completed"
            },()=>{
                this.handleMatrixDefDialogClose();
            })
            
        }
    }

    handleCreateMaster = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.name)){
            isError = true;
            FormErrorsTemp['name'] = "Please enter master name";
        }

        if(isEmptyVariable(this.state.shortName)){
            isError = true;
            FormErrorsTemp['shortName'] = "Please enter master short name";
        }

        if(!isEmptyVariable(this.state.description)){
            if(this.state.description.length > Constants.DESC_STRING_LENGTH){
                isError = true;
                FormErrorsTemp['description'] = "Description must be less then 500 characters";
            }
        }

        if(isEmptyVariable(this.state.noOfRiskLevels) || 
        this.state.noOfRiskLevels === riskLevelPlaceholderDefaultValue){
            isError = true;
            FormErrorsTemp['riskLevels'] = "Please select the risk level";
        }

        if(!this.props.isEdit && (isEmptyVariable(this.state.riskType) || this.state.riskType === risktTypePlaceholderDefaultValue)){
            isError = true;
            FormErrorsTemp['riskType'] = "Please select the risk type";
        }

        if(!isEmptyArray(this.state.riskMatrixArr)){
            this.state.riskMatrixArr.map(item=>{
                if(isEmptyVariable(item.riskLevel)){
                    isError = true;
                    FormErrorsTemp['matrixDef'] = "Please complete the risk matrix";
                }
            })
        }

        if(!this.props.isEdit){
            if(isEmptyVariable(this.state.selectedDepartmentName) || this.state.selectedDepartmentName === departmentDropdownDefaultValue){
                isError = true;
                FormErrorsTemp['deparment'] = "Please select the department";
            }

            if(isEmptyVariable(this.state.assignmentType.assignmentTypeId)){
                isError = true;
                FormErrorsTemp['assignmentType'] = "Please select the assignment type";
            }
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let body = {};
            let riskMatrixArrNew = this.state.riskMatrixArr.map((item,index)=>{
                item.level = (index+1);
                return item;
            })
            
            if (this.state.riskType === "Likelihood") {
                url = this.props.isEdit ? Constants.EditLikelihood : Constants.CreateLikelihood;
                body = new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    shortName:this.state.shortName,
                    name:this.state.name,
                    description:this.state.description,
                    likelihoodId:this.props.selectedId,
                    levels:this.state.noOfRiskLevels,
                    isDefault:this.state.isDefaultSelected,
                    likelihoodLevels:JSON.stringify(riskMatrixArrNew),
                    departmentId:this.state.selectedDepartmentId,
                });
            }
            else {
                url = this.props.isEdit ? Constants.EditRiskMasters : Constants.CreateRiskMasters;
                body = new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    shortName:this.state.shortName,
                    name:this.state.name,
                    description:this.state.description,
                    riskId:this.props.selectedId,
                    levels:this.state.noOfRiskLevels,
                    isDefault:this.state.isDefaultSelected,
                    riskLevels:JSON.stringify(riskMatrixArrNew),
                    departmentId:this.state.selectedDepartmentId,
                    assignmentTypeId:this.state.assignmentType?.assignmentTypeId
                });
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: body,
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showCreateDialog:false,
                        showLoader:false,
                        selectedId:"",
                        name:"",
                        shortName:"",
                        description:"",
                        riskMatrixArr:[],
                        noOfRiskLevels:"",
                        matrixDefPlaceholder:riskMatrixPlaceholderDefaultValue,
                        assignmentType: { name: assignmentTypeDefaultValue },
                    },() => {
                        this.props.handleCreateRiskDialogClose(true)
                    });
                }else{
                    // FormErrorsTemp['apiErr'] = data.responseMessage;
                    toast.error(data.responseMessage);
                    this.setState({
                        // formErrors:FormErrorsTemp,
                        showLoader:false
                    });
                }
            });
        }
    }

    getRiskDetails = () => {
        fetch(Constants.GetRiskDetails,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                riskId:this.props.selectedId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let riskMatrixObj = {};
                let riskMatrixArr = [];
                
                data.data.riskLevels.map((item,idx)=>{
                    riskMatrixObj = {
                        id:idx,
                        riskLevelId:item.riskLevelId,
                        riskLevel:item.riskLevel_new,
                        colorCode:item.colorCode_new,
                        showColorPicker:false,
                        formErrors:""
                    };
                    riskMatrixArr.push(riskMatrixObj);
                })
                this.setState({
                    showDialogLoader:false,
                    name:data.data.risk.name_new,
                    shortName:data.data.risk.shortName_new,
                    description:data.data.risk.description_new,
                    riskStatus:data.data.risk.status,
                    noOfRiskLevels:data.data.risk.levels,
                    riskMatrixArr:riskMatrixArr,
                    matrixDefPlaceholder:"Risk Matrix Completed",
                    isDefaultSelected:"N",
                    FormErrorsTemp:{}
                });
                
            }else{
                this.setState({
                    showDialogLoader:false,
                });
            }
        });
    }

    assignmentTypeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['assignmentType'] = "";
        }

        this.setState({
            assignmentType: item,
            formErrors: FormErrorsTemp,
        });
    }
    /***************** RISK LEVELS DIALOG ENDS **********************/

    riskTypeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;
        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['riskType'] = "";
        }
        this.setState({
            riskType: item.riskType,
            formErrors:FormErrorsTemp,
        })
    }

    render(){
        const assignmentTypesArr = [ { assignmentTypeId: "Generic", name: "Generic" }, ...this.props.assignmentTypes ];
        return(
            <div>
            <Modal className="custom-dialog risk-dialog" show={this.props.showCreateRiskDialog} 
            backdrop="static">
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit Master":"Add New Master"}</h5>
                    <button 
                        onClick={this.props.handleCreateRiskDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div class="modal-body">
                    {
                        this.state.showDialogLoader &&
                        <div className="modal-loading-text">
                            <p
                            style={{
                                background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`,
                                color:`rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`
                            }}
                            >Loading....</p>
                        </div>
                    }
                    <div class="addnew-modal-form">
                        {
                            !this.props.isEdit &&
                            !isEmptyArray(this.props.departmentArr) &&
                            this.props.departmentArr.length > 1 &&
                            <div class="addnew-modal-form-group">
                                <p>Department*</p>
                                <RegularDropdown 
                                    placeholder={this.state.selectedDepartmentName}
                                    dropdownArr={this.props.departmentArr}
                                    labelParam="name"
                                    onDropDownItemClick={this.deptDropdownClick}
                                    isFocusRequired={true}
                                    defaultPlaceholderDropDown = {departmentDropdownDefaultValue}
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['deparment']) &&
                                    <span class="cm-error">{this.state.formErrors['deparment']}</span>
                                }
                            </div>
                        }
                        <div class="addnew-modal-form-group">
                            <p>Short Name*</p>
                            <input type="text" placeholder="Enter Short Name" spellCheck="true" 
                            name="shortName" value={this.state.shortName} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['shortName']) &&
                                <span class="cm-error">{this.state.formErrors['shortName']}</span>
                            }
                        </div>
                        <div class="addnew-modal-form-group">
                            <p>Name*</p>
                            <input type="text" placeholder="Enter Name" spellCheck="true" 
                            name="name" value={this.state.name} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['name']) &&
                                <span class="cm-error">{this.state.formErrors['name']}</span>
                            }
                        </div>
                        <div class="addnew-modal-form-group">
                            <p>Description</p>
                            <textarea type="text" placeholder="Enter Description" spellCheck="true" 
                            name="description" value={this.state.description} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['description']) &&
                                <span class="cm-error">{this.state.formErrors['description']}</span>
                            }
                           
                        </div>
                        {
                            !this.props.isEdit &&
                            <div class="addnew-modal-form-group">
                                <p>Type*</p>
                                <RegularDropdown 
                                    placeholder={this.state.riskType}
                                    dropdownArr={this.state.riskTypes}
                                    labelParam="riskType"
                                    onDropDownItemClick={this.riskTypeDropdownClick}
                                    defaultPlaceholderDropDown={risktTypePlaceholderDefaultValue}
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['riskType']) &&
                                    <span class="cm-error">{this.state.formErrors['riskType']}</span>
                                }
                            </div>
                        }
                        {
                            // (!this.props.isEdit ||
                            // this.state.riskStatus === "Created") &&
                            !this.props.isEdit &&
                            <div class="addnew-modal-form-group">
                                <p>Risk Levels*</p>
                                <RegularDropdown 
                                    placeholder={this.state.noOfRiskLevels }
                                    dropdownArr={this.state.riskLevelArr}
                                    labelParam="riskLevel"
                                    onDropDownItemClick={this.riskLevelDropdownClick}
                                    defaultPlaceholderDropDown = {riskLevelPlaceholderDefaultValue}
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['riskLevels']) &&
                                    <span class="cm-error">{this.state.formErrors['riskLevels']}</span>
                                }
                            </div>
                        }

                        {
                            !this.props.isEdit &&
                            <div class="addnew-modal-form-group">
                                <p>Assignment Type*</p>
                                <RegularDropdown 
                                    placeholder={this.state.assignmentType?.name}
                                    dropdownArr={assignmentTypesArr}
                                    labelParam="name"
                                    onDropDownItemClick={this.assignmentTypeDropdownClick}
                                    defaultPlaceholderDropDown = {assignmentTypeDefaultValue}
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['assignmentType']) &&
                                    <span class="cm-error">{this.state.formErrors['assignmentType']}</span>
                                }
                            </div>
                        }
                        
                        {
                            !isEmptyVariable(this.state.noOfRiskLevels) &&
                            this.state.noOfRiskLevels !== riskLevelPlaceholderDefaultValue &&
                            <div class="addnew-modal-form-group">
                                <p>Matrix Definition*</p>
                                <div className="dummy-dropdown" tabIndex={0} onKeyPress={this.onKeyBtnPress}  
                                onClick={this.handleMatrixDefDialogShow}>
                                    <p class="placeholder-text">{this.state.matrixDefPlaceholder}</p>
                                    <span class="material-icons">keyboard_arrow_right</span>
                                </div>
                                {
                                    !isEmptyVariable(this.state.formErrors['matrixDef']) &&
                                    <span class="cm-error">{this.state.formErrors['matrixDef']}</span>
                                }
                            </div>
                        }
                        {
                            !this.props.isEdit &&
                            !isEmptyVariable(this.state.showIsDefault) &&
                            this.state.showIsDefault === "Y" &&
                            <div class="addnew-modal-form-group">
                                <FilterCheckBox themeSettings={themeSettings} style={{marginLeft:0}}>
                                    <input 
                                        type="checkbox"
                                        name="isDefaultValue"
                                        id="isDefaultValue"
                                        onChange={this.handleCheck}
                                        checked={this.state.isDefaultSelected === "Y"?true:false}
                                    />
                                    <label for="isDefaultValue">Use this matrix to Set importance / priority of Tasks</label>
                                </FilterCheckBox>
                            </div>
                        }
                        {/* {
                            !isEmptyVariable(this.state.formErrors['apiErr']) &&
                            <span class="cm-error">{this.state.formErrors['apiErr']}</span>
                        } */}
                        
                    </div>
                    <div class="modal-close-save">
                        <div class="close-save-col">
                            <button onClick={this.props.handleCreateRiskDialogClose} type="button" class="modal-close">Cancel</button>
                            <DialogSaveButton 
                            themeSettings={themeSettings}
                            onClick={this.handleCreateMaster} type="button">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal className="custom-dialog risk-matrix-dialog" show={this.state.showMatrixDefDialog} 
                backdrop="static">
                    <Modal.Header>
                        <h5>Risk Matrix</h5>
                        <button 
                            onClick={this.handleMatrixDefDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body risk-definition">
                        {
                            !isEmptyArray(this.state.riskMatrixArr) &&
                            this.state.riskMatrixArr.map((item,idx)=>{
                                return <div className="row custom-row">
                                    <div className="col-md-3 custom-col">
                                        <p>
                                            {
                                                idx === 0
                                                ?
                                                    "Highest Risk"
                                                :
                                                    idx === (this.state.riskMatrixArr.length - 1)
                                                    ?
                                                        "Lowest Risk"
                                                    :
                                                        ""
                                            }
                                        </p>
                                    </div>
                                    <div className="col-md-7 custom-col">
                                        <input type="text" placeholder="Description" 
                                        name={idx} value={item.riskLevel} onChange={this.handleChangeMatrixLevels}/>
                                        {
                                            !isEmptyVariable(item.formErrors) &&
                                            <span class="cm-error">{item.formErrors}</span>
                                        }
                                    </div>
                                    <div className="col-md-2 custom-col">
                                        <div className="color-layout-wrapper">
                                            <div style={{background:item.colorCode}} className="color-layout"
                                            onClick={this.openColorPicker.bind(this,idx)} />
                                        </div>
                                        {
                                            item.showColorPicker &&
                                            <div className="popover">
                                                <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,idx) }/>
                                                <SketchPicker color={item.colorCode} onChange={ this.handleColorPickerChange} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            })
                        }
                        {
                            this.state.noOfRiskLevels > 1 && this.state.noOfRiskLevels < 7 &&
                            <span className="reset-risk-colors" onClick = {this.resetToDefault}>Reset colors to default</span>
                        }
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.handleMatrixDefDialogClose} type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton 
                                themeSettings={themeSettings}
                                onClick={this.handleRiskMatrixDefSubmit} type="button">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default AddEditRisk;