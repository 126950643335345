import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';

class DifferenceDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showDifferenceDialog){
            
        }
    }

    render() {
        return(
            <Modal className="custom-dialog" show={this.props.showDifferenceDialog} onHide={this.props.handleDifferenceDialogClose}>
                <Modal.Header>
                <h5>Differences</h5>
                <button 
                    onClick={this.props.handleDifferenceDialogClose}
                    type="button" data-dismiss="modal">
                    <span class="material-icons">close</span>
                </button>
            </Modal.Header>
                <hr />
                <div class="modal-body">
                    <div class="addnew-modal-form">
                        
                    </div>
                    <div class="modal-close-save">
                        <div class="close-save-col">
                            <button onClick={this.props.handleDifferenceDialogClose} type="button" class="modal-close">Close</button>
                        </div>
                    </div>

                </div>
                
            </Modal>


        );
    }
}

export default DifferenceDialog;