import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {TableIcon} from '../../../common/Global/globalStyles.style';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    isEmptyArray,getOnlyFileNameFromPath} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import AlertDialog from "../../../common/AlertDialog";

const sortDescription = "a.description";
const sortDocumentName = "a.name";

const userDetails  = getLocalStorageVariables();

class DocumentSampleList extends Component{
    constructor(props){
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            resultSizePlaceholder:Constants.RESULT_SIZE,
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            sort:"",
            sortDir:"",

            showAlertDialog:false,
            selectedAssignmentWorkingPaperUploadId:"",
        }
    }

    componentDidMount(){
        // this.getDocumentSampleList();
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showUploadWorkingPaperDocs){
            this.setState({
                componentDidMountFlag:false,
                apiSearchKey:"",
                searchkey:"",
                currentPageNo: 1,
                showLoader:false,
                resultSize:Constants.RESULT_SIZE,
                totalCount:0,
                datalist:[],
                resultSizePlaceholder:Constants.RESULT_SIZE,
                resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
                sort:"",
                sortDir:"",

                showAlertDialog:false,
                selectedAssignmentWorkingPaperUploadId:"",
            },()=> {
                this.getDocumentSampleList();
            })
        }
    }


    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            selectedAssignmentWorkingPaperUploadId:"",
        });
    }

    handleShowDeleteAlert = (item) => {
        this.setState({
            showAlertDialog:true,
            selectedAssignmentWorkingPaperUploadId:item,
        });
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getDocumentSampleList();
        })
    }
    /*****************************API*********************************/
    downloadAttachment = (documentPath,e) => {
        e.stopPropagation();
        fetch(Constants.API_SERVER_PATH+documentPath)
        .then(response => { return response.blob(); } )
        .then(blob =>
        {
            let fileName = getOnlyFileNameFromPath(documentPath);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    getDocumentSampleList = () => {
        fetch(Constants.GetWorkingPaperUploads,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                assignmentWorkingPaperId:this.props.assignmentWorkingPaperId,
                pageNo: this.state.currentPageNo,
                search: this.state.searchkey,
                resultsize: this.state.resultSize,
                status: this.state.status,
                sort: this.state.sort,
                sortDir: this.state.sortDir,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.workingPaperUploads,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:[],
                    totalCount:0,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetWorkingPaperUploads,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    documentId:this.props.documentId,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:[],
                        totalCount:0,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }
    deleteAttachment = () => {
        this.setState({
            showLoader:true
        })

        fetch(Constants.Deleteworkingpaperupload, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentWorkingPaperUploadId: this.state.selectedAssignmentWorkingPaperUploadId,
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                this.setState({
                    showLoader:false,
                    showAlertDialog:false,
                    selectedAssignmentWorkingPaperUploadId:"",
                },() => {
                    this.getDocumentSampleList();
                })
            } else {
            }
        });
    }
    render(){
        return(
            <section>
                <Modal className="task-select-table-dialog custom-dialog"
                    show={this.props.showUploadWorkingPaperDocs} 
                    onHide={this.props.handleUploadWorkingPaperDocsClose}>
                    <Modal.Header>
                        <h5>Documents</h5>
                        <button 
                            onClick={this.props.handleUploadWorkingPaperDocsClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div className="loading-layout"
                        style={{background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},0.7)`}}>
                            <div className="loading-text-layout">
                                <p
                                style={{color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`}}
                                >Loading....</p>
                            </div>
                        </div>
                    }

                    {
                        // Need to check component did mount flag, other wise until api is called,
                        // it shows no items found layout.
                        this.state.componentDidMountFlag &&
                        <div className="body-wrapper">
                            {
                                !isEmptyArray(this.state.datalist) && 
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                <th className="text-center" width={"5%"}>No.</th>
                                                <th width={"20%"} className="c-pointer"  onClick={this.sortTableLocal.bind(this, sortDocumentName)}>
                                                    <div className="sort-header">
                                                        Name
                                                        <span
                                                        className={
                                                            this.state.sort === sortDocumentName
                                                                ? "material-icons"
                                                                : "material-icons hide-sort-arrow"
                                                        }
                                                        >
                                                            {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                        </span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDescription)}>
                                                    <div className="sort-header">
                                                        Description
                                                        <span
                                                        className={
                                                            this.state.sort === sortDescription
                                                                ? "material-icons"
                                                                : "material-icons hide-sort-arrow"
                                                        }
                                                        >
                                                            {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                        </span>
                                                    </div>
                                                </th>
                                                <th className="text-center"  width={"10%"}>Download</th>
                                                <th className="text-center"  width={"10%"}>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                !isEmptyArray(this.state.datalist) && 
                                                this.state.datalist.map((item,idx) => {

                                                    return <tr>
                                                        <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                        <td>{getOnlyFileNameFromPath(item.name)}</td>
                                                        <td>{truncateString(item.description,Constants.REMARKS_STRING_LENGTH)}</td>
                                                        <TableIcon themeSettings={this.props.themeSettings} className="text-center">
                                                            <span class="material-icons" onClick={this.downloadAttachment.bind(this, item.documentPath)}>get_app</span>
                                                        </TableIcon>
                                                        <TableIcon themeSettings={this.props.themeSettings} className="text-center">
                                                            <span class="material-icons" onClick={this.handleShowDeleteAlert.bind(this, item.assignmentWorkingPaperUploadId)}>delete</span>
                                                        </TableIcon>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                <div class="no-items-layout">
                                    <div class="no-items-card" style={{padding:40}}>
                                        <h6>
                                        {
                                            isEmptyVariable(this.state.apiSearchKey)?
                                            Constants.NO_RECORDS_WARNING
                                            :
                                            Constants.EMPTY_SEARCH_WARNING
                                        }
                                        </h6>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    </div>
                </Modal>

                <AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={Constants.ALERT_TYPE_WARNING}
                    alertDialogHeading={"Delete Document"}
                    alertDialogMessage={"Are you sure you want to delete this document"}
                    proceedBtnClick={this.deleteAttachment}
                    proceedBtnLabel={"Delete"}
                    themeSettings={this.props.themeSettings}
                />
            </section>
        )
    }
}

export default DocumentSampleList;