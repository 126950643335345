import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {DialogSaveButton} from '../../../common/Global/globalStyles.style';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();

const departmentDropdownDefaultValue = "Select Department";

class UpdateReviewStatusDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,

            functionName:"",
            formErrors:{},
            submitClickedAtleastOnce:false,
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){

            if(this.props.isEdit){
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    functionName:this.props.functionName,
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }else{
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    functionName:"",
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.handleCreateMaster();
        }
    }

    handleCreateDialogClose = () =>{
        this.props.handleAddEditDialogClose(false);
    }

    handleCreateMaster = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.functionName)){
            isError = true;
            FormErrorsTemp['functionName'] = "Please enter function name";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
            }

            if(this.props.isEdit){
                url = Constants.UpdateFunction;
                let funcObj = {
                    functionId:this.props.selectedId,
                    functionName:this.state.functionName
                }
                postParam.function = JSON.stringify(funcObj);
                postParam.rootFunctionId = this.props.rootFunctionId;
            }else{
                postParam.departmentId = this.props.departmentId;
                if(this.props.createNodeType === "root"){
                    url = Constants.AddRootFunction;
                    let funcObj = {
                        functionId:0,
                        functionName:this.state.functionName
                    }
                    postParam.function = JSON.stringify(funcObj);

                }else if(this.props.createNodeType === "child"){
                    url = Constants.AddChildFunction;
                    let funcObj = {
                        functionId:0,
                        functionName:this.state.functionName
                    }

                    postParam.childFunction = JSON.stringify(funcObj);
                    postParam.parentFunctionId = this.props.relativeFunctionId;
                    postParam.rootFunctionId = this.props.rootFunctionId;
                    
                }else if(this.props.createNodeType === "sibling"){
                    url = Constants.AddSiblingFunction;

                    let funcObj = {
                        functionId:0,
                        functionName:this.state.functionName
                    }

                    postParam.siblingFunction = JSON.stringify(funcObj);
                    postParam.targetFunctionId = this.props.relativeFunctionId;
                    postParam.rootFunctionId = this.props.rootFunctionId;
                }
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    let createNodeTypeTemp = this.props.createNodeType;

                    if(createNodeTypeTemp === "root"){
                        this.setState({
                            showLoader:false,
                            functionName:"",
                            formErrors:{},
                        },() => {
                            this.props.handleAddEditDialogClose(true);
                        });
                    }else if(createNodeTypeTemp === "child" ||
                    createNodeTypeTemp === "sibling"){
                        this.setState({
                            showLoader:false,
                            functionName:"",
                            formErrors:{},
                        },() => {
                            this.props.handleAddEditDialogClose(true);
                        });
                    }
                }else{
                    // FormErrorsTemp['apiErr'] = data.responseMessage;
                    toast.error(data.responseMessage);
                    this.setState({
                        // formErrors:FormErrorsTemp,
                        showLoader:false
                    });
                }
            });
        }
    }

    render() {
        return(
            <Modal className="custom-dialog" show={this.props.showCreateDialog} backdrop="static">
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit Function":"Add Function"}</h5>
                    <button 
                        onClick={this.handleCreateDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div class="modal-body">
                    {
                        this.state.showLoader &&
                        <div className="modal-loading-text">
                            <p
                            style={{
                                background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                            }}
                            >Loading....</p>
                        </div>
                    }
                    <div class="addnew-modal-form">
                        <div class="addnew-modal-form-group">
                            <p>Function Name*</p>
                            <input type="text" placeholder="Enter Function Name" onKeyPress={this.onEnterBtnPress} spellCheck="true" 
                            name="functionName" value={this.state.functionName} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['functionName']) &&
                                <span class="cm-error">{this.state.formErrors['functionName']}</span>
                            }
                            {/* {
                                !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                <span class="cm-error">{this.state.formErrors['apiErr']}</span>
                            } */}
                        </div>
                    </div>
                    <div class="modal-close-save">
                        <div class="close-save-col">
                            <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                            <DialogSaveButton 
                            themeSettings={this.props.themeSettings}
                            onClick={this.handleCreateMaster} type="button">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default UpdateReviewStatusDialog;