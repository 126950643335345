import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,InfoCardSpan} from '../../common/Global/globalStyles.style';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import AlertDialog from '../../common/AlertDialog';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import Pagination from '../../common/Global/pagination';
import ClientDetailsDialog from './clientdetailsdialog';
import AddClientAdminDialog from './addclientadmin';
import AnimateHeight from 'react-animate-height';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {getLocalStorageVariables,isEmptyVariable,ifEmptyReturn,sortTable,ifEmptyReturnEmptyStr,
    isEmptyArray} from '../../common/Global/commonFunctions';
import CopyClientDataDialog from './copyClientData';

let userDetails  = {};

const editClient = "Edit Client";
const addClientAdmin = "Add Client Admin";
const viewUsers = "View Users";
const deleteClient = "Delete Client";
const reactivateClient = "Reactivate Client";
const copyClientData = "Copy Demo Data";

const sortShortName = "companyShortName";
const sortClientCode = "clientCode";
const sortCity = "city";
const sortUserCount = "clientUserCount";
const sortContact1 = "cp.firstName1";
const sortContact2 = "cp.firstName2";
const copyDataSuccessMessage = "Copy data initiated. It might take 10-15 minutes to complete";

class ListClients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            clientSearchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active status",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:sortClientCode,
            sortDir:"asc",
            infoCard:"",

            status:"Active",
            statusArr:[
                {
                    label:"Active",
                    isSelected:true
                },
                {
                    label:"Inactive",
                    isSelected:false
                }
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            
            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            alertDialogMessage:"",

            //Client Listing Dialog
            selectedClientId:"",
            showUsersListDialog:false,
            selectedClientName:"",
            showAddClientAdminDialog:false,
            showClientDetailsDialog:false,

            //copy Client
            showCopyClientDataDialog:false,

            showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
        }
    }

    componentDidMount(){
        userDetails = getLocalStorageVariables();
        this.getClientsList();
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === deleteClient){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.clientId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete client "+dropdownItem.companyShortName+"?"
            })
        }else if(item.label === reactivateClient){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.clientId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate client "+dropdownItem.companyShortName+"?"
            })
        }else if(item.label === viewUsers){
            this.props.viewClientUsersDropDownItemClick(dropdownItem.clientId,dropdownItem.companyShortName)
        }else if(item.label === editClient){
            window.location = Constants.URL_EDITCLIENT+"/"+dropdownItem.clientId;
        }else if(item.label === addClientAdmin){
            this.setState({
                selectedClientId:dropdownItem.clientId,
                showAddClientAdminDialog:true,
                selectedClientName:dropdownItem.companyName
            })
        }else if(item.label === copyClientData){
            this.setState({
                selectedClientId:dropdownItem.clientId,
                showCopyClientDataDialog:true,
                selectedClientName:dropdownItem.companyName
            })
        }
    }

    onUserCountClick = (selectedClientObj) => {
        this.setState({
            selectedClientId:selectedClientObj.clientId,
            showUsersListDialog:true,
            selectedClientName:selectedClientObj.companyName
        })
    }

    onCompanyNameClick = (selectedClientObj) => {
        this.setState({
            selectedClientId:selectedClientObj.clientId,
            showClientDetailsDialog:true,
            selectedClientName:selectedClientObj.companyName
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onSearchIconClick = () => {
        this.getClientsList();
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.getClientsList();
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () => {
		this.setState({
			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
		},() => {
            this.getClientsList();
        });
	};
    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label + " status";
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getClientsList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getClientsList();
        })
    }

    handleUsersListDialogClose = () => {
        this.setState({
            selectedClientId:"",
            showUsersListDialog:false,
            selectedClientName:""
        })
    }

    handleClientDetailsDialogClose = () => {
        this.setState({
            selectedClientId:"",
            showClientDetailsDialog:false,
            selectedClientName:""
        })
    }
    
    handleAddClientAdminDialogClose = (reloadFlag) => {
        this.setState({
            selectedClientId:"",
            showAddClientAdminDialog:false,
            selectedClientName:""
        },()=>{
            if(reloadFlag === true){
                this.getClientsList();
            }
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getClientsList();
        })
    }
    handleCopyClientDataDialogClose = (alertFlag) => {
        if(alertFlag){
            this.setState({
                showCopyClientDataDialog:false,
                showAlertDialogInfo: true,
                alertDialogMessageInfo: copyDataSuccessMessage,
            })
        }else{
            this.setState({
                showCopyClientDataDialog:false,
            })
        }
    }
    /*******************API CALLS*******************/

    getClientsList = () => {
        this.setState({
            showLoader:true,
        });
        userDetails  = getLocalStorageVariables();
        fetch(Constants.GetClients,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.clientSearchkey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.result,
                    infoCard:data.data.infoCard,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.clientSearchkey,
                })
            }else{
                this.setState({
                    datalist:[],
                    totalCount:0,
                    infoCard:"",
                    showLoader:false,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.clientSearchkey,
                })
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        userDetails  = getLocalStorageVariables();
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });
            
            fetch(Constants.GetClients,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.clientSearchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.clientSearchkey,
                        currentPageNo:page,
                    })
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        componentDidMountFlag:true,
                        apiSearchKey:this.state.clientSearchkey,
                    })
                }
            });
        }
    }

    deleteActivateClient = () => {
        let url = "";
        userDetails  = getLocalStorageVariables();
        if(this.state.operationType === deleteClient){
            url = Constants.DeleteClient;
        }else if(this.state.operationType === reactivateClient){
            url = Constants.ActivateClient;
        }

        this.setState({
            showLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                clientId:this.state.deleteReactivateId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                },() =>{
                    this.getClientsList();
                })
            }else{
                alert(data.responseMessage);
            }
        });
    }

    render() {
        const actionArr = [
            {
                icon:"edit",
                label:editClient
            },
            {
                icon:"person_add",
                label:addClientAdmin
            },
            {
                icon:"file_copy",
                label:copyClientData
            },
            {
                icon:"group",
                label:viewUsers
            },
            {
                icon:"delete",
                label:deleteClient
            },
        ]
        const actionArrReactivate = [
            {
                icon:"group",
                label:viewUsers
            },
            {
                icon:"check_circle",
                label:reactivateClient
            },
        ]

        return(
            <Router>
                <section>
                {
                    this.state.showLoader &&
                    <div className="loading-layout"
                    style={{background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},0.7)`}}>
                        <div className="loading-text-layout">
                            <p
                            style={{color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`}}
                            >Loading....</p>
                        </div>
                    </div>
                }
                {
                    this.state.componentDidMountFlag &&
                    <AnimateHeight duration={ 300 } height={ this.props.displayCards?"auto":0 } >
                    <div className="master-card-bg">
                        <div className="master-card-col">
                            <InfoCardSpan themeSettings={this.props.themeSettings} className="material-icons">supervised_user_circle</InfoCardSpan>
                            <div className="mcc-text">
                            <h5>{ifEmptyReturnEmptyStr(this.state.infoCard.ActiveClientsCount,"0")}</h5>
                            <p>Active Clients</p>
                            </div>
                        </div>
                        <div className="master-card-col">
                            <InfoCardSpan themeSettings={this.props.themeSettings} className="material-icons">people</InfoCardSpan>
                            <div className="mcc-text">
                            <h5>{ifEmptyReturnEmptyStr(this.state.infoCard.activeUsersCount,"0")}</h5>
                            <p>Active Users </p>
                            </div>
                        </div>
                        <div className="master-card-col">
                            <InfoCardSpan themeSettings={this.props.themeSettings} className="material-icons">people</InfoCardSpan>
                            <div className="mcc-text">
                            <h5>{ifEmptyReturnEmptyStr(this.state.infoCard.maxActiveUsersCountThisMonth,"0")}</h5>
                            <p>Monthly Active Users</p>
                            </div>
                        </div>
                        <div className="master-card-col">
                            <InfoCardSpan themeSettings={this.props.themeSettings} className="material-icons">people</InfoCardSpan>
                            <div className="mcc-text">
                            <h5>{ifEmptyReturnEmptyStr(this.state.infoCard.maxActiveUsersCountLastMonth,"0")}</h5>
                            <p>Prev Month Active Users</p>
                            </div>
                        </div>
                    </div>
                    </AnimateHeight>
                }
                {
                    // Need to check component did mount flag, other wise until api is called,
                    // it shows no items found layout.
                    this.state.componentDidMountFlag &&
                    <div className="body-wrapper">
                        <div className="search-addnew-bg">
                            <div className="search-addnew">
                                <div className="search-filter-layout">
                                    <FilterDropDown
                                        placeholder={this.state.resultSizePlaceholder}
                                        dropdownArr={this.state.resultSizeArr}
                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                        themeSettings={this.props.themeSettings}
                                        mleft={0}
                                    />
                                    <div className="search-col m-l-10">
                                        <input 
                                            name="clientSearchkey" 
                                            type="text"
                                            onChange={this.handleChange}
                                            placeholder="Search" 
                                            onKeyPress={this.onEnterBtnPress}
                                            value={this.state.clientSearchkey} />
                                        <SearchSpan 
                                            className="material-icons"
                                            themeSettings={this.props.themeSettings}
                                            onClick = {this.onSearchIconClick}>search</SearchSpan>
                                    </div>
                                    <FilterDropDown
                                        placeholder={this.state.statusPlaceholder}
                                        dropdownArr={this.state.statusArr}
                                        onDropDownItemClick={this.statusDropdownClick}
                                        themeSettings={this.props.themeSettings}
                                    />
                                    
                                </div>
                                <AddNewButtonLayout
                                    themeSettings={this.props.themeSettings}
                                >
                                    <a href={Constants.URL_ADDCLIENT} data-toggle="modal" data-target="#addnewModal">
                                        <span className="material-icons">add_circle</span>
                                        <p>Add New</p>
                                    </a>
                                </AddNewButtonLayout>
                            </div>
                        </div>
                        {
                            !isEmptyArray(this.state.datalist) && 
                            <div className="common-tab-bg">
                                <div className="common-table" style={{width:"100%"}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                        <th className="text-center" width={"5%"}>No</th>
                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortClientCode)}>
                                            <div className="sort-header">Client Code
                                                <span className={(this.state.sort === sortClientCode)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                            </div>
                                        </th>
                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortShortName)}>
                                            <div className="sort-header">Short Name
                                                <span className={(this.state.sort === sortShortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                            </div>
                                        </th>
                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortCity)}>
                                            <div className="sort-header">City
                                                <span className={(this.state.sort === sortCity)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                            </div>
                                        </th>
                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortContact1)}>
                                            <div className="sort-header">Primary Contact
                                                <span className={(this.state.sort === sortContact1)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                            </div>
                                        </th>
                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortContact2)}>
                                            <div className="sort-header">Secondary Contact
                                                <span className={(this.state.sort === sortContact2)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                            </div>
                                        </th>
                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortUserCount)}>
                                            <div className="sort-header">User Count
                                                <span className={(this.state.sort === sortUserCount)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                            </div>
                                        </th>
                                        <th className="text-center"  width={"5%"}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            this.state.datalist.map((item,idx) => {
                                                return <tr onClick={this.onCompanyNameClick.bind(this,item)}>
                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                    <td>
                                                        <div className="flex-center-layout">
                                                            {item.clientCode}
                                                            {
                                                                item.copyStatus==="In Progress" &&
                                                                <strong className="copy-status">Copying</strong>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>{item.companyShortName}</td>
                                                    <td>{item.city}</td>
                                                    <td>{item.firstName1+" "+ifEmptyReturn(item.middleName1)+" "+item.lastName1}</td>
                                                    <td>{
                                                        isEmptyVariable(item.firstName2)?
                                                        "-"
                                                        :
                                                        item.firstName2+" "+ifEmptyReturn(item.middleName2)+" "+item.lastName2
                                                    }</td>
                                                    <td>{item.clientUserCount+" Users"}</td>
                                                    
                                                    <td className="text-center">
                                                        <TableDropDown 
                                                            actionArr={item.clientStatus === "Active"?actionArr:actionArrReactivate}
                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                            dropDownId = {item}
                                                            dropdownWidth = {"180px"}
                                                            themeSettings={this.props.themeSettings}
                                                        />
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            
                                </div>
                            </div>
                        }
                        {
                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                            <div class="no-items-layout">
                                <div class="no-items-card">
                                    <h6>
                                    {
                                        isEmptyVariable(this.state.apiSearchKey)?
                                        Constants.NO_RECORDS_WARNING
                                        :
                                        Constants.EMPTY_SEARCH_WARNING
                                    }
                                    </h6>
                                </div>
                            </div>
                        }
                        <div className="pagination-layout">
                            <div className="row custom-row">
                                <div className="col-md-9 custom-col">
                                {
                                    (this.state.totalCount > this.state.datalist.length) && 
                                    <Pagination 
                                        totalLength ={this.state.totalCount} 
                                        items={this.state.datalist} 
                                        onChangePage={this.onChangePage} 
                                        pageSize={this.state.resultSize}
                                        currentPageNo = {this.state.currentPageNo}
                                        initialPage={this.state.currentPageNo} 
                                        themeSettings={this.props.themeSettings}/>
                                }
                                </div>
                                <div className="col-md-3 custom-col">
                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                    <AlertDialog
                        showAlertDialog={this.state.showAlertDialog}
                        handleAlertDialogClose={this.handleAlertDialogClose}
                        type={
                            (this.state.operationType === deleteClient)
                            ?
                            Constants.ALERT_TYPE_WARNING
                            :
                            Constants.ALERT_TYPE_INFO
                        }
                        alertDialogHeading={
                            (this.state.operationType === deleteClient)
                            ?
                            "Delete Client"
                            :
                            "Reactivate Client"
                        }
                        alertDialogMessage={this.state.alertDialogMessage}
                        proceedBtnClick={this.deleteActivateClient}
                        proceedBtnLabel={
                            (this.state.operationType === deleteClient)
                            ?
                            "Delete Client"
                            :
                            "Reactivate"
                        }
                        themeSettings={this.props.themeSettings}
                    />

                    <ClientDetailsDialog 
                        clientId = {this.state.selectedClientId}
                        showClientDetailsDialog = {this.state.showClientDetailsDialog}
                        handleClientDetailsDialogClose = {this.handleClientDetailsDialogClose}
                        selectedClientName = {this.state.selectedClientName}
                        themeSettings={this.props.themeSettings}
                    />

                    <AddClientAdminDialog 
                        clientId = {this.state.selectedClientId}
                        showAddClientAdminDialog = {this.state.showAddClientAdminDialog}
                        handleAddClientAdminDialogClose = {this.handleAddClientAdminDialogClose}
                        selectedClientName = {this.state.selectedClientName}
                        themeSettings={this.props.themeSettings}
                    />
                    <CopyClientDataDialog
                        clientId = {this.state.selectedClientId}
                        showCopyClientDataDialog = {this.state.showCopyClientDataDialog}
                        handleCopyClientDataDialogClose = {this.handleCopyClientDataDialogClose}
                        selectedClientName = {this.state.selectedClientName}
                        themeSettings={this.props.themeSettings}
                    />
                    <AlertDialog
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type={Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                    themeSettings={this.props.themeSettings}
                />
                </section>
            </Router>
        );
    }
}

export default ListClients;