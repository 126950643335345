import React, { Component } from "react";
import * as Constants from "../../../../common/Global/constants";
import { getLocalStorageVariables, isEmptyVariable, ifEmptyReturn, isEmptyArray } from "../../../../common/Global/commonFunctions";
import { AddNewButtonLayout } from "../../../../common/Global/globalStyles.style";

import { Modal } from "react-bootstrap";
import moment from "moment";
const userDetails = getLocalStorageVariables();

class ReviewHistoryDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			showLoader: false,
			datalist: [],
			// totalCount:0,
			// currentPageNo: 1,
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showReviewCommentsDialog) {
			this.setState(
				{
					componentDidMountFlag: false,
					showLoader: false,
					datalist: [],
					// totalCount:0,
					// currentPageNo: 1,
					showCreateDialog: false,
				},
				() => {
					this.getInitData();
				}
			);
		}
	}
	// handleCreateDialogShow = () => {
	// 	this.setState({
	// 		showCreateDialog: true,

	// 		selectedId: "",
		
	// 	});
	// };

	// handleAddDialogClose = (reloadFlag) => {
	// 	this.setState(
	// 		{
	// 			showCreateDialog: false,
	// 		},
	// 		() => {
	// 			if (reloadFlag) this.getInitData();
	// 		}
	// 	);
	// };

	getInitData = () => {
		this.setState({
			showLoader: true,
		});

		let url="";
		if(this.props.isDraftOnward === true){
			url=Constants.GetDraftSampleCheckedRemarks;
		}else{
			url=Constants.GetSampleCheckedRemarks;
		}

		fetch(url, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentSampleCheckedId: this.props.masterDataId,
				assignmentId:this.props.assignmentId
				// pageNo:this.state.currentPageNo
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						datalist: data.data.remarks,
						// totalCount:data.data.count,
						showLoader: false,
						componentDidMountFlag: true,
					});
				} else {
					this.setState({
						datalist: [],
						// totalCount:0,
						showLoader: false,
						componentDidMountFlag: true,
					});
				}
			});
	};

	render() {
		return (
			<Modal className="review-history-dialog custom-dialog" show={this.props.showReviewCommentsDialog} onHide={this.props.handleReviewCommentsDialogClose}>
				<Modal.Header>
					<h5>Review Comments</h5>
					<button onClick={this.props.handleReviewCommentsDialogClose} type="button" data-dismiss="modal">
						<span class="material-icons">close</span>
					</button>
				</Modal.Header>
				<hr />
				<div className="modal-body">
					{this.state.showLoader && (
						<div className="modal-loading-text">
							<p>Loading....</p>
						</div>
					)}
					<div className="review-history-wrapper">
						<div className="search-addnew-bg">
							<div className="search-addnew">
								<div></div>
								{/* <AddNewButtonLayout themeSettings={this.props.themeSettings}>
									<a onClick={this.handleCreateDialogShow} href="javascript:void(0);">
										<span className="material-icons">add_circle</span>
										<p>Add New</p>
									</a>
								</AddNewButtonLayout> */}
							</div>
						</div>
						{this.state.componentDidMountFlag && (
							<div className="review-history-list">
								{!isEmptyArray(this.state.datalist) &&
									this.state.datalist.map((item) => {
										return (
											<div className="history-item-layout">
												<p className="font-bold">{item.remark}</p>
												<span>
													{" "}
													{ifEmptyReturn(item.salutation) + " " + item.firstName + " " + ifEmptyReturn(item.middleName) + " " + item.lastName} (
													{item.createBy})
												</span>
												<span className="review-history-date-span">
													{moment(item.createDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD-MMM-YYYY hh:mm a")}
												</span>
											</div>
										);
									})}
							</div>
						)}
						{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
							<div className="dialog-no-records">
								<p>{Constants.NO_RECORDS_WARNING}</p>
							</div>
						)}
					</div>
				</div>
				
			</Modal>
		);
	}
}

export default ReviewHistoryDialog;
