import React, { Component } from "react";
import * as Constants from "../../../../common/Global/constants";
import * as ReportConstants from "./constants";
import { AddNewButtonLayout } from "../../../../common/Global/globalStyles.style";
import {
	isEmptyVariable,
	ifEmptyReturnEmptyStr,
	removeHtmlTags,
	isJsonString,
	isEmptyArray,
	SplitJoinString,
} from "../../../../common/Global/commonFunctions";
import { BrowserRouter as Router } from "react-router-dom";
import html2pdf from "html2pdf.js";
import {ViewReportWrapper, FirstPageLayout,
    SecondPageLayout,ThirdPageLayout,FourthPageLayout,
    FifthPageLayout,PageMargin,HeaderLayout,FooterLayout
} from "./viewReport.style";
import moment from "moment";

class ViewFirstPageReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
            companyDownloadReport:"",
            observationsDownloadReport:"",
			showLoader: false,
			datalist: [],
			firstPageContent: "",
            showFirstPage:false,
            vaNameArr:[],
            includeLayout:true,

		};
        this.firstPageRef = React.createRef();
	}

	componentDidMount() {
	}

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) ){
            this.getInitData();

        }
    }

    getInitData = () => {
        let vaHeadingFlag = false;
        let vaNameArr = [];
       
        if(!isEmptyArray(this.props.fourthPageContent)){
           this.props.fourthPageContent.forEach((item) => {
               item.obsList.forEach((obsItem) => {
                   if (!isEmptyArray(obsItem.valueAdditions)) {
                       obsItem.valueAdditions.forEach((vaItem) => {
                           if(!vaHeadingFlag){
                               vaNameArr.push(ifEmptyReturnEmptyStr(vaItem.name, "-"))
                           }
                       })
                       vaHeadingFlag=true;
                   }
               })
           })
           this.setState({
               vaNameArr:vaNameArr
           });
       }

            
    }
    handleFirstPageClose = () =>{
        this.props.handleFirstPageClose();
    }

    handleDownloadHtmltoPdf = () => {
        const firstPage =this.firstPageRef.current;
		
		const opt = {
            margin:       [2.7, 0],
            filename:     'Report.pdf',
            image:        { type: 'jpeg', quality: 1 },
            html2canvas:  {  useCORS:true },
            jsPDF:        { orientation: 'portrait' , unit: 'cm', format: 'a4'},
            pagebreak: { before: ['.assignment-details','.executive-summary', '.value-additions','.observations'], avoid: 'img' },
		};

        this.setState({
            includeLayout:false
        },()=>{
            let clientName = ifEmptyReturnEmptyStr(this.props.headerFooterContent.clientName, " ");
            let typeOfAudit = ifEmptyReturnEmptyStr(this.props.headerFooterContent.typeOfAudit, " ");
            let companyName = ifEmptyReturnEmptyStr(this.props.headerFooterContent.companyName, " ");
            html2pdf().set(opt).from(firstPage).toPdf().get('pdf').then(function (pdf) {
                var totalPages = pdf.internal.getNumberOfPages();
                for (var i = 1; i <= totalPages; i++) {
                    let pageNumberText = "Page " + i + " of "+ totalPages;
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(0);
                    if(i!==1){
                        pdf.setLineWidth(0.01);
                        pdf.line(0,2.7-0.05, pdf.internal.pageSize.getWidth(), 2.7-0.05);
                        //Headers
                        pdf.text(clientName,ReportConstants.HeaderLeftMargin,ReportConstants.HeaderTopMargin);
                        pdf.text(typeOfAudit,pdf.internal.pageSize.getWidth()-pdf.getTextWidth(typeOfAudit)-ReportConstants.HeaderRightMargin,ReportConstants.HeaderTopMargin);
                        //Footers
                        pdf.text(companyName, ReportConstants.FooterLeftMargin, (pdf.internal.pageSize.getHeight() - 0.3));
                        pdf.text(pageNumberText, (pdf.internal.pageSize.getWidth()-pdf.getTextWidth(pageNumberText))-ReportConstants.FooterRightMargin, (pdf.internal.pageSize.getHeight() - 0.3));
                    }
                }
            }).save();

            
        })
		
	
        // let dataurl = "";
		// html2pdf().set(opt).from(firstPage).toPdf().output('datauristring').then(async function (pdf) {

        //     dataurl = pdf;
        //     this.setState({
        //         blah:"blah"
        //     },()=>{
        //         alert();
        //     })
        //     var totalPages = pdf.internal.getNumberOfPages();
        //     for (var i = 1; i <= totalPages; i++) {
        //         pdf.setPage(i);
        //         pdf.setFontSize(10);
        //         pdf.setTextColor(0);
        //         pdf.text('' + i, (pdf.internal.pageSize.getWidth()/2), (pdf.internal.pageSize.getHeight() - 0.3));
        //     } 
        // });
		
	}

	
	/*******************API CALLS*******************/
	
	/*******************API CALLS END HERE*******************/

	render() {
		return (
			<Router>
                <div className="m-t-10">
                    {this.state.showLoader && (
                        <div
                            className="loading-layout"
                            style={{ background: `rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},0.7)` }}
                        >
                            <div className="loading-text-layout">
                                <p
                                    style={{
                                        color: `rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`,
                                    }}
                                >
                                    Loading....
                                </p>
                            </div>
                        </div>
                    )}
                    
                        <div className="prev-next-layout">
                            <div className="flex-center-layout secondary-top-bar">
                                <div className="back-btn-layout" onClick={this.handleFirstPageClose}>
                                    <span class="material-icons-outlined">arrow_back_ios</span>
                                </div>
                                <h6>{"View Report"}</h6>
                            </div>
                            
                            <AddNewButtonLayout themeSettings={this.props.themeSettings}>
                                <a onClick={this.handleDownloadHtmltoPdf}>
                                    <span className="material-icons">download</span>
                                    <p>Download</p>
                                </a>
                            </AddNewButtonLayout>
                        
                        </div>
                        <ViewReportWrapper ref={this.firstPageRef} >
                        {
                            !isEmptyVariable(this.props.firstPageContent) &&
                            <FirstPageLayout>
                                {
                                    this.state.includeLayout &&
                                    <HeaderLayout>
                                        <p>Hello</p>
                                    </HeaderLayout>
                                }
                                
                                <div className="client-layout">
                                    <p>{this.props.firstPageContent.unitNameCityList}</p>
                                    <p>Address: {this.props.firstPageContent.clientAddress}</p>
                                </div>
                                <div className="company-layout">
                                    {
                                        !isEmptyVariable(this.props.firstPageContent.companyLogo) &&
                                            <img src={Constants.ImageBaseUrl+this.props.firstPageContent.companyLogo} />
                                        
                                    }
                                        <p> {this.props.firstPageContent.companyName}</p>
                                    
                                        <p>{this.props.firstPageContent.companyAddressLine1}</p>
                                    {
                                        !isEmptyVariable(this.props.firstPageContent.companyAddressLine2) &&
                                            <p>{this.props.firstPageContent.companyAddressLine2}</p>
                                    }
                                        <p>{this.props.firstPageContent.companyAddressLine3}</p>
                                </div>
                            </FirstPageLayout>
                        }
                        {this.state.includeLayout &&
                        <PageMargin data-html2canvas-ignore="true"/>}
                        {
                            !isEmptyVariable(this.props.secondPageContent) && 
                            <SecondPageLayout className="assignment-details" ref={this.secondPageRef} >
                                    <h6>ASSIGNMENT DETAILS</h6>
                                        <table >
                                            <tbody>
                                                <tr>
                                                    <th className="text-center" >Type of Assignment</th>
                                                    <td className="text-center"  >
                                                        {this.props.secondPageContent.assignmentTypeName}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">Unit</th>
                                                    <td className="text-center" >
                                                        {this.props.secondPageContent.unitList}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">Unit Address</th>
                                                    <td className="text-center" >
                                                        {this.props.secondPageContent.unitAddressList}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">Process/Function</th>
                                                    <td className="text-center" >
                                                        {this.props.secondPageContent.assignmentAreaList}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">Resources</th>
                                                    <td className="text-center" >
                                                        {this.props.secondPageContent.memberList}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">Start Date</th>
                                                    <td className="text-center" >
                                                        {this.props.secondPageContent.startDate}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">End Date</th>
                                                    <td className="text-center" >
                                                        {this.props.secondPageContent.endDate}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">Report Date</th>
                                                    <td className="text-center" >
                                                        {this.props.secondPageContent.reportDate}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">Sample Period</th>
                                                    <td className="text-center" >
                                                        {this.props.secondPageContent.samplePeriod}
                                                    </td>
                                                </tr> 
                                                {
                                                    !isEmptyVariable(this.props.secondPageContent.reportRiskLevel) &&
                                                    <tr>
                                                        <th className="text-center">Overall Risk</th>
                                                        <td className="text-center" >
                                                            {this.props.secondPageContent.reportRiskLevel}
                                                        </td>
                                                    </tr>
                                                }
                                                
                                                
                                            </tbody>
                                        </table>
                            </SecondPageLayout>
                        }
                        {
                            !isEmptyArray(this.props.thirdPageContent) &&
                            <ThirdPageLayout className="executive-summary" ref={this.thirdPageRef} >
                                    <h6> EXECUTIVE SUMMARY</h6>
                                {this.props.thirdPageContent.map((item) => {
                                   return item.obsList.map((obsItem) => {
                                        if(!isEmptyVariable(obsItem.executiveSummary)){
                                            return(
                                                <ul>
                                                    <li>
                                                        <p>{ifEmptyReturnEmptyStr(removeHtmlTags(obsItem.executiveSummary), "")} </p>
                                                    </li>
                                                    {
                                                        !isEmptyVariable(obsItem.riskLevel) && 
                                                        <li style={{ listStyleType: "none" }}>
                                                            <p> 
                                                                <strong>Risk Level:</strong>
                                                                {ifEmptyReturnEmptyStr(obsItem.riskLevel, "")}
                                                            </p>
                                                        </li>
                                                    }
                                                </ul> 
                                            )
                                        }
                                        
                                    });
                                })}
                        
                            </ThirdPageLayout>
                        }

                        {
                            !isEmptyArray(this.props.fourthPageContent) && 
                            <FourthPageLayout className="value-additions" ref={this.fourthPageRef} >
                                    <h6> Value Additions</h6>
                                
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="text-center" width={"60%"}>Observation</th>
                                                
                                                {
                                                    this.state.vaNameArr.map((vaName)=>{
                                                        return <th  width={"20%"}>{vaName}</th>
                                                    }) 
                                                }                        
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.props.fourthPageContent.map((item) => {
                                                return item.obsList.map((obsItem) => {
                                                    if (!isEmptyArray(obsItem.valueAdditions)) {
                                                        const isVaValuesNotPresent = obsItem.valueAdditions.every((vaItem) => {
                                                            return isEmptyVariable(vaItem.value);
                                                        });
                                                        if(!isVaValuesNotPresent){

                                                            return (
                                                                <tr>
                                                                    <td>{
                                                                        !isEmptyVariable(removeHtmlTags(obsItem.observationHeading))
                                                                            ? `${removeHtmlTags(obsItem.observationHeading)}`
                                                                            : `${removeHtmlTags(obsItem.observationDetail)}`
                                                                        }
                                                                    </td>
                                                                    {obsItem.valueAdditions.map((vaItem) => {
                                                                        return <td>{ifEmptyReturnEmptyStr(vaItem.value, "-")}</td>
                                                                    })}
                                                                </tr>
                                                            );
                                                        }
                                                    }
                                                })
                                            })
                                        }
                                        </tbody>
                                    </table>
                                
                            </FourthPageLayout>
                        }
                        
                        {
                            !isEmptyArray(this.props.fifthPageContent) &&
                            <FifthPageLayout className="observations" ref={this.fifthPageRef} >
                                    <h6> Observations</h6>
                                {
                                    this.props.fifthPageContent.map((obsItem) => {
                                        let columnArray = [];
                                        let rowArray = [];
                                        let selectedRiskIdArr = [];
                                        let reportHeadingArr = [];
                                        if(!isEmptyVariable(obsItem.businessRisk) && isJsonString(obsItem.businessRisk)){
                                            selectedRiskIdArr=JSON.parse(obsItem.businessRisk).map(item=>(item.split(":-:")[1]));
                                        }

                                        if(!isEmptyVariable(obsItem.reportCustomHeading)){
                                            reportHeadingArr = obsItem.reportCustomHeading.split(":--:");
                                        }

                                        //instance table
                                        if (!isEmptyArray(obsItem.instanceColumns) && !isEmptyArray(obsItem.instanceValues)) {
                                            obsItem.instanceColumns.forEach((instanceColItem) => {
                                                for (var i = 1; i <= 10; i++) {
                                                    if (!isEmptyVariable(instanceColItem["column" + i])) {
                                                        columnArray.push(instanceColItem["column" + i]);
                                                    }
                                                }
                                            });
                                            
                                            rowArray = obsItem.instanceValues.map((instanceValItem) => {
                                                let singleRow = [];
                                                for (var i = 1; i <= 10; i++) {
                                                    if (!isEmptyVariable(instanceValItem["value" + i])) {
                                                        singleRow.push(instanceValItem["value" + i]);
                                                    }
                                                }
                                                return singleRow;
                                            });
                                        }
                                        return <div className="observation-wrapper">
                                            
                                            {/*  Custom observation heading */}
                                            {!isEmptyArray(reportHeadingArr) && 
                                                reportHeadingArr.map((item)=>{
                                                    return <div>
                                                        <p> <strong>{!isEmptyVariable(item) ? removeHtmlTags(item) : ""}</strong></p>
                                                    </div>
                                                })
                                            }
                                            {/*  observation heading */}
                                            {!isEmptyVariable(obsItem.observationHeading) &&
                                                <div>
                                                    <p> <strong>{!isEmptyVariable(obsItem.observationHeading) ? removeHtmlTags(obsItem.observationHeading) : ""}</strong></p>
                                                </div>
                                            }
                                            {/*  observation detail */}
                                            {!isEmptyVariable(obsItem.observationDetail) &&
                                                <p> {!isEmptyVariable(obsItem.observationDetail) ? removeHtmlTags(obsItem.observationDetail) : ""}</p>
                                            } 
                                            {/* Instance Table */}
                                            <table>
                                                <thead>
                                                    <tr>
                                                        {/* add column array as Instance table heading */}
                                                        {
                                                            columnArray.map((instanceColItem) => {
                                                               return <th>{ifEmptyReturnEmptyStr(instanceColItem, "-")}</th>
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* add row array as Instance table values */}
                                                    {
                                                        rowArray.map((instanceValueArrItem) => {
                                                            return <tr>
                                                                {
                                                                    instanceValueArrItem.map((instanceValueItem) => {
                                                                        return <td>
                                                                            {ifEmptyReturnEmptyStr(instanceValueItem, "-")}
                                                                        </td>
                                                                    })
                                                                }

                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>

                                                {/* Business Risk */}
                                                {
                                                    !isEmptyVariable(obsItem.businessRisk) && isJsonString(obsItem.businessRisk) &&
                                                        <div>
                                                            <p><strong>Risk/Impact:</strong></p>

                                                        {
                                                            selectedRiskIdArr.map(riskIem => {
                                                                return <p>{!isEmptyVariable(riskIem) ? removeHtmlTags(riskIem) : ""}</p>
                                                            })
                                                        }
                                                        </div>

                                                }
                                                {/* Suggestions */}
                                                {
                                                    !isEmptyVariable(obsItem.suggestion) &&
                                                    <div>
                                                        <p><strong>Suggestions</strong></p>
                                                        <p>{!isEmptyVariable(obsItem.suggestion) ? removeHtmlTags(obsItem.suggestion) : ""}</p>
                                                    </div>
                                                }
                                                {/* Reponses */}
                                                {
                                                    !isEmptyArray(obsItem.responses) && 
                                                        <div>
                                                            <p><strong>Responses</strong></p>
                                                        {
                                                            obsItem.responses.map((responsesItem) => {
                                                                return <div>
                                                                    <p>Response: {ifEmptyReturnEmptyStr(responsesItem.response, "")}</p>
                                                                    <p>Target Date: {moment(responsesItem.target).format("DD/MM/YYYY")}<span>Responsibility: {responsesItem.firstName} {responsesItem.lastName}</span></p>
                                                                </div>
                                                            })
                                                        }
                                                        </div>

                                                }
                                        </div>
                                    }
                                )}
                        
                            </FifthPageLayout>
                        }
                    </ViewReportWrapper>
                </div>
					
			</Router>
		);
	}
}

export default ViewFirstPageReport;
