
import { AlignmentType, Table, TableRow, TableCell, Paragraph, ImageRun, WidthType, BorderStyle, TextRun, VerticalAlign, } from "docx";

import { isEmptyVariable, removeHtmlTags, } from "../../../common/Global/commonFunctions";
let fontName = "Times New Roman";

let tempqmsRefrenceId=9 
let temprequirementId=1 
let tempclausesRefrenceId=8 
let tempComplianceStatusId=6 
let tempRecordOfNotesId=4 

let commonSize=16

export const getAuditFindingPlanPageContent = (
  assignmentDetails,
  executionTemplateValuesMap,
  taskTamplateValueMap,
  taskList,
  processName,
  functionName,
  functionId,
  companyLogo,isLogoAvailable,
  selectedTaskTemplateFields,
  execTaskTemplateFields,
  finalEmployeeList,allAuditorList,SNFlag
) => {
  
  let tempEmployeeList =[];

  Object.values(finalEmployeeList).map((item)=>{
    if(item.functionId==functionId)
    {
      tempEmployeeList.push(item.employeeName)
    }
    // if(functionName.toLocaleLowerCase()=="qs_subcontracts")
    // {
    //   if((String(item.functionName).toLocaleLowerCase().trim()).includes(functionName.replace("_", " & ").toLocaleLowerCase().trim()) )
    //   {
    //     tempEmployeeList.push(item.employeeName)
    //   }
    // }
    // if(functionName.toLocaleLowerCase()=="construction plant and equipment (cpe)")
    // {
    //   if((String(item.functionName).trim())=="Construction Plants & Equipment (CPE)")
    //   {
    //     tempEmployeeList.push(item.employeeName)
    //   }
    // }
    // if(functionName.toLocaleLowerCase()=="quality excellence cell (qxl)")
    // {
    //   if((String(item.functionName).trim())=="Quality Excellance Cell (QxL)")
    //   {
    //     tempEmployeeList.push(item.employeeName)
    //   }
    // }
  })
  let auditFindingPlanContent = [];
  let unitName = String(assignmentDetails.unitAddressList).split(":-:")[0]+" ("+ JSON.stringify(assignmentDetails.unitNameCityList).split("(")[0].replace('"', "").trim()+")";
  let auditorName = allAuditorList.join(", ");
  let startDate =!isEmptyVariable(assignmentDetails.startDate)?localDateExtractor(assignmentDetails.startDate):"";
  let endDate =!isEmptyVariable(assignmentDetails.endDate)?localDateExtractor(assignmentDetails.endDate):"";
  let auditNo = !isEmptyVariable(assignmentDetails.referenceNo)?assignmentDetails.referenceNo:""
  let tempTableRow = [];
  // let tempTableHeader = [];
  // let tempNewTableHeaderWithAllData =[]
  // let tempRecordOfNotes =[]


  /// Logo, Audit finding Sheet, Date,Rev
  tempTableRow.push(
    new TableRow({
      tableHeader: true,
      children: [
        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          columnSpan: 5,

          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },

          children: [
            new Paragraph({
              children: [
                !isEmptyVariable(isLogoAvailable) &&
                new ImageRun({
                  data: companyLogo,
                  transformation: {
                    width: 100,
                    height: 30,
                  },
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          columnSpan: 15,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
            right: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "\t\t\t\tAudit finding sheet",
                  allCaps: true,
                  font: fontName,
                  size: 23,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
       
        new TableCell({
          columnSpan: 10,

          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },

          children: [
            new Paragraph({
              spacing: {
                after: 20,
              },
              children: [
                new TextRun({
                  text: "Doc No: ",
                  font: fontName,
                  size: commonSize,
                  bold: true,
                }),
                new TextRun({
                  text: "QF 9001 08",
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({
                  spacing: {
                    after: 20,
                  },
                  text: "Rev No: ",
                  font: fontName,
                  size: commonSize,
                  bold: true,
                }),
                new TextRun({
                  text: "R6",
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Date: ",
                  font: fontName,
                  size: commonSize,
                  bold: true,
                }),
                new TextRun({
                  text: "01/02/2022",
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// Audit No
  tempTableRow.push(
    new TableRow({
      tableHeader: true,
      children: [
        new TableCell({
          columnSpan: 3,
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Audit No:",
                  font: fontName,
                  size: commonSize,
                  bold: true,
                  allCaps: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          columnSpan: 14,

          margins: {
            top: 50,
            bottom: 50,
          },

          children: [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: auditNo,
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
          ],
        }),


        new TableCell({
          columnSpan: 1,
          width: {
            size: 1,
            type: WidthType.PERCENTAGE,
          },

          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Date:",
                  font: fontName,
                  bold: true,
                  allCaps: true,
                  size:commonSize
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          columnSpan: 13,
          margins: {
            top: 50,
            bottom: 50,
          },

          children: [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text:startDate+" - "+endDate,
                  allCaps: true,
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
          ],
        }),
      
      ],
    })
  );

  /// Site/Dept, siteName,
  tempTableRow.push(
    new TableRow({
      tableHeader: true,
      children: [
        new TableCell({
          columnSpan: 3,

          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Site:",
                  font: fontName,
                  bold: true,
                  allCaps: true,
                  size:commonSize
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          columnSpan: 14,

          margins: {
            top: 50,
            bottom: 50,
          },

          children: [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: unitName,
                  allCaps: true,
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
          ],
        }),

        new TableCell({
          columnSpan: 1,
          width: {
            size: 30,
            type: WidthType.PERCENTAGE,
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Department Name:",
                  font: fontName,
                  bold: true,
                  allCaps: true,
                  size:commonSize
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          columnSpan: 13,

          margins: {
            top: 50,
            bottom: 50,
          },

          children: [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text:functionName,
                  allCaps: true,
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// Auditee, Name
  tempTableRow.push(
    new TableRow({
      tableHeader: true,
      children: [
        new TableCell({
          columnSpan: 3,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
        
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Auditee:",
                  allCaps: true,
                  bold: true,
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          columnSpan: 14,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: String(tempEmployeeList),
                  allCaps: true,
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
          ],
        }),

        new TableCell({
          columnSpan: 1,
          width: {
            size: 1,
            type: WidthType.PERCENTAGE,
          },
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Auditor: ",
                  font: fontName,
                  bold: true,
                  allCaps: true,
                  size:commonSize
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          columnSpan: 13,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },

          margins: {
            top: 50,
            bottom: 50,
          },

          children: [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text:auditorName,
                  allCaps: true,
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
          ],
        }),

      ],
    })
  );


  auditFindingPlanContent.push(
    new Table({
      margins: {
        bottom: 50,
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        top: { style: BorderStyle.NONE},
        bottom: { style: BorderStyle.NONE},
        left: { style: BorderStyle.NONE},
        right: { style: BorderStyle.NONE},
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: tempTableRow,
    })
  );

  
  tempTableRow =[];
  /// Sr No, Requirement, C, OBS,SC , etc..
  tempTableRow.push(
    new TableRow({
      tableHeader: true,
      children: [
        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
            right: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
          },
         
          width:{
            type:WidthType.PERCENTAGE,
            size:10
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Sr No.",
                  bold:true,
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
          ],
        }),

        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
            right: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          width:{
            type:WidthType.PERCENTAGE,
            size:25
          },
          columnSpan: 5,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "ISO 9001 Standard Clauses Reference",
                  font: fontName,
                  size: commonSize,
                  bold:true,
                }),
              ],
            }),
          ],
        }),

        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
            right: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          columnSpan: 3,
          width:{
            type:WidthType.PERCENTAGE,
            size:25
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
              },
              children: [
                new TextRun({
                  text: "QMS Document Reference",
                  font: fontName,
                  size: commonSize,
                  bold:true,
                }),
              ],
            }),
          ],
        }),

        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
            right: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          columnSpan: 5,
          width:{
            type:WidthType.PERCENTAGE,
            size:60
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Requirement",
                  font: fontName,
                  size: commonSize,
                  bold:true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
            right: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          columnSpan: 4,
          width:{
            type:WidthType.PERCENTAGE,
            size:20
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "C / OBS / NC",
                  font: fontName,
                  size: commonSize,
                  bold:true,
                }),
              ],
            }),
          ],
        }),

        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
            right: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          columnSpan: 8,
          width:{
            type:WidthType.PERCENTAGE,
            size:100
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Record Note of Audit",
                  font: fontName,
                  size: commonSize,
                  bold:true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          columnSpan: 4,
          width:{
            type:WidthType.PERCENTAGE,
            size:20
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Description",
                  font: fontName,
                  size: commonSize,
                  bold:true,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// Process Name
  tempTableRow.push(
    new TableRow({
      tableHeader: true,
      children: [
        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          columnSpan: 30,
          borders: {
            bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
          },
          margins: {
            top: 50,
            bottom: 50,
          },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: processName,
                  allCaps: true,
                  bold: true,
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );


  Object.values(taskList).map((task, taskIdx) => {
    let tempTaskId = task.taskId;
    let tempAssignmentTaskId = task.assignmentTaskId;
    let clausesRefrence = [];
    let qmsRefrence = [];
    let requirement = "";
    let complianceStatus = "";
    let recordOfNotes = [];
    let tempclauseRefChildren = [];
    let tempQMSRefChildren = [];
    let tempRowSpan = 1;
    let description="";

    let valueNR ="N";


    Object.entries(taskTamplateValueMap).map(([taskKey, taskValue]) => {
      if (taskKey == tempTaskId) {
        Object.values(taskValue).map((val) => {
         
          if (val.fieldId === temprequirementId) {
            requirement = val.value;
          } else if (val.fieldId === tempqmsRefrenceId) {
            qmsRefrence = !isEmptyVariable(val.value)
              ? JSON.parse(val.value)
              : [];
          } else if (val.fieldId === tempclausesRefrenceId) {
            clausesRefrence = !isEmptyVariable(val.value)
              ? JSON.parse(val.value)
              : [];
          }
        });
      }
    });

   

    Object.entries(executionTemplateValuesMap).map(([execKey, execValue]) => {
      if (execKey == tempAssignmentTaskId) {
        Object.values(execValue).map((val) => {
          if (val.fieldId === tempComplianceStatusId) {
            complianceStatus = val.value;
          }
          else if(val.fieldId=== 1)
          {
            description=val.value
          }
          else if (val.fieldId === tempRecordOfNotesId) 
         {
          valueNR = !isEmptyVariable(val.valueNR)?val.valueNR:"N"
            recordOfNotes = !isEmptyVariable(val.value)
            ? JSON.parse(val.value)
            : [];

          }

        });
      }
    });


    // requirement = removeHtmlTags(requirement.replace(/<\/p>/g, "\n\n").replace(/&.*;/g,''));
    requirement = removeHtmlTags(requirement).replace(/&amp;/g, '&').replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/&nbsp;/g, '');

    clausesRefrence.map((item, idx) => {
      tempclauseRefChildren.push(
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: `${item}${idx !== clausesRefrence.length - 1 ? "," : ""}`,
              font: fontName,
              size: commonSize,
            }),
          ],
        })
      );
    });

    qmsRefrence.map((item, idx) => {
      tempQMSRefChildren.push(
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: `${item}${idx !== qmsRefrence.length - 1 ? "," : ""}`,
              font: fontName,
              size: commonSize,
            }),
          ],
        })
      );
    });

    tempRowSpan = !isEmptyVariable(recordOfNotes.tableArray)
      ? Object.values(recordOfNotes.tableArray).length
      : 0;

    !isEmptyVariable(recordOfNotes.tableArray) &&
      Object.values(recordOfNotes.tableArray).map((item, idx) => {
        if (idx === 0) {
          let tempRowData = [];

          /// sr No.
          tempRowData.push(
            new TableCell({
              verticalAlign:VerticalAlign.CENTER,
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
              },
              margins: {
                top: 50,
                bottom: 50,
              },
      
              width:{
                type:WidthType.PERCENTAGE,
                size:10
              },
              rowSpan: tempRowSpan,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: taskIdx + 1,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            })
          );

          /// Clause Refrence
          tempRowData.push(
            new TableCell({
              verticalAlign:VerticalAlign.CENTER,
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              columnSpan: 5,
              width:{
                type:WidthType.PERCENTAGE,
                size:25
              },
              rowSpan: tempRowSpan,
              children: tempclauseRefChildren,
            })
          );

          /// QMS Refrence
          tempRowData.push(
            new TableCell({
              verticalAlign:VerticalAlign.CENTER,
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              columnSpan: 3,
              width:{
                type:WidthType.PERCENTAGE,
                size:25
              },
              rowSpan: tempRowSpan,
              children: tempQMSRefChildren,
            })
          );

          /// Requirement
          tempRowData.push(
            new TableCell({
              verticalAlign:VerticalAlign.CENTER,
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
              },
              margins: {
                top: 50,
                bottom: 50,
                left:50,
              },
              columnSpan: 5,
              width:{
                type:WidthType.PERCENTAGE,
                size:60
              },
              rowSpan: tempRowSpan,
              children: [
                new Paragraph({
            
                  children: [
                    new TextRun({
                      text: requirement,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            })
          );

          /// Compliance Status
          tempRowData.push(
            new TableCell({
              verticalAlign:VerticalAlign.CENTER,
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              columnSpan: 4,
               width:{
                type:WidthType.PERCENTAGE,
                size:20
              },
              rowSpan: tempRowSpan,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: complianceStatus,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            })
          );

          /// Table key
          tempRowData.push(
            new TableCell({
              verticalAlign:VerticalAlign.CENTER,
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
              },
              margins: {
                top: 50,
                bottom: 50,
                left:50
              },
              columnSpan: 3,
              width:{
                type:WidthType.PERCENTAGE,
                size:50
              },
              children: [
                new Paragraph({
              
                  children: [
                    new TextRun({
                      text: String(item[0]).trim().replace(/Â/g, ""),
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            })
          );

          
          /// Table Value
          tempRowData.push(
            new TableCell({
              verticalAlign:VerticalAlign.CENTER,
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
              },
              margins: {
                top: 50,
                bottom: 50,
                left:50
              },
              columnSpan: 5,
              width:{
                type:WidthType.PERCENTAGE,
                size:50
              },
              children: [
                new Paragraph({
                
                  children: [
                    new TextRun({
                      // text: (item[1].toLocaleLowerCase()==="yes"||item[1].toLocaleLowerCase()==="no")?item[1]+"\t":"\t",
                      text: FirstColumnData(item[1],valueNR),
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            })
          );

          /// Description
          tempRowData.push(
            new TableCell({
              verticalAlign:VerticalAlign.CENTER,
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              columnSpan: 4,
              width:{
                type:WidthType.PERCENTAGE,
                size:20
              },
              rowSpan: tempRowSpan,

              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: description,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            })
          );

          tempTableRow.push(
            new TableRow({
              cantSplit: true,
              children: tempRowData,
            })
          );
        } else {
          let tempRowData = [];

          /// Table key
          tempRowData.push(
            new TableCell({
              verticalAlign:VerticalAlign.CENTER,
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
              },
              margins: {
                top: 50,
                bottom: 50,
                left:50
              },
              columnSpan: 3,
              width:{
                type:WidthType.PERCENTAGE,
                size:50
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item[0].trim().replace(/Â/g, ""),
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            })
          );

          /// Table Value
          tempRowData.push(
            new TableCell({
            verticalAlign:VerticalAlign.CENTER,
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
                right: {
                  style: BorderStyle.SINGLE,
                  color: "000000",
                  size: 3,
                },
              },
              margins: {
                top: 50,
                bottom: 50,
                left:50
              },
              columnSpan: 5,
              width:{
                type:WidthType.PERCENTAGE,
                size:50
              },
              children: [
                new Paragraph({
               
                  children: [
                    new TextRun({
                      text: item[1].trim().replace(/Â/g, ""),
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            })
          );

          tempTableRow.push(
            new TableRow({
              cantSplit: true,
              children: tempRowData,
            })
          );
        }
      });

      //  recoed of note is empty 
      if(isEmptyVariable(recordOfNotes.tableArray)){
        let tempRowData = [];
        /// sr No.
        tempRowData.push(
          new TableCell({
            verticalAlign:VerticalAlign.CENTER,
            borders: {
              bottom: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
              right: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
            },
            margins: {
              top: 50,
              bottom: 50,
            },
    
            width:{
              type:WidthType.PERCENTAGE,
              size:10
            },
            rowSpan: tempRowSpan,
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: taskIdx + 1,
                    font: fontName,
                    size: commonSize,
                  }),
                ],
              }),
            ],
          })
        );

        /// Clause Refrence
        tempRowData.push(
          new TableCell({
            verticalAlign:VerticalAlign.CENTER,
            borders: {
              bottom: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
              right: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
            },
            margins: {
              top: 50,
              bottom: 50,
            },
            columnSpan: 5,
            width:{
              type:WidthType.PERCENTAGE,
              size:25
            },
            rowSpan: tempRowSpan,
            children: tempclauseRefChildren,
          })
        );

        /// QMS Refrence
        tempRowData.push(
          new TableCell({
            verticalAlign:VerticalAlign.CENTER,
            borders: {
              bottom: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
              right: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
            },
            margins: {
              top: 50,
              bottom: 50,
            },
            columnSpan: 3,
            width:{
              type:WidthType.PERCENTAGE,
              size:25
            },
            rowSpan: tempRowSpan,
            children: tempQMSRefChildren,
          })
        );

        /// Requirement
        tempRowData.push(
          new TableCell({
            verticalAlign:VerticalAlign.CENTER,
            borders: {
              bottom: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
              right: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
            },
            margins: {
              top: 50,
              bottom: 50,
              left:50,
            },
            columnSpan: 5,
            width:{
              type:WidthType.PERCENTAGE,
              size:60
            },
            rowSpan: tempRowSpan,
            children: [
              new Paragraph({
          
                children: [
                  new TextRun({
                    text: requirement,
                    font: fontName,
                    size: commonSize,
                  }),
                ],
              }),
            ],
          })
        );

        /// Compliance Status
        tempRowData.push(
          new TableCell({
            verticalAlign:VerticalAlign.CENTER,
            borders: {
              bottom: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
              right: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
            },
            margins: {
              top: 50,
              bottom: 50,
            },
            columnSpan: 4,
             width:{
              type:WidthType.PERCENTAGE,
              size:20
            },
            rowSpan: tempRowSpan,
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: complianceStatus,
                    font: fontName,
                    size: commonSize,
                  }),
                ],
              }),
            ],
          })
        );

         /// Table key
         tempRowData.push(
          new TableCell({
            verticalAlign:VerticalAlign.CENTER,
            borders: {
              bottom: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
              right: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
            },
            margins: {
              top: 50,
              bottom: 50,
              left:50
            },
            columnSpan: 3,
            width:{
              type:WidthType.PERCENTAGE,
              size:50
            },
            children: [
              new Paragraph({
            
                children: [
                  new TextRun({
                    text: '',
                    font: fontName,
                    size: commonSize,
                  }),
                ],
              }),
            ],
          })
        );

        
        /// Table Value
        tempRowData.push(
          new TableCell({
            verticalAlign:VerticalAlign.CENTER,
            borders: {
              bottom: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
              right: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
            },
            margins: {
              top: 50,
              bottom: 50,
              left:50
            },
            columnSpan: 5,
            width:{
              type:WidthType.PERCENTAGE,
              size:50
            },
            children: [
              new Paragraph({
              
                children: [
                  new TextRun({
                    // text: (item[1].toLocaleLowerCase()==="yes"||item[1].toLocaleLowerCase()==="no")?item[1]+"\t":"\t",
                    text: '',
                    font: fontName,
                    size: commonSize,
                  }),
                ],
              }),
            ],
          })
        );

        /// Description
        tempRowData.push(
          new TableCell({
            verticalAlign:VerticalAlign.CENTER,
            borders: {
              bottom: {
                style: BorderStyle.SINGLE,
                color: "000000",
                size: 3,
              },
            },
            margins: {
              top: 50,
              bottom: 50,
            },
            columnSpan: 4,
            width:{
              type:WidthType.PERCENTAGE,
              size:20
            },
            rowSpan: tempRowSpan,

            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: description,
                    font: fontName,
                    size: commonSize,
                  }),
                ],
              }),
            ],
          })
        );

        tempTableRow.push(
          new TableRow({
            cantSplit: true,
            children: tempRowData,
          })
        );

      }
  });
  if(SNFlag=="SY")
  {
    tempTableRow.push(
      new TableRow({
        children: [
          new TableCell({
            verticalAlign:VerticalAlign.CENTER,
            columnSpan: 30,
            borders: {
              bottom: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
            },
            margins: {
              top: 50,
              bottom: 50,
              right:100
            },
            children: [
              new Paragraph({
                spacing:{
                  after:20
                },
                alignment: AlignmentType.END,
                children: [
                  new TextRun({
                    text: auditorName,
                    font: "Mistral",
                    size: commonSize+3,
                    // underline:true
                  }),
                ],
              }),
              new Paragraph({
                alignment: AlignmentType.END,
                children: [
                  new TextRun({
                    text: "Signature",
                    font: fontName,
                    size: commonSize,
                  }),
                ],
              }),
            ],
          }),
        ],
      })
    );

  }
 


  auditFindingPlanContent.push(
    new Table({
      margins: {
        bottom: 50,
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        top: { style: BorderStyle.NONE},
        bottom: { style: BorderStyle.NONE},
        left: { style: BorderStyle.NONE},
        right: { style: BorderStyle.NONE},
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: tempTableRow,
    })
  );


  return [...auditFindingPlanContent];
};

export const localDateExtractor=(date)=>{
  var date = new Date(date);
  return date.toLocaleDateString('en-GB')
}

export const FirstColumnData = (item,valueNR) =>{
  if(valueNR==="Y")
  {
    return "NA"
  }
  else{
    let tempFirstRowList =["yes / no with disable","yes / no / na with disable","yes / no","yes / no / na","textfield"]
    if(!tempFirstRowList.includes(item.toLocaleLowerCase()) )
    {
      return item.trim().replace(/Â/g, "")
    }
    
    else
    {
      return "\t\t"
    }

  }

}