import * as Constants from "./constants";
import {isEmptyVariable} from "./commonFunctions"
import * as TemplateConstants from "./templateConstants";

export const getDropdownActionArray = (allowEdit, allowReview, status, currentReviewStatus, deletePermission,status_new,previousStatus) => {
	const activeStatus = "Active";
	const inActiveStatus = "Inactive";
	const inActiveInReviewStatus = "Inactive In Review";
	const requestChangeCheckText = "Request Change";
	const updatedStatus = "Updated";
	const deletedStatus = "Deleted";
	const createdStatus = "Created";

	const editDropdownObj = {icon: "edit",label: "Edit",};
	const delDropdownObj = {icon: "delete",label: "Delete",};
	const reactivateDropdownObj = {icon: "check_circle",label: "Reactivate",};
	const submitDropdownObj = {icon: "send",label: "Submit For Review",};
	const startDropdownObj = {icon: "play_circle_filled",label: "Start Review",};
	const updateReviewDropdownObj = {icon: "wifi_protected_setup",label: "Update Review Status",};
	const reviewHistoryDropdownObj = {icon: "history",label: "Review History",};
	const reviewRemarksDropdownObj = {icon: "chat",label: "Review Remarks",};
	const discardChanges = {icon: "backspace",label: "Discard Changes",}

	let actionObj = {};
	let actionArr = [];
	let dropdownWidth = "160px";

    //when user has edit permission
    if(allowEdit === "Y"){
        if(allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW){
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus !== updatedStatus){
                actionArr.push(delDropdownObj);
            }

            //when record becomes active prev status will stay in updated/deleted
            //So we need to check this
            if(status_new !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if(allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS){
            actionArr.push(updateReviewDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus === createdStatus){
                actionArr.push(delDropdownObj);
            }

            if(status_new !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status_new === activeStatus) {
            actionArr.push(editDropdownObj);
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }

            if(status_new !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
                dropdownWidth = "170px";
            }

            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status_new === inActiveStatus || status_new === inActiveInReviewStatus) {
            actionArr.push(reactivateDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
    
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if(allowEdit === "N"){
        //when user has only delete permissions without edit
        if (allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            if(status_new !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
            actionArr.push(updateReviewDropdownObj);
            //commented for time being
            // if(deletePermission === "Y"){
            //     actionArr.push(delDropdownObj);
            // }
            if(status_new !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (status_new === activeStatus) {
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }
            
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if (allowReview === Constants.ALLOW_REVIEW_STARTREVIEW) {
        actionArr.push(startDropdownObj);
        
        // if(status_new !== activeStatus && (previousStatus === updatedStatus || previousStatus === deletedStatus ) ){
        //     actionArr.push(discardChanges);
        //     dropdownWidth = "170px";
        // }
        actionArr.push(reviewHistoryDropdownObj);

        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };

        return actionObj;
    }

    actionArr.push(reviewHistoryDropdownObj);
    actionObj = {
        actionArr: actionArr,
        dropdownWidth: dropdownWidth,
    };

    return actionObj;
};

export const getDropdownActionArrayProcessTasks = (allowEdit, allowReview, status, currentReviewStatus, deletePermission,previousStatus) => {
	const activeStatus = "Active";
	const inActiveStatus = "Inactive";
	const inActiveInReviewStatus = "Inactive In Review";
	const requestChangeCheckText = "Request Change";
	const updatedStatus = "Updated";
	const deletedStatus = "Deleted";
	const createdStatus = "Created";

	const editDropdownObj = {icon: "edit",label: "Edit",};
	const delDropdownObj = {icon: "delete",label: "Delete",};
	const reactivateDropdownObj = {icon: "check_circle",label: "Reactivate",};
	const submitDropdownObj = {icon: "send",label: "Submit For Review",};
	const startDropdownObj = {icon: "play_circle_filled",label: "Start Review",};
	const updateReviewDropdownObj = {icon: "wifi_protected_setup",label: "Update Review Status",};
	const reviewHistoryDropdownObj = {icon: "history",label: "Review History",};
	const reviewRemarksDropdownObj = {icon: "chat",label: "Review Remarks",};
	const discardChanges = {icon: "backspace",label: "Discard Changes",}

	let actionObj = {};
	let actionArr = [];
	let dropdownWidth = "160px";

    //when user has edit permission
    if(allowEdit === "Y"){
        if(allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW){
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus !== updatedStatus){
                actionArr.push(delDropdownObj);
            }

            //when record becomes active prev status will stay in updated/deleted
            //So we need to check this
            if(status !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if(allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS){
            actionArr.push(updateReviewDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus === createdStatus){
                actionArr.push(delDropdownObj);
            }

            if(status !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status === activeStatus) {
            actionArr.push(editDropdownObj);
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }

            if(status !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
                dropdownWidth = "170px";
            }

            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status === inActiveStatus || status === inActiveInReviewStatus) {
            actionArr.push(reactivateDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
    
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if(allowEdit === "N"){
        //when user has only delete permissions without edit
        if (allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            if(status !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
            actionArr.push(updateReviewDropdownObj);
            //commented for time being
            // if(deletePermission === "Y"){
            //     actionArr.push(delDropdownObj);
            // }
            if(status !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (status === activeStatus) {
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }
            
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if (allowReview === Constants.ALLOW_REVIEW_STARTREVIEW) {
        actionArr.push(startDropdownObj);
        
        // if(status_new !== activeStatus && (previousStatus === updatedStatus || previousStatus === deletedStatus ) ){
        //     actionArr.push(discardChanges);
        //     dropdownWidth = "170px";
        // }
        actionArr.push(reviewHistoryDropdownObj);

        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };

        return actionObj;
    }

    actionArr.push(reviewHistoryDropdownObj);
    actionObj = {
        actionArr: actionArr,
        dropdownWidth: dropdownWidth,
    };

    return actionObj;
};

export const getDocMasterDropdownActionArray = (allowEdit, allowReview, status, currentReviewStatus, 
    deletePermission,status_new,previousStatus,subscription) => {
	const activeStatus = "Active";
	const inActiveStatus = "Inactive";
	const inActiveInReviewStatus = "Inactive In Review";
	const requestChangeCheckText = "Request Change";
    const updatedStatus = "Updated";
	const deletedStatus = "Deleted";
	const createdStatus = "Created";

	const editDropdownObj = {icon: "edit",label: "Edit",};
	const delDropdownObj = {icon: "delete",label: "Delete",};
	const reactivateDropdownObj = {icon: "check_circle",label: "Reactivate",};
	const submitDropdownObj = {icon: "send",label: "Submit For Review",};
	const startDropdownObj = {icon: "play_circle_filled",label: "Start Review",};
	const updateReviewDropdownObj = {icon: "wifi_protected_setup",label: "Update Review Status",};
	const reviewHistoryDropdownObj = {icon: "history",label: "Review History",};
	const reviewRemarksDropdownObj = {icon: "chat",label: "Review Remarks",};
	const uploadSample = {icon: "cloud_upload",label: "Upload Sample",};
	const getSamples = {icon: "topic",label: "Show Samples",};
    const discardChanges = {icon: "backspace",label: "Discard Changes",}

	let actionObj = {};
	let actionArr = [];
	let dropdownWidth = "160px";


    if(allowEdit === "Y"){
        if(allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW){
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            actionArr.push(editDropdownObj);

            if(subscription?.docSampleUpload === "Y"){
                actionArr.push(getSamples);
                actionArr.push(uploadSample);
            }
            
            if(deletePermission === "Y" && previousStatus !== updatedStatus){
                actionArr.push(delDropdownObj);
            }

            //when record becomes active prev status will stay in updated/deleted
            //So we need to check this
            if(status_new !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if(allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS){
            actionArr.push(updateReviewDropdownObj);
            actionArr.push(editDropdownObj);

            if(subscription?.docSampleUpload === "Y"){
                actionArr.push(getSamples);
                actionArr.push(uploadSample);
            }

            if(deletePermission === "Y" && previousStatus === createdStatus){
                actionArr.push(delDropdownObj);
            }

            if(status_new !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status_new === activeStatus) {
            actionArr.push(editDropdownObj);
            
            if(subscription?.docSampleUpload === "Y"){
                actionArr.push(getSamples);
                actionArr.push(uploadSample);
            }

            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }

            if(status_new !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
                dropdownWidth = "170px";
            }

            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status_new === inActiveStatus || status_new === inActiveInReviewStatus) {
            actionArr.push(reactivateDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
    
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }

    if(allowEdit === "N"){
        //when user has only delete permissions without edit
        if (allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }

            actionArr.push(submitDropdownObj);
            if(status_new !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
            actionArr.push(updateReviewDropdownObj);
            //commented for time being
            // if(deletePermission === "Y"){
            //     actionArr.push(delDropdownObj);
            // }
            if(status_new !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (status_new === activeStatus) {
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }
            
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }

	if (allowReview === Constants.ALLOW_REVIEW_STARTREVIEW) {
		actionArr.push(startDropdownObj);
        
        // if(status_new !== activeStatus && (previousStatus === updatedStatus || previousStatus === deletedStatus ) ){
        //     actionArr.push(discardChanges);
        //     dropdownWidth = "170px";
        // }
        actionArr.push(reviewHistoryDropdownObj);

        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };

        return actionObj;
	}

    actionArr.push(reviewHistoryDropdownObj);
	actionObj = {
		actionArr: actionArr,
		dropdownWidth: dropdownWidth,
	};

	return actionObj;
};

export const getDropdownActionArrayRisk = (allowEdit, allowReview, status, currentReviewStatus, 
    deletePermission, status_new, previousStatus, showIsDefault) => {
	const activeStatus = "Active";
	const inActiveStatus = "Inactive";
	const inActiveInReviewStatus = "Inactive In Review";
	const requestChangeCheckText = "Request Change";
	const updatedStatus = "Updated";
	const deletedStatus = "Deleted";
	const createdStatus = "Created";

	const editDropdownObj = {icon: "edit",label: "Edit",};
	const delDropdownObj = {icon: "delete",label: "Delete",};
	const reactivateDropdownObj = {icon: "check_circle",label: "Reactivate",};
	const submitDropdownObj = {icon: "send",label: "Submit For Review",};
	const startDropdownObj = {icon: "play_circle_filled",label: "Start Review",};
	const updateReviewDropdownObj = {icon: "wifi_protected_setup",label: "Update Review Status",};
	const reviewHistoryDropdownObj = {icon: "history",label: "Review History",};
	const reviewRemarksDropdownObj = {icon: "chat",label: "Review Remarks",};
	const discardChanges = {icon: "backspace",label: "Discard Changes",}
	const assignToClient = {icon: "cloud_upload",label: "Assign to Client",};
	const setDefault = {icon: "settings",label: "Set as Default",};

	let actionObj = {};
	let actionArr = [];
	let dropdownWidth = "160px";

    if(allowEdit === "Y"){
        if(allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW){
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus !== updatedStatus){
                actionArr.push(delDropdownObj);
            }

            //when record becomes active prev status will stay in updated/deleted
            //So we need to check this
            if(status_new !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        
        if(allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS){
            actionArr.push(updateReviewDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus === createdStatus){
                actionArr.push(delDropdownObj);
            }

            if(status_new !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (status_new === activeStatus) {
            actionArr.push(editDropdownObj);
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }
            // actionArr.push(assignToClient);
            if(showIsDefault === "Y"){
                actionArr.push(setDefault);
            }

            if(status_new !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
                dropdownWidth = "170px";
            }

            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (status_new === inActiveStatus || status_new === inActiveInReviewStatus) {
            actionArr.push(reactivateDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
    
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }

    if(allowEdit === "N"){
        //when user has only delete permissions without edit
        if (allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            if(status_new !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
            actionArr.push(updateReviewDropdownObj);
            //commented for time being
            // if(deletePermission === "Y"){
            //     actionArr.push(delDropdownObj);
            // }
            if(status_new !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (status_new === activeStatus) {
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }
            
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }

    if (allowReview === Constants.ALLOW_REVIEW_STARTREVIEW) {
        actionArr.push(startDropdownObj);
        actionArr.push(reviewHistoryDropdownObj);

        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };

        return actionObj;
    }

    actionArr.push(reviewHistoryDropdownObj);
    actionObj = {
        actionArr: actionArr,
        dropdownWidth: dropdownWidth,
    };

    return actionObj;
};

export const getCheckListDropdownActionArray = (allowEdit, allowReview, status, currentReviewStatus, deletePermission,previousStatus) => {
	const activeStatus = "Active";
	const inActiveStatus = "Inactive";
	const inActiveInReviewStatus = "Inactive In Review";
	const requestChangeCheckText = "Request Change";
    const updatedStatus = "Updated";
	const deletedStatus = "Deleted";
	const createdStatus = "Created";

	const editDropdownObj = {icon: "edit",label: "Edit",};
	const headingDetailsDropdownObj = {icon: "list_alt",label: "Details",};
	const delDropdownObj = {icon: "delete",label: "Delete",};
	const reactivateDropdownObj = {icon: "check_circle",label: "Reactivate",};
	const submitDropdownObj = {icon: "send",label: "Submit For Review",};
	const startDropdownObj = {icon: "play_circle_filled",label: "Start Review",};
	const updateReviewDropdownObj = {icon: "wifi_protected_setup",label: "Update Review Status",};
	const reviewHistoryDropdownObj = {icon: "history",label: "Review History",};
	const reviewRemarksDropdownObj = {icon: "chat",label: "Review Remarks",};
	const versions = {icon: "cloud_upload",label: "Versions",};
    const discardChanges = {icon: "backspace",label: "Discard Changes",}
    
	let actionObj = {};
	let actionArr = [];
	let dropdownWidth = "160px";

    if(allowEdit === "Y"){
        if(allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW){
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus !== updatedStatus){
                actionArr.push(delDropdownObj);
            }

            //when record becomes active prev status will stay in updated/deleted
            //So we need to check this
            if(status !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(versions);
	        actionArr.push(headingDetailsDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if(allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS){
            actionArr.push(updateReviewDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus === createdStatus){
                actionArr.push(delDropdownObj);
            }

            if(status !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(versions);
	        actionArr.push(headingDetailsDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status === activeStatus) {
            actionArr.push(editDropdownObj);
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }

            if(status !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
                dropdownWidth = "170px";
            }

            actionArr.push(versions);
	        actionArr.push(headingDetailsDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status === inActiveStatus || status === inActiveInReviewStatus) {
            actionArr.push(reactivateDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
    
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if(allowEdit === "N"){
        //when user has only delete permissions without edit
        if (allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            if(status !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(versions);
	        actionArr.push(headingDetailsDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
            actionArr.push(updateReviewDropdownObj);
            //commented for time being
            // if(deletePermission === "Y"){
            //     actionArr.push(delDropdownObj);
            // }
            if(status !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(versions);
	        actionArr.push(headingDetailsDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (status === activeStatus) {
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }
            
            actionArr.push(versions);
	        actionArr.push(headingDetailsDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if (allowReview === Constants.ALLOW_REVIEW_STARTREVIEW) {
        actionArr.push(startDropdownObj);
        
        // if(status_new !== activeStatus && (previousStatus === updatedStatus || previousStatus === deletedStatus ) ){
        //     actionArr.push(discardChanges);
        //     dropdownWidth = "170px";
        // }
        actionArr.push(reviewHistoryDropdownObj);

        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };

        return actionObj;
    }

    actionArr.push(reviewHistoryDropdownObj);
    actionObj = {
        actionArr: actionArr,
        dropdownWidth: dropdownWidth,
    };

    return actionObj;
};

export const getDropdownActionArrayPlanned = (allowEdit, allowReview, status, 
    currentReviewStatus, deletePermission, previousStatus, isSchedulable) => {
	const activeStatus = "Reviewed";
	const inActiveStatus = "Inactive";
	const inActiveInReviewStatus = "Inactive In Review";
	const requestChangeCheckText = "Request Change";
    const updatedStatus = "Updated";
	const deletedStatus = "Deleted";
	const createdStatus = "Created";

	const editDropdownObj = {icon: "edit",label: "Edit",};
	const delDropdownObj = {icon: "delete",label: "Delete",};
	const reactivateDropdownObj = {icon: "check_circle",label: "Reactivate",};
	const submitDropdownObj = {icon: "send",label: "Submit For Review",};
	const startDropdownObj = {icon: "play_circle_filled",label: "Start Review",};
	const updateReviewDropdownObj = {icon: "wifi_protected_setup",label: "Update Review Status",};
	const reviewHistoryDropdownObj = {icon: "history",label: "Review History",};
	const reviewRemarksDropdownObj = {icon: "chat",label: "Review Remarks",};
	const discardChanges = {icon: "backspace",label: "Discard Changes",}
	const scheduleAssignment = {icon: "schedule",label: "Schedule Assignment",};

	let actionObj = {};
	let actionArr = [];
	let dropdownWidth = "160px";

    
    if(allowEdit === "Y"){
        if(allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW){
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus !== updatedStatus){
                actionArr.push(delDropdownObj);
            }

            //when record becomes active prev status will stay in updated/deleted
            //So we need to check this
            if(status !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if(allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS){
            actionArr.push(updateReviewDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus === createdStatus){
                actionArr.push(delDropdownObj);
            }

            if(status !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status === activeStatus) {
            actionArr.push(editDropdownObj);
            
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }

            if (isSchedulable === "Y") {
                // actionArr.push(scheduleAssignment);
                // dropdownWidth = "200px";
            }

            if(status !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
                dropdownWidth = "200px";
            }

            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status === inActiveStatus || status === inActiveInReviewStatus) {
            actionArr.push(reactivateDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
    
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if(allowEdit === "N"){
        //when user has only delete permissions without edit
        if (allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            if(status !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
            actionArr.push(updateReviewDropdownObj);
            //commented for time being
            // if(deletePermission === "Y"){
            //     actionArr.push(delDropdownObj);
            // }
            if(status !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (status === activeStatus) {
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }
            
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if (allowReview === Constants.ALLOW_REVIEW_STARTREVIEW) {
        actionArr.push(startDropdownObj);
        
        // if(status_new !== activeStatus && (previousStatus === updatedStatus || previousStatus === deletedStatus ) ){
        //     actionArr.push(discardChanges);
        //     dropdownWidth = "170px";
        // }
        actionArr.push(reviewHistoryDropdownObj);

        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };

        return actionObj;
    }
};

export const getDropdownActionArrayScheduled = (allowEdit, allowReview, status, currentReviewStatus,
    screen, isResourceAssigned, isReportResourceAssigned,startAssignment,assignmentStatus, assignmentArea,
    checkList, deletePermission, previousStatus) => {
    const activeStatus="Reviewed";
    const inActiveStatus="Inactive";
    const inActiveInReviewStatus="Inactive In Review";
    const requestChangeCheckText="Request Change";
    const updatedStatus = "Updated";
	const deletedStatus = "Deleted";
	const createdStatus = "Created";
    
    const editDropdownObj = {icon:"edit",label:"Edit"}
    const delDropdownObj = {icon:"delete",label:"Delete"}
    const reactivateDropdownObj = {icon:"check_circle",label:"Reactivate"}
    const submitDropdownObj = {icon:"send",label:"Submit For Review"}
    const startDropdownObj = {icon:"play_circle_filled",label:"Start Review"}
    const updateReviewDropdownObj = {icon:"wifi_protected_setup",label:"Update Review Status"}
    const reviewHistoryDropdownObj = {icon:"history",label:"Review History"}
    const reviewRemarksDropdownObj = {icon:"chat",label:"Review Remarks"}
    const assignTasks = {icon:"assignment",label:"Assign Tasks"}
	const changeChecklist = {icon:"checklist",label:"Change Checklist"}
    const startAssignmentAction = {icon:"play_circle_filled",label:"Start Assignment"}
	const resources = {icon:"assignment_ind",label:"Assign Resources"}
	const reassignAllResources = {icon:"assignment_ind",label:"Reassign Resources"}
    const discardChanges = {icon: "backspace",label: "Discard Changes",}
    // const assignResources = {icon:"assignment_ind",label:"Assign Resources"}
    // const reAssignResources = {icon:"assignment_ind",label:"Reassign Resources"}
    // const assignReportResources = {icon:"folder_shared",label:"Assign Report Resources"}
    // const reAssignReportResources = {icon:"folder_shared",label:"Reassign Report Resources"}
    
    let actionObj = {};
    let actionArr = []
    let dropdownWidth = "160px";

    //when user has edit permission
    if(allowEdit === "Y"){
        if(allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW){
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            actionArr.push(editDropdownObj);

            if(isResourceAssigned === 1 && isReportResourceAssigned === 1){
                actionArr.push(reassignAllResources);
            }else{
                actionArr.push(resources)
            }

            actionArr.push(assignTasks)
            if(assignmentArea === "CheckList" && !isEmptyVariable(checkList) &&
            assignmentStatus === "Scheduled"){
                actionArr.push(changeChecklist);
            }

            if(deletePermission === "Y" && previousStatus !== updatedStatus){
                actionArr.push(delDropdownObj);
            }

            //when record becomes active prev status will stay in updated/deleted
            //So we need to check this
            if(status !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth="230px"
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if(allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS){
            actionArr.push(updateReviewDropdownObj);
            actionArr.push(editDropdownObj);

            if(isResourceAssigned === 1 && isReportResourceAssigned === 1){
                actionArr.push(reassignAllResources);
            }else{
                actionArr.push(resources)
            }
            actionArr.push(assignTasks)
            if(assignmentArea === "CheckList" && !isEmptyVariable(checkList) && 
            assignmentStatus === "Scheduled"){
                actionArr.push(changeChecklist);
            }

            if(deletePermission === "Y" && previousStatus === createdStatus){
                actionArr.push(delDropdownObj);
            }

            if(status !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "230px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status === "Reviewed" || status === "Waiting for Review") {
            if(assignmentStatus === "Scheduled" || assignmentStatus === "Ongoing"){
                actionArr.push(editDropdownObj);
            }

            if(isResourceAssigned === 1 && isReportResourceAssigned === 1){
                actionArr.push(reassignAllResources);
            }else{
                actionArr.push(resources)
            }

            if(assignmentStatus === "Scheduled" || assignmentStatus === "Ongoing" ){
                actionArr.push(assignTasks)
                if(assignmentArea === "CheckList" && !isEmptyVariable(checkList) && 
                assignmentStatus === "Scheduled"){
                    actionArr.push(changeChecklist);
                }
            }

            if(deletePermission === "Y" && assignmentStatus === "Scheduled"){
                actionArr.push(delDropdownObj);
            }

            if(startAssignment+"" === "1"){
                actionArr.push(startAssignmentAction)
            }

            // if(status_new !== activeStatus && 
            // (previousStatus === updatedStatus || previousStatus === deletedStatus)){
            //     actionArr.push(discardChanges);
            //     dropdownWidth = "170px";
            // }
            dropdownWidth="240px"

            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (status === inActiveStatus || status === inActiveInReviewStatus) {
            actionArr.push(reactivateDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
    
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if(allowEdit === "N"){
        //when user has only delete permissions without edit
        if (allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            if(status !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
            actionArr.push(updateReviewDropdownObj);
            //commented for time being
            // if(deletePermission === "Y"){
            //     actionArr.push(delDropdownObj);
            // }
            if(status !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (status === activeStatus) {
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }
            
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if (allowReview === Constants.ALLOW_REVIEW_STARTREVIEW) {
        actionArr.push(startDropdownObj);
        
        // if(status_new !== activeStatus && (previousStatus === updatedStatus || previousStatus === deletedStatus ) ){
        //     actionArr.push(discardChanges);
        //     dropdownWidth = "170px";
        // }
        actionArr.push(reviewHistoryDropdownObj);

        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };

        return actionObj;
    }

    actionArr.push(reviewHistoryDropdownObj);
    actionObj = {
        actionArr: actionArr,
        dropdownWidth: dropdownWidth,
    };
    return actionObj;
}
export const getDropdownActionArrayFollowUp = (assignmentItem, isReportResourceAssigned, deletePermission, endReviewUserId, userId) => {
    const activeStatus="Reviewed";
    const inActiveStatus="Inactive";
    const inActiveInReviewStatus="Inactive In Review";
    const requestChangeCheckText="Request Change";
    const updatedStatus = "Updated";
	const deletedStatus = "Deleted";
	const createdStatus = "Created";
    
    const delDropdownObj = {icon:"delete",label:"Delete"}
    const reactivateDropdownObj = {icon:"check_circle",label:"Reactivate"}
    const submitDropdownObj = {icon:"send",label:"Submit For Review"}
    const startDropdownObj = {icon:"play_circle_filled",label:"Start Review"}
    const updateReviewDropdownObj = {icon:"wifi_protected_setup",label:"Update Review Status"}
    const reviewHistoryDropdownObj = {icon:"history",label:"Review History"}
    const assignTasks = {icon:"assignment",label:"Assign Tasks"}
    const assignObservationTasks = {icon:"assignment",label:"Assign Observation/Tasks"}
	const resources = {icon:"assignment_ind",label:"Assign Resources"}
	const reassignAllResources = {icon:"assignment_ind",label:"Reassign Resources"}
    const discardChanges = {icon: "backspace",label: "Discard Changes"}
    
    let actionObj = {};
    let actionArr = []
    let dropdownWidth = "160px";

    //when user has edit permission
    if(assignmentItem.allowEdit === "Y"){
        if(assignmentItem.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW){
            actionArr.push(submitDropdownObj);

            if(assignmentItem.isResourceAssigned === 1 && isReportResourceAssigned === 1){
                actionArr.push(reassignAllResources);
            }else{
                actionArr.push(resources)
            }
            if(!isEmptyVariable(assignmentItem.observationTemplateId)){
                actionArr.push(assignObservationTasks);
            }else{
                actionArr.push(assignTasks);
            }

            if(deletePermission === "Y" && assignmentItem.previousStatus !== updatedStatus){
                actionArr.push(delDropdownObj);
            }

            //when record becomes active prev status will stay in updated/deleted
            //So we need to check this
            if(assignmentItem.status !== activeStatus && 
            (assignmentItem.previousStatus === deletedStatus || assignmentItem.previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth="230px"
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if(assignmentItem.allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS){
            actionArr.push(updateReviewDropdownObj);

            if(assignmentItem.isResourceAssigned === 1 && isReportResourceAssigned === 1){
                actionArr.push(reassignAllResources);
            }else{
                actionArr.push(resources)
            }
            if(!isEmptyVariable(assignmentItem.observationTemplateId)){
                actionArr.push(assignObservationTasks);
            }else{
                actionArr.push(assignTasks);
            }

            if(deletePermission === "Y" && assignmentItem.previousStatus === createdStatus){
                actionArr.push(delDropdownObj);
            }

            if(assignmentItem.status !== activeStatus && 
            (assignmentItem.previousStatus === updatedStatus || assignmentItem.previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "230px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (assignmentItem.status === "Reviewed" || assignmentItem.status === "Waiting for Review") {
            if(assignmentItem.isResourceAssigned === 1 && isReportResourceAssigned === 1){
                actionArr.push(reassignAllResources);
            }else{
                actionArr.push(resources)
            }

            if(assignmentItem.assignmentStatus === "Completed"){
                if(!isEmptyVariable(assignmentItem.observationTemplateId)){
                actionArr.push(assignObservationTasks);
            }else{
                actionArr.push(assignTasks);
            }
            }

            if(deletePermission === "Y" && assignmentItem.assignmentStatus === "Completed"){
                actionArr.push(delDropdownObj);
            }

            dropdownWidth="240px"

            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
        if (assignmentItem.status === inActiveStatus || assignmentItem.status === inActiveInReviewStatus) {
            actionArr.push(reactivateDropdownObj);
            actionArr.push(reviewHistoryDropdownObj);
    
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if(assignmentItem.allowEdit === "N"){
        //when user has only delete permissions without edit
        if (assignmentItem.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
            actionArr.push(submitDropdownObj);
            if(assignmentItem.status !== activeStatus && 
            (assignmentItem.previousStatus === deletedStatus || assignmentItem.previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "190px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (assignmentItem.allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
            actionArr.push(updateReviewDropdownObj);
            //commented for time being
            // if(deletePermission === "Y"){
            //     actionArr.push(delDropdownObj);
            // }
            if(assignmentItem.status !== activeStatus && 
            (assignmentItem.previousStatus === updatedStatus || assignmentItem.previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }

            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }

        if (assignmentItem.status === activeStatus) {
            if(deletePermission === "Y"){
                actionArr.push(delDropdownObj);
            }
            
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }
    
    if (assignmentItem.allowReview === Constants.ALLOW_REVIEW_STARTREVIEW) {
        actionArr.push(startDropdownObj);
        
        // if(status_new !== activeStatus && (previousStatus === updatedStatus || previousStatus === deletedStatus ) ){
        //     actionArr.push(discardChanges);
        //     dropdownWidth = "170px";
        // }
        actionArr.push(reviewHistoryDropdownObj);

        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };

        return actionObj;
    }

    actionArr.push(reviewHistoryDropdownObj);
    actionObj = {
        actionArr: actionArr,
        dropdownWidth: dropdownWidth,
    };
    return actionObj;
}
export const getDropdownActionArrayScheduledOldDel = (allowEdit, allowReview, status, currentReviewStatus,
    screen, isResourceAssigned, isReportResourceAssigned,startAssignment,assignmentStatus, assignmentArea,checkList) => {
    
    const activeStatus="Active";
    const inActiveStatus="Inactive";
    const inActiveInReviewStatus="Inactive In Review";
    const requestChangeCheckText="Request Change";
    
    const editDropdownObj = {icon:"edit",label:"Edit"}
    const delDropdownObj = {icon:"delete",label:"Delete"}
    const reactivateDropdownObj = {icon:"check_circle",label:"Reactivate"}
    const submitDropdownObj = {icon:"send",label:"Submit For Review"}
    const startDropdownObj = {icon:"play_circle_filled",label:"Start Review"}
    const updateReviewDropdownObj = {icon:"wifi_protected_setup",label:"Update Review Status"}
    const reviewHistoryDropdownObj = {icon:"history",label:"Review History"}
    const reviewRemarksDropdownObj = {icon:"chat",label:"Review Remarks"}
    const assignTasks = {icon:"assignment",label:"Assign Tasks"}
	const changeChecklist = {icon:"checklist",label:"Change Checklist"}
    const startAssignmentAction = {icon:"play_circle_filled",label:"Start Assignment"}
	const resources = {icon:"assignment_ind",label:"Assign Resources"}
	const reassignAllResources = {icon:"assignment_ind",label:"Reassign Resources"}
    // const assignResources = {icon:"assignment_ind",label:"Assign Resources"}
    // const reAssignResources = {icon:"assignment_ind",label:"Reassign Resources"}
    // const assignReportResources = {icon:"folder_shared",label:"Assign Report Resources"}
    // const reAssignReportResources = {icon:"folder_shared",label:"Reassign Report Resources"}
    
    let actionObj = {};
    let actionArr = []
    let dropdownWidth = "160px";

    //submit for review
    if(allowEdit === "Y" && 
    allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW){

        if(currentReviewStatus.includes(requestChangeCheckText)){
            actionArr.push(reviewRemarksDropdownObj)
        }
		
        actionArr.push(submitDropdownObj)
        actionArr.push(editDropdownObj)
        // if(isResourceAssigned === 0){
		// 	actionArr.push(assignResources)
        // }else{
        //     actionArr.push(reAssignResources)
        // }
        // if(isReportResourceAssigned === 0){
        //     actionArr.push(assignReportResources)
        // }else{
        //     actionArr.push(reAssignReportResources)
        // }
		if(isResourceAssigned === 1 && isReportResourceAssigned === 1){
			actionArr.push(reassignAllResources);
		}else{
			actionArr.push(resources)
		}
        actionArr.push(assignTasks)
		if(assignmentArea === "CheckList" && !isEmptyVariable(checkList)){
			actionArr.push(changeChecklist);
		}
        actionArr.push(delDropdownObj)
        dropdownWidth="230px"
    }

    //Update Review Status
    if(allowEdit === "Y" && 
    allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS){
        actionArr.push(updateReviewDropdownObj)
        actionArr.push(editDropdownObj)
        // if(isResourceAssigned === 0){
        //     actionArr.push(assignResources)
        // }else{
        //     actionArr.push(reAssignResources)
        // }
        // if(isReportResourceAssigned === 0){
        //     actionArr.push(assignReportResources)
        // }else{
        //     actionArr.push(reAssignReportResources)
        // }
		if(isResourceAssigned === 1 && isReportResourceAssigned === 1){
			actionArr.push(reassignAllResources);
		}else{
			actionArr.push(resources)
		}
        actionArr.push(assignTasks)
		if(assignmentArea === "CheckList" && !isEmptyVariable(checkList)){
			actionArr.push(changeChecklist);
		}
        actionArr.push(delDropdownObj)
        dropdownWidth="230px"
    }
    //Start Review
    if(allowReview === Constants.ALLOW_REVIEW_STARTREVIEW){
        actionArr.push(startDropdownObj)
        dropdownWidth="160px"
    }
    //Reviewed Status and Waiting for Review
    if(allowEdit === "Y" && (status === "Reviewed" || status === "Waiting for Review")){
        if(assignmentStatus === "Scheduled" || assignmentStatus === "Ongoing"){
            actionArr.push(editDropdownObj)
        }

        // if(isResourceAssigned === 0){
        //     actionArr.push(assignResources)
        // }else{
        //     if(assignmentStatus === "Scheduled" || assignmentStatus === "Ongoing" ){
        //         actionArr.push(reAssignResources)

        //     }
        // }
        // if(isReportResourceAssigned === 0){
        //     actionArr.push(assignReportResources)
        // }else{
        //     actionArr.push(reAssignReportResources)
        // }

        if(isResourceAssigned === 1 && isReportResourceAssigned === 1){
			actionArr.push(reassignAllResources);
		}else{
			actionArr.push(resources)
		}

        if(assignmentStatus === "Scheduled" || assignmentStatus === "Ongoing" ){
            actionArr.push(assignTasks)
			if(assignmentArea === "CheckList" && !isEmptyVariable(checkList)){
				actionArr.push(changeChecklist);
			}
        }

        
        // if(startAssignment === 1 && assignmentStatus === "Scheduled"){
        //     actionArr.push(startAssignmentAction)
        // }
        
        if(assignmentStatus === "Scheduled"){
            actionArr.push(delDropdownObj)
			//Start assigment action
            actionArr.push(startAssignmentAction)
        }
        dropdownWidth="240px"
    }
    //Inactive Status
    if(allowEdit === "Y" && (status === inActiveStatus || status === inActiveInReviewStatus)){
        actionArr.push(reactivateDropdownObj)
    }

    actionArr.push(reviewHistoryDropdownObj)

    actionObj = {
        actionArr:actionArr,
        dropdownWidth:dropdownWidth
    }

    return actionObj;
}

export const getFollowUpTasks = (taskItem, reviewAssignmentTask) => {
	let dropdownWidth = "200px";
    
    const submitDropdownObj = {icon: "send",label: "Submit For Review",};
    const startDropdownObj = {icon:"play_circle_filled",label:"Start Review"}
	const updateReviewDropdownObj = {icon: "wifi_protected_setup",label: "Update Review Status",};
    const followUpDetails = {icon:"check",label:"Follow Up Details"}
    const reviewHistoryDropdownObj = {icon:"history",label:"Review History"}
	let actionArr = [];

    if(reviewAssignmentTask === "N"){
        if(taskItem.allowEdit === "Y"){
            if (taskItem.completionStatus === "Completed" && isEmptyVariable(taskItem.observationStatus)) {
                if(taskItem.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW && taskItem.closeOpenStatus === "Close") {
                    if(taskItem.currentReviewLevel!=taskItem.endReviewLevel){
                        actionArr.push(submitDropdownObj);
                    }
                    
                }else if(taskItem.allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS && taskItem.closeOpenStatus === "Close") {
                    actionArr.push(updateReviewDropdownObj);
                }
            }else if(taskItem.completionStatus === "Pending" && taskItem.closeOpenStatus === "Close" && taskItem.currentReviewLevel != taskItem.endReviewLevel){
                if(taskItem.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
                    actionArr.push(submitDropdownObj);
                }
            }
        }
        if (taskItem.allowReview === Constants.ALLOW_REVIEW_STARTREVIEW) {
            actionArr.push(startDropdownObj);
        }
    }else{
    }

    actionArr.push(followUpDetails);
    actionArr.push(reviewHistoryDropdownObj);

	let actionObj = {
		actionArr: actionArr,
		dropdownWidth: dropdownWidth,
	};
	return actionObj;
};

export const getObservationTasks = (area, allowEdit, allowReview, completionStatus, 
    observationStatus,myPosition,valueAddition, reviewAssignmentTask) => {
	let dropdownWidth = "200px";

	const showGuidanceObj = {icon: "visibility",label: "Show Guidance",};
	const markCompletedObj = {icon: "play_circle_filled",label: "Mark as Completed",};
	// const previousAssignmentObj = {icon: "manage_search",label: "Previous Assignment Data",};
    const submitDropdownObj = {icon: "send",label: "Submit For Review",};
	const updateReviewDropdownObj = {icon: "wifi_protected_setup",label: "Update Review Status",};
	const reopenTaskObj = {icon: "replay",label: "Reopen Task",}
	const valueAdditionObj = {icon: "library_add",label: "Value Additions",}
	let actionArr = [];

	if (area === "CheckList") {
		actionArr.push(showGuidanceObj);
	}

    if(reviewAssignmentTask === "Y"){
        if(allowEdit === "Y"){
            if (completionStatus === "Pending" && isEmptyVariable(observationStatus)) {
                actionArr.push(markCompletedObj);
                // actionArr.push(previousAssignmentObj)
                if(valueAddition === "Y")  actionArr.push(valueAdditionObj);
            }

            if (completionStatus === "Completed" && isEmptyVariable(observationStatus)) {
                if(allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW){
                    actionArr.push(reopenTaskObj);
                    if(valueAddition === "Y") actionArr.push(valueAdditionObj);
                    actionArr.push(submitDropdownObj);
                }else if(allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
                    actionArr.push(updateReviewDropdownObj);
                }
            }
        }
    }else{
        if (completionStatus === "Pending" && isEmptyVariable(observationStatus)) {
            actionArr.push(markCompletedObj);
            // actionArr.push(previousAssignmentObj)
            if(valueAddition === "Y")  actionArr.push(valueAdditionObj);
        }
        
        if (completionStatus === "Completed" && isEmptyVariable(observationStatus)) {
            actionArr.push(reopenTaskObj);
        }
    }

	let actionObj = {
		actionArr: actionArr,
		dropdownWidth: dropdownWidth,
	};

	return actionObj;
};

export const getReportAssignmentTasksActions = (area, allowEdit,valueAddition) => {
	let dropdownWidth = "200px";

	const showGuidanceObj = {icon: "visibility",label: "Show Guidance",};
	const valueAdditionObj = {icon: "library_add",label: "Value Additions",}
	let actionArr = [];

	if (area === "CheckList") {
		actionArr.push(showGuidanceObj);
	}

    if(allowEdit === "Y"){
        if(valueAddition === "Y")  actionArr.push(valueAdditionObj);
    }

	let actionObj = {
		actionArr: actionArr,
		dropdownWidth: dropdownWidth,
	};

	return actionObj;
};

export const getDropdownActionArrayObservationList = (allowEdit, allowReview, status, 
    currentReviewStatus, taskList) => {
    const inActiveStatus = "Inactive";
    const inProgressStatus = "In Progress";
    
	const inActiveInReviewStatus = "Inactive In Review";
	const requestChangeCheckText = "Request Change";

	const editDropdownObj = {
		icon: "edit",
		label: "Edit",
	};
	const delDropdownObj = {
		icon: "delete",
		label: "Delete",
	};
	const reactivateDropdownObj = {
		icon: "check_circle",
		label: "Reactivate",
	};
	const submitDropdownObj = {
		icon: "send",
		label: "Submit For Review",
	};
	const startDropdownObj = {
		icon: "play_circle_filled",
		label: "Start Review",
	};
	const updateReviewDropdownObj = {
		icon: "wifi_protected_setup",
		label: "Update Review Status",
	};
	const reviewHistoryDropdownObj = {
		icon: "history",
		label: "Review History",
	};
	const reviewRemarksDropdownObj = {
		icon: "chat",
		label: "Review Remarks",
	};
	const uploadSample = {
		icon: "cloud_upload",
		label: "Upload Sample",
	};
	const markComplete = {
		icon: "topic",
		label: "Mark Complete",
	};

	let actionObj = {};
	let actionArr = [];
	let dropdownWidth = "160px";

	if (allowEdit === "Y" && allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
		if (currentReviewStatus.includes(requestChangeCheckText)) {
			actionArr.push(reviewRemarksDropdownObj);
		}

		actionArr.push(submitDropdownObj);
		actionArr.push(editDropdownObj);
		actionArr.push(delDropdownObj);
		dropdownWidth = "190px";
	}

	if (allowEdit === "Y" && allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
		actionArr.push(updateReviewDropdownObj);
		actionArr.push(editDropdownObj);
		actionArr.push(delDropdownObj);
		dropdownWidth = "210px";
	}

	if (allowReview === Constants.ALLOW_REVIEW_STARTREVIEW) {
		actionArr.push(startDropdownObj);
		dropdownWidth = "160px";
	}

	if (allowEdit === "Y" && status === inProgressStatus) {
		actionArr.push(editDropdownObj);
        actionArr.push(delDropdownObj);
        
        // if(!isEmptyVariable(taskList)){
            actionArr.push(markComplete);
        // }
	}

	if (allowEdit === "Y" && (status === inActiveStatus || status === inActiveInReviewStatus)) {
		actionArr.push(reactivateDropdownObj);
	}

	actionArr.push(reviewHistoryDropdownObj);

	actionObj = {
		actionArr: actionArr,
		dropdownWidth: dropdownWidth,
	};

	return actionObj;
};

export const getDropdownActionArrayOngoingReports = (allowEdit, allowReview, status, 
	currentReviewStatus, createDraft,reportRiskLevel) => {
	// const showObservations = {
	// 	icon: "topic",
	// 	label: "Show Observations",
	// };
	const submitDropdownObj = {
		icon: "send",
		label: "Submit For Review",
	};
	const reviewRemarksDropdownObj = {
		icon: "chat",
		label: "Review Remarks",
	};
	const updateReviewDropdownObj = {
		icon: "wifi_protected_setup",
		label: "Update Review Status",
	};
	const reviewHistoryDropdownObj = {
		icon: "history",
		label: "Review History",
	};
	const createDraftDropdownObj = {
		icon: "receipt",
		label: "Create Draft",
	};
	const assignRiskDropdownObj = {
		icon: "assignment_late",
		label: "Assign Overall Risk"
	}
	const reAssignRiskDropdownObj = {
		icon: "assignment_late",
		label: "Reassign Overall Risk"
	}
	// const sampleCheckedDropdownObj = {
	// 	icon: "check_circle",
	// 	label: "Sample Checked"
	// }
	// const workingPapersDropdownObj = {
	// 	icon: "file_present",
	// 	label: "Working Papers"
	// }

	let actionObj = {};
	let actionArr = [];
	let dropdownWidth = "200px";
	const requestChangeCheckText = "Request Change";

	// actionArr.push(showObservations);
	if(createDraft === "Y"){
		actionArr.push(createDraftDropdownObj)
	}
	if(allowEdit === "Y"){
		if(isEmptyVariable(reportRiskLevel)){
			actionArr.push(assignRiskDropdownObj)
		}else{
			actionArr.push(reAssignRiskDropdownObj)
		}
	}

	if (allowEdit === "Y" && allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
		if (currentReviewStatus.includes(requestChangeCheckText)) {
			actionArr.push(reviewRemarksDropdownObj);
		}

		actionArr.push(submitDropdownObj);
		dropdownWidth = "190px";
	}

	if (allowEdit === "Y" && allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
		actionArr.push(updateReviewDropdownObj);
		dropdownWidth = "210px";
	}
	actionArr.push(reviewHistoryDropdownObj);
	
	// actionArr.push(sampleCheckedDropdownObj);
	// actionArr.push(workingPapersDropdownObj);
	
	actionObj = {
		actionArr: actionArr,
		dropdownWidth: dropdownWidth,
	};

	return actionObj;
};

export const getDropdownActionArrayDraftReports = (allowEdit, allowReview, status, currentReviewStatus, allowFinalizeReport) => {
	// const showObservations = {
	// 	icon: "topic",
	// 	label: "Show Observations",
	// };
	const submitDropdownObj = {
		icon: "send",
		label: "Submit For Review",
	};
	const reviewRemarksDropdownObj = {
		icon: "chat",
		label: "Review Remarks",
	};
	const updateReviewDropdownObj = {
		icon: "wifi_protected_setup",
		label: "Update Review Status",
	};
	const reviewHistoryDropdownObj = {
		icon: "history",
		label: "Review History",
	};
	const finalizeReportDropdownObj = {
		icon: "gpp_good",
		label: "Finalize Report",
	};
	// const sampleCheckedDropdownObj = {
	// 	icon: "check_circle",
	// 	label: "Sample Checked"
	// }
	// const workingPapersDropdownObj = {
	// 	icon: "file_present",
	// 	label: "Working Papers"
	// }
	

	let actionObj = {};
	let actionArr = [];
	let dropdownWidth = "200px";
	const requestChangeCheckText = "Request Change";

	// actionArr.push(showObservations);

	if(allowFinalizeReport === "Y" ){
	// if(allowFinalizeReport === "Y" && allowEdit === "Y"){
		actionArr.push(finalizeReportDropdownObj);
	}
	
	if (allowEdit === "Y" && allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
		if (currentReviewStatus.includes(requestChangeCheckText)) {
			actionArr.push(reviewRemarksDropdownObj);
		}

		actionArr.push(submitDropdownObj);
		dropdownWidth = "190px";
	}

	if (allowEdit === "Y" && allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
		actionArr.push(updateReviewDropdownObj);
		dropdownWidth = "210px";
	}
	actionArr.push(reviewHistoryDropdownObj);

	// actionArr.push(sampleCheckedDropdownObj);
	// actionArr.push(workingPapersDropdownObj);
	
	actionObj = {
		actionArr: actionArr,
		dropdownWidth: dropdownWidth,
	};

	return actionObj;
};

export const getDropdownActionArrayDoNotObservationList = () => {
	const putBackDropdownObj = {
		icon: "send",
		label: "Put Back",
	};

	let actionObj = {};
	let actionArr = [];
	let dropdownWidth = "200px";

	actionArr.push(putBackDropdownObj);
	actionObj = {
		actionArr: actionArr,
		dropdownWidth: dropdownWidth,
	};

	return actionObj;
}

export const getDropdownActionArrayTemplateList = (allowEdit, allowReview, status, 
    currentReviewStatus, status_new, previousStatus, templateType, deletePermission, isDefault) => {
    const activeStatus = "Active";
    const updatedStatus = "Updated";
	const deletedStatus = "Deleted";
	const requestChangeCheckText = "Request Change";

	const editDropdownObj = {icon: "edit",label: "Edit"};
    const delDropdownObj = {icon: "delete",label: "Delete",};
	const submitDropdownObj = {icon: "send",label: "Submit For Review"};
	const startDropdownObj = {icon: "play_circle_filled",label: "Start Review"};
	const updateReviewDropdownObj = {icon: "wifi_protected_setup",label: "Update Review Status"};
	const reviewRemarksDropdownObj = {icon: "chat",label: "Review Remarks"};
	const reviewHistoryDropdownObj = {icon: "history",label: "Review History"};
    const discardChanges = {icon: "backspace",label: "Discard Changes",}
	
	let actionObj = {};
	let actionArr = [];
	let dropdownWidth = "160px";

    if(allowEdit === "Y"){
        if (allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus !== updatedStatus && isDefault !== "Y"
            && templateType !== TemplateConstants.TEMPLATE_TYPE_TASK){
                actionArr.push(delDropdownObj);
            }
    
            if(status_new !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            actionArr.push(reviewHistoryDropdownObj);
            dropdownWidth = "190px";
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    
        if (allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
            actionArr.push(updateReviewDropdownObj);
            actionArr.push(editDropdownObj);

            if(deletePermission === "Y" && previousStatus !== updatedStatus && isDefault !== "Y"
            && templateType !== TemplateConstants.TEMPLATE_TYPE_TASK){
                actionArr.push(delDropdownObj);
            }
    
            if(status_new !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    
        if (status_new === activeStatus) {
            actionArr.push(editDropdownObj);
            if(deletePermission === "Y" && isDefault !== "Y"
            && templateType !== TemplateConstants.TEMPLATE_TYPE_TASK){
                actionArr.push(delDropdownObj);
            }
    
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }

    if(allowEdit === "N"){
        if (allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) {
            if (currentReviewStatus.includes(requestChangeCheckText)) {
                actionArr.push(reviewRemarksDropdownObj);
            }
    
            actionArr.push(submitDropdownObj);
    
            if(status_new !== activeStatus && 
            (previousStatus === deletedStatus || previousStatus === updatedStatus)){
                actionArr.push(discardChanges);
            }
    
            actionArr.push(reviewHistoryDropdownObj);
            dropdownWidth = "190px";
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    
        if (allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) {
            actionArr.push(updateReviewDropdownObj);
    
            if(status_new !== activeStatus && 
            (previousStatus === updatedStatus || previousStatus === deletedStatus)){
                actionArr.push(discardChanges);
            }
    
            dropdownWidth = "210px";
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    
        if (status_new === activeStatus) {
            if(deletePermission === "Y" && isDefault !== "Y"
            && templateType !== TemplateConstants.TEMPLATE_TYPE_TASK){
                actionArr.push(delDropdownObj);
            }
            actionArr.push(reviewHistoryDropdownObj);
            actionObj = {
                actionArr: actionArr,
                dropdownWidth: dropdownWidth,
            };
            return actionObj;
        }
    }

    if (allowReview === Constants.ALLOW_REVIEW_STARTREVIEW) {
		actionArr.push(startDropdownObj);

        dropdownWidth = "160px";
        actionArr.push(reviewHistoryDropdownObj);
        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };
        return actionObj;
	}
	
	actionArr.push(reviewHistoryDropdownObj);
    actionObj = {
        actionArr: actionArr,
        dropdownWidth: dropdownWidth,
    };

    return actionObj;
}