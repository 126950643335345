import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from "recharts";
import { ChartColors } from "../Global/constants";
import { getRechartXAxisLabel,getRechartYAxisLabel } from "../Global/commonFunctions";

export default class RechartsLineChart extends React.PureComponent {
    constructor (props) {
        super(props);
        this.state = {}
    }

    render () {
        const { data, xAxixDataKey, yAxisKeys } = this.props;

        return (
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 30,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray="2 2" />
                    <XAxis dataKey={xAxixDataKey} tickFormatter={getRechartXAxisLabel} />
                    {this.props.isShowPercentage ? <YAxis tickFormatter={getRechartYAxisLabel}/>:<YAxis />}
                    <Tooltip  formatter={(value)=>{
                       return this.props.isShowPercentage? value + "%":value;
                    }}/>
                    <Legend verticalAlign="top" align="right" wrapperStyle={{ top: 0 }} />
                    <Brush dataKey={xAxixDataKey} height={30} stroke="#8884d8" />
                    {yAxisKeys.map((dataKey, i) => (
                        <Line
                            key={dataKey.key}
                            type="monotone"
                            dataKey={dataKey.key}
                            activeDot={{ r: 8 }}
                            stroke={dataKey.color || ChartColors[i % ChartColors.length]}
                            // label={{
                            //     fill: dataKey.labelColor || "#000",
                            //     position: "top"
                            // }}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        )
    }
}
