export const HeaderLeftMargin = 1.6;
export const HeaderRightMargin = 1.6;
export const HeaderTopMargin = 2;
export const HeaderBottomMargin = 0;

export const FooterLeftMargin = 1.6;
export const FooterRightMargin = 1.6;
export const FooterTopMargin = 2;
export const FooterBottomMargin = 0;

