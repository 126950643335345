import React, { Component } from 'react';
import {isEmptyVariable} from '../Global/commonFunctions';
import {TableDropDownLayout} from '../Global/globalStyles.style';

class TableDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayMenu:false
        }
    }

    showDropdownMenu = (event) => {
        event.stopPropagation();
        this.setState({ displayMenu: true }, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu = () => {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });
    }

    dropDownItemClick = (item,e) => {
        e.stopPropagation();
        this.props.onDropDownItemClick(item,this.props.dropDownId,this.props.udf1,this.props.udf2);
    }

    render(){
        return(
            <TableDropDownLayout
            themeSettings={this.props.themeSettings}
            >
                <ul className="actico-col" onClick={this.showDropdownMenu}>
                    <li>
                        <a className="table-actions" href="javascript:void(0);">
                            <span className="material-icons">more_vert</span>
                        </a>
                        {
                            this.state.displayMenu && 
                            <div className="tableact-dropdown" style={{width:this.props.dropdownWidth?this.props.dropdownWidth:"140px",zIndex:1000}}>
                                <div className="tableact-dropdown-col">
                                    <ul>
                                        {
                                            !isEmptyVariable(this.props.actionArr) &&
                                            this.props.actionArr.map((item)=>{
                                                return <li onClick={this.dropDownItemClick.bind(this,item)}><a href="javascript:void(0);">
                                                    <span className="material-icons">{item.icon}</span> <p>{item.label}</p>
                                                </a></li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        }
                    </li>
                </ul>
            </TableDropDownLayout>
        )
    }
}

export default TableDropDown;