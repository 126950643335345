import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import {
  getLocalStorageVariables,
  getThemeSettingsVariables,
  isEmptyVariable,
  isEmptyArray,
  isAlphaNumericCharacter,
} from "../../../common/Global/commonFunctions";
import {
  PaginationLayout,
  SearchSpan,
} from "../../../common/Global/globalStyles.style";
import { Modal } from "react-bootstrap";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import {
  DialogSaveButton,
  FilterCheckBox,
} from "../../../common/Global/globalStyles.style";
import * as TemplateConstants from "../../../common/Global/templateConstants";

const userDetails = getLocalStorageVariables();
const fieldTypePlaceholderText = "Select Field Type";
const themeSettings = getThemeSettingsVariables();
const RESULT_SIZE_GRID_DD_ARR = [
  {
      label:5,
      isSelected:true
  },
  {
    label:10,
    isSelected:false
},
  {
      label:15,
      isSelected:false
  },
  {
      label:20,
      isSelected:false
  }
];
class AddDropdownValuesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentDidMountFlag: false,
      showLoader: false,
      isDataEdit: false,
      dropdownVal: "",
      dropDownDesc: "",
      datalist: TemplateConstants.FIELD_TYPE_DD_VALUE_ARR,
      currentPage: 0,
      perPageItem: 5,
      totalPages: 0,

      currentBottomPaginationNumber: 0,
      perBottomPaginationItem: 3,

      formErrors: {},
      dropDownDataList: [],
      dropDownSearchDataList: [],
      submitClickedAtleastOnce: false,
      selectedItemIndex: "",
      searchkey: "",
      resultSizeArr:RESULT_SIZE_GRID_DD_ARR

    };
  }

  
  
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps) !== JSON.stringify(this.props) &&
      this.props.showCreateDDValueDialog
    ) {
      let typeDDValueArr = [];
      if (this.props.isEdit) {
        if (!isEmptyArray(this.props.typeDDValueArr)) {
          typeDDValueArr = this.props.typeDDValueArr;
        }
        this.setState({
          datalist: typeDDValueArr,
          dropDownDataList: typeDDValueArr,

          totalPages: Math.ceil(typeDDValueArr.length / this.state.perPageItem),
          currentPage:
            typeDDValueArr.length !== 0
              ? Math.ceil(typeDDValueArr.length / this.state.perPageItem) - 1
              : 0,
        });
      }
    }
  }

  handleCreateDDValues = () => {
    let isError = false;
    let datalist = this.state.dropDownDataList;

    if (datalist.length < 2) {
      isError = true;
      this.state.formErrors.saveErr = "Dropdown must have at least 2 value";
    }
    if (isError) {
      this.setState({
        formErrors: this.state.formErrors,
      });

      setTimeout(
        function () {
          this.setState({
            formErrors: {},
          });
        }.bind(this),
        Constants.WRNG_MSG_TIMEOUT
      );
    }

    if (!isError) {
      this.props.handleCreateDDValueDialogClose(datalist);
      this.setState({
        datalist: [],
        dropDownDataList: [],
      });
    } else {
      this.setState({
        datalist: datalist,
      });
    }
  };

  handleDeleteItemClick = (index) => {
    let datalist = this.state.datalist;
    datalist.splice(index, 1);
    this.setState({
      datalist: datalist,
    });
  };

  ///   Akshay's Code Strat Here

  // This Method handle Change in Dropdown Value Box
  handleChange = (e) => {
    this.setState({ dropdownVal: e.target.value });
  };

  // This Method handle Change in Dropdown Description Box
  handleChangeDesc = (e) => {
    this.setState({ dropDownDesc: e.target.value });
  };

  // This Method Add New Data to DropdownList
  handleAddNewValueClick = () => {
       let formErrors = {};
    let isError = false;
    if (this.state.dropdownVal === "") {
      formErrors.valueErr = "Please Enter Dropdown Value";
      isError = true;
    }

    if(this.state.dropdownVal!=="")
    {
      let datalist = this.state.dropDownDataList;
      datalist.map((item)=>{
        if (item.value.toLowerCase()=== this.state.dropdownVal.toLowerCase())
        {
          formErrors.valueErr = "Please Enter Unique Dropdown Value";
          isError = true;
        }
      })
    }
    
    
    if (isError) {
      this.setState({
        formErrors: formErrors,
      });

      setTimeout(
        function () {
          this.setState({
            formErrors: {},
          });
        }.bind(this),
        Constants.WRNG_MSG_TIMEOUT
      );
    } else {
      let datalist = this.state.dropDownDataList;
      let newDDObj = {
        value: this.state.dropdownVal,
        description: this.state.dropDownDesc,
        valueNdesc: " ",
      };
      datalist.push(newDDObj);
      this.setState({
        dropDownDataList: datalist,
        dropDownDesc: "",
        dropdownVal: "",
        totalPages: Math.ceil(
          this.state.dropDownDataList.length / this.state.perPageItem
        ),
      });
    }
  };

  // This Method Edit The Selected DropdownData
  editDropDownValue = (item, idx) => {
    this.setState({
      dropdownVal: item.value,
      dropDownDesc: item.description,
      isDataEdit: true,
      selectedItemIndex: idx,
    });
  };

  
  // This Method Update The Selected DropdownData
  updatDropDownValue = () => {
    let formErrors = {};
    let isError = false;
    if (this.state.dropdownVal === "") {
      formErrors.valueErr = "Please Enter Dropdown Value";
      isError = true;
    }

    if(this.state.dropdownVal!=="")
    {
      let datalist = this.state.dropDownDataList;
      datalist.map((item)=>{
        if (item.value.toLowerCase()=== this.state.dropdownVal.toLowerCase())
        {
          formErrors.valueErr = "Please Enter Unique Dropdown Value";
          isError = true;
        }
      })
    }

    if (isError) {
      this.setState({
        formErrors: formErrors,
      });

      setTimeout(
        function () {
          this.setState({
            formErrors: {},
          });
        }.bind(this),
        Constants.WRNG_MSG_TIMEOUT
      );
    } 
    
   else if(this.state.searchkey!=="")
    {
      
      let datalist = this.state.dropDownDataList;
      let searchDataList = this.state.dropDownSearchDataList;
      datalist.map((item)=>{
        if(item.value===searchDataList[this.state.selectedItemIndex].value)
        {
          item.value = this.state.dropdownVal;
            item.description =
            this.state.dropDownDesc;
        }
      })
      this.setState({
        dropDownDataList: datalist,
        dropDownDesc: "",
        dropdownVal: "",
        isDataEdit: false,
        selectedItemIndex: "",
      });

    }
    
    else {
      let datalist = this.state.dropDownDataList;
      datalist[this.state.selectedItemIndex].value = this.state.dropdownVal;
      datalist[this.state.selectedItemIndex].description =
        this.state.dropDownDesc;

      this.setState({
        dropDownDataList: datalist,
        dropDownDesc: "",
        dropdownVal: "",
        isDataEdit: false,
        selectedItemIndex: "",
      });
    }
  };

  // This Method Delete Data From DropdownList
  deleteDropdownValue(idx) {

      let datalist = this.state.dropDownDataList;
      let searchDataList = this.state.dropDownSearchDataList;



       if(this.state.searchkey!=="")
       {

        Object.values(datalist).map((item,i)=>{
          if(item.value===searchDataList[idx].value)
          {
            datalist.splice(i, 1);
            searchDataList.splice(idx,1)
            
          }
        })
       }
       else{
          datalist.splice(idx, 1);
       }

    this.setState({
      dropDownDataList: datalist,
      dropDownSearchDataList:searchDataList,
      dropdownVal: "",
      dropDownDesc: "",
      isDataEdit: false,
      totalPages: Math.ceil(
        this.state.dropDownDataList.length / this.state.perPageItem
      ),
      currentPage:
        this.state.dropDownDataList.length !== 0
          ? Math.ceil(
              this.state.dropDownDataList.length / this.state.perPageItem
            ) - 1
          : 0,
    });
  
  }

  // This Method Change Pagination According Numbers
  setPage = (pageNumber) => {
    if (pageNumber < this.state.totalPages) {

      if(pageNumber>this.state.perBottomPaginationItem-1)
      {
        this.setState({
          currentBottomPaginationNumber: pageNumber-1
        })
      }
      else{
        this.setState({
          currentBottomPaginationNumber:0
        })
      }
    
      this.setState({
        currentPage: pageNumber,
      });
    }
  };

  // This Method Handle Search Value
  handleChangeSearch = (e) => {
    let value = e.target.value;
    let searchDataList = [];

    if (!isEmptyVariable(value)) {
      Object.values(this.state.dropDownDataList).map((item) => {
        if (
          JSON.stringify(item.value)
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())
        ) {
          searchDataList.push(item);
        }
      });
      this.setState({
        totalPages: Math.ceil(searchDataList.length / this.state.perPageItem),
        currentPage:
          this.state.dropDownDataList.length !== 0
            ? Math.ceil(searchDataList.length / this.state.perPageItem) - 1
            : 0,
      });
    } else {
      this.setState({
        totalPages: Math.ceil(
          this.state.dropDownDataList.length / this.state.perPageItem
        ),
        currentPage:
          this.state.dropDownDataList.length !== 0
            ? Math.ceil(
                this.state.dropDownDataList.length / this.state.perPageItem
              ) - 1
            : 0,
      });
    }

    this.setState({
      searchkey: e.target.value,
      dropDownSearchDataList: searchDataList,
      
    });
  };

  resultSizeDropdownClick = (selectedIndex) => {
  
    let tempArr = this.state.resultSizeArr;
    let placeholder =5;
 
    tempArr.map((item)=>{
      if(item.label==selectedIndex.label)
      {
        item.isSelected = true;
        placeholder = item.label;
      }
      else{
        item.isSelected = false;
      }
    })

  
    
    this.setState({
         resultSizeArr:tempArr,
         perPageItem:placeholder,
         totalPages: Math.ceil(
          this.state.dropDownDataList.length / placeholder
        ),
        currentPage: 0,

    })
}
  render() {
    return (
      <Modal
        className="custom-dialog value-desc-dialog"
        show={this.props.showCreateDDValueDialog}
        backdrop="static"
      >
        <Modal.Header>
          <h5>
            {this.state.isEdit ? "Edit Dropdown Values" : "Add Dropdown Values"}
          </h5>
          <button
            onClick={this.props.handleCreateDDValueDialogClose}
            type="button"
            data-dismiss="modal"
          >
            <span class="material-icons">close</span>
          </button>
        </Modal.Header>
        <hr />
        <div class="modal-body">
          <div class="addnew-modal-form body-wrapper">
            {/* Akshay's Code Start Here */}
            <div className="row custom-row-5">
              <div className="col-md-4 custom-col-5">
                <div class="addnew-modal-form-group">
                  <p>Dropdown Value*</p>
                  <input
                    type="text"
                    placeholder="Enter Value"
                    value={this.state.dropdownVal}
                    onChange={this.handleChange}
                  />
                  {!isEmptyVariable(this.state.formErrors.valueErr) && (
                    <span class="cm-error">
                      {this.state.formErrors.valueErr}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-8 custom-col-5">
                <div class="addnew-modal-form-group">
                  <p>Description</p>
                  <div className="flex-center-layout">
                    <textarea
                      type="text"
                      placeholder={"Enter Description "}
                      onChange={this.handleChangeDesc}
                      value={this.state.dropDownDesc}
                    />

                    {
                      
                      <div
                        className="add-more-btn-layout mx-2"
                        onClick={
                          this.state.isDataEdit
                            ? this.updatDropDownValue
                            : this.handleAddNewValueClick
                        }
                      >
                        <span className="material-icons">add_circle</span>
                      </div>
                    }
                  </div>
                </div>
              </div>
              {this.state.dropDownDataList.length > 0 && (
                <>
                   <div className="search-filter-layout">
                                                   
                      <div className="search-col m-l-10">
                          <input 
                              name="searchkey" 
                              type="text"
                              onChange={this.handleChangeSearch}
                              placeholder="Search" 
                              
                              value={this.state.searchkey}
                          />
                          <SearchSpan 
                          themeSettings={themeSettings}
                          onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                      </div> &nbsp;
                      <FilterDropDown
                          placeholder={this.state.perPageItem}
                          dropdownArr={this.state.resultSizeArr}
                          onDropDownItemClick={this.resultSizeDropdownClick}
                          themeSettings={themeSettings}
                          mleft={2}
                      />
                  </div>
              
                    {!isEmptyVariable(this.state.searchkey) &&
                    this.state.dropDownSearchDataList.length === 0 ? (
                      <>
                     
                        <div className="col-12 body-wrapper mr-4 mt-2">
                            <h6>{ Constants.NO_RECORDS_WARNING}</h6>
                        </div>
                      </>
                    ) : (
                      <div className="common-tab-bg my-2 body-wrapper">
                        <div 
                          className="common-table"
                          style={{ border: "1px solid #e5e5e5" }}
                        >


                          <table className="table">
                            <thead>
                              <tr>
                                <th>Sr No.</th>
                                <th>Dropdown Value</th>
                                <th>Description</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody>
                              {(isEmptyVariable(this.state.searchkey)
                                ? this.state.dropDownDataList
                                : this.state.dropDownSearchDataList
                              ).map((item, idx) => {
                                return (
                                  idx >=
                                    this.state.currentPage *
                                      this.state.perPageItem &&
                                  idx <
                                    this.state.currentPage *
                                      this.state.perPageItem +
                                      this.state.perPageItem && (
                                    <tr>
                                      <td className="text-center ">
                                        {idx + 1}
                                      </td>
                                      <td
                                        style={{
                                          overflowWrap: "anywhere",
                                          width: "200px",
                                        }}
                                      >
                                        {isEmptyVariable(item.value)
                                          ? "-"
                                          : item.value}
                                      </td>
                                      <td
                                        style={{
                                          overflowWrap: "anywhere",
                                          width: "200px",
                                        }}
                                      >
                                        {isEmptyVariable(item.description)
                                          ? "-"
                                          : item.description}
                                      </td>
                                      <td>
                                        <span
                                          onClick={this.editDropDownValue.bind(
                                            this,
                                            item,
                                            idx
                                          )}
                                          className="material-icons mx-2"
                                          style={{
                                            cursor: "pointer",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontSize: 18,
                                          }}
                                        >
                                          edit
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          onClick={this.deleteDropdownValue.bind(
                                            this,

                                            idx
                                          )}
                                          className="material-icons mx-2"
                                          style={{
                                            cursor: "pointer",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontSize: 18,
                                          }}
                                        >
                                          delete
                                        </span>
                                      </td>
                                    </tr>
                                  )
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                
                </>
              )}
            </div>
          </div>
          {/* Akshay's Code End Here */}

          {!isEmptyVariable(this.state.formErrors.saveErr) && (
            <>
            
            <span class="cm-error" style={{fontSize:12}}>{this.state.formErrors.saveErr}</span>
            <br/>
            </>
          )}
          {this.state.dropDownDataList.length > 0 && (
            <div>
              <PaginationLayout themeSettings={themeSettings}>
                <ul className="pagination">
                  <li
                    className={
                      this.state.currentPage === 0
                        ? "disabled page-item"
                        : "page-item"
                    }
                  >
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => this.setPage(0)}
                      className="page-link"
                    >
                      First
                    </a>
                  </li>
                  <li
                    className={
                      this.state.currentPage === 0
                        ? "disabled page-item"
                        : "page-item"
                    }
                  >
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => this.setPage(this.state.currentPage - 1)}
                      className="page-link"
                    >
                      <span aria-hidden="true">{"<<"}</span>
                    </a>
                  </li>

                  {Array.from(Array(this.state.totalPages), (e, i) => {
                    return (
                      i >= this.state.currentBottomPaginationNumber &&
                      i <
                        this.state.currentBottomPaginationNumber +
                          this.state.perBottomPaginationItem && (
                        <li
                          key={i}
                          className={
                            this.state.currentPage === i
                              ? "active page-item"
                              : "page-item"
                          }
                        >
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => this.setPage(i)}
                            className="page-link"
                          >
                            {i + 1}
                          </a>
                        </li>
                      )
                    );
                  })}

                  <li
                    className={
                      this.state.currentPage === this.state.totalPages - 1
                        ? "disabled page-item"
                        : "page-item"
                    }
                  >
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => this.setPage(this.state.currentPage + 1)}
                      className="page-link"
                    >
                      <span aria-hidden="true">{">>"}</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                  <li
                    className={
                      this.state.currentPage === this.state.totalPages - 1
                        ? "disabled page-item"
                        : "page-item"
                    }
                  >
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => this.setPage(this.state.totalPages - 1)}
                      className="page-link"
                    >
                      Last
                    </a>
                  </li>
                </ul>
              </PaginationLayout>
            </div>
          )}
          <div class="modal-close-save">
            <div class="close-save-col">
              <button
                onClick={this.props.handleCreateDDValueDialogClose}
                type="button"
                class="modal-close"
              >
                Cancel
              </button>
              <DialogSaveButton
                themeSettings={this.props.themeSettings}
                onClick={this.handleCreateDDValues}
                type="button"
              >
                Save
              </DialogSaveButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddDropdownValuesDialog;
