import React, { Component } from 'react'
import * as Constants from '../../../common/Global/constants';
import {Modal} from 'react-bootstrap';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import {
	getLocalStorageVariables,
	getThemeSettingsVariables,
	isEmptyVariable, 
	isEmptyArray,

} from "../../../common/Global/commonFunctions"; 
import Pagination from "../../../common/Global/pagination";
import { SearchSpan } from "../../../common/Global/globalStyles.style";

let userDetails = getLocalStorageVariables();
const RESULT_SIZE_DD_ARR =[
    {
      label:5,
      isSelected:true
    },
    {
      label:10,
      isSelected:false
    },
    {
      label:15,
      isSelected:false
    },
  ]

export default class PreviousRefNoDialog extends Component {
  constructor(props) {
      super(props);
      this.state = {
        componentDidMountFlag:false,
        dataList:[],
        tableHeader:[],
        totalCount:0,
        resultSize:Constants.INFO_RESULT_SIZE,
        currentPageNo: 1,
        resultSizePlaceholder:5,
        resultSizeArr:RESULT_SIZE_DD_ARR,
        searchkey:"",
        showLoader:false

      }
  }

  getPreviousRefData = (pageNo) =>{
    this.setState({
      showLoader:true
    })
    let unitIds = this.props.unitList.map(e=>e.unitId)||[];

    fetch(Constants.GetPreviousRefNoData,
    {
      method: "POST",
      mode:'cors',
      body: new URLSearchParams({ 
          email: userDetails.email,
          accessToken: userDetails.accessToken,
          unitIds:JSON.stringify(unitIds),
          departmentId:this.props.dropdownItem.departmentId,
          assignmentTypeId:this.props.assignmentTypeId,
          pageNo: pageNo,
          resultSize:this.state.resultSizePlaceholder,
          searchKey:this.state.searchkey,
        })
    })
    .then(response => { return response.json(); } )
    .then(data =>
    {
      if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
        localStorage.clear();
        window.location = "/";
      } else if (data.responseCode === Constants.CODE_SUCCESS) {
        let tempTableHeader = ["No.","Assignment Type","Scope","Scope Details","Reference No.","Status"];
        this.setState({
          tableHeader:tempTableHeader,
          dataList:data.data,
          totalCount:data.totalCount,
          currentPageNo:pageNo,
          showLoader:false,
          componentDidMountFlag:true,
        })                
      }
      else
      {
        this.setState({
          componentDidMountFlag:true,
          showLoader:false
        })
      }
     
    })
  }

  // componentDidMount() {
  //   this.getInitData();
  // }

  // getInitData = () =>{
  //   userDetails = getLocalStorageVariables();
  //   this.getPreviousRefData(1) 
  // }

  componentDidUpdate(prevProps){
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props) 
      && this.props.showDialogBox) {
        this.getPreviousRefData(1)
      }
  }
  onEnterBtnPress = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      this.onSearchIconClick()  
    }
  };

  onChangePage = (page) =>{
    if (page !== this.state.currentPageNo) {
    this.getPreviousRefData(page)
    }
  }

  resultSizeDropdownClick = (item, selectedIndex) =>{
    
    let tempArr = this.state.resultSizeArr;
    let placeholder = "";

    if(item.label!==this.state.resultSizePlaceholder){
      for (const [i, value] of tempArr.entries()) {
        if (i === selectedIndex) {
          tempArr[i].isSelected = true;
          placeholder = value.label ;
        } else {
          tempArr[i].isSelected = false;
        }
      }

      this.setState(
        {
          resultSizeArr: tempArr,
          resultSize: item.label,
          resultSizePlaceholder: placeholder,
        },
        () => {
          this.getPreviousRefData(this.state.currentPageNo);
        }
      )
    }
  
  }
  handleChangeSearch = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };
  onSearchIconClick = () => {
    this.getPreviousRefData(1);
  };

  render() {
    return (
        <section>
            <Modal className="custom-dialog scheduled-assignment"
            show={this.props.showDialogBox} 
            onHide={this.props.closeDialogBox}>
                <Modal.Header>
                    <h5>Previous Reference No Details</h5>
                        <button 
                            onClick={this.props.closeDialogBox}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                </Modal.Header>
                <hr />
                {
                  this.state.showLoader &&
                  <div className="modal-loading-text" style={{top:56,zIndex:10}}>
                      <p>Loading....</p>
                  </div>
                }
                <div className="modal-body pt-0">
                  <div className="body-wrapper">
                      <div className="search-addnew-bg">
                          <div className="search-addnew">
                              <div className="search-filter-layout">
                                  <FilterDropDown
                                      placeholder={this.state.resultSizePlaceholder}
                                      dropdownArr={this.state.resultSizeArr}
                                      onDropDownItemClick={this.resultSizeDropdownClick}
                                      themeSettings={this.props.themeSettings}
                                      mleft={0}
                                  />
                                  <div className="search-col m-l-10">
                                    <input
                                      name="searchkey"
                                      type="text"
                                      onChange={this.handleChangeSearch}
                                      placeholder="Search"
                                      onKeyPress={this.onEnterBtnPress}
                                      value={this.state.searchkey}
                                    />
                                    <SearchSpan themeSettings={this.props.themeSettings} onClick={this.onSearchIconClick} className="material-icons">
                                      {" "}
                                      search{" "}
                                    </SearchSpan>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="common-tab-bg">
                        {this.state.componentDidMountFlag && Object.values(this.state.dataList).length>0 &&
                          <div className="common-table" style={{ width: "100%" }} >
                            <table className="table">
                              <thead>
                                <tr className='text-center'>
                                {
                                  this.state.tableHeader.map((item)=>{
                                    return <td>{item}</td>
                                  })
                                }
                                </tr>
                              </thead>
                              <tbody>
                                {Object.values(this.state.dataList).map((item,idx)=>{
                                  return(
                                    <tr className='text-center' style={{background:idx%2!=0?"#f5f5f5":"#fff"}}>
                                    {((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}
                                    <td>{isEmptyVariable(item.assignmentType)?"-":item.assignmentType}</td>
                                    <td>{isEmptyVariable(item.scope)?"-":item.scope}</td>
                                    <td>{isEmptyArray(item.scopeDetail)?"-":item.scopeDetail.join(",")}</td>
                                    <td>{isEmptyVariable(item.refNo)?"-":item.refNo}</td>
                                    <td>{isEmptyVariable(item.status)?"-":item.status}</td>
                                    </tr>
                                )
                                })}
                              </tbody>
                            </table>
                          </div>
                        }
                      </div>
                        {this.state.componentDidMountFlag && isEmptyArray(this.state.dataList) && (
                      <div class="no-items-layout">
                        <div class="no-items-card">
                          <h6>
                            {isEmptyVariable(this.state.searchkey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
                          </h6>
                        </div>
                      </div>
									    )}
                  <div className="pagination-layout">
                    <div className="row custom-row">
                      <div className="col-md-9 custom-col">
                        {(this.state.totalCount > this.state.dataList.length) && (
                          <Pagination
                            totalLength={this.state.totalCount}
                            items={this.state.dataList}
                            onChangePage={this.onChangePage}
                            pageSize={this.state.resultSize}
                            currentPageNo={this.state.currentPageNo}
                            initialPage={this.state.currentPageNo}
                            themeSettings={this.props.themeSettings}
                          />
                        )}
                      </div>
                      <div className="col-md-3 custom-col">
                        <p>{"Total Records: " + this.state.totalCount}</p>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
            </Modal>
        </section>
    )
  }
}
