import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { isEmptyVariable, getLocalStorageVariables,getStringWithSpaceAfterComma,isJsonString } from '../../../common/Global/commonFunctions';
import { AssigmentDetailsIconLayout } from '../../../common/Global/globalStyles.style';
import moment from "moment";
import FollowUpDetails from "../infoCard/infoCardFollowUpComponent";

class FollowupHeading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignmentDetails:this.props.assignmentDetails,
            showDetailsDialog: false,
			selectedItem: "",
            showInfoCard:false
        }
    }

    handleDetailsDialogClose = () => {
		this.setState({
			showDetailsDialog: false,
			selectedItem: "",
		});
	};

	handleDetailsDialogShow = (item) => {
		this.setState({
			showDetailsDialog: true,
			selectedItem: item,
		});
	};

    handleGetAssignmentDetails = () => {
        this.handleDetailsDialogShow(this.state.assignmentDetails);
    }

    render(){
        return (
            <div>
                <div className="assignment-heading" style={{marginTop:5}}>
                    {
                        this.props.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                        !isEmptyVariable(this.props.assignmentDetails.unitCityList) &&
                        <p className="m-b-0 font-bold" style={{marginRight:20}}>
                            {getStringWithSpaceAfterComma(this.props.assignmentDetails.unitNameCityList)} 
                        </p>
                    }
                    {
                        this.props.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                        !isEmptyVariable(this.props.assignmentDetails.companyName) &&
                        <p className="m-b-0 font-bold" style={{marginRight:20}}>
                            {this.props.assignmentDetails.companyName} 
                        </p>
                    }
                    {!isEmptyVariable(this.props.assignmentDetails.assignmentTypeName) &&
                        <p className="m-b-0 font-bold" style={{marginRight:20}}>
                            {this.props.assignmentDetails.assignmentTypeShortName} 
                        </p>
                    }
                    {!isEmptyVariable(this.props.assignmentDetails.endDate) &&
                        <p className="m-b-0 font-bold" style={{marginRight:20}}>
                            End Date: {moment(this.props.assignmentDetails.endDate).format("DD/MM/YYYY")} 
                        </p>
                    }
                    {!isEmptyVariable(this.props.assignmentDetails.periodToDate) && !isEmptyVariable(this.props.assignmentDetails.periodFromDate) &&
                        <p className="m-b-0 font-bold" style={{marginRight:20}}>
                            {"Sample Period: "+moment(this.props.assignmentDetails.periodFromDate).format("DD/MM/YYYY")} - {moment(this.props.assignmentDetails.periodToDate).format("DD/MM/YYYY")}
                        </p>
                    }
                    <AssigmentDetailsIconLayout themeSettings={this.props.themeSettings}>
                        <span 
                        className="material-icons" onClick={this.handleGetAssignmentDetails}>info</span>
                     </AssigmentDetailsIconLayout>
                </div>
                <FollowUpDetails
                    showDetailsDialog={this.state.showDetailsDialog}
                    handleDetailsDialogClose={this.handleDetailsDialogClose}
                    selectedItem={this.state.selectedItem}
                    themeSettings={this.props.themeSettings}
                    assignmentMatrix={this.props.assignmentMatrix}
                    company={this.props.company}
                />
            </div>
        );
    }
}
export default FollowupHeading;
