import React, { Component, PureComponent } from "react";
import * as Constants from "../../../common/Global/constants";
import { TableRadioButton, DialogSaveButton } from "../../../common/Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray } from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import { toast } from "react-toastify";

const userDetails = getLocalStorageVariables();
const defaultAssignmentTypeRisk = "Select risk";

class RiskTable extends PureComponent {
	constructor (props) {
		super(props);
		this.state = {};
	}

	render () {
		const { readOnly, datalist, themeSettings, handleSelection, selectedRiskId } = this.props;
		return (
			<div className="common-table" style={{ width: "100%", border: "1px solid #e5e5e5" }}>
				{!isEmptyArray(datalist) ?
					<table className="table">
						<thead>
							<tr>
								{!readOnly && <th className="text-center" width={"8%"}></th>}
								<th className="text-center" width={"5%"}>
									No.
								</th>
								<th>Risk Level</th>
								<th>Color Code</th>
							</tr>
						</thead>
						<tbody>
							{datalist.map((item, idx) => {
								return (
									<tr>
										{!readOnly &&
											<td>
												<TableRadioButton themeSettings={themeSettings}>
													<input
														type="checkbox"
														name={idx}
														id={item.riskLevelId}
														onChange={handleSelection}
														checked={item.riskLevelId + "" === selectedRiskId + "" ? true : false}
													/>
													<label for={item.riskLevelId} style={{ marginLeft: 6 }}></label>
												</TableRadioButton>
											</td>
										}
										<td className="text-center">{idx + 1}</td>
										<td>{item.riskLevel}</td>
										<td>
											<div className="risk-level-color-wrapper" style={{ width: "38px" }}>
												<div className="risk-level-color" style={{ background: item.colorCode }} />
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					:
					<div class="no-items-layout">
						<div class="no-items-card">
							<h6>{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}</h6>
						</div>
					</div>
				}
			</div>
		)
	}
}

class AssertionAssignmentList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag:false,
			showLoader:false,
			selectedRiskId: "",
			// selectedRiskObj: {},
			riskErr: "",
			assignmenTypeRisks: [],
			showAssignmenTypeRiskModal: false,
			selectedAssignmentTypeRisk: { name: defaultAssignmentTypeRisk },
			assignmenTypeRiskLevels: [],
			riskMatrixErr: "",
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showRiskDialog) {
			// let selectedObj=this.props.selectedRiskObj;
			let selectedId = isEmptyVariable(this.props.selectedRiskId) ? "" : this.props.selectedRiskId;
			this.setState({
				componentDidMountFlag:false,
                showLoader:false,
				selectedRiskId: selectedId,
				// selectedRiskObj:selectedObj,
				// riskErr: "",
			},()=>{
				this.getInitData();
			});
		}
	}

	handleRowSelection = (idx) => {
		let riskLevelArrIndex = idx;
		let selectedId = "";
		selectedId = this.state.datalist[riskLevelArrIndex].riskLevelId;

		this.setState({
			selectedRiskId: selectedId,
		});
	}

	handleSelection = (e) => {
		// e.stopPropagation();
		let isChecked = e.target.checked;
		let riskLevelArrIndex = e.target.name;
		let selectedId = "";
		// let selectedRiskObj = {};

		// if (isChecked) {
			selectedId = this.state.datalist[riskLevelArrIndex].riskLevelId;
			// selectedRiskObj = this.state.datalist[riskLevelArrIndex];
		// }

		this.setState({
			selectedRiskId: selectedId,
			// selectedRiskObj:selectedRiskObj
		});
	};

	handleSelectRiskDialogClose =(reloadFlag)=>{
		this.setState({
			datalist: ""
		},()=>{
			this.props.handleSelectRiskDialogClose(reloadFlag);
		});
	}

	/*****************************API*********************************/
	getInitData = () => {
		this.setState({
			showLoader: true,
			showDetailsDialog:false,
            selectedItem:"",
		});

		let params ={
			email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
		}
		if(!isEmptyVariable(this.props.assignRiskId)){
			params.riskId = this.props.assignRiskId
		}

		Promise.all([			
			fetch(Constants.GetReportRiskLevels, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(params),
			}),
			fetch(Constants.GetAssignmentTypeRisk, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			})			
		])
			.then(([masterRes, riskRes]) => {
				return Promise.all([masterRes.json(), riskRes.json()]);
			})
			.then(([masterRes, riskRes]) => {
				let datalistTemp = [];
				let assignedRiskLevelId = "";
				let assignmenTypeRisks = [];
				
				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					datalistTemp = masterRes.data.riskLevels;
					assignedRiskLevelId = masterRes.data.assignedRiskLevelId;
				} else {
				}

				if (riskRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || riskRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (riskRes.responseCode === Constants.CODE_SUCCESS) {
					assignmenTypeRisks = (Object.values(riskRes.data)).flat();
				}
				
				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					datalist: datalistTemp,
					assignedRiskLevelId: assignedRiskLevelId,
					assignmenTypeRisks: assignmenTypeRisks,
				});
			});
	};

	saveReportRisk = () => {
		this.setState({
			showLoader: true,
		});

		// let riskErrTemp = "";

		if (isEmptyVariable(this.state.selectedRiskId)) {
			// riskErrTemp = "Select the risk level";
			toast.warning("Select the risk level");
			this.setState({
				// riskErr: riskErrTemp,
				showLoader:false,
			});

			// setTimeout(
			// 	function () {
			// 		this.setState({
			// 			riskErr: "",
			// 		});
			// 	}.bind(this),
			// 	Constants.WRNG_MSG_TIMEOUT
			// );
		} else {
			fetch(Constants.AssignReportRisk, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
					
					riskLevelId: this.state.selectedRiskId,
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.handleSelectRiskDialogClose(true);
					} else {
						toast.error(data.responseMessage);
						this.setState({
							// riskErr: data.responseMessage,
							showLoader: false,
						});
					}
				});
		}
	};

	onClickAssignmentTypeRisk = () => {
		this.setState({
			showAssignmenTypeRiskModal: true,
		});
	}

	hideAssignmentTypeRiskModal = () => {
		this.setState({
			showAssignmenTypeRiskModal: false,
		});
	}

	onChangeAssignmentTypeRisk = (item) => {
		this.setState({
			selectedAssignmentTypeRisk: item,
		});

		this.setState({
			showLoader: true,
		});
		fetch(Constants.GetReportRiskLevels, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId: this.props.assignmentId,
				riskId: item.riskId,
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				let assignmenTypeRiskLevels = [];
				if (res.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || res.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (res.responseCode === Constants.CODE_SUCCESS) {
					assignmenTypeRiskLevels = res.data.riskLevels;
				}

				this.setState({
					showLoader: false,
					assignmenTypeRiskLevels: assignmenTypeRiskLevels,
				});
			});
	}

	saveAssignmentTypeRisk = () => {
		if (isEmptyVariable(this.state.selectedAssignmentTypeRisk.riskId)) {
			this.setState({
				riskMatrixErr: "Please select a risk",
			});
			return;
		}
		this.setState({
			showLoader: true,
			riskMatrixErr: "",
		});
		console.log("Constants.ChnageRiskReport::",Constants.ChnageRiskReport)

		let params = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentId: this.props.assignmentId
		}
		if(!isEmptyVariable(this.state.selectedAssignmentTypeRisk.riskId)){
			params.riskId = this.state.selectedAssignmentTypeRisk.riskId
		}

		fetch(Constants.ChnageRiskReport, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(params),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || res.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (res.responseCode === Constants.CODE_SUCCESS) {
					// 
				}

				this.setState({
					showLoader: false,
					datalist: this.state.assignmenTypeRiskLevels,
					showAssignmenTypeRiskModal: false,
				});
			});
	}

	/*****************************API*********************************/
	render() {
		return (
			<section>
				<Modal className="task-select-dept-dialog custom-dialog" show={this.props.showRiskDialog} backdrop="static">
					<Modal.Header>
						<h5>Risk Level</h5>
						<button onClick={this.handleSelectRiskDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						{this.state.showLoader && (
							<div
								className="loading-layout"
								style={{ background: `rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},0.7)` }}
							>
								<div className="loading-text-layout">
									<p
										style={{
											color: `rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`,
										}}
									>
										Loading....
									</p>
								</div>
							</div>
						)}
						<div className="body-wrapper">
							{!isEmptyArray(this.state.datalist) && this.state.componentDidMountFlag &&(
								<div className="common-tab-bg">
									<RiskTable
										readOnly={false}
										themeSettings={this.props.themeSettings}
										datalist={this.state.datalist}
										handleSelection={this.handleSelection}
										selectedRiskId={this.state.selectedRiskId}
									/>
								</div>
							)}
							{isEmptyArray(this.state.datalist) && this.state.componentDidMountFlag &&(
								<div class="no-items-layout">
									<div class="no-items-card">
										<h6>{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}</h6>
									</div>
								</div>
							)}
							{/* {!isEmptyVariable(this.state.riskErr) && <span className="cm-error">{this.state.riskErr}</span>} */}
							<div className="modal-close-save m-t-15">
								<div className="close-save-col">
									<button onClick={this.handleSelectRiskDialogClose} type="button" className="modal-close m-b-0">
										Cancel
									</button>
									<DialogSaveButton
										themeSettings={this.props.themeSettings}
										onClick={this.saveReportRisk}
										type="button"
										className="modal-save m-b-0"
									>
										Save
									</DialogSaveButton>
									<DialogSaveButton
										themeSettings={this.props.themeSettings}
										onClick={this.onClickAssignmentTypeRisk}
										type="button"
										className="modal-save m-b-0"
									>
										Choose Another Matrix
									</DialogSaveButton>
								</div>
							</div>
						</div>
						<Modal className="task-select-dept-dialog custom-dialog" show={this.state.showAssignmenTypeRiskModal} onHide={this.hideAssignmentTypeRiskModal}>
							<Modal.Header>
								<h5>Choose Matrix</h5>
								<button onClick={this.hideAssignmentTypeRiskModal} type="button" data-dismiss="modal">
									<span class="material-icons">close</span>
								</button>
							</Modal.Header>
							<hr />
							<div className="modal-body">
								{this.state.showLoader && (
									<div
										className="loading-layout"
										style={{ background: `rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},0.7)` }}
									>
										<div className="loading-text-layout">
											<p
												style={{
													color: `rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`,
												}}
											>
												Loading....
											</p>
										</div>
									</div>
								)}
								<RegularDropdown 
                                    placeholder={this.state.selectedAssignmentTypeRisk?.name}
                                    dropdownArr={this.state.assignmenTypeRisks}
                                    labelParam="name"
                                    onDropDownItemClick={this.onChangeAssignmentTypeRisk}
                                    defaultPlaceholderDropDown = {defaultAssignmentTypeRisk}
                                />
								{!isEmptyVariable(this.state.riskMatrixErr) && <span className="cm-error">{this.state.riskMatrixErr}</span>}
								<div className="mt-3" style={{ minHeight: 250 }}>
									<RiskTable
										readOnly={true}
										themeSettings={this.props.themeSettings}
										datalist={this.state.assignmenTypeRiskLevels}
									/>
								</div>
								<div className="modal-close-save m-t-15">
									<div className="close-save-col">
										<button onClick={this.hideAssignmentTypeRiskModal} type="button" className="modal-close m-b-0">
											Cancel
										</button>
										<DialogSaveButton
											themeSettings={this.props.themeSettings}
											onClick={this.saveAssignmentTypeRisk}
											type="button"
											className="modal-save m-b-0"
										>
											Save
										</DialogSaveButton>
									</div>
								</div>
							</div>
						</Modal>
					</div>
				</Modal>
			</section>
		);
	}
}

export default AssertionAssignmentList;
