import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan, TableIcon} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import MasterMenuTabs from './topmenu';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../common/AlertDialog';
import Pagination from '../../common/Global/pagination';
import TaskDetailsLayout from './taskDetailsWithTemplates';
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable, sortTable,
    isEmptyArray,removeHtmlTags,GetOrderedMasterTabMenuTask,
    ifEmptyReturnEmptyStr} from '../../common/Global/commonFunctions';
import {
    BrowserRouter as Router,
} from 'react-router-dom';

const sortRisk = "d.riskLevel";
const sortTask = "d.task";
const sortProcess = "processNameList";

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();

class ListDraftTasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            deleteReactivateMasterType:"",
            alertDialogMessage:"",

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            status:"Draft",
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,

            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:"",
            user:{},

            departmentArr:[],
            selectedDeptId:"",
            deptPlaceholder:"",
        }
        this.masterTypeFilterArr = [];
    }

    componentDidMount(){
        this.getInitData();
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getTaskList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getTaskList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getTaskList();
        })
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let {departmentArr} = this.state;

        for(const [i,value] of this.state.departmentArr.entries()){
            if(i === selectedIndex){
                departmentArr[i].isSelected = true;
                placeholder = value.name;
            }else{
                departmentArr[i].isSelected = false;
            }
        }
        
        this.setState({
            selectedDeptId:item.departmentId,
            deptPlaceholder:placeholder
        },() => {
            this.getTaskList();
        })
    }

    handleItemDeleteClick = (item,e) => {
        e.stopPropagation();
        this.setState({
            operationType:"Delete",
            alertDialogHeading:"Delete Task",
            proceedBtnLabel:"Delete",
            deleteReactivateId:item.taskId,
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to delete task "+removeHtmlTags(item.task)+"?"
        })
        
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getTaskList();
        })
    }
    handleShowEditTaskLayout = (taskId,allowEdit,assignmentTypeId) => {
        if(allowEdit === "Y"){
            this.props.history.push(Constants.URL_EDITTASK_WITH_TEMPLATES+"/"+taskId+"-"+assignmentTypeId);

        }
    }

    handleHideTaskDetailsLayout = () => {
        this.setState({
            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:""
        })
    }
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetTasks,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([generalRes,menuRes]) => { 
            return Promise.all([generalRes.json(),menuRes.json()]) 
        })
        .then(([generalRes,menuRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;
            let menusTemp = {};
            let userTemp = {};
            let departmentsTemp = [];
            let placeholder = "";
            let selectedDeptId = "";

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.taskList;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
                userTemp = menuRes.data.user;
                departmentsTemp = menuRes.data.departments;

                if(!isEmptyArray(departmentsTemp)){
                    departmentsTemp[0].isSelected = true;
                    placeholder = departmentsTemp[0].name;
                    selectedDeptId = departmentsTemp[0].departmentId;
                }
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenuTask(menusTemp)

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:datalistTemp,
                totalCount:totalCountTemp,
                menus:menusTemp2,
                user:userTemp,
                departmentArr:departmentsTemp,
                deptPlaceholder:placeholder,
                selectedDeptId:selectedDeptId,
            })
        })
    }

    getTaskList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetTasks,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                departmentId:this.state.selectedDeptId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.taskList,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetTasks,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    departmentId:this.state.selectedDeptId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.taskList,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    deleteTask = () => {
        let url = Constants.DeleteTask;

        this.setState({
            showLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                taskId:this.state.deleteReactivateId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    deleteReactivateMasterType:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                },() => {
                    this.getTaskList();
                });
            }else{
                this.setState({
                    showLoader:false,
                    deleteReactivateId:"",
                    deleteReactivateMasterType:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    /*******************API CALLS END HERE*******************/

    render() {

        const actionArr = [
            {
                icon:"edit",
                label:"Edit"
            },
            {
                icon:"delete",
                label:"Delete"
            },
        ]
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    this.state.showLoader &&
                                    <div className="loading-layout"
                                    style={{background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`}}>
                                        <div className="loading-text-layout">
                                            <p
                                            style={{color:`rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`}}
                                            >Loading....</p>
                                        </div>
                                    </div>
                                }
                                {
                                    // Need to check component did mount flag, otherwise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    !this.state.showTaskDetailsLayout &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                        themeSettings={themeSettings}
                                                        onClick = {this.onSearchIconClick} 
                                                        className="material-icons"> search </SearchSpan>
                                                    </div>

                                                    
                                                    {
                                                        this.state.departmentArr.length > 1 &&
                                                        <FilterDropDown
                                                            placeholder={this.state.deptPlaceholder}
                                                            dropdownArr={this.state.departmentArr}
                                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                                            name={"name"}
                                                            themeSettings={themeSettings}
                                                            dropdownWidth={"120px"}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center" width={"5%"}>No.</th>
                                                                <th width={"60%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortTask)}>
                                                                    <div className="sort-header">
                                                                        Task
                                                                        <span className={
                                                                                this.state.sort === sortTask ? "material-icons" : "material-icons hide-sort-arrow"
                                                                            }
                                                                        >
                                                                            {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                                <th width={"10%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortRisk)}>
                                                                    <div className="sort-header">
                                                                        Risk
                                                                        <span className={
                                                                                this.state.sort === sortRisk ? "material-icons" : "material-icons hide-sort-arrow"
                                                                            }
                                                                        >
                                                                            {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                                <th width={"15%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortProcess)}>
                                                                    <div className="sort-header">
                                                                        Process
                                                                        <span className={
                                                                                this.state.sort === sortProcess ? "material-icons" : "material-icons hide-sort-arrow"
                                                                            }
                                                                        >
                                                                            {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                                {/* <th width={"10%"}>Users Count</th> */}
                                                                {
                                                                    this.state.menus[Constants.SCREEN_TASKS] ==="ALL" && (
                                                                        <th className="text-center"  width={"10%"}></th>
                                                                    )
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {
                                                                return <tr onClick={this.handleShowEditTaskLayout.bind(this,item.taskId,item.allowEdit,item.assignmentTypeId)}>
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    <td>{removeHtmlTags(item.task)}</td>
                                                                    <td>{ifEmptyReturnEmptyStr(item.riskLevel,"-")}</td>
                                                                    <td>{item.processNameList}</td>
                                                                    {
                                                                        this.state.menus[Constants.SCREEN_TASKS] ==="ALL" && item.allowEdit === "Y" ? (
                                                                            <TableIcon className="text-center" themeSettings={themeSettings}>
                                                                                <span onClick={this.handleItemDeleteClick.bind(this,item)} className="material-icons"> delete</span>
                                                                            </TableIcon>
                                                                        ):<td></td>
                                                                    }
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings={themeSettings}/>
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.showTaskDetailsLayout &&
                                    <TaskDetailsLayout
                                        taskId = {this.state.selectedTaskIdForDetails}
                                        handleHideTaskDetailsLayout = {this.handleHideTaskDetailsLayout}
                                        subscriptionType={this.state.user.subscriptionType}
                                    />
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                Constants.ALERT_TYPE_WARNING
                            }
                            alertDialogHeading={
                                "Delete Task"
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.deleteTask}
                            proceedBtnLabel={
                                "Delete"
                            }
                            themeSettings={themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                    </section>
                </main>
            </Router>
        );
    }
}

export default ListDraftTasks;