import React, { Component } from 'react';
import {isEmptyVariable,isEmptyArray,removeHtmlTags} from '../Global/commonFunctions';
import {Dropdown} from 'react-bootstrap';
import {TreeNodeDropdownIcon} from '../Global/globalStyles.style'
class TreeNodeDropdown extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
    }

    componentDidUpdate(){
    }

    dropDownItemClick = (item,e) => {
        e.stopPropagation();
        this.props.onDropDownItemClick(item,this.props.dropdownId);
    }

    render(){
        return(
            <div className="regular-dropdown-layout tree-node-dropdown">
            <Dropdown drop={"right"}>
                <Dropdown.Toggle id="dropdown-basic">
                    <p 
                        style={{color:this.props.fontColor,marginTop:this.props.paraMT?this.props.paraMT:0}}
                        className={this.props.fontbold?"placeholder-text font-bold":"placeholder-text"}>
                        {removeHtmlTags(this.props.placeholder.split("$_$")[0])}<span style={{display:"none"}}>{"$_$"+this.props.placeholder.split("$_$")[1]}</span>
                    </p>
                    {
                        this.props.showResponseIcon &&
                        <span className="material-icons-outlined responses-icon">chat</span>
                    }
                </Dropdown.Toggle>
                {
                    !isEmptyVariable(this.props.dropdownArr) &&
                    !isEmptyArray(this.props.dropdownArr) &&
                    <Dropdown.Menu>
                    {
                        !isEmptyVariable(this.props.dropdownArr) &&
                        this.props.dropdownArr.map((item)=>{
                            return <Dropdown.Item onClick={this.dropDownItemClick.bind(this,item)} href="javascript:void(0)">
                                <TreeNodeDropdownIcon
                                themeSettings={this.props.themeSettings}
                                className="material-icons">{item.icon}</TreeNodeDropdownIcon> 
                                <p className="dropdown-text">{item[this.props.labelParam]}</p>
                            </Dropdown.Item>
                        })
                    }
                    </Dropdown.Menu>
                }
            </Dropdown>
            </div>
        )
    }
}

export default TreeNodeDropdown;