import React, { Component } from "react";
import * as Constants from "../../../../common/Global/constants";
import { TableIcon, DialogSaveButton, AddNewButtonLayout,FilterCheckBox } from "../../../../common/Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray, truncateString,
	getOnlyFileNameFromPath,getThemeSettingsVariables } from "../../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";

const themeSettings = getThemeSettingsVariables();

class AssertionAssignmentList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			datalist:[],
			backup_NR:"",
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showUploadBackupDialog) {
			this.setState({
				datalist:this.props.uploadedBackupArr,
				backup_NR:this.props.backup_NR,
			})
		}
	}

	downloadAttachment = (documentPath,e) => {
        e.stopPropagation();
        fetch(Constants.API_SERVER_PATH+documentPath)
        .then(response => { return response.blob(); } )
        .then(blob =>
        {
            let fileName = getOnlyFileNameFromPath(documentPath);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

	/*****************************API*********************************/
	render() {
		const delDropdownObj = {
            icon:"delete",
            label:"Delete"
        }
        let actionObjAllowEdit = [delDropdownObj];
        let actionObjDisallowEdit = [];

		return (
			<section>
				<Modal
					className="task-select-dept-dialog custom-dialog"
					show={this.props.showUploadBackupDialog} backdrop="static"
				>
					<Modal.Header>
						<h5>Backup</h5>
						<button onClick={this.props.handleSelectUploadBackupDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						<div className="body-wrapper">
							<div className="search-addnew-bg m-t-0">
								<div className="search-addnew">
								<div className="search-filter-layout" style={{position:"relative"}}>
									
									<div className="overlay-nr" style={{display:this.state.backup_NR === "Y"?"block":"none"}}></div>
								</div>
									<FilterCheckBox themeSettings={this.props.themeSettings}
									style={{marginLeft:0}}>
										<input 
											type="checkbox"
											name="backup_NR"
											id="backup_NR"
											checked={this.state.backup_NR === "Y"?true:false}
										/>
										<label for="backup_NR">Not Required</label>
									</FilterCheckBox>
								</div>
							</div>

							<div style={{position:"relative"}}>
							{
								(!isEmptyArray(this.state.datalist) && (this.state.backup_NR !== "Y")) &&  
								<div className="common-tab-bg">
									<div className="common-table" style={{width:"100%"}}>
										<table className="table">
											<thead>
												<tr>
													<th className="text-center" width={"5%"}>No.</th>
													<th width={"5%"}>File Name</th>
													<th width={"5%"}>Remarks</th>
													<th className="text-center" width={"5%"}>Download</th>
												</tr>
											</thead>
											<tbody>
											{
												!isEmptyArray(this.state.datalist) && 
												this.state.datalist.map((item,idx) => {
													return !item.isDeleted?<tr>
														<td className="text-center">{(idx+1)}</td>
														<td>{getOnlyFileNameFromPath(item.fileName)}</td>
														<td>{truncateString(item.remark,Constants.REMARKS_STRING_LENGTH)}</td>
														<TableIcon themeSettings={this.props.themeSettings} className="text-center">
															<span class="material-icons" onClick={this.downloadAttachment.bind(this, item.fileName)}>get_app</span>
														</TableIcon>
														
													</tr>
													:
													null
												})
											}
											</tbody>
										</table>
									</div>
								</div>
							}
							{
								(isEmptyArray(this.state.datalist) || (this.state.annexure_NR === "Y")) &&
								<div class="no-items-layout">
									<div class="no-items-card">
										<h6>
										{
											isEmptyVariable(this.state.apiSearchKey)?
											Constants.NO_RECORDS_WARNING
											:
											Constants.EMPTY_SEARCH_WARNING
										}
										</h6>
									</div>
								</div>
							}
							<div className="overlay-nr" style={{display:this.state.backup_NR === "Y"?"block":"none"}}></div>
							</div>


							{/* {!isEmptyVariable(this.state.uploadedBackupErr) && <span className="cm-error">{this.state.uploadedBackupErr}</span>} */}
							<div className="modal-close-save m-t-15">
								<div className="close-save-col">
									<button onClick={this.props.handleSelectUploadBackupDialogClose} type="button" className="modal-close m-b-0">
										Cancel
									</button>
									
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</section>
		);
	}
}

export default AssertionAssignmentList;
