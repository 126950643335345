import React from "react";
import { Modal } from "react-bootstrap";
import RegularDD from '../../../common/DropdownMenus/RegularDropdown';
import { DialogSaveButton } from "../../../common/Global/globalStyles.style";
import { isEmptyVariable } from "../../../common/Global/commonFunctions";

export default class ExecutionTasksActionDialog extends React.PureComponent {
    constructor (props) {
        super(props);
        this.state = {
            action: "",
            error: "",
        };
    }

    actionClick = (selectedItem) => {
        this.setState({
            action: selectedItem.label,
        });
    }

    handleCloseDialog = () => {
        this.setState({
            action: "",
            error: "",
        });
        this.props.handleExecutionTaskActionDialogClose();
    }

    handleExecutionTaskActionOk = () => {
        this.setState({
            error: "",
        });

        if (this.state.action === "") {
            this.setState({
                error: "Please select action",
            });
        }
        else {
            this.props.handleExecutionTaskActionOk(this.state.action);
            this.handleCloseDialog();
        }
    }

    render () {
        const actionArr = [{ label: "Mark as Completed" }, { label: "Submit For Review" }];
        return (
            <Modal className="review-history-dialog custom-dialog"  show={this.props.showDialog} onHide={this.handleCloseDialog}>
                <Modal.Header>
                    <h5>Execution Tasks Action</h5>
                    <button 
                        onClick={this.handleCloseDialog}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    <RegularDD
                        placeholder={this.state.action || "Select action"}
                        dropdownArr={actionArr}
                        labelParam="label"
                        onDropDownItemClick={this.actionClick}
                    />
                    {
                        !isEmptyVariable(this.state.error) &&
                        <span class="cm-error">{this.state.error}</span>
                    }
                    <div class="modal-close-save mt-4">
                        <div class="close-save-col">
                            <button onClick={this.handleCloseDialog} type="button" class="modal-close">Cancel</button>
                            <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.handleExecutionTaskActionOk} type="button">OK</DialogSaveButton>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}