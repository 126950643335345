import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getThemeSettingsVariables, isEmptyVariable } from '../../common/Global/commonFunctions';
import {TopTabsLayout} from '../../common/Global/globalStyles.style';


let themeColor = ``;
const themeSettings = getThemeSettingsVariables();
class CompaniesTopMenu extends Component{
    constructor(props) {
        super(props);
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
    }

    companiesOnClick = () => {
        this.props.companyTabItemOnClick();
    }

    unitsOnClick = () => {
        this.props.unitTabItemOnClick(this.props.companyId)
    }

    employeesOnClick = () => {
        this.props.employeesTabItemOnClick(this.props.unitId)
    }

    projectOnClick = () => {
        this.props.projectTabItemOnClick();
    }
    groupingOnClick = () =>{
        this.props.gropingTabItemOnClick();
    }

    render(){
        const {companyId,showUnit,unitId,displayingCompanies,displayingUnits,
            displayingEmployees,showProject,displayingProjects,displayingGrouping} = this.props;

        let width=showProject?"37.5%":"25%";
        if(!isEmptyVariable(companyId) && !isEmptyVariable(unitId)){
            width = showProject?"62.5%":"50%";
        }else if(!isEmptyVariable(companyId)){
            width = showProject?"50%":"37.5%";
        }
        
        return(
            <TopTabsLayout
            themeSettings={this.props.themeSettings}
            >
            
                <div className="question-tabs"  style={{marginRight:0}}>
                    <ul style={{width:width}}>

                    {/* By default Clients must be displayed. if users tab is there then we need to
                    display both the tabls */}
                    <li className={displayingCompanies?"questiontab-active":""}>
                        <a onClick={this.companiesOnClick} href="javascript:void(0)"
                        style={{color: !displayingCompanies ? themeColor : '', fontWeight: !displayingCompanies ? "bold" : "100px"}}>Clients</a>
                    </li>
                    {
                        !isEmptyVariable(companyId) && showUnit &&
                        <li className={displayingUnits?"questiontab-active":""}>
                            <a onClick={this.unitsOnClick} href="javascript:void(0)" 
                            style={{color: !displayingUnits ? themeColor : '', fontWeight: !displayingUnits ? "bold" : "100px"}}>Units</a>
                        </li>
                    }
                    {
                        !isEmptyVariable(unitId) && 
                        <li className={displayingEmployees?"questiontab-active":""}>
                            <a onClick={this.employeesOnClick} href="javascript:void(0)" 
                            style={{color: !displayingEmployees ? themeColor : '', fontWeight: !displayingEmployees ? "bold" : "100px"}}>Employees</a>
                        </li>
                    }
                    {
                        showProject && 
                        <li className={displayingProjects?"questiontab-active":""}>
                            <a onClick={this.employeesOnClick} href="javascript:void(0)" 
                            style={{color: !displayingProjects ? themeColor : '', fontWeight: !displayingProjects ? "bold" : "100px"}}>Projects</a>
                        </li>
                    }
                     <li className={displayingGrouping?"questiontab-active":""}>
                        <a onClick={this.groupingOnClick} href="javascript:void(0)"
                        style={{color: !displayingGrouping ? themeColor : '', fontWeight: !displayingGrouping ? "bold" : "100px"}}>Grouping</a>
                    </li>
                    </ul>
                </div>

                <div onClick={this.props.toggleCardsDisplay}
                style={{opacity:this.props.hideInfo?"0":"1"}} className="infocard-showhide-col">
                    {/* <div className="infocard-icon-col">
                        <img src="/assets/images/topbar-info.svg" />
                    </div> */}
                </div>
            </TopTabsLayout>
        )
    }
}

export default withRouter(CompaniesTopMenu);