import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';

const userDetails  = getLocalStorageVariables();
class CompanyDetailsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(){
    }


    render() {
        return(
            <Modal className="user-details-dialog custom-dialog" 
            show={this.props.showCompanyDetailsDialog} onHide={this.props.handleCompanyDetailsDialogClose}>
                <Modal.Header>
                    <h5>{this.props.companyData.companyName+" Details"}</h5>
                    <button 
                        onClick={this.props.handleCompanyDetailsDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="body-wrapper">
                        {
                            !isEmptyVariable(this.props.companyData.companyId) && 
                            <div className="details-table-layout">
                                <div className="row custom-row">
                                    <div className="col-md-6 custom-col">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td width={"48%"}>Company Name</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.companyData.companyName}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Short Name</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.companyData.companyShortName}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Client Code</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.companyData.companyCode}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Company Type</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.companyData.name}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Address line 1</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.companyData.address1}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Address line 2</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{isEmptyVariable(this.props.companyData.address2)?"-":this.props.companyData.address2}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6 custom-col">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td width={"48%"}>City</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.companyData.city}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Pin code</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.companyData.pinCode}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>State</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.companyData.state}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Country</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.companyData.country}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default CompanyDetailsDialog;