import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {isEmptyVariable, getLocalStorageVariables} from '../../../common/Global/commonFunctions';
import {DialogSaveButton} from '../../../common/Global/globalStyles.style';
import {Modal} from 'react-bootstrap';
import { toast } from 'react-toastify';
let userDetails = {};

class editTaskDescriptionDialog extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showLoader:false,
            description:"",
            formErrors:{},
        }
        
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) 
        && this.props.showDescriptionDialog) {
            this.setState({
                description:""
            })
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        let FormErrorsTemp = this.state.formErrors;

        if(!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH){
            FormErrorsTemp['description'] = "Description must be less then 500 characters";
        }else{
            FormErrorsTemp['description'] = "";
        }

        this.setState({ 
            description: value,
            formErrors:FormErrorsTemp 
        });
    }

    handleDescriptionDialogClose = () => {
        this.setState({
            description:""
        },()=>{
            this.props.handleDescriptionDialogClose();
        })
    }

    handleEditDescription = () => {
        this.setState({
            showLoader:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(!isEmptyVariable(this.state.description)){
            if(this.state.description.length > Constants.DESC_STRING_LENGTH){
                isError = true;
                FormErrorsTemp['description'] = "Description must be less then 500 characters";
            }
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            // userDetails  = getLocalStorageVariables();
            // fetch("",{
            //     method: "POST",
            //     mode:'cors',
            //     body: new URLSearchParams({
            //         email:userDetails.email,
            //         accessToken:userDetails.accessToken,
            //         description:this.state.description,
            //     })
            // })
            // .then(response => { return response.json(); } )
            // .then(data =>
            // {
            //     if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            //         data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
            //         localStorage.clear();
            //         window.location="/";
            //     }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        description:"",
                        formErrors:{}
                    },() => {
                        this.props.handleDescriptionDialogClose();
                    });
            //     }else{
            //         toast.error(data.responseMessage);
            //         this.setState({
            //             showLoader:false
            //         });
            //     }
            // });
        }
    }

    render(){
        return(
            <section>
                <Modal className="custom-dialog" show={this.props.showDescriptionDialog} backdrop="static">
                    <Modal.Header>
                        <h5>{"Edit Description"}</h5>
                        <button 
                            onClick={this.handleDescriptionDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        {
                            this.state.showLoader &&
                            <div className="modal-loading-text">
                                <p
                                style={{
                                    background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                    color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                                }}
                                >Loading....</p>
                            </div>
                        }
                        <div class="addnew-modal-form">
                            <div class="addnew-modal-form-group">
                                <p>{this.props.item?.value}</p>
                                <textarea 
                                    type="text"
                                    placeholder="Enter Description"
                                    spellCheck="true"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['description']) &&
                                    <span class="cm-error">{this.state.formErrors['description']}</span>
                                }
                            </div>
                        </div>
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.handleDescriptionDialogClose} type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton 
                                    themeSettings={this.props.themeSettings}
                                    onClick={this.handleEditDescription}
                                    type="button" class="modal-save">
                                        Save
                                </DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
        )
    }

}

export default editTaskDescriptionDialog;