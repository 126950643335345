import React, { Component } from 'react'
import { matchPath, withRouter } from 'react-router-dom';
import * as Constants from '../../common/Global/constants';
import { isEmptyVariable, getLocalStorageVariables,getThemeSettingsVariables } from '../../common/Global/commonFunctions';
import { TopTabsLayout } from '../../common/Global/globalStyles.style';

let isClient ='';
let isDepartment ='';
let themeColor = ``;
class SettingTopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
        }
        isClient = this.checkActivePath("/clientSettings");
        isDepartment = this.checkActivePath("/departmentsettings");

    }
    checkActivePath = (path) =>{
        const themeSettings = getThemeSettingsVariables();
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
       return matchPath(this.props.history.location.pathname, { path });
    }
  render() {

    let width = 25;
    // if (!isEmptyVariable(this.props.masterMenus)) {
    //     width = 12.5 * Object.keys(this.props.masterMenus).length
    // }
    return (
        <TopTabsLayout themeSettings={this.props.themeSettings}>
        {this.state.showLoader && (
            <div
                className="loading-layout"
                style={{ background: `rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},0.7)` }}
            >
                <div className="loading-text-layout">
                    <p
                        style={{
                            color: `rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`,
                        }}
                    >
                        Loading....
                    </p>
                </div>
            </div>
        )}
        <div className="question-tabs" style={{ marginRight: 0 }}>
            
                <ul style={{ width: width + "%" }}>
                  {(!isEmptyVariable(this.props.menus['Client Settings']) && this.props.menus['Client Settings'] !== "NONE")&& 
                       <li
                            className={
                                matchPath(this.props.history.location.pathname, { path: "/clientSettings" })
                                    ? "questiontab-active"
                                    : ""
                            }
                            >
                            <a href={Constants.URL_CLIENT_SETTINGS}
                                style={{color: !isClient ? themeColor : '', fontWeight: !isClient ? "bold" : "100px"}}>Client</a>
                        </li>
                    }
                       
                    {  (!isEmptyVariable(this.props.menus['Department Settings']) && this.props.menus['Department Settings'] !== "NONE") &&
                        <li
                            className={
                                matchPath(this.props.history.location.pathname, { path:"/departmentsettings"})
                                    ? "questiontab-active"
                                    : ""
                            }
                        >
                            <a href={Constants.URL_DEPARTMENT_SETTINGS}
                                style={{color: !isDepartment ? themeColor : '', fontWeight: !isDepartment ? "bold" : "100px"}}>Department</a>
                        </li>
                    }
                    
                  
                </ul>
            
        </div>

    </TopTabsLayout>
    )
  }
}

export default withRouter(SettingTopMenu);
