import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {TableRadioButton,DialogSaveButton} from '../../common/Global/globalStyles.style';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();

class departmentList extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedDeptId:"",
            // deptErr:"",
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showDeptDialog){
            let selectedId=this.props.selectedDeptId;
            this.setState({
                selectedDeptId:selectedId,
                // deptErr:"",
            })
            
        }
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let isChecked = e.target.checked;
        let deptId = e.target.name;
        let selectedId = "";
        
        if(isChecked){
            selectedId = deptId;
        }

        this.setState({
            selectedDeptId:selectedId
        });
    }

    returnSelectedDeptId = () => {
        let deptErrTemp = "";

        if(isEmptyVariable(this.state.selectedDeptId)){
            // deptErrTemp =  "Select the department";
            toast.warning("Select the department");
            // this.setState({
            //     deptErr:deptErrTemp,
            // })

            // setTimeout(function(){
            //     this.setState({
            //         deptErr:"",
            //     });
            // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else{
            this.props.handleSelectDeptDialogSaveClose(this.state.selectedDeptId);
        }
    }
    /*****************************API*********************************/
    render(){
        return(
            <section>
                <Modal className="task-select-dept-dialog custom-dialog"
                    show={this.props.showDeptDialog} 
                    onHide={this.props.handleSelectDeptDialogClose}>
                    <Modal.Header>
                        <h5>Departments</h5>
                        {
                            // !this.props.showInitialSelectDeptDialog &&
                            <button 
                                onClick={this.props.handleSelectDeptDialogClose}
                                type="button" data-dismiss="modal">
                                <span class="material-icons">close</span>
                            </button>
                        }
                        
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        <div className="body-wrapper">
                            {
                                !isEmptyArray(this.props.departmentArr) && 
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" width={"8%"}></th>
                                                    <th className="text-center" width={"5%"}>No</th>
                                                    <th>Short Name</th>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                !isEmptyArray(this.props.departmentArr) && 
                                                this.props.departmentArr.map((item,idx) => {

                                                    return <tr>
                                                        <td>
                                                            <TableRadioButton
                                                                themeSettings={this.props.themeSettings}>
                                                                <input 
                                                                    type="checkbox"
                                                                    name={item.departmentId}
                                                                    id={item.departmentId}
                                                                    onChange={this.handleSelection}
                                                                    checked={item.departmentId+""===this.state.selectedDeptId+""?true:false}
                                                                />
                                                                <label for={item.departmentId} 
                                                                style={{marginLeft:6}}></label>
                                                            </TableRadioButton>
                                                        </td>
                                                        <td className="text-center">{(idx+1)}</td>
                                                        <td>{item.shortName}</td>
                                                        <td>{item.name}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                isEmptyArray(this.props.departmentArr) &&
                                <div class="no-items-layout">
                                    <div class="no-items-card">
                                        <h6>
                                        {
                                            isEmptyVariable(this.state.apiSearchKey)?
                                            Constants.NO_RECORDS_WARNING
                                            :
                                            Constants.EMPTY_SEARCH_WARNING
                                        }
                                        </h6>
                                    </div>
                                </div>
                            }
                            {/* {
                                !isEmptyVariable(this.state.deptErr) &&
                                <span className="cm-error">{this.state.deptErr}</span>
                            } */}
                            <div className="modal-close-save m-t-15">
                                <div className="close-save-col">
                                {
                                    // !this.props.showInitialSelectDeptDialog &&
                                    <button onClick={this.props.handleSelectDeptDialogClose} type="button" 
                                    className="modal-close m-b-0">Cancel</button>
                                }
                                    
                                    <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                    onClick={this.returnSelectedDeptId} type="button" 
                                    className="modal-save m-b-0">Save</DialogSaveButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
        )
    }
}

export default departmentList;