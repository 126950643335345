import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {DialogSaveButton,TableRadioButton,TreeNodeCheckBox} from '../../../common/Global/globalStyles.style';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import {getLocalStorageVariables,isEmptyVariable,
    isEmptyArray} from '../../../common/Global/commonFunctions';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import moment from "moment";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import {Modal} from 'react-bootstrap';
import SelectFunctionDialog from '../Common/selectFunction';
import SelectProcessDialog from '../Common/selectProcess';

const functionDropdownDefaultValue = "Select Function";
const processDropdownDefaultValue = "Select Process";
const companyDefaultPlaceholder = "Select Client";
const plannedAssignmentDefaultPlaceholder = "Select Assignment";
const unitDefaultPlaceholder = "Select Units";
const departmentDropdownDefaultValue = "Select Department";
const assignmentTypeDefaultPlaceholder = "Select Assignment Type";
const defaultRiskPlaceholder = "Select Risk";
const defaultVAPlaceholder = "Select Value Addition";
const unplannedReasonDefaultPlaceholder = "Select Reason";
const userDetails  = getLocalStorageVariables();

class CreateAnnualPlan extends Component{
    constructor(props){
        super(props);
        this.state = {
            isNewAssignment:false,
            planDate:"",
            selectedCompanyId:"",
            selectedCompanyPlaceholder:companyDefaultPlaceholder,
            
            companyUnits:[],
            selectedUnitIds:[],
            selectedUnitPlaceholder:unitDefaultPlaceholder,
            isUnitsDropdownEnabled:false,
            
            selectedAssignmentTypeId:"",
            assignmentTypeFiltered:[],
            selectedAssignmentTypePlaceholder:assignmentTypeDefaultPlaceholder,
            
            assignmentArea:"",
            selectedFunctionObjArr:[],
            selectedProcessObjArr:[],

            selectedPlannedAssignment:plannedAssignmentDefaultPlaceholder,
            selectedPlannedAssignmentId:"",
            datalist:[],
            disablePlannedAssignment:true,

            selectedDepartmentId:"",
            selectedDepartmentName:departmentDropdownDefaultValue,
            
            startDate:"",
            endDate:"",
            reportDate:"",
            referenceNo:"",

            selectedRiskPlaceholder:defaultRiskPlaceholder,
            selectedRiskId:"",

            selectedValuePlaceholder:defaultVAPlaceholder,
            selectedVAIds:[],

            periodFromDate:"",
            periodToDate:"",

            submitClickedAtleastOnce:false,
            formErrors:{},
        }
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){

            let dropdownItem = this.props.selectedItem;
            let companyUnits = [];
            companyUnits = this.props.units.filter(unit => unit.companyId === dropdownItem.companyId);

            let assignmentTypeFiltered = this.props.assignmentType.filter(at => at.departmentId === dropdownItem.departmentId);
            
            let selectedUnitIds = [];
            let splitArr = dropdownItem.unitList.split(",");
            if(!isEmptyArray(splitArr)){
                splitArr.map((item)=>{
                    selectedUnitIds.push(parseInt(item.split(":-:")[1]));
                })
            }

            let selectedProcessObjArr = [];
            splitArr = dropdownItem.processList.split(",");
            if(!isEmptyArray(splitArr)){
                splitArr.forEach((item)=>{
                    if(!isEmptyVariable(item.split(":-:")[1])){
                        selectedProcessObjArr.push({
                            processId:item.split(":-:")[1]
                        })
                    }
                })
            }

            let selectedFunctionObjArr = [];
            splitArr = dropdownItem.functionList.split(",");
            if(!isEmptyArray(splitArr)){
                splitArr.map((item)=>{
                    if(!isEmptyVariable(item.split(":-:")[1])){
                        selectedFunctionObjArr.push({
                            functionId:item.split(":-:")[1]
                        });
                    }
                })
            }

            this.setState({
                selectedDepartmentId:dropdownItem.departmentId,
                planDate:"",
                selectedCompanyId:dropdownItem.companyId,
                selectedCompanyPlaceholder:dropdownItem.companyName,

                companyUnits:companyUnits,
                selectedUnitIds:selectedUnitIds,
                isUnitsDropdownEnabled:true,

                assignmentTypeFiltered:assignmentTypeFiltered,
                selectedAssignmentTypeId:dropdownItem.plannedAssignmentTypeId,
                selectedAssignmentTypePlaceholder:dropdownItem.assignmentTypeName,

                selectedFunctionObjArr:selectedFunctionObjArr,
                selectedProcessObjArr:selectedProcessObjArr,
                assignmentArea:dropdownItem.plannedAssignmentArea,
                selectedId:dropdownItem.assignmentId,

                startDate:"",
                endDate:"",
                reportDate:"",
                referenceNo:"",

                selectedRiskPlaceholder:defaultRiskPlaceholder,
                selectedRiskId:"",

                selectedVAIds:[],

                periodFromDate:"",
                periodToDate:"",

                submitClickedAtleastOnce:false,
                formErrors:{}
            });
        }
    }

    handleCreateDialogClose = (flag) => {
        this.setState({
            companyUnits:[]
        },()=>{
            this.props.handleCreateDialogClose(flag);
        })
        
    }

    riskDropdownClick = (item) => {

        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['risk'] = "";
        }

        this.setState({
            selectedRiskPlaceholder:item.name,
            selectedRiskId:item.riskId,
            formErrors:FormErrorsTemp
        })
    }

    onDateChange = (date, dateString) => {
		let planDate = "";
		if (!isEmptyVariable(date)) {
			planDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['planDate'] = "";
		this.setState({
            planDate:planDate,
            formErrors:FormErrorsTemp
        })
    };

    onStartDateChange = (date, dateString) => {
		let startDate = "";
		if (!isEmptyVariable(date)) {
			startDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['startDate'] = "";
		this.setState({
            startDate:startDate,
            formErrors:FormErrorsTemp
        })
    };

    onEndDateChange = (date, dateString) => {
		let endDate = "";
		if (!isEmptyVariable(date)) {
			endDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['endDate'] = "";
		this.setState({
            endDate:endDate,
            formErrors:FormErrorsTemp
        })
    };
    
    onReportDateChange = (date, dateString) => {
		let reportDate = "";
		if (!isEmptyVariable(date)) {
			reportDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['reportDate'] = "";
		this.setState({
            reportDate:reportDate,
            formErrors:FormErrorsTemp
        })
    };
    
    onPeriodFromDateChange = (date, dateString) => {
		let periodFromDate = "";
		if (!isEmptyVariable(date)) {
			periodFromDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['periodFromDate'] = "";
		this.setState({
            periodFromDate:periodFromDate,
            formErrors:FormErrorsTemp
        })
    };
    
    onPeriodToDateChange = (date, dateString) => {
		let periodToDate = "";
		if (!isEmptyVariable(date)) {
			periodToDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['periodToDate'] = "";
		this.setState({
            periodToDate:periodToDate,
            formErrors:FormErrorsTemp
        })
    };
       
    disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    //Functions
    handleSelectFunctionDialogClose = () => {
        this.setState({
            showSelectFunctionDialog:false,
        })
    }
    handleSelectFunctionDialogSaveClose = (selectedFunctionObjArr) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp['functionList'] = "";
        this.setState({
            showSelectFunctionDialog:false,
            selectedFunctionObjArr:selectedFunctionObjArr,
            formErrors:FormErrorsTemp,
        })
    }
    handleSelectFunctionDialogShow = () => {
        let formErr = this.state.formErrors;
        if(isEmptyVariable(this.state.selectedDepartmentId) && !this.props.isEdit){
            formErr["functionList"] = "Please select the department before function selection"
            this.setState({
                formErrors:formErr
            })
        }else{
            this.setState({
                showSelectFunctionDialog:true,
            })
        }
        
    }

    //Process
    handleSelectProcessDialogClose = () => {
        this.setState({
            showSelectProcessDialog:false,
        })
    }

    handleSelectProcessDialogSaveClose = (selectedProcessObjArr) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp['processList'] = "";
        this.setState({
            showSelectProcessDialog:false,
            formErrors:FormErrorsTemp,
            selectedProcessObjArr:selectedProcessObjArr
        })
    }

    handleSelectProcessDialogShow = () => {
        let formErr = this.state.formErrors;
        if(isEmptyVariable(this.state.selectedDepartmentId) && !this.state.isEdit){
            formErr["processList"] = "Please select the department before process selection"
            this.setState({
                formErrors:formErr
            })
        }else{
            this.setState({
                showSelectProcessDialog:true,
            })
        }
        
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        let FormErrorsTemp  = this.state.formErrors;
        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['referenceNo'] = isEmptyVariable(value)?"Please enter reference no":"";
        }

        this.setState({
            [name]:value,
            formErrors:FormErrorsTemp
        })
    }

    companyDropdownClick = (item) => {
        this.setState({
            showLoader:true,
        })
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['company'] = "";
        }

        let companyUnits = this.props.units.filter(unit => unit.companyId === item.companyId);

        this.setState({
            selectedCompanyPlaceholder:item.companyName,
            selectedCompanyId:item.companyId,
            formErrors:FormErrorsTemp,

            //reset units when company is changed
            isUnitsDropdownEnabled:true,
            selectedUnitIds:[],
            selectedUnitPlaceholder:unitDefaultPlaceholder,
            companyUnits:companyUnits,

            showLoader:false,
        })
    }

    addSelection = (args) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp.selectedUnit = "";
        console.log(this.state.selectedUnitIds);
        if(!isEmptyVariable(args.itemData.unitId))
        {
            this.state.selectedUnitIds.push(args.itemData.unitId)
        }
        this.setState({
            selectedUnitIds:this.state.selectedUnitIds,
            FormErrors:FormErrorsTemp
        })

    }

    removeSelection = (args) => {
        if(!isEmptyVariable(args.itemData.unitId)){
            let idx = this.state.selectedUnitIds.indexOf(args.itemData.unitId);
            if(idx !== -1){
                this.state.selectedUnitIds.splice(idx,1);
            }
        }
        this.setState({
            selectedUnitIds:this.state.selectedUnitIds
        })
    }

    addSelectionVA = (args) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp.selectedVA = "";

        if(!isEmptyVariable(args.itemData.valueAdditionId))
        {
            this.state.selectedVAIds.push(args.itemData.valueAdditionId)
        }

        this.setState({
            selectedVAIds:this.state.selectedVAIds,
            FormErrors:FormErrorsTemp
        })

    }

    removeSelectionVA = (args) => {
        if(!isEmptyVariable(args.itemData.valueAdditionId)){
            let idx = this.state.selectedVAIds.indexOf(args.itemData.valueAdditionId);
            if(idx !== -1){
                this.state.selectedVAIds.splice(idx,1);
            }
        }
        this.setState({
            selectedVAIds:this.state.selectedVAIds
        })
    }

    assignmentTypeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['assignmentType'] = "";
        }

        this.setState({
            selectedAssignmentTypePlaceholder:item.name,
            selectedAssignmentTypeId:item.assignmentTypeId,
            formErrors:FormErrorsTemp,
        })
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let type = e.target.name;

        let FormErrorsTemp = this.state.formErrors;

        this.setState({
            assignmentArea:type,
            FormErrors:FormErrorsTemp
        });
    }

    handleSelectionIsNew = (e) => {
        let isChecked = e.target.checked;
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp.checkListType = "";

        this.setState({
            isNewAssignment:isChecked,
            FormErrors:FormErrorsTemp
        })
    }

    handleCreateMaster = () => {
        this.setState({
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.selectedCompanyPlaceholder) 
        || this.state.selectedCompanyPlaceholder === companyDefaultPlaceholder){
            isError = true;
            FormErrorsTemp['company'] = "Please select company";
        }

        if(isEmptyVariable(this.state.selectedAssignmentTypePlaceholder) 
        || this.state.selectedAssignmentTypePlaceholder === assignmentTypeDefaultPlaceholder){
            isError = true;
            FormErrorsTemp['assignmentType'] = "Please select assignment type";
        }

        if(isEmptyArray(this.state.selectedUnitIds)){
            isError = true;
            FormErrorsTemp['selectedUnit'] = "Please select units";
        }

        if(isEmptyVariable(this.state.assignmentArea)){
            isError = true;
            FormErrorsTemp['assignmentArea'] = "Please select Scope";
        }

        if(this.state.assignmentArea==="process" &&
        isEmptyArray(this.state.selectedProcessObjArr)){
            isError = true;
            FormErrorsTemp['processList'] = "Please select process";
        }

        if(this.state.assignmentArea==="function" &&
        isEmptyArray(this.state.selectedFunctionObjArr)){
            isError = true;
            FormErrorsTemp['functionList'] = "Please select function";
        }

        //additional fields
        if(isEmptyVariable(this.state.startDate)){
            isError=true;
            FormErrorsTemp['startDate'] = "Please select the start date";
        }

        if(isEmptyVariable(this.state.endDate)){
            isError=true;
            FormErrorsTemp['endDate'] = "Please select the end date";
        }

        if(!isEmptyVariable(this.state.startDate) &&
        !isEmptyVariable(this.state.endDate) &&
        !moment(this.state.endDate).isAfter(this.state.startDate)){
            isError=true;
            FormErrorsTemp['endDate'] = "End date must be greater than start date";
        }

        if(isEmptyVariable(this.state.reportDate)){
            isError=true;
            FormErrorsTemp['reportDate'] = "Please select the report date";
        }

        if(isEmptyVariable(this.state.referenceNo)){
            isError=true;
            FormErrorsTemp['referenceNo'] = "Please enter reference no";
        }

        if(isEmptyVariable(this.state.periodFromDate)){
            isError=true;
            FormErrorsTemp['periodFromDate'] = "Please select the period from";
        }

        if(isEmptyVariable(this.state.periodToDate)){
            isError=true;
            FormErrorsTemp['periodToDate'] = "Please select the period to";
        }

        if(!isEmptyVariable(this.state.periodFromDate) &&
        !isEmptyVariable(this.state.periodToDate) &&
        !moment(this.state.periodToDate).isAfter(this.state.periodFromDate)){
            isError=true;
            FormErrorsTemp['periodToDate'] = "End date must be greater than start date";
        }

        // if(isEmptyVariable(this.state.selectedRiskPlaceholder) ||
        // this.state.selectedRiskPlaceholder===defaultRiskPlaceholder){
        //     isError=true;
        //     FormErrorsTemp['risk'] = "Please select the risk";
        // }

        // if(isEmptyArray(this.state.selectedVAIds)){
        //     isError=true;
        //     FormErrorsTemp['selectedVA'] = "Please select the value addition";
        // }
        
        
        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = Constants.ScheduleAssignment;
            let postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                assignmentId:this.state.selectedId,

                units:JSON.stringify(this.state.selectedUnitIds),
                assignmentTypeId:this.state.selectedAssignmentTypeId,
                assignmentArea:this.state.assignmentArea,
                referenceNo:this.state.referenceNo,
                startDate:this.state.startDate,
                endDate:this.state.endDate,
                reportDate:this.state.reportDate,
                reportFormat:'Word',
                periodFromDate:this.state.periodFromDate,
                periodToDate:this.state.periodToDate,
                riskId:this.state.selectedRiskId,
                valueAdditions:JSON.stringify(this.state.selectedVAIds),
            }

            if(this.state.assignmentArea === "Process"){
                let processIds = [];

                this.state.selectedProcessObjArr.map(item=>{
                    if(!isEmptyVariable(item) && !isEmptyVariable(item.processId)){
                        processIds.push(item.processId)
                    }
                })
                postParams.processIds = JSON.stringify(processIds)

            }else if(this.state.assignmentArea === "Function"){
                let functionIds = [];
                this.state.selectedFunctionObjArr.map(item=>{
                    functionIds.push(item.functionId)
                })
                postParams.functionIds = JSON.stringify(functionIds)
            }else if(this.state.assignmentArea === "CheckList"){
                
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParams)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showCreateDialog:false,
                        showLoader:false,
                        isEdit:false,
                        selectedId:"",
                        name:"",
                        shortName:"",
                        description:"",
                    },() => {
                        this.handleCreateDialogClose(true);
                    });
                }else{
                    FormErrorsTemp['apiErr'] = data.responseMessage;
                    this.setState({
                        formErrors:FormErrorsTemp,
                        showLoader:false
                    });
                }
            });
        }
    }

    render(){
        let fields = {text:"unitName",value:"unitId"}
        let fieldsVA = {text:"name",value:"valueAdditionId"}
        return(
            <div>
                <Modal className="custom-dialog scheduled-assignment" show={this.props.showCreateDialog} 
                backdrop="static">
                    <Modal.Header>
                        <h5>{this.props.isEdit?"Edit Assignment":"Schedule Assignment"}</h5>
                        <button 
                            onClick={this.handleCreateDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        <div class="addnew-modal-form">
                            <div className="row custom-row">
                                {
                                    this.props.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                    <div className="col-md-6 custom-col">
                                        <div class="addnew-modal-form-group">
                                            <p>Client*</p>
                                            <RegularDropdown 
                                                placeholder={this.state.selectedCompanyPlaceholder}
                                                dropdownArr={this.props.companies}
                                                labelParam="companyName"
                                                onDropDownItemClick={this.companyDropdownClick}
                                                disabled={true}
                                                defaultPlaceholderDropDown={companyDefaultPlaceholder}
                                            />
                                            {
                                                !isEmptyVariable(this.state.formErrors['company']) &&
                                                <span class="cm-error">{this.state.formErrors['company']}</span>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Units*</p>
                                        <MultiSelectComponent 
                                            id="unitsCheckbox"
                                            dataSource={this.state.companyUnits}
                                            fields={fields}
                                            placeholder={this.state.selectedUnitPlaceholder} 
                                            mode="CheckBox"
                                            closePopupOnSelect={false}
                                            allowFiltering={false}
                                            select={this.addSelection}
                                            removed={this.removeSelection}
                                            cssClass="clientsChecklist dialogcl"
                                            value={this.state.selectedUnitIds}
                                            showDropDownIcon={true}
                                            showClearButton={false}
                                            enabled={this.state.isUnitsDropdownEnabled}
                                        >
                                            <Inject services={[CheckBoxSelection]} />
                                        </MultiSelectComponent>
                                        {
                                            !isEmptyVariable(this.state.formErrors['selectedUnit']) &&
                                            <span className="cm-error">{this.state.formErrors['selectedUnit']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Assignment Type*</p>
                                        <RegularDropdown 
                                            placeholder={this.state.selectedAssignmentTypePlaceholder}
                                            dropdownArr={this.state.assignmentTypeFiltered}
                                            labelParam="name"
                                            onDropDownItemClick={this.assignmentTypeDropdownClick}
                                            defaultPlaceholderDropDown={assignmentTypeDefaultPlaceholder}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['assignmentType']) &&
                                            <span class="cm-error">{this.state.formErrors['assignmentType']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Reference No.*</p>
                                        <input 
                                            type="text" 
                                            placeholder="Enter Reference Number" 
                                            name="referenceNo"
                                            onChange ={this.handleChange}
                                            value={this.state.referenceNo}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['referenceNo']) &&
                                            <span className="cm-error">{this.state.formErrors['referenceNo']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row custom-row">
                                <div class="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Scope*</p>
                                        <TableRadioButton
                                        style={{marginLeft:0,display:"inline-flex"}}
                                        themeSettings={this.props.themeSettings}>
                                            <input 
                                                type="checkbox"
                                                name={"Process"}
                                                id={"processCb"}
                                                onChange={this.handleSelection}
                                                checked={this.state.assignmentArea==="Process"?true:false}
                                            />
                                            <label for={"processCb"} 
                                            style={{marginLeft:0}}>Process</label>
                                        </TableRadioButton>
                                        <TableRadioButton
                                        style={{marginLeft:10,display:"inline-flex"}}
                                        themeSettings={this.props.themeSettings}>
                                            <input 
                                                type="checkbox"
                                                name={"Function"}
                                                id={"functionCb"}
                                                onChange={this.handleSelection}
                                                checked={this.state.assignmentArea==="Function"?true:false}
                                            />
                                            <label for={"functionCb"} 
                                            style={{marginLeft:0}}>Function</label>
                                        </TableRadioButton>
                                        <TableRadioButton
                                        style={{marginLeft:10,display:"inline-flex"}}
                                        themeSettings={this.props.themeSettings}>
                                            <input 
                                                type="checkbox"
                                                name={"CheckList"}
                                                id={"checklistCb"}
                                                onChange={this.handleSelection}
                                                checked={this.state.assignmentArea==="CheckList"?true:false}
                                            />
                                            <label for={"checklistCb"} 
                                            style={{marginLeft:0}}>Checklist</label>
                                        </TableRadioButton>
                                        {
                                            !isEmptyVariable(this.state.formErrors['assignmentArea']) &&
                                            <span className="cm-error">{this.state.formErrors['assignmentArea']}</span>
                                        }
                                    </div>
                                </div>
                                <div class="col-md-6 custom-col">
                                {
                                    this.state.assignmentArea==="Function" &&
                                    <div className="addnew-modal-form-group">
                                        <p>Function</p>
                                        <div className="dummy-dropdown" tabIndex={0} onKeyPress={this.onKeyBtnPress}  
                                        onClick={this.handleSelectFunctionDialogShow}>
                                            <p class="placeholder-text">
                                                {isEmptyArray(this.state.selectedFunctionObjArr)
                                                ?functionDropdownDefaultValue
                                                :this.state.selectedFunctionObjArr.length === 1
                                                ?"1 Function Selected"
                                                :this.state.selectedFunctionObjArr.length+" Functions Selected"}
                                            </p>
                                            <span class="material-icons">keyboard_arrow_right</span>
                                        </div>
                                        {
                                            !isEmptyVariable(this.state.formErrors['functionList']) &&
                                            <span className="cm-error">{this.state.formErrors['functionList']}</span>
                                        }
                                    </div>
                                }   
                                {
                                    this.state.assignmentArea==="Process" &&
                                    <div className="addnew-modal-form-group">
                                        <p>Process</p>
                                        <div className="dummy-dropdown" tabIndex={0} onKeyPress={this.onKeyBtnPress}  
                                        onClick={this.handleSelectProcessDialogShow}>
                                            <p class="placeholder-text">
                                                {isEmptyArray(this.state.selectedProcessObjArr)
                                                ?processDropdownDefaultValue
                                                :this.state.selectedProcessObjArr.length === 1
                                                ?"1 Process Selected"
                                                :this.state.selectedProcessObjArr.length+" Processes Selected"}
                                            </p>
                                            <span class="material-icons">keyboard_arrow_right</span>
                                        </div>
                                        {
                                            !isEmptyVariable(this.state.formErrors['processList']) &&
                                            <span className="cm-error">{this.state.formErrors['processList']}</span>
                                        }
                                    </div>
                                }  
                                </div>
                            </div>
                            
                            <p className="schedule-assignment-heading">Timeline</p>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Start*</p>
                                        <DatePicker
                                            onChange={this.onStartDateChange}
                                            format={"DD/MM/YYYY"}
                                            value={!isEmptyVariable(this.state.startDate) ? moment(this.state.startDate) : ""}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['startDate']) &&
                                            <span class="cm-error">{this.state.formErrors['startDate']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>End*</p>
                                        <DatePicker
                                            onChange={this.onEndDateChange}
                                            format={"DD/MM/YYYY"}
                                            value={!isEmptyVariable(this.state.endDate) ? moment(this.state.endDate) : ""}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['endDate']) &&
                                            <span class="cm-error">{this.state.formErrors['endDate']}</span>
                                        }
                                    </div>
                                </div>
                            </div> 
                            
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Report*</p>
                                        <DatePicker
                                            onChange={this.onReportDateChange}
                                            format={"DD/MM/YYYY"}
                                            value={!isEmptyVariable(this.state.reportDate) ? moment(this.state.reportDate) : ""}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['reportDate']) &&
                                            <span class="cm-error">{this.state.formErrors['reportDate']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            
                            <p className="schedule-assignment-heading">Sample Period</p>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>From*</p>
                                        <DatePicker
                                            onChange={this.onPeriodFromDateChange}
                                            format={"DD/MM/YYYY"}
                                            value={!isEmptyVariable(this.state.periodFromDate) ? moment(this.state.periodFromDate) : ""}
                                            />
                                        {
                                            !isEmptyVariable(this.state.formErrors['periodFromDate']) &&
                                            <span class="cm-error">{this.state.formErrors['periodFromDate']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>To*</p>
                                        <DatePicker
                                            onChange={this.onPeriodToDateChange}
                                            format={"DD/MM/YYYY"}
                                            value={!isEmptyVariable(this.state.periodToDate) ? moment(this.state.periodToDate) : ""}
                                            />
                                        {
                                            !isEmptyVariable(this.state.formErrors['periodToDate']) &&
                                            <span class="cm-error">{this.state.formErrors['periodToDate']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        
                            <p className="schedule-assignment-heading">Other Details</p>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Risk</p>
                                        <RegularDropdown 
                                            placeholder={this.state.selectedRiskPlaceholder}
                                            dropdownArr={this.props.risks}
                                            labelParam="name"
                                            onDropDownItemClick={this.riskDropdownClick}
                                            defaultPlaceholderDropDown={defaultRiskPlaceholder}
                                        />
                                        {/* {
                                            !isEmptyVariable(this.state.formErrors['risk']) &&
                                            <span class="cm-error">{this.state.formErrors['risk']}</span>
                                        } */}
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Value Addition</p>
                                        <MultiSelectComponent 
                                            id="vaCheckbox"
                                            dataSource={this.props.valueAdditions}
                                            fields={fieldsVA}
                                            placeholder={this.state.selectedValuePlaceholder} 
                                            mode="CheckBox"
                                            closePopupOnSelect={false}
                                            allowFiltering={false}
                                            select={this.addSelectionVA}
                                            removed={this.removeSelectionVA}
                                            cssClass="clientsChecklist dialogcl"
                                            value={this.state.selectedVAIds}
                                            showDropDownIcon={true}
                                            showClearButton={false}
                                        >
                                            <Inject services={[CheckBoxSelection]} />
                                        </MultiSelectComponent>
                                        {/* {
                                            !isEmptyVariable(this.state.formErrors['selectedVA']) &&
                                            <span className="cm-error">{this.state.formErrors['selectedVA']}</span>
                                        } */}
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.handleCreateMaster} type="button">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>

                </Modal>
                <SelectFunctionDialog
                    showFunctionsDialog={this.state.showSelectFunctionDialog}
                    handleSelectFuncDialogClose={this.handleSelectFunctionDialogClose}
                    handleSelectFunctionDialogSaveClose={this.handleSelectFunctionDialogSaveClose}
                    selectedFunctionObjArr={this.state.selectedFunctionObjArr}
                    departmentId={this.state.selectedDepartmentId}
                    themeSettings = {this.props.themeSettings}
                />
                <SelectProcessDialog
                    showProcessesDialog={this.state.showSelectProcessDialog}
                    handleSelectProcessDialogClose={this.handleSelectProcessDialogClose}
                    handleSelectProcessDialogSaveClose={this.handleSelectProcessDialogSaveClose}
                    selectedProcessObjArr={this.state.selectedProcessObjArr}
                    departmentId={this.state.selectedDepartmentId}
                    themeSettings = {this.props.themeSettings}
                />
            </div>
        )
    }
}

export default CreateAnnualPlan;