import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import SpinnerLoader from 'react-loader-spinner';
import {DialogSaveButton,UploadSampleLayout} from '../../../common/Global/globalStyles.style';
import AlertDialog from '../../../common/AlertDialog';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();

class UploadDocumentSample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            submitClickedAtleastOnce:false,
            uploadFileArr:[
                {
                    fileName:"",
                    originalFileName:"",
                    fileId:"file_1",
                    isUploading:false,
                    // remark:"",
                    newlyAdded:true,
                    errors:{}
                }
            ],
            apiErr:"",
            uniqueIdCount:1,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showUploadReportDialog){
            this.setState({
                componentDidMountFlag:false,
                showLoader:false,
                submitClickedAtleastOnce:false,
                uploadFileArr:[
                    {
                        fileName:"",
                        originalFileName:"",
                        fileId:"file_1",
                        isUploading:false,
                        // remark:"",
                        newlyAdded:true,
                        errors:{}
                    }
                ],
                apiErr:"",
                uniqueIdCount:1,
                showAlertDialogInfo:false,
                alertDialogMessageInfo:""
            })
        }
    }

    // handleChange = (e) => {
    //     const { name, value } = e.target;
    //     let uploadFileArrTemp = this.state.uploadFileArr;
    //     uploadFileArrTemp[name].remark = value;

    //     if(this.state.submitClickedAtleastOnce){
    //         if(!isEmptyVariable(uploadFileArrTemp[name].remark) && 
    //         uploadFileArrTemp[name].remark.length > Constants.REMARKS_STRING_LENGTH){
    //             uploadFileArrTemp[name].errors['remark'] = "Remarks must be less then "+Constants.REMARKS_STRING_LENGTH+" characters";
    //         }else{
    //             uploadFileArrTemp[name].errors['remark'] = "";
    //         }
    //     }

    //     this.setState({
    //         uploadFileArr:uploadFileArrTemp,
    //     })
    // }
    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    fileValidation(id){
        var fileInput = document.getElementById(id);
        var filePath = fileInput.value;

        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.xlsx|\.xls|\.docx|\.doc|\.pdf|\.zip|\.pptx|\.ppt)$/i;

        if(!allowedExtensions.exec(filePath)){
            return false;
        }else{
            return true;
        }
    }

    handleAddNewClick = () => {
        let uniqueIdCount = this.state.uniqueIdCount + 1;
        let newObj = {
            fileName:"",
            originalFileName:"",
            fileId:"file_"+uniqueIdCount,
            isUploading:false,
            // remark:"",
            newlyAdded:true,
            errors:{}
        }
        let uploadFileArrTemp = this.state.uploadFileArr;
        uploadFileArrTemp.push(newObj);

        this.setState({
            uploadFileArr:uploadFileArrTemp,
            uniqueIdCount:uniqueIdCount
        })
    }

    handleDeleteItemClick = (index) => {
        let uploadFileArrTemp = this.state.uploadFileArr;
        uploadFileArrTemp.splice(index,1);
        this.setState({
            uploadFileArr:uploadFileArrTemp,
        })
    }

    handleUploadReportDialogClose = () =>{
        this.props.handleUploadReportDialogClose(false);
    }
    
    uploadReportDocumentsApi = () => {
        this.setState({
            submitClickedAtleastOnce:true,
            showLoader:true,
        });
        
        let isError = false;
        let apiErrTemp = "";
        let uploadFileArrTemp = this.state.uploadFileArr;

        uploadFileArrTemp.map((item,index)=>{
            if(item.isUploading){
                isError = true;
                apiErrTemp = "Files are being uploaded. Please wait";
            }

            if(isEmptyVariable(item.fileName)){
                isError = true;
                uploadFileArrTemp[index].errors['uploadFileErr'] = "Please upload a file";
            }

            // if(!isEmptyVariable(item.remark)){
            //     if(item.remark > Constants.REMARKS_STRING_LENGTH){
            //         isError = true;
            //         uploadFileArrTemp[index].errors['remark'] = "Remarks must be less then "+Constants.REMARKS_STRING_LENGTH+" characters";
            //     }

            // }
        })

        if(isError){
            this.setState({
                uploadFileArr:uploadFileArrTemp,
                apiErr:apiErrTemp,
                showLoader:false
            });
        }else{
            //return uploadFileArr
            // this.props.returnUploadedArray(this.state.uploadFileArr);
            let tmpReportFileArr = [];
            this.state.uploadFileArr.forEach((item,index)=>{
                let fileObj = {
                    fileName:item.fileName,
                    name:item.originalFileName,
                    description:"",
                }
                tmpReportFileArr.push(fileObj);
            })
            let postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                assignmentId:this.props.assignmentId,
                tmpFileArr:JSON.stringify(tmpReportFileArr),
                // delTmpFileArr:JSON.stringify([]),
            }
            fetch(Constants.SaveReportUpload,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams(postParams)
                })
                .then(response => { return response.json(); } )
                .then(data =>
                {
                    if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                        localStorage.clear();
                        window.location="/";
                    }else if(data.responseCode === Constants.CODE_SUCCESS){
                        this.setState({
                            showLoader:false,
                        },() => {
                            this.props.handleUploadReportDialogClose(true);
                        });
                    }else{
                        this.setState({
                            showLoader:false,
        
                            showAlertDialogInfo:true,
                            alertDialogMessageInfo:data.responseMessage,
                        })
                    }
                });
        }
    }

    handleUploadReportFile = (index, e) => {
        e.preventDefault();

        if(!isEmptyVariable(e.target.files[0])){
            let uploadFileArrTemp = this.state.uploadFileArr;
            uploadFileArrTemp[index].isUploading=true;
            uploadFileArrTemp[index].originalFileName=e.target.files[0].name;

            this.setState({
                uploadFileArr:uploadFileArrTemp,
            })

            var sizeInMB    =   (e.target.files[0].size / (1024*1024)).toFixed(2);
            if(!this.fileValidation(uploadFileArrTemp[index].fileId))
            {
                uploadFileArrTemp[index].isUploading=false;
                uploadFileArrTemp[index].errors.uploadFileErr = "Please upload file having extensions docx, doc, ppt, pptx, xls, xlsx, zip, jpeg, jpg and png";
                this.setState({
                    uploadFileArr:uploadFileArrTemp,
                });
            }else if(sizeInMB > Constants.MAX_DOCUMENT_MASTER_FILE_SIZE){
                uploadFileArrTemp[index].isUploading=false;
                uploadFileArrTemp[index].errors.uploadFileErr = "Please upload file size less than "+Constants.VALID_PROFILE_IMAGE_SIZE+" MB";
                this.setState({
                    uploadFileArr:uploadFileArrTemp,
                });
            }
            else
            {
                const formData = new FormData();
                formData.append('documentSample',e.target.files[0]);
                formData.append('email',userDetails.email);
                formData.append('accessToken',userDetails.accessToken);

                let url = "";
                url = Constants.UploadReport;
                // if(this.props.isDraftOnward === true){
                //     if(this.props.type == "Annexure"){
                //     }else if(this.props.type == "Backup"){
                //         url = Constants.UploadDraftBackup;
                //     }
                // }else{
                //     if(this.props.type == "Annexure"){
                //         url = Constants.UploadAnnexure;
                //     }else if(this.props.type == "Backup"){
                //         url = Constants.UploadBackup;
                //     }
                // }

                fetch(url,
                {
                    method: "POST",
                    mode: 'cors',
                    body: formData
                })
                .then(response => { return response.json(); })
                .then(data => {
                    if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                        localStorage.clear();
                        window.location="/";
                    }else if(data.responseCode === Constants.CODE_SUCCESS){
                        uploadFileArrTemp[index].isUploading=false;
                        uploadFileArrTemp[index].fileName=data.fileName;
                        uploadFileArrTemp[index].errors={};
                        this.setState({
                            uploadFileArr:uploadFileArrTemp,
                        });
                    }else{
                        uploadFileArrTemp[index].isUploading=false;
                        uploadFileArrTemp[index].originalFileName="";
                        // uploadFileArrTemp[index].errors.uploadFileErr=data.responseMessage;
                        toast.error(data.responseMessage)
                    }
                });
            }
        }
    }

    render() {
        return(
            <Modal className="upload-doc-sample-dialog custom-dialog" 
            show={this.props.showUploadReportDialog} backdrop="static">
                <Modal.Header>
                    <h5>Upload Report</h5>
                    <button 
                        onClick={this.handleUploadReportDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div className="modal-loading-text">
                            <p
                            style={{
                                background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                            }}
                            >Loading....</p>
                        </div>
                    }
                    <div className="addnew-modal-form">
                    {
                        !isEmptyArray(this.state.uploadFileArr) &&
                        this.state.uploadFileArr.map((item,index) => {
                            return <div className="row custom-row">
                                    <div className="col-md-12 custom-col">
                                        <div className="addnew-modal-form-group  m-b-10">
                                            <p>Report Document*</p>
                                            <div className="flex-center-layout">
                                                <input type="text" placeholder="Select Report Document"
                                                    value={item.originalFileName}
                                                    disabled={true}
                                                />
                                                <UploadSampleLayout themeSettings={this.props.themeSettings}>
                                                    <input name="file" 
                                                        type="file"
                                                        id={item.fileId}
                                                        onChange={this.handleUploadReportFile.bind(this,index)}
                                                        disabled={item.isUploading}
                                                    />
                                                    <label disabled={item.isUploading} for={item.fileId}>Upload Report</label>
                                                </UploadSampleLayout>
                                                {
                                                    index !== 0 &&
                                                    <span className="material-icons delete-doc-row"
                                                    onClick={this.handleDeleteItemClick.bind(this,index)}>
                                                        delete
                                                    </span>
                                                }
                                            </div>
                                            {
                                                !isEmptyVariable(item.errors['uploadFileErr']) &&
                                                <span class="cm-error">{item.errors['uploadFileErr']}</span>
                                            }
                                            {
                                                item.isUploading &&
                                                <div className="flex-center-layout single-line m-t-5">
                                                    <SpinnerLoader style={{ marginLeft:5 }} 
                                                        type={Constants.LOADER_TYPE} color={Constants.LOADER_COLOR} height={20} width={30} />
                                                    <span class="loading-span" style={{ marginLeft:5 }}>Uploading document</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12 custom-col">
                                        <div className="addnew-modal-form-group m-b-0">
                                            <p>Remarks</p>
                                            <textarea type="text" placeholder="Enter Remarks"
                                            name={index} value={item.remark} onChange={this.handleChange}/>
                                            {
                                                !isEmptyVariable(item.errors['remark']) &&
                                                <span className="cm-error">{item.errors['remark']}</span>
                                            }
                                        </div>
                                    </div> */}
                                    {
                                        (index !== (this.state.uploadFileArr.length - 1)) &&
                                        <hr className="doc-upload-divider"/>
                                    }
                                    
                                </div>
                            })
                        }
                        <div className="row custom-row">
                            <div className="col-md-12 custom-col">
                                <div className="add-more-btn-layout" onClick={this.handleAddNewClick}>
                                    <span className="material-icons">add_circle</span>
                                    <p>Add New</p>
                                </div>
                            </div>
                        </div>
                        {
                            !isEmptyVariable(this.state.apiErr) &&
                            <span className="cm-error">{this.state.apiErr}</span>
                        }
                    </div>
                    
                    <div className="modal-close-save">
                        <div className="close-save-col">
                            <button onClick={this.handleUploadReportDialogClose} type="button" className="modal-close">Cancel</button>
                            <DialogSaveButton
                            themeSettings={this.props.themeSettings}
                            onClick={this.uploadReportDocumentsApi} type="button">Save</DialogSaveButton>
                        </div>
                    </div>
                    <AlertDialog 
                        showAlertDialog={this.state.showAlertDialogInfo}
                        handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                        type= {Constants.ALERT_TYPE_ALERT}
                        alertDialogMessage={this.state.alertDialogMessageInfo}
                        proceedBtnClick={this.handleAlertDialogCloseInfo}
                        proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                        themeSettings={this.props.themeSettings}
                    />
                </div>
            </Modal>
        );
    }
}

export default UploadDocumentSample;