import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../common/DropdownMenus/RegularDropdown';
import {DialogSaveButton,TreeNodeCheckBox} from '../../common/Global/globalStyles.style';
import AddCityDialog from '../../common/DropdownMenus/addCityDropdownDialog'
import CreatableSelect from "react-select/creatable";
import SelectGroupingDialog from './selectGrouping';

const userDetails  = getLocalStorageVariables();
const unitTypePlaceholder = "Select Unit Type";
const functionPlaceholder = "Select Function";
const cityPlaceholderText = "Select City";
const unitGroupingPlaceholderText = "Select Grouping";

const FunctionTree = (props) => {

    return <div className="dialog-tree-structure">
        <div className="tree-node checkboxparent" style={{marginLeft:(props.level*40)+"px"}}>
            <span 
                onClick={()=>props.onClickNode(props.functionObj)}
                class="material-icons" style={{opacity:isEmptyArray(props.functionObj.children)?"0":"1"}}>
                {props.functionObj.isShowingChildren?"keyboard_arrow_down":"keyboard_arrow_right"}
            </span>
            <TreeNodeCheckBox
            themeSettings={props.themeSettings}>
                <input 
                    type="checkbox"
                    name={props.functionObj.functionId}
                    id={props.functionObj.functionId}
                    onChange={props.handleSelection}
                    checked={props.functionObj.isSelected?true:false}
                />
                <label for={props.functionObj.functionId} 
                style={{marginLeft:6}}>{props.functionObj.functionName}</label>
            </TreeNodeCheckBox>
            {/* <p for={props.functionObj.functionId}>{props.functionObj.functionName}</p> */}
        </div>

        {
            //Exit Condition
            props.functionObj.isShowingChildren &&
            props.getChildNodes(props.functionObj).map((child, idx)=>{
                let levelTemp = props.level + 1;
                return <FunctionTree 
                    functionObj = {child} 
                    getChildNodes = {props.getChildNodes}
                    level={levelTemp}
                    handleSelection={props.handleSelection}
                    onClickNode={props.onClickNode}
                    themeSettings={props.themeSettings}
                />
            })
        }
    </div>
}

const epicCustomStyles = {
	placeholder: (provided, state) => ({
		...provided,
		fontSize: 13,
        color:"#ccc"
	}),
	option: (provided, state) => ({
		...provided,
		fontSize: 13,
        background:state.isFocused?"#f8f9fa":"transparent",
        ":hover":{
            background:"#f8f9fa"
        }
	}),
	singleValue: (provided, state) => ({
		...provided,
		fontSize: 13,
	}),
	input: (provided, state) => ({
		...provided,
		fontSize: 14,
	}),
	control: (provided, state) => ({
		...provided,
		// border: "1px solid transparent",
		minHeight: 34,
		// ":hover": {
		// 	border: "1px solid red",
		// },
	}),
    menuList: (provided, state) => ({
		...provided,
		maxHeight:300,
        paddingTop:0,
        paddingBottom:0,
	}),
    menu:(provided, state)=>({
        ...provided,
        marginTop:2
    })
};

class AddUnitDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,

            unitName:"",
            unitShortName:"",
            address1:"",
            address2:"",
            city:"",
            pinCode:"",
            state:"",
            country:"",
            unitType:unitTypePlaceholder,
            unitTypeShortName:"",
            tagsMasterDD:[],
            tagsSelectedDD:[],
            formErrors:{},
            functionFormErr:{},

            showAddCityDialog: false,
            selectedGroupingObjArr: []

        }
        this.textInput = React.createRef();
        this.functionMapLocal = {};
        this.selectedFunctionObjArr = [];
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAddUnitDialog){

            this.functionMapLocal = {};
            this.functionMapLocal = JSON.parse(JSON.stringify(this.props.functionsArr));

            let tagsMasterDD= this.props.functionsArr.map((item)=>({value:item.function,label:item.function}));
            
            if(this.props.isEdit){
                //get the function ids and then change the function map
                //add isSelected and saveValue flags
                //functionList is in the format functionName:-:12,functionName:-:53
                //Retrieve the function id from above stirng and check the boxes
                //Also Open the parent dropdown

                var funcWithIdArr = [];
                if(!isEmptyVariable(this.props.editUnitItem.functionList)){
                    funcWithIdArr = this.props.editUnitItem.functionList.split(",");
                }

                let tagsSelectedDD = funcWithIdArr.map(funcItem => {
                    return {
                        value:funcItem,
                        label:funcItem
                    }
                })

                this.setState({
                    formErrors:{},
                    unitName:this.props.editUnitItem.unitName,
                    unitShortName:this.props.editUnitItem.unitShortName,
                    address1:this.props.editUnitItem.address1,
                    address2:this.props.editUnitItem.address2,
                    city:this.props.editUnitItem.city,
                    pinCode:this.props.editUnitItem.pinCode,
                    state:this.props.editUnitItem.state,
                    country:this.props.editUnitItem.country,
                    unitType:this.props.editUnitItem.unitTypeName,
                    unitTypeShortName:this.props.editUnitItem.unitType,
                    tagsSelectedDD:tagsSelectedDD,
                    tagsMasterDD:tagsMasterDD,
                    showAddCityDialog: false,
                    selectedGroupingObjArr: this.props.editUnitItem.selectedGroupingList
                },()=>{
                    this.textInput.current.focus();
                })
            }else{
                this.setState({
                    formErrors:{},
                    unitName:"",
                    unitShortName:"",
                    address1:"",
                    address2:"",
                    city:"",
                    pinCode:"",
                    state:"",
                    country:"",
                    functionList:functionPlaceholder,
                    unitType:unitTypePlaceholder,
                    showAddCityDialog: false,
                    tagsMasterDD:tagsMasterDD,
                    tagsSelectedDD:[],
                    isEdit:false,
                    selectedGroupingObjArr: []
                },()=>{
                    this.textInput.current.focus();
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleTagChange = (newValueArr, actionMeta) => {
		this.setState({
            tagsSelectedDD: newValueArr,
        });
	};

    typeDropdownClick = (item) => {
        this.setState({
            unitType:item.name,
            unitTypeShortName:item.shortName
        })
    }
    
    onKeyBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.handleSelectFuncDialogShow();
        }
    }

    handleSearchCityDialogClose = (selectedItem) => {

        if(!isEmptyVariable(selectedItem)){
            this.setState({
                city:selectedItem.city,
                state:selectedItem.state,
                country:selectedItem.country,
                showAddCityDialog:false,
            })
        }
        else{
            this.setState({
                showAddCityDialog: false,
            });
        }
	};

	showAddCityDialog = () => {
        if(!isEmptyVariable(this.state.city)){
            this.setState({
                showAddCityDialog: true,
                isEdit:true,
    
            });
        }
        else{
            this.setState({
                showAddCityDialog: true,
    
            });
        }
		
	};

    handleSelectGroupingDialogShow = () => {
        this.setState({
            showGroupingDialog: true
        })
    }

    handleGroupingTreeDialogClose = () => {
        this.setState({
            showGroupingDialog: false,
        })
    }

    handleSelectGroupingDialogSaveClose = (selectedGroupingObjArr) => {
        this.setState({
            showGroupingDialog: false,
            selectedGroupingObjArr: selectedGroupingObjArr
        })
    }

    /************************API CALLS **************************/
    addEditUnit = () => {
        this.setState({
            showLoader:true,
        });

        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.unitName.trim())){
            isError = true;
            FormErrorsTemp['unitName'] = "Please enter unit name";
        }

        if(isEmptyVariable(this.state.unitShortName.trim())){
            isError = true;
            FormErrorsTemp['unitShortName'] = "Please enter unit short name";
        }

        // if(isEmptyArray(this.state.tagsSelectedDD)){
        //     isError = true;
        //     FormErrorsTemp['functionList'] = "Please select function";
        // }

        if(isEmptyVariable(this.state.unitType) || this.state.unitType === unitTypePlaceholder){
            isError = true;
            FormErrorsTemp['unitType'] = "Please select unit type";
        }

        if(isEmptyVariable(this.state.address1.trim())){
            isError = true;
            FormErrorsTemp['address1'] = "Please enter address1";
        }

        if(isEmptyVariable(this.state.city)){
            isError = true;
            FormErrorsTemp['city'] = "Please enter city";
        }

        if(isEmptyVariable(this.state.pinCode.trim())){
            isError = true;
            FormErrorsTemp['pinCode'] = "Please enter pin code";
        }

        if(!isEmptyVariable(this.state.pinCode)){
            var regexp = new RegExp(/^(?=.*[0-9])[ 0-9]+$/);
            if(!regexp.test(this.state.pinCode)) {
                isError = true;
                FormErrorsTemp['pinCode'] = "Please enter valid pin code";
            }
        }

        if(isEmptyVariable(this.state.state)){
            isError = true;
            FormErrorsTemp['state'] = "Please enter state";
        }

        if(isEmptyVariable(this.state.country)){
            isError = true;
            FormErrorsTemp['country'] = "Please enter country";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

            setTimeout(function(){
                this.setState({
                    formErrors:{},
                });
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else{
            let url = Constants.CreateUnit
            if(this.props.isEdit){
                url = Constants.EditUnit
            }
            let functionIds = [];
            this.state.tagsSelectedDD.map(item => {
                functionIds.push(item.value);
            })
            
            let postParam = {
                unitName:this.state.unitName,
                unitShortName:this.state.unitShortName,
                address1:this.state.address1,
                address2:this.state.address2,
                city:this.state.city,
                pinCode:this.state.pinCode,
                state:this.state.state,
                country:this.state.country,
                unitType:this.state.unitTypeShortName,
            }
            if(!isEmptyArray(this.state.selectedGroupingObjArr)){
                let unitGroupingIdList = [];
                this.state.selectedGroupingObjArr.map(item=>{
                    unitGroupingIdList.push({"unitGroupId":item?.unitGroupId,"rootUnitGroupId":item.rootUnitGroupId})
                })
                postParam.unitGroupingIdList = unitGroupingIdList
            }
            if(this.props.isEdit){
                postParam.unitId = this.props.editUnitItem.unitId
            }else{
                //TODO hardcoded value must be removed
                postParam.companyId = this.props.companyId
                // postParam.companyId = 19
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    data:JSON.stringify(postParam),
                    // functions:JSON.stringify(functionIds)
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                    },()=>{
                        this.props.handleAddUnitDialogClose(true)
                    });
                }else{
                    
                    // FormErrorsTemp['responseMessage'] = data.responseMessage;
                    this.setState({
                        // formErrors:FormErrorsTemp,
                        showLoader:false
                    });

                    // setTimeout(function(){
                    //     this.setState({
                    //         formErrors:{},
                    //     });
                    // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                }
            });
        }
    }

    render() {
        return(
            <div>
                <Modal className="add-unit-dialog custom-dialog" 
                show={this.props.showAddUnitDialog} backdrop="static">
                    <Modal.Header>
                        <h5>{this.props.isEdit?"Edit Unit":"Add New Unit"}</h5>
                        <button 
                            onClick={this.props.handleAddUnitDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div className="modal-loading-text">
                                <p
                                style={{
                                    background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                    color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                                }}
                                >Loading....</p>
                            </div>
                        }
                        <div className="addnew-modal-form">
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Unit Name*</p>
                                        <input 
                                            ref={this.textInput}
                                            type="text" 
                                            placeholder="Enter Unit Name" 
                                            name="unitName"
                                            onChange ={this.handleChange}
                                            value={this.state.unitName}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['unitName']) &&
                                            <span className="cm-error">{this.state.formErrors['unitName']}</span>
                                        }
                                        
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Unit Short Name*</p>
                                        <input 
                                            type="text" 
                                            placeholder="Enter Unit Short Name" 
                                            name="unitShortName"
                                            onChange ={this.handleChange}
                                            value={this.state.unitShortName}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['unitShortName']) &&
                                            <span className="cm-error">{this.state.formErrors['unitShortName']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <fieldset>
                                        <p>Unit Type*</p>
                                        <RegularDropdown 
                                            placeholder={this.state.unitType}
                                            dropdownArr={this.props.unitTypeArr}
                                            labelParam="name"
                                            onDropDownItemClick={this.typeDropdownClick}
                                            defaultPlaceholderDropDown={unitTypePlaceholder}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['unitType']) &&
                                            <span className="cm-error">{this.state.formErrors['unitType']}</span>
                                        }
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Unit Grouping</p>
                                        <div 
                                            className="dummy-dropdown" 
                                            tabIndex={0} 
                                            onClick={this.handleSelectGroupingDialogShow}>
                                                <p class="placeholder-text">
                                                    {isEmptyArray(this.state.selectedGroupingObjArr)
                                                    ?unitGroupingPlaceholderText
                                                    :this.state.selectedGroupingObjArr.length === 1
                                                    ?"1 Grouping Selected"
                                                    :this.state.selectedGroupingObjArr.length+" Grouping Selected"}
                                                </p>
                                                <span class="material-icons">keyboard_arrow_right</span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Functions*</p>
                                        <CreatableSelect
                                            isMulti
                                            placeholder="Add Functions"
                                            styles={epicCustomStyles}
                                            classNamePrefix="dialog-react-select"
                                            value={this.state.tagsSelectedDD}
                                            onChange={this.handleTagChange}
                                            options={this.state.tagsMasterDD}
                                            // menuIsOpen={true}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['functionList']) &&
                                            <span className="cm-error">{this.state.formErrors['functionList']}</span>
                                        }
                                    </div>
                                </div> */}
                            </div>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Address Line 1*</p>
                                        <textarea 
                                            type="text" 
                                            placeholder="Enter Address Line 1" 
                                            name="address1"
                                            onChange ={this.handleChange}
                                            value={this.state.address1}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['address1']) &&
                                            <span className="cm-error">{this.state.formErrors['address1']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Address Line 2</p>
                                        <textarea
                                            type="text" 
                                            placeholder="Enter Address Line 2" 
                                            name="address2"
                                            onChange ={this.handleChange}
                                            value={this.state.address2}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>City*</p>
                                        <div 
                                            className="dummy-dropdown" 
                                            tabIndex={0} 
                                            onClick={this.showAddCityDialog}>
                                                <p style={{color:'gray',fontSize:'12px',opacity:0.8}} class="placeholder-text">
                                                    {isEmptyVariable(this.state.city)
                                                    ?cityPlaceholderText
                                                    :this.state.city}
                                                </p>
                                                <span class="material-icons">keyboard_arrow_right</span>
                                        </div>
                                        {
                                            !isEmptyVariable(this.state.formErrors['city']) &&
                                            <span className="cm-error">{this.state.formErrors['city']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Pin Code*</p>
                                        <input
                                            type="text" 
                                            placeholder="Enter Pin Code" 
                                            name="pinCode"
                                            onChange ={this.handleChange}
                                            value={this.state.pinCode}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['pinCode']) &&
                                            <span className="cm-error">{this.state.formErrors['pinCode']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group input-dis">
                                        <p>State*</p>
                                        <input
                                            type="text" 
                                            placeholder="Enter State" 
                                            name="state"
                                            onChange ={this.handleChange}
                                            value={this.state.state}
                                            // disabled={!isEmptyVariable(this.state.state)?true:false}
                                            disabled={true}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['state']) &&
                                            <span className="cm-error">{this.state.formErrors['state']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group input-dis">
                                        <p>Country*</p>
                                        <input
                                            type="text" 
                                            placeholder="Enter Country" 
                                            name="country"
                                            onChange ={this.handleChange}
                                            value={this.state.country}
                                            // disabled={!isEmptyVariable(this.state.country)?true:false}
                                            disabled={true}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['country']) &&
                                            <span className="cm-error">{this.state.formErrors['country']}</span>
                                        }
                                    </div>
                                </div>
                                {/* {
                                    !isEmptyVariable(this.state.formErrors['responseMessage']) &&
                                    <span className="cm-error">{this.state.formErrors['responseMessage']}</span>
                                } */}
                            </div>
                        </div>
                        
                        <div className="modal-close-save">
                            <div className="close-save-col">
                                <button onClick={this.props.handleAddUnitDialogClose} type="button" className="modal-close">Cancel</button>
                                <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.addEditUnit} type="button" className="modal-save">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </Modal>
                <AddCityDialog
                    showAddCityDialog = {this.state.showAddCityDialog}
                    handleCityDialogClose = {this.handleSearchCityDialogClose}
                    themeSettings={this.props.themeSettings}
                    isEdit={this.state.isEdit}
                    selectedCity={this.state.city}
                />
                <SelectGroupingDialog
                    showGroupingDialog={this.state.showGroupingDialog}
                    handleGroupingDialogClose={this.handleGroupingTreeDialogClose}
                    handleSelectGroupingDialogSaveClose={this.handleSelectGroupingDialogSaveClose}
                    themeSettings={this.props.themeSettings}
                    isClient={"N"}
                    companyId={this.props.companyId}
                    companyType={this.props.companyType}
                    selectedGroupingObjArr={this.state.selectedGroupingObjArr}
                />
            </div>
        );
    }
}

export default AddUnitDialog;