import styled from 'styled-components';

export const ViewReportWrapper = styled.div`
    .flex-center-layout {
        display:flex;
        align-items:center;
    }

    .justify-content {
        justify-content:space-between;
    }

    .justify-content-center {
        justify-content:center;
    }
`;
export const PageMargin = styled.span`
    height:25px;
    display:block;
`;
export const PageLayout = styled.div`
    width:21cm; min-height:24.2cm;
    padding: 0cm 1.6cm;
    margin:auto;
    background:#c1c2c3;
    margin-bottom: 0px;
`;

export const HeaderLayout = styled.div`
    height:2.7cm;
`;

export const FooterLayout = styled.div`

`;

export const FirstPageLayout = styled(PageLayout)`
    
    p{
        margin-bottom: 0px;
        text-align: center;
    }
    
    .client-layout{
        max-width: 300px;
        margin:auto;
        padding-top:100px;
    }

    .company-layout{
        max-width: 300px;
        margin:auto;
        margin-top:200px;
        text-align:center;
    }

    .company-layout img{
        width:80px;
        height:auto;
        object-fit:cover;
    }
`;

export const SecondPageLayout = styled(PageLayout)`
    
    h6{
        margin-bottom: 0px;
        text-align: center;
        padding-top:0px;
        margin-bottom: 15px;
        text-decoration:underline;
    }

    table{
        margin-bottom: 0px;
        width: 100%;
    }
    table, th, td {
        border: 0.6px solid black;
      }

`;

export const ThirdPageLayout = styled(PageLayout)`
    
    h6{
        margin-bottom: 0px;
        text-align: center;
        padding-top:100px;
        margin-bottom: 15px;
        text-decoration:underline;
    }

    p{
        margin-bottom: 0px;
    }

    ul{
        margin-bottom:10px;
    }
`;

export const FourthPageLayout = styled(PageLayout)`
    
    h6{
        margin-bottom: 0px;
        text-align: center;
        padding-top:100px;
        margin-bottom: 15px;
        text-decoration:underline;
    }

    p{
        margin-bottom: 0px;
    }

    table{
        margin-bottom: 0px;
        width: 100%;
    }
    table, th, td {
        border: 0.6px solid black;
      }
`;

export const FifthPageLayout = styled(PageLayout)`
    
    h6{
        margin-bottom: 0px;
        text-align: center;
        padding-top:100px;
        margin-bottom: 15px;
        text-decoration:underline;
    }

    .observation-wrapper{
        margin-bottom:20px;
    }

    p{
        margin-bottom: 0px;
    }

    table{
        margin-bottom: 0px;
        width: 100%;
    }
    table, th, td {
        border: 0.6px solid black;
      }
`;
