import React, { PureComponent } from 'react';
import * as Constants from '../../../common/Global/constants';
import {PageSaveButton,FilterCheckBox,AlertInfo, AddNewButtonLayout} from '../../../common/Global/globalStyles.style';
import Sidebar from '../../../common/SidebarCollapse';
import Topbar from '../../../common/Topbar';
import MasterMenuTabs from "../Common/topmenu";
import {getLocalStorageVariables,getThemeSettingsVariables,getStatusSpan,
    GetTemplateTabMenu,isEmptyVariable, isJsonString, isEmptyArray} from '../../../common/Global/commonFunctions';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import * as TemplateConstants from "../../../common/Global/templateConstants";
import AddFieldDialog from '../Common/addFieldDialog';
import AddTemplateNamedialog from '../Common/addTemplateNamedialog'
import AlertDialog from '../../../common/AlertDialog';
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import DifferenceDialog from '../Common/differenceDialog'
import SelectAssignments from '../Common/selectAssignments'
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import { DatePicker } from 'antd';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { Spinner } from 'react-bootstrap';

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();
const addfieldTypePlaceholder = "Add Field";
const templateNamePlaceholder = "Template Name Here";
const templateAlertDialogMessage = "Any change in template may have impact on download report.";
const editDropdownObj = {icon: "edit",label: "Edit"};
const enableDropdownObj = {icon: "toggle_off",label: "Enable Field"};
const disableDropdownObj = {icon: "toggle_on",label: "Disable Field"};
const delDropdownObj = {icon: "delete",label: "Delete"};
const discardChangesObj = {icon: "backspace",label: "Discard Changes",}
const differenceDropdownObj = {icon: "compare_arrows",label: "Show Differences"};

let isDetails = false;
let isTemplateEdit = false;
let isTemplateAlertDialog =false;

export default class EditOverallTemplate extends PureComponent {
    constructor(props) {
        super(props);
        if(this.props.location.pathname.includes(Constants.URL_OVERALL_TEMPLATES_DETAILS)){
            isDetails = true;
            isTemplateEdit=false;
            isTemplateAlertDialog=false;
        }else{
            isDetails = false;
            isTemplateEdit=true;
            isTemplateAlertDialog=true;
        }
        this.state = {
            menus:{},
            componentDidMountFlag:false,
            templateId:this.props.match.params.id,
            datalist:[],
            templateFieldsMap:{},
            fieldUsedInTasks:false,
            editTemplateItem:{},
            editTemplateItem_prev:{},
            fieldUseCountMap:{},
            updateAssignmentIds:[],
            fieldNameArray:[],
            showCreateDialog:false,
            addfieldTypePlaceholder:addfieldTypePlaceholder,
            templateName:templateNamePlaceholder,
            
            showAddTemplateNameDialog:false,
            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            alertDialogMessage:"",
            
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",
            showSecondAlertDialog:false,
            secondAlertDialogMessage:"",
            secondAlertProceedBtnLabel:"",
            showDifferenceDialog:false,
            templateType:TemplateConstants.TEMPLATE_TYPE_OVERALL,
            showSelectAssignmentDialog:false,
            showTemplateAlertDialog:isTemplateAlertDialog,
            showTemplateInfoDialog:isTemplateEdit,
            companyDetials:{}
        }
    }

    componentDidMount(){
        this.initData();
    }

    onDragEnd = (result) => {
        const {destination, source} = result;
        //Outside the droppable
        if(!destination){
            return
        }
        //if draggable is dropped in same droppable and in same position
        if(destination.droppableId === source.droppableId &&
        destination.index === source.index){
            return
        }
        let newPosition = destination.index;
        let oldPosition = source.index;
        let datalist = this.state.datalist;
        let fieldObj = datalist[oldPosition];
        datalist.splice(oldPosition,1)//remove from old position
        datalist.splice(newPosition,0,fieldObj);//move the field Obj to new pos
        let datalistNew = datalist.map((item,idx)=>{
            let index = idx+1;
            if(index+"" !== item.orderPos+""){
                item.fieldStatus_new = item.fieldStatus_new==="Created"?"Created":"Updated";
            }
            item.orderPos = idx+1;
            return item;
        })
        this.setState({
            datalist:datalistNew
        })
    }
    handleAddNewFieldClick = () => {
        let fieldNameArray = this.state.datalist.map((item)=>item.fieldName.trim());
        this.setState({
            showCreateDialog:true,
            fieldNameArray:fieldNameArray
        });
    }
    handleEditTemplateNameDialog = () => {
        this.setState({
            showAddTemplateNameDialog:true,
            isEditTemplateName:true
        });
    }
    handleEditTemplateNameDialogClose = (reloadFlag,templateName) => {
        if(reloadFlag){
            this.setState({
                showAddTemplateNameDialog:false,
                isEditTemplateName:false,
                templateName:templateName
            });
        }else{
            this.setState({
                showAddTemplateNameDialog:false,
                isEditTemplateName:false,
            });
        }
    }
    handleFieldItemEditIconClick = (templateItem,index) => {
        let templateItem_prev = this.state.templateFieldsMap[templateItem.fieldId];
        let fieldUsedInTasks = this.isUsedInTasks(templateItem.fieldId);
        this.setState({
            showCreateDialog:true,
            editTemplateItem:templateItem,
            editTemplateItem_prev:templateItem_prev,
            fieldUsedInTasks:fieldUsedInTasks,
            isEdit:true,
            deleteReactivateId:index,       
        });
    }
    //1
    handleFieldItemDeleteIconClick = (fieldItem,index) => {
        this.setState({
            operationType:"Delete",
            alertDialogHeading:"Delete Field",
            proceedBtnLabel:"Delete",
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to delete field - "+fieldItem.fieldName+"?"
        });
    }
    //2
    handleRecordOperation = () => {
        if(this.state.operationType === "Delete"){
            this.handleDeleteFieldItemAlert();
        }else if(this.state.operationType === "Reactive"){
            this.handleReactivateFieldItem();
        }else if(this.state.operationType === "Discard"){
            this.discardAllTemplateChanges();
        }else if(this.state.operationType === "Discard Item Change"){
            this.discardItemChange();
        }else if(this.state.operationType === "Enable Field"){
            this.enableDisableRiskField("Y");
        }else if(this.state.operationType === "Disable Field"){
            this.enableDisableRiskField("N");
        }
    }
    //3
    handleDeleteFieldItemAlert = () =>{
        let item = this.state.datalist[this.state.deleteReactivateId]
        let isUsedInTasks = this.isUsedInTasks(item.fieldId);
        if(isUsedInTasks){
            //need to show one more dialog just to confirm the delete
            this.setState({
                showSecondAlertDialog:true,
                secondAlertDialogMessage:"There is a data stored for the field. Data will also get deleted with the field. Are you sure?",
                secondAlertProceedBtnLabel:"Ok",
            })
        }else{
            this.handleDeleteFieldItem();
        }
    }
    //4
    handleDeleteFieldItem = () => {
        let datalistTemp = this.state.datalist;
        let item = this.state.datalist[this.state.deleteReactivateId];
        //check the previous status, if it is just created then hard delete it
        if(item.fieldStatus === "Created"){
            datalistTemp.splice(this.state.deleteReactivateId,1);
        }else{
            item.fieldStatus_new = "Deleted";
            datalistTemp.splice(this.state.deleteReactivateId,1,item);
        }
        this.setState({
            datalist:datalistTemp,
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
            showSecondAlertDialog:false,
            secondAlertDialogMessage:"",
            secondAlertProceedBtnLabel:"",
        })
    }
    enableDisableRiskField = (flag) => {
        let datalistTemp = this.state.datalist;
        let item = this.state.datalist[this.state.deleteReactivateId];
        item.isRiskMasterEnabled = flag;
        item.fieldStatus_new = item.fieldStatus_new==="Created"?"Created":"Updated";
        datalistTemp.splice(this.state.deleteReactivateId,1,item);
        this.setState({
            datalist:datalistTemp,
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
        })
    }
    handleFieldItemReactivateIconClick = (fieldName,index) => {
        this.setState({
            operationType:"Reactive",
            alertDialogHeading:"Reactivate Field",
            proceedBtnLabel:"Reactivate",
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to reactivate field - "+fieldName+"?"
        })
    }
    handleFieldItemDiscardChangesIconClick = (templateItem,index) => {
        let templateItem_prev = {};
        try{
            templateItem_prev = JSON.parse(JSON.stringify(this.state.templateFieldsMap[templateItem.fieldId]));
        }catch(e){
            templateItem_prev = {};
        }
        this.setState({
            operationType:"Discard Item Change",
            alertDialogHeading:"Discard Change",
            proceedBtnLabel:"Discard",
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to discard changes to field - "+templateItem.fieldName+"?",
            editTemplateItem:templateItem,
            editTemplateItem_prev:templateItem_prev,
        })
    }
    discardItemChange = () => {
        let datalistTemp = this.state.datalist;
        datalistTemp.splice(this.state.deleteReactivateId,1,this.state.editTemplateItem_prev);
        this.setState({
            datalist:datalistTemp,
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
            editTemplateItem:{},
            editTemplateItem_prev:{}
        });
    }
    handleReactivateFieldItem = () =>{
        let datalistTemp = [];
        datalistTemp = this.state.datalist;
        let item = this.state.datalist[this.state.deleteReactivateId];
        item.fieldStatus_new = item.fieldStatus;
        datalistTemp.splice(this.state.deleteReactivateId,1,item);
        this.setState({
            datalist:datalistTemp,
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
        })
    }
    
    handleCreateDialogClose = (fieldObj) => {
        let datalistTemp = this.state.datalist;
        if(fieldObj){
            let taskFieldCount =this.state.taskFieldCount;
            if(this.state.isEdit){
                fieldObj.orderPos = datalistTemp[this.state.deleteReactivateId].orderPos;
                datalistTemp.splice(this.state.deleteReactivateId,1, fieldObj);
            }else{
                fieldObj.fieldId = ++taskFieldCount +"";
                fieldObj.orderPos = datalistTemp.length+1;
                datalistTemp.push(fieldObj);
            }
            this.setState({
                showCreateDialog:false,
                isEdit:false,
                datalist:datalistTemp,
                editTemplateItem:{},
                editTemplateItem_prev:{},
                fieldUsedInTasks:false,
                deleteReactivateId:"",
                taskFieldCount:taskFieldCount,
                fieldNameArray:[]
            });
            
        }else{
            this.setState({
                showCreateDialog:false,
                isEdit:false,
                editTemplateItem:{},
                editTemplateItem_prev:{},
                deleteReactivateId:"",
            });
        }
    }
    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:"",
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
        });
    }
    handleSecondAlertDialogClose = () =>{
        this.setState({
            showSecondAlertDialog:false,
            secondAlertDialogMessage:"",
            secondAlertProceedBtnLabel:"",
        });
    }
    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }
    onCancelClick = () => {
        this.props.history.push(Constants.URL_OVERALL_TEMPLATES);
    }
    isUsedInTasks = (fieldId) => {
        let fieldUsageItem = this.state.fieldUseCountMap[fieldId];
        
        if(isEmptyVariable(fieldUsageItem)){
            return false;
        }
        let count = 0;
        try{
            count = parseInt(fieldUsageItem.cnt);
        }catch(e){
            count = 0;
        }
        if(count > 0){
            return true;
        }
        
        return false;
    }
    onTableDropDownItemClick = (item,dropdownItem,index) =>{
        if(item.label === "Edit"){
            this.handleFieldItemEditIconClick(dropdownItem,index);
        }else if(item.label === "Delete"){
            this.handleFieldItemDeleteIconClick(dropdownItem,index);
        }else if(item.label === "Discard Changes" && dropdownItem.fieldStatus_new === "Deleted"){
            this.handleFieldItemReactivateIconClick(dropdownItem.fieldName,index);
        }else if(item.label === "Discard Changes" && dropdownItem.fieldStatus_new === "Updated"){
            this.handleFieldItemDiscardChangesIconClick(dropdownItem,index);
        }else if(item.label === "Show Differences"){
            this.setState({ showDifferenceDialog:true });
        }else if(item.label === "Enable Field"){
            this.enableDisableFieldAlert(dropdownItem,index);
        }else if(item.label === "Disable Field"){
            this.enableDisableFieldAlert(dropdownItem,index);
        }
    }
    enableDisableFieldAlert = (fieldItem,index) => {
        let message = "Are you sure you want to disable field - "+fieldItem.fieldName+"?";
        let label = "Disable";
        let operationType = "Disable Field";
        if(fieldItem.isRiskMasterEnabled === "N"){
            message = "Are you sure you want to enable field - "+fieldItem.fieldName+"?";
            label = "Enable";
            operationType = "Enable Field";
        }
        this.setState({
            operationType:operationType,
            alertDialogHeading:operationType,
            proceedBtnLabel:label,
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:message
        })
    }
    getFieldActionArray = (canBeDeleted, fieldStatus_new, isFromRiskMaster, isRiskMasterEnabled) => {
        let actionObj = {};
        let actionArr = [];
        let dropdownWidth = "140px";
        
        actionArr.push(editDropdownObj);
        if(canBeDeleted === "Y" && fieldStatus_new !== "Deleted"){
            actionArr.push(delDropdownObj);
        }else if(canBeDeleted === "Y" && fieldStatus_new === "Deleted"){
            dropdownWidth = "170px";
            actionArr.push(discardChangesObj);
        }
        if(isFromRiskMaster){
            if(isRiskMasterEnabled === "N"){
                actionArr.push(enableDropdownObj);
            }else{
                actionArr.push(disableDropdownObj);
            }
        }
        if(fieldStatus_new === "Updated"){
            dropdownWidth = "170px";
            actionArr.push(discardChangesObj);
            actionArr.push(differenceDropdownObj);
        }
        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };
        return actionObj;
    }
    handleDifferenceDialogClose = () =>{
        this.setState({
            showDifferenceDialog:false
        })
    }
    handleSelectAssignmentDialogClose = () => {
        this.setState({
            showSelectAssignmentDialog:false,
            showLoader:false
        })
    }
    returnSelectedAssignmentIds = (selectedIds) => {
        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            templateId:this.state.templateId,
            templateName:this.state.templateName,
            templateFields:JSON.stringify(this.state.datalist),
            templateFieldsMaxId:this.state.taskFieldCount,
            templateFieldsLeft:JSON.stringify([]),
            assignmentIds:JSON.stringify(selectedIds)
        }
        this.handleSaveTemplate(Constants.UpdateTemplate,postParams);
    }
    handleSaveTemplateValidation = () => {
        this.setState({
            showLoader:true,
        }); 
        let callApiNameChange  = this.state.templateName !== this.state.templateNameOld;
        //before we call update template we need to check if anything is changed
        let callapi = this.state.datalist.some(item => (item.fieldStatus_new === "Created" || 
        item.fieldStatus_new === "Deleted" || item.fieldStatus_new === "Updated"))
        if(callapi || callApiNameChange){
            this.checkTemplateVersionCreation();
        }else{
            this.props.history.push(Constants.URL_OVERALL_TEMPLATES);
        }
    }
    /************************API CALLS****************************/
    initData = () => {
        Promise.all([
			fetch(Constants.GetTemplates, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					templateType:this.state.templateType,
					templateId:this.state.templateId,
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
		])
        .then(([generalRes, menuRes]) => {
            return Promise.all([generalRes.json(), menuRes.json()]);
        })
        .then(([generalRes, menuRes]) => {
            let menusTemp = {};
            let datalist = [];
            let templateObj = {};
            let templateName = "";
            let templateId = "";
            let taskFieldCount = 0;
            let templateFieldsMap = {};
            let fieldUseCountMap = {};
            let updateAssignmentIds = [];
            let companyDetialsTemp={};
            if (generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (generalRes.responseCode === Constants.CODE_SUCCESS) {
                templateObj = generalRes.data.result[0] || {};
                if(!isEmptyVariable(templateObj.templateFieldsNew) && isJsonString(templateObj.templateFieldsNew)){
                    datalist = JSON.parse(templateObj.templateFieldsNew);
                }
                if(!isEmptyVariable(templateObj.templateFields) && isJsonString(templateObj.templateFields)){
                    let templateFields = JSON.parse(templateObj.templateFields);
                    templateFields.forEach(item=>{
                        templateFieldsMap[item.fieldId] = item;
                    })
                }
                
                templateName = templateObj.templateName;
                templateId = templateObj.templateId;
                taskFieldCount = templateObj.templateFieldsMaxId;
                let updateAssignmentIdsStr = templateObj.updateAssignmentIds;
                if(!isEmptyVariable(updateAssignmentIdsStr) && isJsonString(updateAssignmentIdsStr)){
                    updateAssignmentIds = JSON.parse(updateAssignmentIdsStr);
                }
                
                let fieldUseCount = generalRes.data.fieldUseCount;
                //lets create a field usage map
                !isEmptyArray(fieldUseCount) &&
                fieldUseCount.forEach(item=>{
                    fieldUseCountMap[item.fieldId+""] = item;
                })
            } else {
            }
            if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                menusTemp = menuRes.data.menus;
                companyDetialsTemp = menuRes.data.company
            } else {
            }
            
            let menusTemp2 = GetTemplateTabMenu(menusTemp);
            this.setState({
                componentDidMountFlag: true,
                showLoader: false,
                datalist: datalist,
                templateFieldsMap: templateFieldsMap,
                templateName:templateName,
                templateNameOld:templateName,
                templateId:templateId,
                taskFieldCount:taskFieldCount,
                menus: menusTemp2,
                fieldUseCountMap:fieldUseCountMap,
                updateAssignmentIds:updateAssignmentIds,
                companyDetials:companyDetialsTemp
            });
        });
    }
    checkTemplateVersionCreation = () => {
        fetch(Constants.CheckTemplateVersionCreation,{
            method:"POST",
            mode: "cors",
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                templateId:this.state.templateId,
                templateName:this.state.templateName,
                templateFields:JSON.stringify(this.state.datalist),
                templateFieldsMaxId:this.state.taskFieldCount,
                templateFieldsLeft:JSON.stringify([]),
                assignmentIds:JSON.stringify([])
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let isAssignmentListNeeded = data.isAssignmentListNeeded;
                if(isAssignmentListNeeded === "Y"){
                    this.checkOngoingAssignments();
                }else{
                    this.props.history.push(Constants.URL_OVERALL_TEMPLATES);
                }
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                    showLoader:false,
                });
            }
        });
    }
    checkOngoingAssignments = () => {
        fetch(Constants.getOngoingAssignments,{
            method:"POST",
            mode: "cors",
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                templateId:this.state.templateId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let assignments = data.data.assignments;
                if(isEmptyArray(assignments)){
                    let postParams = {
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        templateId:this.state.templateId,
                        templateName:this.state.templateName,
                        templateFields:JSON.stringify(this.state.datalist),
                        templateFieldsMaxId:this.state.taskFieldCount,
                        templateFieldsLeft:JSON.stringify([]),
                    }
                    this.handleSaveTemplate(Constants.UpdateTemplate,postParams);
                }else{
                    //show the list of ongoing assignments
                    this.setState({
                        assignments:assignments,
                        showSelectAssignmentDialog:true
                    })
                }
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                    showLoader:false,
                });
            }
        });
    }
    handleSaveTemplate = (url,postParams) => {
        fetch(url,{
            method:"POST",
            mode: "cors",
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.props.history.push(Constants.URL_OVERALL_TEMPLATES);
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                    showLoader:false,
                });
            }
        });
        
    }
    handleTemplateAlertDialogClose = () =>{
        this.setState({
            showTemplateAlertDialog:false
        })
    }
    handleTemplateInfoClose = () => {
        this.setState({
            showTemplateInfoDialog:false
        })
    }

    render () {
    let fields = {text:"value",value:"value"};
        let fieldsWithDesc = {text:"valueNdesc",value:"value"};
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                        <div className="dashboard-right-bg-collpase">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="m-t-10">
                                        <div className="row custom-row">
                                            <div className="col-md-4 custom-col"></div>
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                <div className="col-md-8 custom-col add-template">
                                                    {this.state.showTemplateInfoDialog &&
                                                    <AlertInfo themeSettings={themeSettings}>
                                                        <span className="material-icons info-icon" >info</span>
                                                        <p className="flex-center-layout just-content">
                                                            <p>
                                                                <strong>Important:</strong> {templateAlertDialogMessage}
                                                            </p>
                                                            <span className="material-icons close-icon" onClick={this.handleTemplateInfoClose}>close</span>
                                                        </p>
                                                    </AlertInfo>}
                                                    
                                                    <div>
                                                    {   
                                                        <div className="flex-center-layout just-content">
                                                            <p className="m-b-0"><strong>{this.state.templateName}</strong></p>
                                                            {
                                                                !isDetails &&
                                                                <span className="material-icons template-action-icons m-b-0"
                                                                    onClick={this.handleEditTemplateNameDialog}
                                                                    >
                                                                    edit
                                                                </span>
                                                            }
                                                        </div>
                                                    }
                                                    
                                                    {
                                                        <Droppable droppableId={"RightFields"}>
                                                            {(provided)=>(
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.droppableProps}
                                                                >
                                                                {
                                                                    this.state.datalist.map((item,index)=>{
                                                                        let actionObj = this.getFieldActionArray(item.canBeDeleted, item.fieldStatus_new, 
                                                                            item.isFromRiskMaster, item.isRiskMasterEnabled);
                                                                        let spanObj = getStatusSpan(item.fieldStatus_new,themeSettings);
                                                                        return <Draggable draggableId={item.fieldId+""} index={index} key={item.fieldId+""}>
                                                                                {(provided,snapshot)=>(
                                                                                    <div class="template-field-layout"
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    ref={provided.innerRef}
                                                                                    >
                                                                                        <div className="editor-card" style={{flexGrow:1}}>
                                                                                            <div className="task-header-layout">
                                                                                                <h6 className="editor-heading m-b-0 flex-center-layout" style={{marginBottom:10}}>
                                                                                                    {item.fieldName}{item.isMandatorySelected === "Y"?"*":""}
                                                                                                    {
                                                                                                        !isEmptyVariable(spanObj.spanText) &&
                                                                                                        <span
                                                                                                        style={spanObj.spanStyle}
                                                                                                        >{spanObj.spanText}</span>
                                                                                                    }
                                                                                                    {
                                                                                                        item.isRiskMasterEnabled === "N" &&
                                                                                                        <span
                                                                                                        style={{
                                                                                                            background:"#ddd",
                                                                                                            color:"#000"
                                                                                                        }}
                                                                                                        >Disabled</span>
                                                                                                    }
                                                                                                </h6>
                                                                                                {
                                                                                                    item.isNotReqSelected === "Y" &&
                                                                                                    <FilterCheckBox themeSettings={themeSettings}
                                                                                                        style={{marginLeft:0}}>
                                                                                                        <input 
                                                                                                            type="checkbox"
                                                                                                            name="isNotReqSelected"
                                                                                                            id="isNotReqSelected"
                                                                                                            disabled={true}
                                                                                                        />
                                                                                                        <label for="isNotReqSelected">Not Required</label>
                                                                                                    </FilterCheckBox>
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                item.fieldType === TemplateConstants.FIELD_TYPE_TEXT &&
                                                                                                <div class="row custom-row">
                                                                                                    <div class="col-md-12 custom-col">
                                                                                                        <div class="editor-layout template-fields-ph">
                                                                                                            <textarea placeholder={item.placeholderValue} disabled="true"/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                            item.fieldType === TemplateConstants.FIELD_TYPE_DATE &&
                                                                                            <DatePicker
                                                                                                // onChange={this.onStartDateChange}
                                                                                                format={"DD/MM/YYYY"}
                                                                                                // disabled={true}
                                                                                                placeholder={item.placeholderValue}
                                                                                            />

                                                                                        }
                                                                                        {
                                                                                            item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN &&
                                                                                            <RegularDropdown 
                                                                                                placeholder={item.placeholderValue}
                                                                                                dropdownArr={item.dropdownValues}
                                                                                                // labelParam="value"
                                                                                                labelParam={item.showDescription==="Y"?"valueNdesc":"value"}
                                                                                                onDropDownItemClick={()=>{}}
                                                                                                // isFocusRequired={true}
                                                                                                // defaultPlaceholderDropDown = {fieldTypePlaceholderText}
                                                                                            />

                                                                                        }
                                                                                        {
                                                                                            item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                                                            item.isFromRiskMaster &&
                                                                                            <MultiSelectComponent 
                                                                                                id={"multi"+index}
                                                                                                dataSource={this.state.businessRisk}
                                                                                                fields={item.showDescription === "Y"?fieldsWithDesc:fields}
                                                                                                placeholder={item.placeholderValue} 
                                                                                                mode="CheckBox"
                                                                                                closePopupOnSelect={false}
                                                                                                allowFiltering={false}
                                                                                                readOnly={true}
                                                                                                cssClass="clientsChecklist dialogcl"
                                                                                                showDropDownIcon={true}
                                                                                                showClearButton={false}
                                                                                                // select={()=>{}}
                                                                                                // removed={()=>{}}
                                                                                                // value={this.state.selectedRiskIdArr}
                                                                                            >
                                                                                                <Inject services={[CheckBoxSelection]} />
                                                                                            </MultiSelectComponent>

                                                                                        }
                                                                                        {
                                                                                            item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                                                            !item.isFromRiskMaster &&
                                                                                            <MultiSelectComponent 
                                                                                                id={"multi"+index}
                                                                                                dataSource={item.dropdownValues}
                                                                                                fields={item.showDescription === "Y"?fieldsWithDesc:fields}
                                                                                                placeholder={item.placeholderValue} 
                                                                                                mode="CheckBox"
                                                                                                closePopupOnSelect={false}
                                                                                                allowFiltering={false}
                                                                                                readOnly={true}
                                                                                                cssClass="clientsChecklist dialogcl"
                                                                                                showDropDownIcon={true}
                                                                                                showClearButton={false}
                                                                                                // select={()=>{}}
                                                                                                // removed={()=>{}}
                                                                                                // value={this.state.selectedRiskIdArr}
                                                                                            >
                                                                                                <Inject services={[CheckBoxSelection]} />
                                                                                            </MultiSelectComponent>
                                                                                        }
                                                                                        
                                                                                        {
                                                                                            item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                                                                                            <div className="flex-center-layout" style={{justifyContent:"center"}}>
                                                                                                <AddNewButtonLayout themeSettings={themeSettings}>
                                                                                                    <a href="javascript:void(0);">
                                                                                                        <span className="material-icons">add_circle</span>
                                                                                                        <p>Add Table</p>
                                                                                                    </a>
                                                                                                </AddNewButtonLayout>
                                                                                            </div>
                                                                                        }
                                                                                        </div>
                                                                                        {
                                                                                            !isDetails &&
                                                                                            <div>
                                                                                                <TableDropDown
                                                                                                    actionArr={actionObj.actionArr}
                                                                                                    onDropDownItemClick={this.onTableDropDownItemClick}
                                                                                                    dropDownId={item}
                                                                                                    dropdownWidth={actionObj.dropdownWidth}
                                                                                                    themeSettings={themeSettings}
                                                                                                    udf1 = {index}
                                                                                                    udf2 = {"Right"}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                                
                                                                            </Draggable>
                                                                    })
                                                                }
                                                                {provided.placeholder} 
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    }
                                                    {
                                                        !isDetails &&
                                                        <div className="add-more-btn-layout" onClick={this.handleAddNewFieldClick}>
                                                            <span className="material-icons">add_circle</span>
                                                            <p>Add Field</p>
                                                        </div>
                                                    }
                                                    </div>
                                                </div>
                                            </DragDropContext>
                                        </div>
                                        <div className="row custom-row">
                                            <div className="col-md-12 custom-col">
                                                <div className="common-close-save m-b-20">
                                                    <div className="common-close-save-col">
                                                        <button onClick={this.onCancelClick} type="button" className="common-close">Cancel</button>
                                                        
                                                        {
                                                            !isDetails &&
                                                            <PageSaveButton 
                                                            themeSettings = {themeSettings}
                                                            onClick={this.handleSaveTemplateValidation} type="button">{this.state.showLoader ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : 'Save'}</PageSaveButton>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <AddFieldDialog
                            isEdit={this.state.isEdit}
                            showCreateDialog = {this.state.showCreateDialog}
                            handleCreateDialogClose = {this.handleCreateDialogClose}
                            themeSettings={themeSettings}
                            editTemplateItem={this.state.editTemplateItem}
                            isUsedInTasks={this.state.fieldUsedInTasks}
                            editTemplateItem_prev={this.state.editTemplateItem_prev}
                            addfieldTypePlaceholder={this.state.addfieldTypePlaceholder}
                            addToPosition={"Right"}
                            fieldNameArray={this.state.fieldNameArray}
                            templateType = {this.state.templateType}
                        />
                        <AddTemplateNamedialog
                            isEdit={this.state.isEditTemplateName}
                            showAddTemplateNameDialog={this.state.showAddTemplateNameDialog}
                            handleAddEditDialogClose={this.handleEditTemplateNameDialogClose}
                            themeSettings={themeSettings}
                            templateName={this.state.templateName}
                        />
                        <AlertDialog 
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel }
                            themeSettings={themeSettings}
                        />
                        <AlertDialog 
                            showAlertDialog={this.state.showSecondAlertDialog}
                            handleAlertDialogClose={this.handleSecondAlertDialogClose}
                            type={Constants.ALERT_TYPE_WARNING}
                            alertDialogMessage={this.state.secondAlertDialogMessage}
                            proceedBtnClick={this.handleDeleteFieldItem}
                            proceedBtnLabel={this.state.secondAlertProceedBtnLabel }
                            themeSettings={themeSettings}
                        />
                        <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />
                        
                        <AlertDialog 
                            showAlertDialog={this.state.showTemplateAlertDialog}
                            handleAlertDialogClose={this.handleTemplateAlertDialogClose}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={templateAlertDialogMessage}
                            proceedBtnClick={this.handleTemplateAlertDialogClose}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />
                        <DifferenceDialog
                            showDifferenceDialog = {this.state.showDifferenceDialog}
                            handleDifferenceDialogClose = {this.handleDifferenceDialogClose}
                            themeSettings = {themeSettings}
                        />
                        <SelectAssignments
                            showSelectAssignmentDialog = {this.state.showSelectAssignmentDialog}
                            assignments = {this.state.assignments}
                            updateAssignmentIds = {this.state.updateAssignmentIds}
                            handleSelectAssignmentDialogClose = {this.handleSelectAssignmentDialogClose}
                            returnSelectedAssignmentIds = {this.returnSelectedAssignmentIds}
                            companyType = {this.state.companyDetials.companyType}
                            themeSettings = {themeSettings}
                        />
                        
                    </section>
                </main>
            </Router>
        )
    }
}
