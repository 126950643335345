import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import { matchPath,withRouter } from 'react-router-dom';
import {isEmptyVariable,getThemeSettingsVariables} from '../../../common/Global/commonFunctions';
import {TopTabsLayout} from '../../../common/Global/globalStyles.style';

const allPrivilege = "ALL";
const viewPrivilege = "VIEW";

let isProcess ='';
let isFunction ='';
let isDocument ='';
let isIndustry ='';
let isMatrix ='';
let isOther ='';
let isGeneral ='';
let themeColor = ``;

class MasterTopMenu extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
        
        isProcess = this.checkActivePath(["/processes"]);
        isFunction = this.checkActivePath("/functions");
        isDocument = this.checkActivePath("/documents");
        isIndustry = this.checkActivePath("/industries");
        isMatrix = this.checkActivePath("/risk");
        isOther = this.checkActivePath("/othermasters");
        isGeneral = this.checkActivePath("/generalmasters");
    }

    checkActivePath = (path) =>{
        const themeSettings = getThemeSettingsVariables();
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
       return matchPath(this.props.history.location.pathname, { path });

    }

    render(){
        let width = 12.5;
        if(!isEmptyVariable(this.props.masterMenus)){
            width = 12.5*Object.keys(this.props.masterMenus).length
        }
        return(
            <TopTabsLayout themeSettings={this.props.themeSettings}>
            
                <div className="question-tabs"  style={{marginRight:0}}>
                    <ul style={{width:width+"%"}}>
                        {
                            !isEmptyVariable(this.props.masterMenus) &&
                            Object.keys(this.props.masterMenus).map(key => {
                                return <React.Fragment key={key}>
                                    {
                                        key === Constants.SCREEN_PROCESS &&
                                        (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege) &&
                                        <li className={isProcess? "questiontab-active" : ""}>
                                            <a href={Constants.URL_PROCESSES}
                                                style={{color: !isProcess ? themeColor : '', fontWeight: !isProcess ? "bold" : "100px"}}>Process</a>
                                        </li>
                                    }
                                    {
                                        key === Constants.SCREEN_FUNCTION &&
                                        (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege) &&
                                        <li className={isFunction ? "questiontab-active" : ""}>
                                            <a href={Constants.URL_FUNCTIONS}
                                                style={{color: !isFunction ? themeColor : '', fontWeight: !isFunction ? "bold" : "100px"}}>Function</a>
                                        </li>
                                    }
                                    {
                                        key === Constants.SCREEN_INDUSTRY &&
                                        (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege) &&
                                        <li className={isIndustry ? "questiontab-active" : ""}>
                                            <a href={Constants.URL_INDUSTRIES}
                                                style={{color: !isIndustry ? themeColor : '', fontWeight: !isIndustry ? "bold" : "100px"}}>Industry</a>
                                        </li>
                                    }
                                    {
                                        key === Constants.SCREEN_DOCUMENT &&
                                        (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege) &&
                                        <li className={isDocument ? "questiontab-active" : ""}>
                                            <a href={Constants.URL_DOCUMENTS}
                                                style={{color: !isDocument ? themeColor : '', fontWeight: !isDocument ? "bold" : "100px"}}>Document</a>
                                        </li>
                                    }
                                    {
                                        key === Constants.SCREEN_RISK &&
                                        (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege) &&
                                        <li className={isMatrix ? "questiontab-active" : ""}>
                                            <a href={Constants.URL_RISK}
                                                style={{color: !isMatrix ? themeColor : '', fontWeight: !isMatrix ? "bold" : "100px"}}>Matrix</a>
                                        </li>
                                    }
                                    {
                                        key === Constants.SCREEN_OTHER &&
                                        (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege) &&
                                        <li className={isOther ? "questiontab-active" : ""}>
                                            <a href={Constants.URL_OTHERMASTERS}
                                                style={{color: !isOther ? themeColor : '', fontWeight: !isOther ? "bold" : "100px"}}>Other</a>
                                        </li>
                                    }
                                    {
                                        key === Constants.SCREEN_GENERAL &&
                                        (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege) &&
                                        <li className={isGeneral? "questiontab-active" : ""}>
                                            <a href={Constants.URL_GENERALMASTERS}
                                                style={{color: !isGeneral ? themeColor : '', fontWeight: !isGeneral ? "bold" : "100px"}}>General</a>
                                        </li>
                                    }
                                </React.Fragment>
                            })
                        }
                    </ul>
                </div>

                <div style={{opacity:this.props.hideInfo?"0":"1"}} className="infocard-showhide-col">
                    {/* <div className="infocard-icon-col">
                        <img src="/assets/images/topbar-info.svg" />
                    </div> */}
                </div>
            </TopTabsLayout>
        )
    }
}

export default withRouter(MasterTopMenu);