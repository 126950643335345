import React, { Component } from "react";
import * as Constants from "../../common/Global/constants";
import { SearchSpan } from "../../common/Global/globalStyles.style";
import Sidebar from "../../common/Sidebar";
import Topbar from "../../common/Topbar";
import MasterMenuTabs from "./Common/topmenu";
import FilterDropDown from "../../common/DropdownMenus/FilterDropdown";
// import AlertDialog from "../../common/AlertDialog";
import Pagination from "../../common/Global/pagination";
import {
	getLocalStorageVariables,
	getThemeSettingsVariables,
	isEmptyVariable,
	sortTable,
	isEmptyArray,
	truncateString,
	getStringWithSpaceAfterComma,
	GetOrderedMasterTabMenuExecutes
} from "../../common/Global/commonFunctions";
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const sortReferenceNo = "a.referenceNo";
const sortCompanyName = "c.companyName";
const sortStartDate = "a.startDate";
const sortEndDate = "a.endDate";
const sortReportDate = "a.reportDate";
const sortType = "description";
const sortStatus = "a.assignmentStatus";
const sortLocation = "unitCityList";
const sortunitAddressList = "unitAddressList";
const sortunitCityList ="unitCityList";
const sortunitNameCityList = "unitNameCityList";


const departmentDropdownDefaultValue = "Select Department";
const departmentFilterDropdownDefaultValue = "All";
const activeStatus = "Active";


class OtherMasters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			statusPlaceholder: "Active",
			resultSizePlaceholder: Constants.RESULT_SIZE ,
			sort: "",
			sortDir: "",
			company:{},

			departmentArr: [],
			selectedDepartmentName: departmentDropdownDefaultValue,
			selectedDepartmentId: "",

			status: activeStatus,
			resultSizeArr: Constants.RESULT_SIZE_DD_ARR,
		};
	}

	componentDidMount() {
		this.getInitData();
	}

	handleChangeSearch = (e) => {
		const { name, value } = e.target;

		this.setState({
			[name]: value,
		});
	};

	onSearchIconClick = () => {
		this.setState(
			{
				currentPageNo: 1,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
			this.setState(
				{
					currentPageNo: 1,
				},
				() => {
					this.getAssignmentList();
				}
			);
		}
	};

	resultSizeDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.resultSizeArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label ;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				resultSizeArr: tempArr,
				resultSize: item.label,
				resultSizePlaceholder: placeholder,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	deptFilterDropdownClick = (item, selectedIndex) => {
		let placeholder = "";
		let id = "";
		let filterDeptArr = this.state.filterDeptArr;

		for (const [i, value] of filterDeptArr.entries()) {
			if (i === selectedIndex) {
				filterDeptArr[i].isSelected = true;
				placeholder = value.name;
				id = value.departmentId;
			} else {
				filterDeptArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				selectedFilterDepartmentName: placeholder,
				selectedFilterDepartmentId: id,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	handleExecuteTaskList = (assignmentId) => {
		this.props.history.push(Constants.URL_EXECUTETASK + "/" + assignmentId);
	};
	/*******************API CALLS*******************/
	getInitData = () => {
		this.setState({
			showLoader: true,
		});

		Promise.all([
			fetch(Constants.GetExecuteAssignments, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					pageNo: this.state.currentPageNo,
					resultsize: this.state.resultSize,
                    activeFlag:"Y"
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
		])
			.then(([masterRes, menuRes]) => {
				return Promise.all([masterRes.json(), menuRes.json()]);
			})
			.then(([masterRes, menuRes]) => {
				let datalistTemp = [];
				let totalCountTemp = 0;
				let menusTemp = {};
				let deptTemp = [];
				let companyTemp = {};

				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					datalistTemp = masterRes.data.result;
					totalCountTemp = masterRes.data.count;
				} else {
				}
				if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
					deptTemp = menuRes.data.departments;
					companyTemp = menuRes.data.company;
					menusTemp = menuRes.data.menus;
				} else {
				}

				let menusTemp2 = GetOrderedMasterTabMenuExecutes(menusTemp);

				let selectedDepartmentId = "";
				let selectedDepartmentName = departmentDropdownDefaultValue;
				if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
					selectedDepartmentId = deptTemp[0].departmentId;
					selectedDepartmentName = deptTemp[0].name;
				}

				//Filter Dept array
				let filterDeptArr = [];
				let selectedFilterDepartmentId = "-100";
				let selectedFilterDepartmentName = "All";
				if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
				} else {
					let obj = {
						departmentId: "-100",
						name: departmentFilterDropdownDefaultValue,
						isSelected: true,
					};
					filterDeptArr = [...deptTemp];
					filterDeptArr.splice(0, 0, obj);
				}

				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					datalist: datalistTemp,
					totalCount: totalCountTemp,
					company:companyTemp,
					menus:menusTemp2,

					filterDeptArr: filterDeptArr,
					selectedFilterDepartmentId: selectedFilterDepartmentId,
					selectedFilterDepartmentName: selectedFilterDepartmentName,
				});
			});
	};

	getAssignmentList = () => {
		this.setState({
			showLoader: true,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			pageNo: this.state.currentPageNo,
			search: this.state.searchkey,
			resultsize: this.state.resultSize,
			// status:this.state.status,
			sort: this.state.sort,
			sortDir: this.state.sortDir,
            activeFlag:"Y"
		};

		if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue) {
			postParam.departmentId = this.state.selectedFilterDepartmentId;
		}

		fetch(Constants.GetExecuteAssignments, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						datalist: data.data.result,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						totalCount: 0,
						componentDidMountFlag: true,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};

	onChangePage = (page) => {
		// update state with new page of items
		if (page !== this.state.currentPageNo) {
			this.setState({
				showLoader: true,
			});

			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				pageNo: page,
				search: this.state.searchkey,
				resultsize: this.state.resultSize,
				// status:this.state.status,
				sort: this.state.sort,
				sortDir: this.state.sortDir,
                activeFlag:"Y"
			};

			if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== "All") {
				postParam.departmentId = this.state.selectedFilterDepartmentId;
			}

			fetch(Constants.GetExecuteAssignments, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState({
							showLoader: false,
							datalist: data.data.result,
							totalCount: data.data.count,
							apiSearchKey: this.state.searchkey,
							currentPageNo: page,
						});
					} else {
						this.setState({
							datalist: [],
							showLoader: false,
							apiSearchKey: this.state.searchkey,
						});
					}
				});
		}
	};

	/*******************API CALLS END HERE*******************/

	render() {
		return (
			<Router>
				<main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />

						<div className="dashboard-right-bg position-relative">
							<div className="dashboard-wrapper">
								<Topbar />
								<MasterMenuTabs masterExecuteMenus={this.state.menus} themeSettings={themeSettings}/>
								{this.state.showLoader && (
									<div
										className="loading-layout"
										style={{ background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)` }}
									>
										<div className="loading-text-layout">
											<p
												style={{
													color: `rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`,
												}}
											>
												Loading....
											</p>
										</div>
									</div>
								)}
								{
									// Need to check component did mount flag, other wise until api is called,
									// it shows no items found layout.
									this.state.componentDidMountFlag && (
										<div className="body-wrapper">
											<div className="search-addnew-bg">
												<div className="search-addnew">
													<div className="search-filter-layout">
														<FilterDropDown
															placeholder={this.state.resultSizePlaceholder}
															dropdownArr={this.state.resultSizeArr}
															onDropDownItemClick={this.resultSizeDropdownClick}
															themeSettings={themeSettings}
															mleft={0}
														/>
														<div className="search-col m-l-10">
															<input
																name="searchkey"
																type="text"
																onChange={this.handleChangeSearch}
																placeholder="Search"
																onKeyPress={this.onEnterBtnPress}
																value={this.state.searchkey}
															/>
															<SearchSpan themeSettings={themeSettings} onClick={this.onSearchIconClick} className="material-icons">
																{" "}
																search{" "}
															</SearchSpan>
														</div>

														{this.state.filterDeptArr.length > 0 && (
															<FilterDropDown
																placeholder={this.state.selectedFilterDepartmentName}
																dropdownArr={this.state.filterDeptArr}
																onDropDownItemClick={this.deptFilterDropdownClick}
																name={"name"}
																themeSettings={themeSettings}
																dropdownWidth={"120px"}
															/>
														)}
													</div>
												</div>
											</div>

											{!isEmptyArray(this.state.datalist) && (
												<div className="common-tab-bg">
													<div className="common-table" style={{ width: "100%" }}>
														<table className="table">
															<thead>
																<tr>
																	<th className="text-center" width={"5%"}>
																		No.
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortReferenceNo)}>
																		<div className="sort-header">
																			Ref No.
																			<span
																				className={
																					this.state.sort === sortReferenceNo ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	{
																		this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
																		<th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortunitNameCityList)}>
																			<div className="sort-header">Unit
																			<span className={(this.state.sort === sortunitNameCityList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
																			</div>
																		</th>
																	}
																	{
																		this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
																		<th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortCompanyName)}>
																			<div className="sort-header">Client
																			<span className={(this.state.sort === sortCompanyName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
																			</div>
																		</th>
																	}
																	 {
                                                                this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                           <th >
                                                                <div className="sort-header" onClick={this.sortTableLocal.bind(this,sortunitAddressList)}>Name
                                                                <span className={(this.state.sort === sortunitAddressList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
    
                                                            }
                                                             {
                                                                this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                           <th >
                                                                <div className="sort-header" onClick={this.sortTableLocal.bind(this,sortunitCityList)}>City
                                                                <span className={(this.state.sort === sortunitCityList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
    
                                                            }
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortType)}>
																		<div className="sort-header">
																			Type
																			<span
																				className={
																					this.state.sort === sortType
																						? "material-icons"
																						: "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortStartDate)}>
																		<div className="sort-header">
																			Start Date
																			<span
																				className={
																					this.state.sort === sortStartDate ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortEndDate)}>
																		<div className="sort-header">
																			End Date
																			<span
																				className={
																					this.state.sort === sortEndDate ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortReportDate)}>
																		<div className="sort-header">
																			Report Date
																			<span
																				className={
																					this.state.sort === sortReportDate ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortStatus)}>
																		<div className="sort-header">
																			Status
																			<span
																				className={
																					this.state.sort === sortStatus ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																</tr>
															</thead>
															<tbody>
																{!isEmptyArray(this.state.datalist) &&
																	this.state.datalist.map((item, idx) => {
																		// let actionObj = getDropdownActionArrayScheduled(item.allowEdit, item.allowReview,
																		//     item.status, item.currentReviewStatus, "Functions", item.isResourceAssigned,item.isReportResourceAssigned);

																		return (
																			<tr onClick={this.handleExecuteTaskList.bind(this, item.assignmentId)}>
																				<td className="text-center">
																					{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
																				</td>
																				<td>{item.referenceNo}</td>
																				{
																					this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
																					<td>{truncateString(String(getStringWithSpaceAfterComma(item.unitNameCityList)).split("(")[0],Constants.OTHERMASTERS_STRING_LENGTH)}</td>
																				}
																				{
																					this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
																					<td>{item.companyName}</td>
																				}
																				 {   this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && <td>
                                                                    {isEmptyVariable(item.unitAddressList)? "-": String(item.unitAddressList).split(":-:")[0]}
                                                                    </td>}
                                                                    {   this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && <td>
                                                                    {isEmptyVariable(item.unitCityList)? "-": item.unitCityList}
                                                                    </td>}
																				<td>{item.assignmentTypeName}</td>
																				<td>{moment(item.startDate).format("DD/MM/YYYY")}</td>
																				<td>{moment(item.endDate).format("DD/MM/YYYY")}</td>
																				<td>{isEmptyVariable(item.reportDate)?"": moment(item.reportDate).format("DD/MM/YYYY")}</td>
																				<td>{item.assignmentStatus}</td>
																			</tr>
																		);
																	})}
															</tbody>
														</table>
													</div>
												</div>
											)}
											{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
												<div class="no-items-layout">
													<div class="no-items-card">
														<h6>
															{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
														</h6>
													</div>
												</div>
											)}
											<div className="pagination-layout">
												<div className="row custom-row">
													<div className="col-md-9 custom-col">
														{this.state.totalCount > this.state.datalist.length && (
															<Pagination
																totalLength={this.state.totalCount}
																items={this.state.datalist}
																onChangePage={this.onChangePage}
																pageSize={this.state.resultSize}
																currentPageNo={this.state.currentPageNo}
																initialPage={this.state.currentPageNo}
																themeSettings={themeSettings}
															/>
														)}
													</div>
													<div className="col-md-3 custom-col">
														<p>{"Total Records: " + this.state.totalCount}</p>
													</div>
												</div>
											</div>
										</div>
									)
								}
							</div>
						</div>
					</section>
				</main>
			</Router>
		);
	}
}

export default OtherMasters;
