import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,DialogSaveButton,FilterCheckBox} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import UserManagementTopMenu from './topmenu';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import RegularDropdown from '../../common/DropdownMenus/RegularDropdown';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../common/AlertDialog';
import Pagination from '../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable, sortTable,truncateString,ifEmptyReturnEmptyStr,
    getThemeSettingsVariables,SplitJoinString, isEmptyArray,ifEmptyReturn} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import ReactSelectDropdown from '../../common/DropdownMenus/ReactSelectDropdownWithSearch';
import moment from "moment";

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();
const rolePlaceholder = "Select Type";
const reviewRolePlaceholder = "Select Role";
const designationPlaceholder = "Select Designation";
const departmentPlaceholder = "Select Department";
const salutationPlaceholderText = "Select";
const displayRoleNameUser = "User";

const sortFirstName = "firstName";
const sortEmail = "email";
// const sortPhone = "phoneNo";
const sortRole = "r1.role";
const sortReviewRole = "r.reviewRole";
const sortDesignation = "u.designation";
const sortDeptList = "v.departmentList";
const sortInactivateDate = "u.userStartDate";

class UsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            status:"Active",
            sort:sortFirstName,
            sortDir:"asc",

            rolesList:[],
            reviewRolesList:[],
            deptList:[],
            designationArr:[],
            selectedDesignation:designationPlaceholder,

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            alertDialogMessage:"",

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            statusArr:[
                {
                    label:"Active",
                    isSelected:true
                },
                {
                    label:"Inactive",
                    isSelected:false
                }
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,

            showCreateDialog:false,
            submitClickedAtleastOnce:false,
            isEdit:false,
            salutation:salutationPlaceholderText,
            firstName:"",
            middleName:"",
            lastName:"",
            email:"",
            phone:"",
            activateForMonth:"",
            selectedRole:rolePlaceholder,
            selectedRoleId:"",
            selectedReviewRole:reviewRolePlaceholder,
            selectedReviewRoleId:"",
            selectedDeptId:[],
            selectedUserId:"",
            formErrors:{},

            showDetailsDialog:false,
            selectedItem:"",
            selectedDesignationObj:{},
            isInactiveTemporaryUser:false,
            isDisplayExtendBox:false

        }
    }

    componentDidMount(){
        this.getInitialData();
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            if(name === "firstName"){
                FormErrorsTemp['firstName'] = isEmptyVariable(value)?"Please enter first name":"";
            }
            else if(name === "lastName"){
                FormErrorsTemp['lastName'] = isEmptyVariable(value)?"Please enter last name":"";
            }
            else if(name === "email"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['email'] = "Please enter email";
                }else{
                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if(!pattern.test(value)) {
                        FormErrorsTemp["email"] = "Please enter valid email";
                    }else{
                        FormErrorsTemp["email"] = "";
                    }
                }
            }
            else if(name === "phone"){
                var regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
                if(!isEmptyVariable(value) && !regexp.test(value)){
                    FormErrorsTemp['phone'] = "Please enter valid phone number";
                }else{
                    FormErrorsTemp['phone'] = "";
                }
            }
         
        }

        this.setState({ 
            [name]: value,
            formErrors:FormErrorsTemp
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getUsersList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getUsersList();
            })
        }
    }
    
    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    handleAlertDialogClose = () => {
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleCreateDialogClose = () =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedUserId:"",
            salutation:salutationPlaceholderText,
            firstName:"",
            lastName:"",
            middleName:"",
            email:"",
            phone:"",
            selectedRole:rolePlaceholder,
            selectedRoleId:"",
            activateForMonth:"",
            selectedReviewRole:reviewRolePlaceholder,
            selectedReviewRoleId:"",
            selectedDeptId:[],
        });
    }

    showCreateDialog = () => {
        this.setState({
            showCreateDialog:true,
            submitClickedAtleastOnce:false,
            isEdit:false,
            selectedUserId:"",
            salutation:salutationPlaceholderText,
            firstName:"",
            middleName:"",
            lastName:"",
            email:"",
            phone:"",
            selectedRole:rolePlaceholder,
            selectedRoleId:"",
            selectedReviewRole:reviewRolePlaceholder,
            selectedDesignation:designationPlaceholder,
            selectedReviewRoleId:"",
            selectedDeptId:[],
            formErrors:{},

            selectedDDObj:"",
            selectedDesignationObj:{}
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getUsersList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getUsersList();
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.userId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete user "+dropdownItem.firstName+" "+dropdownItem.lastName+"?"
            })
        }else if(item.label === "Reactivate"){
            if(dropdownItem.displayRoleName=="Temporary User")
            {
                let currentDate = new Date();
                let exprieDate = new Date(dropdownItem.lastInactiveDate)
                const tmpDisplayExtendBox= exprieDate > currentDate
                console.log("tmpDisplayExtendBox ::",tmpDisplayExtendBox)
                this.setState({
                    showExtendMonthDialog:true,
                    activateForMonth:"",
                    operationType:item.label,
                    alertDialogMessage:"Are you sure you want to delete user "+dropdownItem.firstName+" "+dropdownItem.lastName+"?",
                    activateUserId:dropdownItem.userId,
                    isDisplayExtendBox:tmpDisplayExtendBox

                })
            }
            else{
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.userId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate user "+dropdownItem.firstName+" "+dropdownItem.lastName+"?"
            })
        }
        }else if(item.label === "Edit"){
            var resDeptIDArr = [];

            if(!isEmptyVariable(dropdownItem.departmentList)){
                var deptWithIdArr = dropdownItem.departmentList.split(",");

                deptWithIdArr.map(deptItem => {
                    resDeptIDArr.push(parseInt(deptItem.split(":-:")[1]))
                })
            }
            //Selected DD Object for React-select Dropdown
            let selectedDDObj={};
            selectedDDObj['reviewRole']=!isEmptyVariable(dropdownItem.reviewRole)?dropdownItem.reviewRole:reviewRolePlaceholder
            selectedDDObj['reviewRoleId']=!isEmptyVariable(dropdownItem.reviewRoleId)?dropdownItem.reviewRoleId:""

            let selectedDesignationObj ={}
            Object.values(this.state.designationArr).map((item)=>{
                if(item.name === dropdownItem.designation)
                {
                    selectedDesignationObj['name']=!isEmptyVariable(item.name)?item.name:""
                    selectedDesignationObj['designationId']=!isEmptyVariable(item.designationId)?item.designationId:""

                }
            })
            
            this.setState({
                showCreateDialog:true,
                submitClickedAtleastOnce:false,
                isEdit:true,
                selectedUserId:dropdownItem.userId,
                salutation:dropdownItem.salutation,
                firstName:dropdownItem.firstName,
                middleName:!isEmptyVariable(dropdownItem.middleName)?dropdownItem.middleName:"",
                lastName:dropdownItem.lastName,
                activateForMonth:dropdownItem.activeForMonth==0?"":dropdownItem.activeForMonth,
                email:dropdownItem.email,
                phone:!isEmptyVariable(dropdownItem.phoneNo)?dropdownItem.phoneNo:"",
                selectedRole:dropdownItem.displayRoleName,
                selectedDesignation:dropdownItem.designation,
                selectedRoleId:dropdownItem.roleId,
                selectedReviewRole:!isEmptyVariable(dropdownItem.reviewRole)?dropdownItem.reviewRole:reviewRolePlaceholder,
                selectedReviewRoleId:!isEmptyVariable(dropdownItem.reviewRoleId)?dropdownItem.reviewRoleId:"",
                selectedDeptId:resDeptIDArr,
                formErrors:{},
                initalSelectionRole:dropdownItem.displayRoleName,
                selectedDDObj:selectedDDObj,
                selectedDesignationObj:selectedDesignationObj
            });
        }

        else if (item.label == "Extend Month")
        {
            let inactivationDate = new Date(dropdownItem.userStartDate);
            inactivationDate.setMonth(inactivationDate.getMonth() + dropdownItem.activeForMonth);
            inactivationDate.setDate(inactivationDate.getDate() + 1);

            this.setState({
                showExtendMonthDialog:true,
                activateForMonth:"",
                selectedUserId:dropdownItem.userId,
                operationType:item.label,
                expireDateOfUserAcc:inactivationDate.toLocaleDateString("en-IN")

                
            })
        }
    }

    salutionDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            if(isEmptyVariable(item.name) || item.name === salutationPlaceholderText){
                FormErrorsTemp['salutation'] = "Please select salutation";
            }else{
                FormErrorsTemp['salutation'] = "";
            }
        }

        this.setState({
            salutation:item.name,
            formErrors:FormErrorsTemp
        })
    }

    typeDropdownClick = (item) => {
        if(item.displayRoleName !== displayRoleNameUser){
            let FormErrorsTemp = this.state.formErrors;
            FormErrorsTemp['selectedReviewRole'] = "";
    
            this.setState({
                formErrors:FormErrorsTemp
            })
        }
        this.setState({
            selectedRole:item.displayRoleName,
            selectedRoleId:item.roleId,
            selectedReviewRole: item.displayRoleName=="Temporary User"?reviewRolePlaceholder:this.state.selectedReviewRole,
            selectedReviewRoleId:item.displayRoleName=="Temporary User"?"":this.state.selectedReviewRoleId,
            selectedDDObj: item.displayRoleName == "Temporary User"? "":this.state.selectedDDObj,
            
        })
    }

    designationDropdownClick = (item) => {
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['selectedDesignation'] = "";

        this.setState({
            selectedDesignation:item.name,
            formErrors:FormErrorsTemp
        })
    }

    // roleDropdownClick = (item) => {
    //     let FormErrorsTemp = this.state.formErrors;

    //     if(this.state.selectedRole === displayRoleNameUser){
    //         if(item.reviewRole === reviewRolePlaceholder){
    //             FormErrorsTemp['selectedReviewRole'] = "Please select a role";
    //         }else{
    //             FormErrorsTemp['selectedReviewRole'] = "";
    //         }
    //     }

    //     this.setState({
    //         selectedReviewRole:item.reviewRole,
    //         selectedReviewRoleId:item.reviewRoleId,
    //         formErrors:FormErrorsTemp
    //     })
    // }

    addSelection = (args) => {
        if(!isEmptyVariable(args.itemData.departmentId))
        {
            this.state.selectedDeptId.push(args.itemData.departmentId)
        }
        this.setState({
            selectedDeptId:this.state.selectedDeptId,
            clientslistErr:""
        })

    }


    removeSelection = (args) => {
        if(!isEmptyVariable(args.itemData.departmentId)){
            let idx = this.state.selectedDeptId.indexOf(args.itemData.departmentId);
            if(idx !== -1){
                this.state.selectedDeptId.splice(idx,1);
            }
        }
        this.setState({
            selectedDeptId:this.state.selectedDeptId
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getUsersList();
        })
    }

    handleReactSelectClose = (item) => {
        let FormErrorsTemp = this.state.formErrors;
        
        if(!isEmptyVariable(item)){
            if(this.state.selectedRole === displayRoleNameUser){
                if(isEmptyVariable(item.reviewRole) || item.reviewRole === reviewRolePlaceholder ){
                    FormErrorsTemp['selectedReviewRole'] = "Please select a role";
                }else{
                    FormErrorsTemp['selectedReviewRole'] = "";
                }
            }
            
            let selectedDDObj={};
            selectedDDObj['reviewRole']=!isEmptyVariable(item.reviewRole)?item.reviewRole:reviewRolePlaceholder
            selectedDDObj['reviewRoleId']=!isEmptyVariable(item.reviewRoleId)?item.reviewRoleId:""
        
            this.setState({
                selectedReviewRole:item.reviewRole,
                selectedReviewRoleId:item.reviewRoleId,
                selectedDDObj:selectedDDObj,
            })
        }else{
            this.setState({
                selectedReviewRole:reviewRolePlaceholder,
                selectedReviewRoleId:""
            })
        }


        this.setState({
            formErrors:FormErrorsTemp,
        })
    }

    /*******************API CALLS*******************/

    getInitialData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetUsers,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            }),
            fetch(Constants.GetUserMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([userListRes,masterRes]) => { 
            return Promise.all([userListRes.json(),masterRes.json()]) 
        })
        .then(([userListRes,masterRes]) => { 
            let userList = [];
            let userCount = 0;
            let rolesArr = [];
            let reviewRolesArr = [];
            let deptArr = [];
            let designationArr = [];

            if(userListRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                userListRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(userListRes.responseCode === Constants.CODE_SUCCESS){
                userList = userListRes.data.result;
                userCount = userListRes.data.count;
            }else{
                //TODO - show error message inside dialog if possible
            }

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                rolesArr = masterRes.data.roles;
                reviewRolesArr = masterRes.data.reviewRoles;
                deptArr = masterRes.data.departments;
                designationArr = masterRes.data.designations;
            }else{
                //TODO - show error message inside dialog if possible
            }

            // let tempRoleObj = {
            //     reviewRoleId:"",
            //     reviewRole:reviewRolePlaceholder
            // }

            // reviewRolesArr.splice(0,0,tempRoleObj);



            this.setState({
                datalist:userList,
                totalCount:userCount,
                rolesList:rolesArr,
                reviewRolesList:reviewRolesArr,
                deptList:deptArr,
                designationArr:designationArr,
                showLoader:false,
                componentDidMountFlag:true,
                isInactiveTemporaryUser:false,
            })

        });
    }

    getUsersList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetUsers,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetUsers,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    deleteActivateUser = () => {
        let url = "";
        let postParam = {};

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteUser;
            postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                deleteUserId:this.state.deleteReactivateId
            }
        }else if(this.state.operationType === "Reactivate"){

            url = Constants.ActivateUser;
            postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                activateUserId:this.state.deleteReactivateId
            }
        }

        this.setState({
            showLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                },() => {
                    this.getUsersList();
                });
            }else{
                this.setState({
                    showLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    handleCreateUser = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.salutation) || this.state.salutation === salutationPlaceholderText){
            isError = true;
            FormErrorsTemp['salutation'] = "Please select salutation";
        }

        if(isEmptyVariable(this.state.firstName)){
            isError = true;
            FormErrorsTemp['firstName'] = "Please enter first name";
        }

        if(isEmptyVariable(this.state.lastName)){
            isError = true;
            FormErrorsTemp['lastName'] = "Please enter last name";
        }

        if(isEmptyVariable(this.state.email)){
            isError = true;
            FormErrorsTemp['email'] = "Please enter email";
        }

        if(!isEmptyVariable(this.state.email))
        {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!pattern.test(this.state.email)) {
                isError = true;
                FormErrorsTemp["email"] = "Please enter valid email";
            }
        }

        if(!isEmptyVariable(this.state.phone)){
            var regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
            if(!regexp.test(this.state.phone)) {
                isError = true;
                FormErrorsTemp['phone'] = "Please enter valid phone number";
            }
        }

        if(this.state.selectedRole === rolePlaceholder){
            isError = true;
            FormErrorsTemp['selectedRole'] = "Please select a type";
        }

        if(this.state.selectedDesignation === designationPlaceholder){
            isError = true;
            FormErrorsTemp['selectedDesignation'] = "Please select a designation";
        }

        if(this.state.selectedRole === displayRoleNameUser){
            if(this.state.selectedReviewRole === reviewRolePlaceholder){
                isError = true;
                FormErrorsTemp['selectedReviewRole'] = "Please select a role";
            }
            if(isEmptyArray(this.state.selectedDeptId)){
                isError = true;
                FormErrorsTemp['selectedDept'] = "Please select a department";
            }
        }

        if(this.state.selectedRole == "Temporary User")
        {
            if(isEmptyVariable(this.state.activateForMonth))
            {
                isError = true;
                FormErrorsTemp['activateForMonth'] = "Please enter a Month"
            }
            else if(!(/^[0-9]+$/.test(Number(this.state.activateForMonth))))
                {
                    isError = true;
                    FormErrorsTemp['activateForMonth'] = "Month must be Number";

                }
           else if (Number(this.state.activateForMonth)< 0 ||  Number(this.state.activateForMonth>12))
                {
                    isError = true;
                    FormErrorsTemp['activateForMonth'] = "Month must be between 1 and 12";
                }
        }


        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let postParam = {};
            //Create dept array in the required format
            let postDeptArr = [];
            this.state.selectedDeptId.map(item=>{
                postDeptArr.push(
                    {
                        departmentId:item
                    }
                )
            })

            if(this.state.isEdit){
                url = Constants.UpdateUser;
                postParam = {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    userId:this.state.selectedUserId,
                    firstName:this.state.firstName,
                    salutation:this.state.salutation,
                    middleName:this.state.middleName,
                    lastName:this.state.lastName,
                    updateEmail:this.state.email,
                    phoneNo:this.state.phone,
                    roleId:this.state.selectedRoleId,
                    designation:this.state.selectedDesignation,
                    reviewRoleId:this.state.selectedReviewRoleId,
                    departments:JSON.stringify(postDeptArr),
                    activeForMonth: Number(this.state.activateForMonth),
                    
                }
            }else{
                url = Constants.RegisterUser;
                postParam = {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    firstName:this.state.firstName,
                    salutation:this.state.salutation,
                    middleName:this.state.middleName,
                    lastName:this.state.lastName,
                    registerEmail:this.state.email,
                    phoneNo:this.state.phone,
                    roleId:this.state.selectedRoleId,
                    reviewRoleId:this.state.selectedReviewRoleId,
                    designation:this.state.selectedDesignation,
                    departments:JSON.stringify(postDeptArr),
                    activeForMonth: this.state.activateForMonth,
                    createInactiveRemark:this.state.isInactiveTemporaryUser?"Y":"N"
                }
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        showCreateDialog:false,
                        isEdit:false,
                        selectedUserId:"",
                        firstName:"",
                        lastName:"",
                        email:"",
                        phone:"",
                        activateForMonth:"",
                        selectedRole:rolePlaceholder,
                        selectedRoleId:"",
                        selectedReviewRole:reviewRolePlaceholder,
                        selectedReviewRoleId:"",
                        selectedDeptId:[],
                    },() => {
                        this.getUsersList();
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        showCreateDialog:false,
                        isEdit:false,
                        selectedUserId:"",
                        firstName:"",
                        lastName:"",
                        email:"",
                        phone:"",
                        selectedRole:rolePlaceholder,
                        selectedRoleId:"",
                        activateForMonth:"",
                        selectedReviewRole:reviewRolePlaceholder,
                        selectedReviewRoleId:"",
                        selectedDept:departmentPlaceholder,
                        selectedDeptId:[],

                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                        isInactiveTemporaryUser:false,
                    })
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    handleExtendMonthClose = () =>{
        this.setState({
            showExtendMonthDialog:false,
            activateForMonth:"",
            formErrors:{}
            
        })
    }

    handleExtendMonth = () =>{

        let isError = false;
        let FormErrorsTemp = {};

        if(!this.state.isDisplayExtendBox){
            if(isEmptyVariable(this.state.activateForMonth))
            {
                isError = true;
                FormErrorsTemp['activateForMonth'] = "Please enter a Month"
            }
            else if(!(/^[0-9]+$/.test(Number(this.state.activateForMonth))))
            {
                isError = true;
                FormErrorsTemp['activateForMonth'] = "Month must be Number";

            }
           else if (Number(this.state.activateForMonth)< 0 ||  Number(this.state.activateForMonth>12))
            {
                isError = true;
                FormErrorsTemp['activateForMonth'] = "Month must be between 1 and 12";
            }

        }
        
        if(isError){
                this.setState({
                    formErrors:FormErrorsTemp,
        });
            }
            else { 
                this.setState({
                    showLoader:true
                })
                let url ="";
                let postParam = {}
                if(this.state.operationType=="Reactivate")
                {
                    url = Constants.ActivateUser;
                    postParam = {
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        activateUserId:this.state.activateUserId,
                        activeForMonth:!this.state.isDisplayExtendBox?this.state.activateForMonth:0

                    }
                } 
                else {
                    url = Constants.ExtendMonth;
                    postParam ={
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        userId:this.state.selectedUserId,
                        activeForMonth:this.state.activateForMonth

                    };

                } 
                    fetch(url,
                    {
                        method: "POST",
                        mode:'cors',
                        body: new URLSearchParams(postParam)
                    } ).then(response => { return response.json(); } ).then((data)=>{

                        if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                            data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                            localStorage.clear();
                            window.location="/";
                        }
                        else{
                            this.setState({
                                showExtendMonthDialog:false,
                                showLoader:false
                            },() => {
                                this.getUsersList();
                            })
                        }
                    }) 
            }
        
    }
    handleTemporaryUserCheckBoxClick =(e) =>{
        this.setState({
            isInactiveTemporaryUser:e.target.checked,
            activateForMonth: e.target.checked?0:"",
            formErrors:{}
        })
    }
     TemporaryUserDisableCheck = () =>{
        if(!this.state.isEdit && !this.state.isInactiveTemporaryUser)
        {
            return false;
        }
       
        else if(this.state.isEdit && !this.state.isInactiveTemporaryUser)
        {
          return this.state.initalSelectionRole!=="Temporary User"
          && this.state.selectedRole=="Temporary User"? false: true;

        }
        else if(this.state.isEdit && this.state.isInactiveTemporaryUser)
        {
            return true;
        }
        else if (!this.state.isEdit && this.state.isInactiveTemporaryUser)
        {
            return true;
        }
        else{
            return false;
        }
        
    }

    render() {
        let fields = {text:"name",value:"departmentId"}
        const actionArr = [
            {
                icon:"edit",
                label:"Edit"
            },
            {
                icon:"delete",
                label:"Delete"
            },
        ]

        const tempUserActionArr = [
            {
                icon:"edit",
                label:"Edit"
            },
            {
                icon:"add",
                label:"Extend Month"
            },
            {
                icon:"delete",
                label:"Delete"
            },
        ]

        const actionArrReactivate = [
            {
                icon:"check_circle",
                label:"Reactivate"
            },
        ]
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.showLoader &&
                                    <div className="loading-layout"
                                    style={{background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`}}>
                                        <div className="loading-text-layout">
                                            <p
                                            style={{color:`rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`}}
                                            >Loading....</p>
                                        </div>
                                    </div>
                                }
                                <UserManagementTopMenu 
                                    themeSettings = {themeSettings}
                                />
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings = {themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                            className="material-icons"
                                                            themeSettings = {themeSettings}
                                                            onClick = {this.onSearchIconClick}>search</SearchSpan>
                                                    </div>

                                                    
                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        themeSettings = {themeSettings}
                                                    />
                                                </div>

                                                <AddNewButtonLayout
                                                    themeSettings = {themeSettings}
                                                >
                                                    <a onClick={this.showCreateDialog} href="javascript:void(0);">
                                                        <span className="material-icons">add_circle</span>
                                                        <p style={{fontSize: "10.5px"}}>Add New</p>
                                                    </a>
                                                </AddNewButtonLayout>
                                            </div>
                                        </div>

                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th className="c-pointer" width={"15%"} onClick={this.sortTableLocal.bind(this,sortFirstName)}>
                                                                <div className="sort-header">Name
                                                                <span className={(this.state.sort === sortFirstName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" width={"20%"} onClick={this.sortTableLocal.bind(this,sortEmail)}>
                                                                <div className="sort-header">Email
                                                                <span className={(this.state.sort === sortEmail)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {/* <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortPhone)}>
                                                                <div className="sort-header">Phone
                                                                <span className={(this.state.sort === sortPhone)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th> */}
                                                            <th className="c-pointer" width={"12%"} onClick={this.sortTableLocal.bind(this,sortRole)}>
                                                                <div className="sort-header">Role
                                                                <span className={(this.state.sort === sortRole)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" width={"13%"} onClick={this.sortTableLocal.bind(this,sortReviewRole)}>
                                                                <div className="sort-header">Review Role
                                                                <span className={(this.state.sort === sortReviewRole)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" width={"13%"} onClick={this.sortTableLocal.bind(this,sortDesignation)}>
                                                                <div className="sort-header">Designation
                                                                <span className={(this.state.sort === sortDesignation)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" width={"13%"}  onClick={this.sortTableLocal.bind(this,sortDeptList)}>
                                                                <div className="sort-header">Department
                                                                <span className={(this.state.sort === sortDeptList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" width={"25%"} onClick={this.sortTableLocal.bind(this,sortInactivateDate)}>
                                                                <div className="sort-header">Inactivate Date
                                                                <span className={(this.state.sort === sortInactivateDate)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="text-center" width={"5%  "}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {
                                                                let inactivationDate = new Date(item.userStartDate);
                                                                inactivationDate.setMonth(inactivationDate.getMonth() + item.activeForMonth);
                                                                inactivationDate.setDate(inactivationDate.getDate() + 1);

                                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    <td>{item.firstName + " " + item.lastName}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.displayRoleName}</td>
                                                                    <td>{ifEmptyReturnEmptyStr(item.reviewRole,"-")}</td>
                                                                    <td>{ifEmptyReturnEmptyStr(item.designation,"-")}</td>
                                                                    <td>{truncateString(SplitJoinString(item.departmentList,",",":-:",", "),Constants.DEPT_STRING_LENGTH)}</td> 
                                                                    <td>{item.roleId=="14"?inactivationDate.toLocaleDateString("en-IN"):"-"}</td>
                                                                    <td className="text-center">
                                                                        <TableDropDown 
                                                                            actionArr={item.status === "Active"?item.displayRoleName
                                                                            =="Temporary User"?tempUserActionArr:actionArr:actionArrReactivate}
                                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                            dropDownId = {item}
                                                                            themeSettings = {themeSettings}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div className="no-items-layout">
                                                <div className="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings = {themeSettings}
                                                    />
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                            <div className="col-md-12">
                                                <p style={{fontSize: '12px'}}><strong>*Note:</strong> If a temporary user is working on any ongoing project, then the date of inactivation will get extended automatically by a month and charges will be levied accordingly.</p>
                                            </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete User"
                                :
                                "Activate User"
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.deleteActivateUser}
                            proceedBtnLabel={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete"
                                :
                                "Reactivate"
                            }
                            themeSettings = {themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings = {themeSettings}
                        />

                        <Modal enforceFocus={false} className="custom-dialog add-user-dialog" show={this.state.showCreateDialog}
                        backdrop="static">
                            <Modal.Header>
                                <h5>{this.state.isEdit?"Edit User":"Add New User"}</h5>
                                <button 
                                    onClick={this.handleCreateDialogClose}>
                                    <span className="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />

                            <div className="modal-body">
                                <div className="addnew-modal-form">
                                    <div className="row custom-row">
                                        
                                        <div className="col-md-2 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>Title*</p>
                                                <RegularDropdown 
                                                    placeholder={this.state.salutation}
                                                    dropdownArr={Constants.SALUTATION_ARR}
                                                    labelParam="name"
                                                    onDropDownItemClick={this.salutionDropdownClick}
                                                    isFocusRequired = {true}
                                                    defaultPlaceholderDropDown={salutationPlaceholderText}
                                                />
                                                {
                                                    !isEmptyVariable(this.state.formErrors['salutation']) &&
                                                    <span className="cm-error">{this.state.formErrors['salutation']}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-4 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>First Name*</p>
                                                <input type="text" placeholder="Enter First Name"
                                                name="firstName" value={this.state.firstName} onChange={this.handleChange}/>
                                                {
                                                    !isEmptyVariable(this.state.formErrors['firstName']) &&
                                                    <span className="cm-error">{this.state.formErrors['firstName']}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-3 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>Middle Name</p>
                                                <input type="text" placeholder="Enter First Name" 
                                                name="middleName" value={this.state.middleName} onChange={this.handleChange}/>
                                                {
                                                    !isEmptyVariable(this.state.formErrors['middleName']) &&
                                                    <span className="cm-error">{this.state.formErrors['middleName']}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-3 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>Last Name*</p>
                                                <input type="text" placeholder="Enter Last Name" 
                                                name="lastName" value={this.state.lastName} onChange={this.handleChange}/>
                                                {
                                                    !isEmptyVariable(this.state.formErrors['lastName']) &&
                                                    <span className="cm-error">{this.state.formErrors['lastName']}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row custom-row">
                                        <div className="col-md-6 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>Email*</p>
                                                <input type="text" placeholder="Enter Email"
                                                name="email" value={this.state.email} onChange={this.handleChange}/>
                                                {
                                                    !isEmptyVariable(this.state.formErrors['email']) &&
                                                    <span className="cm-error">{this.state.formErrors['email']}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-6 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>Phone</p>
                                                <input type="text" placeholder="Enter Phone"
                                                name="phone" value={this.state.phone} onChange={this.handleChange}/>
                                                {
                                                    !isEmptyVariable(this.state.formErrors['phone']) &&
                                                    <span className="cm-error">{this.state.formErrors['phone']}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row custom-row">
                                        <div className="col-md-6 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>Designation*</p>
                                                {/* <RegularDropdown 
                                                    placeholder={this.state.selectedDesignation}
                                                    dropdownArr={this.state.designationArr}
                                                    labelParam="name"
                                                    onDropDownItemClick={this.designationDropdownClick}
                                                    defaultPlaceholderDropDown={designationPlaceholder}
                                                /> */}

                                                <ReactSelectDropdown
                                                    handleReactSelectClose={this.designationDropdownClick}
                                                    selectedDDObj= {this.state.selectedDesignationObj}
                                                    selectDropdownArr={this.state.designationArr}
                                                    label={"name"}
                                                    value={"designationId"}
                                                    placeholder={this.state.selectedDesignation}
                                                />
                                                {
                                                    !isEmptyVariable(this.state.formErrors['selectedDesignation']) &&
                                                    <span className="cm-error">{this.state.formErrors['selectedDesignation']}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-6 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>Review Role{this.state.selectedRole === displayRoleNameUser && "*"}</p>

                                                {/* <RegularDropdown 
                                                    placeholder={this.state.selectedReviewRole}
                                                    dropdownArr={this.state.reviewRolesList}
                                                    labelParam="reviewRole"
                                                    onDropDownItemClick={this.roleDropdownClick}
                                                    defaultPlaceholderDropDown={reviewRolePlaceholder}
                                                /> */}


                                                <ReactSelectDropdown
                                                    selectedDDObj= {this.state.selectedDDObj}
                                                    handleReactSelectClose={this.handleReactSelectClose}
                                                    selectDropdownArr={this.state.reviewRolesList}
                                                    label={"reviewRole"}
                                                    value={"reviewRoleId"}
                                                    placeholder={reviewRolePlaceholder}
                                                    disabled ={this.state.selectedRole == "Temporary User"}
                                                    isClearSelect={true}
                                                />
                                                {
                                                    !isEmptyVariable(this.state.formErrors['selectedReviewRole']) &&
                                                    <span className="cm-error">{this.state.formErrors['selectedReviewRole']}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row custom-row">
                                        <div className="col-md-6 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>User Type*</p>
                                                
                                                <RegularDropdown 
                                                    placeholder={this.state.selectedRole}
                                                    dropdownArr={this.state.rolesList}
                                                    labelParam="displayRoleName"
                                                    onDropDownItemClick={this.typeDropdownClick}
                                                    defaultPlaceholderDropDown={rolePlaceholder}
                                                />
                                                {
                                                    !isEmptyVariable(this.state.formErrors['selectedRole']) &&
                                                    <span className="cm-error">{this.state.formErrors['selectedRole']}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-6 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>User Department</p>
                                                <MultiSelectComponent 
                                                    id="deptCheckbox"
                                                    dataSource={this.state.deptList}
                                                    fields={fields}
                                                    placeholder={departmentPlaceholder} 
                                                    mode="CheckBox"
                                                    closePopupOnSelect={false}
                                                    allowFiltering={true}
                                                    select={this.addSelection}
                                                    removed={this.removeSelection}
                                                    cssClass="clientsChecklist dialogcl"
                                                    value={this.state.selectedDeptId}
                                                    showDropDownIcon={true}
                                                    showClearButton={false}
                                                >
                                                    <Inject services={[CheckBoxSelection]} />
                                                </MultiSelectComponent>
                                                {
                                                    !isEmptyVariable(this.state.formErrors['selectedDept']) &&
                                                    <span className="cm-error">{this.state.formErrors['selectedDept']}</span>
                                                }
                                            </div>
                                        </div>
                                        { this.state.selectedRole == "Temporary User" &&
                                             <div className="col-md-6 custom-col">
                                             <div className="addnew-modal-form-group">
                                                 <p>Active for Month(s)*</p>
                                                 <input type="text" placeholder="Enter Month"
                                                 disabled={this.TemporaryUserDisableCheck()}
                                                //   disabled={this.state.isEdit? 
                                                //     (this.state.initalSelectionRole!="Temporary User" &&
                                                //     this.state.selectedRole=="Temporary User")?false:true:false}
                                                 name="activateForMonth" value={this.state.activateForMonth} onChange={this.handleChange}/>
                                                 {
                                                     !isEmptyVariable(this.state.formErrors['activateForMonth']) &&
                                                     <span className="cm-error">{this.state.formErrors['activateForMonth']}</span>
                                                 }
                                             </div>
                                         </div>
                                        }

                                        { this.state.selectedRole == "Temporary User" && !this.state.isEdit &&
                                            <div className="col-md-6 custom-col">
                                            <div className="addnew-modal-form-group">
                                            <FilterCheckBox
                                                themeSettings={themeSettings}
                                            >
                                            <input
                                                type="checkbox"
                                                name={"temporaryuser"}
                                                id={"temporaryuser"}
                                                onChange={this.handleTemporaryUserCheckBoxClick}
                                                checked={this.state.isInactiveTemporaryUser ? true : false}
                                            />
                                            <label for={"temporaryuser"}>
                                                Create Inactive Temporary User
                                            </label>
                                            </FilterCheckBox>
                                             </div>
                                         </div>
                                        }
                                    </div>
                                </div>
                                
                                <div className="modal-close-save">
                                    <div className="close-save-col">
                                        <button onClick={this.handleCreateDialogClose} type="button" className="modal-close">Cancel</button>
                                        <DialogSaveButton
                                        themeSettings = {themeSettings}
                                        onClick={this.handleCreateUser} type="button">Save</DialogSaveButton>
                                    </div>
                                </div>
                            </div>

                        </Modal>

                        <Modal enforceFocus={false} className="custom-dialog" show={this.state.showExtendMonthDialog} backdrop="static">
                            <Modal.Header>
                                <h5>{this.state.operationType==="Reactivate"?"Activate User":"Extend Month"}</h5>
                                <button 
                                    onClick={this.handleExtendMonthClose}>
                                    <span className="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />

                            <div className="modal-body">
                                <div className="addnew-modal-form">
                                {this.state.operationType==="Reactivate" && <center><h6>{this.state.alertDialogMessage} <br/><br/></h6></center>}
                                             <div className="col-md-7 custom-col"> 

                                             <div className="addnew-modal-form-group">
    
                                                {!this.state.isDisplayExtendBox &&
                                                <>
                                                    <p>Active for Month(s)*</p>
                                                    <input type="text" placeholder="Enter Month" 
                                                    name="activateForMonth" value={this.state.activateForMonth} onChange={this.handleChange}/>
                                                </>
                                                }

                                                {
                                                    !isEmptyVariable(this.state.formErrors['activateForMonth']) &&
                                                    <span className="cm-error">{this.state.formErrors['activateForMonth']}</span>
                                                }
                                                {this.state.operationType!=="Reactivate" &&
                                                <>
                                                    <p style={{fontSize:10,marginTop:10}}>Expiry Date: <span style={{fontSize:12}}>{this.state.expireDateOfUserAcc}</span></p>
                                                    <p style={{fontSize:10}}>Period will be extended from the date of Expiry.</p>
                                                </> 
                                                }
                                             </div>
                                        </div>
                                </div> 
                                
                                <div className="modal-close-save">
                                    <div className="close-save-col">
                                        <button onClick={this.handleExtendMonthClose} type="button" className="modal-close">Cancel</button>
                                        <DialogSaveButton
                                        themeSettings = {themeSettings}
                                        onClick={this.handleExtendMonth} type="button">{this.state.operationType==="Reactivate" ?"Reactivate":"Save"}</DialogSaveButton>
                                    </div>
                                </div>
                            </div>

                        </Modal>
                        
                        <Modal className="custom-dialog" show={this.state.showDetailsDialog} backdrop="static">
                            <Modal.Header>
                                <h5>Details</h5>
                                <button 
                                    onClick={this.handleDetailsDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                <div className="details-table-layout">
                                    <div className="row custom-row">
                                        <div className="col-md-12 custom-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width={"24%"}>Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.salutation+" "+
                                                        this.state.selectedItem.firstName+" "+ifEmptyReturn(this.state.selectedItem.middleName)+" "+ this.state.selectedItem.lastName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Email</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Phone</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{ifEmptyReturnEmptyStr(this.state.selectedItem.phoneNo,"-")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Type</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.displayRoleName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Review Role</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{ifEmptyReturnEmptyStr(this.state.selectedItem.reviewRole,"-")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Departments</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{ifEmptyReturnEmptyStr(SplitJoinString(this.state.selectedItem.departmentList,",",":-:",", "),"-")}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </section>
                </main>
            </Router>
        );
    }
}

export default UsersList;