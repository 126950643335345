import {
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  Paragraph,
  WidthType,
  BorderStyle,
  ImageRun,
  TextRun,
} from "docx";

import { isEmptyVariable } from "../../../common/Global/commonFunctions";
let fontName = "Times New Roman";
const lightGreenColor = "#E2FAB5";
const purpleColor = "#000437";
const yellowColor = "#FCD12A";
export const getFirstPageContent = ( assignmentDetails, companyLogo, isLogoAvailable,allAuditorList) => {
  const cellBorderStyle={ style: BorderStyle.SINGLE, color: "000000", size: 3 };
  let unitName = String(assignmentDetails.unitAddressList).split(":-:")[0]+" ("+ JSON.stringify(assignmentDetails.unitNameCityList).split("(")[0].replace('"', "").trim()+")";
  let auditCycleNo = `Audit Cycle: ${assignmentDetails.groupSet1.split("$-$")[1]}`;
  let companyName = isEmptyVariable(assignmentDetails.companyName) ? "" : assignmentDetails.companyName;
  let auditorName = allAuditorList.map(e=>e.userName).join(", ");
  let firstPageContent = [];
  let endDate =!isEmptyVariable(assignmentDetails.endDate)?localDateExtractor(assignmentDetails.endDate):"";

  firstPageContent.push(
    new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        bottom: { style: BorderStyle.NONE, },
        top: { style: BorderStyle.NONE },
        right: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE },
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },

      rows: [
        /// Logo
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 20,
            
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    !isEmptyVariable(isLogoAvailable) &&
                      new ImageRun({
                        data: companyLogo,
                        transformation: {
                          width: 110,
                          height: 40,
                        },
                      }),
                    new TextRun({
                      break: 6,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// Internal audit report
        new TableRow({
          children: [
            new TableCell({
           
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 3,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
             
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 2,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              borders:{
               
                top: cellBorderStyle,
                right: cellBorderStyle,
                left: cellBorderStyle,
                },
              columnSpan: 10,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                
                  children: [
                    new TextRun({
                      text: "Internal",
                      size: 70,
                      font: fontName,
                      allCaps: true,
                    }),
                  ],
                }),

                new Paragraph({
                 
                  children: [
                    new TextRun({
                     
                      text: "Audit",
                      size: 70,
                      font: fontName,
                      allCaps: true,
                    }),
                  ],
                }),
                new Paragraph({
                
                  children: [
                    new TextRun({
                      text: "Report",
                      size: 70,
                      font: fontName,
                      allCaps: true,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 5,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t\t",
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// Audit Cycle
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 3,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 2,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              columnSpan: 10,
              borders:{
               
                bottom: cellBorderStyle,
                right: cellBorderStyle,
                left: cellBorderStyle,
                },
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  spacing: {
                    before: 200,
                  },
                  children: [
                    new TextRun({
                      text: auditCycleNo,
                      size: 65,
                      font: fontName,
                      // allCaps: true,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 5,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t\t",
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        // space
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 3,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      break: 2,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 2,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      break: 2,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 15,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      font: fontName,
                      break: 2,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// Afcons LTD.
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 3,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 2,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              borders:{
                top:cellBorderStyle,
                left:cellBorderStyle,
                right:cellBorderStyle,
                bottom:cellBorderStyle,

              },
              columnSpan: 10,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  alignment:AlignmentType.CENTER,
                  
                  children: [
                    new TextRun({
                      text: companyName,
                      size: 35,
                      font: fontName,
                      allCaps: true,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 5,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t\t",
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        // space
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 3,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      break: 2,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 2,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      break: 2,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 15,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      font: fontName,
                      break: 2,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// Unit Name
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 3,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 2,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 10,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  alignment:AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: unitName,
                      size: 25,
                      font: fontName,
                      allCaps: true,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 5,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t\t",
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        // space
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 3,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      break: 2,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 2,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      break: 2,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 15,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      font: fontName,
                      break: 2,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        ///  HOD/ Auditee
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 3,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              shading: {
                fill: yellowColor,
              },
              columnSpan: 17,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  alignment:AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "To be distributed to All Process owner / HOD / Auditee",
                      size: 25,
                      font: fontName,
                      allCaps: true,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        // space
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 3,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      break: 13,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 2,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      break: 13,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 15,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      font: fontName,
                      break: 13,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        ///  Perpared by
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 3,
              margins: {
                top: 100,
                bottom: 100,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 17,
              margins: {
                top: 97,
                bottom: 100,
                right: 300,
              },
              children: [
                new Paragraph({
                  spacing: {
                    after: 40,
                  },
                  alignment: AlignmentType.END,
                  children: [
                    new TextRun({
                      text: "Prepared by",
                      size: 23,
                      font: fontName,
                      break:1
                    }),
                  ],
                }),
                new Paragraph({
                  spacing: {
                    after: 40,
                  },
                  alignment: AlignmentType.END,
                  children: [
                    new TextRun({
                      text: auditorName,
                      size: 23,
                      font: fontName,
                     }),
                  ],
                }),
                new Paragraph({
                  spacing: {
                    after: 40,
                  },
                  alignment: AlignmentType.END,
                  children: [
                    new TextRun({
                      text: "Date: "+endDate,
                      size: 23,
                      font: fontName,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),


        // // space
        new TableRow({
          children: [
            new TableCell({
              shading: {
                fill: lightGreenColor,
              },
              columnSpan: 3,
              margins: {
                top: 30,
                bottom: 30,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 2,
              margins: {
                top: 30,
                bottom: 30,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading: {
                fill: purpleColor,
              },
              columnSpan: 15,
              margins: {
                top: 30,
                bottom: 30,
                left: 80,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "\t",
                      font: fontName,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  return [...firstPageContent];
};

export const localDateExtractor=(date)=>{
  var date = new Date(date);
  return date.toLocaleDateString('en-GB')
}