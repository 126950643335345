import {
	isEmptyVariable,
	isEmptyArray,
	ifEmptyReturnEmptyStr,
	removeHtmlTags,
	SplitJoinString,
	isJsonString,
	getStringWithSpaceAfterComma,
  } from "../../../common/Global/commonFunctions";
  import {
	Footer,
	Header,
	PageBreak,
	Packer,
	PageNumber,
	AlignmentType,
	Paragraph,
	TextRun,
	Table,
	TableRow,
	TableCell,
	VerticalAlign,
	WidthType,
	TabStopType,
	UnderlineType,
	BorderStyle,
	TabStopPosition,
	ImageRun,
	ExternalHyperlink,
  } from "docx";
  import moment from "moment";
  import * as TemplateConstants from "../../../common/Global/templateConstants";
  
  const fontName = "Calibri";
  export const getParagraphStyles = () => {
	return [
	  {
		id: "paraSmall",
		name: "paraSmall",
  
		run: {
		  size: 22,
		  font: fontName,
		},
	  },
	  {
		id: "paraSmallBold",
		name: "paraSmallBold",
  
		run: {
		  size: 24,
		  bold: true,
		  font: fontName,
		},
	  },
	  {
		id: "tableHeading",
		name: "tableHeading",
  
		run: {
		  size: 22,
		  bold: true,
		  font: fontName,
		},
	  },
	  {
		id: "pageHeading",
		name: "pageHeading",
		paragraph: {
		  alignment: AlignmentType.CENTER,
		},
		run: {
		  size: 22,
		  bold: true,
		  font: fontName,
		},
	  },
	  {
		id: "commonHeading",
		name: "commonHeading",
		paragraph: {},
		run: {
		  size: 22,
		  bold: true,
		  font: fontName,
		},
	  },
	  {
		id: "firstPageHeading",
		name: "firstPageHeading",
		paragraph: {
		  alignment: AlignmentType.CENTER,
		},
		run: {
		  size: 36,
		  // bold: true,
		  font: fontName,
		},
	  },
	];
  };
  
  export const getTableCellMargin = () => {
	return {
	  left: 40,
	};
  };
  
  export const getLineBreak = (noOfLines) => {
	return new TextRun({
	  break: noOfLines,
	});
  };
  export const getFirstPageContent = (
	clientNameTemp,
	unitNameCityListTemp,
	clientAddrTemp,
	companyNameTemp,
	companyAddr1Temp,
	companyAddr2Temp,
	companyCityTemp,
	companyStateTemp,
	companyPincodeTemp,
	companyLogo
  ) => {
	// let clientName = ifEmptyReturnEmptyStr(clientNameTemp, "");
	let unitNameCityList = "";
	if (!isEmptyVariable(unitNameCityListTemp)) {
	  unitNameCityList = getStringWithSpaceAfterComma(unitNameCityListTemp);
	}
	let clientAddr = "";
	if (!isEmptyVariable(clientAddrTemp)) {
	  clientAddr = SplitJoinString(clientAddrTemp, ";;", ":-:", ",", 1);
	}
  
	let companyName = ifEmptyReturnEmptyStr(companyNameTemp, "");
	let companyAddr =
	  ifEmptyReturnEmptyStr(companyAddr1Temp, "") +
	  ", " +
	  ifEmptyReturnEmptyStr(companyAddr2Temp, "") +
	  (isEmptyVariable(companyAddr2Temp) ? "" : ", ") +
	  ifEmptyReturnEmptyStr(companyCityTemp, "") +
	  ", " +
	  ifEmptyReturnEmptyStr(companyStateTemp, "") +
	  ", " +
	  ifEmptyReturnEmptyStr(companyPincodeTemp, "");
  
	let companyAddrLine1 = ifEmptyReturnEmptyStr(companyAddr1Temp, "") + ",";
	let companyAddrLine2 = "";
  
	if (!isEmptyVariable(companyAddr2Temp)) {
	  companyAddrLine2 =
		ifEmptyReturnEmptyStr(companyAddr2Temp, "") +
		(isEmptyVariable(companyAddr2Temp) ? "" : ",");
	}
	let companyAddrLine3 =
	  ifEmptyReturnEmptyStr(companyCityTemp, "") +
	  ", " +
	  ifEmptyReturnEmptyStr(companyStateTemp, "") +
	  ", " +
	  ifEmptyReturnEmptyStr(companyPincodeTemp, "");
  
  
	let firstPageContent = [];
  
	//Client name
	// firstPageContent.push(
	// 	new Paragraph({
	// 		children: [
	// 			new TextRun({
	// 				text:"Client: ",
	// 				break: 5,
	// 			}),
	// 			new TextRun({
	// 				text: `${clientName}`,
  
	// 			}),
	// 		],
	// 		style: "firstPageHeading",
	// 	})
	// );
  
	//Unit types
	firstPageContent.push(
	  new Paragraph({
		children: [
		  new TextRun({
			text: `${unitNameCityList}`,
			break: 5,
		  }),
		],
		style: "firstPageHeading",
	  })
	);
	//Address
  
	firstPageContent.push(
	  new Paragraph({
		children: [
  
		  new ExternalHyperlink({
			children:[
			  new TextRun({
				text: "This is an external link!",
				style: "Hyperlink",
				imprint:true
				
			  }),
			],
  
			link:"hello.pdf"
		  }),
		  new TextRun({
			text: "Address: ",
		  }),
  
		  new TextRun({
			text: `${clientAddr}`,
		  }),
		],
		style: "firstPageHeading",
	  })
	);
  
  
  
	// Add Logo before Company Name
	if (!isEmptyVariable(companyLogo)) {
	 
	  firstPageContent.push(
		new Paragraph({
		  children: [
			getLineBreak(10),
			new ImageRun({
			  data: companyLogo,
			  transformation: {
				width: 100,
				height: 100,
			  },
			}),
		  ],
		  style: "firstPageHeading",
		})
	  );
	  //Company Name
	  firstPageContent.push(
		new Paragraph({
		  children: [
			new TextRun({
			  text: `${companyName}`,
			}),
		  ],
		  style: "firstPageHeading",
		})
	  );
	} else {
	  //Company Name
	  firstPageContent.push(
		new Paragraph({
		  children: [
			getLineBreak(10),
			new TextRun({
			  text: `${companyName}`,
			}),
		  ],
		  style: "firstPageHeading",
		})
	  );
	}
  
	//Company Address
	firstPageContent.push(
	  new Paragraph({
		children: [
		  new TextRun({
			text: `${companyAddrLine1}`,
		  }),
		],
		style: "firstPageHeading",
	  })
	);
	if (!isEmptyVariable(companyAddr2Temp)) {
	  firstPageContent.push(
		new Paragraph({
		  children: [
			new TextRun({
			  text: `${companyAddrLine2}`,
			}),
		  ],
		  style: "firstPageHeading",
		})
	  );
	}
	firstPageContent.push(
	  new Paragraph({
		children: [
		  new TextRun({
			text: `${companyAddrLine3}`,
		  }),
		  new PageBreak(),
		],
		style: "firstPageHeading",
	  })
	);
  
	return [...firstPageContent];
  };
  
  export const getHeaderContent = (clientNameTemp, typeOfAuditTemp) => {
	let clientName = ifEmptyReturnEmptyStr(clientNameTemp, "");
	let typeOfAudit = ifEmptyReturnEmptyStr(typeOfAuditTemp, "");
  
	return {
	  default: new Header({
		children: [
		  new Paragraph({
			tabStops: [
			  {
				type: TabStopType.END,
				position: TabStopPosition.MAX,
			  },
			],
			children: [
			  new TextRun({
				text: `${clientName}`,
			  }),
			  new TextRun({
				text: `\t${typeOfAudit}`,
			  }),
			],
			thematicBreak: true,
		  }),
		],
	  }),
	};
  };
  
  export const getFooterContent = (companyNameTemp) => {
	let companyName = ifEmptyReturnEmptyStr(companyNameTemp, "");
  
	return {
	  default: new Footer({
		children: [
		  new Paragraph({
			thematicBreak: true,
		  }),
		  new Paragraph({
			tabStops: [
			  {
				type: TabStopType.RIGHT,
				position: TabStopPosition.MAX,
			  },
			],
			children: [
			  new TextRun({
				text: `${companyName}`,
			  }),
			  new TextRun({
				children: [`\tPage `, PageNumber.CURRENT],
			  }),
			  new TextRun({
				children: [" of ", PageNumber.TOTAL_PAGES],
			  }),
			],
		  }),
		],
	  }),
	};
  };
  
  export const getTableContent = (
	assignmentTypeNameTemp,
	unitListTemp,
	unitAddressListTemp,
	assignmentAreaTemp,
	processListTemp,
	functionListTemp,
	checkListTemp,
	memberListTemp,
	L1UserNameTemp,
	startDateTemp,
	endDateTemp,
	reportDateTemp,
	periodFromDateTemp,
	periodToDateTemp,
	reportRiskLevelTemp
  ) => {
  
	let tableRows = [];
  
	if (!isEmptyVariable(assignmentTypeNameTemp)) {
	  tableRows.push(
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Type of Assignment",
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${assignmentTypeNameTemp}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		})
	  );
	}
	if (!isEmptyVariable(unitListTemp)) {
	  let unitList = SplitJoinString(unitListTemp, ";;", ":-:", ", ");
	  tableRows.push(
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Unit",
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${unitList}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		})
	  );
	}
	if (!isEmptyVariable(unitAddressListTemp)) {
	  let unitAddressList = SplitJoinString(
		unitAddressListTemp,
		";;",
		":-:",
		",",
		1
	  );
	  tableRows.push(
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Unit Address",
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${unitAddressList}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		})
	  );
	}
  
	if (!isEmptyVariable(assignmentAreaTemp)) {
	  let assignmentArea = "";
	  if (assignmentAreaTemp === "Process") {
		assignmentArea = SplitJoinString(processListTemp, ",", ":-:", ", ");
	  } else if (assignmentAreaTemp === "Function") {
		assignmentArea = SplitJoinString(functionListTemp, ",", ":-:", ", ");
	  } else if (
		assignmentAreaTemp === "CheckList" &&
		!isEmptyVariable(checkListTemp)
	  ) {
		assignmentArea = SplitJoinString(checkListTemp, ",", ":-:", ", ");
	  }
	  tableRows.push(
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Process/Function",
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${assignmentArea}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		})
	  );
	}
  
	if (!isEmptyVariable(memberListTemp)) {
	  let memberList = SplitJoinString(memberListTemp, ",", ":", ", ", 1);
	  if (!isEmptyVariable(L1UserNameTemp)) {
		memberList = memberList + ", " + L1UserNameTemp;
	  }
	  tableRows.push(
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Resources",
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${memberList}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		})
	  );
	}
  
	if (!isEmptyVariable(startDateTemp)) {
	  tableRows.push(
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Start Date",
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${moment(startDateTemp).format("DD/MM/YYYY")}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		})
	  );
	}
	if (!isEmptyVariable(endDateTemp)) {
	  tableRows.push(
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "End Date",
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${moment(endDateTemp).format("DD/MM/YYYY")}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		})
	  );
	}
	if (!isEmptyVariable(reportDateTemp)) {
	  tableRows.push(
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Report Date",
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${moment(reportDateTemp).format("DD/MM/YYYY")}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		})
	  );
	}
	if (
	  !isEmptyVariable(periodFromDateTemp) &&
	  !isEmptyVariable(periodToDateTemp)
	) {
	  tableRows.push(
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Sample Period",
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${moment(periodFromDateTemp).format(
					"DD/MM/YYYY"
				  )} - ${moment(periodToDateTemp).format("DD/MM/YYYY")}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		})
	  );
	}
	if (!isEmptyVariable(reportRiskLevelTemp)) {
	  tableRows.push(
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Overall Risk",
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${reportRiskLevelTemp}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		})
	  );
	}
  
	let table = new Table({
	  width: {
		size: 100,
		type: WidthType.PERCENTAGE,
	  },
	  margins: getTableCellMargin(),
	  rows: tableRows,
	});
	return table;
  };
  
  // HEADING above the ASSIGNMENT table
  export const getAssignementTableHeadingContent = () => {
	let assignementTableHeading = new Paragraph({
	  style: "pageHeading",
	  pageBreakBefore: true,
	  children: [
		new TextRun({
		  text: "ASSIGNMENT DETAILS",
		  underline: {},
		}),
		getLineBreak(1),
	  ],
	});
	return assignementTableHeading;
  };
  
  export const getExecutiveSummaryContent = (observationsTemp) => {
	// let executiveSummaryList = [];
	let executiveSummaryParagraph = [];
	const para = new Paragraph({
	  style: "pageHeading",
	  pageBreakBefore: true,
	  children: [
		new TextRun({
		  text: "EXECUTIVE SUMMARY",
		  underline: {},
		}),
	  ],
	});
  
	executiveSummaryParagraph.push(para);
  
	let highRiskLevelTemp = [];
	let lowRiskLeveltemp = [];
	let mediumRiskLeveltemp = [];
  
	observationsTemp.forEach((obsItem) => {
	  let esDisplayValues = getExecSummaryFromTemplateValues(
		obsItem.templateFieldValues
	  );
	  if (obsItem.riskLevel == "High") {
		highRiskLevelTemp.push(esDisplayValues);
	  } else if (obsItem.riskLevel == "Medium") {
		mediumRiskLeveltemp.push(esDisplayValues);
	  } else if (obsItem.riskLevel == "Low") {
		lowRiskLeveltemp.push(esDisplayValues);
	  }
	});
  
  
  
	if (highRiskLevelTemp.length > 0) {
	  risklevelGenration("High", highRiskLevelTemp, executiveSummaryParagraph);
	}
	if (mediumRiskLeveltemp.length > 0) {
	  risklevelGenration(
		"Medium",
		mediumRiskLeveltemp,
		executiveSummaryParagraph
	  );
	}
	if (lowRiskLeveltemp.length > 0) {
	  risklevelGenration("Low", lowRiskLeveltemp, executiveSummaryParagraph);
	}
	return executiveSummaryParagraph;
  };
  
  export const risklevelGenration = (
	riskName,
	riskLevelArrayTem,
	executiveSummaryParagraph
  ) => {
	executiveSummaryParagraph.push(
	  new Table({
		rows: [
		  new TableRow({
			height: {
			  value: 20,
			},
			children: [
			  new TableCell({
				width: {
				  size: 100,
				  type: WidthType.PERCENTAGE,
				},
				borders: {
				  top: {
					style: BorderStyle.singleRow,
					size: 1,
					color: "000000",
				  },
				  right: {
					style: BorderStyle.singleRow,
					size: 1,
					color: "000000",
				  },
				  left: {
					style: BorderStyle.singleRow,
					size: 1,
					color: "000000",
				  },
				},
				children: [
				  new Paragraph({
					style: "pageHeading",
					children: [
					  new TextRun({
						text: `Risk Level: `,
						font: fontName,
						bold: true,
						break: 3,
  
					  
					  }),
					  new TextRun({
						text: riskName,
						font: fontName,
						
					  }),
					],
				  }),
				],
			  }),
			],
		  }),
		],
	  })
	);
  
	riskLevelArrayTem.forEach((element) => {
	  executiveSummaryParagraph.push(
		new Paragraph({
		  spacing: {
			before: 100,
		  },
		  bullet: {
			level: 0,
		  },
		  style: "paraSmall",
		  children: [
			new TextRun({
			  text: `${element}`,
			  font: fontName,
			}),
		  ],
		})
	  );
	});
	return executiveSummaryParagraph;
  };
  
  export const getValueAdditionContent = (observationsTemp) => {
	let ValueAdditionSectionContent = [];
	let vaHeading = [];
	let vaRows = [];
	let vaHeadingFlag = false;
  
	const para = new Paragraph({
	  style: "pageHeading",
	  pageBreakBefore: true,
	  children: [
		new TextRun({
		  text: "Value Additions",
		  underline: {},
		}),
		getLineBreak(1),
	  ],
	});
  
	ValueAdditionSectionContent.push(para);
  
	const obsHeadingCell = new TableCell({
	  width: {
		size: 70,
		type: WidthType.PERCENTAGE,
	  },
	  children: [
		new Paragraph({
		  text: "Observation",
		  style: "tableHeading",
		  alignment: AlignmentType.CENTER,
		}),
	  ],
	  verticalAlign: VerticalAlign.CENTER,
	});
	vaHeading.push(obsHeadingCell);
  
	observationsTemp.forEach((obsItem) => {
	  if (!isEmptyArray(obsItem.valueAdditions)) {
		let vaSingleRow = [];
  
		// check if all value additions for an observation are present
		const isVaValuesNotPresent = obsItem.valueAdditions.every((vaItem) => {
		  return isEmptyVariable(vaItem.value);
		});
  
		if (!isVaValuesNotPresent) {
		  const obsCell = new TableCell({
			width: {
			  size: 70,
			  type: WidthType.PERCENTAGE,
			},
			children: [
			  new Paragraph({
				text: `${
				  !isEmptyVariable(removeHtmlTags(obsItem.observationHeading))
					? `${removeHtmlTags(obsItem.observationHeading)}`
					: `${removeHtmlTags(obsItem.observationDetail)}`
				}`,
				style: "paraSmall",
			  }),
			],
			verticalAlign: VerticalAlign.CENTER,
		  });
		  vaSingleRow.push(obsCell);
  
		  // console.log(" VA ",obsItem.observationId,isVaValuesNotPresent);
  
		  // get cells with valueAddition name and values from the obsList item and push it to row
		  obsItem.valueAdditions.forEach((vaItem) => {
			//get value addition table heading for first time
			if (!vaHeadingFlag) {
			  const headingCell = new TableCell({
				width: {
				  size: 15,
				  type: WidthType.PERCENTAGE,
				},
				children: [
				  new Paragraph({
					text: `${ifEmptyReturnEmptyStr(vaItem.name, "-")}`,
					style: "tableHeading",
				  }),
				],
				verticalAlign: VerticalAlign.CENTER,
			  });
			  vaHeading.push(headingCell);
			}
  
			const vaCell = new TableCell({
			  width: {
				size: 15,
				type: WidthType.PERCENTAGE,
			  },
  
			  children: [
				new Paragraph({
				  text: `${ifEmptyReturnEmptyStr(vaItem.value, "-")}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			});
			vaSingleRow.push(vaCell);
		  });
		  vaHeadingFlag = true;
		  vaRows.push(
			new TableRow({
			  children: vaSingleRow,
			})
		  );
		}
	  }
	});
  
	const valueAdditionTable = new Table({
	  width: {
		size: 100,
		type: WidthType.PERCENTAGE,
	  },
	  columnWidths: [70, 15, 15],
	  margins: getTableCellMargin(),
	  rows: [
		new TableRow({
		  children: vaHeading,
		}),
		// show value addition rows
		...vaRows,
	  ],
	});
  
	ValueAdditionSectionContent.push(valueAdditionTable);
  
	return ValueAdditionSectionContent;
  };
  
  export const getObservationContentWithTemplates = (
	observationArr,
	observationTemplateFieldsAll,
	businessRiskMap
  ) => {
	let observationSectionContent = [];
	const obsHeading = new Paragraph({
	  style: "pageHeading",
	  pageBreakBefore: true,
	  children: [
		new TextRun({
		  text: "Observations",
		  underline: {},
		}),
		getLineBreak(1),
	  ],
	});
	observationSectionContent.push(obsHeading);
  
	observationArr.forEach((obsItem) => {
	  // Observation heading
	  if (!isEmptyVariable(obsItem.observationHeading)) {
		observationSectionContent.push(
		  new Paragraph({
			style: "paraSmallBold",
			children: [
			  new TextRun({
				text: `${
				  !isEmptyVariable(obsItem.observationHeading)
					? removeHtmlTags(obsItem.observationHeading)
					: ""
				}`,
				// text: `${obsItem.observationHeading}`,
				underline: {},
			  }),
			  getLineBreak(1),
			],
		  })
		);
	  }
	  // Observation detail
	  if (!isEmptyVariable(obsItem.observationDetail)) {
		observationSectionContent.push(
		  new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: `${
				  !isEmptyVariable(obsItem.observationDetail)
					? removeHtmlTags(obsItem.observationDetail)
					: ""
				}`,
				// text: `${obsItem.observationDetail}`,
			  }),
			  getLineBreak(1),
			],
		  })
		);
	  }
  
	  //Lets parse all the fields except above 2 items
	  observationTemplateFieldsAll.forEach((fieldItem) => {
		let fieldId = fieldItem.fieldId;
		if (fieldId != 1 && fieldId != 2 && fieldId != 3) {
		  //1 - obs heading 2 - obs details 3 - exec summary (Always)
		  let displayValue = getObservationDisplayValues(
			obsItem.templateFieldValues,
			fieldItem,
			businessRiskMap,
			obsItem.customBusinessRisk
		  );
		  if (fieldItem.fieldType !== TemplateConstants.FIELD_TYPE_TABLE) {
			if (!isEmptyVariable(displayValue)) {
			  observationSectionContent.push(
				new Paragraph({
				  style: "commonHeading",
				  text: `${fieldItem.fieldName}`,
				}),
				new Paragraph({
				  style: "paraSmall",
				  children: [
					new TextRun({
					  text: `${displayValue}`,
					}),
					getLineBreak(1),
				  ],
				})
			  );
			}
		  } else {
			//if it is table then
			// obsItem.templateFieldValues
			let table = getObservationTableCells(
			  obsItem.templateFieldValues,
			  fieldItem
			);
  
			if (!isEmptyVariable(table)) {
			  observationSectionContent.push(
				new Paragraph({
				  style: "commonHeading",
				  text: `${fieldItem.fieldName}`,
				})
			  );
			  observationSectionContent.push(table);
			  observationSectionContent.push(
				new Paragraph({
				  children: [
					// getLineBreak(1)
				  ],
				})
			  );
			}
		  }
		}
	  });
  
  
  
	  //Responses
	  let reponsesArr = [];
	  if (!isEmptyArray(obsItem.responses)) {
		obsItem.responses.forEach((responsesItem) => {
		  //Obs Responses
		  let targetArr = [];
		  if (!isEmptyVariable(responsesItem.target)) {
			let targatDate = moment(
			  responsesItem.target,
			  "YYYY-MM-DDTHH:mm:ss.SSSZ"
			).format("DD/MM/YYYY");
			targetArr.push(targatDate);
		  }
		  if ( 
			!isEmptyVariable(responsesItem.immediate) &&
			responsesItem.immediate === "Y"
		  ) {
			targetArr.push("Immediate");
		  }
		  if (
			!isEmptyVariable(responsesItem.continuous) &&
			responsesItem.continuous === "Y"
		  ) {
			targetArr.push("Continuous");
		  }
		  if (!isEmptyVariable(responsesItem.customTarget)) {
			targetArr.push(responsesItem.customTarget);
		  }
		  let target = targetArr.join(", ");
  
		  observationSectionContent.push(
			new Paragraph({
			  style: "commonHeading",
			  text: `Response`,
			})
		  );
  
		  let responseText = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "Response: ",
			  }),
			  new TextRun({
				text: `${ifEmptyReturnEmptyStr(responsesItem.response, "-")}`,
			  }),
			],
		  });
		  observationSectionContent.push(responseText);
  
		  let targetDate = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "Target: ",
			  }),
			  new TextRun({
				text: `${target}`,
			  }),
			],
		  });
		  observationSectionContent.push(targetDate);
		  let responsibility = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "Responsibility: ",
			  }),
			  new TextRun({
				text: `${
				  isEmptyVariable(responsesItem.salutation)
					? ""
					: responsesItem.salutation
				} ${
				  isEmptyVariable(responsesItem.firstName)
					? ""
					: responsesItem.firstName
				} ${
				  isEmptyVariable(responsesItem.lastName)
					? ""
					: responsesItem.lastName
				}`,
			  }),
			  getLineBreak(1),
			],
		  });
		  observationSectionContent.push(responsibility);
		});
	  }
	});
  
	return observationSectionContent;
  };
  
  export const getSecondFormatObservationsContentWithTemplates = (
	observationsTemp,
	observationTemplateFieldsAll,
	businessRiskMap
  ) => {
	let observationSectionContent = [];
	let observationSectionTable = [];
	let observationRows = [];
  
	const obsHeading = new Paragraph({
	  style: "pageHeading",
	  pageBreakBefore: true,
	  children: [
		new TextRun({
		  text: "Observations",
		  underline: {},
		}),
		getLineBreak(1),
	  ],
	});
  
	observationSectionContent.push(obsHeading);
  
	observationsTemp.forEach((obsItem, obsItemIndex) => {
	  let heading = getReportCustomHeadingArray(obsItem.reportCustomHeading);
	  //Reponses
	  let reponsesArr = [];
  
	  if (!isEmptyArray(obsItem.responses)) {
		obsItem.responses.forEach((responsesItem) => {
		  //Obs Responses
		  let targetArr = [];
		  if (!isEmptyVariable(responsesItem.target)) {
			let targatDate = moment(
			  responsesItem.target,
			  "YYYY-MM-DDTHH:mm:ss.SSSZ"
			).format("DD/MM/YYYY");
			targetArr.push(targatDate);
		  }
		  if (
			!isEmptyVariable(responsesItem.immediate) &&
			responsesItem.immediate === "Y"
		  ) {
			targetArr.push("Immediate");
		  }
		  if (
			!isEmptyVariable(responsesItem.continuous) &&
			responsesItem.continuous === "Y"
		  ) {
			targetArr.push("Continuous");
		  }
		  if (!isEmptyVariable(responsesItem.customTarget)) {
			targetArr.push(responsesItem.customTarget);
		  }
		  let target = targetArr.join(", ");
  
		  let responseText = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "Response: ",
			  }),
			  new TextRun({
				text: `${ifEmptyReturnEmptyStr(responsesItem.response, "-")}`,
			  }),
			],
		  });
		  reponsesArr.push(responseText);
  
		  let targetDate = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "Target: ",
			  }),
			  new TextRun({
				text: `${target}`,
			  }),
			],
		  });
		  reponsesArr.push(targetDate);
  
		  let responsibility = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "Responsibility: ",
			  }),
			  new TextRun({
				text: `${responsesItem.salutation} ${responsesItem.firstName} ${responsesItem.lastName}`,
			  }),
			  getLineBreak(1),
			],
		  });
		  reponsesArr.push(responsibility);
		});
	  }
  
	  let otherObsFields = [];
	  //Lets parse all the fields except above 2 items
	  observationTemplateFieldsAll.forEach((fieldItem) => {
		let fieldId = fieldItem.fieldId;
		console.log(
		  "ObservationId: " + obsItem.observationId + ", FieldID: " + fieldId
		);
		if (fieldId != 1 && fieldId != 2 && fieldId != 3) {
		  //1 - obs heading 2 - obs details 3 - exec summary (Always)
		  let displayValue = getObservationDisplayValues(
			obsItem.templateFieldValues,
			fieldItem,
			businessRiskMap,
			obsItem.customBusinessRisk
		  );
		  if (fieldItem.fieldType !== TemplateConstants.FIELD_TYPE_TABLE) {
			if (!isEmptyVariable(displayValue)) {
			  otherObsFields.push(
				new Paragraph({
				  style: "commonHeading",
				  text: `${fieldItem.fieldName}`,
				}),
				new Paragraph({
				  style: "paraSmall",
				  children: [
					new TextRun({
					  text: `${displayValue}`,
					}),
					getLineBreak(1),
				  ],
				})
			  );
			}
		  } else {
			//if it is table then
			// obsItem.templateFieldValues
			let table = getObservationTableCells(
			  obsItem.templateFieldValues,
			  fieldItem
			);
  
			if (!isEmptyVariable(table)) {
			  otherObsFields.push(
				new Paragraph({
				  style: "commonHeading",
				  text: `${fieldItem.fieldName}`,
				})
			  );
			  otherObsFields.push(table);
			  otherObsFields.push(
				new Paragraph({
				  children: [
					// getLineBreak(1)
				  ],
				})
			  );
			}
		  }
		}
	  });
  
	  // add each observation content to docx table
	  observationRows.push(
		new TableRow({
		  cantSplit: true,
		  children: [
			//index cell
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${obsItemIndex + 1}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			  margins: getTableCellMargin(),
			}),
			// observation details cell
  
			new TableCell({
			  margins: getTableCellMargin(),
			  children: [
				...heading,
  
				new Paragraph({
				  style: "paraSmallBold",
				  children: [
					new TextRun({
					  text: `${
						!isEmptyVariable(obsItem.observationHeading)
						  ? removeHtmlTags(obsItem.observationHeading)
						  : ""
					  }`,
					  // text: `${obsItem.observationHeading}`,
					}),
					getLineBreak(1),
				  ],
				}),
				new Paragraph({
				  style: "paraSmall",
				  children: [
					new TextRun({
					  text: `${
						!isEmptyVariable(obsItem.observationDetail)
						  ? removeHtmlTags(obsItem.observationDetail)
						  : ""
					  }`,
					  // text: `${obsItem.observationDetail}`,
					}),
					getLineBreak(1),
				  ],
				}),
				...otherObsFields,
			  ],
			}),
			// responses cell
			new TableCell({
			  margins: getTableCellMargin(),
			  children: [...reponsesArr],
			}),
		  ],
		})
	  );
	});
  
	observationSectionTable = new Table({
	  width: {
		size: 100,
		type: WidthType.PERCENTAGE,
	  },
	  pageBreakBefore: true,
	  columnWidths: [10, 55, 45],
	  rows: [
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Sl. No.",
				  style: "tableHeading",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Observations",
				  style: "tableHeading",
				  alignment: AlignmentType.CENTER,
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Responses",
				  style: "tableHeading",
				  alignment: AlignmentType.CENTER,
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		}),
		...observationRows,
	  ],
	});
	observationSectionContent.push(observationSectionTable);
  
	return observationSectionContent;
  };
  
  export const getObservationTableCells = (ObsValuesMap, fieldItem) => {
	let fieldId = fieldItem.fieldId;
	if (isEmptyVariable(ObsValuesMap[fieldId])) {
	  return "";
	}
  
	let jsonStr = ObsValuesMap[fieldId]["value"];
	if (isEmptyVariable(jsonStr) || !isJsonString(jsonStr)) {
	  return "";
	}
  
	let tableTemplateValuesArr = JSON.parse(jsonStr);
	let tableValuesArr = [];
	tableTemplateValuesArr.forEach((row, rowIndex) => {
	  let tableRowCellsArr = [];
	  row.forEach((column, colIndex) => {
		//first row is heading and also exclude the last column since it is action column
		let colLength = row.length;
		if (rowIndex === 0) {
		  if (colIndex !== colLength - 1) {
			tableRowCellsArr.push(
			  new TableCell({
				children: [
				  new Paragraph({
					text: `${ifEmptyReturnEmptyStr(column, "-")}`,
					style: "tableHeading",
				  }),
				],
				verticalAlign: VerticalAlign.CENTER,
			  })
			);
		  }
		} else {
		  if (colIndex !== colLength - 1) {
			tableRowCellsArr.push(
			  new TableCell({
				children: [
				  new Paragraph({
					text: `${ifEmptyReturnEmptyStr(column, "-")}`,
					style: "paraSmall",
				  }),
				],
				verticalAlign: VerticalAlign.CENTER,
			  })
			);
		  }
		}
	  });
  
	  tableValuesArr.push(
		new TableRow({
		  children: tableRowCellsArr,
		})
	  );
	});
  
	let table = new Table({
	  width: {
		size: 100,
		type: WidthType.PERCENTAGE,
	  },
	  margins: getTableCellMargin(),
	  rows: [...tableValuesArr],
	});
	return table;
  };
  
  export const getExecSummaryFromTemplateValues = (ObsValuesMap) => {
	let fieldId = "3";
	if (isEmptyVariable(ObsValuesMap[fieldId])) {
	  return "";
	}
  
	let displayValue = ObsValuesMap[fieldId]["value"];
	if (isEmptyVariable(displayValue)) {
	  return "";
	}
  
	return removeHtmlTags(displayValue);
  };
  
  export const getObservationDisplayValues = (
	ObsValuesMap,
	fieldItem,
	businessRiskMap,
	customBusinessRisk
  ) => {
	let fieldId = fieldItem.fieldId;
	if (isEmptyVariable(ObsValuesMap[fieldId])) {
	  return "";
	}
  
	let displayValue = ObsValuesMap[fieldId]["value"];
	if (isEmptyVariable(displayValue)) {
	  return "";
	}
  
	switch (fieldItem.fieldType) {
	  case TemplateConstants.FIELD_TYPE_TEXT:
		return removeHtmlTags(displayValue);
	  case TemplateConstants.FIELD_TYPE_DATE:
		return isEmptyVariable(displayValue)
		  ? ""
		  : moment(displayValue).format("DD/MM/YYYY");
	  case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
		return displayValue;
	  case TemplateConstants.FIELD_TYPE_DROPDOWN:
		return displayValue;
	  case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
		if (!isJsonString(displayValue)) {
		  return "-";
		}
		return JSON.parse(displayValue).join(", ");
	  case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
		if (fieldItem.fieldId == 5) {
		  if (!isJsonString(displayValue)) {
			return "-";
		  }
		  let riskIdArr = JSON.parse(displayValue);
		  let riskNameArr = riskIdArr.map((item) => {
			if (item === -1) {
			  if (!isEmptyVariable(customBusinessRisk)) {
				return "Others: " + removeHtmlTags(customBusinessRisk);
			  } else {
				return "Others";
			  }
			} else {
			  return (
				businessRiskMap[item].name +
				": " +
				businessRiskMap[item].description
			  );
			}
		  });
		  return riskNameArr.join(", ");
		} else {
		  if (!isJsonString(displayValue)) {
			return "-";
		  }
		  return JSON.parse(displayValue).join(", ");
		}
	}
	return "";
  };
  
  /* ################### OLD FUNCTIONS ##################### **/
  export const getObservationsContentOLD = (observationsTemp) => {
	let observationSectionContent = [];
	const obsHeading = new Paragraph({
	  style: "pageHeading",
	  pageBreakBefore: true,
	  children: [
		new TextRun({
		  text: "Observations",
		  underline: {},
		}),
		getLineBreak(1),
	  ],
	});
	observationSectionContent.push(obsHeading);
  
	observationsTemp.forEach((obsItem) => {
	  let instanceTableHeading = [];
	  let instanceTableValuesArr = [];
	  let instanceTable = [];
	  // let instanceRowValue = [];
  
	  //instance table
	  if (
		!isEmptyArray(obsItem.instanceColumns) &&
		!isEmptyArray(obsItem.instanceValues)
	  ) {
		let columnArray = [];
		obsItem.instanceColumns.forEach((instanceColItem) => {
		  for (var i = 1; i <= 10; i++) {
			if (!isEmptyVariable(instanceColItem["column" + i])) {
			  columnArray.push(instanceColItem["column" + i]);
			}
		  }
		});
		let rowArray = [];
		rowArray = obsItem.instanceValues.map((instanceValItem) => {
		  let singleRow = [];
		  for (var i = 1; i <= 10; i++) {
			if (!isEmptyVariable(instanceValItem["value" + i])) {
			  singleRow.push(instanceValItem["value" + i]);
			}
		  }
		  return singleRow;
		});
		// add column array as Instance table heading
		columnArray.forEach((instanceColItem) => {
		  const instanceHeadingCell = new TableCell({
			children: [
			  new Paragraph({
				text: `${ifEmptyReturnEmptyStr(instanceColItem, "-")}`,
				style: "tableHeading",
			  }),
			],
			verticalAlign: VerticalAlign.CENTER,
		  });
		  instanceTableHeading.push(instanceHeadingCell);
		});
  
		// add row array as Instance table values
		rowArray.forEach((instanceValueArrItem) => {
		  let instanceValueSingleRow = [];
		  instanceValueArrItem.forEach((instanceValueItem) => {
			const instanceValues = new TableCell({
			  children: [
				new Paragraph({
				  text: `${ifEmptyReturnEmptyStr(instanceValueItem, "-")}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			});
  
			instanceValueSingleRow.push(instanceValues);
		  });
		  const instanceRow = new TableRow({
			children: instanceValueSingleRow,
		  });
  
		  instanceTableValuesArr.push(instanceRow);
		});
  
		//instance table for docx
		instanceTable = new Table({
		  width: {
			size: 100,
			type: WidthType.PERCENTAGE,
		  },
		  margins: getTableCellMargin(),
		  rows: [
			new TableRow({
			  children: instanceTableHeading,
			}),
			...instanceTableValuesArr,
		  ],
		});
	  }
  
	  //Not Writing Annexures to document as of now
	  //Annexures
	  let annexureArr = [];
	  let annexureHeading = new Paragraph({
		style: "commonHeading",
		text: "Annexure",
	  });
	  annexureArr.push(annexureHeading);
  
	  if (!isEmptyArray(obsItem.annexures)) {
		obsItem.annexures.forEach((annexureItem) => {
		  let docPath = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "FileName: ",
			  }),
			  new TextRun({
				text: `${ifEmptyReturnEmptyStr(annexureItem.documentPath, "-")}`,
			  }),
			],
		  });
		  annexureArr.push(docPath);
  
		  if (!isEmptyVariable(annexureItem.remark)) {
			let remarks = new Paragraph({
			  style: "paraSmall",
			  children: [
				new TextRun({
				  text: "Remark: ",
				}),
				new TextRun({
				  text: `${ifEmptyReturnEmptyStr(annexureItem.remark, "-")}`,
				}),
			  ],
			});
			annexureArr.push(remarks);
		  }
		});
	  } else {
		let annexureNA = new Paragraph({
		  style: "paraSmall",
		  children: [
			new TextRun({
			  text: "-",
			}),
		  ],
		});
		annexureArr.push(annexureNA);
	  }
  
	  //Reponses
	  let reponsesArr = [];
  
	  if (!isEmptyArray(obsItem.responses)) {
		let reponsesHeading = new Paragraph({
		  style: "commonHeading",
		  children: [
			new TextRun({
			  text: "Responses",
			}),
		  ],
		});
		reponsesArr.push(reponsesHeading);
  
		obsItem.responses.forEach((responsesItem) => {
		  //Obs Responses
		  let responseText = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "Response: ",
			  }),
			  new TextRun({
				text: `${ifEmptyReturnEmptyStr(responsesItem.response, "")}`,
			  }),
			],
		  });
		  reponsesArr.push(responseText);
  
		  let targetDateReponsibilty = new Paragraph({
			style: "paraSmall",
			tabStops: [
			  {
				type: TabStopType.RIGHT,
				position: TabStopPosition.MAX,
			  },
			],
			children: [
			  new TextRun({
				text: "Target Date: ",
			  }),
			  new TextRun({
				text: `${moment(responsesItem.target).format("DD/MM/YYYY")}`,
			  }),
			  new TextRun({
				text: "\tResponsibility: ",
			  }),
			  new TextRun({
				text: `${responsesItem.firstName} ${responsesItem.lastName}`,
			  }),
			  getLineBreak(1),
			],
		  });
		  reponsesArr.push(targetDateReponsibilty);
		});
	  }
  
	  // add each observation content to docx
  
	  if (!isEmptyVariable(obsItem.reportCustomHeading)) {
		let reportHeadingArr = obsItem.reportCustomHeading.split(":--:");
		!isEmptyArray(reportHeadingArr) &&
		  reportHeadingArr.map((item) => {
			observationSectionContent.push(
			  new Paragraph({
				style: "paraSmallBold",
				children: [
				  new TextRun({
					text: `${!isEmptyVariable(item) ? removeHtmlTags(item) : ""}`,
					underline: {},
				  }),
				  getLineBreak(1),
				],
			  })
			);
		  });
	  }
  
	  // observation heading
	  if (!isEmptyVariable(obsItem.observationHeading)) {
		observationSectionContent.push(
		  new Paragraph({
			style: "paraSmallBold",
			children: [
			  new TextRun({
				text: `${
				  !isEmptyVariable(obsItem.observationHeading)
					? removeHtmlTags(obsItem.observationHeading)
					: ""
				}`,
				// text: `${obsItem.observationHeading}`,
				underline: {},
			  }),
			  getLineBreak(1),
			],
		  })
		);
	  }
	  // observation detail
	  if (!isEmptyVariable(obsItem.observationDetail)) {
		observationSectionContent.push(
		  new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: `${
				  !isEmptyVariable(obsItem.observationDetail)
					? removeHtmlTags(obsItem.observationDetail)
					: ""
				}`,
				// text: `${obsItem.observationDetail}`,
			  }),
			  getLineBreak(1),
			],
		  })
		);
	  }
	  //Instance Table
	  if (!isEmptyVariable(instanceTable)) {
		observationSectionContent.push(instanceTable);
		observationSectionContent.push(
		  new Paragraph({
			children: [
			  // getLineBreak(1)
			],
		  })
		);
	  }
	  // Business Risk
	  if (
		!isEmptyVariable(obsItem.businessRisk) &&
		isJsonString(obsItem.businessRisk)
	  ) {
		let selectedRiskIdArr = JSON.parse(obsItem.businessRisk).map(
		  (item) => item.split(":-:")[1]
		);
		const riskHeading = new Paragraph({
		  style: "commonHeading",
		  text: `Risk/Impact:`,
		});
		observationSectionContent.push(riskHeading);
  
		selectedRiskIdArr.forEach((riskIem) => {
		  const riskName = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: `${
				  !isEmptyVariable(riskIem) ? removeHtmlTags(riskIem) : ""
				}`,
			  }),
			],
		  });
		  observationSectionContent.push(riskName);
		});
		// observationSectionContent.push(
		// 	new Paragraph({
		// 		style: "commonHeading",
		// 		text: `Risk/Impact:`,
		// 	}),
		// 	new Paragraph({
		// 		style: "paraSmall",
		// 		children: [
		// 			new TextRun({
		// 				// text: `${ifEmptyReturnEmptyStr(obsItem.businessRisk, "NA")}`,
		// 				text: `${!isEmptyVariable(obsItem.businessRisk) ? removeHtmlTags(obsItem.businessRisk) : ""}`,
		// 			}),
		// 			getLineBreak(1)
		// 		]
		// 	})
		// );
  
		observationSectionContent.push(
		  new Paragraph({
			children: [
			  // getLineBreak(1)
			],
		  })
		);
	  }
	  // Suggestions
	  if (!isEmptyVariable(obsItem.suggestion)) {
		observationSectionContent.push(
		  new Paragraph({
			style: "commonHeading",
			text: `Suggestions`,
		  }),
		  new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: `${
				  !isEmptyVariable(obsItem.suggestion)
					? removeHtmlTags(obsItem.suggestion)
					: ""
				}`,
			  }),
			  getLineBreak(1),
			],
		  })
		);
	  }
	  // Responses
	  if (!isEmptyArray(reponsesArr)) {
		observationSectionContent.push(...reponsesArr);
		observationSectionContent.push(
		  new Paragraph({
			children: [getLineBreak(1)],
		  })
		);
	  }
	  // New line to mark end of obs
	  observationSectionContent.push(
		new Paragraph({
		  children: [getLineBreak(1)],
		})
	  );
  
	  // // New page
	  // observationSectionContent.push(
	  // 	new Paragraph({
	  // 		children: [new PageBreak()],
	  // 	})
	  // );
	});
  
	return observationSectionContent;
  };
  
  export const getSecondFormatHeaderContentOLD = (
	clientNameTemp,
	typeOfAuditTemp
  ) => {
	let clientName = ifEmptyReturnEmptyStr(clientNameTemp, "");
	let typeOfAudit = ifEmptyReturnEmptyStr(typeOfAuditTemp, "");
  
	return {
	  default: new Header({
		children: [
		  new Paragraph({
			children: [
			  new TextRun({
				text: `${clientName}`,
			  }),
			],
			alignment: AlignmentType.LEFT,
		  }),
		  new Paragraph({
			children: [
			  new TextRun({
				text: `${typeOfAudit}`,
				break: 1,
			  }),
			],
			alignment: AlignmentType.CENTER,
			thematicBreak: true,
		  }),
		],
	  }),
	  first: new Header({
		children: [
		  new Paragraph({
			tabStops: [
			  {
				type: TabStopType.RIGHT,
				position: TabStopPosition.MAX,
			  },
			],
			children: [
			  new TextRun({
				text: `${clientName}`,
			  }),
			  new TextRun({
				text: `\t${typeOfAudit}`,
			  }),
			],
			thematicBreak: true,
		  }),
		],
	  }),
	};
  };
  
  export const getSecondFormatObservationsContentOLD = (observationsTemp) => {
	let observationSectionContent = [];
	let observationSectionTable = [];
	let observationRows = [];
  
	const obsHeading = new Paragraph({
	  style: "pageHeading",
	  pageBreakBefore: true,
	  children: [
		new TextRun({
		  text: "Observations",
		  underline: {},
		}),
		getLineBreak(1),
	  ],
	});
  
	observationSectionContent.push(obsHeading);
  
	// console.log("Obs -",observationsTemp)
	// observationsTemp.forEach((item) => {
	// 	item.obsList.forEach((obsItem,obsItemIndex) => {
	observationsTemp.forEach((obsItem, obsItemIndex) => {
	  let instanceTableHeading = [];
	  let instanceTableValuesArr = [];
	  let instanceTable = [];
	  console.log("OBS ITEM:" + JSON.stringify(obsItem));
	  let heading = getReportCustomHeadingArray(obsItem.reportCustomHeading);
  
	  //instance table
	  if (
		!isEmptyArray(obsItem.instanceColumns) &&
		!isEmptyArray(obsItem.instanceValues)
	  ) {
		let columnArray = [];
		obsItem.instanceColumns.forEach((instanceColItem) => {
		  for (var i = 1; i <= 10; i++) {
			if (!isEmptyVariable(instanceColItem["column" + i])) {
			  columnArray.push(instanceColItem["column" + i]);
			}
		  }
		});
		let rowArray = [];
		rowArray = obsItem.instanceValues.map((instanceValItem) => {
		  let singleRow = [];
		  for (var i = 1; i <= 10; i++) {
			if (!isEmptyVariable(instanceValItem["value" + i])) {
			  singleRow.push(instanceValItem["value" + i]);
			}
		  }
		  return singleRow;
		});
  
		// add column array as Instance table heading
		columnArray.forEach((instanceColItem) => {
		  const instanceHeadingCell = new TableCell({
			children: [
			  new Paragraph({
				text: `${ifEmptyReturnEmptyStr(instanceColItem, "-")}`,
				style: "tableHeading",
			  }),
			],
			verticalAlign: VerticalAlign.CENTER,
		  });
		  instanceTableHeading.push(instanceHeadingCell);
		});
  
		// add row array as Instance table values
		rowArray.forEach((instanceValueArrItem) => {
		  let instanceValueSingleRow = [];
		  instanceValueArrItem.forEach((instanceValueItem) => {
			const instanceValuesCell = new TableCell({
			  children: [
				new Paragraph({
				  text: `${ifEmptyReturnEmptyStr(instanceValueItem, "-")}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			});
  
			instanceValueSingleRow.push(instanceValuesCell);
		  });
		  const instanceRow = new TableRow({
			children: instanceValueSingleRow,
		  });
  
		  instanceTableValuesArr.push(instanceRow);
		});
		//instance table for docx
		instanceTable.push(
		  new Table({
			width: {
			  size: 100,
			  type: WidthType.PERCENTAGE,
			},
			margins: getTableCellMargin(),
			rows: [
			  new TableRow({
				children: instanceTableHeading,
			  }),
			  ...instanceTableValuesArr,
			],
		  })
		);
  
		instanceTable.push(
		  new Paragraph({
			children: [
			  // getLineBreak(1)
			],
		  })
		);
	  }
  
	  //Annexures
	  let annexureArr = [];
	  let annexureHeading = new Paragraph({
		style: "commonHeading",
		text: "Annexure",
	  });
	  annexureArr.push(annexureHeading);
  
	  if (!isEmptyArray(obsItem.annexures)) {
		obsItem.annexures.forEach((annexureItem) => {
		  let docPath = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "FileName: ",
			  }),
			  new TextRun({
				text: `${ifEmptyReturnEmptyStr(annexureItem.documentPath, "-")}`,
			  }),
			],
		  });
		  annexureArr.push(docPath);
  
		  if (!isEmptyVariable(annexureItem.remark)) {
			let remarks = new Paragraph({
			  style: "paraSmall",
			  children: [
				new TextRun({
				  text: "Remark: ",
				}),
				new TextRun({
				  text: `${ifEmptyReturnEmptyStr(annexureItem.remark, "-")}`,
				}),
			  ],
			});
			annexureArr.push(remarks);
		  }
		});
	  } else {
		let annexureNA = new Paragraph({
		  style: "paraSmall",
		  children: [
			new TextRun({
			  text: "-",
			}),
		  ],
		});
		annexureArr.push(annexureNA);
	  }
  
	  //Reponses
	  let reponsesArr = [];
  
	  if (!isEmptyArray(obsItem.responses)) {
		obsItem.responses.forEach((responsesItem) => {
		  //Obs Responses
		  let responseText = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "Response: ",
			  }),
			  new TextRun({
				text: `${ifEmptyReturnEmptyStr(responsesItem.response, "-")}`,
			  }),
			],
		  });
		  reponsesArr.push(responseText);
  
		  let targetDate = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "Target Date: ",
			  }),
			  new TextRun({
				text: `${moment(responsesItem.target).format("DD/MM/YYYY")}`,
			  }),
			],
		  });
		  reponsesArr.push(targetDate);
  
		  let responsibility = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: "Responsibility: ",
			  }),
			  new TextRun({
				text: `${responsesItem.firstName} ${responsesItem.lastName}`,
			  }),
			  getLineBreak(1),
			],
		  });
		  reponsesArr.push(responsibility);
		});
	  }
	  // else {
	  // 	let reponseNA = new Paragraph({
	  // 		style: "paraSmall",
	  // 		children: [
	  // 			new TextRun({
	  // 				text: "NA",
	  // 			}),
	  // 		],
	  // 	});
	  // 	reponsesArr.push(reponseNA);
	  // }
	  let businessRiskContent = [];
  
	  if (
		!isEmptyVariable(obsItem.businessRisk) &&
		isJsonString(obsItem.businessRisk)
	  ) {
		// businessRiskContent.push(
		// 	new Paragraph({
		// 		style: "commonHeading",
		// 		text: `Risk/Impact:`,
		// 	}),
		// 	new Paragraph({
		// 		style: "paraSmall",
		// 		children: [
		// 			new TextRun({
		// 				// text: `${ifEmptyReturnEmptyStr(obsItem.businessRisk, "NA")}`,
		// 				text: `${!isEmptyVariable(obsItem.businessRisk) ? removeHtmlTags(obsItem.businessRisk) : ""}`,
  
		// 			}),
		// 			getLineBreak(1)
		// 		],
		// 	})
		// );
  
		let selectedRiskIdArr = JSON.parse(obsItem.businessRisk).map(
		  (item) => item.split(":-:")[1]
		);
		const riskHeading = new Paragraph({
		  style: "commonHeading",
		  text: `Risk/Impact:`,
		});
		businessRiskContent.push(riskHeading);
  
		selectedRiskIdArr.forEach((riskIem) => {
		  const riskName = new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: `${
				  !isEmptyVariable(riskIem) ? removeHtmlTags(riskIem) : ""
				}`,
			  }),
			],
		  });
		  businessRiskContent.push(riskName);
		});
		businessRiskContent.push(
		  new Paragraph({
			children: [
			  // getLineBreak(1)
			],
		  })
		);
	  }
  
	  let suggestionsContent = [];
	  if (!isEmptyVariable(obsItem.suggestion)) {
		suggestionsContent.push(
		  new Paragraph({
			style: "commonHeading",
			text: `Suggestions`,
		  }),
		  new Paragraph({
			style: "paraSmall",
			children: [
			  new TextRun({
				text: `${
				  !isEmptyVariable(obsItem.suggestion)
					? removeHtmlTags(obsItem.suggestion)
					: ""
				}`,
			  }),
			  getLineBreak(1),
			],
		  })
		);
	  }
  
	  // add each observation content to docx table
	  observationRows.push(
		new TableRow({
		  cantSplit: true,
		  children: [
			//index cell
			new TableCell({
			  children: [
				new Paragraph({
				  text: `${obsItemIndex + 1}`,
				  style: "paraSmall",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			  margins: getTableCellMargin(),
			}),
			// observation details cell
  
			new TableCell({
			  margins: getTableCellMargin(),
			  children: [
				...heading,
				new Paragraph({
				  style: "paraSmallBold",
				  children: [
					new TextRun({
					  text: `${
						!isEmptyVariable(obsItem.observationHeading)
						  ? removeHtmlTags(obsItem.observationHeading)
						  : ""
					  }`,
					  // text: `${obsItem.observationHeading}`,
					}),
					getLineBreak(1),
				  ],
				}),
				new Paragraph({
				  style: "paraSmall",
				  children: [
					new TextRun({
					  text: `${
						!isEmptyVariable(obsItem.observationDetail)
						  ? removeHtmlTags(obsItem.observationDetail)
						  : ""
					  }`,
					  // text: `${obsItem.observationDetail}`,
					}),
					getLineBreak(1),
				  ],
				}),
  
				...instanceTable,
				...businessRiskContent,
				...suggestionsContent,
  
				// ...annexureArr,
			  ],
			}),
			// responses cell
			new TableCell({
			  margins: getTableCellMargin(),
			  children: [...reponsesArr],
			}),
		  ],
		})
	  );
	});
	// });
  
	observationSectionTable = new Table({
	  width: {
		size: 100,
		type: WidthType.PERCENTAGE,
	  },
	  pageBreakBefore: true,
	  columnWidths: [10, 55, 45],
	  rows: [
		new TableRow({
		  children: [
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Sl. No.",
				  style: "tableHeading",
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Observations",
				  style: "tableHeading",
				  alignment: AlignmentType.CENTER,
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
			new TableCell({
			  children: [
				new Paragraph({
				  text: "Responses",
				  style: "tableHeading",
				  alignment: AlignmentType.CENTER,
				}),
			  ],
			  verticalAlign: VerticalAlign.CENTER,
			}),
		  ],
		}),
		...observationRows,
	  ],
	});
	observationSectionContent.push(observationSectionTable);
  
	return observationSectionContent;
  };
  
  export const getReportCustomHeadingArray = (customReportHeading) => {
	let tableParaArr = [];
  
	if (!isEmptyVariable(customReportHeading)) {
	  let arr = customReportHeading.split(":--:");
  
	  !isEmptyArray(arr) &&
		arr.map((item) => {
		  // console.log("ITEM:"+item);
		  let para = new Paragraph({
			style: "paraSmallBold",
			children: [
			  new TextRun({
				text: `${!isEmptyVariable(item) ? removeHtmlTags(item) : ""}`,
				underline: {},
			  }),
			  getLineBreak(1),
			],
		  });
  
		  tableParaArr.push(para);
		});
	}
  
	if (isEmptyArray(tableParaArr)) {
	  let paraEmpty = new Paragraph({
		style: "paraSmallBold",
		children: [],
	  });
	  tableParaArr.push(paraEmpty);
	}
  
	return tableParaArr;
  };
  