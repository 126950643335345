import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import { matchPath, withRouter } from 'react-router-dom';
import { isEmptyVariable,getThemeSettingsVariables } from '../../../common/Global/commonFunctions';
import { TopTabsLayout } from '../../../common/Global/globalStyles.style';

let isTask ='';
let isExecuteTask ='';
let isObservation =''
let isOverall =''
let isFollowUp =''
let isAuditeeComment = '';
let themeColor = ``;
class MasterTopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        isTask = this.checkActivePath(["/tasktemplates","/edittasktemplate","/tasktemplatedetails"]);
        isExecuteTask = this.checkActivePath([ "/executetasktemplates","/addexectasktemplate","/editexectasktemplate","/exectasktemplatedetails"]);
        isObservation = this.checkActivePath([ "/observationtemplates", "/addobservationtemplate","/editobservationtemplate","/observationtemplatedetails" ]);
        isOverall = this.checkActivePath([ "/overalltemplates", "/editoveralltemplates","/overalltemplatesdetails" ]);
        isFollowUp = this.checkActivePath([ "/followuptemplates","/editfollowuptemplate","/followuptemplatedetails" ]);
        isAuditeeComment = this.checkActivePath([ "/auditeecommenttemplates", "/editauditeecommenttemplates","/auditeecommenttemplatesDetails" ]);
    }

    checkActivePath = (path) =>{
        const themeSettings = getThemeSettingsVariables();
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
        return matchPath(this.props.history.location.pathname, { path });
    }

    render() {
        let width = 12.5;
        if (!isEmptyVariable(this.props.masterMenus)) {
            width = 12.5 * Object.keys(this.props.masterMenus).length
        }
        // width = 12.5 * 3;

        return (
            <TopTabsLayout themeSettings={this.props.themeSettings}>

                <div className="question-tabs" style={{ marginRight: 0 }}>
                    {
                        !isEmptyVariable(this.props.masterMenus) &&
                        <ul style={{ width: width + "%" }}>
                            {
                                !isEmptyVariable(this.props.masterMenus['Task Template']) &&
                                <li className={ isTask ? "questiontab-active" : "" }>
                                    <a href={Constants.URL_TASK_TEMPLATES} 
                                        style={{color: !isTask ? themeColor : '', fontWeight: !isTask ? "bold" : "100px"}}>Task</a>
                                </li>
                            }
                            {
                                !isEmptyVariable(this.props.masterMenus['Execution Template']) &&
                                <li className={ isExecuteTask ? "questiontab-active" : "" }>
                                    <a href={Constants.URL_EXECUTE_TASK_TEMPLATES}
                                        style={{color: !isExecuteTask ? themeColor : '', fontWeight: !isExecuteTask ? "bold" : "100px"}}>Execute Task</a>
                                </li>
                            }
                            {
                                !isEmptyVariable(this.props.masterMenus['Observation Template']) &&
                                <li className={ isObservation ? "questiontab-active" : "" }>
                                    <a href={Constants.URL_OBSERVATION_TEMPLATES}
                                        style={{color: !isObservation ? themeColor : '', fontWeight: !isObservation ? "bold" : "100px"}}>Observation</a>
                                </li>
                            }
                            {
                                !isEmptyVariable(this.props.masterMenus['Assignment Level Template']) &&
                                <li className={ isOverall ? "questiontab-active" : "" }>
                                    <a href={Constants.URL_OVERALL_TEMPLATES}
                                        style={{color: !isOverall ? themeColor : '', fontWeight: !isOverall ? "bold" : "100px"}}>Overall</a>
                                </li>
                            }
                            {!isEmptyVariable(this.props.masterMenus['Response Template']) &&
                                !isEmptyVariable(this.props.masterMenus['Follow Up Template']) &&
                                <li className={ isFollowUp ? "questiontab-active" : "" }>
                                    <a href={Constants.URL_FOLLOWUP_TEMPLATES}
                                        style={{color: !isFollowUp ? themeColor : '', fontWeight: !isFollowUp ? "bold" : "100px"}}>Follow Up</a>
                                </li>
                            }
                            {
                                !isEmptyVariable(this.props.masterMenus['Response Template']) &&
                                <li className={ isAuditeeComment ? "questiontab-active" : "" }>
                                    <a href={Constants.URL_AUDITEE_COMMENT_TEMPLATES}
                                        style={{color: !isAuditeeComment ? themeColor : '', fontWeight: !isAuditeeComment ? "bold" : "100px"}}>Response</a>
                                </li>
                            }
                        </ul>
                    }
                </div>

                <div style={{ opacity: this.props.hideInfo ? "0" : "1" }} className="infocard-showhide-col">
                    {/* <div className="infocard-icon-col">
                        <img src="/assets/images/topbar-info.svg" />
                    </div> */}
                </div>
            </TopTabsLayout>
        )
    }
}

export default withRouter(MasterTopMenu);