import React, { Component } from 'react';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import ClientTabs from './topmenu';
import ListClients from './listclients';
import ClientUsers from './clientusers'
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { isEmptyVariable,getThemeSettingsVariables } from '../../common/Global/commonFunctions';

let themeSettings  = getThemeSettingsVariables();

class ClientsMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientId:"",
            clientName:"",
            displayingClients:true,
            displayingClientUsers:false,
            displayCards:true,
        }
    }

    viewClientUsersDropDownItemClick = (clientId,clientName) => {
        this.setState({
            clientId:clientId,
            clientName:clientName,
            displayingClients:false,
            displayingClientUsers:true,
        })
    }

    usersTabItemOnClick = (clientId) => {
        this.setState({
            clientId:clientId,
            displayingClients:false,
            displayingClientUsers:true,
        })
    }

    clientsTabItemOnClick = () =>{
        this.setState({
            displayingClients:true,
            displayingClientUsers:false,
        })
    }  
    
    toggleCardsDisplay = () =>{
        this.setState({
            displayCards:!this.state.displayCards
        })
    }

    render() {
        themeSettings  = getThemeSettingsVariables();
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />

                                <ClientTabs 
                                    clientId = {this.state.clientId}
                                    displayingClients = {this.state.displayingClients}
                                    displayingClientUsers = {this.state.displayingClientUsers}
                                    usersTabItemOnClick = {this.usersTabItemOnClick}
                                    clientsTabItemOnClick = {this.clientsTabItemOnClick}
                                    toggleCardsDisplay = {this.toggleCardsDisplay}
                                    themeSettings = {themeSettings}
                                />

                                {
                                    this.state.displayingClients &&
                                    <ListClients 
                                        viewClientUsersDropDownItemClick = {this.viewClientUsersDropDownItemClick}
                                        displayCards={this.state.displayCards}
                                        themeSettings = {themeSettings}
                                    />
                                }

                                {
                                    !isEmptyVariable(this.state.clientId) &&
                                    this.state.displayingClientUsers &&
                                    <ClientUsers 
                                        clientId = {this.state.clientId}
                                        clientName = {this.state.clientName}
                                        displayCards={this.state.displayCards}
                                        themeSettings = {themeSettings}
                                    />
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </Router>
        )
    }
}

export default ClientsMain;

