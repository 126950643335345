import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {DialogSaveButton} from '../../../common/Global/globalStyles.style';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();

class AddProcessDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,

            processName:"",
            formErrors:{},
            submitClickedAtleastOnce:false,
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){
            if(this.props.isEdit){
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    processName:this.props.processName,
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }else{
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    processName:"",
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleCreateDialogClose = () =>{
        this.props.handleAddEditDialogClose(false);
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.handleCreateMaster();
        }
    }

    handleCreateMaster = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.processName)){
            isError = true;
            FormErrorsTemp['processName'] = "Please enter process name";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
            }

            if(this.props.isEdit){
                url = Constants.UpdateProcess;
                let processObj = {
                    processId:this.props.selectedId,
                    processName:this.state.processName
                }
                postParam.process = JSON.stringify(processObj);
                postParam.rootProcessId = this.props.rootProcessId;
            }else{
                postParam.departmentId = this.props.departmentId;
                if(this.props.createNodeType === "root"){
                    url = Constants.AddRootProcess;
                    let processObj = {
                        processId:0,
                        processName:this.state.processName
                    }
                    postParam.process = JSON.stringify(processObj);

                }else if(this.props.createNodeType === "child"){
                    url = Constants.AddChildProcess;
                    let processObj = {
                        processId:0,
                        processName:this.state.processName
                    }

                    postParam.childProcess = JSON.stringify(processObj);
                    postParam.parentProcessId = this.props.relativeProcessId;
                    postParam.rootProcessId = this.props.rootProcessId;
                    
                }else if(this.props.createNodeType === "sibling"){
                    url = Constants.AddSiblingProcess;

                    let processObj = {
                        processId:0,
                        processName:this.state.processName
                    }

                    postParam.siblingProcess = JSON.stringify(processObj);
                    postParam.targetProcessId = this.props.relativeProcessId;
                    postParam.parentProcessId = this.props.relativeParentProcessId;
                    postParam.rootProcessId = this.props.rootProcessId;
                }
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    let createNodeTypeTemp = this.props.createNodeType;

                    if(createNodeTypeTemp === "root"){
                        this.setState({
                            showLoader:false,
                            processName:"",
                            formErrors:{},
                        },() => {
                            this.props.handleAddEditDialogClose(true);
                        });
                    }else if(createNodeTypeTemp === "child" ||
                    createNodeTypeTemp === "sibling"){
                        this.setState({
                            showLoader:false,
                            processName:"",
                            formErrors:{},
                        },() => {
                            this.props.handleAddEditDialogClose(true);
                        });
                    }
                }else{
                    toast.error(data.responseMessage);
                    // FormErrorsTemp['apiErr'] = data.responseMessage;
                    this.setState({
                        // formErrors:FormErrorsTemp,
                        showLoader:false
                    });
                }
            });
        }
    }

    render() {
        return(
            <Modal className="custom-dialog" show={this.props.showCreateDialog} backdrop="static">
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit Process":"Add Process"}</h5>
                    <button 
                        onClick={this.handleCreateDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div class="modal-body">
                    {
                        this.state.showLoader &&
                        <div className="modal-loading-text">
                            <p
                            style={{
                                background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                            }}
                            >Loading....</p>
                        </div>
                    }
                    <div class="addnew-modal-form">
                        <div class="addnew-modal-form-group">
                            <p>Process Name*</p>
                            <input type="text" placeholder="Enter Process Name" onKeyPress={this.onEnterBtnPress} spellCheck="true" 
                            name="processName" value={this.state.processName} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['processName']) &&
                                <span class="cm-error">{this.state.formErrors['processName']}</span>
                            }
                            {/* {
                                !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                <span class="cm-error">{this.state.formErrors['apiErr']}</span>
                            } */}
                        </div>
                    </div>
                    <div class="modal-close-save">
                        <div class="close-save-col">
                            <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                            <DialogSaveButton 
                            themeSettings={this.props.themeSettings}
                            onClick={this.handleCreateMaster} type="button" class="modal-save">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default AddProcessDialog;