import React, { Component } from "react";
import * as Constants from "../Global/constants";
import {   } from "../Global/globalStyles.style";
import {
	getLocalStorageVariables,
	isEmptyArray,
	isEmptyVariable,
} from "../Global/commonFunctions";
import Select, { components } from "react-select";


const userDetails = getLocalStorageVariables();

class ReactSelectDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			datalist: [],

		};
	}

	componentDidMount() {	
		let datalist = [];
		if(!isEmptyVariable(this.props.selectedDDObj)){
			datalist = this.props.selectedDDObj;
		}
		this.setState({
			componentDidMountFlag:false,
			showLoader:false,
			datalist:datalist,
		})
	}

	componentDidUpdate(prevProps)
	{
		if(prevProps.selectedDDObj!==this.props.selectedDDObj)
		{
			let datalist = [];
			datalist = this.props.selectedDDObj;
		this.setState({
			componentDidMountFlag:false,
			showLoader:false,
			datalist:datalist,
		})

		}
	}
	

	// handleDialogClose = () => {
	// 	this.props.handleReactSelectClose(this.state.datalist);
	// };

	handleOnChangeSelectedOption = (newValueObj, actionMeta) => {
		// if (actionMeta.action === "select-option") {
		// 	
		// } else if (actionMeta.action === "create-option") {
		// 	
		// } else if (actionMeta.action === "remove-value") {
		// 	
		// }

		this.setState(
			{
				datalist: newValueObj,
			},()=>{
				this.props.handleReactSelectClose(this.state.datalist);
			
					
				
			});
	};

	render() {
		return (
			<div >
			 <Select
					value={this.state.datalist}
					onChange={this.handleOnChangeSelectedOption}
					options={this.props.selectDropdownArr}
					placeholder={(!isEmptyVariable(this.props.placeholder) || !isEmptyVariable(this.state.datalist))?this.props.placeholder:"Please select"}
					getOptionLabel={(option) => option[this.props.label]}
					getOptionValue={(option) => option[!isEmptyVariable(this.props.value)?this.props.value:this.props.label]}	
					isDisabled={!isEmptyVariable(this.props.disabled) && this.props.disabled?true:false}
					isMulti={!isEmptyVariable(this.props.isMultiselect) && this.props.isMultiselect?true:false}
					search ={true}
					isClearable={!isEmptyVariable(this.props.isClearSelect) && this.props.isClearSelect?true:false}
				/>
			</div>
		);
	}
}

export default ReactSelectDropdown;
