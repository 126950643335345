import { AlignmentType, VerticalAlign, Table, TableRow, TableCell,
   Paragraph, WidthType, BorderStyle, TextRun, ImageRun } from "docx";
import { isEmptyVariable } from "../../../common/Global/commonFunctions";

let fontName = "Times New Roman";
const indexData=["General Information","Internal Audit Plan","Internal Audit Summary Report","Audit Conclusion","Processes Audited","Nonconformity Report"]

export const getIndexPageContent = (dataList,companyLogo,isLogoAvailable) => {
  let indexPageContent = [];

  let rowsdata =[]
  let commonBorderStyle={ style: BorderStyle.SINGLE, color: "000000", size: 3 }

// logo and Index Row
  rowsdata.push(
    new TableRow({
      children: [
        new TableCell({
          borders:{
            bottom:commonBorderStyle
          },  
          margins: {
            top: 100,
            bottom: 100,
            left: 50,
          },
          width: {
            type: WidthType.DXA,
            size: 2000,
          },

          children: [
            new Paragraph({
              children: [
                !isEmptyVariable(isLogoAvailable) &&
                new ImageRun({
                  data: companyLogo,
                  transformation: {
                    width: 100,
                    height: 30,
                  },
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign:VerticalAlign.CENTER,
          borders:{
            bottom:commonBorderStyle
          },
          margins: {
            top: 100,
            bottom: 100,
          },
          width: {
            type: WidthType.DXA,
            size: 2600,
          },
          
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Index",
                  allCaps: true,
                  font: fontName,
                  size: 30,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
      ],
    }),
  )
  
  // All Index Display
  indexData.map((item,idx)=>{
   
    rowsdata.push(
      new TableRow({
        children: [
          new TableCell({
            columnSpan: 12,
            margins: {
              left: 300,
            },
            children: [
              new Paragraph({
                spacing: {
                  before: 100,
                  after: 50,
                },
                children: [
                  new TextRun({
                    text: `${idx+1}.`,
                    font:fontName,
                    size:20
                  }),
                  new TextRun({
                    text: ` ${item}`,
                    // underline: idx!==4? true:false,
                    // color:idx!==4?"0000FF":"000000",
                    font:fontName,
                    size:20
                  }),
                ],
              }),
            ],
          }),
        ],
      })
    )

    if(idx === 4)
    {
      Object.values(dataList).map((item,idx)=>{
        rowsdata.push(
          new TableRow({
            children: [
              new TableCell({
                columnSpan: 12,
                margins: {
                  left: 600,
                },
                children: [
                  new Paragraph({
                    spacing: {
                      before: 100,
                      after: 50,
                    },
                    children: [
                      new TextRun({
                        text: (convertToRoman(idx+1)).toLocaleLowerCase()+".",
                        font:fontName,
                        size:20
                      }),
                      new TextRun({
                        text: ` ${item.functionName}`,
                        // underline:true,
                        // color:"0000FF",
                        font:fontName,
                        size:20
                      }),
                    ],
                  }),
                ],
              }),
            ],
          })
    
        )
      })
    }
  })

  console.log("run this func..")

  indexPageContent.push(
    new Table({
      margins:{
        bottom:100
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        bottom:{style:BorderStyle.NONE},
        left:{style:BorderStyle.NONE},
        top:{style:BorderStyle.NONE},
        right:{style:BorderStyle.NONE},

       
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },
      rows:rowsdata
    })
  );


  return [...indexPageContent];
};


export const convertToRoman=(num)=>{
  var roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  var str = '';

  for (var i of Object.keys(roman)) {
    var q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }

  return str;
}