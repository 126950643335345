import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,getThemeSettingsVariables,removeHtmlTags,ifEmptyReturn,
    isEmptyVariable, isEmptyArray, isJsonString} from '../../../common/Global/commonFunctions';


export const getCheckListScoresFlag = (checkListType) => {
    let flag = false;
    if(checkListType === "Y/N" || checkListType === "WR" || checkListType === "R"){
        flag = true;
    }

    return flag;
}

export const getOnlyMyTasks = (allDataList,assignmentArea,maxRank) => {
    const userDetails  = getLocalStorageVariables();
    return allDataList.map(process=>{
        let taskList = [];
        let maxScore = 0;
        let totalScore = 0;
        process.taskList.forEach(task=>{
            if(task.userId === userDetails.userId){
                taskList.push(task);

                //Now if it is checklist, then based on the type of checklist we need to assign scores
                if(assignmentArea === "CheckList"){
                    let weight = isEmptyVariable(task.weight)?1:task.weight;
                    let rank = isEmptyVariable(task.rank) || task.rank === -1
                                ?0:task.rank;

                    //if rank is NA then that should be considered for max score also
                    let maxRankNew = task.rank === -1?0:maxRank;
                    maxScore += weight*maxRankNew;
                    totalScore += weight*rank;
                }
            }
        })
        process.taskList = taskList;
        process.maxScore = maxScore;
        process.totalScore = totalScore;

        return process;
    })
}

export const getOnlyMyObservations = (allDataList) => {
    const userDetails  = getLocalStorageVariables();
    return allDataList.map(observation=>{
        let observationList = [];
        observation.obsList.forEach(item=>{
            if(item.userId === userDetails.userId){
                observationList.push(item);
            }
        })
        observation.obsList = observationList;
        return observation;
    })
}

export const getAllReportTasks = (allDataList,assignmentArea,maxRank) => {
    const userDetails  = getLocalStorageVariables();

    return allDataList.map(process=>{
        let taskList = [];
        let maxScore = 0;
        let totalScore = 0;
        process.taskList.forEach(task=>{
            task.isCheckboxSelected = false;
            task.isResourceCheckboxSelected = false;
            taskList.push(task);

            //Now if it is checklist, then based on the type of checklist we need to assign scores
            if(assignmentArea === "CheckList"){
                let weight = isEmptyVariable(task.weight)?1:task.weight;
                let rank = isEmptyVariable(task.rank) || task.rank === -1
                            ?0:task.rank;

                //if rank is NA then that should be considered for max score also
                let maxRankNew = task.rank === -1?0:maxRank;
                maxScore += weight*maxRankNew;
                totalScore += weight*rank;
            }
        })
        process.taskList = taskList;
        process.maxScore = maxScore;
        process.totalScore = totalScore;

        return process;
    })
}

export const getMatrixandMyDetails = (assignmentMatrixObj) => {
    let myPosition = "";
    let selectedResourceUserName = "";
    let assignmentMatrix = [];
    let assignmentViewMatrix = [];
    const userDetails  = getLocalStorageVariables();

    let membersStr = assignmentMatrixObj.memberList;
    if(!isEmptyVariable(membersStr)){
        let membersArrTemp = membersStr.split(",")
        
        assignmentMatrix =  membersArrTemp.map(member=>{
            let memberTemp = member.split(":");

            if(memberTemp[0] == userDetails.userId){
                myPosition = "Team Member";
                selectedResourceUserName = memberTemp[1];
                // selectedResourceUserName = memberTemp[1]+", "+memberTemp[2]+", Team Member";
            }

            let obj = {
                id:memberTemp[0],
                label:memberTemp[1],
                position:"Team Member",
                designation:memberTemp[2]
                // label:memberTemp[1]+", "+memberTemp[2]+", Team Member",
            }
            return obj;
        })
    }

    let viewersStr = assignmentMatrixObj.viewerList;
    if(!isEmptyVariable(viewersStr)){
        let viewersArrTemp = viewersStr.split(",")
        
        assignmentViewMatrix =  viewersArrTemp.map(member=>{
            let memberTemp = member.split(":");
            if(memberTemp[0] == userDetails.userId){
                myPosition = "Viewer";
                selectedResourceUserName = memberTemp[1];
                // selectedResourceUserName = memberTemp[1]+", "+memberTemp[2]+", Team Member";
            }

            let obj = {
                id:memberTemp[0],
                label:memberTemp[1],
                position:"Viewer",
                designation:memberTemp[2]
                // label:memberTemp[1]+", "+memberTemp[2]+", Team Member",
            }
            return obj;
        })
    }

    //Now reviewer lists
    for(var i=1;i<7;i++){
        if(!isEmptyVariable(assignmentMatrixObj["L"+i+"UserId"])){
            if(assignmentMatrixObj["L"+i+"UserId"] === userDetails.userId){
                myPosition = "Reviewer"
                selectedResourceUserName = assignmentMatrixObj["L"+i+"UserName"];
                // selectedResourceUserName = assignmentMatrixObj["L"+i+"UserName"]+", "+assignmentMatrixObj["L"+i+"Designation"]+", Review Level "+i
            }

            let obj = {
                id:assignmentMatrixObj["L"+i+"UserId"],
                label:assignmentMatrixObj["L"+i+"UserName"],
                position:"Review Level "+i,
                designation:assignmentMatrixObj["L"+i+"Designation"]
                // label:assignmentMatrixObj["L"+i+"UserName"]+", "+assignmentMatrixObj["L"+i+"Designation"]+", Review Level "+i
            }

            assignmentMatrix.push(obj);
        }
    }

    return {
        myPosition:myPosition,
        selectedResourceUserName:selectedResourceUserName,
        assignmentMatrix:assignmentMatrix,
        assignmentViewMatrix:assignmentViewMatrix,
    }
}

export const getRankingArray = (minRank,maxRank) => {
    let rankingArray = [];
    for(let i=minRank;i<=maxRank; i++){
        rankingArray.push({
            label:i,
            id:i,
        })
    }

    rankingArray.push({
        label:"N/A",
        id:-1,
    })

    return rankingArray;
}