import React, { Component } from 'react'
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {DialogSaveButton,TreeNodeCheckBox, ExpandCollapseWrapper} from '../../../common/Global/globalStyles.style';
import { toast } from 'react-toastify';
let userDetails  = getLocalStorageVariables();


const UnitGropuingTree = (props) => {
  return (
    <div className="dialog-tree-structure">
        <div className="tree-node checkboxparent" style={{marginLeft:(props.level*40)+"px"}}>
            <span 
                onClick={()=>props.onClickNode(props.unitGroupingObj)}
                class="material-icons" style={{opacity:isEmptyArray(props.unitGroupingObj.children)?"0":"1"}}>
                {props.unitGroupingObj.isShowingChildren?"keyboard_arrow_down":"keyboard_arrow_right"}
            </span>
            <TreeNodeCheckBox
            themeSettings={props.themeSettings}>
                <input 
                    type="checkbox"
                    name={props.unitGroupingObj.unitGroupId}
                    id={props.unitGroupingObj.unitGroupId}
                    onChange={props.handleSelection}
                    checked={props.unitGroupingObj.isSelected?true:false}
                />
                <label for={props.unitGroupingObj.unitGroupId} 
                style={{marginLeft:6}}>{props.unitGroupingObj.unitGroupName}</label>
            </TreeNodeCheckBox>
        </div>
        {
            // Exit Condition
            props.unitGroupingObj.isShowingChildren &&
            props.getChildNodes(props.unitGroupingObj).map((child, idx)=>{
                let levelTemp = props.level + 1;
                return <UnitGropuingTree 
                    unitGroupingObj = {child} 
                    getChildNodes = {props.getChildNodes}
                    level={levelTemp}
                    handleSelection={props.handleSelection}
                    onClickNode={props.onClickNode}
                    themeSettings={props.themeSettings}
                />
            })
        }
    </div>)
}

export default class SelectUnitGroupingDialog extends Component {
  constructor(props){
    super(props)
    this.state={
      unitGroupingMap:{},
      rootNodes:[],
      refreshFlag:false,
      showLoader:false,
    //   unitGroupingFormErr:"",

    }
    this.selectedUnitGroupingsObjArr = [];
  }

 componentDidUpdate(prevProps){
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
    this.props.showUnitGroupingDialog){
        this.selectedUnitGroupingsObjArr = [];
        this.setState({
            unitGroupingMap:{},
            rootNodes:[],
            // unitGroupingFormErr:"",
            refreshFlag:false,
            showLoader:false,
        },()=>{
            this.getUnitGroupingTree();
        })
    }
 }

 onClickNode = (node) => {
    if(this.state.unitGroupingMap[node.unitGroupId].isShowingChildren){
        this.state.unitGroupingMap[node.unitGroupId].isShowingChildren = false;
    }else{
        this.state.unitGroupingMap[node.unitGroupId].isShowingChildren = true;
    }
    this.setState({
        refreshFlag:!this.state.refreshFlag
    })
 }

 getChildNodes = (node) => {
    if(isEmptyArray(node.children)){
        return [];
    }else{
        return node.children.map(item => this.state.unitGroupingMap[item.unitGroupId]);
    }
 }

 handleSelection = (e) => {
    // e.stopPropagation();
    let isChecked = e.target.checked;
    let unitGroupId = e.target.name;

    this.selectUnSelectAllChildren(unitGroupId,isChecked);

    this.setState({
        refreshFlag:!this.state.refreshFlag
    });
 }

 selectUnSelectAllChildren = (unitGroupId,isChecked) => {
    this.state.unitGroupingMap[unitGroupId].isSelected = isChecked;
    //show children of checked item
    if(isChecked && !isEmptyArray(this.state.unitGroupingMap[unitGroupId].children)){
        //Dont automatically expand the children - nayans input
        // this.state.processMap[processId].isShowingChildren = true;
    }

    this.state.unitGroupingMap[unitGroupId].children.map(child => {
        this.selectUnSelectAllChildren(child.unitGroupId,isChecked);
    })
 }

 returnSelectedUnitGroupingId = () => {
  this.selectedUnitGroupingsObjArr = [];
//   let unitGroupingFormErrTemp = "";

  for (const [key, item] of Object.entries(this.state.unitGroupingMap)) {
      if(item.isSelected){
          this.selectedUnitGroupingsObjArr.push(item);
      }else{
      }
  }

  if(isEmptyArray(this.selectedUnitGroupingsObjArr)){
    // unitGroupingFormErrTemp =  "Select atleast one Unit Grouping";
    toast.warning("Select atleast one Unit Grouping");
    //   this.setState({
    //       unitGroupingFormErr:unitGroupingFormErrTemp
    //   })

    //   setTimeout(function(){
    //       this.setState({
    //         unitGroupingFormErr:"",
    //       });
    //   }.bind(this),Constants.WRNG_MSG_TIMEOUT);
  }else{
      // alert(this.selectedProcessObjArr);
      // console.log(this.selectedUnitGroupingsObjArr)
      this.props.handleSelectUnitGroupingDialogSaveClose(this.selectedUnitGroupingsObjArr);
  }
 }


 getRootNodes = (unitGroupingMap) => {
  return Object.values(unitGroupingMap).filter(node => node.level === 0)
 }

 expandCollapseAll = (unitGroupId,isChecked) => {
    this.state.unitGroupingMap[unitGroupId].isShowingChildren = isChecked;

    this.state.unitGroupingMap[unitGroupId].children.map(child => {
        this.expandCollapseAll(child.unitGroupId,isChecked);
    })

    this.setState({
        unitGroupingMap:this.state.unitGroupingMap
    })
 }

expandAll = () => {
  this.state.rootNodes.map((unitGroupingMap)=>{
      this.expandCollapseAll(unitGroupingMap.unitGroupId,true);
  })
}

collapseAll = () => {
  this.state.rootNodes.map((unitGroupingMap)=>{
      this.expandCollapseAll(unitGroupingMap.unitGroupId,false);
  })
}


   /***********************API CALLS***********************/
 getUnitGroupingTree = () => {
    userDetails  = getLocalStorageVariables();
    
    this.setState({
        showLoader:true,
    });

    fetch(Constants.GetGroupingSubtree,
    {
        method: "POST",
        mode:'cors',
        body: new URLSearchParams({
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            rootunitGroupId: this.props.rootUnitGroupId,
            newFlag: "Y",
            isShowUnitSelcted: "N",
            isClient: "N"
        })
    })
    .then(response => { return response.json(); } )
    .then(data =>
    {
        if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
            localStorage.clear();
            window.location="/";
        }else if(data.responseCode === Constants.CODE_SUCCESS){
            const unitGroupingMap = data.result.unitGroupMap;
            const rootNodes = this.getRootNodes(unitGroupingMap);

            let selectedUnitGroupingsObjArr=[]

            Object.values(this.props.selectedUnitGroupingObjArr).map((item)=>{
                unitGroupingMap[item].isSelected=true;
                selectedUnitGroupingsObjArr.push(unitGroupingMap[item])
            })
             this.selectedUnitGroupingsObjArr = selectedUnitGroupingsObjArr;

            this.setState({
                showLoader:false,
                unitGroupingMap:unitGroupingMap,
                rootNodes:rootNodes
            });
        }else{
            this.setState({
              unitGroupingMap:{},
                showLoader:false,
            });
        }
    });
 }

  render() {
    return (
      <Modal className="task-tree-dialog custom-dialog" show={this.props.showUnitGroupingDialog} backdrop="static">
            <Modal.Header>
                <h5>Unit Grouping</h5>
                <button 
                    onClick={this.props.handleSelectUnitGroupingDialogClose}
                    type="button" data-dismiss="modal">
                    <span class="material-icons">close</span>
                </button>
            </Modal.Header>
          <hr />
          <div className="modal-body">
                {
                    this.state.showLoader &&
                    <div className="modal-loading-text">
                        <p
                        style={{
                            background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                            color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                        }}
                        >Loading....</p>
                    </div>
                }
                <ExpandCollapseWrapper themeSettings={this.props.themeSettings}>
                    <div className="expand-collapse-layout"
                        onClick={this.expandAll}
                        style={{marginRight:10}}
                        type="button">
                        <span class="material-icons">unfold_more</span>
                        <p>Expand All</p>
                    </div>
                    <div
                        className="expand-collapse-layout"
                        onClick={this.collapseAll}
                        style={{marginLeft:10}}
                        type="button">
                        <span class="material-icons">unfold_less</span>
                        <p>Collapse All</p>
                    </div>
                </ExpandCollapseWrapper>
                <div className="body-wrapper">
                    <div className="tree-wrapper">
                        {
                            this.state.rootNodes.map((item)=>{
                                return item.unitGroupId==this.props.rootUnitGroupId && 
                                    <UnitGropuingTree 
                                        unitGroupingObj = {item} 
                                        getChildNodes = {this.getChildNodes}
                                        level={0}
                                        handleSelection={this.handleSelection}
                                        themeSettings = {this.props.themeSettings}
                                        onClickNode={this.onClickNode}  //EXPAND/COLLAPSE
                                    />
                            })
                        }
                        {/* {
                            !isEmptyVariable(this.state.unitGroupingFormErr) &&
                            <span className="cm-error">{this.state.unitGroupingFormErr}</span>
                        } */}
                    </div>
                    <div className="modal-close-save m-t-15">
                        <div className="close-save-col">
                            <button onClick={this.props.handleSelectUnitGroupingDialogClose} type="button" className="modal-close m-b-0">Cancel</button>
                            <DialogSaveButton  themeSettings={this.props.themeSettings} 
                            onClick={this.returnSelectedUnitGroupingId} type="button" 
                            className="modal-save m-b-0">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>
          </div>
      </Modal>
    )
  }
}
