import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout} from '../../common/Global/globalStyles.style';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import AlertDialog from '../../common/AlertDialog';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import Pagination from '../../common/Global/pagination';
import AddEmployeeDialog from './addemployeedialog';
import EmployeeDetailsDialog from './employeedetailsdialog';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {getLocalStorageVariables,isEmptyVariable,sortTableTwoStates,truncateString,
    SplitJoinString,isEmptyArray} from '../../common/Global/commonFunctions';

const userDetails  = getLocalStorageVariables();
const unitPlaceholderText = "Unit Filter";

const EditEmployee = "Edit";
const deleteEmployee = "Delete";
const reactivateEmployee = "Reactivate";

const sortFirstName = "firstName";
const sortDesignation = "designation";
const sortFunctionName = "functionList";
const sortPhone = "phoneNo";
const sortEmail = "employeeEmail";
const sortIsPOC = "isPOC";

class ListEmployees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unitId:'',
            componentDidMountFlag:false,
            apiSearchKey:"",
            companySearchKey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:sortFirstName,
            sortDir:"asc",
            status:"Active",
            statusArr:[
                {
                    label:"Active",
                    isSelected:true
                },
                {
                    label:"Inactive",
                    isSelected:false
                }
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            unitArr:[],
            selectedUnitIds:[],
            companyInfo:"",
            functionsArr:"",
            
            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            alertDialogMessage:"",

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            //Add Employee
            showAddEmployeeDialog:false,
            showEmpDetailsDialog:false,
            selectedItem:"",
            isEdit:false,
        }
    }

    componentDidMount(){
        this.getInitData();
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === deleteEmployee){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.employeeId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete employee "+dropdownItem.firstName+" "+dropdownItem.lastName+"?"
            })
        }else if(item.label === reactivateEmployee){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.employeeId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate employee "+dropdownItem.firstName+" "+dropdownItem.lastName+"?"
            })
        }else if(item.label === EditEmployee){
            this.setState({
                showAddEmployeeDialog:true,
                isEdit:true,
                selectedItem:dropdownItem
            })
        }
    }

    addNewEmployeeClick = () => {
        this.setState({
            showAddEmployeeDialog:true,
            isEdit:false,
            selectedItem:""
        })
    }

    handleAddEmployeeDialogClose = (reloadFlag) => {
        this.setState({
            selectedItem:"",
            showAddEmployeeDialog:false,
        },()=>{
            if(reloadFlag === true){
                this.getEmployeesList();
            }
        })
    }

    handleEmployeeDetailsDialogShow = (item) => {
        this.setState({
            showEmpDetailsDialog:true,
            selectedItem:item
        })
    }

    handleEmpDetailsDialogClose = () => {
        this.setState({
            showEmpDetailsDialog:false,
            selectedItem:""
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    onSearchIconClick = () => {
        this.getEmployeesList();
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.getEmployeesList();
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getEmployeesList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getEmployeesList();
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTableTwoStates(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getEmployeesList();
        })
    }
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetEmployeeCreateMasterData,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    companyId:this.props.companyId
                })
            }),
            fetch(Constants.GetAllEmployees,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    companyId:this.props.companyId,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.companySearchKey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    unitId:this.state.unitId
                })
            }),
        ])
        .then(([masterRes,employeeRes]) => {
            return Promise.all([masterRes.json(),employeeRes.json()]) 
        })
        .then(([masterRes,employeeRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;

            let unitArrTemp = [];
            let designationArrTemp = [];
            let companyInfoTemp = "";
            let functionsArr = "";
            // let unitArr = "";

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                unitArrTemp = masterRes.data.unit;
                designationArrTemp = masterRes.data.designations;
                companyInfoTemp = masterRes.data.company;
                functionsArr = masterRes.data.functions;
            }else{
                unitArrTemp = [];
                companyInfoTemp = "";
                // unitArr = "";
            }

            if(employeeRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                employeeRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(employeeRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = employeeRes.data.result;
                totalCountTemp = employeeRes.data.count;
            }else{
                datalistTemp = [];
                totalCountTemp = 0;
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                unitArr:unitArrTemp,
                designationArr:designationArrTemp,
                companyInfo:companyInfoTemp,
                functionsArr:functionsArr,
                datalist:datalistTemp,
                totalCount:totalCountTemp,
            })
        });
    }

    getEmployeesList = () => {
        this.setState({
            showLoader:true,
        });

        let params = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            companyId:this.props.companyId,
            pageNo:this.state.currentPageNo,
            search:this.state.companySearchKey,
            resultsize:this.state.resultSize,
            status:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
           
        }

        if(this.state.selectedUnitIds.length>0){
            params.unitIds = JSON.stringify(this.state.selectedUnitIds)
        }

        fetch(Constants.GetAllEmployees,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(params)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.companySearchKey,
                })
            }else{
                this.setState({
                    datalist:[],
                    totalCount:0,
                    showLoader:false,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.companySearchKey,
                })
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });
            
            fetch(Constants.GetAllEmployees,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.companySearchKey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    companyId:this.props.companyId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.companySearchKey,
                        currentPageNo:page,
                    })
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        componentDidMountFlag:true,
                        apiSearchKey:this.state.companySearchKey,
                    })
                }
            });
        }
    }

    deleteActivateEmployee = () => {
        let url = "";

        if(this.state.operationType === deleteEmployee){
            url = Constants.DeleteEmployee;
        }else if(this.state.operationType === reactivateEmployee){
            url = Constants.ReactivateEmployees;
        }

        this.setState({
            showLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                employeeId:this.state.deleteReactivateId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                },() =>{
                    this.getEmployeesList();
                })
            }else{
                this.setState({
                    showLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,

                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                });
            }
        });
    }

    addUnitSelection =(args)=>{
		let tempUnitList = this.state.selectedUnitIds;
		tempUnitList.push(args.itemData.unitId)
		this.setState({
			selectedUnitIds:tempUnitList,
			// formErrors:{}
		},()=>{
            this.getEmployeesList()
        })
	}

	removeUnitSelection = (args) =>
	{
		let tempUnitList = this.state.selectedUnitIds;
		tempUnitList = tempUnitList.filter(function(item) {
			return item != args.itemData.unitId
		})
		this.setState({
			selectedUnitIds:tempUnitList
		},()=>{
            this.getEmployeesList()
        })
	}


    render() {
        const actionArr = [
            {
                icon:"edit",
                label:EditEmployee
            },
            {
                icon:"delete",
                label:deleteEmployee
            },
        ]
        const actionArrReactivate = [
            {
                icon:"check_circle",
                label:reactivateEmployee
            },
        ]
        let fields = {text:"unitName",value:"unitId"}
        return(
            <Router>
                <section>
                    {
                        this.state.showLoader &&
                        <div className="loading-layout"
                        style={{background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},0.7)`}}>
                            <div className="loading-text-layout">
                                <p
                                style={{color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`}}
                                >Loading....</p>
                            </div>
                        </div>
                    }

                    {
                        // Need to check component did mount flag, other wise until api is called,
                        // it shows no items found layout.
                        this.state.componentDidMountFlag &&
                        <div className="body-wrapper">
                            <div className="search-addnew-bg">
                                <div className="search-addnew">
                                    <div className="search-filter-layout">
                                        <FilterDropDown
                                            placeholder={this.state.resultSizePlaceholder}
                                            dropdownArr={this.state.resultSizeArr}
                                            onDropDownItemClick={this.resultSizeDropdownClick}
                                            themeSettings={this.props.themeSettings}
                                            mleft={0}
                                        />
                                        <div className="search-col m-l-10">
                                            <input 
                                                name="companySearchKey" 
                                                type="text"
                                                onChange={this.handleChange}
                                                placeholder="Search" 
                                                onKeyPress={this.onEnterBtnPress}
                                                value={this.state.companySearchKey} />
                                            <SearchSpan 
                                            themeSettings={this.props.themeSettings} 
                                            onClick = {this.onSearchIconClick} 
                                            className="material-icons"> search </SearchSpan>
                                        </div>
                                        <FilterDropDown
                                            placeholder={this.state.statusPlaceholder}
                                            dropdownArr={this.state.statusArr}
                                            onDropDownItemClick={this.statusDropdownClick}
                                            themeSettings={this.props.themeSettings}
                                        />
                                         <div className='ml-2 mt-1' style={{width:"250px",marginRight:10}}>
                                            <MultiSelectComponent
                                                id="functionCheckbox"
                                                dataSource={this.state.unitArr}
                                                fields={fields}
                                                placeholder={unitPlaceholderText} 
                                                mode="CheckBox"
                                                closePopupOnSelect={false}
                                                allowFiltering={true}
                                                select={this.addUnitSelection}
                                                removed={this.removeUnitSelection}
                                                cssClass="clientsChecklist"
                                                value={this.state.selectedUnitIds}
                                                showDropDownIcon={true}
                                                showClearButton={false}
                                            >
                                                <Inject services={[CheckBoxSelection]} />
                                            </MultiSelectComponent> 
                                        </div>
                                        
                                    </div>
                                    <AddNewButtonLayout themeSettings={this.props.themeSettings}>
                                    <a  href="javascript:void(0);" onClick={this.addNewEmployeeClick}>
                                        <span className="material-icons">add_circle</span>
                                        <p>Add Employee</p>
                                    </a>
                                    </AddNewButtonLayout>
                                </div>
                            </div>
                            <div>
                                <h6 className="small-h6">{this.props.empHeaderText}</h6>
                            </div>
                            {
                                !(isEmptyArray(this.state.datalist)) &&
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                <th className="text-center" width={"5%"}>No.</th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortFirstName)}>
                                                    <div className="sort-header">Name
                                                        <span className={(this.state.sort === sortFirstName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDesignation)}>
                                                    <div className="sort-header">Designation
                                                        <span className={(this.state.sort === sortDesignation)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortFunctionName)}>
                                                    <div className="sort-header">Units
                                                        <span className={(this.state.sort === sortFunctionName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortEmail)}>
                                                    <div className="sort-header">Email
                                                        <span className={(this.state.sort === sortEmail)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                {/* <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortIsPOC)}>
                                                    <div className="sort-header">Is POC
                                                        <span className={(this.state.sort === sortIsPOC)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th> */}
                                                <th className="text-center"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !isEmptyArray(this.state.datalist) && 
                                                    this.state.datalist.map((item,idx) => {
                                                        let employeeName = `${isEmptyVariable(item.salutation)?"":item.salutation} ${isEmptyVariable(item.firstName)?"":item.firstName} ${isEmptyVariable(item.lastName)?"":item.lastName}`
                                                        return <tr onClick={this.handleEmployeeDetailsDialogShow.bind(this,item)}>
                                                            <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                            <td>{employeeName}</td>
                                                            <td>{item.designation}</td>
                                                            <td>{isEmptyArray(item.units)?"":item.units.map(e=>e.unitName).join(", ") ||''}</td>
                                                            <td>{item.employeeEmail}</td>
                                                            {/* <td>{item.isPOC === "Y"?"Yes":"No"}</td> */}
                                                            
                                                            <td className="text-center">
                                                                <TableDropDown 
                                                                    actionArr={item.status === "Active"?actionArr:actionArrReactivate}
                                                                    onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                    dropDownId = {item}
                                                                    dropdownWidth = {"140px"}
                                                                    themeSettings={this.props.themeSettings}
                                                                />
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                
                                    </div>
                                </div>
                            }
                            {
                                this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && 
                                <div class="no-items-layout">
                                    <div class="no-items-card">
                                        <h6>
                                        {
                                            isEmptyVariable(this.state.apiSearchKey)?
                                            Constants.NO_RECORDS_WARNING
                                            :
                                            Constants.EMPTY_SEARCH_WARNING
                                        }
                                        </h6>
                                    </div>
                                </div>
                            }
                            <div className="pagination-layout">
                                <div className="row custom-row">
                                    <div className="col-md-9 custom-col">
                                    {
                                        (this.state.totalCount > this.state.datalist.length) && 
                                        <Pagination 
                                            totalLength ={this.state.totalCount} 
                                            items={this.state.datalist} 
                                            onChangePage={this.onChangePage} 
                                            pageSize={this.state.resultSize}
                                            currentPageNo = {this.state.currentPageNo}
                                            initialPage={this.state.currentPageNo}
                                            themeSettings={this.props.themeSettings} />
                                    }
                                    </div>
                                    <div className="col-md-3 custom-col">
                                        <p>{"Total Records: "+this.state.totalCount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <AlertDialog
                        showAlertDialog={this.state.showAlertDialog}
                        handleAlertDialogClose={this.handleAlertDialogClose}
                        type={
                            (this.state.operationType === deleteEmployee)
                            ?
                            Constants.ALERT_TYPE_WARNING
                            :
                            Constants.ALERT_TYPE_INFO
                        }
                        alertDialogHeading={
                            (this.state.operationType === deleteEmployee)
                            ?
                            "Delete Unit"
                            :
                            "Reactivate Unit"
                        }
                        alertDialogMessage={this.state.alertDialogMessage}
                        proceedBtnClick={this.deleteActivateEmployee}
                        proceedBtnLabel={
                            (this.state.operationType === deleteEmployee)
                            ?
                            "Delete"
                            :
                            "Reactivate"
                        }
                        themeSettings={this.props.themeSettings}
                    />

                    <AlertDialog 
                        showAlertDialog={this.state.showAlertDialogInfo}
                        handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                        type= {Constants.ALERT_TYPE_ALERT}
                        alertDialogMessage={this.state.alertDialogMessageInfo}
                        proceedBtnClick={this.handleAlertDialogCloseInfo}
                        proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                        themeSettings={this.props.themeSettings}
                    />

                    <AddEmployeeDialog 
                        isEdit = {this.state.isEdit}
                        selectedItem = {this.state.selectedItem}
                        showAddEmployeeDialog = {this.state.showAddEmployeeDialog}
                        handleAddEmployeeDialogClose = {this.handleAddEmployeeDialogClose}
                        unitId = {this.state.unitId}
                        unitArr ={this.state.unitArr}
                        designationArr ={this.state.designationArr}
                        themeSettings={this.props.themeSettings}
                        isShowUnit={"Y"} 
                    />

                    <EmployeeDetailsDialog
                        employeeData = {this.state.selectedItem}
                        showEmpDetailsDialog = {this.state.showEmpDetailsDialog}
                        handleEmpDetailsDialogClose = {this.handleEmpDetailsDialogClose}
                    />
                </section>
            </Router>
        );
    }
   
}

export default ListEmployees;