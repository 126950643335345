import React, { Component } from 'react';
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable,
    GetOrderedMasterTabMenuTask} from '../../common/Global/commonFunctions';
import * as Constants from '../../common/Global/constants';
import MasterMenuTabs from '../Tasks/topmenu';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import ListChecklist from './listChecklist';
import AddNewChecklist from './addNewChecklist';
import AddRemoveTasksProcess from './ProcessFunctionTaskList/processTaskList';
import AddRemoveTasksFunction from './ProcessFunctionTaskList/functionTaskList';

import {
    BrowserRouter as Router,
} from 'react-router-dom';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const activeStatus="Active";


class ChecklistMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menus:{},
            user:{},
            company:{},
            departments:[],
            clients:[],
            checkListType:[],
            assignmentTypes:[],
            checklistItem:"",
            deletePermission:"N",

            showChecklists:false,
            addNewChecklist:false,
            addRemoveTasks:false,
            addRemoveTasksType:"",
            targetId:"",
            checkListHeadingId:"",
            checkListHeading:"",

            currentPageNo: 1,
            searchkey:"",
            resultSize:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            deptOnly:"N",
            status:activeStatus,
            processDeptId:"",
        }
    }

    componentDidMount(){
        this.getInitData();
    }
    //Save filters before showing other components
    //when user presses back reapply the filters
    handleActionDropdownItemClick = (checklistItem,currentPageNo,searchkey,resultSize,
        sort,sortDir,status,
        target,targetType,targetId,checkListHeadingId,checkListHeading,processDeptId) => {
        console.log("TARGET:"+targetId);
        this.setState({
            showChecklists:false,
            addNewChecklist:target==="addNewChecklist"?true:false,
            addRemoveTasks:target==="addRemoveTasks"?true:false,
            addRemoveTasksType:targetType,
            targetId:targetId,
            // documentItem:documentItem,
            currentPageNo:currentPageNo,
            searchkey:searchkey,
            resultSize:resultSize,
            sort:sort,
            sortDir:sortDir,
            status:status,
            checklistItem:checklistItem,
            checkListHeadingId:checkListHeadingId,
            checkListHeading:checkListHeading,
            processDeptId:processDeptId,
        })
    }

    handleBackBtnClick = () => {
        this.setState({
            showChecklists:true,
            addNewChecklist:false,
            addRemoveTasks:false,

            checklistItem:"",
            checkListHeadingId:"",
            checkListHeading:"",
            addRemoveTasksType:"",
            targetId:"",
        })
    }

    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.ChecklistCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    departmentOnly:this.state.deptOnly,
                })
            })
        ])
        .then(([menuRes,masterRes]) => { 
            return Promise.all([menuRes.json(),masterRes.json()]) 
        })
        .then(([menuRes,masterRes]) => { 
            let menusTemp = {};
            let userTemp = {};
            let companyTemp = {};
            let departmentsTemp = [];

            let clients = [];
            let checkListType = [];
            let assignmentTypes =[];

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
                userTemp = menuRes.data.user;
                departmentsTemp = menuRes.data.departments;
                companyTemp = menuRes.data.company;
            }else{
                menusTemp = {};
                userTemp = {};
            }

            console.log(JSON.stringify(departmentsTemp))
            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenuTask(menusTemp)
            let deletePermission = "N";

            if(menusTemp["Delete Checklist"] === "ALL"){
                deletePermission = "Y";
            }

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                clients = masterRes.data.clients;
                checkListType = masterRes.data.checkListType;
                assignmentTypes = masterRes.data.assignmentTypes;
            }else{
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,
                deletePermission:deletePermission,

                menus:menusTemp2,
                user:userTemp,
                departments:departmentsTemp,
                company:companyTemp,
                clients:clients,
                checkListType:checkListType,
                assignmentTypes:assignmentTypes,
                showChecklists:true,
            })
        })
    }

    render(){
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    this.state.showChecklists &&
                                    <ListChecklist
                                        currentPageNo = {this.state.currentPageNo}
                                        searchkey={this.state.searchkey}
                                        resultSize={this.state.resultSize}
                                        status={this.state.status}
                                        sort={this.state.sort}
                                        sortDir={this.state.sortDir}
                                        departments={this.state.departments}
                                        clients={this.state.clients}
                                        checkListType={this.state.checkListType}
                                        deptOnly={this.state.deptOnly}
                                        subscriptionType={this.state.user.subscriptionType}
                                        themeSettings={themeSettings}
                                        handleActionDropdownItemClick={this.handleActionDropdownItemClick}
                                        masterMenus={this.state.menus}
                                        company={this.state.company}
                                        deletePermission={this.state.deletePermission}

                                    />
                                }
                                {
                                    this.state.addNewChecklist &&
                                    <AddNewChecklist
                                        departments={this.state.departments}
                                        clients={this.state.clients}
                                        checkListType={this.state.checkListType}
                                        assignmentTypes={this.state.assignmentTypes}
                                        checklistItem={this.state.checklistItem}
                                        handleBackBtnClick={this.handleBackBtnClick}
                                        isEdit={!isEmptyVariable(this.state.checklistItem)}
                                        themeSettings={themeSettings}
                                        company={this.state.company}
                                    />
                                }
                                {
                                    this.state.addRemoveTasksType==="process" &&
                                    this.state.addRemoveTasks &&
                                    <AddRemoveTasksProcess
                                        handleBackBtnClick={this.handleBackBtnClick}
                                        processId={this.state.targetId}
                                        checkListHeadingId={this.state.checkListHeadingId}
                                        checkListHeading={this.state.checkListHeading}
                                        checklistItem = {this.state.checklistItem}

                                        currentPageNo = {this.state.currentPageNo}
                                        searchkey={this.state.searchkey}
                                        resultSize={this.state.resultSize}
                                        status={this.state.status}
                                        sort={this.state.sort}
                                        sortDir={this.state.sortDir}

                                        handleActionDropdownItemClick={this.handleActionDropdownItemClick}
                                        processDeptId = {this.state.processDeptId}
                                    />
                                }
                                {
                                    this.state.addRemoveTasksType==="function" &&
                                    this.state.addRemoveTasks &&
                                    <AddRemoveTasksFunction
                                        handleBackBtnClick={this.handleBackBtnClick}
                                        functionId={this.state.targetId}
                                        checkListHeadingId={this.state.checkListHeadingId}
                                        checkListHeading={this.state.checkListHeading}
                                        checklistItem = {this.state.checklistItem}

                                        currentPageNo = {this.state.currentPageNo}
                                        searchkey={this.state.searchkey}
                                        resultSize={this.state.resultSize}
                                        status={this.state.status}
                                        sort={this.state.sort}
                                        sortDir={this.state.sortDir}

                                        handleActionDropdownItemClick={this.handleActionDropdownItemClick}
                                        processDeptId = {this.state.processDeptId}
                                    />
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </Router>
        )
    }

}


export default ChecklistMain;