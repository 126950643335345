import React, { Component } from "react";
import * as Constants from "../Global/constants";
import {  DialogSaveButton } from "../Global/globalStyles.style";
import {
	getLocalStorageVariables,
	isEmptyVariable,
} from "../Global/commonFunctions";
import { Modal } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";

const userDetails = getLocalStorageVariables();

// City dropdown result size
const resultSize = 50;
const pageNo = 1;
const cityPlaceholder = "Select City";


class CitiesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			totalCount: 0,
			datalist: [],
			formErrors:{},

		};
	}

	componentDidMount() {	}

	componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAddCityDialog){
            if(this.props.isEdit){
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    formErrors:{},
                    countryList:[],
                    statesList:[],

                    city:this.props.selectedCity,
                    selectedCountry:this.props.selectedCountry,
                    selectedState:this.props.selectedState,
                })
            }else{
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    formErrors:{},

                    city:cityPlaceholder,
                    countryList:[],
                    statesList:[],
                    selectedCountry:"countryPlaceholder",
                    selectedState:"cityPlaceholder",

                })
            }
        }
    }

	handleCityDialogClose = () => {
		this.props.handleCityDialogClose();
	};

	handleSaveCityDialogClose = () =>{
		let isError = false;
        let FormErrorsTemp = {};

		if(isEmptyVariable(this.state.selectedItem)){
			isError = true;
            FormErrorsTemp['city'] = "Please select city";
		}
		if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }
		else{
			this.props.handleCityDialogClose(this.state.selectedItem);

		}
	}

	handleOnChangeSelectedOption = (item) => {
		console.log(item);
		this.setState({ 
			selectedItem:item,
			city:item.city,
         });
	};

	/*******************API CALLS*******************/

	
	getCitiesList = (inputValue,loadedOptions, { page }) => {
		this.setState({
			showLoader: true,
		});

		return fetch(Constants.GetCities, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				city: inputValue,
				resultsize: resultSize,
                pageNo:page,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {

				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						datalist: data.data.result,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
					});
					// return data.data.result;
					// alert((data.data.start + resultSize)  <= (data.data.count));
					return {
						options: data.data.result,
						hasMore: (data.data.start+resultSize) <= (data.data.count),
						additional: {
						  page: page + 1,
						},
					  };
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};


	/*******************API CALLS END HERE*******************/

	render() {
		return (

			<Modal
				enforceFocus={false}
				className="custom-dialog select-city-dialog"
				show={this.props.showAddCityDialog}
				backdrop="static"
			>
				<Modal.Header>
					<h5>Search City</h5>
					<button onClick={this.handleCityDialogClose}>
						<span className="material-icons">close</span>
					</button>
				</Modal.Header>
				<hr />

				<div className="modal-body">
					<div className="addnew-modal-form">
						<div className="row custom-row">
							<div className="col-md-12 custom-col">
								<div className="addnew-modal-form-group">
									<AsyncPaginate
										loadOptions={this.getCitiesList}
										defaultOptions
										cacheOptions
										placeholder={this.state.city}
										getOptionLabel={(e) => e.city}
										getOptionValue={(e) => e.city}
										onChange={this.handleOnChangeSelectedOption}
										additional={{
											page: pageNo,
											}}
									/>
									{
                                        !isEmptyVariable(this.state.formErrors['city']) &&
                                        <span className="cm-error">{this.state.formErrors['city']}</span>
                                    }
								</div>
							</div>
						</div>
					</div>

					<div className="modal-close-save">
						<div className="close-save-col">
							<button onClick={this.handleCityDialogClose} type="button" className="modal-close">
								Cancel
							</button>
							<DialogSaveButton 
								themeSettings={this.props.themeSettings} 
								onClick={this.handleSaveCityDialogClose} 
								type="button" 
								className="modal-save"
								>
									Save
								</DialogSaveButton>
							
						</div>
					</div>
				</div>
			</Modal>

		);
	}
}

export default CitiesList;
