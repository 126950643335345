import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,TableCheckBox,DialogSaveButton,FilterCheckBox,TableIcon} from '../../common/Global/globalStyles.style';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import Pagination from '../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    SplitJoinString,isEmptyArray,ifEmptyReturnEmptyStr} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {getCheckListDropdownActionArray} from '../../common/Global/reviewFunctions';


const userDetails  = getLocalStorageVariables();
const sortShortName = "checkListName";
const sortName = "name";
const sortProcessNameList = "processNameList";
const sortType = "checkListType";

const activeStatus="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";
const departmentFilterDropdownDefaultValue = "All";

class ChecklistVersionList extends Component{
    constructor(props){
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            currentPageNo:1,
            showLoader:false,
            totalCount:0,
            datalist:[],
            sort:"",
            sortDir:"",
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showChecklistVersionsDialog){
            
            this.setState({
                componentDidMountFlag:false,
                apiSearchKey:"",
                currentPageNo:1,
                showLoader:false,
                totalCount:0,
                datalist:[],
                sort:"",
                sortDir:"",
            },()=>{
                this.getInitData();
            })
        }
    }
   
    handleStopPropagation = (e) => {
        e.stopPropagation();
    }
  
    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getChecklistVersionList();
        })
    }

    /*****************************API*********************************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetChecklistVersions,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    checkListVersionId:this.props.checkListVersionId,
                    pageNo:this.state.currentPageNo,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                })
            })
        ])
        .then(([generalRes]) => { 
            return Promise.all([generalRes.json()]) 
        })
        .then(([generalRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

           
            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:datalistTemp,
                totalCount:totalCountTemp,

            })
        })
    }

    getChecklistVersionList = () => {
        this.setState({
            showLoader:true,
        });

        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            status:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
        }

        fetch(Constants.GetChecklistVersions,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    // onChangePage = (page) => {
    //     // update state with new page of items
    //     if(page !== this.state.currentPageNo){
    //         this.setState({
    //             showLoader:true,
    //         });

    //         let postParams = {
    //             email:userDetails.email,
    //             accessToken:userDetails.accessToken,
    //             pageNo:page,
    //             search:this.state.searchkey,
    //             status:this.state.status,
    //             sort:this.state.sort,
    //             sortDir:this.state.sortDir,
    //         }

    //         fetch(Constants.GetChecklistVersions,
    //         {
    //             method: "POST",
    //             mode:'cors',
    //             body: new URLSearchParams(postParams)
    //         })
    //         .then(response => { return response.json(); } )
    //         .then(data =>
    //         {
    //             if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
    //                 data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
    //                 localStorage.clear();
    //                 window.location="/";
    //             }else if(data.responseCode === Constants.CODE_SUCCESS){
    //                 this.setState({
    //                     showLoader:false,
    //                     datalist:data.data.result,
    //                     totalCount:data.data.count,
    //                     apiSearchKey:this.state.searchkey,
    //                     currentPageNo:page,
    //                 });
    //             }else{
    //                 this.setState({
    //                     datalist:[],
    //                     showLoader:false,
    //                     apiSearchKey:this.state.searchkey,
    //                 });
    //             }
    //         });
    //     }
    // }

    render(){
        return(
            <section>
                <Modal className="task-select-table-dialog custom-dialog"
                    show={this.props.showChecklistVersionsDialog} 
                    onHide={this.props.handleChecklistVersionsDialogClose}>
                    <Modal.Header>
                        <h5>Versions</h5>
                        <button 
                            onClick={this.props.handleChecklistVersionsDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div className="modal-loading-text">
                                <p
                                style={{
                                    background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                    color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                                }}
                                >Loading....</p>
                            </div>
                        }
                        {
                            // Need to check component did mount flag, other wise until api is called,
                            // it shows no items found layout.
                            this.state.componentDidMountFlag &&
                            <div className="body-wrapper">
                                <div className="search-addnew-bg" style={{marginTop:0}}>
                                    <div className="search-addnew">
                                        
                                    </div>
                                </div>
                                <div style={{position:"relative"}}>
                                    {
                                        !isEmptyArray(this.state.datalist) && 
                                        <div className="common-tab-bg">
                                            <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                        <th className="text-center" width={"5%"}>No.</th>
                                                        <th width={"25%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortShortName)}>
                                                            <div className="sort-header">Name
                                                            <span className={(this.state.sort === sortShortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                            </div>
                                                        </th>
                                                        {/* <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}> */}
                                                        { this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                            <th >
                                                                <div className="sort-header">Company Name
                                                                {/* <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span> */}
                                                                </div>
                                                            </th>
                                                        }
                                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortProcessNameList)}>
                                                            <div className="sort-header">Process
                                                            <span className={(this.state.sort === sortProcessNameList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                            </div>
                                                        </th>
                                                        <th width={"7%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortType)}>
                                                            <div className="sort-header">Type
                                                            <span className={(this.state.sort === sortType)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                            </div>
                                                        </th>
                                                        <th width={"7%"} >
                                                            <div className="sort-header">Version
                                                            </div>
                                                        </th>
                                                        
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        !isEmptyArray(this.state.datalist) && 
                                                        this.state.datalist.map((item,idx) => {

                                                            let actionObj = getCheckListDropdownActionArray(item.allowEdit, item.allowReview, 
                                                                item.status, item.currentReviewStatus, "Checklist");
                                                            return <tr>
                                                                <td className="text-center">{(++idx)}</td>
                                                                <td>{item.checkListName}</td>
                                                                {this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                    <td>{truncateString(SplitJoinString(item.companyList,",",":-:",","),Constants.OTHERMASTERS_STRING_LENGTH)}</td>
                                                                }
                                                                <td>{ifEmptyReturnEmptyStr(item.processNameList,"-")}</td>
                                                                <td>{item.checkListType}</td>
                                                                <td className="text-center" >{item.version}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                        <div class="no-items-layout">
                                            <div class="no-items-card">
                                                <h6>
                                                {
                                                    isEmptyVariable(this.state.apiSearchKey)?
                                                    Constants.NO_RECORDS_WARNING
                                                    :
                                                    Constants.EMPTY_SEARCH_WARNING
                                                }
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                    {/* <div className="pagination-layout">
                                        <div className="row custom-row">
                                            <div className="col-md-9 custom-col">
                                            {
                                                (this.state.totalCount > this.state.datalist.length) && 
                                                <Pagination 
                                                    totalLength ={this.state.totalCount} 
                                                    items={this.state.datalist} 
                                                    onChangePage={this.onChangePage} 
                                                    pageSize={this.state.resultSize}
                                                    currentPageNo = {this.state.currentPageNo}
                                                    initialPage={this.state.currentPageNo}
                                                    themeSettings={this.props.themeSettings} />
                                            }
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <p>{"Total Records: "+this.state.totalCount}</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="overlay-nr" style={{display:this.state.document_NR === "Y"?"block":"none"}}>
                                    </div> */}
                                </div>

                                <div className="modal-close-save m-t-15">
                                    <div className="close-save-col">
                                        <button onClick={this.props.handleChecklistVersionsDialogClose} type="button" 
                                        className="modal-close m-b-0">Close</button>
                                        {/* <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                        onClick={this.returnSelectedDocumentId} type="button" 
                                        className="modal-save m-b-0">Save</DialogSaveButton> */}
                                    </div>
                                </div>
                            </div>
                        }
                        
                    </div>
                </Modal>
                
            </section>
        )
    }
}

export default ChecklistVersionList;