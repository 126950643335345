import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import { isEmptyArray, SplitJoinString } from '../../../common/Global/commonFunctions';

class EmployeeDetailsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(){
    }


    render() {
        return(
            <Modal className="custom-dialog" show={this.props.showEmpDetailsDialog} onHide={this.props.handleEmpDetailsDialogClose}>
                <Modal.Header>
                    <h5>{"Employee Detail"}</h5>
                    <button 
                        onClick={this.props.handleEmpDetailsDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div class="modal-body">
                    <div className="details-table-layout">
                        <div className="row custom-row">
                            <div className="col-md-12 custom-col">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td width={"24%"}>Name</td>
                                            <td width={"4%"}>:</td>
                                            <td width={"72%"}>{SplitJoinString(this.props.employeeData.name,",",":-:",", ")}</td>
                                        </tr>
                                        <tr>
                                            <td width={"24%"}>Designation</td>
                                            <td width={"4%"}>:</td>
                                            <td width={"72%"}>{SplitJoinString(this.props.employeeData.designation,",",":-:",", ")}</td>
                                        </tr>
                                        <tr>
                                            <td width={"24%"}>Email</td>
                                            <td width={"4%"}>:</td>
                                            <td width={"72%"}>{this.props.employeeData.employeeEmail}</td>
                                        </tr>
                                        <tr>
                                            <td width={"24%"}>Units</td>
                                            <td width={"4%"}>:</td>
                                            <td width={"72%"}>{isEmptyArray(this.props.employeeData.units)?"":this.props.employeeData.units.map(e=>e.unitName).join(", ") ||''}</td>
                                        </tr>
                                        <tr>
                                            <td width={"24%"}>Scope</td>
                                            <td width={"4%"}>:</td>
                                            <td width={"72%"}>{!isEmptyArray(this.props.employeeData.scope)?this.props.employeeData.scope.map(scope => scope.functionName).join(", "): ""}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default EmployeeDetailsDialog;