export const themeDefaultStyles = {
    themeColor:{r: '125',g: '63',b: '118',a: '1'},
    themeTextColor:{r: '255',g: '255',b: '255',a: '1'},
    themeHoverColor:{r: '170',g: '85',b: '160',a: '1'},
    themeHoverTextColor:{r: '255',g: '255',b: '255',a: '1'},
}

export const sideBarDefaultStyles = {
    background:{r: '238',g: '240',b: '242',a: '1'},
    borderRight:{r: '204',g: '204',b: '204',a: '1'},
    dividerColor:{r: '125',g: '63',b: '118',a: '1'},
    // dividerColor:{r: '204',g: '204',b: '204',a: '1'},
    textColor:{r: '0',g: '0',b: '0',a: '1'},
    iconColor:{r: '125',g: '63',b: '118',a: '1'},
    selectedMenuItemBg:{r: '125',g: '63',b: '118',a: '1'},
    selectedMenuItemTextColor:{r: '255',g: '255',b: '255',a: '1'},
    selectedMenuItemIconColor:{r: '255',g: '255',b: '255',a: '1'},
    hoverMenuItembg:{r: '170',g: '85',b: '160',a: '1'},
    hoverMenuItemTextColor:{r: '255',g: '255',b: '255',a: '1'},
    hoverMenuItemIconColor:{r: '255',g: '255',b: '255',a: '1'},
}

export const topbarDefaultStyles = {
    background:{r: '255',g: '255',b: '255',a: '1'},
    iconColor:{r: '125',g: '63',b: '118',a: '1'},
    selectedIconBgColor:{r: '243',g: '243',b: '243',a: '1'},
    notificationBadgeBg:{r: '255',g: '0',b: '0',a: '1'},
    notificationBadgeTextColor:{r: '255',g: '255',b: '255',a: '1'},
}

export const displayDefaultStyles = {
    displaySize: "normal",
}
