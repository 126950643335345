import {
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  Paragraph,
  WidthType,
  BorderStyle,
  TextRun,
  ImageRun,
  VerticalAlign,
} from "docx";
import {
  isEmptyArray,
  isEmptyVariable,
} from "../../../common/Global/commonFunctions";
export const getInternalAuditSummaryReportPageContent = (
  assignmentDetails,
  dataList,
  taskTamplateValueMap,
  executionTemplateValuesMap,
  unitDetials,
  companyLogo,isLogoAvailable
) => {
  let internalAuditSummaryReportContent = [];
  let fontName = "Times New Roman";
  let tempTableRow = [];
  let commonFontSize = 16;
  let commonBorderStyle ={ style: BorderStyle.SINGLE, color: "000000", size: 3 };
  let unitName = String(assignmentDetails.unitAddressList).split(":-:")[0]+" ("+ JSON.stringify(assignmentDetails.unitNameCityList).split("(")[0].replace('"', "").trim()+")";


let tempclausesRefrenceId=8


  let clausesList = [
    {
      key: "4.1",
      value: "Understanding the organization and its context",
    },
    {
      key: "4.2",
      value: "Understanding the needs and expectations od interested parties",
    },
    {
      key: "4.3",
      value: "Determining the scope of the QMS",
    },
    {
      key: "4.4",
      value: "QMS and its process",
    },
    {
      key: "5.1",
      value: "Leadership and commitment",
    },
    {
      key: "5.2",
      value: "Quality Policy",
    },
    {
      key: "5.3",
      value: "Organisational roles, responsibilities and authorities",
    },
    {
      key: "6.1",
      value: "Actions to address risks and opportunities",
    },
    {
      key: "6.2",
      value: "Quality objectives and planning to achieve them",
    },

    {
      key: "6.3",
      value: "Planning of changes",
    },
    {
      key: "7.1",
      value: "Resources",
    },
    {
      key: "7.2",
      value: "Competence",
    },
    {
      key: "7.3",
      value: "Awareness",
    },
    {
      key: "7.4",
      value: "Communication",
    },
    {
      key: "7.5",
      value: "Documented information",
    },
    {
      key: "8.1",
      value: "Organisational planning and control",
    },
    {
      key: "8.2",
      value: "Requirements for products & services",
    },
    {
      key: "8.3",
      value: "Design and development of products and services",
    },
    {
      key: "8.4",
      value: "Control of externally provided processes, product and services",
    },
    {
      key: "8.5",
      value: "Production and services provision",
    },
    {
      key: "8.6",
      value: "Release of products and services",
    },
    {
      key: "8.7",
      value: "Control of nonconforming outputs",
    },

    {
      key: "9.1",
      value: "Monitoring, measurement, analysis and evaluation",
    },
    {
      key: "9.2",
      value: "Internal audit",
    },
    {
      key: "9.3",
      value: "Management review",
    },
    {
      key: "10.1",
      value: "Improvement - General",
    },
    {
      key: "10.2",
      value: "Nonconformity and corrective action",
    },
    {
      key: "10.3",
      value: "Continual improvement",
    },
  ];

  /// logo, Internal Audit Paln, Date
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left:80
          },
          borders: {
           bottom: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: 3,
          children: [
            new Paragraph({
              children: [
                !isEmptyVariable(isLogoAvailable) &&
                new ImageRun({
                  data: companyLogo,
                  transformation: {
                    width: 100,
                    height: 30,
                  },
                }),
              
              ],
            }),
          ],
        }),

        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left:80
          },
          borders: {
           bottom: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: 14,
          children: [
            new Paragraph({
              children: [
               
                new TextRun({
                  text: "\tInternal Audit Summary Report",
                  font: fontName,
                  size: commonFontSize+2,
                  allCaps: true,
                  bold: true,
                }),
              ],
            }),
          ],
        }),

        new TableCell({
          margins: {
            top: 50,
            left: 80,
            bottom: 50,
          },
          borders: {
            left: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
           bottom: commonBorderStyle,
          },

          columnSpan: 23,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Doc No: ",
                  size: commonFontSize,
                  bold: true,
                  font: fontName,
                }),
                new TextRun({
                  text: "QF 9001 10",
                  size: commonFontSize,
                  font: fontName,
                }),
              
              ],
              spacing: {
                after: 20,
              },
            }),
         
            new Paragraph({
              children: [
                new TextRun({
                  text: "Rev No: ",
                  size: commonFontSize,
                  font: fontName,
                  bold: true,
                }),
                new TextRun({
                  text: "1",
                  size: commonFontSize,
                  font: fontName,
                }),
               
              ],
              spacing: {
                after: 50,
              },
            }),
        
           
            new Paragraph({
              children: [
                new TextRun({
                  text: "Date: ",
                  size: commonFontSize,
                  font: fontName,
                  bold: true,
                }),
                new TextRun({
                  text: "15/12/2016",
                  size: commonFontSize,
                  font: fontName,
                }),
               
              ],
              spacing: {
                after: 20,
              },
            }),
          ],
        }),
      ],
    })
  );

  ///Department, Site
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          borders: {
           bottom: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: 40,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Department / site: ",
                  allCaps: true,
                  bold: true,
                  size: commonFontSize,
                  font: fontName,
                }),
                new TextRun({
                  text: unitName,
                  size: commonFontSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );


  let tempEmployeesList =[]
  Object.values(unitDetials).map((unit)=>{
    Object.values(unit.employees).map((emp)=>{
      let salutation = !isEmptyVariable(emp.salutation)?emp.salutation:""
      let firstName =  !isEmptyVariable(emp.firstName)?emp.firstName:""
      let lastName =  !isEmptyVariable(emp.lastName)?emp.lastName:""
      let nameCombination = " "+salutation+firstName+" "+lastName
      if (!tempEmployeesList.includes(nameCombination))
      {
        tempEmployeesList.push(nameCombination)
      }

    })
  })

  // Auditee::
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          borders: {
            bottom: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: 1,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Auditee:",
                  allCaps: true,
                  bold: true,
                  size: commonFontSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),

        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left:50
          },
          borders: {
            bottom: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: 39,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: String(tempEmployeesList),
                  size: commonFontSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  internalAuditSummaryReportContent.push(
    new Table({
      
      margins: {
        bottom: 50,
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        top: { style: BorderStyle.NONE},
        bottom: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE},
        right: { style: BorderStyle.NONE},
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: tempTableRow,
    })
  );

  tempTableRow = [];

  /// ISO 9001:2015 clauses, day
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
          },
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          // columnSpan: 1,
          width:{
            type:WidthType.PERCENTAGE,
            size:1
          },
          rowSpan: 3,
          children: [
            new Paragraph({
              alignment:AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "ISO 9001:",
                  bold: true,
                  size: commonFontSize+1,
                  font: fontName,
                }),
              ],
            }),
            new Paragraph({
              alignment:AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "2015 clause",
                  bold: true,
                  size: commonFontSize+1,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          borders: {
           bottom: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: 39,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "day:",
                  allCaps: true,
                  bold: true,
                  size: commonFontSize,
                  font: fontName,
                }),
                new TextRun({
                  text: " ",
                  allCaps: true,
                  bold: true,
                  size: commonFontSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// auditor
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          borders: {
           bottom: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: 40,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "auditor:",
                  allCaps: true,
                  bold: true,
                  size: commonFontSize,
                  font: fontName,
                }),
                new TextRun({
                  text: " ",
                  allCaps: true,
                  bold: true,
                  size: commonFontSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
       
      ],
    })
  );

  /// Period
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          borders: {
           bottom: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: 39,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "period (am / pm):",
                  allCaps: true,
                  bold: true,
                  size: commonFontSize,
                  font: fontName,
                }),
                new TextRun({
                  text: " ",
                  allCaps: true,
                  bold: true,
                  size: commonFontSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
        
      ],
    })
  );

  let datalistLength = Object.values(dataList).length;
  let functionColSpan = Math.floor(20 / datalistLength);
  let totalColSpan = (20 % datalistLength);


  /// Display all the clause and  function data
  let tempFunctionRowChild = [];
  tempFunctionRowChild.push(
    new TableCell({
      margins: {
        top: 50,
        bottom: 50,
        left: 50,
      },
      borders: {
       bottom: commonBorderStyle,
       right: commonBorderStyle,
      },
      verticalAlign: AlignmentType.CENTER,
      columnSpan: 10,
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: " ",
              allCaps: true,
              bold: true,
              size: commonFontSize,
              font: fontName,
            }),
          ],
        }),
      ],
    })
  );
  Object.values(dataList).map((data) => {
    tempFunctionRowChild.push(
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
          left:10,
          right:10
        },
        borders: {
         bottom: commonBorderStyle,
         right: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: functionColSpan,
        children: [
          new Paragraph({
            alignment:AlignmentType.CENTER,
            children: [
              new TextRun({
                text: data.functionName,
                size: commonFontSize-2,
                font: fontName,
              }),
            ],
          }),
        ],
      })
    );
  });

  tempFunctionRowChild.push(
    new TableCell({
      margins: {
        top: 50,
        bottom: 50,
      },
      borders: {
       bottom: commonBorderStyle,
      },
      verticalAlign: AlignmentType.CENTER,
      columnSpan: totalColSpan,
      children: [
        new Paragraph({
          alignment:AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "Total",
              size: commonFontSize-2,
              font: fontName,
            }),
          ],
        }),
      ],
    })
  );
  tempTableRow.push(
    new TableRow({
      children: tempFunctionRowChild,
    })
  );
  let tempFunctionTotalList = [];

  clausesList.map((item) => {
    let tempRowChildren = [];
    tempRowChildren.push(
      new TableCell({
        margins: {
          top: 10,
          bottom: 10,
        },
        borders: {
         bottom: commonBorderStyle,
         right: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        // columnSpan: 1,
        width:{
          type:WidthType.PERCENTAGE,
          size:1
        },
        width:{
          type:WidthType.AUTO,
          size:10,
        },
        children: [
          new Paragraph({
            alignment:AlignmentType.CENTER,
            children: [
              new TextRun({
                text: item.key,
                size: commonFontSize,
                font: fontName,
              }),
            ],
          }),
        ],
      })
    );
    tempRowChildren.push(
      new TableCell({
        margins: {
          top: 10,
          bottom: 10,
          left: 50,
          right:50
        },
        borders: {
         bottom: commonBorderStyle,
         right: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: 9,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: item.value,
                size: commonFontSize,
                font: fontName,
              }),
            ],
          }),
        ],
      })
    );

    let tempNcCountList = [];

    Object.values(dataList).map((data) => {
      let ncCount = 0;

      Object.values(data.taskList).map((task) => {
        let tempTaskId = task.taskId;
        let tempAssignmentTaskId = task.assignmentTaskId;

        Object.entries(taskTamplateValueMap).map(([taskId, taskVal]) => {
          if (taskId == tempTaskId) {
            Object.values(taskVal).map((val) => {
              if (val.fieldId === tempclausesRefrenceId) {
                let tempClauseList = [];
                tempClauseList = !isEmptyVariable(val.value)
                  ? JSON.parse(val.value)
                  : [];

                Object.values(tempClauseList).map((clause) => {
                  if (parseFloat(clause.match(/[\d\.]+/)) == item.key) {
                    Object.entries(executionTemplateValuesMap).map(
                      ([execId, execVal]) => {
                        if (execId == tempAssignmentTaskId) {
                          Object.values(execVal).map((v) => {
                            
                              if (v.value === "NC") {
                                ncCount++;
                              }
                            
                          });
                        }
                      }
                    );
                  }
                });
              }
            });
          }
        });
      });

      tempNcCountList.push(ncCount);

      tempFunctionTotalList.push({
        key: data.functionName,
        value: ncCount,
      });
    });

    tempNcCountList.map((count) => {
      tempRowChildren.push(
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          borders: {
           bottom: commonBorderStyle,
           right: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: functionColSpan,
          children: [
            new Paragraph({
              alignment:AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: count,
                 
                  size: commonFontSize-2,
                  font: fontName,
                }),
              ],
            }),
          ],
        })
      );
    });

    tempRowChildren.push(
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
        },
        borders: {
         bottom: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: totalColSpan,
        children: [
          new Paragraph({
            alignment:AlignmentType.CENTER,
            children: [
              new TextRun({
                text: !isEmptyArray(tempNcCountList) ? tempNcCountList.reduce(
                  (result, number) => result + number
                ):"0",
                
                size: commonFontSize-2,
                font: fontName,
                bold:true
              }),
            ],
          }),
        ],
      })
    );

    tempTableRow.push(
      new TableRow({
        children: tempRowChildren,
      })
    );
  });



  // Display Total of NC 
  let tempTotalRowChildren = [];

  tempTotalRowChildren.push(
    new TableCell({
      margins: {
        top: 50,
        bottom: 50,
        right: 50,
      },
      borders: {
      bottom:commonBorderStyle,
       right: commonBorderStyle,
      },
      verticalAlign: AlignmentType.CENTER,
      columnSpan: 10,
      children: [
        new Paragraph({
          alignment:AlignmentType.END,
          children: [
            new TextRun({
              text: "Total",
              bold: true,
              size: commonFontSize+1,
              font: fontName,
            }),
          ],
        }),
      ],
    })
  );


  let tempTotalOfToalCount = 0;
  Object.values(dataList).map((data) => {
    let temptotalcount = 0;
    tempFunctionTotalList.map((item) => {
      if (item.key === data.functionName) {
        temptotalcount = temptotalcount + item.value;
      }
    });

    tempTotalRowChildren.push(
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
     
        },
        borders: {
         right: commonBorderStyle,
         bottom:commonBorderStyle
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: functionColSpan,
        children: [
          new Paragraph({
            alignment:AlignmentType.CENTER,
            children: [
              new TextRun({
                text: temptotalcount,
                bold:true,
                size: commonFontSize-2,
                font: fontName,
              }),
            ],
          }),
        ],
      })
    );

    tempTotalOfToalCount = tempTotalOfToalCount + temptotalcount;
  });

  tempTotalRowChildren.push(
    new TableCell({
      margins: {
        top: 50,
        bottom: 50,
      },
      borders: {
        bottom: commonBorderStyle,
       
       },
     
      verticalAlign: AlignmentType.CENTER,
      columnSpan: totalColSpan,
      children: [
        new Paragraph({
          alignment:AlignmentType.CENTER,
          children: [
            new TextRun({
              text: tempTotalOfToalCount,
              allCaps: true,
              bold: true,
              size: commonFontSize,
              font: fontName,
            }),
          ],
        }),
      ],
    })
  );

  tempTableRow.push(
    new TableRow({
      children: tempTotalRowChildren,
    })
  );

  internalAuditSummaryReportContent.push(
    new Table({
      
      margins: {
        bottom: 50,
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        top: { style: BorderStyle.NONE},
        bottom: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE},
        right: { style: BorderStyle.NONE},
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: tempTableRow,
    })
  );

  return [...internalAuditSummaryReportContent];
};
