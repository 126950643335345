import React from "react";
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Cell } from "recharts";
import { ChartColors } from "../Global/constants";

export default class RechartsPieChart extends React.PureComponent {
    constructor (props) {
        super(props);
        this.state = {}
    }

    render () {
        const { chartData: { data, dataKey, nameKey } } = this.props;
        let totalValue = data.reduce((sum, e) => sum + e.total, 0);
        console.log(totalValue)
        return (
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    <Tooltip />
                    <Legend verticalAlign="top" align="right" wrapperStyle={{ top: 0 }} />
                    <Pie
                        dataKey={dataKey}
                        data={data}
                        label={(e) => `${((e.total / totalValue) * 100).toFixed(2)}%`}
                        
                    >
                        {data.map((cell, j) => (
                            <Cell key={`cell-${dataKey}-${j}`} name={cell[nameKey]} fill={ChartColors[j % ChartColors.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        )
    }
}
