import { isEmptyVariable, isEmptyArray, ifEmptyReturnEmptyStr, removeHtmlTags, SplitJoinString, DoubleSplitString,isJsonString } from "../../../common/Global/commonFunctions";
import * as TemplateConstants from "../../../common/Global/templateConstants";
import moment from "moment";

export const yesNoRankArr = [
    {label:"Yes",id:1},
    {label:"No",id:0},
    {label:"N/A",id:-1},
];

export const getExcelJsContents = (assignedTasks,showWeight,showRanking,showYesNoDD) => {
    //Expected format
    //[{name:[],tableCols:[{},{}],tableVal:[[],[]]},{name:[],tableCols:[{},{}],tableVal:[[],[]]}]

    let excelJsContent = [];
    let filterObj={filterButton: false}
    let tableWeightCols=[
        {name:'No.',...filterObj},
        {name:'Task',...filterObj},
        {name:'Weight',...filterObj},
        {name:'Rank',...filterObj},
        {name:'Remarks',...filterObj},
        {name:'Risk',...filterObj},
    ];
    let tableRankCols=[
        {name:'No.',...filterObj},
        {name:'Task',...filterObj},
        {name:'Rank',...filterObj},
        {name:'Remarks',...filterObj},
        {name:'Risk',...filterObj},
    ];
    let tableYNCols=[
        {name:'No.',...filterObj},
        {name:'Task',...filterObj},
        {name:'Rank',...filterObj},
        {name:'Remarks',...filterObj},
        {name:'Risk',...filterObj},
    ];
    let tableCols=[
        {name:'No.',...filterObj},
        {name:'Task',...filterObj},
        {name:'Remarks',...filterObj},
        {name:'Risk',...filterObj},
    ];
    
    assignedTasks.forEach((item)=>{
        if(!isEmptyArray(item.taskList)){
            let taskObj={
                name:[],
                tableCols:[],
                tableValue:[],
            };
            
            //Add Heading/Name
            let tasktableHeading = isEmptyVariable(item.processName)
                ?(isEmptyVariable(item.functionName)
                ?(item.heading)
                :item.functionName)
                :item.processName;
            
            taskObj['name'].push(tasktableHeading);
            
            //Add columns
            if(showWeight && !isEmptyVariable(item.heading)){
                taskObj['tableCols'].push(...tableWeightCols);
            }else if(showRanking && !isEmptyVariable(item.heading)){
                taskObj['tableCols'].push(...tableRankCols);
            }else if(showYesNoDD && !isEmptyVariable(item.heading)){
                taskObj['tableCols'].push(...tableYNCols);
            }else{
                taskObj['tableCols'].push(...tableCols);
            }

            //Add table rows
            item.taskList.forEach((taskItem,index)=>{
                let tableValueRow = [];
                //Task Name
                tableValueRow.push(`${++index}`);
                tableValueRow.push(`${removeHtmlTags(taskItem.task)}`);
                
                if(showWeight && !isEmptyVariable(item.heading)){
                    tableValueRow.push(`${taskItem.weight}`);
                    let rank = isEmptyVariable(taskItem.rank) ?"-" : (taskItem.rank === -1 ? "N/A" : taskItem.rank)
                    tableValueRow.push(`${rank}`);
                } if(showRanking && !showWeight && !isEmptyVariable(item.heading)){
                    let rank = isEmptyVariable(taskItem.rank) ?"-" : (taskItem.rank === -1 ? "N/A" : taskItem.rank)
                    tableValueRow.push(`${rank}`);
                } if(showYesNoDD && !isEmptyVariable(item.heading)){
                    let yNRank="-";
                    if(!isEmptyVariable(taskItem.rank)){
                        yesNoRankArr.forEach((rankItem)=>{
                            if(taskItem.rank === rankItem.id){
                                yNRank = rankItem.label;
                            }
                        });
                    }
                    tableValueRow.push(`${yNRank}`);
                }
                //Remarks
                tableValueRow.push(`${ifEmptyReturnEmptyStr(taskItem.latestRemark, "")}`);


                //Risk
                tableValueRow.push(`${ifEmptyReturnEmptyStr(taskItem.riskLevel, "")}`);


                taskObj['tableValue'].push(tableValueRow)
            });
            excelJsContent.push(taskObj);
        }
    });
    // console.log("-",JSON.stringify(excelJsContent))
    return excelJsContent;
}; 

export const getExcelJsContentsWithTemplates = (assignedTasks,
    selectedTaskTemplateFields,
    taskTemplateValuesMap,
    execTaskTemplateFields,
    executionTemplateValuesMap) => {

    //Expected format
    //[{name:[],tableCols:[{},{}],tableVal:[[],[]]},{name:[],tableCols:[{},{}],tableVal:[[],[]]}]

    let excelJsContent = [];
    let filterObj={filterButton: false};
    
    let tableCols=[
        {name:'No.',...filterObj},
        {name:'Task',...filterObj},
        {name:'Risk',...filterObj},
    ];
    
    assignedTasks.forEach((item)=>{
        if(!isEmptyArray(item.taskList)){
            let taskObj={
                name:[],
                tableCols:[],
                tableValue:[],
            };
            
            //Add Heading/Name
            let tasktableHeading = isEmptyVariable(item.processName)
                ?(isEmptyVariable(item.functionName)
                ?(item.heading)
                :item.functionName)
                :item.processName;
            
            taskObj['name'].push(tasktableHeading);
            //Add columns
            taskObj['tableCols'].push(...tableCols);
            
            // selectedTaskTemplateFields cols
            selectedTaskTemplateFields.forEach(item=>{
                // let templateFieldsCol = {name:item.fieldName,filterButton: false};
                taskObj['tableCols'].push({name:item.fieldName,filterButton: false});
            });
            
            // execTaskTemplateFields cols
            execTaskTemplateFields.forEach(item=>{
                taskObj['tableCols'].push({name:item.fieldName,filterButton: false});
            });

            
            //Add table rows
            item.taskList.forEach((taskItem,index)=>{
                let tableValueRow = [];
                //Task Name
                tableValueRow.push(++index);
                tableValueRow.push(removeHtmlTags(taskItem.task));
                 // //Risk
                tableValueRow.push(ifEmptyReturnEmptyStr(taskItem.riskLevel, ""));
                
                // selectedTaskTemplateFields values
                selectedTaskTemplateFields.forEach(item=>{
                    tableValueRow.push(getTaskDisplayValues(taskItem.taskId,item,taskTemplateValuesMap));
                });

                 // execTaskTemplateFields values
                execTaskTemplateFields.forEach(item=>{
                    tableValueRow.push(getExecTableDataDisplayValue(taskItem.assignmentTaskId,item,executionTemplateValuesMap));
                });


                taskObj['tableValue'].push(tableValueRow)
            });
            excelJsContent.push(taskObj);
        }
    });
    console.log("-",JSON.stringify(excelJsContent))
    return excelJsContent;
} 

//Using same functions from executeTaskList to get values
export const getTaskDisplayValues = (taskId, fieldItem,taskTemplateValuesMap) => {
    if(isEmptyVariable(taskTemplateValuesMap[taskId])){
        return "-";
    }

    let fieldId = fieldItem.fieldId;
    if(isEmptyVariable(taskTemplateValuesMap[taskId][fieldId])){
        return "-";
    }

    let displayValue = taskTemplateValuesMap[taskId][fieldId]["value"];
    if(isEmptyVariable(displayValue)){
        return "-";
    }

    switch(fieldItem.fieldType){
        case TemplateConstants.FIELD_TYPE_TEXT:
            return removeHtmlTags(displayValue);
        case TemplateConstants.FIELD_TYPE_DATE:
            return isEmptyVariable((displayValue))
            ?
            ""
            :
            moment(displayValue).format("DD/MM/YYYY");
        case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
            return displayValue;
        case TemplateConstants.FIELD_TYPE_DROPDOWN:
            return displayValue;
        case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
            if(!isJsonString(displayValue)){
                return "-";
            }
            return JSON.parse(displayValue).join(", ");
        case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
            if(!isJsonString(displayValue)){
                return "-";
            }
            return JSON.parse(displayValue).join(", ");
    }
    return "-";
};

export const getExecTableDataDisplayValue = (assignmentTaskId,fieldItem,executionTemplateValuesMap) => {
    if(isEmptyVariable(executionTemplateValuesMap[assignmentTaskId])){
        return "";
    }

    let fieldId = fieldItem.fieldId;
    if(isEmptyVariable(executionTemplateValuesMap[assignmentTaskId][fieldId])){
        return "";
    }

    let displayValue = executionTemplateValuesMap[assignmentTaskId][fieldId]["value"];
    if(isEmptyVariable(displayValue)){
        return "";
    }

    switch(fieldItem.fieldType){
        case TemplateConstants.FIELD_TYPE_TEXT:
            return removeHtmlTags(displayValue);
        case TemplateConstants.FIELD_TYPE_DATE:
            return moment(displayValue).format("DD/MM/YYYY");
        case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
            return displayValue;
        case TemplateConstants.FIELD_TYPE_DROPDOWN:
            return displayValue;
        case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
            if(!isJsonString(displayValue)){
                return "";
            }
            return JSON.parse(displayValue).join(", ");
        case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
            if(!isJsonString(displayValue)){
                return "";
            }
            return JSON.parse(displayValue).join(", ");
    }
    return "";
};
