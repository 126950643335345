import React, { Component } from "react";
import { DialogSaveButton, FilterCheckBox } from "../../../common/Global/globalStyles.style";
import { isEmptyVariable } from "../../../common/Global/commonFunctions";
import * as Constants from '../../../common/Global/constants';


class TemplateFieldsFilter extends Component {

    render(){
        return(
            <div className="overlay" style={{zIndex: this.props.screenName == Constants.SCREEN_EXECUTE ? 0 : 999}}>
                <div className={`popup-container ${this.props.showFilter ? 'show' : ''}`}>
                    <div>
                        <h5 className="small-h5">Filter</h5>
                    </div>
                    <div style={{marginTop:10}} className="d-flex flex-wrap content">
                    {this.props.filterDataList.map((item,index) => {
                        return(
                            <div className="d-flex flex-column mr-5 mb-1">
                            <h6 className="small-h6 border-top-bottom">{item.fieldName}</h6>
                            <div className="scrollable-container">
                                {item.dropdownValues.map((it,i) => {
                                    return(
                                        <FilterCheckBox themeSettings={this.props.themeSettings}
                                        style={{marginLeft:0, marginTop:10}}>
                                            <input 
                                                type="checkbox"
                                                name={index+":-:"+it.value}
                                                id={index+":-:"+it.value}
                                                onChange={this.props.handleCheck}
                                                checked={it.isSelected?true:false}
                                            />
                                            <label for={index+":-:"+it.value}>{isEmptyVariable(it.value) ? "Blank" : it.value}</label>
                                        </FilterCheckBox>
                                    )
                                })}
                                </div>
                            </div>
                        )
                    })}
                    </div>
                    <div className="fixed-bottom mb-3 ml-3">
                        <button onClick={this.props.handleCancelFilter} type="button" className="modal-close m-b-0 mr-2">
                            Cancel
                        </button>
                        <DialogSaveButton
                            themeSettings={this.props.themeSettings}
                            type="button"
                            style={{cursor:this.props.isDisabled?"no-drop":"auto"}}
                            disabled={this.props.isDisabled}
                            onClick={this.props.handleResetFilter}
                            className="modal-save m-b-0 mr-2"
                        >
                            Reset
                        </DialogSaveButton>
                        <DialogSaveButton
                            themeSettings={this.props.themeSettings}
                            type="button"
                            style={{cursor:this.props.isDisabled?"no-drop":"auto"}}
                            disabled={this.props.isDisabled}
                            onClick={this.props.handleSaveFilter}
                            className="modal-save m-b-0"
                        >
                            Apply
                        </DialogSaveButton>
                    </div>
                </div>
            </div>
        )
    }
}
export default TemplateFieldsFilter;