import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,getThemeSettingsVariables,removeHtmlTags,ifEmptyReturn,
    GetOrderedMasterTabMenuTask,
    isEmptyVariable, isEmptyArray, isJsonString} from '../../../common/Global/commonFunctions';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";

class AddTaskMultiSelectDropdown extends Component{
    
    constructor(props) {
        super(props);
    }

    componentDidMount(){
    }
    addSelection = (args) =>{
        
        let value = "";
        if(this.props.isFromRiskMaster){
            // value=args.itemData.businessRiskId+":-:"+args.itemData.name;
            value=args.itemData[this.props.fields.value];
        }else{
            value=args.itemData.value
        }
        this.props.addSelectedValues(value,this.props.templateItemIndex)
    }

    removeSelection = (args) => {
        
        let value = "";
        if(this.props.isFromRiskMaster){
            // value=args.itemData.businessRiskId+":-:"+args.itemData.name
            value=args.itemData[this.props.fields.value];
        }else{
            value=args.itemData.value
        }
        this.props.removeSelectedValues(value,this.props.templateItemIndex)
    }

    render(){
        return (
            <MultiSelectComponent 
                // id={this.props.multiselectId}
                dataSource={this.props.dropdownValues}
                fields={this.props.fields}
                placeholder={this.props.placeholderValue} 
                mode="CheckBox"
                closePopupOnSelect={false}
                allowFiltering={!isEmptyVariable(this.props.allowFiltering) && this.props.allowFiltering?true:false}
                select={this.addSelection}
                removed={this.removeSelection}
                cssClass="clientsChecklist dialogcl"
                value={!isEmptyArray(this.props.fieldValue)?this.props.fieldValue:""}
                showDropDownIcon={true}
                showClearButton={false}
                open = {this.props.handleFocus}
            >
                <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
    )}
}

export default AddTaskMultiSelectDropdown;
