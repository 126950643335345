
import {
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  Paragraph,
  WidthType,
  BorderStyle,
  TextRun,
  ImageRun
} from "docx";
import { isEmptyVariable } from "../../../common/Global/commonFunctions";

export const getObservationCompilanceContnet=(assignmentDetails,companyLogo,isLogoAvailable)=> {
  let fontName = "Times New Roman";
  let commonBorderStyle ={ style: BorderStyle.SINGLE, color: "000000", size: 3 };
  let observationCompilanceContent = [];
  let tempRowData =[];
  let commonFontSize = 17;
  let unitNameCityList = isEmptyVariable(assignmentDetails.unitNameCityList)?"":JSON.stringify(assignmentDetails.unitNameCityList).split("(")[0].replace('"',"");


/// logo, Internal Audit Paln, Date
tempRowData.push(
  new TableRow({
    children: [
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
          left:80
        },
        borders: {
         bottom: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: 3,
        children: [
          new Paragraph({
            children: [
              !isEmptyVariable(isLogoAvailable) &&
              new ImageRun({
                data: companyLogo,
                transformation: {
                  width: 100,
                  height: 30,
                },
              }),
              
            ],
          }),
        ],
      }),
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
          left:80
        },
        borders: {
         bottom: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: 17,
        children: [
          new Paragraph({
            children: [
             
              new TextRun({
                text: "Observation compliance report",
                font: fontName,
                size: commonFontSize+2,
                allCaps: true,
                bold: true,
              }),
            ],
          }),
        ],
      }),

      new TableCell({
        margins: {
          top: 50,
          left: 80,
          bottom: 50,
        },
        borders: {
          left: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
         bottom: commonBorderStyle,
        },

        columnSpan: 3,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Doc No: ",
                size: 16,
                bold: true,
                font: fontName,
              }),
              new TextRun({
                text: "QF 9001 13",
                size: 16,
                font: fontName,
              }),
            ],
            spacing: {
              after: 50,
            },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "Rev No: ",
                size: 16,
                font: fontName,
                bold: true,
              }),
              new TextRun({
                text: "R1",
                size: 16,
                font: fontName,
              }),
            ],
            spacing: {
              after: 50,
            },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "Date: ",
                size: 16,
                font: fontName,
                bold: true,
              }),
              new TextRun({
                text: "15/12/2016",
                size: 16,
                font: fontName,
              }),
            ],
            spacing: {
              after: 50,
            },
          }),
        ],
      }),
    ],
  })
);

/// Date: 
tempRowData.push(
  new TableRow({
    children: [
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
          left:80
        },
        borders: {
         bottom: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: 23,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Date: ",
                font: fontName,
                size: commonFontSize,
                allCaps: true,
                bold: true,
              }),
              new TextRun({
                text: " ",
                font: fontName,
                size: commonFontSize,
              }),
            ],
          }),
        ],
      }),
    ],
  })
);

 /// Audit No: 
 tempRowData.push(
  new TableRow({
    children: [
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
          left:80
        },
        borders: {
         bottom: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: 23,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Audit No: ",
                font: fontName,
                size: commonFontSize,
                allCaps: true,
                bold: true,
              }),
              new TextRun({
                text: " ",
                font: fontName,
                size: commonFontSize,
             
              }),
            ],
          }),
        ],
      }),
    ],
  })
);
 /// Site/Dept: 
 tempRowData.push(
  new TableRow({
    children: [
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
          left:80
        },
        borders: {
         bottom: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: 23,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "site/Dept: ",
                font: fontName,
                size: commonFontSize,
                allCaps: true,
                bold: true,
              }),
              new TextRun({
                text: '',
                font: fontName,
                size: commonFontSize,
              
              }),
            ],
          }),
        ],
      }),
    ],
  })
);

/// Auditee: 
tempRowData.push(
  new TableRow({
    children: [
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
          left:80
        },
        borders: {
         bottom: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: 23,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "auditee: ",
                font: fontName,
                size: commonFontSize,
                allCaps: true,
                bold: true,
              }),
              new TextRun({
                text: " ",
                font: fontName,
                size: commonFontSize,
                
              }),
            ],
          }),
        ],
      }),
    ],
  })
);

/// Auditor
tempRowData.push(
  new TableRow({
    children: [
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
          left:80
        },
        borders: {
         bottom: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: 23,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "auditor: ",
                font: fontName,
                size: commonFontSize,
                allCaps: true,
                bold: true,
              }),
              new TextRun({
                text: " ",
                font: fontName,
                size: commonFontSize,
               
              }),
            ],
          }),
        ],
      }),
    ],
  })
);

/// Srno, Observationof audit, compilance with objective
tempRowData.push(
  new TableRow({
    children: [
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
     
        },
        borders: {
         bottom: commonBorderStyle,
         right: commonBorderStyle,
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: 3,
        children: [
          new Paragraph({
            alignment:AlignmentType.CENTER,
            children: [
              new TextRun({
                text: "Sr No",
                font:fontName,
                size:commonFontSize,
                bold:true
              }),
            ],
          }),
        ],
      }),
      new TableCell({
          margins: {
            top: 50,
            bottom: 50,
         
          },
          borders: {
           bottom: commonBorderStyle,
           right:commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: 10,
          children: [
            new Paragraph({
              alignment:AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Observation of Audit",
                  font:fontName,
                  size:commonFontSize,
                  bold:true

                }),
              ],
            }),
          ],
        }),
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
        
          },
          borders: {
           bottom: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: 10,
          children: [
            new Paragraph({
              alignment:AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Compailance with Objective Evidance",
                  font:fontName,
                  size:commonFontSize,
                  bold:true
                }),
              ],
            }),
          ],
        }),
    ],
  })
);

/// empty rows
[...Array(15)].map((item,idx)=>{

      tempRowData.push(
          new TableRow({
            children: [
              new TableCell({
                margins: {
                  top: 50,
                  bottom: 50,
                  
                },
                borders: {
                 bottom: commonBorderStyle,
                 right: commonBorderStyle,
                },
                verticalAlign: AlignmentType.CENTER,
                columnSpan: 3,
                children: [
                  new Paragraph({
                    alignment:AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: idx+1,
                        font:fontName,
                        size:commonFontSize,
                  
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                  margins: {
                    top: 50,
                    bottom: 50,
                    left:80
                  },
                  borders: {
                   bottom: commonBorderStyle,
                   right:commonBorderStyle,
                  },
                  verticalAlign: AlignmentType.CENTER,
                  columnSpan: 10,
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: " ",
                          font:fontName,
                          size:commonFontSize,
                          
                        }),
                      ],
                    }),
                  ],
                }),
                new TableCell({
                  margins: {
                    top: 50,
                    bottom: 50,
                    left:80
                  },
                  borders: {
                   bottom: commonBorderStyle,
                  },
                  verticalAlign: AlignmentType.CENTER,
                  columnSpan: 10,
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: " ",
                        }),
                      ],
                    }),
                  ],
                }),
            ],
          })
        )
})

/// Defination
tempRowData.push(
  new TableRow({
    children: [
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
          left:80
        },
        borders: {
         bottom: commonBorderStyle,
        
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: 23,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Definition:",
              }),
            ],
          }),
          new Paragraph({
              children: [
                new TextRun({
                  text: "Observation (OBS): Lapse in fulfillment of a requirement at the time of audit which is likely to turn into Non-confirmity",
                  font:fontName,
                  size:commonFontSize,
                
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "(Requirement: ISO 9001: 2015 standard’s Requirement and Requirement as per Quality Management system)",
                  font:fontName,
                  size:commonFontSize,
                
                }),
              ],
            }),
        ],
      }),
     
    ],
  })
);


/// Signature
tempRowData.push(
  new TableRow({
    children: [
      new TableCell({
        margins: {
          top: 50,
          bottom: 50,
          left:80,
          right:80
        },
        borders: {
         bottom: commonBorderStyle,
         
        },
        verticalAlign: AlignmentType.CENTER,
        columnSpan: 23,
        children: [
          new Paragraph({
            spacing:{
              after:50,
              before:450
            },
            alignment:AlignmentType.END,
            children: [
              new TextRun({
                text: "Signature",
                font:fontName,
                size:commonFontSize,
                allCaps:true,
                bold:true
                
              }),
            ],
          }),
         
        ],
      }),
     
    ],
  })
);


  observationCompilanceContent.push(
      new Table({
          margins:{
            bottom:100
          },
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: {
            bottom:{style:BorderStyle.NONE},
            left:{style:BorderStyle.NONE},
            top:{style:BorderStyle.NONE},
            right:{style:BorderStyle.NONE},
    
           
            insideHorizontal: {
              style: BorderStyle.NONE,
            },
            insideVertical: { style: BorderStyle.NONE },
          },
          rows:tempRowData
        })
  )


return [...observationCompilanceContent]

}
