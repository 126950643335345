import styled from 'styled-components';

export const SaveButton = styled.button`
    color: rgba(${ props => props.themeTextColor.r }, ${ props => props.themeTextColor.g }, ${ props => props.themeTextColor.b }, ${ props => props.themeTextColor.a });;
    border: none;
    border-radius: 5px;
    padding: 7px 25px;
    background: rgba(${ props => props.themeColor.r }, ${ props => props.themeColor.g }, ${ props => props.themeColor.b }, ${ props => props.themeColor.a });
    font-size: 13px;

    :hover{
        color:rgba(${ props => props.themeHoverTextColor.r }, ${ props => props.themeHoverTextColor.g }, ${ props => props.themeHoverTextColor.b }, ${ props => props.themeHoverTextColor.a });
        background: rgba(${ props => props.themeHoverColor.r }, ${ props => props.themeHoverColor.g }, ${ props => props.themeHoverColor.b }, ${ props => props.themeHoverColor.a });
    }
`;
export const ProfileSidebarMenuSample = styled.div`
    .veritical-menu-profile{
        margin-top: 10px;
    }
    .vertical-menu-profile-type1{
        text-align: center;
    }
    .vertical-menu-profile-type1 a{
        font-size: 14px;
    }
    
    .vertical-menu-profile-type1 a {
        font-size: 15px;
        display: inline-block;
        color: #404040;
    }
    .vertical-menu-profile-type1 img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
    }
    .vertical-menu-profile-type1 hr {
        margin-top: 15px;
        margin-bottom: 15px;
        border-top: 1px solid rgba(134, 134, 134, 0.3);
    }
    .client-profile-bg-type1{
        position:relative;
        margin-bottom:10px;
    }
    .client-profile-type1 {
        justify-content: center;
        align-items: center;
        display: flex;
        margin-bottom: 0px;
        margin-top: 5px;
        cursor: pointer;
    }
    .client-profile-type1 span {
        line-height: 1;
        margin-left: 5px;
    }
    .client-profile-type1 h6{
        padding:0;
        border:none;
        color:rgba(${ props => props.sidebarTextColor.r }, ${ props => props.sidebarTextColor.g }, ${ props => props.sidebarTextColor.b }, ${ props => props.sidebarTextColor.a });
        
    }
    .profile-pic-circle{
        background:rgba(${ props => props.sidebarSelectedItemBg.r }, ${ props => props.sidebarSelectedItemBg.g }, ${ props => props.sidebarSelectedItemBg.b }, ${ props => props.sidebarSelectedItemBg.a });
        color:rgba(${ props => props.sidebarIconColor.r }, ${ props => props.sidebarIconColor.g }, ${ props => props.sidebarIconColor.b }, ${ props => props.sidebarIconColor.a });
        width: 50px;
        height: 50px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profile-pic-circle h6{
        font-size: 20px;
        color: #fff;
        padding: 0;
        margin: 0;
        border-bottom: 0px;
    }
    .logo-type1{
        border-radius: 0px !important;
        height: auto !important;
        width: 80px !important;
        margin-bottom: 10px;
    }

    ul {
        padding-left: 0px;
    }
    ul li {
        list-style: none;
        margin-bottom: 2px;
    }
    a {
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        padding: 5px 8px;
    }
    a span {
        line-height: 1;
        color:rgba(${ props => props.sidebarIconColor.r }, ${ props => props.sidebarIconColor.g }, ${ props => props.sidebarIconColor.b }, ${ props => props.sidebarIconColor.a });
    }
    a p {
        font-weight: 500;
        margin-bottom: 0px;
        margin-left: 7px;
        font-size: 13px;
        line-height: 1;
        color:rgba(${ props => props.sidebarTextColor.r }, ${ props => props.sidebarTextColor.g }, ${ props => props.sidebarTextColor.b }, ${ props => props.sidebarTextColor.a });
    }
    a:hover {
        background:rgba(${ props => props.sidebarHoverItemBg.r }, ${ props => props.sidebarHoverItemBg.g }, ${ props => props.sidebarHoverItemBg.b }, ${ props => props.sidebarHoverItemBg.a });
        border-radius: 5px;
    }
    a:hover span{
        color:rgba(${ props => props.sidebarHoverItemIconColor.r }, ${ props => props.sidebarHoverItemIconColor.g }, ${ props => props.sidebarHoverItemIconColor.b }, ${ props => props.sidebarHoverItemIconColor.a });
    }
    a:hover p{
        color:rgba(${ props => props.sidebarHoverItemTextColor.r }, ${ props => props.sidebarHoverItemTextColor.g }, ${ props => props.sidebarHoverItemTextColor.b }, ${ props => props.sidebarHoverItemTextColor.a });
    }
    ul li.active a{
        background:rgba(${ props => props.sidebarSelectedItemBg.r }, ${ props => props.sidebarSelectedItemBg.g }, ${ props => props.sidebarSelectedItemBg.b }, ${ props => props.sidebarSelectedItemBg.a });
        border-radius: 5px;
    }
    ul li.active a span{
        color:rgba(${ props => props.sidebarSelectedItemIconColor.r }, ${ props => props.sidebarSelectedItemIconColor.g }, ${ props => props.sidebarSelectedItemIconColor.b }, ${ props => props.sidebarSelectedItemIconColor.a });
    }
    ul li.active a p{
        color:rgba(${ props => props.sidebarSelectedItemTextColor.r }, ${ props => props.sidebarSelectedItemTextColor.g }, ${ props => props.sidebarSelectedItemTextColor.b }, ${ props => props.sidebarSelectedItemTextColor.a });
    }
`;

export const HeaderRight = styled.div`
    .info-header{
        position: relative;
        display: flex;
        align-items: center;
    }

    .info-header-wrapper{
        display: flex;
        align-items: center;
    }

    .contact-suppport-layout{
        margin-right: 10px;
        line-height: 1;
        cursor: pointer;
    }
    .contact-suppport-layout span{
        color:rgba(${ props => props.iconColor.r }, ${ props => props.iconColor.g }, ${ props => props.iconColor.b }, ${ props => props.iconColor.a });
    }
    .info-layout-wrapper{
        margin-right: 10px;
        border:1px solid transparent;
    }
    .info-layout-wrapper:hover{
        border:1px solid rgba(${ props => props.iconColor.r }, ${ props => props.iconColor.g }, ${ props => props.iconColor.b }, ${ props => props.iconColor.a });
        border-radius: 100%;
    }
    .topbar-info-layout{
        margin: 2px;
        cursor: pointer;
        border-radius: 100%;
        background:rgba(${ props => props.iconColor.r }, ${ props => props.iconColor.g }, ${ props => props.iconColor.b }, ${ props => props.iconColor.a });
        display: flex;
        align-items: center;
        padding: 4px;
    }
    .topbar-info-layout img{
        height:16px;
        width:16px;
        padding: 0px;
    }
    .header-notification-wrapper{
        position: relative;
    }
    .header-notification{
        width:30px;
        height:30px;
        text-align: center;
    }
    .header-notification:hover{
        border-radius: 100%;
        background:rgba(${ props => props.selectedIconBg.r }, ${ props => props.selectedIconBg.g }, ${ props => props.selectedIconBg.b }, ${ props => props.selectedIconBg.a });
    }
    .header-notification span{
        font-size: 26px;
        line-height: 1.2;
        padding:0px;
        color:rgba(${ props => props.iconColor.r }, ${ props => props.iconColor.g }, ${ props => props.iconColor.b }, ${ props => props.iconColor.a });
    }
    .noti-badge{
        position: absolute;
        background: rgba(${ props => props.badgeBg.r }, ${ props => props.badgeBg.g }, ${ props => props.badgeBg.b }, ${ props => props.badgeBg.a });
        border-radius: 100%;
        width: 15px;
        line-height: 11px;
        height: 15px;
        right: 0px;
        top:0px;
        text-align: center;
    }
    .noti-badge span{
        font-size: 8px !important;
        display: inline-block;
        color:rgba(${ props => props.badgeTextColor.r }, ${ props => props.badgeTextColor.g }, ${ props => props.badgeTextColor.b }, ${ props => props.badgeTextColor.a });
        font-weight:600;
        padding: 0px !important;
    }
    .vertical-line{
        background: #ddd;
        width: 1px;
        height: 30px;
        margin-left: 15px;
    }
    .ams-logo img{
        height: 15px;
        margin-left: 20px;
    }
`;