import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import * as Constants from "../../../../common/Global/constants";
import * as TemplateConstants from "../../../../common/Global/templateConstants";
import Sidebar from "../../../../common/SidebarCollapse";
import Topbar from "../../../../common/Topbar";
import { getLocalStorageVariables, getThemeSettingsVariables, isEmptyArray, isEmptyVariable, isJsonString, removeHtmlTags } from "../../../../common/Global/commonFunctions";
import { getCheckListScoresFlag, getRankingArray } from "../../../Execute/Tasks/execTaskUtilFunctions";
import FilterDropDown from "../../../../common/DropdownMenus/FilterDropdown";
import RegularDD from '../../../../common/DropdownMenus/RegularDropdown';
import RegularDropdown from '../../../../common/DropdownMenus/RegularDropdownTable';
import moment from "moment";
import DatePickerComponent from "../../../Tasks/Common/dateSelectComponent";
import TableDropDown from "../../../../common/DropdownMenus/TableDropdown";
import FollowUpDetailsDialog from "../../../Execute/FollowUp/followUpDetailsDialog";

const defaultWeightPlaceholder = "Rank";
const defaultYNPlaceholder = "Rank";
const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const filterOpenCloseDropDownArr = [
    {
        label: "Checked & Open",
        isSelected: true,
    },
    {
        label: "Close",
        isSelected: false,
    },
];
class CompletedFollowUpTask extends Component {
    constructor(props) {
        super(props);
        const item = props.location.state;
        this.state = {
            assignmentId: props.match.params.id,
            departmentId: item.departmentId,
            observationTemplateId: item.observationTemplateId,
            observationTaskFilterDropDownArr: item.observationTaskFilterDropDownArr,
            observationTaskFilterPlaceholder: item.observationTaskFilterPlaceholder,

            componentDidMountFlag: false,
			showLoader: false,
            currentPageNo: 1,
            resultSize: Constants.RESULT_SIZE,
			datalist: [],
            allDatalist:[],

            assignmentStatus: "",
            assignmentArea: "",
            assignmentDetails: "",

            selectedTaskTemplateFields: [],
            taskTemplateValuesMap: {},
            execTaskTemplateFields: [],
            executionTemplateValuesMap: {},
            executionTemplateFixedFields: {},

            overallChecklistScoreObj: {},
            checklistMaxRank: 0,

            filterDataList: [],
            filterDropDownArr: [],
            filterPlaceholder: "All",

            followUpObservation:[],
            filterOpenCloseDropDownArr:filterOpenCloseDropDownArr,
            filterOpenClosePlaceholder: "Open",
            showFollowUpTemplateValueDialog:false,
            followUpTaskTemplateList: [],
            singleSelectDropDownArr: [],

            showFollowUpDetailsDialog: false,
            followUpDetailsItem:{},
        }
    }

    componentDidMount() {
        this.getInitData();
	}

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.setState({
                componentDidMountFlag:false,
                showLoader:false,
                datalist:[],
            },()=>{
                this.getInitData();
            })
        }
    }

    taskFilterDropDownOnClicks = (selectedItem) => {
        let tempObservationFilterDataArray = this.state.observationTaskFilterDropDownArr;
        Object.values(tempObservationFilterDataArray).map((item) => {
            if (selectedItem.label === item.label) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });
       
        if(selectedItem.label === "Observation"){
            this.props.history.replace(Constants.URL_COMPLETED_FOLLOWUP + "/" + this.state.assignmentId,{
                departmentId:this.state.departmentId,
                observationTemplateId:this.state.observationTemplateId,
                observationTaskFilterDropDownArr:tempObservationFilterDataArray,
                observationTaskFilterPlaceholder:selectedItem.label,
            }); 
        }
    }

    singleSelectFilterDropDownOnClick = (fieldId,selectedItem) => {
        let placeholder = "";
        let tempSingleSelectFilterDataArray = this.state.singleSelectDropDownArr;
        
        //Set placeholder based on selection 
        Object.values(tempSingleSelectFilterDataArray).forEach(item => {
            if(item.fieldId === fieldId){
                Object.values(item.dropdownValues).map(dropdownItem => {
                    if (selectedItem.label === dropdownItem.label) {
                        dropdownItem.isSelected = true;
                        if(dropdownItem.label!=="All"){
                            placeholder = dropdownItem.label;
                        }
                        item.placeholderValue = placeholder;
                    }else{
                        dropdownItem.isSelected = false;
                    }
                })
            }
        })

        // Single Select filter datalist based on dropdown item selection
        let datalist = [];
        let tempFilterSingleSelectPlaceHolderDataList = tempSingleSelectFilterDataArray.filter(item => !isEmptyVariable(item.placeholderValue));
        let selectedPlaceHolder = tempFilterSingleSelectPlaceHolderDataList.map(item => item.placeholderValue);

        if(tempFilterSingleSelectPlaceHolderDataList.length === 0){
            datalist = this.state.allDatalist;
        }else{
            tempSingleSelectFilterDataArray.map(singleSelectItem => {
                datalist = this.state.allDatalist.map(functionObj=>({
                    ...functionObj,
                    taskList: functionObj.taskList.filter(taskItem => {
                        return selectedPlaceHolder.includes(this.state.executionTemplateValuesMap[taskItem.assignmentTaskId]?.[singleSelectItem.fieldId]?.["valueNR"]==="Y"?
                        "NA":this.state.executionTemplateValuesMap[taskItem.assignmentTaskId]?.[singleSelectItem.fieldId]?.["value"])
                    })
                })).filter(item => item.taskList.length > 0);
            })
        }
        let tempFilterDataArray = this.state.filterDropDownArr;
        tempFilterDataArray.map((item) => {
            if (item.label === "All") {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });

        this.setState({
            datalist: datalist,
            singleSelectDropDownArr: tempSingleSelectFilterDataArray,
            filterDataList:[],
            filterDropDownArr: tempFilterDataArray,
            filterPlaceholder:this.state.assignmentArea
        });
    }

    singleSelectAll = () => {
        //Single Select filter selection default All
        let tempSingleSelectFilterDataArray = this.state.singleSelectDropDownArr;
        Object.values(tempSingleSelectFilterDataArray).forEach(item => {
            Object.values(item.dropdownValues).map(dropdownItem => {
                if (dropdownItem.label === "All") {
                    dropdownItem.isSelected = true;
                    item.placeholderValue = "";
                }else{
                    dropdownItem.isSelected = false;
                }
            })
        })
        this.setState({
            singleSelectDropDownArr: tempSingleSelectFilterDataArray
        })
    }

    filterDropDownOnClick = (selectedItem) => {
        let tempFilterDataList = [];
        let tempFilterDataArray = this.state.filterDropDownArr;

        if (selectedItem.label === 'All') {
            this.setState({
                filterDataList: [],
            })
        }
        else if (selectedItem.label !== "All") {
            let tempItem = [];
            Object.values(this.state.datalist).map((item) => {
                let tempDataTaskList = []
                tempItem = item
                Object.values(item.taskList).map((task) => {
                    if ((selectedItem.label === (this.state.assignmentArea === "Process" ? item.processName : this.state.assignmentArea === "Function" ? item.functionName : item.heading))) {
                        tempDataTaskList.push(task)
                    }
                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }
        tempFilterDataArray.map((item) => {
            if (item.label === selectedItem.label) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });

        this.singleSelectAll();

        this.setState({
            filterPlaceholder: selectedItem.label,
            filterDataList: tempFilterDataList,
            filterDropDownArr: tempFilterDataArray
        })
    }; 

    getCompletedTask = (allDataList,assignmentArea,maxRank) => {
        let allData = JSON.parse(JSON.stringify(allDataList));
        let arr = allData.map((process) => {
            let taskList = [];
            let maxScore = 0;
            let totalScore = 0;
            process.taskList.forEach((task) => {
                if (task.completionStatus === "Completed") {
                    taskList.push(task);

                    //Now if it is checklist, then based on the type of checklist we need to assign scores
                    if (assignmentArea === "CheckList") {
                        let weight = isEmptyVariable(task.weight) ? 1 : task.weight;
                        let rank =
                            isEmptyVariable(task.rank) || task.rank === -1 ? 0 : task.rank;

                        //if rank is NA then that should be considered for max score also
                        let maxRankNew = task.rank === -1 ? 0 : maxRank;
                        maxScore += weight * maxRankNew;
                        totalScore += weight * rank;
                    }
                }
            });
            process.taskList = taskList;
            process.maxScore = maxScore;
            process.totalScore = totalScore;
            return process;
        });
        return arr;
    };

    getTaskDisplayValues = (taskId, fieldItem) => {
        if (isEmptyVariable(this.state.taskTemplateValuesMap[taskId])) {
            return "-";
        }

        let fieldId = fieldItem.fieldId;
        if (isEmptyVariable(this.state.taskTemplateValuesMap[taskId][fieldId])) {
            return "-";
        }

        let displayValue =
            this.state.taskTemplateValuesMap[taskId][fieldId]["value"];
        if (isEmptyVariable(displayValue)) {
            return "-";
        }

        switch (fieldItem.fieldType) {
            case TemplateConstants.FIELD_TYPE_TEXT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DATE:
                return isEmptyVariable(displayValue)
                    ? ""
                    : moment(displayValue).format("DD/MM/YYYY");
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DROPDOWN:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
                if (!isJsonString(displayValue)) {
                    return "-";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                if (!isJsonString(displayValue)) {
                    return "-";
                }
                return JSON.parse(displayValue).join(", ");
        }
        return "-";
    };

    getProcessGroupingTaskList = (Datalist, assignmentArea) => {
        if (assignmentArea === "Function") {
            let tempProcessList = [];
            let processName = [];
            let tempAllDataList = Object.values(Datalist);
            Object.values(Datalist).map((item) => {
                Object.values(item.taskList).map((task) => {
                    if (!processName.includes(task.processNameList)) {
                        processName.push(task.processNameList)
                    }
                })
            })

            tempAllDataList.map((item) => {
                let tempTaskList = [];
                tempProcessList = [];
                let tempItem = item;
                let tempProcessId = "";
                processName.map((process) => {
                    tempTaskList = [];
                    Object.values(item.taskList).map((task) => {
                        tempProcessId = task.processIdList
                        if (task.processNameList === process) {
                            tempTaskList.push(task)
                        }
                    })

                    tempProcessList.push({
                        processName: process,
                        functionName: item.functionName,
                        processId: tempProcessId,
                        taskList: tempTaskList
                    })
                    tempItem["processTaskList"] = tempProcessList
                })
            })
            return tempAllDataList
        }
        else {
            return Datalist
        }
    }
    /*################################## EXEC TASK RELATED #################################*/
    getExecTableDataDisplayValue = (assignmentTaskId, fieldItem) => {
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId])) {
            return "";
        }

        let fieldId = fieldItem.fieldId;
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId][fieldId])) {
            return "";
        }

        let displayValue = this.state.executionTemplateValuesMap[assignmentTaskId][fieldId]["value"];
        if (isEmptyVariable(displayValue)) {
            return "";
        }

        switch (fieldItem.fieldType) {
            case TemplateConstants.FIELD_TYPE_TEXT:
                return removeHtmlTags(displayValue);
            case TemplateConstants.FIELD_TYPE_DATE:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DROPDOWN:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
                if (!isJsonString(displayValue)) {
                    return "";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                if (!isJsonString(displayValue)) {
                    return "";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_TABLE:
                if (!isJsonString(displayValue)) {
                    return "";
                }
                return JSON.parse(displayValue);
        }
        return "";
    };

    getExecTableDataDisplayValueNR = (assignmentTaskId, fieldItem) => {
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId])) {
            return "";
        }

        let fieldId = fieldItem.fieldId;
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId][fieldId])) {
            return "";
        }

        let displayValue = this.state.executionTemplateValuesMap[assignmentTaskId][fieldId]["valueNR"];
        if (displayValue === "Y") {
            return "Y";
        }else{
            return "N"
        }
    };

    getExecTaskDisplayTableData = (assignmentTaskId,fieldItem) => {
        switch (fieldItem.fieldType) { 
            case TemplateConstants.FIELD_TYPE_TEXT:
                return (
                    <td style={{height:"100%"}}>
                        <div
                            contenteditable={"false"}
                            className="exec-task-text-field"
                        >
                            {this.getExecTableDataDisplayValue(assignmentTaskId, fieldItem)}
                        </div>
                    </td>
                );
            case TemplateConstants.FIELD_TYPE_DATE:
                let displayLabelDate = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                return (
                    <td>
                        <DatePickerComponent
                            templateItemIndex={assignmentTaskId}
                            fieldItem={fieldItem}
                            onDateChange={this.onExecuteDateFieldChange}
                            placeholderValue={"Enter " + fieldItem.fieldName}
                            fieldValue={
                                !isEmptyVariable(displayLabelDate) ? displayLabelDate : ""
                            }
                            isDisabled={"Y"}
                        />
                    </td>
                );
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                let displayLabel = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                let displayLabelNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );
                return (
                    <td>{displayLabelNR == "Y"?"NA":displayLabel}</td>
                );
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
                let displayLabelMulti = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                let displayLabelMultiNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );
                return (
                    <td>{displayLabelMultiNR == "Y"?"NA":displayLabelMulti}</td>
                );
            case TemplateConstants.FIELD_TYPE_TABLE:
                let parsedJsonTableObj = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                let displayLabelTableNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );
                return (
                    <td>
                        {
                            displayLabelTableNR == "Y"
                            ?"NA"
                            :(isEmptyVariable(parsedJsonTableObj) ? "-" : this.getAssignmentTaskExecTable(parsedJsonTableObj))
                        }
                    </td>
                );
        }
    };
    
    getFirstRowItemValue = (item,idx) =>{
        let tempFirstRowList =["yes / no with disable","yes / no / na with disable","yes / no","yes / no / na","textfield"]
        if(idx==0){
            if(!tempFirstRowList.includes(item.toLocaleLowerCase())){
                return item
            }else{
                return ""
            }
        }else if(idx!=0){
            return item
        }
    }

    getAssignmentTaskExecTable = (parsedJsonTableObj) => {
        return <table className="my-2">
            {
                Object.values(parsedJsonTableObj.tableArray).map((item, idx) => {
                    return <tr>
                        <td style={{ fontSize: 10, minwidth: "300px", Height: "22px" }}>{item[0]}</td>
                        <td style={{ fontSize: 10, minwidth: "300px", Height: "22px" }}>{this.getFirstRowItemValue(item[1],idx)}</td>
                    </tr>
                })
            }
        </table>
    }
    /*################################## EXEC TASK RELATED ENDS#################################*/

    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader: true,
        });

        Promise.all([
            fetch(Constants.GetFreezeFollowUpTasks, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                    departmentId: this.state.departmentId
                }),
            }),
            fetch(Constants.GetAssignmentsDetails, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                }),
            }),
        ])
        .then(([taskRes, assignmentRes]) => {
            return Promise.all([
                taskRes.json(),
                assignmentRes.json()
            ]);
        })
        .then(([taskRes, assignmentRes]) => {
            let allDatalistTemp = [];
            let assignmentStatus = "";
            let assignmentArea = "";
            let assignmentDetails = {};
            let completedDatalistTemp = [];
            let singleSelectFilters = [];
            let singleSelectFiltersList = [];

            //Since we need assignment area lets check this first
            let selectedTaskTemplateFields = [];
            let execTaskTemplateFields = [];
            let taskTemplateValuesMap = {};
            let executionTemplateValuesMap = {};
            let executionTemplateFixedFields = {};

            if (
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
                assignmentArea = assignmentRes.data.assignmentDetails.assignmentArea;
                assignmentDetails = assignmentRes.data.assignmentDetails;


                //TASK TEMPLATE RELATED STUFFS
                taskTemplateValuesMap = assignmentRes.data.taskTemplateValuesMap;
                let selectedTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.taskTemplateFields; //Template field IDs
                if (
                    !isEmptyVariable(selectedTaskTemplateFieldsStr) &&
                    isJsonString(selectedTaskTemplateFieldsStr)
                ) {
                    selectedTaskTemplateFields = JSON.parse(selectedTaskTemplateFieldsStr);
                }

                //EXEC TASK RELATED STUFFS
                executionTemplateValuesMap = assignmentRes.data.executionTemplateValuesMap;

                let execTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.templateFields;
                if (
                    !isEmptyVariable(execTaskTemplateFieldsStr) &&
                    isJsonString(execTaskTemplateFieldsStr)
                ) {
                    execTaskTemplateFields = JSON.parse(execTaskTemplateFieldsStr);
                }

                //EXEC TASK FIXED FIELDS
                let fixedTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.fixedTemplateFields;
                if (
                    !isEmptyVariable(fixedTemplateFieldsStr) &&
                    isJsonString(fixedTemplateFieldsStr)
                ) {
                    executionTemplateFixedFields = JSON.parse(fixedTemplateFieldsStr);
                }

            } else {
            }

            let maxRank = 0;
            let showChecklistScores = false;
            let overallChecklistScoreObj = {};
            let assignmentChecklistObj = {};

            if (
                taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (taskRes.responseCode === Constants.CODE_SUCCESS) {
                allDatalistTemp = taskRes.data.assignedTasks;
                singleSelectFilters = taskRes.data.singleSelectFilters;
                //show only Selected User's/My tasks from the tasks list
                completedDatalistTemp = this.getCompletedTask(allDatalistTemp, assignmentArea, maxRank);
                //if assignment area is checklist we may need to show scores in certain cases
                if (assignmentArea === "CheckList") {
                    assignmentChecklistObj = taskRes.data.assignmentCheckList;
                    overallChecklistScoreObj = taskRes.data.assignmentScore;
                    showChecklistScores = getCheckListScoresFlag(
                        assignmentChecklistObj.checkListType
                    );
                    if (showChecklistScores) {
                        maxRank = assignmentChecklistObj.maxRank;
                    }
                }
            } else {
            }

            //Lets check few things that are needed for checklist based assignment
            let showWeight = false;
            let showRanking = false;
            let showYesNoDD = false;
            let minRank = 0;
            let rankingArray = getRankingArray(minRank, maxRank);

            if (!isEmptyArray(allDatalistTemp) && assignmentArea === "CheckList") {
                let firstItem = allDatalistTemp[0];
                minRank = firstItem.taskList[0].minRank;

                if (!isEmptyArray(firstItem.taskList)) {
                    if (firstItem.taskList[0].checkListType === "WR") {
                        showWeight = true;
                        showRanking = true;
                    } else if (firstItem.taskList[0].checkListType === "R") {
                        showRanking = true;
                    } else if (firstItem.taskList[0].checkListType === "Y/N") {
                        showYesNoDD = true;
                    }
                }
            }

            let tempFilterArray = [
                {
                    label: "All",
                    isSelected: true,
                },
            ];

            Object.values(allDatalistTemp).map((item) => {
                tempFilterArray.push({
                    label: assignmentArea === "Process" ? item.processName : assignmentArea === "Function" ? item.functionName : item.heading,
                    isSelected: false,
                });
            });
            
            //Set filter for single select
            Object.values(singleSelectFilters).forEach(item => {
                let tempSingleSelectItem = {};
                tempSingleSelectItem.fieldId = item.fieldId;
                tempSingleSelectItem.fieldName = item.fieldName;
                let tempSingleSelectArr = [
                    {
                        label: "All",
                        isSelected: true
                    },
                    {
                        label:"NA",
                        isSelected: false
                    }
                ];
                Object.values(item.dropdownValues).map(dropdownItem => {
                    tempSingleSelectArr.push({
                        label: dropdownItem.value,
                        isSelected: false
                    })
                })
                tempSingleSelectItem.dropdownValues = tempSingleSelectArr;
                tempSingleSelectItem.placeholderValue = "";
                singleSelectFiltersList.push(tempSingleSelectItem);
            });

            this.setState({
                componentDidMountFlag: true,
                showLoader: false,
                datalist: allDatalistTemp,
                allDatalist: allDatalistTemp,

                selectedTaskTemplateFields: selectedTaskTemplateFields,
                taskTemplateValuesMap: taskTemplateValuesMap,
                execTaskTemplateFields: execTaskTemplateFields,
                executionTemplateValuesMap: executionTemplateValuesMap,
                executionTemplateFixedFields: executionTemplateFixedFields,

                assignmentStatus: assignmentStatus,
                assignmentArea: assignmentArea,
                assignmentDetails: assignmentDetails,

                singleSelectDropDownArr:singleSelectFiltersList,

                showWeight: showWeight,
                showRanking: showRanking,
                showYesNoDD: showYesNoDD,
                checklistMaxRank: maxRank,
                showChecklistScores: showChecklistScores,
                overallChecklistScoreObj: overallChecklistScoreObj,
                rankingArray: rankingArray,
                filterDropDownArr: tempFilterArray,
            });
        });
    };

    getFollowUpTaskTab = () => {
        this.setState({
            showLoader: true,
        });

        fetch(Constants.GetFollowUpTaskTab, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.state.assignmentId,
                departmentId: this.state.departmentId
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                let assignmentChecklistObj = {};
                let overallChecklistScoreObj = {};

                let allDatalistTemp = data.data.assignedTasks;

                let maxRank = 0;
                if (this.state.assignmentArea === "CheckList") {
                    assignmentChecklistObj = data.data.assignmentCheckList;
                    overallChecklistScoreObj = data.data.assignmentScore;

                    if (
                        assignmentChecklistObj.checkListType === "Y/N" ||
                        assignmentChecklistObj.checkListType === "WR" ||
                        assignmentChecklistObj.checkListType === "R"
                    ) {
                        maxRank = assignmentChecklistObj.maxRank;
                    }
                }

                this.setState({
                    showLoader: false,
                    datalist: allDatalistTemp,
                    overallChecklistScoreObj: overallChecklistScoreObj,
                });
            } else {
                this.setState({
                    datalist: [],
                    showLoader: false,
                });
            }
        });
    };

    /*******************API CALLS END HERE*******************/

    onTableDropDownItemClick = (item, dropdownItem) => {
        if (item.label === "Follow Up Details") {
            this.getPreviousFollowUpTaskTemplateValues(dropdownItem.assignmentTaskId, dropdownItem.observationId, dropdownItem);
        } 
    };

    getPreviousFollowUpTaskTemplateValues = (assignmentTaskId,observationId,item) => {
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
        }
        if(isEmptyVariable(observationId)){
            postParam.assignmentTaskId = assignmentTaskId;
        }else{
            postParam.observationId = observationId;
        }
        fetch(Constants.GetPreviousFollowUpTaskTemplateValues, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postParam),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                const previousCheckAndOpen = data.data.previousCheckAndOpen;
                this.handleFollowUpDetailsDialogShow(previousCheckAndOpen,assignmentTaskId,item);
            } else {
                this.setState({
                    showFollowUpDetailsDialog:false
                });
            }
        });
    }

    handleFollowUpDetailsDialogShow = (followUpItem,assignmentTaskId,item) => {
        this.setState({
            showFollowUpDetailsDialog:true,
            followUpDetailsItem:followUpItem,
            assignmentTaskId:assignmentTaskId,
            followUpTaskItem:true
        });
    }
    
    handleFollowUpDetailsDialogClose = () => {
        this.setState({
            showFollowUpDetailsDialog:false
        });
    }

    
     render() {
        let showEmptyCard = true;
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                        <div className="dashboard-right-bg-collpase">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.showLoader && (
                                    <div className="loading-layout" style={{ background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`, }}>
                                        <div className="loading-text-layout">
                                            <p style={{ color: `rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})` }}>
                                                Loading....
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {
                                    <div className="search-addnew-bg">
                                        <div className="search-addnew">
                                            <div className="search-filter-layout">
                                                <p className="font-bold" style={{marginTop:10, justifyContent:'flex-start'}}>Follow Up</p>
                                            </div>
                                        
                                            <div className="flex-center-layout">
                                                {!isEmptyVariable(this.state.observationTemplateId) &&
                                                    <div style={{ marginRight: 10 }}>
                                                        <FilterDropDown
                                                            placeholder={this.state.observationTaskFilterPlaceholder}
                                                            dropdownArr={this.state.observationTaskFilterDropDownArr}
                                                            onDropDownItemClick={this.taskFilterDropDownOnClicks}
                                                            themeSettings={themeSettings}
                                                            name={"label"}
                                                            dropdownWidth="200px"
                                                        />
                                                    </div>
                                                }
                                                {!isEmptyArray(this.state.singleSelectDropDownArr) && this.state.singleSelectDropDownArr.map(item => {
                                                    return(
                                                        <div style={{ marginRight: 30 }}>
                                                            <FilterDropDown
                                                                placeholder={isEmptyVariable(item.placeholderValue)?item.fieldName:item.placeholderValue}
                                                                dropdownArr={item.dropdownValues}
                                                                onDropDownItemClick={this.singleSelectFilterDropDownOnClick.bind(this,item.fieldId)}
                                                                themeSettings={themeSettings}
                                                                name={"label"}
                                                                dropdownWidth="200px"
                                                            />
                                                        </div>
                                                    )
                                                })}
                                                <div style={{ marginRight: 30 }}>
                                                    <FilterDropDown
                                                        placeholder={this.state.filterPlaceholder==="All"?this.state.assignmentArea:this.state.filterPlaceholder}
                                                        dropdownArr={this.state.filterDropDownArr}
                                                        onDropDownItemClick={this.filterDropDownOnClick}
                                                        themeSettings={themeSettings}
                                                        name={"label"}
                                                        dropdownWidth="200px"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag && (
                                        <div className="body-wrapper common-table-scroll">
                                            {
                                                !isEmptyArray(this.state.datalist) && this.state.assignmentArea !== "Function" &&
                                                (this.state.filterDataList.length > 0 ? this.state.filterDataList : this.state.datalist).map((item, index) => {
                                                    return (
                                                        !isEmptyArray(item.taskList) && (
                                                            <div>
                                                                <h6 style={{ marginTop: index === 0 ? 15 : 10 }} className="small-h6">
                                                                    {isEmptyVariable(item.processName) ? isEmptyVariable(item.functionName) ? item.heading : item.functionName : item.processName}
                                                                </h6>
                                                                {
                                                                    !isEmptyArray(item.taskList) && (
                                                                    <div>
                                                                        <div className="common-tab-bg">
                                                                            <div className="common-table" style={{ width: "100%" }} >
                                                                                <table className="table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="text-center" width="38px"> No.</th>
                                                                                            <th width="1%"></th>
                                                                                            {
                                                                                                this.state.selectedTaskTemplateFields.map(
                                                                                                (item) => {
                                                                                                    return (
                                                                                                        <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                            {item.fieldName}
                                                                                                        </th>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                            <th></th>
                                                                                            {
                                                                                                !isEmptyVariable(this.state.assignmentDetails.riskId) &&
                                                                                                <th>Follow Up Risk</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.executionTemplateFixedFields?.assignRisk === "Y" && 
                                                                                                <th>Risk</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.showWeight &&
                                                                                                <th>Weight</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.showYesNoDD &&
                                                                                                <th>Rate</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.showRanking && 
                                                                                                <th>Rate</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.execTaskTemplateFields.map(item => {
                                                                                                    let showHeader = true;
                                                                                                    if (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI) {
                                                                                                        if (item.isFromRiskMaster) {
                                                                                                            if (item.isRiskMasterEnabled === "Y") {
                                                                                                                showHeader = true;
                                                                                                            } else {
                                                                                                                showHeader = false;
                                                                                                            }
                                                                                                        } else {
                                                                                                            showHeader = true;
                                                                                                        }
                                                                                                    }
                                                                                                    return showHeader && <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT
                                                                                                        ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                        {item.fieldName}
                                                                                                        {item.isMandatorySelected === "Y" ? "*" : ""}
                                                                                                    </th>
                                                                                                })
                                                                                            }
                                                                                            {
                                                                                                !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                <th>Observation</th>
                                                                                            }
                                                                                            {
                                                                                                !isEmptyVariable(this.state.assignmentStatus) && 
                                                                                                this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_COMPLETED &&
                                                                                                <th className="text-center"></th>
                                                                                            }
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            !isEmptyArray(item.taskList) &&
                                                                                            item.taskList.map((taskItem, idx) => {
                                                                                                
                                                                                                showEmptyCard = false;
                                                                                                let yNRank = defaultYNPlaceholder;
                                                                                                // For Y/N, get label from yesNoRankArr
                                                                                                if (this.state.showYesNoDD && !isEmptyVariable(taskItem.rank)) {
                                                                                                    Constants.yesNoRankArr.forEach((rankItem) => { if (taskItem.rank === rankItem.id) { yNRank = rankItem.label; } });
                                                                                                }
                                                                                                
                                                                                                return (
                                                                                                    <tr style={{ background: "#f5f5f5", height:"40px"}}>
                                                                                                        <td className="text-center"> {(this.state.currentPageNo - 1) * this.state.resultSize + (idx + 1)}</td>
                                                                                                        <td>
                                                                                                            <div className="flex-center-layout exec-task-indicators">
                                                                                                                <div className="review-comment-layout">
                                                                                                                    <span className="material-icons" style={{
                                                                                                                        color: taskItem.remarkCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                        opacity: taskItem.remarkCount === 0 ? "0.6" : "1",
                                                                                                                    }}>
                                                                                                                        chat
                                                                                                                    </span>
                                                                                                                    {taskItem.newRemarkCount != 0 && (
                                                                                                                        <div class="review-comment-badge">
                                                                                                                            <span>{taskItem.newRemarkCount}</span>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                                {this.state.executionTemplateFixedFields?.annexures === "Y" && (
                                                                                                                    <span className="material-icons" style={{
                                                                                                                        color: taskItem.annexureCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                        opacity: taskItem.annexureCount === 0 ? "0.6" : "1"
                                                                                                                    }}>
                                                                                                                        file_present
                                                                                                                    </span>
                                                                                                                )}
                                                                                                                {this.state.executionTemplateFixedFields?.backups === "Y" && (
                                                                                                                    <span className="material-icons" style={{ 
                                                                                                                        color: taskItem.backupCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                        opacity: taskItem.backupCount === 0 ? "0.6" : "1"
                                                                                                                    }}>
                                                                                                                        cloud_upload
                                                                                                                    </span>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        {
                                                                                                            this.state.selectedTaskTemplateFields.map((item, taskIdx) => {
                                                                                                                return (
                                                                                                                    <td style={taskIdx + "" === "0" ? { borderLeft: "5px solid " + taskItem.colorCode } : {}} >
                                                                                                                        <div className={item.fieldId + "" === "1" ? "dangerouslysethtml-flex" : ""}>
                                                                                                                            <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{ __html: this.getTaskDisplayValues(taskItem.taskId, item) }} />
                                                                                                                            {
                                                                                                                                taskItem.completionStatus === "Completed" && item.fieldId + "" === "1" && (
                                                                                                                                    <span className="material-icons exec-task-done" >
                                                                                                                                        done_all
                                                                                                                                    </span>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                );
                                                                                                            }
                                                                                                        )}
                                                                                                        <td style={{minWidth:"180px"}}>
                                                                                                            <RegularDD
                                                                                                                placeholder={taskItem.completionStatus==="Completed"&&(taskItem.allowReview!==Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS)?"Close":this.state.filterOpenClosePlaceholder}
                                                                                                                dropdownArr={this.state.filterOpenCloseDropDownArr}
                                                                                                                labelParam="label"
                                                                                                                // onDropDownItemClick={this.filterOpenCloseDropDownOnClick.bind(this,taskItem.assignmentTaskId)}
                                                                                                                disabled={true}
                                                                                                            />
                                                                                                        </td>
                                                                                                        {
                                                                                                            !isEmptyVariable(this.state.assignmentDetails.riskId) && 
                                                                                                            <td>
                                                                                                                {
                                                                                                                    isEmptyVariable(taskItem.followUpRiskLevel)?
                                                                                                                    <div className="risk-level-color-wrapper" style={{ width: "110px",height:"30px", marginLeft:0 }}/> :
                                                                                                                    <div>{isEmptyVariable(taskItem.followUpRiskLevel)?"":taskItem.followUpRiskLevel}</div>
                                                                                                                }
                                                                                                            </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.executionTemplateFixedFields?.assignRisk === "Y" &&
                                                                                                            <td>
                                                                                                                {isEmptyVariable(taskItem.assignmentTaskRiskLevel) ? "-" : taskItem.assignmentTaskRiskLevel}
                                                                                                            </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.showWeight && <td className="text-center"> {taskItem.weight} </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.showYesNoDD &&
                                                                                                            <td>
                                                                                                                <RegularDropdown
                                                                                                                    placeholder={isEmptyVariable(taskItem.rank) ? defaultYNPlaceholder : yNRank}
                                                                                                                    dropdownArr={Constants.yesNoRankArr}
                                                                                                                    labelParam="label"
                                                                                                                    onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                    udf={idx + ":-:" + index}
                                                                                                                    disabled={false}
                                                                                                                />
                                                                                                            </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.showRanking &&
                                                                                                            <td>
                                                                                                                <RegularDropdown
                                                                                                                    placeholder={isEmptyVariable(taskItem.rank) ? defaultWeightPlaceholder : taskItem.rank === -1 ? "N/A" : taskItem.rank}
                                                                                                                    dropdownArr={this.state.rankingArray}
                                                                                                                    labelParam="label"
                                                                                                                    onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                    udf={idx + ":-:" + index}
                                                                                                                    disabled={false}
                                                                                                                />
                                                                                                            </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.execTaskTemplateFields.map((item) => { 
                                                                                                                return this.getExecTaskDisplayTableData(taskItem.assignmentTaskId, item); 
                                                                                                            }
                                                                                                        )}
                                                                                                        {/* if task has no observation linked to it then show create button
                                                                                                        if task has no observation linked to it but completion status is completed then show just "-" */}
                                                                                                        {
                                                                                                            !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                            <td>
                                                                                                                {isEmptyVariable(taskItem.observationStatus) && taskItem.completionStatus === "Completed" ? "-" : isEmptyVariable(taskItem.observationStatus) ? "Create" : taskItem.observationStatus}
                                                                                                            </td>
                                                                                                        }
                                                                                                        <td className="text-center"></td>
                                                                                                    </tr>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            this.state.showChecklistScores &&
                                                                            <div className="checklist-scores-layout">
                                                                                <p>{"Max Score: " + item.maxScore}</p>
                                                                                <p> {"Total Score: " + item.totalScore} </p>
                                                                                <p> {"Percentage: " + ((item.totalScore / item.maxScore) * 100).toFixed(1) + "%"} </p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    );
                                                })
                                            }

                                            {
                                                !isEmptyArray(this.state.datalist) && this.state.assignmentArea === "Function" &&
                                                (this.state.filterDataList.length > 0 ? this.getProcessGroupingTaskList(this.state.filterDataList, this.state.assignmentArea)
                                                    : this.getProcessGroupingTaskList(this.state.datalist, this.state.assignmentArea)
                                                ).map((process) => {
                                                    return !isEmptyArray(process.processTaskList) && Object.values(process.processTaskList).map((item, index) => {
                                                        return (
                                                            !isEmptyArray(item.taskList) && (
                                                                <div className="sticky-main-header">
                                                                    <div className="sticky-exec-header">
                                                                        {
                                                                            <h6 style={{ marginTop: index === 0 ? 5 : 5 }} className="small-h6"> 
                                                                                {!isEmptyVariable(process.functionName) ? process.functionName : ""} 
                                                                            </h6>
                                                                        }
                                                                        <center>
                                                                            <h6 style={{ marginTop: index === 0 ? 5 : 5 }} className="small-h6" >
                                                                                {!isEmptyVariable(item.processName) ? item.processName : ""}
                                                                            </h6>
                                                                        </center>
                                                                    </div>
                                                                    {
                                                                        !isEmptyArray(item.taskList) && (
                                                                        <div>
                                                                            <div className="common-tab-bg">
                                                                                <div className="common-table" style={{ width: "100%" }} >
                                                                                    <table className="table">
                                                                                        <thead className="sticky-exec-header" style={{top:29}}>
                                                                                            <tr>
                                                                                                <th className="text-center" width="38px" > No. </th>
                                                                                                <th width="1%"></th>
                                                                                                {
                                                                                                    this.state.selectedTaskTemplateFields.map((item) => {
                                                                                                        return (
                                                                                                            <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"} > {item.fieldName} </th>
                                                                                                        );
                                                                                                    })
                                                                                                }
                                                                                                <th></th>
                                                                                                {
                                                                                                    !isEmptyVariable(this.state.assignmentDetails.riskId) &&
                                                                                                    <th>Follow Up Risk</th>
                                                                                                }
                                                                                                {this.state.executionTemplateFixedFields?.assignRisk === "Y" && (<th>Risk</th>)}
                                                                                                {this.state.showWeight && (<th>Weight</th>)}
                                                                                                {this.state.showYesNoDD && (<th>Rank</th>)}
                                                                                                {this.state.showRanking && (<th>Rank</th>)}
                                                                                                {
                                                                                                    this.state.execTaskTemplateFields.map(item => {
                                                                                                        let showHeader = true;
                                                                                                        if (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI) {
                                                                                                            if (item.isFromRiskMaster) {
                                                                                                                if (item.isRiskMasterEnabled === "Y") {
                                                                                                                    showHeader = true;
                                                                                                                } else {
                                                                                                                    showHeader = false;
                                                                                                                }
                                                                                                            } else {
                                                                                                                showHeader = true;
                                                                                                            }
                                                                                                        }
                                                                                                        return showHeader && <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT
                                                                                                            ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                            {item.fieldName}
                                                                                                            {item.isMandatorySelected === "Y" ? "*" : ""}
                                                                                                        </th>
                                                                                                    })
                                                                                                }
                                                                                                {
                                                                                                    !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                    <th>Observation</th>
                                                                                                }
                                                                                                {
                                                                                                    !isEmptyVariable(this.state.assignmentStatus) && 
                                                                                                    this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_COMPLETED &&
                                                                                                    <th className="text-center"></th>
                                                                                                }
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                !isEmptyArray(item.taskList) &&
                                                                                                item.taskList.map((taskItem, idx) => {
                                                                                                    if(taskItem.annexureCount>0)
                                                                                                    {
                                                                                                        this.getAllAnnexureList(taskItem);
                                                                                                    }
                                                                                                    showEmptyCard = false;
                                                                                                    let yNRank = defaultYNPlaceholder;
                                                                                                    let actionObj = {
                                                                                                        actionArr: [{icon:"check",label:"Follow Up Details"}],
                                                                                                        dropdownWidth: '200px',
                                                                                                    };
                                                                                                    // For Y/N, get label from yesNoRankArr
                                                                                                    if (this.state.showYesNoDD && !isEmptyVariable(taskItem.rank)) {
                                                                                                        Constants.yesNoRankArr.forEach((rankItem) => {
                                                                                                            if (taskItem.rank === rankItem.id) { yNRank = rankItem.label }
                                                                                                        });
                                                                                                    }
                                                                                                    
                                                                                                    return (
                                                                                                        <tr style={{ background: "#f5f5f5" }} >
                                                                                                            <td className="text-center"> {(this.state.currentPageNo - 1) * this.state.resultSize + (idx + 1)} </td>
                                                                                                            <td>
                                                                                                                <div className="flex-center-layout exec-task-indicators">
                                                                                                                    <div className="review-comment-layout">
                                                                                                                        <span className="material-icons"
                                                                                                                            style={{
                                                                                                                                color: taskItem.remarkCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                                opacity: taskItem.remarkCount === 0 ? "0.6" : "1",
                                                                                                                            }}>
                                                                                                                            chat
                                                                                                                        </span>
                                                                                                                        {
                                                                                                                            taskItem.newRemarkCount != 0 && (
                                                                                                                            <div class="review-comment-badge">
                                                                                                                                <span>{taskItem.newRemarkCount}</span>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    {this.state.executionTemplateFixedFields?.annexures === "Y" && (
                                                                                                                        <span className="material-icons"
                                                                                                                            style={{
                                                                                                                                color: taskItem.annexureCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                                opacity: taskItem.annexureCount === 0 ? "0.6" : "1",
                                                                                                                            }}>
                                                                                                                            file_present
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                    {
                                                                                                                        this.state.executionTemplateFixedFields?.backups === "Y" && (
                                                                                                                        <span className="material-icons"
                                                                                                                            style={{
                                                                                                                                color: taskItem.backupCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                                opacity: taskItem.backupCount === 0 ? "0.6" : "1",
                                                                                                                            }}>
                                                                                                                            cloud_upload
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            {
                                                                                                                this.state.selectedTaskTemplateFields.map((item, taskIdx) => {
                                                                                                                return (
                                                                                                                    <td style={taskIdx + "" === "0" ? { borderLeft: "5px solid " + taskItem.colorCode, } : {}} >
                                                                                                                        <div className={item.fieldId + "" === "1" ? "dangerouslysethtml-flex" : ""}>
                                                                                                                            <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{ __html: this.getTaskDisplayValues(taskItem.taskId, item) }} />
                                                                                                                            {
                                                                                                                                taskItem.completionStatus === "Completed" && item.fieldId + "" === "1" && (
                                                                                                                                    <span className="material-icons exec-task-done"> done_all </span>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                );
                                                                                                            }
                                                                                                            )}
                                                                                                            <td style={{minWidth:"200px"}}>
                                                                                                                <RegularDD
                                                                                                                    placeholder={taskItem.completionStatus==="Completed"&&(taskItem.allowReview!==Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS)?"Close":this.state.filterOpenClosePlaceholder}
                                                                                                                    dropdownArr={this.state.filterOpenCloseDropDownArr}
                                                                                                                    labelParam="label"
                                                                                                                    // onDropDownItemClick={this.filterOpenCloseDropDownOnClick.bind(this,taskItem.assignmentTaskId)}
                                                                                                                    disabled={true}
                                                                                                                />
                                                                                                            </td>
                                                                                                            {
                                                                                                                !isEmptyVariable(this.state.assignmentDetails.riskId) &&
                                                                                                                <td>
                                                                                                                    {
                                                                                                                        isEmptyVariable(taskItem.followUpRiskLevel)?
                                                                                                                        <div className="risk-level-color-wrapper" style={{ width: "110px",height:"30px", marginLeft:0 }} 
                                                                                                                            onClick={this.handleSelectRiskDialogShow.bind(this,taskItem)}/>
                                                                                                                        :
                                                                                                                        <div onClick={this.handleSelectRiskDialogShow.bind(this,taskItem)}>
                                                                                                                            {isEmptyVariable(taskItem.followUpRiskLevel)?"":taskItem.followUpRiskLevel}
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </td>
                                                                                                            }
                                                                                                            {
                                                                                                                this.state.executionTemplateFixedFields?.assignRisk === "Y" && 
                                                                                                                <td>
                                                                                                                    {isEmptyVariable(taskItem.assignmentTaskRiskLevel) ? "-" : taskItem.assignmentTaskRiskLevel}
                                                                                                                </td>
                                                                                                            }
                                                                                                            {this.state.showWeight && (<td className="text-center"> {taskItem.weight} </td>)}
                                                                                                            {
                                                                                                                this.state.showYesNoDD && (
                                                                                                                <td>
                                                                                                                    <RegularDropdown
                                                                                                                        placeholder={isEmptyVariable(taskItem.rank) ? defaultYNPlaceholder : yNRank}
                                                                                                                        dropdownArr={Constants.yesNoRankArr}
                                                                                                                        labelParam="label"
                                                                                                                        onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                        udf={idx + ":-:" + index}
                                                                                                                        disabled={false}
                                                                                                                    />
                                                                                                                </td>
                                                                                                            )}
                                                                                                            {
                                                                                                                this.state.showRanking && (
                                                                                                                <td>
                                                                                                                    <RegularDropdown
                                                                                                                        placeholder={isEmptyVariable(taskItem.rank) ? defaultWeightPlaceholder : taskItem.rank === -1 ? "N/A" : taskItem.rank}
                                                                                                                        dropdownArr={this.state.rankingArray}
                                                                                                                        labelParam="label"
                                                                                                                        onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                        udf={idx + ":-:" + index}
                                                                                                                        disabled={false}
                                                                                                                    />
                                                                                                                </td>
                                                                                                            )}

                                                                                                            {
                                                                                                                this.state.execTaskTemplateFields.map((item) => {
                                                                                                                    return this.getExecTaskDisplayTableData(taskItem.assignmentTaskId, item);
                                                                                                                }
                                                                                                            )}
                                                                                                            {/* if task has no observation linked to it then show create button if task has no observation linked to it but completion status is completed then show just "-" */}
                                                                                                            {
                                                                                                                !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                                <td>
                                                                                                                    {isEmptyVariable(taskItem.observationStatus) && taskItem.completionStatus === "Completed" ? "-" : isEmptyVariable(taskItem.observationStatus) ? "Create" : taskItem.observationStatus}
                                                                                                                </td>
                                                                                                            }
                                                                                                            <td className="text-center">
                                                                                                                <TableDropDown
                                                                                                                    actionArr={actionObj.actionArr}
                                                                                                                    onDropDownItemClick={this.onTableDropDownItemClick}
                                                                                                                    dropDownId={taskItem}
                                                                                                                    dropdownWidth={actionObj.dropdownWidth}
                                                                                                                    themeSettings={themeSettings}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    );
                                                                                                }
                                                                                                )}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.showChecklistScores && (
                                                                                <div className="checklist-scores-layout">
                                                                                    <p>{"Max Score: " + item.maxScore}</p>
                                                                                    <p> {"Total Score: " + item.totalScore} </p>
                                                                                    <p> {"Percentage: " + ((item.totalScore / item.maxScore) * 100).toFixed(1) + "%"} </p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        );
                                                    })
                                                })
                                            }

                                            {
                                                this.state.componentDidMountFlag && showEmptyCard && (
                                                <div class="no-items-layout m-t-10">
                                                    <div class="no-items-card">
                                                        <h6>{Constants.NO_RECORDS_WARNING}</h6>
                                                    </div>
                                                </div>
                                            )}
                                            {
                                                this.state.showChecklistScores && (
                                                <div className="checklist-scores-layout">
                                                    <p> {"Checklist - Max Score: " + this.state.overallChecklistScoreObj?.maxScore} </p>
                                                    <p> {"Total Score: " + this.state.overallChecklistScoreObj?.actualScore} </p>
                                                    <p> {"Percentage: " + this.state.overallChecklistScoreObj?.scorePct + "%"} </p>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {this.state.showFollowUpDetailsDialog &&
                            <FollowUpDetailsDialog
                                showFollowUpDetailsDialog={this.state.showFollowUpDetailsDialog}
                                followUpDetailsItem={this.state.followUpDetailsItem}
                                handleFollowUpDetailsDialogClose={this.handleFollowUpDetailsDialogClose}
                                themeSettings={themeSettings}
                                item={this.state.followUpTaskItem}
                                assignmentTaskId={this.state.assignmentTaskId}
                                assignmentId={this.state.assignmentId}
                            />
                        }
                    </section>
                </main>
            </Router>
        )
     }
}
export default CompletedFollowUpTask;