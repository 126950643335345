import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray, isAlphaNumericCharacter} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import {DialogSaveButton,FilterCheckBox,TableRadioButton} from '../../../common/Global/globalStyles.style';
import * as TemplateConstants from "../../../common/Global/templateConstants";
import AddDropdownValuesDialog from './addDropdownValuesDialog';

const fieldTypePlaceholderText = "Select Field Type";
const execTaskTablePlaceholderText = "Select Execute Task Table";

class AddFieldDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            fieldName:"",
            fieldType:fieldTypePlaceholderText,
            selectedExecTaskTableFieldName:execTaskTablePlaceholderText,
            selectedExecTaskTableFieldId:"",
            fieldTypeArr:[],
            placeholderValue:"",
            dropdownValues:[],
            formErrors:{},
            submitClickedAtleastOnce:false,
            notRequiredOption_NR:"",
            isMandatorySelected:"",
            isUsedForExec:"",
            showDescription:"N",
            showCreateDDValueDialog:false,
            canBeDeleted:"",
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){
            let fieldTypeArr = [];
            if (this.props.templateType === TemplateConstants.TEMPLATE_TYPE_AUDITEE_COMMENT) {
                fieldTypeArr = TemplateConstants.FIELD_TYPES_AUDITEE_COMMENT;
            }
            else if(this.props.addToPosition ==="Right" && this.props.templateType !== TemplateConstants.TEMPLATE_TYPE_EX_TASK){
                fieldTypeArr = TemplateConstants.FIELD_TYPES_ARR;
            }else if(this.props.addToPosition ==="Right" && this.props.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK){
                fieldTypeArr = TemplateConstants.EX_TASK_FIELD_TYPES_ARR;
            }else if(this.props.addToPosition === "Left"){
                fieldTypeArr = TemplateConstants.FIELD_TYPES_ARR_LEFT;
            } 
            
            if(this.props.isEdit){
                let dropdownValues = [];
                if(this.props.editTemplateItem.fieldType  === TemplateConstants.FIELD_TYPE_SINGLE_SELECT ||
                    this.props.editTemplateItem.fieldType  === TemplateConstants.FIELD_TYPE_MULTI_SELECT ||
                    this.props.editTemplateItem.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN ||
                    this.props.editTemplateItem.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI){
                    dropdownValues = this.props.editTemplateItem.dropdownValues;
                }

                this.setState({
                    fieldName:this.props.editTemplateItem.fieldName,
                    fieldType:this.props.editTemplateItem.fieldType,
                    fieldTypeArr:fieldTypeArr,
                    placeholderValue:this.props.editTemplateItem.placeholderValue,
                    dropdownValues:dropdownValues,
                    canBeDeleted:this.props.editTemplateItem.canBeDeleted,
                    notRequiredOption_NR:this.props.editTemplateItem.isNotReqSelected,
                    isMandatorySelected:this.props.editTemplateItem.isMandatorySelected,
                    isUsedForExec:this.props.editTemplateItem.isUsedForExec,
                    selectedExecTaskTableFieldId:isEmptyVariable(this.props.editTemplateItem?.execTaskTableFieldId)?"":this.props.editTemplateItem?.execTaskTableFieldId,
                    selectedExecTaskTableFieldName:isEmptyVariable(this.props.editTemplateItem?.execTaskTableFieldName)?"":this.props.editTemplateItem?.execTaskTableFieldName,
                })
            }else{
                let fieldTypePlaceholder = fieldTypePlaceholderText;
                // if(this.props.isFieldTypeAddedinDD && !isEmptyVariable(this.props.addfieldTypePlaceholder)){
                //     fieldTypePlaceholder = this.props.addfieldTypePlaceholder;
                // }
                this.setState({
                    fieldName:"",
                    fieldType: this.props.defaultFieldType || fieldTypePlaceholder,
                    fieldTypeArr:fieldTypeArr,
                    placeholderValue:"",
                    dropdownValues:[],
                    // notRequiredOption:"Y",
                    notRequiredOption_NR:"Y",
                    isMandatorySelected:"N",
                    isUsedForExec:"N",
                    canBeDeleted:"Y",
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleCheck = (e) =>{
        let isChecked = e.target.checked;
        let name = e.target.name;
        if(isChecked)
            {
                this.setState({
                    [name]:"Y",
                })
            }
            else
            {
                this.setState({
                    [name]:"N",
                })
            }
    }

    handleCheckRadio = () => {

    }

    fieldTypeDropdownClick = (item) => {
        this.setState({
            fieldType:item.value,
        })
    }

    execTaskTableDropdownClick = (item) => {
        this.setState({
            selectedExecTaskTableFieldName:item.fieldName,
            selectedExecTaskTableFieldId:item.fieldId,
        })
    }

    handleAddEditDDValuesClick = () => {
        if(!isEmptyArray(this.state.dropdownValues)){
            this.setState({
                showCreateDDValueDialog:true,
                isDDValuesEdit:true,
            });
        }else{
            
            this.setState({
                showCreateDDValueDialog:true,
                isDDValuesEdit:false,
            });
        }
    }

    handleCreateDDValueDialogClose = (typeDDValueArr) => {
        
        if(!isEmptyArray(typeDDValueArr)){
            this.setState({
                showCreateDDValueDialog:false,
                isDDValuesEdit:false,
                dropdownValues:typeDDValueArr
            });
        }else{
        
            this.setState({
                showCreateDDValueDialog:false,
                isDDValuesEdit:false,
            });
        }
    }

    checkForUpdates = () => {
        // if(fieldStatus === "Updated"){
        //     return true;
        // }
        if(this.state.fieldName !== this.props.editTemplateItem_prev?.fieldName){
            return true;
        }
        
        if(this.state.fieldType !== this.props.editTemplateItem_prev?.fieldType){
            return true;
        }

        if(this.state.placeholderValue !== this.props.editTemplateItem_prev?.placeholderValue){
            return true;
        }

        if(this.state.notRequiredOption_NR !== this.props.editTemplateItem_prev?.isNotReqSelected){
            return true;
        }

        if(this.state.isMandatorySelected !== this.props.editTemplateItem_prev?.isMandatorySelected){
            return true;
        }

        if(this.state.isUsedForExec !== this.props.editTemplateItem_prev?.isUsedForExec){
            return true;
        }

        if((this.state.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT ||
            this.state.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT ||
            this.state.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI || 
            this.state.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN) &&
            JSON.stringify(this.state.dropdownValues) !== JSON.stringify(this.props.editTemplateItem_prev?.dropdownValues)){
            return true;
        }

        if(this.state.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
        this.props.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK &&
        this.state.selectedExecTaskTableFieldId !== this.props.editTemplateItem_prev?.selectedExecTaskTableFieldId){
            return true;
        }

        return false;
    }

    handleCreateField = () =>{
        let isError = false;
        let duplicateFlag = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.fieldName) ){
            isError = true;
            FormErrorsTemp['fieldName'] = "Please enter Field name";
        }
        if(!isEmptyVariable(this.state.fieldName)){
            if(!isAlphaNumericCharacter(this.state.fieldName)){
                isError = true;
                FormErrorsTemp['fieldName'] = "Field name should be Alphanumeric";
            }
            
            duplicateFlag=this.props.fieldNameArray.includes(this.state.fieldName.trim());
            if(duplicateFlag){
                isError = true;
                FormErrorsTemp['fieldName'] = "Field name cannot be same";
            }
        }
        
        if(isEmptyVariable(this.state.fieldType) || this.state.fieldType === fieldTypePlaceholderText ){
            isError = true;
            FormErrorsTemp['fieldType'] = "Please select Field type";
        }
        if(isEmptyVariable(this.state.placeholderValue) && 
            this.state.fieldType !== TemplateConstants.FIELD_TYPE_TABLE ){
            isError = true;
            FormErrorsTemp['placeholderValue'] = "Please enter Placeholder value";
        }
        if(!isEmptyVariable(this.state.placeholderValue)){
            if(!isAlphaNumericCharacter(this.state.placeholderValue)){
                isError = true;
                FormErrorsTemp['placeholderValue'] = "Placeholder should be Alphanumeric";
            }
        }
        if((this.state.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT ||
            this.state.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT ||
            this.state.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN || 
            this.state.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI) && 
            isEmptyArray(this.state.dropdownValues) &&
            !this.props.editTemplateItem.isFromRiskMaster){

            isError = true;
            FormErrorsTemp['fieldType'] = "Please enter Dropdown values";
        }

        if(this.state.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
        this.props.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK &&
        isEmptyVariable(this.state.selectedExecTaskTableFieldId)){
            isError = true;
            FormErrorsTemp['execTaskTableField'] = "Please select task table";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

            setTimeout(() => {
                this.setState({
                    formErrors:{},
                });
            },Constants.WRNG_MSG_TIMEOUT);

        }else{
            let fieldObj = {};
            try{
                fieldObj = JSON.parse(JSON.stringify(this.props.editTemplateItem));
            }catch(e){
                fieldObj = {};
            }
        
            fieldObj.fieldName =this.state.fieldName;
            fieldObj.fieldType =this.state.fieldType;
            fieldObj.isNotReqSelected = this.state.notRequiredOption_NR;
            fieldObj.isUsedForExec = this.state.isUsedForExec;
            fieldObj.showDescription = this.state.showDescription;
            fieldObj.canBeDeleted = this.state.canBeDeleted;

            //if it is exec table then we need to add task field name and id
            if(this.state.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
            this.props.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK){
                fieldObj.execTaskTableFieldId = this.state.selectedExecTaskTableFieldId;
                fieldObj.execTaskTableFieldName = this.state.selectedExecTaskTableFieldName;
            }
            
            if(this.state.fieldType !== TemplateConstants.FIELD_TYPE_TABLE ||
                this.props.templateType !== TemplateConstants.TEMPLATE_TYPE_AUDITEE_COMMENT){
                    fieldObj.isMandatorySelected = this.state.isMandatorySelected;
            }
            // fieldObj.addToPosition = this.props.addToPosition;

            if(this.props.isEdit){
                //check anything is updated
                let updateFlag = this.checkForUpdates();
                if(updateFlag){
                    fieldObj.fieldStatus_new = fieldObj.fieldStatus === "Created"?"Created":"Updated"
                }else{
                    fieldObj.fieldStatus_new = fieldObj.fieldStatus === "Created"?"Created":"Active"
                }
            }else{
                fieldObj.fieldStatus = "Created";
                fieldObj.fieldStatus_new = "Created";
            }

            if(this.state.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT ||
                this.state.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT ||
                this.state.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN ||
                this.state.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI){
                    
                    fieldObj.dropdownValues = this.state.dropdownValues;
            }

            if(this.state.fieldType !== TemplateConstants.FIELD_TYPE_TABLE ){
                fieldObj.placeholderValue =  this.state.placeholderValue
            }
            this.props.handleCreateDialogClose(fieldObj);
        }
    }

    handleCreateDialogClose = () =>{
        this.props.handleCreateDialogClose(false);
    }

    getNotRequiredOptionDisplayFlag = () => {
        if(this.state.canBeDeleted === "Y" &&
        (this.props.templateType === TemplateConstants.TEMPLATE_TYPE_TASK ||
        this.props.templateType === TemplateConstants.TEMPLATE_TYPE_OBSERVATION ||
        (
            this.props.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK &&
            (
                this.state.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT ||
                this.state.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT ||
                this.state.fieldType === TemplateConstants.FIELD_TYPE_TABLE
            )
        )) &&
        this.props.addToPosition === "Right"){
            return true;
        }

        return false;
    }

    render() {
        return(
            <Modal className="custom-dialog" show={this.props.showCreateDialog} backdrop="static">
                <Modal.Header>
                <h5>{this.props.isEdit?"Edit Field":"Add New Field"}</h5>
                <button 
                    onClick={this.handleCreateDialogClose}
                    type="button" data-dismiss="modal">
                    <span class="material-icons">close</span>
                </button>
            </Modal.Header>
                <hr />
                <div class="modal-body">
                    <div class="addnew-modal-form">
                        <div class="addnew-modal-form-group">
                            <p>Field Name*</p>
                            <input type="text" placeholder="Enter Short Name"
                            name="fieldName" value={this.state.fieldName} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['fieldName']) &&
                                <span class="cm-error">{this.state.formErrors['fieldName']}</span>
                            }
                        </div>
                        {
                            <div class="addnew-modal-form-group">
                                <p>Field Type*</p>
                                <RegularDropdown 
                                    placeholder={this.state.fieldType}
                                    dropdownArr={this.state.fieldTypeArr}
                                    labelParam="value"
                                    onDropDownItemClick={this.fieldTypeDropdownClick}
                                    // isFocusRequired={true}
                                    defaultPlaceholderDropDown = {fieldTypePlaceholderText}
                                    disabled = {
                                        (this.state.canBeDeleted  === "N" || this.props.isUsedInTasks)
                                        ?true
                                        :false}

                                />
                                <div>
                                    {   
                                        (this.state.canBeDeleted === "Y" || !this.props.isUsedInTasks) &&
                                        !this.props.editTemplateItem.isFromRiskMaster &&
                                        (this.state.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN ||
                                        this.state.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT ||
                                        this.state.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT ||
                                        this.state.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI) &&
                                        <div className="add-more-btn-layout m-t-10" onClick={this.handleAddEditDDValuesClick}>
                                            <span className="material-icons">add_circle</span>
                                            <p className="m-b-0">{!isEmptyArray(this.state.dropdownValues)?"Edit":"Add"} Values</p>
                                        </div>
                                    }
                                </div>
                                
                                {
                                    (this.state.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN ||
                                    this.state.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI) &&
                                    <div>
                                        <FilterCheckBox themeSettings={this.props.themeSettings} style={{ marginLeft: 0 }}>
                                            <input
                                                type="checkbox"
                                                name="showDescription"
                                                id="showDescription"
                                                onChange={this.handleCheck}
                                                checked={this.state.showDescription === "Y" }
                                            />
                                            <label for="showDescription">Show description in dropdown</label>
                                        </FilterCheckBox>
                                    </div>
                                }
                                {
                                    !isEmptyVariable(this.state.formErrors['fieldType']) &&
                                    <span class="cm-error">{this.state.formErrors['fieldType']}</span>
                                }
                            </div>
                        }
                        {/* //here */}
                        {
                            this.state.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                            this.props.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK &&
                            <div class="addnew-modal-form-group">
                                <p>Linked Assigned Task*</p>
                                <RegularDropdown 
                                    placeholder={this.state.selectedExecTaskTableFieldName}
                                    dropdownArr={this.props.executeTableFields}
                                    labelParam="fieldName"
                                    onDropDownItemClick={this.execTaskTableDropdownClick}
                                    defaultPlaceholderDropDown = {execTaskTablePlaceholderText}
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['execTaskTableField']) &&
                                    <span class="cm-error">{this.state.formErrors['execTaskTableField']}</span>
                                }
                            </div>
                        }
                                
                        {
                            this.state.fieldType !== TemplateConstants.FIELD_TYPE_TABLE &&
                            <div class="addnew-modal-form-group">
                                <p>Placeholder Value*</p>
                                <input type="text" placeholder="Enter placeholder value"
                                name="placeholderValue" value={this.state.placeholderValue} onChange={this.handleChange}/>
                                {
                                    !isEmptyVariable(this.state.formErrors['placeholderValue']) &&
                                    <span class="cm-error">{this.state.formErrors['placeholderValue']}</span>
                                }
                            </div>
                        }
                        <div class="addnew-modal-form-group">
                            {
                                this.getNotRequiredOptionDisplayFlag() && 
                                // this.props.templateType !== TemplateConstants.TEMPLATE_TYPE_EX_TASK) &&
                                <FilterCheckBox themeSettings={this.props.themeSettings} style={{ marginLeft: 0 }}>
                                    <input
                                        type="checkbox"
                                        name="notRequiredOption_NR"
                                        id="notRequiredOption_NR"
                                        onChange={this.handleCheck}
                                        checked={this.state.notRequiredOption_NR === "Y" ? true : false}
                                    />
                                    <label for="notRequiredOption_NR">Show "Not Required" option</label>
                                </FilterCheckBox>
                            }
                            {
                                this.state.canBeDeleted === "Y" && 
                                (this.state.fieldType !== TemplateConstants.FIELD_TYPE_TABLE ||
                                this.props.templateType !== TemplateConstants.TEMPLATE_TYPE_AUDITEE_COMMENT) &&
                                <FilterCheckBox themeSettings={this.props.themeSettings} style={{ marginLeft: 0 }} 
                                    className={(this.state.canBeDeleted === "Y" &&
                                        this.props.addToPosition === "Right") ?"m-t-10":""}>
                                    <input 
                                        type="checkbox"
                                        name="isMandatorySelected"
                                        id="isMandatorySelected"
                                        onChange={this.handleCheck}
                                        checked={this.state.isMandatorySelected === "Y"?true:false}
                                    />
                                    <label for="isMandatorySelected">Mandatory</label>
                                </FilterCheckBox>
                            }
                            {
                                this.state.canBeDeleted === "Y" && 
                                this.state.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                                this.props.templateType === TemplateConstants.TEMPLATE_TYPE_TASK &&
                                <FilterCheckBox themeSettings={this.props.themeSettings} style={{ marginLeft: 0 }} 
                                    className={(this.state.canBeDeleted === "Y" &&
                                        this.props.addToPosition === "Right") ?"m-t-10":""}>
                                    <input 
                                        type="checkbox"
                                        name="isUsedForExec"
                                        id="isUsedForExec"
                                        onChange={this.handleCheck}
                                        checked={this.state.isUsedForExec === "Y"?true:false}
                                    />
                                    <label for="isUsedForExec">Used In Execute Task</label>
                                </FilterCheckBox>
                            }
                        </div>

                    </div>
                    <div class="modal-close-save">
                        <div class="close-save-col">
                            <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                            <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.handleCreateField} type="button">Save</DialogSaveButton>
                        </div>
                    </div>

                    <AddDropdownValuesDialog
                        isEdit={this.state.isDDValuesEdit}
                        showCreateDDValueDialog = {this.state.showCreateDDValueDialog}
                        handleCreateDDValueDialogClose = {this.handleCreateDDValueDialogClose}
                        themeSettings={this.props.themeSettings}
                        addToPosition={this.props.addToPosition}
                        typeDDValueArr = {this.state.dropdownValues}
                    />
                </div>
                
            </Modal>


        );
    }
}

export default AddFieldDialog;