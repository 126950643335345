import React from "react";
import { Modal } from "react-bootstrap";
import * as Constants from "../Global/constants";
import {
  isEmptyVariable,
  getThemeSettingsVariables,
} from "../Global/commonFunctions";
import {
  AlertDialogSpan,
  DialogProceedButton,
  TableRadioButton,
  FilterCheckBox,
} from "../Global/globalStyles.style";
const themeSettings = getThemeSettingsVariables();

class AlertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRadioBox: "",
      isDisableSelected: false,
      formErrors: {},
      isError: false,
    };
  }
  
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps) !== JSON.stringify(this.props) &&
      this.props.showAlertDialog
    ) {
      this.setState({
        selectedRadioBox: this.props.firstRowType,
        isDisableSelected: this.props.isFirstRowTypeDisable,
      });
    }
  }

  /// Akshay's Code start Here
  /// This Method Handle Checkbox Input for Execute Table.
  handleCheckBoxClick = (e) => {
    if (e.target.name === "YN") {
      this.setState({
        selectedRadioBox: "YN",
      });
    } else if (e.target.name === "YNNA") {
      this.setState({
        selectedRadioBox: "YNNA",
      });
    } else if (e.target.name === "TEXTFIELD") {
      this.setState({
        selectedRadioBox: "TEXTFIELD",
        isDisableSelected:false
      });
    } else if (e.target.name === "DIS") {
      this.setState({
        isDisableSelected: !this.state.isDisableSelected,
      });
    }
  };

  /// This Method Checks all Validation of Radio Button, Show Error Message And save changes For Execute Table.
  handleProceedButtonClickExec = () => {
    let formErrors = {};
    let isError = false;
    let selectedRadioBox = this.state.selectedRadioBox;
    let isDisableSelected = this.state.isDisableSelected;

    if (this.state.selectedRadioBox === "") {
      formErrors.checkBoxErr = "Please select First Row Type";
      isError = true;
    }

    if (isError) {
      this.setState({
        formErrors: formErrors,
        showLoader: false,
      });

      setTimeout(
        function () {
          this.setState({
            formErrors: {},
          });
        }.bind(this),
        Constants.WRNG_MSG_TIMEOUT
      );
    } else {
      this.props.proceedBtnClick(selectedRadioBox, isDisableSelected);
    }
  };

  /// AKshay's Code End Here

  render() {
    return (
      <Modal
        show={this.props.showAlertDialog} backdrop="static"
      >
        <Modal.Body>
          {this.props.showLoader && (
            <div className="modal-loading-text">
              <p
                style={{
                  background: `rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                  color: `rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`,
                }}
              >
                Loading....
              </p>
            </div>
          )}
          <div class="dialog-layout">
            <button onClick={this.props.handleAlertDialogClose}>
              <span className="material-icons">close</span>
            </button>
            {this.props.type === Constants.ALERT_TYPE_WARNING && (
              <span className="material-icons">warning</span>
            )}
            {(this.props.type === Constants.ALERT_TYPE_ALERT ||
              this.props.type === Constants.ALERT_TYPE_INFO) && (
              <AlertDialogSpan
                themeSettings={this.props.themeSettings}
                className="material-icons"
              >
                error_outline
              </AlertDialogSpan>
            )}
            {
              // !this.props.type === Constants.ALERT_TYPE_ALERT &&
              !isEmptyVariable(this.props.alertDialogHeading) && (
                <h5>{this.props.alertDialogHeading}</h5>
              )
            }
            <p style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{__html: this.props.alertDialogMessage}}></p>
            {/* Akshay's Code Start Here */}
           
            {this.props.type === Constants.ALERT_TYPE_INFO &&
              this.props.isUsedForExec === "Y" && (
                <>
                  <div className="row custom" style={{ textAlign: "left" }}>
                    <div class=" mx-3 col-l">
                      {!isEmptyVariable(
                        this.state.formErrors["checkBoxErr"]
                      ) && (
                        <p
                          className="cm-error"
                          style={{ textAlign: "left", fontWeight: "bold" }}
                        >
                          {this.state.formErrors["checkBoxErr"]}
                        </p>
                      )}
                      <CreateRadioBoxComp
                        item={"TEXTFIELD"}
                        selectedRadioBox={this.state.selectedRadioBox}
                        label={"Text Field"}
                        handleCheckBoxClick={this.handleCheckBoxClick}
                      />
                      <CreateRadioBoxComp
                        item={"YN"}
                        selectedRadioBox={this.state.selectedRadioBox}
                        label={
                          "First row is a dropdown with Yes and No options"
                        }
                        handleCheckBoxClick={this.handleCheckBoxClick}
                      />
                      <CreateRadioBoxComp
                        item={"YNNA"}
                        selectedRadioBox={this.state.selectedRadioBox}
                        label={
                          "First row is a dropdown with Yes,No and NA options"
                        }
                        handleCheckBoxClick={this.handleCheckBoxClick}
                      />

                      {(this.state.selectedRadioBox === "YN" ||
                        this.state.selectedRadioBox === "YNNA") && (
                        <FilterCheckBox
                          themeSettings={themeSettings}
                          style={{ marginLeft: 0, marginBottom: 10 }}
                        >
                          <input
                            type="checkbox"
                            name={"DIS"}
                            id={"DIS"}
                            onChange={this.handleCheckBoxClick}
                            checked={
                              this.state.isDisableSelected ? true : false
                            }
                          />
                          <label for={"DIS"}>
                            Disable the fields when Yes option is not Selected
                          </label>
                        </FilterCheckBox>
                      )}
                    </div>
                  </div>
                </>
              )}

            {/* Akshay's Code End Here */}
            <div class="dialog-btn-layout">
              {!(this.props.type === Constants.ALERT_TYPE_ALERT) && (
                <a
                  class="alert-cancel"
                  href="javascript:void(0);"
                  onClick={this.props.handleAlertDialogClose}
                >
                  Cancel
                </a>
              )}
              {this.props.type === Constants.ALERT_TYPE_WARNING && (
                <a
                  class="alert-dialog-warning"
                  href="javascript:void(0);"
                  onClick={this.props.proceedBtnClick}
                  style={{
                    marginLeft:
                      this.props.type === Constants.ALERT_TYPE_ALERT
                        ? "0px"
                        : "10px",
                  }}
                >
                  {this.props.proceedBtnLabel}
                </a>
              )}

              {(this.props.type === Constants.ALERT_TYPE_ALERT ||
                this.props.type === Constants.ALERT_TYPE_INFO) && (
                <DialogProceedButton
                  themeSettings={this.props.themeSettings}
                  class="alert-proceed"
                  href="javascript:void(0);"
                  onClick={
                    this.props.isUsedForExec === "Y"
                      ? this.handleProceedButtonClickExec
                      : this.props.proceedBtnClick
                  }
                  style={{
                    marginLeft:
                      this.props.type === Constants.ALERT_TYPE_ALERT
                        ? "0px"
                        : "10px",
                  }}
                >
                  {this.props.proceedBtnLabel}
                </DialogProceedButton>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AlertDialog;

/// This class Return TableRadioButton Component
/// Akshay's code
export class CreateRadioBoxComp extends React.Component {
  render() {
    return (
      <TableRadioButton
        themeSettings={themeSettings}
        style={{ marginLeft: 0, marginBottom: 10 }}
      >
        <input
          type="checkbox"
          name={this.props.item}
          id={this.props.item}
          onChange={this.props.handleCheckBoxClick}
          checked={
            this.props.selectedRadioBox === this.props.item ? true : false
          }
        />
        <label for={this.props.item}>{this.props.label}</label>
      </TableRadioButton>
    );
  }
}
