import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import { matchPath,withRouter } from 'react-router-dom';
import {TopTabsLayout} from '../../common/Global/globalStyles.style';
import { getThemeSettingsVariables  } from '../../common/Global/commonFunctions';


let isUsers ='';
let isRoles ='';
let isDepartments ='';
let themeColor = ``;
class UserManagementTopMenu extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }

        isUsers = this.checkActivePath("/users");
        isRoles = this.checkActivePath(["/userroles","/adduserrole","/edituserrole"]);
        isDepartments = this.checkActivePath("/userdepartments");
    }

    checkActivePath = (path) =>{
        const themeSettings = getThemeSettingsVariables();
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
       return matchPath(this.props.history.location.pathname, { path });

    }

    userRolesOnClick = () => {
        localStorage.setItem('userRoleFilters', "");
        window.location = Constants.URL_USERROLES;
    }

    render(){
        return(
            <TopTabsLayout
            themeSettings={this.props.themeSettings}
            >
                <div className="question-tabs"  style={{marginRight:0}}>
                    <ul style={{width:"50%"}}>
                        <li className={isUsers ? "questiontab-active" : ""}>
                            <a href={Constants.URL_USERLIST}
                                style={{color: !isUsers ? themeColor : '', fontWeight: !isUsers ? "bold" : "100px"}}>Users</a>
                        </li>
                        <li className={isRoles ? "questiontab-active" : ""}>
                            <a onClick={this.userRolesOnClick} href="javascript:void(0)"
                                style={{color: !isRoles ? themeColor : '', fontWeight: !isRoles ? "bold" : "100px"}}>Roles</a>
                        </li>
                        <li className={isDepartments ? "questiontab-active" : ""}>
                            <a href={Constants.URL_USERDEPARTMENTS}
                                style={{color: !isDepartments ? themeColor : '', fontWeight: !isDepartments ? "bold" : "100px"}}>Departments</a>
                        </li>
                        {/* <li 
                            className={matchPath(this.props.history.location.pathname, { path: "/managers" }) ? "questiontab-active" : ""}>
                            <a href={Constants.URL_MANAGERS}>Managers</a>
                        </li> */}
                    </ul>
                </div>

                <div style={{opacity:this.props.hideInfo?"0":"1"}} className="infocard-showhide-col">
                    {/* <div className="infocard-icon-col">
                        <img src="/assets/images/topbar-info.svg" />
                    </div> */}
                </div>
            </TopTabsLayout>
        )
    }
}

export default withRouter(UserManagementTopMenu);