import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../common/DropdownMenus/RegularDropdown';
import {DialogSaveButton} from '../../common/Global/globalStyles.style';

let userDetails  = {} ;
// const copyDataSuccessMessage = "Copy data initiated. It might take 10-15 minutes to complete";
class AddClientAdminDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            submitClickedAtleastOnce:false,

            domainName:"",
            formErrors:{},
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCopyClientDataDialog){
            this.setState({
                submitClickedAtleastOnce:false,
                domainName:"",
                formErrors:{},
            })
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            if(name === "domainName"){
                FormErrorsTemp['domainName'] = isEmptyVariable(value)?"Please enter domain name":"";
            }
        }

        this.setState({ 
            [name]: value,
            formErrors:FormErrorsTemp
        });
    }
    // handleAlertDialogCloseInfo = () => {
	// 	this.setState({
	// 		showAlertDialogInfo: false,
	// 		alertDialogMessageInfo: "",
	// 	},() => {
    //         this.props.handleCopyClientDataDialogClose();
    //     });
	// };

    handleCopyClientData = () => {
        userDetails= getLocalStorageVariables();
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.domainName)){
            isError = true;
            FormErrorsTemp['domainName'] = "Please enter domain name";
        }


        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            fetch(Constants.CopyClientData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    domain:this.state.domainName,
                    copyToClientId:this.props.clientId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                // if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                //     data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                //     localStorage.clear();
                //     window.location="/";
                // }else if(data.responseCode === Constants.CODE_SUCCESS){
                //     this.setState({
                //         showLoader:false,
                //         domainName:"",
                //         formErrors:{},
                //         showAlertDialogInfo: true,
                //         alertDialogMessageInfo: copyDataSuccessMessage,
                //     });
                // }else{
                //     let formErrTemp = {};
                //     formErrTemp['domainName'] = data.responseMessage;
                //     this.setState({
                //         showLoader:false,
                //         formErrors:formErrTemp,
                //     })
                //     setTimeout(function(){
                //         this.setState({
                //             formErrors:{},
                //         });
                //     }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                // }
            });

            this.setState({
                showLoader:false,
                domainName:"",
                formErrors:{},
                // showAlertDialogInfo: true,
                // alertDialogMessageInfo: copyDataSuccessMessage,
            },()=>{
                this.props.handleCopyClientDataDialogClose(true);
            });
        }
    }

    render() {
        return(
            <Modal className="custom-dialog" 
            show={this.props.showCopyClientDataDialog} backdrop="static">
                <Modal.Header>
                    <h5>Copy Client Data</h5>
                    <button 
                        onClick={this.props.handleCopyClientDataDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div className="modal-loading-text">
                            <p
                            style={{
                                background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                            }}
                            >Loading....</p>
                        </div>
                    }
                    <div className="addnew-modal-form">
                        <div className="row custom-row">
                            <div className="col-md-12 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Domain Name*</p>
                                    <input type="text" placeholder="Enter Domain name"
                                    name="domainName" value={this.state.domainName} onChange={this.handleChange}/>
                                    <span>xyzca.com</span>
                                    {
                                        !isEmptyVariable(this.state.formErrors['domainName']) &&
                                        <span className="cm-error">{this.state.formErrors['domainName']}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* {
                            !isEmptyVariable(this.state.formErrors['apiErr']) &&
                            <span className="cm-error">{this.state.formErrors['apiErr']}</span>
                        } */}
                    </div>
                    
                    <div className="modal-close-save">
                        <div className="close-save-col">
                            <button onClick={this.props.handleCopyClientDataDialogClose} type="button" className="modal-close">Cancel</button>
                            <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.handleCopyClientData} type="button">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>
                {/* <AlertDialog
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type={Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                    themeSettings={this.props.themeSettings}
                /> */}
            </Modal>
        );
    }
}

export default AddClientAdminDialog;