import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';

const userDetails  = getLocalStorageVariables();
class CompanyDetailsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(){
    }


    render() {
        var resFunctionArr = [];
        if(!isEmptyVariable(this.props.unitData.functionList)){
            var funcWithIdArr = this.props.unitData.functionList.split(",");

            funcWithIdArr.map(funcItem => {
                resFunctionArr.push(funcItem.split(":-:")[0])
            })
        }
        return(
            <Modal className="user-details-dialog custom-dialog" 
            show={this.props.showUnitDetailsDialog} onHide={this.props.handleUnitDetailsDialogClose}>
                <Modal.Header>
                    <h5>{this.props.unitData.unitName+" Details"}</h5>
                    <button 
                        onClick={this.props.handleUnitDetailsDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="body-wrapper">
                        {
                            !isEmptyVariable(this.props.unitData.companyId) && 
                            <div className="details-table-layout">
                                <div className="row custom-row">
                                    <div className="col-md-6 custom-col">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td width={"33%"}>Company Name</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{this.props.unitData.companyName}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Unit Name</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{this.props.unitData.unitName}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Unit Short Name</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{this.props.unitData.unitShortName}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Unit Type</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{this.props.unitData.unitTypeName}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Functions</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{isEmptyVariable(resFunctionArr)?"-":resFunctionArr.join(", ")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6 custom-col">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td width={"33%"}>Address line 1</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{this.props.unitData.address1}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Address line 2</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{isEmptyVariable(this.props.unitData.address2)?"-":this.props.unitData.address2}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>City</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{this.props.unitData.city}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Pin code</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{this.props.unitData.pinCode}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>State</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{this.props.unitData.state}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Country</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{this.props.unitData.country}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default CompanyDetailsDialog;