import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {TableRadioButton,DialogSaveButton,SearchSpan} from '../../../common/Global/globalStyles.style';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import Pagination from '../../../common/Global/pagination';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();

class departmentList extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedCheckListId:"",
            // deptErr:"",
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showSelectChecklistFlag){
            if(this.props.isEdit){
                let selectedId=this.props.selectedChecklistId;
                this.setState({
                    selectedCheckListId:selectedId,
                    // deptErr:"",

                    componentDidMountFlag:false,
                    apiSearchKey:"",
                    searchkey:"",
                    currentPageNo: 1,
                    showLoader:false,
                    resultSize:Constants.RESULT_SIZE,
                    totalCount:0,
                    datalist:[],
                    resultSizePlaceholder:Constants.RESULT_SIZE,
                    resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
                    sort:"",
                    sortDir:"",
                },()=>{
                    this.getCheckLists();
                });
            }else{

                // let selectedId=this.props.selectedChecklistId;
                this.setState({
                    selectedCheckListId:"",
                    // deptErr:"",

                    componentDidMountFlag:false,
                    apiSearchKey:"",
                    searchkey:"",
                    currentPageNo: 1,
                    showLoader:false,
                    resultSize:Constants.RESULT_SIZE,
                    totalCount:0,
                    datalist:[],
                    resultSizePlaceholder:Constants.RESULT_SIZE,
                    resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
                    sort:"",
                    sortDir:"",
                },()=>{
                    this.getCheckLists();
                });
            }
            
            
        }
    }

    getCheckLists = () => {
        this.setState({
            showLoader:true,
        });

        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:"Active",
            sort:this.state.sort,
            sortDir:this.state.sortDir,
            departmentId:this.props.departmentId,
            // departmentId:this.props.departmentId,
        }

        fetch(Constants.GetChecklists,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                    componentDidMountFlag:true,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let isChecked = e.target.checked;
        let deptId = e.target.name;
        
        let selectedId = "";
        if(isChecked){
            selectedId = deptId;
        }

        this.setState({
            selectedCheckListId:selectedId
        });
    }

    returnSelectedDeptId = () => {
        // let deptErrTemp = "";

        if(isEmptyVariable(this.state.selectedCheckListId)){
            // deptErrTemp =  "Select the checklist";
            toast.warning("Select the checklist");
            // this.setState({
            //     deptErr:deptErrTemp,
            // })

            // setTimeout(function(){
            //     this.setState({
            //         deptErr:"",
            //     });
            // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else{
            if(this.props.isEdit){
                this.setState({
                    showLoader:true,
                });
        
                let postParams = {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                    checkListVersionId:this.state.selectedCheckListId,
                }
        
                fetch(Constants.ChangeChecklist,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams(postParams)
                })
                .then(response => { return response.json(); } )
                .then(data =>
                {
                    if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                        localStorage.clear();
                        window.location="/";
                    }else if(data.responseCode === Constants.CODE_SUCCESS){
                        this.setState({
                            showLoader:false,
                            componentDidMountFlag:true,
                        },()=>{
                            this.props.onChecklistSelectDialogClose();
                        });
                    }else{
                        // deptErrTemp =  data.responseMessage;
                        toast.error(data.responseMessage);
                        this.setState({
                            // deptErr:deptErrTemp,
                            showLoader:false,
                            componentDidMountFlag:true,
                        })
            
                        // setTimeout(function(){
                        //     this.setState({
                        //         deptErr:"",
                        //     });
                        // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                    }
                });

            }else{
                
                this.props.onSelectChecklistSaveClose(this.state.selectedCheckListId);
            }
        }
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getCheckLists();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getCheckLists();
            })
        }
    }
    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getCheckLists();
        })
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            let postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:page,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:"Active",
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                departmentId:this.props.departmentId,
                // departmentId:this.props.departmentId,
            }
    
            fetch(Constants.GetChecklists,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParams)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        componentDidMountFlag:true,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        totalCount:0,
                        componentDidMountFlag:true,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }
    /*****************************API*********************************/
    render(){
        return(
            <section>
                <Modal className="task-select-dept-dialog custom-dialog"
                    show={this.props.showSelectChecklistFlag} 
                    backdrop="static">
                    <Modal.Header>
                        {
                            this.props.isEdit 
                            ? <h5>Change Checklist</h5>
                            : <h5>Checklists</h5>
                        }
                        <button 
                            onClick={this.props.onChecklistSelectDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>

                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div className="modal-loading-text">
                                <p
                                style={{
                                    background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                    color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                                }}
                                >Loading....</p>
                            </div>
                        }
                        {
                            this.state.componentDidMountFlag &&
                            <div className="body-wrapper">
                                <div className="search-addnew-bg" style={{marginTop:0}}>
                                    <div className="search-addnew">
                                        
                                        <div className="search-filter-layout" style={{position:"relative"}}>
                                            <FilterDropDown
                                                placeholder={this.state.resultSizePlaceholder}
                                                dropdownArr={this.state.resultSizeArr}
                                                onDropDownItemClick={this.resultSizeDropdownClick}
                                                addBorder={true}
                                                themeSettings={this.props.themeSettings}
                                                mleft={0}
                                            />
                                            <div className="search-col m-l-10" style={{border:"1px solid #e5e5e5"}}>
                                                <input 
                                                    name="searchkey" 
                                                    type="text"
                                                    onChange={this.handleChangeSearch}
                                                    placeholder="Search" 
                                                    onKeyPress={this.onEnterBtnPress}
                                                    value={this.state.searchkey}
                                                />
                                                <SearchSpan 
                                                themeSettings={this.props.themeSettings}
                                                onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                            </div>

                                            
                                            <div className="overlay-nr" style={{display:this.state.document_NR === "Y"?"block":"none"}}></div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !isEmptyArray(this.state.datalist) && 
                                    <div className="common-tab-bg">
                                        <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" width={"8%"}></th>
                                                        <th className="text-center" width={"5%"}>No.</th>
                                                        <th>Name</th>
                                                        <th>Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    !isEmptyArray(this.state.datalist) && 
                                                    this.state.datalist.map((item,idx) => {

                                                        return <tr>
                                                            <td>
                                                                <TableRadioButton
                                                                    themeSettings={this.props.themeSettings}>
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={item.checkListVersionId}
                                                                        id={item.checkListVersionId}
                                                                        onChange={this.handleSelection}
                                                                        checked={item.checkListVersionId+""===this.state.selectedCheckListId+""?true:false}
                                                                    />
                                                                    <label for={item.checkListVersionId} 
                                                                    style={{marginLeft:6}}></label>
                                                                </TableRadioButton>
                                                            </td>
                                                            <td className="text-center">{(idx+1)}</td>
                                                            <td>{item.checkListName}</td>
                                                            <td>{item.checkListType}</td>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                                {
                                    isEmptyArray(this.state.datalist) &&
                                    <div class="no-items-layout">
                                        <div class="no-items-card">
                                            <h6>
                                            {
                                                isEmptyVariable(this.state.apiSearchKey)?
                                                Constants.NO_RECORDS_WARNING
                                                :
                                                Constants.EMPTY_SEARCH_WARNING
                                            }
                                            </h6>
                                        </div>
                                    </div>
                                }
                                <div className="pagination-layout">
                                    <div className="row custom-row">
                                        <div className="col-md-9 custom-col">
                                        {
                                            (this.state.totalCount > this.state.datalist.length) && 
                                            <Pagination 
                                                totalLength ={this.state.totalCount} 
                                                items={this.state.datalist} 
                                                onChangePage={this.onChangePage} 
                                                pageSize={this.state.resultSize}
                                                currentPageNo = {this.state.currentPageNo}
                                                initialPage={this.state.currentPageNo} 
                                                themeSettings={this.props.themeSettings}/>
                                        }
                                        </div>
                                        <div className="col-md-3 custom-col">
                                            <p>{"Total Records: "+this.state.totalCount}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* {
                                    !isEmptyVariable(this.state.deptErr) &&
                                    <span className="cm-error">{this.state.deptErr}</span>
                                } */}
                                <div className="modal-close-save m-t-15">
                                    <div className="close-save-col">
                                    {
                                        <button onClick={this.props.onChecklistSelectDialogClose} type="button" 
                                        className="modal-close m-b-0">Cancel</button>
                                    }
                                        
                                        <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                        onClick={this.returnSelectedDeptId} type="button" 
                                        className="modal-save m-b-0">Save</DialogSaveButton>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal>
            </section>
        )
    }
}

export default departmentList;