import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { getLocalStorageVariables, isEmptyVariable, ifEmptyReturn, isEmptyArray } from "../../../common/Global/commonFunctions";
import { AddNewButtonLayout } from "../../../common/Global/globalStyles.style";
import AddCommentDialog from "./addcommentdialog";

import { Modal } from "react-bootstrap";
import moment from "moment";
const userDetails = getLocalStorageVariables();

class ReviewHistoryDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			showLoader: false,
			datalist: [],
			isEdit:false,
			selectedAssignmentTaskRemarkId:"",
			selectedRemark:"",
			// totalCount:0,
			// currentPageNo: 1,
			reloadFlag:false,
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showReviewCommentsDialog) {
			this.setState(
				{
					componentDidMountFlag: false,
					showLoader: false,
					datalist: [],
					showCreateDialog: false,
					isEdit:false,
					selectedAssignmentTaskRemarkId:"",
					selectedRemark:"",
					reloadFlag:false,
				},
				() => {
					this.getInitData();
				}
			);
		}
	}
	handleCreateDialogShow = () => {
		this.setState({
			showCreateDialog: true,
			isEdit:false,
			selectedAssignmentTaskRemarkId:"",
			selectedRemark:"",
		});
	};

	handleEditDialogShow = (item) => {
		this.setState({
			showCreateDialog: true,
			isEdit:true,
			selectedAssignmentTaskRemarkId:item.assignmentTaskRemarkId,
			selectedRemark:item.remark,
		});
	};

	handleAddDialogClose = (reloadFlag) => {
		let reloadFlagtemp=false;
		if(reloadFlag){
			reloadFlagtemp=reloadFlag
		}
		this.setState(
			{
				showCreateDialog: false,
				isEdit:false,
				selectedAssignmentTaskRemarkId:"",
				selectedRemark:"",
				reloadFlag:reloadFlagtemp
			},
			() => {
				if (reloadFlag) this.getInitData();
			}
		);
	};
	handleReviewCommentsDialogClose = () => {
		this.props.handleReviewCommentsDialogClose(this.state.reloadFlag);
	}

	getInitData = () => {
		this.setState({
			showLoader: true,
		});
		let url = "";
		let params = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentTaskId: this.props.masterDataId,
			// pageNo:this.state.currentPageNo
			type:this.props.type
		}
		if(this.props.isDraftOnward === true){
			url = Constants.GetDraftTaskRemarks
		}else{
			url = Constants.GetTaskRemarks;
		}

		fetch(url, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(params),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						datalist: data.data.remarks,
						// totalCount:data.data.count,
						showLoader: false,
						componentDidMountFlag: true,
					});
				} else {
					this.setState({
						datalist: [],
						// totalCount:0,
						showLoader: false,
						componentDidMountFlag: true,
					});
				}
			});
	};

	render() {
		return (
			<Modal className="review-history-dialog custom-dialog" show={this.props.showReviewCommentsDialog} backdrop="static">
				<Modal.Header>
					<h5>{this.props.type==="Remark"?"Remarks":this.props.type==="Comment"?"Review Comments":""}</h5>
					<button onClick={this.handleReviewCommentsDialogClose} type="button" data-dismiss="modal">
						<span class="material-icons">close</span>
					</button>
				</Modal.Header>
				<hr />
				<div className="modal-body">
					{this.state.showLoader && (
						<div className="modal-loading-text">
							<p>Loading....</p>
						</div>
					)}
					<div className="review-history-wrapper">
						{
							this.props.type === "Comment" && this.props.isDraftOnward !== true && !this.props.isCompletedScreen && 
							<div className="search-addnew-bg m-t-0">
								<div className="search-addnew">
									<div></div>
									<AddNewButtonLayout themeSettings={this.props.themeSettings}>
										<a onClick={this.handleCreateDialogShow} href="javascript:void(0);">
											<span className="material-icons">add_circle</span>
											<p>Add New</p>
										</a>
									</AddNewButtonLayout>
								</div>
							</div>
						}
						{this.state.componentDidMountFlag && (
							<div className="review-history-list">
								{!isEmptyArray(this.state.datalist) &&
									this.state.datalist.map((item,idx) => {
										return (
											<div className="history-item-layout">
												<div className="flex-center-layout just-space-between">
													<p className="font-bold">{item.remark}</p>
													{
														idx === 0 && this.props.type === "Remark" && this.props.isDraftOnward !== true &&
														<div onClick = {this.handleEditDialogShow.bind(this,item)} className="history-span">
															<span className="material-icons">edit</span>
														</div>
													}
													{
														idx === 0 && item.userId === userDetails.userId && this.props.type === "Comment" && this.props.isDraftOnward !== true &&
														<div onClick = {this.handleEditDialogShow.bind(this,item)} className="history-span">
															<span className="material-icons">edit</span>
														</div>
													}
												</div>
												<span>
													{" "}
													{ifEmptyReturn(item.salutation) + " " + item.firstName + " " + ifEmptyReturn(item.middleName) + " " + item.lastName} (
													{item.createBy})
												</span>
												<span className="review-history-date-span">
													{moment(item.createDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD-MMM-YYYY hh:mm a")}
												</span>
											</div>
										);
									})}
							</div>
						)}
						{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
							<div className="dialog-no-records">
								<p>{Constants.NO_RECORDS_WARNING}</p>
							</div>
						)}
					</div>
				</div>
				<AddCommentDialog
					showCreateDialog={this.state.showCreateDialog}
					isEdit={this.state.isEdit}
					selectedAssignmentTaskRemarkId={this.state.selectedAssignmentTaskRemarkId}
					selectedRemark={this.state.selectedRemark}
					handleAddDialogClose={this.handleAddDialogClose}
                    assignmentTaskId= {this.props.masterDataId}
                    themeSettings={this.props.themeSettings}
                    assignmentId = {this.props.assignmentId}
					type = {this.props.type}
                    assignmentMatrix = {this.props.assignmentMatrix}
                    assignmentTaskUserId = {this.props.assignmentTaskUserId}
				/>
			</Modal>
		);
	}
}

export default ReviewHistoryDialog;
