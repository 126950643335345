import React, { Component } from 'react';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import UnitsTabs from './unitstopmenu';
import Grouping from './unitgrouping';

import ListUnits from './listunits';
import Employees from './listemployees'
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import * as Constants from '../../common/Global/constants';

import { isEmptyVariable,getLocalStorageVariables,getThemeSettingsVariables } from '../../common/Global/commonFunctions';

const themeSettings  = getThemeSettingsVariables();
class ClientsMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unitId:"",
            empHeaderText:"",
            companyId:"",
            companyType:"",
            displayingUnits:true,
            displayingEmployees:false,
            displayCards:true,
            displayingGrouping:false,

        }
    }

    viewEmployeesDropDownItemClick = (unitId,empHeaderText) => {
        this.setState({
            unitId:unitId,
            empHeaderText:empHeaderText,
            displayingUnits:false,
            displayingEmployees:true,
            displayingGrouping:false,
                        
        })
    }

    employeesTabItemOnClick = (unitId) => {
        this.setState({
            unitId:unitId,
            displayingUnits:false,
            displayingEmployees:true,
            displayingGrouping:false
        })
    }

    unitTabItemOnClick = () =>{
        this.setState({
            displayingUnits:true,
            companyType:this.state.companyType,
            displayingEmployees:false,
            displayingGrouping:false
        })
    }  
    
    gropingTabItemOnClick = () =>{
        this.setState({
            displayingUnits:false,
            displayingEmployees:false,
            displayingGrouping:true
        })
    }
    gropingTabItemOnClick = () =>{
        this.setState({
            displayingUnits:false,
            displayingEmployees:false,
            displayingGrouping:true
        })
    }
    toggleCardsDisplay = () =>{
        this.setState({
            displayCards:!this.state.displayCards
        })
    }

    componentDidMount(){
        this.initData();
    }
    initData = () => {
        const userDetails  = getLocalStorageVariables();
        fetch(Constants.GetUserMenu,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let companyId = data.data.company.companyId;
                let companyType = data.data.company.companyType
                this.setState({
                    companyId:companyId,
                    companyType: companyType
                })
            }else{
            }
        });
    }

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <UnitsTabs 
                                    unitId = {this.state.unitId}

                                    displayingUnits = {this.state.displayingUnits}
                                    displayingEmployees = {this.state.displayingEmployees}
                                    displayingGrouping = {this.state.displayingGrouping}

                                    unitTabItemOnClick = {this.unitTabItemOnClick}
                                    employeesTabItemOnClick = {this.employeesTabItemOnClick}
                                    gropingTabItemOnClick = {this.gropingTabItemOnClick}

                                    toggleCardsDisplay = {this.toggleCardsDisplay}
                                    themeSettings = {themeSettings}
                                />

                                {
                                    this.state.displayingUnits &&
                                    <ListUnits 
                                        onlyParent={true}
                                        companyType={this.state.companyType}
                                        viewEmployeesDropDownItemClick = {this.viewEmployeesDropDownItemClick}
                                        displayCards={this.state.displayCards}
                                        themeSettings = {themeSettings}
                                    />
                                }
                                {
                                    !isEmptyVariable(this.state.unitId) &&
                                    this.state.displayingEmployees &&
                                    <Employees 
                                        unitId = {this.state.unitId}
                                        empHeaderText = {this.state.empHeaderText}
                                        displayCards={this.state.displayCards}
                                        themeSettings = {themeSettings}
                                        companyId={this.state.companyId}
                                    />
                                }
                                 {this.state.displayingGrouping && 
                                <Grouping />
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </Router>
        )
    }
}

export default ClientsMain;

