import React, { Component } from 'react';
import {Modal, Tab, Tabs} from 'react-bootstrap';
import AssignResourcesComponent from "./assignResourcesComp"
import AssignReportResourcesComponent from "./assignReportResourcesComp"
import ReassignResources from "./assignResourcesCompEdit";
import ReassignReportResuces from "./assignReportResourcesCompEdit";
import { getLocalStorageVariables, isEmptyVariable } from '../../../common/Global/commonFunctions';
import * as Constants from '../../../common/Global/constants';
const userDetails  = getLocalStorageVariables();

class AssignResources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            datalist:[],
            // allDataList:[],
            totalCount:0,
            currentPageNo: 1,
            key:""
            // formErrors:{},
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showResourcesDialog){
            this.setState({
                key:this.props.selectedAssignmentStatus === "Scheduled" || 
        this.props.selectedAssignmentStatus === "Ongoing"?"teamResource":"reportResource"
            })
            // this.getInitData()
            // if(this.props.isEdit){
            //     this.setState({
            //         componentDidMountFlag:false,
            //         showLoader:false,
            //         datalist:[],
            //         datalistReport:[],
            //         totalCount:0,
            //         currentPageNo: 1,
            //         formErrors:{},
    
            //     })
            // }else{
            //     this.setState({
            //         componentDidMountFlag:false,
            //         showLoader:false,
            //         datalist:[],
            //         totalCount:0,
            //         currentPageNo: 1,
            //         formErrors:{},
    
            //     })
            // }
        }
    }

    // getInitData = () => {
    //     fetch(Constants.AssignResourceMasterData,
    //     {
    //         method:"POST",
    //         mode:"cors",
    //         body: new URLSearchParams(
    //         {
    //             email:userDetails.email,
    //             accessToken:userDetails.accessToken,
    //             assignmentId:this.props.assignmentId,
    //         })
    //     })
    //     .then(response => { return response.json(); } )
    //     .then(data =>
    //     {
    //         if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
    //             data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
    //             localStorage.clear();
    //             window.location="/";
    //         }else if(data.responseCode === Constants.CODE_SUCCESS){
    //             this.setState({
    //                 datalist:data.data.users,
    //                 allDataList:[...data.data.users],
    //                 componentDidMountFlag:true
    //             })
    //         }else{
    //             this.setState({
    //                 datalist:[],
    //                 componentDidMountFlag:true
    //             })
    //         }
    //     });
    // }

    onTabSelect = (key) => {
        this.setState({
            key:key
        })
    }

    render() {
        const showExecResource =
         this.props.selectedAssignmentStatus === "Scheduled" || 
        this.props.selectedAssignmentStatus === "Ongoing";
        const showReviewCycleCheckbox = true;
        // const showReviewCycleCheckbox = !isEmptyVariable(this.props.selectedObsTemplateId);

        return(
            <Modal className="assign-resources-dialog custom-dialog" 
            show={this.props.showResourcesDialog} backdrop="static">
                <Modal.Header>
                    <h5>Assign Resources</h5>
                    <button 
                        onClick={this.props.handleAllResourcesDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    {
                        <Tabs className="report-details-tab"
                            activeKey={this.state.key}
                            onSelect={(k) => this.onTabSelect(k)}
                            defaultActiveKey={(showExecResource)?"teamResource":"reportResource"}
                            id="assignResources">
                            {
                                //if it is assign resources show this dialog
                                (showExecResource) &&
                                <Tab eventKey="teamResource" title="Execute Resources">
                                    {this.state.key == "teamResource" &&
                                        (this.props.assignResourcesEdit && this.props.selectedAssignmentStatus !=='Scheduled'
                                        ?
                                        <ReassignResources
                                            handleAllResourcesDialogClose = {this.props.handleAllResourcesDialogClose}
                                            assignmentId = {this.props.assignmentId}
                                            selectedAssignmentStatus = {this.props.selectedAssignmentStatus}
                                            themeSettings = {this.props.themeSettings}
                                            showReviewCycleCheckbox= {showReviewCycleCheckbox}
                                            reviewAssignmentTask={this.props.reviewAssignmentTask}
                                            // datalist={this.state.datalist}
                                        />
                                        :
                                        <AssignResourcesComponent
                                            handleAllResourcesDialogClose = {this.props.handleAllResourcesDialogClose}
                                            assignmentId = {this.props.assignmentId}
                                            themeSettings = {this.props.themeSettings}
                                            selectedAssignmentStatus = {this.props.selectedAssignmentStatus}
                                            isEdit= {this.props.assignResourcesEdit}
                                            showReviewCycleCheckbox= {showReviewCycleCheckbox}
                                            reviewAssignmentTask={this.props.reviewAssignmentTask}
                                            // datalist={this.state.datalist}
                                            // allDataList={this.state.allDataList}
                                        />)
                                    }
                                    
                                </Tab>
                            }
                            <Tab eventKey="reportResource" title="Report Resources">
                                {this.state.key == "reportResource" &&
                                    (this.props.assignReportResourcesEdit && this.props.selectedAssignmentStatus !=='Scheduled'
                                    ?
                                    <ReassignReportResuces
                                        handleAllResourcesDialogClose = {this.props.handleAllResourcesDialogClose}
                                        assignmentId = {this.props.assignmentId}
                                        selectedAssignmentStatus = {this.props.selectedAssignmentStatus}
                                        themeSettings = {this.props.themeSettings}
                                        // datalist={this.state.datalist}
                                    />
                                    :
                                    <AssignReportResourcesComponent
                                        handleAllResourcesDialogClose = {this.props.handleAllResourcesDialogClose}
                                        selectedAssignmentStatus = {this.props.selectedAssignmentStatus}
                                        assignmentId = {this.props.assignmentId}
                                        themeSettings = {this.props.themeSettings}
                                        isEdit= {this.props.assignReportResourcesEdit}
                                        // datalist={this.state.datalist}
                                        // allDataList={this.state.allDataList}
                                    />)
                                }
                                
                            </Tab>
                        </Tabs>
                    }
                </div>
            </Modal>
        );
    }
}

export default AssignResources;