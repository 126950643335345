import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {TableRadioButton,DialogSaveButton} from '../../common/Global/globalStyles.style';
import {getLocalStorageVariables,isEmptyVariable,
    isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();

class AssertionAssignmentList extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedRiskId:"",
            selectedRiskObj:{},
            // riskErr:"",
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showRiskDialog){
            let selectedObj=this.props.selectedRiskObj;
            let selectedId=isEmptyVariable(this.props.selectedRiskObj.riskLevelId)
            ?"":this.props.selectedRiskObj.riskLevelId;

            this.setState({
                selectedRiskId:selectedId,
                selectedRiskObj:selectedObj,
                // riskErr:"",
            })
            
        }
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let isChecked = e.target.checked;
        let riskLevelArrIndex = e.target.name;
        let selectedId = "";
        let selectedRiskObj = {};
        
        if(isChecked){
            selectedId = this.props.riskArr[riskLevelArrIndex].riskLevelId;
            selectedRiskObj = this.props.riskArr[riskLevelArrIndex];
        }

        this.setState({
            selectedRiskId:selectedId,
            selectedRiskObj:selectedRiskObj
        });
    }

    returnSelectedDeptId = () => {
        // let deptErrTemp = "";

        if(isEmptyVariable(this.state.selectedRiskId)){
            // deptErrTemp =  "Select the risk level";
            toast.warning("Select the risk level");
            // this.setState({
            //     riskErr:deptErrTemp,
            // })

            // setTimeout(function(){
            //     this.setState({
            //         riskErr:"",
            //     });
            // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else{
            this.props.handleSelectRiskDialogSaveClose(this.state.selectedRiskObj);
        }
    }
    /*****************************API*********************************/
    render(){
        return(
            <section>
                <Modal className="task-select-dept-dialog custom-dialog"
                    show={this.props.showRiskDialog} backdrop="static">
                    <Modal.Header>
                        <h5>Risk Level</h5>
                        <button 
                            onClick={this.props.handleSelectRiskDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        <div className="body-wrapper">
                            {
                                !isEmptyArray(this.props.riskArr) && 
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" width={"8%"}></th>
                                                    <th className="text-center" width={"5%"}>No</th>
                                                    <th>Risk Level</th>
                                                    <th>Color Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                !isEmptyArray(this.props.riskArr) && 
                                                this.props.riskArr.map((item,idx) => {

                                                    return <tr>
                                                        <td>
                                                            <TableRadioButton
                                                                themeSettings={this.props.themeSettings}>
                                                                <input 
                                                                    type="checkbox"
                                                                    name={idx}
                                                                    id={item.riskLevelId}
                                                                    onChange={this.handleSelection}
                                                                    checked={item.riskLevelId+""===this.state.selectedRiskId+""?true:false}
                                                                />
                                                                <label for={item.riskLevelId} 
                                                                style={{marginLeft:6}}></label>
                                                            </TableRadioButton>
                                                        </td>
                                                        <td className="text-center">{(idx+1)}</td>
                                                        <td>{item.riskLevel}</td>
                                                        <td>
                                                            <div className="risk-level-color-wrapper" style={{width:"38px"}}>
                                                                <div className="risk-level-color"
                                                                    style={{background:item.colorCode}}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                isEmptyArray(this.props.riskArr) &&
                                <div class="no-items-layout">
                                    <div class="no-items-card">
                                        <h6>
                                        {
                                            isEmptyVariable(this.state.apiSearchKey)?
                                            Constants.NO_RECORDS_WARNING
                                            :
                                            Constants.EMPTY_SEARCH_WARNING
                                        }
                                        </h6>
                                    </div>
                                </div>
                            }
                            {/* {
                                !isEmptyVariable(this.state.riskErr) &&
                                <span className="cm-error">{this.state.riskErr}</span>
                            } */}
                            <div className="modal-close-save m-t-15">
                                <div className="close-save-col">
                                    <button onClick={this.props.handleSelectRiskDialogClose} type="button" 
                                    className="modal-close m-b-0">Cancel</button>
                                    <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                    onClick={this.returnSelectedDeptId} type="button" 
                                    className="modal-save m-b-0">Save</DialogSaveButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
        )
    }
}

export default AssertionAssignmentList;