import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { SearchSpan, DialogSaveButton } from "../../../common/Global/globalStyles.style";
import Sidebar from "../../../common/Sidebar";
import Topbar from "../../../common/Topbar";
// import MasterMenuTabs from '../Common/topmenu';
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import AlertDialog from "../../../common/AlertDialog";
import Pagination from "../../../common/Global/pagination";
import MasterMenuTabs from "../Common/topmenu";
import RegularDropdown from "../../../common/DropdownMenus/RegularDropdown";
import UpdateReviewStatusDialog from '../Common/assignmentupdatereviewstatusdialog'
import ReviewHistoryDialog from '../../../common/reviewhistorydialog'
import {
	getLocalStorageVariables,
	getThemeSettingsVariables,
	GetOrderedMasterTabMenuReports,
	isEmptyVariable,
	sortTable,
	truncateString,
	isEmptyArray,
	getStringWithSpaceAfterComma
} from "../../../common/Global/commonFunctions";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import {getDropdownActionArrayDraftReports} from "../../../common/Global/reviewFunctions"
import { Modal, Tabs, Tab } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment";
// import ReportDetails from "../Common/reportDetails";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const sortName = "c.companyName";
const sortReferenceNo = "a.referenceNo";
const sortDescription = "assignmentTypeName";
const sortEndDate = "a.endDate";
const sortReportDate = "a.reportDate";
const sortArea = "a.assignmentArea";
const sortLocation = "unitNameCityList";
const sortunitAddressList = "unitAddressList";
const sortunitCityList ="unitCityList";

const masterTypeDefaultPlaceholder = "Select Master Type";
const masterTypeFilterDefaultPlaceholder = "All Master Types";
const departmentDropdownDefaultValue = "Select Department";
const departmentFilterDropdownDefaultValue = "All";

// const activeStatus = "Active";
// const inActiveStatus = "Inactive";
// const underReviewStatus = "Under Review";
// const reviewedStatus = "Reviewed";
const requestChangeCheckText="Request Change";


class ReportAssignmentList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			// statusPlaceholder: reviewedStatus,
			resultSizePlaceholder: Constants.RESULT_SIZE,
			sort: "",
			sortDir: "",
			company:{},
			masterTypeArr: [],
			masterTypeSelected: "All",
			masterFilterPlaceholder: masterTypeFilterDefaultPlaceholder,

			departmentArr: [],
			selectedDepartmentName: departmentDropdownDefaultValue,
			selectedDepartmentId: "",

			deptOnly: "N",

			showAlertDialog: false,
			operationType: "",
			deleteReactivateId: "",
			deleteReactivateMasterType: "",
			reviewLevel: "",
			alertDialogMessage: "",
			alertDialogHeading: "",
			proceedBtnLabel: "",

			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",

			showUpdateReviewDialog: false,
			showReviewHistoryDialog: false,

			// status: reviewedStatus,
			// statusArr: [
				
			// 	{
			// 		label: reviewedStatus,
			// 		isSelected: true,
			// 	},
			// 	{
			// 		label: underReviewStatus,
			// 		isSelected: false,
			// 	}
			// ],
			resultSizeArr: Constants.RESULT_SIZE_DD_ARR,

			name: "",
			shortName: "",
			description: "",
			masterType: "",
			formErrors: {},
			submitClickedAtleastOnce: false,

			// showDetailsDialog:false,
            // selectedItem:"",
		};
		this.masterTypeFilterArr = [];
	}

	componentDidMount() {
		this.getInitData();
	}

	handleChange = (e) => {
		const { name, value } = e.target;

		let FormErrorsTemp = this.state.formErrors;

		if (this.state.submitClickedAtleastOnce) {
			if (name === "shortName") {
				if (isEmptyVariable(value)) {
					FormErrorsTemp["shortName"] = "Please enter master short name";
				} else {
					FormErrorsTemp["shortName"] = "";
				}
			} else if (name === "name") {
				console.log("Inside name");
				if (isEmptyVariable(value)) {
					console.log("Inside name value:" + value);
					FormErrorsTemp["name"] = "Please enter master name";
				} else {
					FormErrorsTemp["name"] = "";
				}
			} else if (name === "description") {
				if (!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH) {
					FormErrorsTemp["description"] = "Description must be less then 500 characters";
				} else {
					FormErrorsTemp["description"] = "";
				}
			}
		}

		this.setState({
			[name]: value,
			formErrors: FormErrorsTemp,
		});
	};

	handleCheck = (e) => {
		let isChecked = e.target.checked;
		let name = e.target.name;

		if (isChecked) {
			this.setState(
				{
					deptOnly: "Y",
				},
				() => {
					this.getAssignmentList();
				}
			);
		} else {
			this.setState(
				{
					deptOnly: "N",
				},
				() => {
					this.getAssignmentList();
				}
			);
		}
	};

	handleChangeSearch = (e) => {
		const { name, value } = e.target;

		this.setState({
			[name]: value,
		});
	};

	onSearchIconClick = () => {
		this.setState(
			{
				currentPageNo: 1,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
			this.setState(
				{
					currentPageNo: 1,
				},
				() => {
					this.getAssignmentList();
				}
			);
		}
	};

	handleAlertDialogClose = () => {
		this.setState({
			showAlertDialog: false,
			alertDialogMessage: "",
		});
	};

	handleAlertDialogCloseInfo = () => {
		this.setState({
			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
		});
	};

	handleUpdateReviewDialogClose = (reloadFlag) => {
		this.setState(
			{
				showUpdateReviewDialog: false,
				deleteReactivateMasterType: "",
				deleteReactivateId: "",
				reviewLevel: "",
			},
			() => {
				if (reloadFlag) {
					this.getAssignmentList();
				}
			}
		);
	};

	handleReviewHistoryDialogClose = () => {
		this.setState({
			showReviewHistoryDialog: false,
			deleteReactivateMasterType: "",
			deleteReactivateId: "",
		});
	};

	

	// handleDetailsDialogClose = () =>{
    //     this.setState({
    //         showDetailsDialog:false,
    //         selectedItem:"",
    //     });
    // }

    // handleDetailsDialogShow = (item) => {
    //     this.setState({
    //         showDetailsDialog:true,
    //         selectedItem:item,
    //     });
    // }

	

	// statusDropdownClick = (item, selectedIndex) => {
	// 	let tempArr = this.state.statusArr;
	// 	let placeholder = "";

	// 	for (const [i, value] of tempArr.entries()) {
	// 		if (i === selectedIndex) {
	// 			tempArr[i].isSelected = true;
	// 			placeholder = value.label;
	// 		} else {
	// 			tempArr[i].isSelected = false;
	// 		}
	// 	}

	// 	this.setState(
	// 		{
	// 			currentPageNo: 1,
	// 			statusArr: tempArr,
	// 			status: item.label,
	// 			statusPlaceholder: placeholder,
	// 		},
	// 		() => {
	// 			this.getAssignmentList();
	// 		}
	// 	);
	// };

	handleShowObservations = (assignmentId) => {
		this.props.history.push(Constants.URL_DRAFT_OBSERVATIONS + "/" + assignmentId);

	};

	resultSizeDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.resultSizeArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				resultSizeArr: tempArr,
				resultSize: item.label,
				resultSizePlaceholder: placeholder,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	masterFilterDropdownClick = (item, selectedIndex) => {
		let placeholder = "";

		for (const [i, value] of this.masterTypeFilterArr.entries()) {
			if (i === selectedIndex) {
				this.masterTypeFilterArr[i].isSelected = true;
				placeholder = value.masterTypeDesc;
			} else {
				this.masterTypeFilterArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				masterTypeSelected: placeholder,
				masterFilterPlaceholder: placeholder === "All" ? masterTypeFilterDefaultPlaceholder : placeholder,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	deptFilterDropdownClick = (item, selectedIndex) => {
		let placeholder = "";
		let id = "";
		let filterDeptArr = this.state.filterDeptArr;

		for (const [i, value] of filterDeptArr.entries()) {
			if (i === selectedIndex) {
				filterDeptArr[i].isSelected = true;
				placeholder = value.name;
				id = value.departmentId;
			} else {
				filterDeptArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				selectedFilterDepartmentName: placeholder,
				selectedFilterDepartmentId: id,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

	masterTypeDropdownClick = (item) => {
		let FormErrorsTemp = this.state.formErrors;

		if (this.state.submitClickedAtleastOnce) {
			if (isEmptyVariable(this.state.masterType) || this.state.masterType === masterTypeDefaultPlaceholder) {
				FormErrorsTemp["masterType"] = "";
			} else {
				FormErrorsTemp["masterType"] = "";
			}
		}

		this.setState({
			masterType: item.masterTypeDesc,
			formErrors: FormErrorsTemp,
		});
	};

	deptDropdownClick = (item) => {
		let FormErrorsTemp = this.state.formErrors;

		if (this.state.submitClickedAtleastOnce) {
			FormErrorsTemp["deparment"] = "";
		}

		this.setState({
			selectedDepartmentName: item.name,
			selectedDepartmentId: item.departmentId,
			formErrors: FormErrorsTemp,
		});
	};

	onTableDropDownItemClick = (item, dropdownItem) => {
		// if (item.label === "Show Observations") {
		// 	this.props.history.push(Constants.URL_DRAFT_OBSERVATIONS + "/" + dropdownItem.assignmentId);
		// } else 
		if (item.label === "Submit For Review") {
			this.setState({
				operationType: item.label,
				alertDialogHeading: "Submit For Review",
				proceedBtnLabel: "Submit",
				deleteReactivateId: dropdownItem.assignmentId,
				deleteReactivateMasterType: dropdownItem.masterType,
				showAlertDialog: true,
				alertDialogMessage: "Are you sure you want to submit " + dropdownItem.referenceNo + " for review?",
			});
		} else if (item.label === "Update Review Status") {
			this.setState({
				reviewLevel: dropdownItem.reviewLevel,
				deleteReactivateId: dropdownItem.assignmentId,
				deleteReactivateMasterType: dropdownItem.masterType,
				showUpdateReviewDialog: true,
			});
		}else if (item.label === "Review Remarks") {
			this.setState({
				operationType: item.label,
				alertDialogHeading: "Review Remarks",
				proceedBtnLabel: "Okay",
				showAlertDialog: true,
				alertDialogMessage: '"' + dropdownItem.lastReviewRemark + '"',
			});
		}else if (item.label === "Finalize Report") {
			this.setState({
				operationType: item.label,
				deleteReactivateId: dropdownItem.assignmentId,
				alertDialogHeading: "Finalize Report",
				proceedBtnLabel: "Yes",
				showAlertDialog: true,
				alertDialogMessage: "Are you sure you want to finalize " + dropdownItem.referenceNo + " report?",
			});
		} else if (item.label === "Review History") {
			this.setState({
				deleteReactivateId: dropdownItem.assignmentId,
				deleteReactivateMasterType: "Draft",
				showReviewHistoryDialog: true,
			});
		}
		// else if (item.label === "Sample Checked") {
		// 	this.props.history.push(Constants.URL_DRAFT_SAMPLE_CHECKED+"/"+dropdownItem.assignmentId);

		// } else if (item.label === "Working Papers") {
		// 	this.props.history.push(Constants.URL_DRAFT_WORKING_PAPERS+"/"+dropdownItem.assignmentId);
		// } 
	};

	sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};
	handleExecuteTaskList = (assignmentId) => {
		this.props.history.push(Constants.URL_EXECUTETASK + "/" + assignmentId);
	};
	/*******************API CALLS*******************/
	getInitData = () => {
		this.setState({
			showLoader: true,
			// showDetailsDialog:false,
            // selectedItem:"",
		});

		Promise.all([
			fetch(Constants.GetDraftReports, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					pageNo: this.state.currentPageNo,
					resultsize: this.state.resultSize,
					// status:this.state.status,
					sort: this.state.sort,
					sortDir: this.state.sortDir,
					
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
		])
			.then(([masterRes, menuRes]) => {
				return Promise.all([masterRes.json(), menuRes.json()]);
			})
			.then(([masterRes, menuRes]) => {
				let datalistTemp = [];
				let totalCountTemp = 0;
				let deptTemp = [];
				let menusTemp = {};
				let companyTemp = {};

				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					datalistTemp = masterRes.data.result;
					totalCountTemp = masterRes.data.count;
				} else {
				}
				if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
					deptTemp = menuRes.data.departments;
					menusTemp = menuRes.data.menus;
					companyTemp = menuRes.data.company;
				} else {
				}
				
				

				let menusTemp2 = GetOrderedMasterTabMenuReports(menusTemp);

				//Filter Dept array
				let filterDeptArr = [];
				let selectedFilterDepartmentId = "-100";
				let selectedFilterDepartmentName = "All";
				if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
				} else {
					let obj = {
						departmentId: "-100",
						name: departmentFilterDropdownDefaultValue,
						isSelected: true,
					};
					filterDeptArr = [...deptTemp];
					filterDeptArr.splice(0, 0, obj);
				}

				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					datalist: datalistTemp,
					totalCount: totalCountTemp,
					menus: menusTemp2,
					company:companyTemp,
					filterDeptArr: filterDeptArr,
					selectedFilterDepartmentId: selectedFilterDepartmentId,
					selectedFilterDepartmentName: selectedFilterDepartmentName,
				});
			});
	};

	getAssignmentList = () => {
		this.setState({
			showLoader: true,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			pageNo: this.state.currentPageNo,
			search: this.state.searchkey,
			resultsize: this.state.resultSize,
			// status:this.state.status,
			sort: this.state.sort,
			sortDir: this.state.sortDir,
		};

		if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue) {
			postParam.departmentId = this.state.selectedFilterDepartmentId;
		}

		fetch(Constants.GetDraftReports, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						datalist: data.data.result,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						totalCount: 0,
						componentDidMountFlag: true,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};

	onChangePage = (page) => {
		// update state with new page of items
		if (page !== this.state.currentPageNo) {
			this.setState({
				showLoader: true,
			});

			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				pageNo: page,
				search: this.state.searchkey,
				resultsize: this.state.resultSize,
				// status:this.state.status,
				sort: this.state.sort,
				sortDir: this.state.sortDir,
			};

			if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== "All") {
				postParam.departmentId = this.state.selectedFilterDepartmentId;
			}

			fetch(Constants.GetDraftReports, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState({
							showLoader: false,
							datalist: data.data.result,
							totalCount: data.data.count,
							apiSearchKey: this.state.searchkey,
							currentPageNo: page,
						});
					} else {
						this.setState({
							datalist: [],
							showLoader: false,
							apiSearchKey: this.state.searchkey,
						});
					}
				});
		}
	};

	handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            assignmentId:this.state.deleteReactivateId,
        }
        let callApi = true;

        if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitDraftReportForReview;
        }
		else if(this.state.operationType === "Review Remarks"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
            })
        }
		else if(this.state.operationType === "Finalize Report"){
            url = Constants.FinalizeReport;
        }

        if(callApi){
            this.setState({
                showLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        showCreateDraftAlertDialog:false,
                    },() => {
                        this.getAssignmentList();
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        deleteReactivateId:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        showCreateDraftAlertDialog:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

	/*******************API CALLS END HERE*******************/

	render() {
		return (
			<Router>
				<main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />

						<div className="dashboard-right-bg position-relative">
							<div className="dashboard-wrapper">
								<Topbar />
								<MasterMenuTabs assignmentId={this.state.assignmentId} masterMenus={this.state.menus} themeSettings={themeSettings} />
								{this.state.showLoader && (
									<div
										className="loading-layout"
										style={{ background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)` }}
									>
										<div className="loading-text-layout">
											<p
												style={{
													color: `rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`,
												}}
											>
												Loading....
											</p>
										</div>
									</div>
								)}
								{
									// Need to check component did mount flag, other wise until api is called,
									// it shows no items found layout.
									this.state.componentDidMountFlag && (
										<div className="body-wrapper">
											<div className="search-addnew-bg">
												<div className="search-addnew">
													<div className="search-filter-layout">
														<FilterDropDown
															placeholder={this.state.resultSizePlaceholder}
															dropdownArr={this.state.resultSizeArr}
															onDropDownItemClick={this.resultSizeDropdownClick}
															themeSettings={themeSettings}
															mleft={0}
														/>
														<div className="search-col m-l-10">
															<input
																name="searchkey"
																type="text"
																onChange={this.handleChangeSearch}
																placeholder="Search"
																onKeyPress={this.onEnterBtnPress}
																value={this.state.searchkey}
															/>
															<SearchSpan themeSettings={themeSettings} onClick={this.onSearchIconClick} className="material-icons">
																{" "}
																search{" "}
															</SearchSpan>
														</div>

														{/* <FilterDropDown
															placeholder={this.state.statusPlaceholder}
															dropdownArr={this.state.statusArr}
															onDropDownItemClick={this.statusDropdownClick}
															themeSettings={themeSettings}
														/> */}
														

														{this.state.filterDeptArr.length > 0 && (
															<FilterDropDown
																placeholder={this.state.selectedFilterDepartmentName}
																dropdownArr={this.state.filterDeptArr}
																onDropDownItemClick={this.deptFilterDropdownClick}
																name={"name"}
																themeSettings={themeSettings}
																dropdownWidth={"120px"}
															/>
														)}
													</div>
												</div>
											</div>

											{
                                                !isEmptyArray(this.state.datalist) && (
												<div className="common-tab-bg">
													<div className="common-table" style={{ width: "100%" }}>
														<table className="table">
															<thead>
																<tr>
																	<th className="text-center" width={"5%"}>
																		No.
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortReferenceNo)}>
																		<div className="sort-header">
																			Ref No.
																			<span
																				className={
																					this.state.sort === sortReferenceNo ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	{
																		this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
																		<th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortLocation)}>
																			<div className="sort-header">Unit
																			<span className={(this.state.sort === sortLocation)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
																			</div>
																		</th>
																	}
                                                                    {
																		this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
																		<th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortunitAddressList)}>
																			<div className="sort-header">Name
																			<span className={(this.state.sort === sortunitAddressList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
																			</div>
																		</th>
																	}
																	{
																		this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
																		<th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortunitCityList)}>
																			<div className="sort-header">City
																			<span className={(this.state.sort === sortunitCityList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
																			</div>
																		</th>
																	}
																	{
																		this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
																		<th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
																			<div className="sort-header">Client
																			<span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
																			</div>
																		</th>
																	}
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDescription)}>
																		<div className="sort-header">
																			Type
																			<span
																				className={
																					this.state.sort === sortDescription
																						? "material-icons"
																						: "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortArea)}>
																		<div className="sort-header">
																		Scope
																			<span
																				className={
																					this.state.sort === sortArea ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
                                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortEndDate)}>
																		<div className="sort-header">
																			End Date
																			<span
																				className={
																					this.state.sort === sortEndDate ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortReportDate)}>
																		<div className="sort-header">
																			Report Date
																			<span
																				className={
																					this.state.sort === sortReportDate ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="text-center" width={"5%"}>
																		<div className="sort-header"></div>
																	</th>
																</tr>
															</thead>
															<tbody>
																{
                                                                    !isEmptyArray(this.state.datalist) &&
																	this.state.datalist.map((item, idx) => {
																		let actionObj = getDropdownActionArrayDraftReports(item.allowEdit, item.allowReview,
																		    item.status, item.currentReviewStatus, item.finalizeReport
																			// "Y"
																			);
																			let tempScop = [];
																			if(!isEmptyVariable(item.functionList)){
																				tempScop = item.functionList?.split(",").map((func) => func.split(":-:")[0]);
																			}else if(!isEmptyVariable(item.processList)){
																				console.log("true..")
																				tempScop = item.processList.split(",").map((func) => func.split(":-:")[0]);
																			}																        return (
																			<tr onClick={this.handleShowObservations.bind(this, item.assignmentId)}>
																			
																				<td className="text-center">
																					{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
																				</td>
																				<td>{item.referenceNo}</td>
                                                                                {
																					this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
																					<td>{truncateString(String(getStringWithSpaceAfterComma(item.unitNameCityList)).split("(")[0],100)}</td>
																				}
																				{
																					this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
																					<td>{item.companyName}</td>
																				}
																				{   
                                                                                    this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && 
                                                                                    <td>
                                                                                    {isEmptyVariable(item.unitAddressList)? "-": String(item.unitAddressList).split(":-:")[0]}
                                                                                    </td>
                                                                                }
                                                                                {   
                                                                                    this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && 
                                                                                    <td>
                                                                                    {isEmptyVariable(item.unitCityList)? "-": item.unitCityList}
                                                                                    </td>
                                                                                }
																				<td>{item.assignmentTypeName}</td>
																				<td>{tempScop.length==1?tempScop[0]:`${tempScop[0]} + ${tempScop.length-1}`}</td>
																				<td>{tempScop.length==1?tempScop[0]:`${tempScop[0]} + ${tempScop.length-1}`}</td>
																				<td>{moment(item.endDate).format("DD/MM/YYYY")}</td>
																				<td>{isEmptyVariable(item.reportDate)?"": moment(item.reportDate).format("DD/MM/YYYY")}</td>

																				<td className="text-center">
																					{actionObj.actionArr && actionObj.actionArr.length > 0 && (
																						<TableDropDown
																							actionArr={actionObj.actionArr}
																							onDropDownItemClick={this.onTableDropDownItemClick}
																							dropDownId={item}
																							dropdownWidth={actionObj.dropdownWidth}
																							themeSettings={themeSettings}
																						/>
																					)}
																				</td>
																			</tr>
																		);
																	})}
															</tbody>
														</table>
													</div>
												</div>
											)}
											{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
												<div class="no-items-layout">
													<div class="no-items-card">
														<h6>
															{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
														</h6>
													</div>
												</div>
											)}
											<div className="pagination-layout">
												<div className="row custom-row">
													<div className="col-md-9 custom-col">
														{this.state.totalCount > this.state.datalist.length && (
															<Pagination
																totalLength={this.state.totalCount}
																items={this.state.datalist}
																onChangePage={this.onChangePage}
																pageSize={this.state.resultSize}
																currentPageNo={this.state.currentPageNo}
																initialPage={this.state.currentPageNo}
																themeSettings={themeSettings}
															/>
														)}
													</div>
													<div className="col-md-3 custom-col">
														<p>{"Total Records: " + this.state.totalCount}</p>
													</div>
												</div>
											</div>
										</div>
									)
								}
							</div>
						</div>

						<AlertDialog
							showAlertDialog={this.state.showAlertDialog}
							handleAlertDialogClose={this.handleAlertDialogClose}
							type={
								this.state.operationType === "Delete"
									? Constants.ALERT_TYPE_WARNING
									: this.state.operationType === "Review Remarks"
									? Constants.ALERT_TYPE_ALERT
									: Constants.ALERT_TYPE_INFO
							}
							alertDialogHeading={this.state.alertDialogHeading}
							alertDialogMessage={this.state.alertDialogMessage}
							proceedBtnClick={this.handleRecordOperation}
							proceedBtnLabel={this.state.proceedBtnLabel}
							themeSettings={themeSettings}
						/>

						<AlertDialog
							showAlertDialog={this.state.showAlertDialogInfo}
							handleAlertDialogClose={this.handleAlertDialogCloseInfo}
							type={Constants.ALERT_TYPE_ALERT}
							alertDialogMessage={this.state.alertDialogMessageInfo}
							proceedBtnClick={this.handleAlertDialogCloseInfo}
							proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
							themeSettings={themeSettings}
						/>

						<UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
							isDraftOnward={true}
                        />

						<ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
							isDraftOnward = {true}
                        />
					
						{/* <ReportDetails
							showDetailsDialog={this.state.showDetailsDialog}
							handleDetailsDialogClose={this.handleDetailsDialogClose}
							selectedItem= {this.state.selectedItem}
							themeSettings={themeSettings}
						/> */}
					</section>
				</main>
			</Router>
		);
	}
}

export default ReportAssignmentList;
