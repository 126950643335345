import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import { matchPath, withRouter } from 'react-router-dom';
import { isEmptyVariable,getThemeSettingsVariables, isEmptyArray  } from '../../../common/Global/commonFunctions';
import { TopTabsLayout } from '../../../common/Global/globalStyles.style';
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import AlertDialog from '../../../common/AlertDialog';

const FILTER_DROPDOWN_ARR = [
    // {
    //     label: "Assign Overall Risk",
    //     isSelected: false,
    // },
    {
        label: "Tasks",
        isSelected: true,
    },
    {
        label: "Sample Checked",
        isSelected: false,
    },
    {
        label: "Working Papers",
        isSelected: false,
    }, 
    {
        label: "Timeline",
        isSelected: false,
    },
    {
        label: "Auditee",
        isSelected: false,
    },
    {
        label: "Do not report task",
        isSelected: false,
    },
    {
        label: "Upload Report",
        isSelected: false,
    },
    {
        label: "Uploaded Report",
        isSelected: false,
    },
]

let isOngoing ='';
let isDraft ='';
let isCompleted ='';
let themeColor = ``;
const themeSettings = getThemeSettingsVariables();
class MasterTopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterDropDownArr: FILTER_DROPDOWN_ARR,
            filterPlaceholder: this.props.filterPlaceholder,
            showAlertDialog:false,
            alertDialogMessage:"",
            screenName:""
        }
        isOngoing = this.checkActivePath(["/ongoingassignmentreports", "/ongoingobservations", "/ongoingsamplechecked", "/ongoingworkingpapers", "/editongoingobservation","/ongoingassignmentemployee","/ongoingtimeline","/ongoingoverall"]);
        isDraft = this.checkActivePath(["/draftassignmentreports", "/draftobservations", "/editdraftobservation", "/draftsamplechecked", "/draftworkingpapers","/draftassignmentemployee","/drafttimeline","/draftoverall"]);
        isCompleted = this.checkActivePath(["/completedassignmentreports", "/finalizedobservations", "/finalsamplechecked", "/finalworkingpapers", "/initialdraftobservations","/completedfollowupobservation","/completedfollowuptask","/finalassignmentemployee","/initdraftassignmentemployee","/finaltimeline","/initdrafttimeline","/finalizedoverall", "/initdraftoverall"]);
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            const userMenus = this.props.userMenus;
            const filterDropDownArr = this.state.filterDropDownArr;
            const isAssignmentLevelTemplateAdded = filterDropDownArr.find(arr => arr.label === "Overall");
            if(isEmptyVariable(isAssignmentLevelTemplateAdded) && !isEmptyVariable(userMenus) && !isEmptyVariable(userMenus['Assignment Level Template']) && userMenus["Assignment Level Template"] !== "NONE") {
                filterDropDownArr.push({
                    label: "Overall",
                    isSelected: false,
                });
            }
            this.setState({ filterDropDownArr });
        }
    }

    checkActivePath = (path) =>{
        const themeSettings = getThemeSettingsVariables();
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
       return matchPath(this.props.history.location.pathname, { path });

    }

    dropdownOnClick = (selectedItem) => {
        let tempFilterDataArray = this.state.filterDropDownArr;
        let tempSlectedLabel = "Tasks"
        tempFilterDataArray.map((item) => {
            if (item.label === selectedItem.label && selectedItem.label !== "Assign Overall Risk") {
                tempSlectedLabel = item.label
            }
        })
        this.setState({
            filterDropDownArr: tempFilterDataArray,
            filterPlaceholder: tempSlectedLabel,
        }, () => {
            this.props.dropdownOnClick(selectedItem)
        })
    }

    checkChangeUrl = (screenName) => {
        this.setState({
            screenName:screenName
        })
        if(!isEmptyArray(this.props.timeLineData)){
            let timeLineDataFilter = this.props.timeLineData.filter(e => e.isEditable == true && !isEmptyVariable(e.timelineType) && !isEmptyVariable(e.startDate));
            if(timeLineDataFilter.length > 0){
                this.setState({
                    showAlertDialog:true,
                    alertDialogMessage:"There is unsaved data. Do you want to save the data?"
                })
            }else{
                if(screenName==="Ongoing"){
                    window.location=Constants.URL_ONGOING_REPORTS
                }else if(screenName==="Draft"){
                    window.location=Constants.URL_DRAFT_REPORTS
                }else if(screenName==="Completed"){
                    window.location=Constants.URL_COMPLETED_REPORTS
                }
            }
        }else{
            if(screenName==="Ongoing"){
                window.location=Constants.URL_ONGOING_REPORTS
            }else if(screenName==="Draft"){
                window.location=Constants.URL_DRAFT_REPORTS
            }else if(screenName==="Completed"){
                window.location=Constants.URL_COMPLETED_REPORTS
            }
        }
    }

    handleAlertDialogOkayButtonClick  = () => {
        this.props.saveTimeLineData();
        let screenName = this.state.screenName;
        if(screenName==="Ongoing"){
            window.location=Constants.URL_ONGOING_REPORTS
        }else if(screenName==="Draft"){
            window.location=Constants.URL_DRAFT_REPORTS
        }else if(screenName==="Completed"){
            window.location=Constants.URL_COMPLETED_REPORTS
        }
    }

     handleAlertDialogClose = () => {
        let screenName = this.state.screenName;
        if(screenName==="Ongoing"){
            window.location=Constants.URL_ONGOING_REPORTS
        }else if(screenName==="Draft"){
            window.location=Constants.URL_DRAFT_REPORTS
        }else if(screenName==="Completed"){
            window.location=Constants.URL_COMPLETED_REPORTS
        }
    }

    render() {
        let width = 12.5;
        if (!isEmptyVariable(this.props.masterMenus)) {
            width = 12.5 * Object.keys(this.props.masterMenus).length
        }

        if(isEmptyVariable(this.props.observationTemplatedId) && this.props.filterDisplayFlag === true){
            width += 12.5;
        }

        return (
            <TopTabsLayout themeSettings={this.props.themeSettings}>

                <div className="question-tabs" style={{ marginRight: 0 }}>
                    {
                        !isEmptyVariable(this.props.masterMenus) &&
                        <ul style={{ width: width + "%" }}>
                            <li className={isOngoing? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                <a onClick={this.checkChangeUrl.bind(this,"Ongoing")}
                                    // href={Constants.URL_ONGOING_REPORTS}
                                    style={{color: !isOngoing ? themeColor : '', fontWeight: !isOngoing ? "bold" : "100px"}}>Ongoing</a>
                            </li>
                            <li className={isDraft? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                <a onClick={this.checkChangeUrl.bind(this,"Draft")}
                                    // href={Constants.URL_DRAFT_REPORTS}
                                    style={{color: !isDraft ? themeColor : '', fontWeight: !isDraft ? "bold" : "100px"}}>Draft</a>
                            </li>
                            <li className={isCompleted? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                <a onClick={this.checkChangeUrl.bind(this,"Completed")}
                                    // href={Constants.URL_COMPLETED_REPORTS}
                                    style={{color: !isCompleted ? themeColor : '', fontWeight: !isCompleted ? "bold" : "100px"}}>Completed</a>
                            </li>
                            {
                                isEmptyVariable(this.props.observationTemplatedId) && this.props.filterDisplayFlag === true &&
                                <div style={{ width: "100%" }}>
                                    <FilterDropDown
                                        placeholder={this.state.filterPlaceholder}
                                        dropdownArr={this.state.filterDropDownArr}
                                        onDropDownItemClick={this.dropdownOnClick}
                                        themeSettings={this.props.themeSettings}
                                        name={"label"}
                                    />
                                </div>
                            }
                        </ul>
                    }
                </div>
                <AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={Constants.ALERT_TYPE_WARNING}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={this.handleAlertDialogOkayButtonClick}
                    proceedBtnLabel={"Yes"}
                    themeSettings={themeSettings}
                />

                <div style={{ opacity: this.props.hideInfo ? "0" : "1" }} className="infocard-showhide-col">
                    {/* <div className="infocard-icon-col">
                        <img src="/assets/images/topbar-info.svg" />
                    </div> */}
                </div>
            </TopTabsLayout>
        )
    }
}

export default withRouter(MasterTopMenu);