import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout} from '../../common/Global/globalStyles.style';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import AlertDialog from '../../common/AlertDialog';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import Pagination from '../../common/Global/pagination';
import AddCompanyDialog from './addcompanydialog';
import CompanyDetailsDialog from './companydetailsdialog';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {getLocalStorageVariables,isEmptyVariable,sortTable,
    isEmptyArray} from '../../common/Global/commonFunctions';
import UploadLogoDialog from "./uploadcompanylogodialog";
const userDetails  = getLocalStorageVariables();

const editCompany = "Edit";
// const addEmployee = "Add Employee";
// const viewEmployee = "View Employees";
const viewUnits = "View Units";
const deleteCompany = "Delete";
const uploadLogo = "Upload Logo";
const reactivateCompany = "Reactivate";

const sortShortName = "companyShortName";
const sortCity = "city";
const sortCompanyName = "companyName";
const sortState = "state";

class ListCompanies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            companySearchKey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            parent:"",
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            status:"Active",
            statusArr:[
                {
                    label:"Active",
                    isSelected:true
                },
                {
                    label:"Inactive",
                    isSelected:false
                }
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            
            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            alertDialogMessage:"",

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            //Add Company
            showAddCompanyDialog:false,
            isEdit:false,
            editCompanyItem:"",

            //Company Details
            showCompanyDetailsDialog:false,
            selectedItem:"",

            //Upload Logo
            showUploadLogoDialog:false,
            companyLogo:"",
        }
    }

    componentDidMount(){
        this.getCompaniesList();
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === deleteCompany){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.companyId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete company "+dropdownItem.companyShortName+"?"
            })
        }else if(item.label === reactivateCompany){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.companyId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate company "+dropdownItem.companyShortName+"?"
            })
        }else if(item.label === viewUnits){
            this.props.viewUnitsDropDownItemClick(dropdownItem.companyId)
        }else if(item.label === editCompany){
            this.setState({
                showAddCompanyDialog:true,
                isEdit:true,
                editCompanyItem:dropdownItem
            })
        }
        else if(item.label === uploadLogo){
            this.setState({
                showUploadLogoDialog:true,
                companyLogo:dropdownItem.companyLogo,
                deleteReactivateId:dropdownItem.companyId,
            })
        }
        // else if(item.label === addEmployee){
        //     this.setState({
        //         showAddEmployeeDialog:true,
        //         selectedItem:dropdownItem
        //     })
        // }else if(item.label === viewEmployee){
        //     window.open(Constants.URL_EMPLOYEES+"/"+dropdownItem.unitId, '_blank');
        // }
    }

    onAddCompanyClick = () => {
        this.setState({
            showAddCompanyDialog:true,
            isEdit:false,
            editCompanyItem:""
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    onSearchIconClick = () => {
        this.getCompaniesList();
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.getCompaniesList();
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleCompanyDetailsDialogShow = (item) => {
        this.setState({
            showCompanyDetailsDialog:true,
            selectedItem:item
        })
    }

    handleCompanyDetailsDialogClose = () => {
        this.setState({
            showCompanyDetailsDialog:false,
            selectedItem:""
        })
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getCompaniesList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getCompaniesList();
        })
    }

    handleAddCompanyDialogClose = (reloadFlag) => {
        this.setState({
            selectedClientId:"",
            showAddCompanyDialog:false,
            selectedClientName:""
        },()=>{
            if(reloadFlag === true){
                this.getCompaniesList();
            }
        })
    }
    
    handleUploadLogoDialogClose = () => {
        this.setState({
            showUploadLogoDialog:false,
            companyLogo:"",
            deleteReactivateId:"",
        },()=>{
            this.getCompaniesList();
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getCompaniesList();
        })
    }
    /*******************API CALLS*******************/

    getCompaniesList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetCompanies,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.companySearchKey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.result,
                    parent:this.state.status === "Inactive"?"":data.data.parent,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.companySearchKey,
                })
            }else{
                this.setState({
                    datalist:[],
                    totalCount:0,
                    showLoader:false,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.companySearchKey,
                })
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });
            
            fetch(Constants.GetCompanies,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.companySearchKey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.companySearchKey,
                        currentPageNo:page,
                    })
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        componentDidMountFlag:true,
                        apiSearchKey:this.state.companySearchKey,
                    })
                }
            });
        }
    }

    deleteActivateClient = () => {
        let url = "";

        if(this.state.operationType === deleteCompany){
            url = Constants.DeleteCompany;
        }else if(this.state.operationType === reactivateCompany){
            url = Constants.ActivateCompany;
        }

        this.setState({
            showLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                companyId:this.state.deleteReactivateId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                },() =>{
                    this.getCompaniesList();
                })
            }else{  
                this.setState({
                    showLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,

                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    render() {
        const actionArr = [
            {
                icon:"edit",
                label:editCompany
            },
            // {
            //     icon:"person_add",
            //     label:addEmployee
            // },
            // {
            //     icon:"group",
            //     label:viewEmployee
            // },
            {
                icon:"business",
                label:viewUnits
            },
            {
                icon:"delete",
                label:deleteCompany
            },
            

        ]
        const actionArrParent = [
            {
                icon:"edit",
                label:editCompany
            },
            // {
            //     icon:"person_add",
            //     label:addEmployee
            // },
            // {
            //     icon:"group",
            //     label:viewEmployee
            // },
            {
                icon:"business",
                label:viewUnits
            },
            {
                icon:"upload",
                label:uploadLogo
            },
        ]
        const actionArrReactivate = [
            // {
            //     icon:"group",
            //     label:viewUnits
            // },
            {
                icon:"check_circle",
                label:reactivateCompany
            },
        ]

        return(
            <Router>
                <section>
                    {
                        this.state.showLoader &&
                        <div className="loading-layout"
                        style={{background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},0.7)`}}>
                            <div className="loading-text-layout">
                                <p
                                style={{color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`}}
                                >Loading....</p>
                            </div>
                        </div>
                    }

                    {
                        // Need to check component did mount flag, other wise until api is called,
                        // it shows no items found layout.
                        this.state.componentDidMountFlag &&
                        <div className="body-wrapper">
                            <div className="search-addnew-bg">
                                <div className="search-addnew">
                                    <div className="search-filter-layout">
                                        <FilterDropDown
                                            placeholder={this.state.resultSizePlaceholder}
                                            dropdownArr={this.state.resultSizeArr}
                                            onDropDownItemClick={this.resultSizeDropdownClick}
                                            themeSettings={this.props.themeSettings}
                                            mleft={0}
                                        />
                                        <div className="search-col m-l-10">
                                            <input 
                                                name="companySearchKey" 
                                                type="text"
                                                onChange={this.handleChange}
                                                placeholder="Search" 
                                                onKeyPress={this.onEnterBtnPress}
                                                value={this.state.companySearchKey} />
                                            <SearchSpan themeSettings={this.props.themeSettings} onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                        </div>
                                        <FilterDropDown
                                            placeholder={this.state.statusPlaceholder}
                                            dropdownArr={this.state.statusArr}
                                            onDropDownItemClick={this.statusDropdownClick}
                                            themeSettings={this.props.themeSettings}
                                        />
                                        
                                    </div>
                                    <AddNewButtonLayout
                                    themeSettings={this.props.themeSettings}
                                    >
                                        <a  href="javascript:void(0);" onClick={this.onAddCompanyClick}>
                                            <span className="material-icons">add_circle</span>
                                            <p>Add New</p>
                                        </a>
                                    </AddNewButtonLayout>
                                </div>
                            </div>
                            {
                                !(isEmptyArray(this.state.datalist) && isEmptyVariable(this.state.parent)) &&
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                <th className="text-center" width={"5%"}>No.</th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortShortName)}>
                                                    <div className="sort-header">Short Name
                                                        <span className={(this.state.sort === sortShortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortCompanyName)}>
                                                    <div className="sort-header">Company Name
                                                        <span className={(this.state.sort === sortCompanyName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortCity)}>
                                                    <div className="sort-header">City
                                                        <span className={(this.state.sort === sortCity)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortState)}>
                                                    <div className="sort-header">State
                                                        <span className={(this.state.sort === sortState)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="text-center" width={"5%"}></th>
                                                </tr>
                                            </thead>
                                            
                                            <tbody>
                                            {/* PARENT COMPANY - it must be first row */}
                                            {
                                                !isEmptyVariable(this.state.parent.companyShortName) &&
                                                <tr className="highlight-row" onClick={this.handleCompanyDetailsDialogShow.bind(this,this.state.parent)}>
                                                    <td className="text-center"></td>
                                                    <td>{this.state.parent.companyShortName}</td>
                                                    <td>{this.state.parent.companyName}</td>
                                                    <td>{this.state.parent.city}</td>
                                                    <td>{this.state.parent.state}</td>
                                                    
                                                    <td className="text-center">
                                                        <TableDropDown 
                                                            actionArr={actionArrParent}
                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                            dropDownId = {this.state.parent}
                                                            dropdownWidth = {"150px"}
                                                            themeSettings={this.props.themeSettings}
                                                        />
                                                    </td>
                                                </tr>
                                            }

                                            {
                                                !isEmptyArray(this.state.datalist) && 
                                                this.state.datalist.map((item,idx) => {
                                                    return <tr onClick={this.handleCompanyDetailsDialogShow.bind(this,item)}>
                                                        <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                        <td>{item.companyShortName}</td>
                                                        <td>{item.companyName}</td>
                                                        <td>{item.city}</td>
                                                        <td>{item.state}</td>
                                                        
                                                        <td className="text-center">
                                                            <TableDropDown 
                                                                actionArr={item.companyStatus === "Active"?actionArr:actionArrReactivate}
                                                                onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                dropDownId = {item}
                                                                dropdownWidth = {"150px"}
                                                                themeSettings={this.props.themeSettings}
                                                            />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                
                                    </div>
                                </div>
                            }
                            {
                                this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && 
                                isEmptyVariable(this.state.parent) &&
                                <div class="no-items-layout">
                                    <div class="no-items-card">
                                        <h6>
                                        {
                                            isEmptyVariable(this.state.apiSearchKey)?
                                            Constants.NO_RECORDS_WARNING
                                            :
                                            Constants.EMPTY_SEARCH_WARNING
                                        }
                                        </h6>
                                    </div>
                                </div>
                            }
                            <div className="pagination-layout">
                                <div className="row custom-row">
                                    <div className="col-md-9 custom-col">
                                    {
                                        (this.state.totalCount > this.state.datalist.length) && 
                                        <Pagination 
                                            totalLength ={this.state.totalCount} 
                                            items={this.state.datalist} 
                                            onChangePage={this.onChangePage} 
                                            pageSize={this.state.resultSize}
                                            currentPageNo = {this.state.currentPageNo}
                                            initialPage={this.state.currentPageNo} 
                                            themeSettings={this.props.themeSettings}/>
                                    }
                                    </div>
                                    <div className="col-md-3 custom-col">
                                        <p>{"Total Records: "+this.state.totalCount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <AlertDialog
                        showAlertDialog={this.state.showAlertDialog}
                        handleAlertDialogClose={this.handleAlertDialogClose}
                        type={
                            (this.state.operationType === deleteCompany)
                            ?
                            Constants.ALERT_TYPE_WARNING
                            :
                            Constants.ALERT_TYPE_INFO
                        }
                        alertDialogHeading={
                            (this.state.operationType === deleteCompany)
                            ?
                            "Delete Company"
                            :
                            "Reactivate Company"
                        }
                        alertDialogMessage={this.state.alertDialogMessage}
                        proceedBtnClick={this.deleteActivateClient}
                        proceedBtnLabel={
                            (this.state.operationType === deleteCompany)
                            ?
                            "Delete"
                            :
                            "Reactivate"
                        }
                        themeSettings={this.props.themeSettings}
                    />

                    <AlertDialog 
                        showAlertDialog={this.state.showAlertDialogInfo}
                        handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                        type= {Constants.ALERT_TYPE_ALERT}
                        alertDialogMessage={this.state.alertDialogMessageInfo}
                        proceedBtnClick={this.handleAlertDialogCloseInfo}
                        proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                        themeSettings={this.props.themeSettings}
                    />

                    <AddCompanyDialog 
                        showAddCompanyDialog = {this.state.showAddCompanyDialog}
                        handleAddCompanyDialogClose = {this.handleAddCompanyDialogClose}
                        isEdit={this.state.isEdit}
                        companyType={this.props.companyType}
                        editCompanyItem = {this.state.editCompanyItem}
                        themeSettings={this.props.themeSettings}
                    />
                    <UploadLogoDialog 
                        showUploadLogoDialog = {this.state.showUploadLogoDialog}
                        handleUploadLogoDialogClose = {this.handleUploadLogoDialogClose}
                        themeSettings={this.props.themeSettings}
                        companyLogo={this.state.companyLogo}
                        companyId={this.state.deleteReactivateId}
                    />
                    <CompanyDetailsDialog
                        companyData = {this.state.selectedItem}
                        showCompanyDetailsDialog = {this.state.showCompanyDetailsDialog}
                        handleCompanyDetailsDialogClose = {this.handleCompanyDetailsDialogClose}
                    />

                </section>
            </Router>
        );
    }
}

export default ListCompanies;