import styled from 'styled-components';

export const TopbarWrapper = styled.div`
    position: relative;
    display: flex;
    flex: 1 1 auto;
    height:42px;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    z-index: 49;
    // border-radius: 5px;
    background:rgba(${ props => props.topbarBg.r }, ${ props => props.topbarBg.g }, ${ props => props.topbarBg.b }, ${ props => props.topbarBg.a });
    // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    border-bottom:1px solid rgba(${ props => props.themeColor.r }, ${ props => props.themeColor.g }, ${ props => props.themeColor.b }, ${ props => props.themeColor.a });

    .menu-icon{
        flex-shrink: 0;
        display: none;
    }

    .menu-icon span{
        font-size: 32px;
    }

    .spacer{
        display: flex;
        flex: 1 1 auto;
        height: 1px;
    }

    .info-header{
        display: flex;
        align-items: center;
    }
    
    .contact-suppport-layout{
        margin-right: 13px;
        line-height: 1;
        cursor: pointer;
    }
    .contact-suppport-layout span{
        color:rgba(${ props => props.iconColor.r }, ${ props => props.iconColor.g }, ${ props => props.iconColor.b }, ${ props => props.iconColor.a });
        font-size:27px;
        margin-top:2px;
    }
    .info-layout-wrapper{
        margin-right: 10px;
        border:1px solid transparent;
    }
    .info-layout-wrapper:hover{
        border:1px solid rgba(${ props => props.iconColor.r }, ${ props => props.iconColor.g }, ${ props => props.iconColor.b }, ${ props => props.iconColor.a });
        border-radius: 100%;
    }
    .topbar-info-layout{
        margin: 2px;
        cursor: pointer;
        border-radius: 100%;
        background:rgba(${ props => props.iconColor.r }, ${ props => props.iconColor.g }, ${ props => props.iconColor.b }, ${ props => props.iconColor.a });
        display: flex;
        align-items: center;
        padding: 4px;
    }
    .topbar-info-layout img{
        height:16px;
        width:16px;
        padding: 0px;
    }
    .header-notification-wrapper{
        position: relative;
    }
    .header-notification{
        width:30px;
        height:30px;
        text-align: center;
        color:rgba(${ props => props.badgeTextColor.r }, ${ props => props.badgeTextColor.g }, ${ props => props.badgeTextColor.b }, ${ props => props.badgeTextColor.a });
    }
    .header-notification:hover{
        border-radius: 100%;
        background:rgba(${ props => props.selectedIconBg.r }, ${ props => props.selectedIconBg.g }, ${ props => props.selectedIconBg.b }, ${ props => props.selectedIconBg.a });
    }
    .header-notification span{
        font-size: 26px;
        line-height: 1.2;
        padding:0px;
        color:rgba(${ props => props.iconColor.r }, ${ props => props.iconColor.g }, ${ props => props.iconColor.b }, ${ props => props.iconColor.a });
    }
    .notification-all-col a{
        padding: 10px;
        display:block;
        font-size: 14px;
        color:rgba(${ props => props.themeColor.r }, ${ props => props.themeColor.g }, ${ props => props.themeColor.b }, ${ props => props.themeColor.a });
        font-weight: 600;
    }
    .notification-all-col a:hover{
        color:rgba(${ props => props.themeHoverTextColor.r }, ${ props => props.themeHoverTextColor.g }, ${ props => props.themeHoverTextColor.b }, ${ props => props.themeHoverTextColor.a });
        background: rgba(${ props => props.themeColor.r }, ${ props => props.themeColor.g }, ${ props => props.themeColor.b }, ${ props => props.themeColor.a });
    }
    .noti-badge{
        position: absolute;
        background: rgba(${ props => props.badgeBg.r }, ${ props => props.badgeBg.g }, ${ props => props.badgeBg.b }, ${ props => props.badgeBg.a });
        border-radius: 100%;
        width: 15px;
        line-height: 11px;
        height: 15px;
        right: 0px;
        top:0px;
        text-align: center;
    }
    .noti-badge span{
        font-size: 8px !important;
        display: inline-block;
        color:rgba(${ props => props.badgeTextColor.r }, ${ props => props.badgeTextColor.g }, ${ props => props.badgeTextColor.b }, ${ props => props.badgeTextColor.a });
        font-weight:600;
        padding: 0px !important;
    }
    .vertical-line{
        background: rgba(${ props => props.themeColor.r }, ${ props => props.themeColor.g }, ${ props => props.themeColor.b }, ${ props => props.themeColor.a });
        width: 1px;
        height: 30px;
        margin-left: 15px;
    }
    .ams-logo img{
        height: 18px;
        margin-left: 20px;
    }

    .info-header-wrapper{
        display: flex;
        align-items: center;
    }
`;