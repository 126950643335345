import styled from 'styled-components';

export const ViewReportWrapper = styled.div`
    .flex-center-layout {
        display:flex;
        align-items:center;
    }

    .justify-content {
        justify-content:space-between;
    }

    .justify-content-center {
        justify-content:center;
    }
`;

export const FirstPageLayout = styled.div`
    width:21cm;
    margin:auto;
    background:#f5f5f5;
    margin-bottom: 15px;

    p{
        margin-bottom: 0px;
        text-align: center;
    }
    
    .client-layout{
        max-width: 300px;
        margin:auto;
        padding-top:100px;
    }

    .company-layout{
        max-width: 300px;
        margin:auto;
        margin-top:200px;
        text-align:center;
    }

    .company-layout img{
        width:80px;
        height:auto;
        object-fit:cover;
    }
`;

export const SecondPageLayout = styled.div`
    width:21cm;
    margin:auto;
    background:#f5f5f5;
    margin-bottom: 15px;
    
    h6{
        margin-bottom: 0px;
        text-align: center;
        padding-top:100px;
        margin-bottom: 15px;
        text-decoration:underline;
    }

    table{
        margin-bottom: 0px;
        width: 100%;
    }
    table, th, td {
        border: 0.6px solid black;
      }

`;
export const ThirdPageLayout = styled.div`
    width:21cm;
    margin:auto;
    background:#f5f5f5;
    margin-bottom: 15px;
    
    h6{
        margin-bottom: 0px;
        text-align: center;
        padding-top:100px;
        margin-bottom: 15px;
        text-decoration:underline;
    }

    p{
        margin-bottom: 0px;
    }

    ul{
        margin-bottom:10px;
    }
`;

export const FourthPageLayout = styled.div`
    width:21cm;
    margin:auto;
    background:#f5f5f5;
    margin-bottom: 15px;
    
    h6{
        margin-bottom: 0px;
        text-align: center;
        padding-top:100px;
        margin-bottom: 15px;
        text-decoration:underline;
    }

    p{
        margin-bottom: 0px;
    }

    table{
        margin-bottom: 0px;
        width: 100%;
    }
    table, th, td {
        border: 0.6px solid black;
      }
`;


export const FifthPageLayout = styled.div`
    width:29.7cm;
    margin:auto;
    background:#f5f5f5;
    margin-bottom: 15px;
    
    h6{
        margin-bottom: 0px;
        text-align: center;
        padding-top:100px;
        margin-bottom: 15px;
        text-decoration:underline;
    }

    .observation-wrapper{
        margin-bottom:20px;
    }

    p{
        margin-bottom: 0px;
    }

    table{
        margin-bottom: 0px;
        width: 100%;
    }
    table, th, td {
        border: 0.6px solid black;
      }
      .responses{
        display: flex;
        justify-content: space-between;
      }
`;
