import React, { Component } from 'react';
import {isEmptyVariable,isEmptyArray} from '../Global/commonFunctions';
import {Dropdown} from 'react-bootstrap';

class RegularDropDown extends Component {
    constructor(props) {
        super(props);
        this.state= {
            refresh:false
        }

        this.dropdownRef = React.createRef();
    }

    componentDidMount(){
        if(this.props.isFocusRequired && !this.state.refresh){
            this.setState({
                refresh:!this.state.refresh
            })
        }
    }

    componentDidUpdate(){
        //just rerendering so that first dropdown gets the focus
        if(this.props.isFocusRequired && this.state.refresh){
            this.setState({
                refresh:!this.state.refresh
            },()=>{
                this.dropdownRef.current.focus();
            })
        }
    }

    dropDownItemClick = (item,e) => {
        e.stopPropagation();
        if(isEmptyVariable(this.props.udf)){
            this.props.onDropDownItemClick(item);
        }else{
            this.props.onDropDownItemClick(item,this.props.udf);
        }
        
    }

    render(){
        return(
            <div className="regular-dropdown-layout reports-dd">
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" ref={this.dropdownRef} disabled={this.props.disabled?true:false}>
                    {
                        this.props.placeholder === this.props.defaultPlaceholderDropDown &&
                        <p className="placeholder-text">{this.props.placeholder}</p>
                    }
                    {
                        this.props.placeholder !== this.props.defaultPlaceholderDropDown &&
                        <p className="placeholder-text">{this.props.placeholder}</p>
                    }
                    <span className="material-icons">expand_more</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                {
                    !isEmptyVariable(this.props.dropdownArr) &&
                    this.props.dropdownArr.map((item)=>{
                        return <Dropdown.Item onClick={this.dropDownItemClick.bind(this,item)} href="javascript:void(0)">
                            <p className="dropdown-text">{item[this.props.labelParam]}</p>
                        </Dropdown.Item>
                    })
                }
                {
                    isEmptyArray(this.props.dropdownArr) &&
                    <Dropdown.Item href="javascript:void(0)">
                        <p style={{textAlign:"center"}} className="dropdown-text">No Records</p>
                    </Dropdown.Item>
                }
                </Dropdown.Menu>
            </Dropdown>
            </div>
        )
    }
}

export default RegularDropDown;