import React, { Component } from 'react';
import {isEmptyVariable} from '../Global/commonFunctions';
import {Dropdown} from 'react-bootstrap';
import {AddNewButtonLayout, FilterDropDownLayout} from '../Global/globalStyles.style';

class FilterDropDown extends Component {
    constructor(props) {
        super(props);
    }

    dropDownItemClick = (item,idx,e) => {
        e.stopPropagation();
        this.props.onDropDownItemClick(item,idx);
    }

    displayFilter = () => {
        if (this.props.placeholder && this.props.icon) {
            return (
                <AddNewButtonLayout themeSettings={this.props.themeSettings}>
                    <a href="javascript:void(0);">
                        <span className="material-icons mr-1">{this.props.icon}</span>
                        <p>{this.props.placeholder}</p>
                    </a>
                </AddNewButtonLayout>
            );
        } else if (this.props.icon) {
            return (
                <span className="tool" data-title="Download Report">
                <AddNewButtonLayout themeSettings={this.props.themeSettings}>
                    <a href="javascript:void(0);">
                        <span className="material-icons mr-1">{this.props.icon}</span>
                    </a>
                </AddNewButtonLayout>
                </span>
            );
        } else if (this.props.placeholder) {
            return (
                <>
                    <p>{this.props.placeholder}</p>
                    <span className="material-icons">expand_more</span>
                </>
            );
        }
    };

    render(){
        let marginLeft = 10;
        if(!isEmptyVariable(this.props.mleft)){
            marginLeft = this.props.mleft;
        }
        return(
            <FilterDropDownLayout
            themeSettings={this.props.themeSettings}
            dropdownWidth = {this.props.dropdownWidth}
            >
            <div className={this.props.addBorder?"filter-dropdown-layout add-border":
            this.props.icon?"filter-dropdown-layout filter-layout":"filter-dropdown-layout"} 
            style={{marginLeft:marginLeft}}>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" disabled={this.props.disabled}>
                        {this.displayFilter()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    {
                        !isEmptyVariable(this.props.dropdownArr) &&
                        this.props.dropdownArr.map((item,idx)=>{
                            return <Dropdown.Item onClick={this.dropDownItemClick.bind(this,item,idx)} 
                                href="javascript:void(0)"
                                className={item.isSelected?"filter-dropdown-item active-dd-item":"filter-dropdown-item"}>
                                    <span class="material-icons">check_circle</span>
                                    <p>{isEmptyVariable(this.props.name)?item.label:item[this.props.name]}</p>
                            </Dropdown.Item>
                        })
                    }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            </FilterDropDownLayout>
        )
    }
}

export default FilterDropDown;