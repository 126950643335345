import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import { matchPath,withRouter } from 'react-router-dom';
import {isEmptyVariable,getThemeSettingsVariables, isEmptyArray} from '../../../common/Global/commonFunctions';
import {TopTabsLayout} from '../../../common/Global/globalStyles.style';
const allPrivilege = "ALL";
const viewPrivilege = "VIEW";

let isScheduledAssignments = '';
let isPlannedAssignments = '';
let isFollowUpAssignments = '';
let themeColor = ``;

class MasterTopMenu extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
        isScheduledAssignments = this.checkActivePath("/scheduledassignments");
        isPlannedAssignments = this.checkActivePath("/assignments");
        isFollowUpAssignments = this.checkActivePath("/followups");
    }

    checkActivePath = (path) =>{
        const themeSettings = getThemeSettingsVariables();
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
       return matchPath(this.props.history.location.pathname, { path });

    }

    render(){
        let width = 12.5;
        if(!isEmptyVariable(this.props.masterMenus)){
            width = 12.5*Object.keys(this.props.masterMenus).length
        }
        let departmentSetting = [];
        const departmentIds = !isEmptyArray(this.props.departments) && this.props.departments.map(item => item.departmentId);
        const filteredSettings =  !isEmptyArray(this.props.allSettings) && this.props.allSettings.filter(item => departmentIds.includes(item.departmentId));
        departmentSetting = !isEmptyArray(filteredSettings) && 
        filteredSettings.filter(item=> item.settingColumn.includes("directScheduled") && item.settingColumnEnabled === "N");

        return(
            <TopTabsLayout themeSettings={this.props.themeSettings}>
            
                <div className="question-tabs"  style={{marginRight:0}}>
                    <ul style={{width:width+"%"}}>
                        {
                            !isEmptyVariable(this.props.masterMenus) &&
                            Object.keys(this.props.masterMenus).map(key => {
                                return <React.Fragment key={key}>
                                    {
                                        (
                                            (key === Constants.SCREEN_SCHEDULED_ASSIGNMENT_ALL &&
                                            (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege)) ||
                                            (key === Constants.SCREEN_SCHEDULED_ASSIGNMENT_MANAGERS &&
                                            (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege))
                                        ) &&
                                        <li 
                                            className={isScheduledAssignments ? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                            <a href={Constants.URL_SCHEDULEDASSIGNMENTS} style={{color: !isScheduledAssignments ? themeColor : '', fontWeight: !isScheduledAssignments ? "bold" : "100px"}}>Scheduled</a>
                                        </li>
                                    }
                                    {
                                        (
                                            (key === Constants.SCREEN_PLAN_ASSIGNMENT_ALL &&
                                            (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege)) ||
                                            (key === Constants.SCREEN_PLAN_ASSIGNMENT_MANAGERS &&
                                            (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege))
                                            
                                        ) && departmentSetting.length > 0 &&
                                        <li 
                                            className={isPlannedAssignments ? "questiontab-active" : ""}>
                                            <a href={Constants.URL_PLANNEDASSIGNMENTS} style={{color: !isPlannedAssignments ? themeColor : '', fontWeight: !isPlannedAssignments ? "bold" : "100px"}}>Planned</a>
                                        </li>
                                    }
                                    {
                                        (
                                            (key === Constants.SCREEN_FOLLOW_UP_ASSIGNMENT &&
                                            (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] === viewPrivilege))
                                            
                                        ) &&
                                        <li 
                                            className={isFollowUpAssignments ? "questiontab-active" : ""}>
                                            <a href={Constants.URL_FOLLOWUP_ASSIGNMENTS} style={{color: !isFollowUpAssignments ? themeColor : '', fontWeight: !isFollowUpAssignments ? "bold" : "100px"}}>Follow Up</a>
                                        </li>
                                    }
                                </React.Fragment>
                            })
                        }
                    </ul>
                </div>

                <div style={{opacity:this.props.hideInfo?"0":"1"}} className="infocard-showhide-col">
                    {/* <div className="infocard-icon-col">
                        <img src="/assets/images/topbar-info.svg" />
                    </div> */}
                </div>
            </TopTabsLayout>
        )
    }
}

export default withRouter(MasterTopMenu);