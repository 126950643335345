import React, { Component } from "react";
import * as Constants from "../../common/Global/constants";
import { isEmptyVariable, isEmptyArray, getLocalStorageVariables, getThemeSettingsVariables, } from "../../common/Global/commonFunctions.js";
import { DialogSaveButton, TableCheckBox, SearchSpan } from "../../common/Global/globalStyles.style";
import { Modal } from "react-bootstrap";
import Pagination from '../../common/Global/pagination';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import { toast } from "react-toastify";

let themeSettings = getThemeSettingsVariables();
let userDetails = getLocalStorageVariables();

class approvedUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            datalist: [],
            selectedUserIds: [],
            currentPageNo: 1,
            searchKey:"",
            sort:"u.firstName",
            sortDir:"asc",
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            resultSizePlaceholder:Constants.RESULT_SIZE,
            isComponentDidMountFlag:false,
            isSelctAllChecked:false,

        };
    }
    componentDidMount(){
        themeSettings = getThemeSettingsVariables();
        userDetails = getLocalStorageVariables();
        this.getInitData();
    }

    handleSelection = (userId, e) => {
        e.stopPropagation();
        let isChecked = e.target.checked;
        let newDataList = this.state.datalist;

        newDataList.map((item)=>{
            if(item.userId == userId) {
                item.isChecked = isChecked;
            }
        })

        let selectedUserIds = this.state.selectedUserIds;

        if(isChecked){
            selectedUserIds.push(userId)
        } else {
            selectedUserIds = selectedUserIds.filter(item=>item != userId);
        }
        
        this.setState({
            dataList: newDataList,
            selectedUserIds: selectedUserIds
        })
    }

    approvalOnClick = () => {
        let selectedUserIds = this.state.selectedUserIds
        // let userFormErrTemp = "";
       
        if(isEmptyArray(selectedUserIds)){
            toast.warning("Select atleast one user");
            // userFormErrTemp =  "Select atleast one user";
            // this.setState({
            //     userFormErr:userFormErrTemp,
            // })
            
            // setTimeout(function(){
            //     this.setState({
            //         userFormErr:"",
            //     });
            // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        else{

            fetch(Constants.DepartmentApprovedUser,{
                method: "post",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    approvedUsers: JSON.stringify(this.state.selectedUserIds)
                })
            })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                        localStorage.clear();
                        window.location = "/";
                }else if (data.responseCode === Constants.CODE_SUCCESS) {
                        this.setState({
                            approvedUsers: selectedUserIds 
                    },()=>{
                        this.getApprovalList("Y");
                    })
                }

            })
        }
    }

    handleStopPropagation = (e) => {
        e.stopPropagation();
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getApprovalList();
        })
    }
    
    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getApprovalList();
            })
        }
    }
    
    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getApprovalList();
        })
    }

    getInitData = () => {
        userDetails = getLocalStorageVariables();

        fetch(Constants.GetApprovalUsers, {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(
            {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchKey,
                resultsize:this.state.resultSize,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
            })
        })
        .then((response)=> { return response.json(); })
        .then((data)=> {
            let newDataList = []
            let totalCountTemp = 0;

            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                newDataList = data.data.approvalUserList;
                newDataList.map((data) =>{ data.isChecked = false; })
                totalCountTemp = data.data.totalCount;
            }else{
                totalCountTemp = 0;
            }
            this.setState({
                isComponentDidMountFlag: true,
                totalCount:totalCountTemp,
                datalist: newDataList,

            })
        })
    }


    getApprovalList = (closeDialog) => {
        fetch(Constants.GetApprovalUsers, {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                search:this.state.searchKey,
                resultsize:this.state.resultSize,
                pageNo:this.state.currentPageNo,
            })
        })
        .then(response=> { return response.json(); })
        .then(data=> {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    datalist:data.data.approvalUserList,
                    totalCount:data.data.totalCount,
                },()=>{
                    if(closeDialog=="Y"){
                        this.props.onCancleButtonClick(this.state.datalist.length);
                    }
                });
            }
        })
    }

    onChangePage = (page) => {
        if(page !== this.state.currentPageNo){
            fetch(Constants.GetApprovalUsers,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchKey,
                    resultsize:this.state.resultSize,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        datalist:data.data.approvalUserList,
                        totalCount:data.data.totalCount,
                        currentPageNo:page,
                    });
                }
            });
        }
    }

    selectUnselctAll = (e) => {
        // e.target.checked
        let newDataList = this.state.datalist;
        let selectedUserIds=[];
        newDataList.map((item)=>{
            item.isChecked = e.target.checked?true:false
            if(e.target.checked){
                selectedUserIds.push(item.userId)
            }
        })

        this.setState({
            datalist:newDataList,
            isSelctAllChecked:e.target.checked,
            selectedUserIds:selectedUserIds
        })
    }

    render() {
        return (
            <Modal className="assignment-select-dialog custom-dialog" show={this.props.selectedUsersApprovalFlag} backdrop="static" > 
            <Modal.Header>
                    <h5>User Approval</h5>
                    <button
                        onClick={this.props.onCancleButtonClick}
                        type="button"
                        data-dismiss="modal"
                    >
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                {   this.state.isComponentDidMountFlag &&
                        <div className="modal-body">
                            <div className="body-wrapper">
                                <div className="search-addnew-bg" style={{marginTop:0}}>
                                    <div className="search-addnew">
                                        <div className="search-filter-layout" style={{position:"relative"}}>
                                            <FilterDropDown
                                                placeholder={this.state.resultSizePlaceholder}
                                                dropdownArr={this.state.resultSizeArr}
                                                onDropDownItemClick={this.resultSizeDropdownClick}
                                                addBorder={true}
                                                themeSettings={themeSettings}
                                                mleft={0}
                                            />
                                            <div className="search-col m-l-10" style={{border:"1px solid #e5e5e5"}}>
                                                <input 
                                                    name="searchKey" 
                                                    type="text"
                                                    onChange={this.handleChangeSearch}
                                                    placeholder="Search" 
                                                    onKeyPress={this.onEnterBtnPress}
                                                    value={this.state.searchKey}
                                                />
                                                <SearchSpan 
                                                themeSettings={themeSettings}
                                                onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                           </div>
                                       </div>
                                    </div>
                                </div>
                        {
                            !isEmptyArray(this.state.datalist) &&
                            <div className="common-tab-bg">
                                <div
                                    className="common-table"
                                    style={{ border: "1px solid #e5e5e5" }}
                                >
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <TableCheckBox onClick={this.handleStopPropagation} themeSettings={themeSettings} >
                                                        <input 
                                                            type="checkbox"
                                                            name={"all"}
                                                            id={"all"}
                                                            onChange={this.selectUnselctAll}
                                                            checked={this.state.isSelctAllChecked?true:false}
                                                        />
                                                        <label for={"all"} style={{marginLeft:2}}></label>
                                                    </TableCheckBox>
                                                </th>
                                                <th>No</th>
                                                <th>Name</th>
                                                <th>Designation</th>
                                                <th>User Role</th>
                                                <th>Department</th>
                                                <th>Create By</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.values(this.state.datalist).map((item, idx) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <TableCheckBox onClick={this.handleStopPropagation} themeSettings={themeSettings} >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={idx}
                                                                            id={item.userId}
                                                                            onChange={this.handleSelection.bind(this, item.userId)}
                                                                            checked={item.isChecked?true:false}
                                                                        />
                                                                        <label for={item.userId} 
                                                                        style={{marginLeft:2}}></label>
                                                                </TableCheckBox>
                                                            </td>
                                                            <td>{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                            <td>{item.userName}</td>
                                                            <td>{isEmptyVariable(item.designation)?"":item.designation}</td>
                                                            <td>{item.displayRoleName}</td>
                                                            <td>{item.department}</td>
                                                            <td>{item.createBy}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {
                            isEmptyArray(this.state.datalist) &&
                            <div className="no-items-layout">
                                <div className="no-items-card">
                                    <h6>
                                    {
                                        isEmptyVariable(this.state.searchKey)?
                                        Constants.NO_RECORDS_WARNING
                                        :
                                        Constants.EMPTY_SEARCH_WARNING
                                    }
                                    </h6>
                                </div>
                            </div>
                        }
                        <div className="pagination-layout">
                            <div className="row custom-row">
                                <div className="col-md-9 custom-col">
                                    {(this.state.totalCount > this.state.datalist.length) &&
                                        <Pagination 
                                            totalLength ={this.state.totalCount} 
                                            items={this.state.datalist} 
                                            onChangePage={this.onChangePage} 
                                            pageSize={this.state.resultSize}
                                            currentPageNo = {this.state.currentPageNo}
                                            initialPage={this.state.currentPageNo} 
                                            themeSettings = {themeSettings}
                                        />
                                    }
                                    
                                </div>
                                <div className="col-md-3 custom-col">
                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                </div>
                            </div>
                        </div>
                        {/* {
                            !isEmptyVariable(this.state.userFormErr) &&
                            <span className="cm-error">{this.state.userFormErr}</span>
                        }             */}
                        <div className="modal-close-save m-t-15">
                            <div className="close-save-col">
                                <button
                                    onClick={this.props.onCancleButtonClick}
                                    type="button"
                                    className="modal-close m-b-0"
                                >
                                    Cancel
                                </button>

                                {
                                    <DialogSaveButton
                                        themeSettings={themeSettings}
                                        type="button"
                                        className="modal-save m-b-0"
                                    >
                                        Decline
                                    </DialogSaveButton>
                                }
                                    <DialogSaveButton
                                        themeSettings={themeSettings}
                                        onClick={this.approvalOnClick}
                                        type="button"
                                        className="modal-save m-b-0"
                                    >
                                        Approved
                                    </DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </div>}
            </Modal>
        );
    }
}

export default approvedUser;
