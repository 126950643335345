import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,AlertInfo} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import MasterMenuTabs from './topmenu';
import ProcessTreeDialog from './processTree'
import FunctionTreeDialog from './functionTree'
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import Pagination from '../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable,GetOrderedMasterTabMenuTask,
    getThemeSettingsVariables,isEmptyArray} from '../../common/Global/commonFunctions';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import SelecAssignmentTypeDialog from './selectAssignmentTypeDialog';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const activeStatus="";
const underReviewStatus="Under Review";
const departmentFilterDropdownDefaultValue = "All";

class ProcessMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE_GRID,
            totalCount:0,
            datalist:[],

            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE_GRID,
            departmentArr:[],
            deptOnly:"N",
            isProcessView:true,

            status:activeStatus,
            statusArr:[
                {
                    label:activeStatus,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    isSelected:false
                },
            ],
            resultSizeArr:Constants.RESULT_SIZE_GRID_DD_ARR,

            processName:"",
            selectedId:"",
            rootId:"",
            showProcessTreeDialog:false,

            //Assignment Type
            showSelectAssigmentTypeDialog:false,
            selectedAssigmentTypeId:"",
            functionInfoAlertDialog:true
        }
    }

    componentDidMount(){
        this.getInitData(false);
    }

    handleProcessTreeDialogClose = () => {
        this.setState({
            showProcessTreeDialog:false,
            rootId:""
        })
    }

    handleFunctionTreeDialogClose = () => {
        this.setState({
            showFunctionsTreeDialog:false,
            rootId:""
        })
    }

    switchView = () => {
        this.getInitData(true);
        this.setState({
            functionInfoAlertDialog:true
        })
    }

    showTreeDialog = (item) =>{
        if(this.state.isProcessView){
            this.setState({
                showProcessTreeDialog:true,
                rootId:item.processId,
                rootNode:item
            })
        }else{
            this.setState({
                showFunctionsTreeDialog:true,
                rootId:item.functionId,
                rootNode:item
            })
        }
        
    }

    handleCheck = (e) =>{
        let isChecked = e.target.checked;
        let name = e.target.name;

        if(isChecked){
            this.setState({
                deptOnly:"Y"
            },()=>{
                this.getMasterList()
            })
        }else{
            this.setState({
                deptOnly:"N"
            },()=>{
                this.getMasterList()
            })
        }

    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getMasterList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getMasterList();
            })
        }
    }

    addTaskOnClick = (processObj) => {
        // this.props.history.push(Constants.URL_ADDTASKS+"/"+processObj.processId);
        // this.props.history.push(Constants.URL_ADDTASK_WITH_TEMPLATES+"/"+processObj.processId+"-"+7);

    }

    viewTaskOnClick = (processObj) => {
        this.props.history.push(Constants.URL_VIEWTASKS+"/"+processObj.processId);
    }

    viewTaskFunctionOnClick = (functionObj) => {
        this.props.history.push(Constants.URL_VIEWFUNCTIONTASKS+"/"+functionObj.functionId);
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getMasterList();
        })
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let id = "";
        let filterDeptArr = this.state.filterDeptArr;

        for(const [i,value] of filterDeptArr.entries()){
            if(i === selectedIndex){
                filterDeptArr[i].isSelected = true;
                placeholder = value.name;
                id = value.departmentId;
            }else{
                filterDeptArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo: 1,
            selectedFilterDepartmentName:placeholder,
            selectedFilterDepartmentId:id
        },() => {
            this.getMasterList();
        })
    }

    getInitData = (isSwitchView) => {
        if(isSwitchView){
            this.setState({
                showLoader:true,
            },()=>{
                if(!this.state.isProcessView){
                    this.getProcessInitData(isSwitchView);
                }else{
                    this.getFunctionInitData(isSwitchView);
                }
            });
        }else{
            this.setState({
                showLoader:true,
            },()=>{
                if(this.state.isProcessView){
                    this.getProcessInitData(isSwitchView);
                }else{
                    this.getFunctionInitData(isSwitchView);
                }
            });
        }
        
    }

    //AssignmentType
    handleSelectAssignmentTypeDialogClose = () => {
        this.setState({
            showSelectAssigmentTypeDialog:false,
            selectedProcessId:""
        })
    }
    handleSelectAssignmentTypeDialogSaveClose = (selectedAssigmentTypeId) => {
        // this.setState({
        //     showSelectAssigmentTypeDialog:false,
        //     selectedAssigmentTypeId:selectedAssigmentTypeId
        // });
        this.props.history.push(Constants.URL_ADDTASK_WITH_TEMPLATES+"/"+this.state.selectedProcessId+"-"+selectedAssigmentTypeId);
    }
    handleSelectAssignmentTypeDialogShow = (processObj) => {
        this.setState({
            showSelectAssigmentTypeDialog:true,
            selectedProcessId:processObj.processId

        })
    }

    /*******************API CALLS*******************/
    getProcessInitData = (isSwitchView) => {
        Promise.all([
            fetch(Constants.GetProcessCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetRootProcesses,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    departmentOnly:this.state.deptOnly,
                    activeFlag:"Y"//get values from active tables
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,generalRes,menuRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,generalRes,menuRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;
            let menusTemp = {};
            let deptTemp = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    deptTemp = masterRes.data.departments;
            }else{
                deptTemp = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenuTask(menusTemp)

            //Filter Dept array
            let filterDeptArr = [];
            let selectedFilterDepartmentId = "-100";
            let selectedFilterDepartmentName = "All";
            if(!isEmptyArray(deptTemp) && deptTemp.length===1){

            }else{
                let obj = {
                    departmentId:"-100",
                    name:departmentFilterDropdownDefaultValue,
                    isSelected:true
                }
                filterDeptArr = [...deptTemp];
                filterDeptArr.splice(0,0,obj)
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:datalistTemp,
                totalCount:totalCountTemp,
                menus:menusTemp2,
                departmentArr:deptTemp,
                isProcessView:isSwitchView?!this.state.isProcessView:this.state.isProcessView,

                filterDeptArr:filterDeptArr,
                selectedFilterDepartmentId:selectedFilterDepartmentId,
                selectedFilterDepartmentName:selectedFilterDepartmentName,
            })
        })
    }

    getFunctionInitData = (isSwitchView) => {
        Promise.all([
            fetch(Constants.GetFunctionsCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetRootFunctions,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    departmentOnly:this.state.deptOnly,
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,generalRes,menuRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,generalRes,menuRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;
            let menusTemp = {};
            let deptTemp = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    deptTemp = masterRes.data.departments;
            }else{
                deptTemp = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenuTask(menusTemp)
            
            //Filter Dept array
            let filterDeptArr = [];
            let selectedFilterDepartmentId = "-100";
            let selectedFilterDepartmentName = "All";
            if(!isEmptyArray(deptTemp) && deptTemp.length===1){

            }else{
                let obj = {
                    departmentId:"-100",
                    name:departmentFilterDropdownDefaultValue,
                    isSelected:true
                }
                filterDeptArr = [...deptTemp];
                filterDeptArr.splice(0,0,obj)
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:datalistTemp,
                totalCount:totalCountTemp,
                menus:menusTemp2,
                departmentArr:deptTemp,
                isProcessView:isSwitchView?!this.state.isProcessView:this.state.isProcessView,

                filterDeptArr:filterDeptArr,
                selectedFilterDepartmentId:selectedFilterDepartmentId,
                selectedFilterDepartmentName:selectedFilterDepartmentName,
            })
        })
    }
    
    getMasterList = () => {
        this.setState({
            showLoader:true,
        });

        if(this.state.isProcessView){
            this.getProcessMasterList();
        }else{
            this.getFunctionMasterList();
        }
    }

    getProcessMasterList = () => {
        this.setState({
            showLoader:true,
        });

        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:this.state.status,
            activeFlag:"Y"//get values from active tables
        }

        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }

        fetch(Constants.GetRootProcesses,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    getFunctionMasterList = () => {
        this.setState({
            showLoader:true,
        });

        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:this.state.status,
        }

        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }

        fetch(Constants.GetRootFunctions,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            if(this.state.isProcessView){
                let postParam = {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    activeFlag:"Y"//get values from active tables
                }
        
                if(this.state.filterDeptArr.length > 1 &&
                    this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
                    postParam.departmentId = this.state.selectedFilterDepartmentId;
                }

                fetch(Constants.GetRootProcesses,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams(postParam)
                })
                .then(response => { return response.json(); } )
                .then(data =>
                {
                    if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                        localStorage.clear();
                        window.location="/";
                    }else if(data.responseCode === Constants.CODE_SUCCESS){
                        this.setState({
                            showLoader:false,
                            datalist:data.data.result,
                            totalCount:data.data.count,
                            apiSearchKey:this.state.searchkey,
                            currentPageNo:page,
                        });
                    }else{
                        this.setState({
                            datalist:[],
                            showLoader:false,
                            apiSearchKey:this.state.searchkey,
                        });
                    }
                });
            }else{
                let postParam = {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                }
        
                if(this.state.filterDeptArr.length > 1 &&
                    this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
                    postParam.departmentId = this.state.selectedFilterDepartmentId;
                }

                fetch(Constants.GetRootFunctions,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams(postParam)
                })
                .then(response => { return response.json(); } )
                .then(data =>
                {
                    if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                        localStorage.clear();
                        window.location="/";
                    }else if(data.responseCode === Constants.CODE_SUCCESS){
                        this.setState({
                            showLoader:false,
                            datalist:data.data.result,
                            totalCount:data.data.count,
                            apiSearchKey:this.state.searchkey,
                            currentPageNo:page,
                        });
                    }else{
                        this.setState({
                            datalist:[],
                            showLoader:false,
                            apiSearchKey:this.state.searchkey,
                        });
                    }
                });
            }
        }
    }

    handleCloseFunctionInfo = () =>{
        this.setState({
            functionInfoAlertDialog:false,
        });
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    this.state.showLoader &&
                                    <div className="loading-layout"
                                    style={{background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`}}>
                                        <div className="loading-text-layout">
                                            <p
                                            style={{color:`rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`}}
                                            >Loading....</p>
                                        </div>
                                    </div>
                                }
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                        themeSettings={themeSettings}
                                                        onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                                    </div>
                                                    
                                                    {
                                                        this.state.filterDeptArr.length > 0 &&
                                                        <FilterDropDown
                                                            placeholder={this.state.selectedFilterDepartmentName}
                                                            dropdownArr={this.state.filterDeptArr}
                                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                                            name={"name"}
                                                            dropdownWidth={"120px"}
                                                            themeSettings={themeSettings}
                                                        />
                                                    }
                                                </div>

                                                <div className="flex-center-layout">
                                                    <AddNewButtonLayout themeSettings={themeSettings}>
                                                        <a onClick={this.switchView} href="javascript:void(0);">
                                                            <span className="material-icons">swap_horiz</span>
                                                            <p>{this.state.isProcessView?"Functions":"Processes"}</p>
                                                        </a>
                                                    </AddNewButtonLayout>
                                                    <AddNewButtonLayout themeSettings={themeSettings} style={{marginLeft:15}}>
                                                        <a href={Constants.URL_DRAFTTASKS}>
                                                            <span className="material-icons">source</span>
                                                            <p>Drafts</p>
                                                        </a>
                                                    </AddNewButtonLayout>
                                                    <AddNewButtonLayout themeSettings={themeSettings} style={{marginLeft:15}}>
                                                        <a href={Constants.URL_UNDERREVIEWTASKS}>
                                                            <span className="material-icons">rate_review</span>
                                                            <p>Under Review</p>
                                                        </a>
                                                    </AddNewButtonLayout>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='col'>
                                           <center> 
                                                <p style={{padding:0,marginBottom:3,fontSize:14,fontWeight:'bold'}}>
                                                    {this.state.isProcessView?"Processes":"Functions"}
                                                </p>
                                            </center>
                                            <hr style={{marginTop:0,background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`}}/>
                                        </div>
                                        {
                                           !this.state.isProcessView && this.state.functionInfoAlertDialog &&
                                            <AlertInfo themeSettings={themeSettings}>
                                                <span className="material-icons info-icon" >info</span>
                                                <p className="flex-center-layout just-content">
                                                    <p>
                                                        <strong>Note:</strong> To <strong>Create and Edit Task</strong>, go through <strong>Process</strong> and not through Functions.
                                                    </p>
                                                    <span className="material-icons close-icon" onClick={this.handleCloseFunctionInfo}>close</span>
                                                </p>
                                            </AlertInfo>
                                        }
                                        <div className="row custom-row">
                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            this.state.datalist.map((item,idx) => {
                                                let marTop = 10;
                                                if(idx === 0 || idx === 1 || idx === 2){
                                                    marTop=0;
                                                }

                                                return <div className="col-md-4 custom-col-5">
                                                    <div onClick={this.showTreeDialog.bind(this,item)}
                                                    className="tree-list-card-layout c-pointer" style={{marginTop:marTop}}>
                                                        <div className="tree-card-text-layout">
                                                            <p className="font-bold font-bold-resize">{this.state.isProcessView?item.processName:item.functionName}</p>
                                                            {!isEmptyArray(this.state.departmentArr) && this.state.departmentArr.length > 1 && 
                                                                <span>{item.deptName}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                        </div>
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings={themeSettings}/>
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <ProcessTreeDialog
                            showProcessTreeDialog={this.state.showProcessTreeDialog}
                            handleProcessTreeDialogClose={this.handleProcessTreeDialogClose}
                            rootProcessId={this.state.rootId}
                            rootNode={this.state.rootNode}
                            addTaskOnClick={this.addTaskOnClick}
                            viewTaskOnClick={this.viewTaskOnClick}
                            themeSettings={themeSettings}
                            masterMenus={this.state.menus}
                            handleSelectAssignmentTypeDialogShow={this.handleSelectAssignmentTypeDialogShow}
                        />

                        <FunctionTreeDialog
                            showFunctionsTreeDialog={this.state.showFunctionsTreeDialog}
                            handleFunctionTreeDialogClose={this.handleFunctionTreeDialogClose}
                            rootFunctionId={this.state.rootId}
                            rootNode={this.state.rootNode}
                            viewTaskFunctionOnClick={this.viewTaskFunctionOnClick}
                            themeSettings={themeSettings}
                        />

                        <SelecAssignmentTypeDialog
                            showOtherMasterDialog={this.state.showSelectAssigmentTypeDialog}
                            handleSelectOtherMasterDialogClose={this.handleSelectAssignmentTypeDialogClose}
                            handleSelectOtherMasterDialogSaveClose={this.handleSelectAssignmentTypeDialogSaveClose}
                            selectedAssigmentTypeId={this.state.selectedAssigmentTypeId}
                            masterType={"Assignment Type"}
                            themeSettings = {themeSettings}
                            departmentId={this.state.selectedDepartmentId}
                            
                        />
                    </section>
                </main>
            </Router>
        );
    }
}

export default ProcessMasters;