import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import { matchPath,withRouter } from 'react-router-dom';
import {getThemeSettingsVariables, isEmptyVariable} from '../../common/Global/commonFunctions';
import {TopTabsLayout} from '../../common/Global/globalStyles.style';

const allPrivilege = "ALL";
const viewPrivilege = "VIEW";
let isTasks = '';
let isChecklist = '';
let themeColor = ``;

class MasterTopMenu extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
        isTasks = this.checkActivePath(["/tasks","/addtaskwithtemplates","/viewtasks","/edittaskwithtemplates",
                                            "/viewfunctiontasks","/drafttasks","/underreviewtasks"]);
        isChecklist = this.checkActivePath("/checklist");
    }

    checkActivePath = (path) =>{
        const themeSettings = getThemeSettingsVariables();
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
       return matchPath(this.props.history.location.pathname, { path });
    }

    render(){
        let width = 12.5;
        if(!isEmptyVariable(this.props.masterMenus)){
            width = 12.5*Object.keys(this.props.masterMenus).length
        }
        return(
            <TopTabsLayout themeSettings={this.props.themeSettings}>
            
                <div className="question-tabs"  style={{marginRight:0}}>
                    <ul style={{width:width+"%"}}>
                        {
                            !isEmptyVariable(this.props.masterMenus) &&
                            Object.keys(this.props.masterMenus).map(key => {
                                return <React.Fragment key={key}>
                                    {
                                        key === Constants.SCREEN_TASKS &&
                                        (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege) &&
                                        <li 
                                            className={isTasks ? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                            <a href={Constants.URL_TASKS}
                                                style={{color: !isTasks ? themeColor : '', fontWeight: !isTasks ? "bold" : "100px"}}>Tasks</a>
                                        </li>
                                    }
                                    {
                                        key === Constants.SCREEN_CHECKLIST &&
                                        (this.props.masterMenus[key] === allPrivilege || this.props.masterMenus[key] ===viewPrivilege) &&
                                        <li 
                                            className={isChecklist ? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                            <a href={Constants.URL_CHECKLIST}
                                                style={{color: !isChecklist ? themeColor : '', fontWeight: !isChecklist ? "bold" : "100px"}}>Checklist</a>
                                        </li>
                                    }
                                </React.Fragment>
                            })
                        }
                    </ul>
                </div>

                <div style={{opacity:this.props.hideInfo?"0":"1"}} className="infocard-showhide-col">
                    {/* <div className="infocard-icon-col">
                        <img src="/assets/images/topbar-info.svg" />
                    </div> */}
                </div>
            </TopTabsLayout>
            // <></>
        )
    }
}

export default withRouter(MasterTopMenu);