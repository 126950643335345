import React, { Component } from 'react'
import * as Constants from "../../../common/Global/constants";
import DashboardHeader from './dashboardHeader'
import { getLocalStorageVariables,isEmptyVariable,getThemeSettingsVariables, } from "../../../common/Global/commonFunctions";  
import DashboardDisplayMultilevelChart from './displayMultilevelChart'
import DashboardDisplayMultilevelTable from './displayMultilevelTable'
let userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const tableType = "Table";
const chartType = "Chart";
const tableAndChartType = "Table and Chart";
const displayChartTypeArr = [
  { label: 'Bar', chartName: 'ColumnChart' },
  { label: 'Line', chartName: 'LineChart' },
  { label: 'Scatter', chartName: 'ScatterChart' },
];

const displayTypeArr = []

const formulaArr =[
  {
    label:"More Than",
    isSelected:true
  },
  {
    label:"Less Than",
    isSelected:false
  },
  {
    label:"Top",
    isSelected:false
  },
  {
    label:"Bottom",
    isSelected:false
  }
]
export default class FigureAndTable extends Component {
  constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
      allDatalist: [],
			datalist: [],
      filterList:[],

			selectedChartDisplayType:"Bar",
			selectedChartName:"ColumnChart",
			displayChartTypeArr:displayChartTypeArr,

      selectedDisplayType:"Table",
			displayTypeArr:displayTypeArr,

      showAlertDialogBox:false,
      criteria:[],
      criteriaPlaceholder:"",
      criteriaId:"",
      editCriteriaName:"",
      isMultiLevelCheck:"N",

      multiLevelSelectedItem:{},
      tableHeader:[],
      fixedName:"",

      buWiseFilterList:[],
      unitList:[],

      formulaArrPlaceholder:"More Than",
      formulaArr:formulaArr,
      perSearchKey:"",
      formErrors: {},
      showTableAndChart: false,
      sortColumnIndexAudit: null,
      sortColumnIndexAuditCompliance: null,
      sortColumnNameAudit: "",
      sortColumnNameAuditCompliance: "",
      sortOrderAudit: "asc",
      sortOrderAuditCompliance: "asc",
      datalisTopBottomCount: 0,
		};
	}

  componentDidMount() {
		// this.getInitData();
    this.criteriaDropdownItemClick(this.props.selectedCriteria)
	}

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.criteriaDropdownItemClick(this.props.selectedCriteria)
    }
  }
 
  showAlertDialogBox = () =>{
    this.setState({
      showAlertDialogBox:true
    })

  }
  
  criteriaDropdownItemClick = (selectedItem) =>{
   if(selectedItem.isMultilevel==="Y"){
      let tempChartName ="ColumnChart";
      let tempChartTypeArr = this.state.displayChartTypeArr;
      let tempDisplayTypeArr = [];
      if(selectedItem.finalName=="AuditCycleBase") {
        tempChartTypeArr = tempChartTypeArr.some(ele => ele.label === "Pie") ?
        tempChartTypeArr : [...tempChartTypeArr, { label: 'Pie', chartName: 'PieChart' }]
      } else{
        tempChartTypeArr= tempChartTypeArr.filter(ele=>ele.label!=="Pie")
      }

      let displayOnlyTable = ["clauseWithNcrTable","areaOfImpovementTable","masterSheet","projectWiseQms","reviewEffectiveness","auditorMatrix"]

      if(displayOnlyTable.includes(selectedItem.finalName)) {
        tempDisplayTypeArr = [{ label:tableType }]
      } else{
        tempDisplayTypeArr = [
          { label:tableType, },
          { label:chartType, },
          { label:tableAndChartType, }
        ]
      }


      Object.values(this.state.displayChartTypeArr).map((Item)=>{
        if(Item.label==selectedItem.chartType) {
          tempChartName = Item.chartName
        }
      })

      this.setState({
        isMultiLevelCheck:selectedItem.isMultilevel,
        criteriaPlaceholder:selectedItem.criteriaName,
        multiLevelSelectedItem:selectedItem,
        criteriaId:selectedItem.criteriaId,
        editCriteriaName:selectedItem.criteriaName,
        fixedName:selectedItem.finalName,
        selectedChartName:tempChartName,
        selectedChartDisplayType:"Bar",
        filterList:selectedItem.filterList,
        datalist:[],
        displayChartTypeArr:tempChartTypeArr,
        displayTypeArr:tempDisplayTypeArr,
        showTableAndChart: false,
      })
    }
	}

  handleChange = (e) => {
    this.setState({editCriteriaName:e.target.value,formErrors:{}});
  }

  handleMultilevleDataObj = (obj)=>{
    // console.log("datalist ::",obj.datalist)
    if(this.state.fixedName=="areaOfImpovementTable"){
      if(obj.datalist[0].unitArr.length==0){
        obj.datalist=[]
        
      }
    }
    this.setState({
      allDatalist:obj.datalist,
      datalist:obj.datalist,
      selectedDisplayType:obj.selectedDisplayType,
      selectedChartDisplayType:obj.selectedChartDisplayType,
      tableHeader:obj.tableHeader,
      showTableAndChart: true,
    },()=>{
      if(this.state.fixedName==="BUAndNCBased") {
        let filterList = [];
        Object.values(this.state.datalist).map((Item)=>{
          filterList.push({name:Item.name,unitList:Item.unitList})
        })
        this.setState({
          buWiseFilterList:filterList,
        }
        )
      }
    })
  }

  displayChartTypeDropdownItemClick = (selectedItem)=>{
    this.setState({
      selectedChartName:selectedItem.chartName,
      selectedChartDisplayType:selectedItem.label
    })
  }

  formulaArrOnClick = (selectedItem) => {
    formulaArr.map((item)=>{
        if(item.label==selectedItem.label){
          item.isSelected=true
        } else{
          item.isSelected=false
        }
      })
    this.filterCalculation(selectedItem.label, this.state.perSearchKey);
    this.setState({
      formulaArr:formulaArr,
      formulaArrPlaceholder:selectedItem.label
    });
  }

  perSearchHandleChange = (e) => {
    const value = e.target.value;
    if(isEmptyVariable(value)){
      this.setState({
        perSearchKey:value,
        formErrors:{}
      });
    }else{
      let tmpFormErrors ={};
      if(value < 1){
        tmpFormErrors["perReqFilter"] = "Enter value greater than 1."
        this.setState({
          formErrors:tmpFormErrors,
          perSearchKey:value
        })
      }
      else{
        this.setState({
          perSearchKey:value,
          formErrors:{}
        });
      }
    }
    this.filterCalculation(this.state.formulaArrPlaceholder, value);
  }

  filterCalculation = (formulaArrPlaceholder, value) =>{
    let tmpFilterData = [];
    const filterArr = [ "More Than", "Less Than" ];
    if (!isEmptyVariable(value) && filterArr.includes(formulaArrPlaceholder)) {
      this.state.allDatalist.forEach((item)=>{
        let tmpCount =0;
      item.result.map((data)=>{
        if(formulaArrPlaceholder=="More Than"){
          if(data.total>value){
            tmpCount++;
          }
        }else if(formulaArrPlaceholder=="Less Than"){
          if(data.total<value){
            tmpCount++;
          }
        }
      })
      if(tmpCount===item.result.length){
        tmpFilterData.push(item)
      }
      })
    }
    else {
      tmpFilterData = this.state.allDatalist;
    }
    this.setState({
      datalisTopBottomCount: value,
      datalist: tmpFilterData,
    });
  }

  onSortDatalist = (sortedDatalist) => {
    this.setState({
      datalist: sortedDatalist,
    });
  }

  sortAuditTableRows = (columnIndex, columnName) => {
    this.setState({
      sortColumnIndexAudit: columnIndex,
      sortColumnNameAudit: columnName,
      sortOrderAudit: this.state.sortOrderAudit === "asc" ? "desc" : "asc",
    });
  }

  sortComplianceTableRows = (columnIndex, columnName) => {
    this.setState({
      sortColumnIndexAuditCompliance: columnIndex,
      sortColumnNameAuditCompliance: columnName,
      sortOrderAuditCompliance: this.state.sortOrderAuditCompliance === "asc" ? "desc" : "asc",
    });
  }

  render() {
    return (
    <>
      {!isEmptyVariable(this.state.criteriaPlaceholder) && 
        <DashboardHeader
          showBody={true}
          departmentId={this.props.departmentId}
          displayTypeArr ={this.state.displayTypeArr}
          selectedItemList = {this.props.selectedCriteria} 
          editCriteriaName ={this.props.editCriteriaName}
          criteriaPlaceholder={this.props.criteriaPlaceholder}
          handleMultilevelObj = {this.handleMultilevleDataObj}
          handleUpdateClick = {this.props.handleUpdateClick}
          filterList ={this.state.filterList}
          showLoader ={this.props.showLoader}
          selectedUsersApproval={this.props.selectedUsersApproval}
          showSelectedUsersApproval={this.props.showSelectedUsersApproval}
          criteriaList={this.props.criteriaList}
          displayDropdownItemClick={this.props.displayDropdownItemClick}
					criteriaDropdownItemClick={this.props.criteriaDropdownItemClick}
          updateCriteria={this.props.updateCriteria}
        />
      }
       {Object.values(this.state.datalist).length!=0 && 
        <div className="card-slide-wrapper">
          <div className="row">
            {this.state.showTableAndChart && [tableType,tableAndChartType].includes(this.state.selectedDisplayType) &&
              <div className='col-auto'>
                <DashboardDisplayMultilevelTable
                  datalist = {this.state.datalist}
                  chartType = {this.state.selectedChartDisplayType}
                  tableHeader = {this.state.tableHeader}
                  fixedName ={this.state.fixedName}
                  criteriaPlaceholder={this.state.criteriaPlaceholder}
                  isDisplay ={[tableType,tableAndChartType].includes(this.state.selectedDisplayType)}
                  themeSettings ={themeSettings}
                  showLoader ={this.props.showLoader}
                  formulaArrPlaceholder={this.state.formulaArrPlaceholder}
                  formulaArr={this.state.formulaArr}
                  formulaArrOnClick={this.formulaArrOnClick}
                  perSearchKey={this.state.perSearchKey}
                  perSearchHandleChange={this.perSearchHandleChange}
                  formErrors={this.state.formErrors}
                  onSortDatalist={this.onSortDatalist}
                  sortAuditTableRows={this.sortAuditTableRows}
                  sortComplianceTableRows={this.sortComplianceTableRows}
                  sortColumnIndexAudit={this.state.sortColumnIndexAudit}
                  sortColumnIndexAuditCompliance={this.state.sortColumnIndexAuditCompliance}
                  sortOrderAudit={this.state.sortOrderAudit}
                  sortOrderAuditCompliance={this.state.sortOrderAuditCompliance}
                  datalisTopBottomCount={this.state.datalisTopBottomCount}
                />
              </div>
            }
            {this.state.showTableAndChart && [chartType,tableAndChartType].includes(this.state.selectedDisplayType) &&
              <div className='col'>
                <DashboardDisplayMultilevelChart
                  datalist = {this.state.datalist}
                  fixedName ={this.state.fixedName}
                  chartType = {this.state.selectedChartDisplayType}
                  tableHeader = {this.state.tableHeader}
                  criteriaPlaceholder={this.state.criteriaPlaceholder}
                  chartName ={this.state.selectedChartName}
                  isDisplay = {[chartType,tableAndChartType].includes(this.state.selectedDisplayType)}
                  selectedChartDisplayType={this.state.selectedChartDisplayType}
                  displayChartTypeArr={this.state.displayChartTypeArr}
                  displayChartTypeDropdownItemClick={this.displayChartTypeDropdownItemClick}
                  formulaArrPlaceholder={this.state.formulaArrPlaceholder}
                  formulaArr={this.state.formulaArr}
                  formulaArrOnClick={this.formulaArrOnClick}
                  perSearchKey={this.state.perSearchKey}
                  perSearchHandleChange={this.perSearchHandleChange}
                  formErrors={this.state.formErrors}
                  sortColumnNameAudit={this.state.sortColumnNameAudit}
                  sortColumnNameAuditCompliance={this.state.sortColumnNameAuditCompliance}
                  sortOrderAudit={this.state.sortOrderAudit}
                  sortOrderAuditCompliance={this.state.sortOrderAuditCompliance}
                  datalisTopBottomCount={this.state.datalisTopBottomCount}
                />
              </div>
            }
          </div>
        </div>
      }
       
      {Object.values(this.state.datalist).length==0 &&  (
        <div class="no-items-layout">
          <div class="no-items-card">
            <h6>
              {Constants.NO_RECORDS_WARNING }
            </h6>
          </div>
        </div>
      )}
    </>
    )
  }
}
