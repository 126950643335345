import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {DialogSaveButton,TableRadioButton, ExpandCollapseWrapper} from '../../common/Global/globalStyles.style';
import { toast } from "react-toastify";

const userDetails  = getLocalStorageVariables();

const GroupingTree = (props) => {
    return <div className="dialog-tree-structure">
        <div className="tree-node checkboxparent" style={{marginLeft:(props.level*40)+"px"}}>
            <span 
                onClick={()=>props.onClickNode(props.groupingObj)}
                class="material-icons" style={{opacity:isEmptyArray(props.groupingObj.children)?"0":"1"}}>
                {props.groupingObj.isShowingChildren?"keyboard_arrow_down":"keyboard_arrow_right"}
            </span>
                <TableRadioButton
                themeSettings={props.themeSettings}>
                    <input
                        type="radio"
                        name={props.name}
                        id={props.groupingObj.unitGroupId}
                        value={props.groupingObj.unitGroupId}
                        onChange={props.handleSelection}
                        checked={props.groupingObj.isSelected?true:false}
                    />
                    <label for={props.groupingObj.unitGroupId}
                    style={{marginLeft:6}}>{props.groupingObj.unitGroupName}</label>
                </TableRadioButton>
        </div>

        {
            //Exit Condition
            props.groupingObj.isShowingChildren &&
            props.getChildNodes(props.groupingObj).map((child, idx)=>{
                let levelTemp = props.level + 1;
                return <GroupingTree 
                    groupingObj={child} 
                    name={props.name}
                    getChildNodes={props.getChildNodes}
                    level={levelTemp}
                    handleSelection={props.handleSelection}
                    onClickNode={props.onClickNode}
                    themeSettings={props.themeSettings}
                />
            })
        }
    </div>
}

class SelectGroupingDialog extends Component{
    constructor(props){
        super(props);
        this.state={
            groupingMap: {},
            rootNodes:[],
            refreshFlag:false,
            showLoader:false
        }
        this.selectedGroupingObjArr = [];
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showGroupingDialog){
            this.selectedGroupingObjArr = [];
            this.setState({
                groupingMap: {},
                rootNodes:[],
                refreshFlag:false,
                showLoader:false
            },()=>{
                this.selectedGroupingObjArr = this.props.selectedGroupingObjArr;
                this.getUnitGroupingTree();
            })
        }
    }

    onClickNode = (node) => {
        if(this.state.groupingMap[node.unitGroupId].isShowingChildren){
            this.state.groupingMap[node.unitGroupId].isShowingChildren = false;
        }else{
            this.state.groupingMap[node.unitGroupId].isShowingChildren = true;
        }

        this.setState({
            refreshFlag:!this.state.refreshFlag
        })
    }

    getRootLevelgrouping = (groupingMapTemp) => {
        return Object.values(groupingMapTemp).filter(node => node.level === 0)
    }

    getChildNodes = (node) => {
        if(isEmptyArray(node.children)){
            return [];
        }else{
            return node.children.map(item => this.state.groupingMap[item.unitGroupId]);
        }
    }

    handleSelection = (e) => {
        let isChecked = e.target.checked;
        let unitGroupId = e.target.name;
        
        for (const [key, item] of Object.entries(this.state.groupingMap)) {
            if(item.rootUnitGroupId == unitGroupId){
                item.isSelected = false;
                item.rootUnitGroupId = null;
            }
        }
        let groupingMap = this.state.groupingMap;
        groupingMap[e.target.value].isSelected = isChecked;
        groupingMap[e.target.value].rootUnitGroupId = parseInt(unitGroupId);

        this.setState({
            groupingMap:groupingMap
        });
    }
    
    returnSelectedFunctionId = () => {
        this.selectedGroupingObjArr = [];
        for (const [key, item] of Object.entries(this.state.groupingMap)) {
            if(item.isSelected){
                this.selectedGroupingObjArr.push(item);
            }else{
            }
        }

        if(isEmptyArray(this.selectedGroupingObjArr)){
            toast.warning("Select atleast one grouping");
        }else{
            this.props.handleSelectGroupingDialogSaveClose(this.selectedGroupingObjArr);
        }
    }

    expandCollapseAll = (unitGroupId,isChecked) => {
        this.state.groupingMap[unitGroupId].isShowingChildren = isChecked;

        this.state.groupingMap[unitGroupId].children.map(child => {
            this.expandCollapseAll(child.unitGroupId,isChecked);
        })

        this.setState({
            groupingMap:this.state.groupingMap
        })
    }

    expandAll = () => {
        this.state.rootNodes.map((groupingObj)=>{
            this.expandCollapseAll(groupingObj.unitGroupId,true);
        })
    }

    collapseAll = () => {
        this.state.rootNodes.map((groupingObj)=>{
            this.expandCollapseAll(groupingObj.unitGroupId,false);
        })
    }
    
    /***********************API CALLS***********************/
    getUnitGroupingTree = () => {
        this.setState({
            showLoader:true
        });
        fetch(Constants.GetGroupingSubtree, {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                newFlag:"Y",
                isShowUnitSelcted:"N",
                isClient:this.props.isClient
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }
            else if(data.responseCode === Constants.CODE_SUCCESS){
                const unitGroupMap = data.result.unitGroupMap;
                const rootNodes = this.getRootLevelgrouping(unitGroupMap);
                let groupingTreeData = [];
                if(this.props.isClient == "Y" && this.props.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF){
                    groupingTreeData = rootNodes.filter(item => item.companyId == null && item.isClient == "Y")
                }else if(this.props.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF){
                    groupingTreeData = rootNodes.filter(item => item.companyId == null && item.isClient == "N")
                }else if(this.props.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF){
                    groupingTreeData = rootNodes.filter(item => item.companyId == this.props.companyId && item.isClient == "N")
                }
                //Select Radio button from selectedGroupingObjArr array
                this.selectedGroupingObjArr.map((groupingObj)=>{
                    unitGroupMap[groupingObj.unitGroupId].isSelected = true;
                    unitGroupMap[groupingObj.unitGroupId].rootUnitGroupId = groupingObj.rootUnitGroupId;
                })
                this.setState({
                    showLoader:false,
                    groupingMap:unitGroupMap,
                    rootNodes:groupingTreeData
                })
            }else{
                this.setState({
                    groupingMap:{},
                    showLoader:false
                });
            }
        })
    }    

    render(){
        
        return(
            <Modal className="task-tree-dialog custom-dialog" 
            show={this.props.showGroupingDialog} backdrop="static">
                <Modal.Header>
                    <h5>Grouping</h5>
                    <button 
                        onClick={this.props.handleGroupingDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>

                <hr />
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div className="modal-loading-text">
                            <p
                            style={{
                                background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                            }}
                            >Loading....</p>
                        </div>
                    }
                    <ExpandCollapseWrapper themeSettings={this.props.themeSettings}>
                            <div className="expand-collapse-layout"
                                onClick={this.expandAll}
                                style={{marginRight:10}}
                                type="button">
                                <span class="material-icons">unfold_more</span>
                                <p>Expand All</p>
                            </div>
                            <div
                                className="expand-collapse-layout"
                                onClick={this.collapseAll}
                                style={{marginLeft:10}}
                                type="button">
                                <span class="material-icons">unfold_less</span>
                                <p>Collapse All</p>
                            </div>

                        </ExpandCollapseWrapper>
                    <div className="body-wrapper">
                    <div className="tree-wrapper">
                            {
                                this.state.rootNodes.map((item)=>{
                                    return <GroupingTree 
                                        groupingObj={item} 
                                        name={item.unitGroupId}
                                        getChildNodes={this.getChildNodes}
                                        level={0}
                                        handleSelection={this.handleSelection}
                                        themeSettings={this.props.themeSettings}
                                        onClickNode={this.onClickNode}  //EXPAND/COLLAPSE
                                    />
                                })
                            }
                            {
                                isEmptyArray(this.state.rootNodes) &&
                                <div className="no-items-layout">
                                    <div className="no-items-card">
                                        <h6>
                                        {
                                            Constants.NO_RECORDS_WARNING
                                        }
                                        </h6>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-close-save m-t-15">
                            <div className="close-save-col">
                                <button onClick={this.props.handleGroupingDialogClose} type="button" className="modal-close m-b-0">Cancel</button>
                                {!isEmptyArray(this.state.rootNodes) &&
                                    <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                    onClick={this.returnSelectedFunctionId} type="button" 
                                    className="modal-save m-b-0">Save</DialogSaveButton>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default SelectGroupingDialog;