import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../common/DropdownMenus/RegularDropdown';
import {DialogSaveButton,  TableRadioButton,
} from '../../common/Global/globalStyles.style';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();

const departmentDropdownDefaultValue = "Select Department";

class UpdateReviewStatusDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,

            groupingName:"",
            selectedDepartmentName:departmentDropdownDefaultValue,
            selectedDepartmentId:"",
            formErrors:{},
            submitClickedAtleastOnce:false,
            selectedRadioBox:"",

            companyList:[],
            selectedCompanyPlaceholder:"Select Clients",
            selectedCompanyIds:[],
        }
    }


    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){
            let selectedDepartmentId = "";
            let selectedDepartmentName = departmentDropdownDefaultValue;

            if(!isEmptyVariable(this.props.departmentArr) && 
            this.props.departmentArr.length===1){
                selectedDepartmentId = this.props.departmentArr[0].departmentId;
                selectedDepartmentName = this.props.departmentArr[0].name;
            }

            if(this.props.isEdit){
                let selectedCompanyIds = this.state.selectedCompanyIds;
                if(this.props.isClient!=="Y" && this.props.companyType!==Constants.USER_ENGAGEMENT_TYPE_SELF && this.props.createNodeType=="root")
                {
                    selectedCompanyIds.push(Object.values(this.props.companyDetial)[0].companyId)
                }

                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
                    groupingName:this.props.groupingName,
                    selectedDepartmentName:selectedDepartmentName, //NOT REQ
                    selectedDepartmentId:selectedDepartmentId,//NOT REQ
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                    selectedRadioBox:this.props.isClient==="Y"?"client":"unit",
                    companyList:this.props.companyDetial,
                    selectedCompanyIds:selectedCompanyIds,
                    
                })
            }
            else{
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
                    groupingName:"",
                    selectedDepartmentName:selectedDepartmentName,
                    selectedDepartmentId:selectedDepartmentId,
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                    selectedCompanyIds:[],
            
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.handleCreateMaster();
        }
    }

    deptDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['deparment'] = "";
        }

        this.setState({
            selectedDepartmentName:item.name,
            selectedDepartmentId:item.departmentId,
            formErrors:FormErrorsTemp
        })
    }

    handleCreateDialogClose = () =>{
        this.setState({
            selectedRadioBox:"",
            companyList:[],
            selectedCompanyIds:[]
        })
        this.props.handleAddEditDialogClose(false);
    }

    handleCreateMaster = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

       
      if(this.props.companyType!==Constants.USER_ENGAGEMENT_TYPE_SELF &&this.props.createNodeType=="root" && this.state.selectedRadioBox=="unit" && this.state.selectedCompanyIds==0)
        {
            isError = true;
            FormErrorsTemp['clientName'] = "Please select client";

        }
       else if(this.props.companyType!==Constants.USER_ENGAGEMENT_TYPE_SELF && this.state.selectedRadioBox=="" && this.props.createNodeType=="root")
        {
            isError = true;
            FormErrorsTemp['radioBox'] = "Please select grouping category";

        }
        else if(isEmptyVariable(this.state.groupingName.trim())){
            isError = true;
            FormErrorsTemp['groupingName'] = "Please enter grouping name";
        }
        


        if(isError){
            
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }
        if(!isError){
        

            let url = "";
            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
            }

            if(this.state.selectedRadioBox==="unit" && this.props.companyType!==Constants.USER_ENGAGEMENT_TYPE_SELF)
            {
                postParam.selectedCompany = this.state.selectedCompanyIds;
            }

            if(this.props.isEdit){
                
                url = Constants.UpdateGrouping;
                let groupingObj = {
                    unitGroupId:this.props.selectedId,
                    unitGroupName:this.state.groupingName,
                    
                }
                postParam.unitGrouping = JSON.stringify(groupingObj);
                postParam.rootUnitGroupId = this.props.rootGroupingId;
            }else{
                postParam.departmentId = this.state.selectedDepartmentId;
                
                if(this.props.createNodeType === "root"){
                    url = Constants.AddRootGrouping;
                    let grpObj = {
                        unitGroupId:0,
                        unitGroupName:this.state.groupingName,
                        isClient:this.state.selectedRadioBox==="client"?"Y":"N",
                        isSelf: this.props.companyType===Constants.USER_ENGAGEMENT_TYPE_SELF?"Y":"N"
                        
                    }
                    postParam.unitgrouping = JSON.stringify(grpObj);

                }

                else if(this.props.createNodeType === "child"){
                  
                    url = Constants.AddChildGrouping;
                    let grpObj = {
                        unitGroupId:0,
                        unitGroupName:this.state.groupingName
                    }

                   
                    postParam.childUnitGrouping = JSON.stringify(grpObj);
                    postParam.parentUnitGroupId = this.props.relativeGroupingId;
                    postParam.rootUnitGroupId = this.props.rootGroupingId;
                    postParam.departmentId = this.props.departmentId;
                    
                }else if(this.props.createNodeType === "sibling"){
                    url = Constants.AddSiblingGrouping;

                    let groupObj = {
                        unitGroupId:0,
                        unitGroupName:this.state.groupingName
                    }

                    postParam.siblingUnitGrouping = JSON.stringify(groupObj);
                    postParam.targetUnitGroupId = this.props.relativeGroupingId;
                    postParam.rootUnitGroupId = this.props.rootGroupingId;
                    postParam.departmentId = this.props.departmentId;

                }
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    let createNodeTypeTemp = this.props.createNodeType;

                    if(createNodeTypeTemp === "root"){
                        this.setState({
                            showLoader:false,
                            groupingName:"",
                            formErrors:{},
                            selectedCompany:[],
                            selectedRadioBox:"",
                        },() => {
                            this.props.handleAddEditDialogClose(true);
                        });
                    }else if(createNodeTypeTemp === "child" ||
                    createNodeTypeTemp === "sibling"){
                        this.setState({
                            showLoader:false,
                            groupingName:"",
                            formErrors:{},
                        },() => {
                            this.props.handleAddEditDialogClose(true);
                        });
                    }
                }else{
                    // FormErrorsTemp['apiErr'] = data.responseMessage;
                    toast.error(data.responseMessage);
                    this.setState({
                        // formErrors:FormErrorsTemp,
                        showLoader:false
                    });
                }
            });
        }
    }

    addSelection = (args) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp.clientName="";
          if(!isEmptyVariable(args.itemData.companyId))
            {
                this.state.selectedCompanyIds.push(args.itemData.companyId)
            }
            this.setState({
                selectedCompanyIds:this.state.selectedCompanyIds,
                FormErrors:FormErrorsTemp
            })
        }
       

    removeSelection = (args) => {
            if(!isEmptyVariable(args.itemData.companyId))
            {
               let idx = this.state.selectedCompanyIds.indexOf(args.itemData.companyId);
               if(idx !== -1){
                this.state.selectedCompanyIds.splice(idx,1);
            }
            }
            this.setState({
                selectedCompanyIds:this.state.selectedCompanyIds,
            })
       
    }

    handleCheckBoxClick =(e) =>{
        let value =e.target.name;
        console.log("val::",value)
        if(value=="unit" && !this.props.isEdit)
        {
      
            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                isClient:"N",

            }
           let  postUrl = Constants.GetAllCompanyData

           fetch(postUrl,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } ).then(data=>{
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                  
                    this.setState({
                        companyList:data.data,
                        selectedRadioBox:value
                    },()=>{
                        console.log("selectedRadioBox::",this.state.selectedRadioBox)
                    })
                }
            })
        }
        else{
        this.setState({
            selectedRadioBox:value
        })
    }

    }
    render() {
       let  fields = {text:"companyName",value:"companyId"}


        return(
            <Modal className="custom-dialog" show={this.props.showCreateDialog} backdrop="static">
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit Grouping":"Add Grouping"}</h5>
                    <button 
                        onClick={this.handleCreateDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div class="modal-body">
                    {
                        this.state.showLoader &&
                        <div className="modal-loading-text">
                            <p
                            style={{
                                background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                            }}
                            >Loading....</p>
                        </div>
                    }
                    <div class="addnew-modal-form" >
                        <div class="addnew-modal-form-group">
                        {this.props.companyType!==Constants.USER_ENGAGEMENT_TYPE_SELF && this.props.createNodeType=="root" && 
                            <div className='row'>
          
                                <TableRadioButton themeSettings={this.props.themeSettings} style={{ marginBottom: 10 }} >
                                    <input type="checkbox" name={"unit"} id={"unit"} onChange={this.handleCheckBoxClick}
                                    disabled={this.props.isEdit}
                                    checked={
                                        this.state.selectedRadioBox === "unit" ? true : false
                                    }
                                    />
                                    <label for="unit">Unit</label>
                                </TableRadioButton>
                                <TableRadioButton themeSettings={this.props.themeSettings} style={{marginBottom: 10 }}>
                                    <input type="checkbox" name={"client"} id={"client"} onChange={this.handleCheckBoxClick}
                                     disabled={this.props.isEdit}
                                    checked={
                                        this.state.selectedRadioBox === "client" ? true : false
                                    }
                                    />
                                    <label for="client">Client</label>
                                </TableRadioButton>  
                                
                            </div>
                        }
                        {
                           this.props.companyType!==Constants.USER_ENGAGEMENT_TYPE_SELF && this.props.createNodeType=="root" && this.state.selectedRadioBox=="unit" &&
                           <div className='mb-2'>
                           <p>Clients*</p> 
                           <MultiSelectComponent 
                           id="companyCheckbox"
                           maximumSelectionLength={1}
                           dataSource={this.state.companyList}
                           fields={fields}
                           placeholder={this.state.selectedCompanyPlaceholder} 
                           mode="CheckBox"
                           closePopupOnSelect={false}
                           disabled={this.props.isEdit}
                           allowFiltering={false}   
                           select={this.addSelection}
                           removed={this.removeSelection}
                           cssClass="clientsChecklist dialogcl"
                           value={this.state.selectedCompanyIds}
                           showDropDownIcon={true}
                           showClearButton={false}
                       >
                           <Inject services={[CheckBoxSelection]} />
                       </MultiSelectComponent> 
                        {
                            !isEmptyVariable(this.state.formErrors['clientName']) &&
                            <span class="cm-error">{this.state.formErrors['clientName']}</span>
                        }
                       </div>
                        
                        }
                            <p>Grouping Name*</p>
                            <input type="text" placeholder="Enter Grouping Name" onKeyPress={this.onEnterBtnPress} spellCheck="true" 
                            name="groupingName" value={this.state.groupingName} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['groupingName']) &&
                                <span class="cm-error">{this.state.formErrors['groupingName']}</span>
                            }
                             {
                                !isEmptyVariable(this.state.formErrors['radioBox']) &&
                                <span class="cm-error">{this.state.formErrors['radioBox']}</span>
                            }
                            {/* {
                                !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                <span class="cm-error">{this.state.formErrors['apiErr']}</span>
                            } */}
                        </div>
                    </div>
                    <div class="modal-close-save">
                        <div class="close-save-col">
                            <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                            <DialogSaveButton 
                            themeSettings={this.props.themeSettings}
                            onClick={this.handleCreateMaster} type="button">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default UpdateReviewStatusDialog;