import React from 'react';
import {Modal} from 'react-bootstrap';
import {getLocalStorageVariables, isEmptyVariable} from '../Global/commonFunctions';
import * as Constants from '../Global/constants'
import { toast } from 'react-toastify';
import ReactSelectDropdown from '../DropdownMenus/ReactSelectDropdownWithSearch';
let userDetails = {};

class AlertWithRadioDialog extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            removalOption:"",
            newUserId:"",
            newUserPlaceholder:"Select User",
            errorMsg:"",
        }
    };

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAlertDialog){
            this.setState({
                removalOption:"",
                newUserId:"",
                newUserPlaceholder:"Select User",
                errorMsg:"",
            })
        }
    }

    handleCheck = (e) =>{
        let name = e.target.name;

        if(name === "removalOption3"){
            this.setState({
                removalOption:3,
                errorMsg:""
            })
        }else{
            this.setState({
                removalOption:"",
                newUserId:"",
                newUserPlaceholder:"Select User",
            })
        }
    }

    usersDropdownClick = (item) => {
        this.setState({
            newUserId:item.userId,
            newUserPlaceholder:item.fullName
        })
    }

    handleProceedButtonClick = () => {
        let isError = false;
        let errorMessage = "";
        if(this.props.selectedAssignmentStatus === "Ongoing"){
            if(this.state.removalOption===3 && isEmptyVariable(this.state.newUserId)){
                isError = true;
                errorMessage = "Please select the new user"
            }

            if(this.props.isLastResource === "Y" && this.state.removalOption !== 3){
                isError = true;
                errorMessage = "You are not allowed to delete the last assigned resource. Please add a new resource or choose a replacement resource";
            }
        }
        
        if(isError){
            this.setState({
                errorMsg:errorMessage
            })
        }else{
            userDetails  = getLocalStorageVariables();
            fetch(Constants.RemoveReportResource,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                    removeUserId:this.props.removeUserId,
                    removalOption:this.state.removalOption,
                    newUserId:this.state.newUserId,
                })
            }).then(res=>{
                return res.json();
            }).then(res=>{
                if(res.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    res.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(res.responseCode === Constants.CODE_SUCCESS){
                    this.props.handleAlertDialogClose(true);
                }else{
                    toast.error(res.responseMessage);
                    // this.setState({
                    //     errorMsg:res.responseMessage
                    // })            
                }
            })
        }
    }

    render() {
        return (
            <Modal show={this.props.showAlertDialog} backdrop="static">
                <Modal.Body>
                    <div class="dialog-layout">
                        <button 
                            onClick={this.props.handleAlertDialogClose}>
                            <span className="material-icons">close</span>
                        </button>
                        <span className="material-icons">warning</span>
                        {
                            !isEmptyVariable(this.props.alertDialogHeading) &&
                            <h5>
                                {this.props.alertDialogHeading}
                            </h5>
                        }
                        <p style={{marginBottom:10}}>{this.props.alertDialogMessage}</p>

                        {
                            this.props.selectedAssignmentStatus === "Ongoing" &&
                            <div className="radio-group radio-group-block m-b-10">
                                <div className="radio-btn">
                                    <input 
                                        type="radio"
                                        name="removalOption3"
                                        id="removalOption3"
                                        onChange={this.handleCheck}
                                        checked={this.state.removalOption === 3?true:false}
                                    />
                                    <label for="removalOption3">Automatically re-assign the tasks</label>
                                </div>
                            </div>
                        }
                        {
                            this.state.removalOption === 3 &&
                            <div className="newuser-dd-layout">
                                <ReactSelectDropdown
                                    handleReactSelectClose={this.usersDropdownClick}
                                    selectDropdownArr={this.props.usersList}
                                    label={"fullName"}
                                    value={"userId"}
                                    placeholder={this.state.newUserPlaceholder}
                                />
                            </div>
                        }
                        {
                            this.state.errorMsg &&
                            <span className="cm-error dialog-error">{this.state.errorMsg}</span>
                        }

                        <div class="dialog-btn-layout">
                            <a class="alert-cancel" href="javascript:void(0);"
                                onClick={this.props.handleAlertDialogClose}
                            >Cancel</a>
                            
                            <a 
                                class="alert-proceed alert-dialog-warning" href="javascript:void(0);"
                                onClick = {this.handleProceedButtonClick}
                                style={{
                                    marginLeft:"10px"
                                }}
                            >
                            {this.props.proceedBtnLabel}
                            </a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AlertWithRadioDialog;