import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,removeHtmlTags,getStatusSpan,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import AlertDialog from '../../../common/AlertDialog';
import {ExpandCollapseWrapper,AddNewButtonLayout} from '../../../common/Global/globalStyles.style';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-navigations/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import TreeNodeDropdown from '../../../common/DropdownMenus/TreeNodeDropdown';
import HeadingTree from "./headingTree"
import AddEditGuidanceDialog from "./addEditGuidanceDialog";
import EditWeightDialog from "./editWeightDialog";
const userDetails  = getLocalStorageVariables();
const activeStatus = "Active";
const delDropdownObj = {
    icon:"delete",
    label:"Delete"
}
const editDropdownObj = {
    icon:"edit",
    label:"Add/Edit Guidance"
}
const editWeightDropdownObj = {
    icon:"edit",
    label:"Edit Weight"
}

const EditTaskActionArray = [editDropdownObj,delDropdownObj];
const EditTaskActionWR = [editDropdownObj,editWeightDropdownObj,delDropdownObj];

const TreeNodeEditable = ({headingObj,checkListType,spanObj,themeSettings,
    onDropDownItemClick,minWeight, maxWeight}) => {
    return <div className="tree-dropdown-layout-sync tree-node-sync">
        {
            headingObj.nodeType === "task"
            ?
            <TreeNodeDropdown
                placeholder={removeHtmlTags(headingObj.task)}
                dropdownArr={
                    spanObj.spanText === "Deleted"?[]:
                    (checkListType === "WR"?EditTaskActionWR:EditTaskActionArray)
                }
                labelParam={"label"}
                onDropDownItemClick={onDropDownItemClick}
                dropdownId={{
                    headingObj:headingObj,
                    minWeight:minWeight,
                    maxWeight:maxWeight,
                }}
                fontbold={headingObj.fontbold}
                paraMT={"1.5px"}
                themeSettings={themeSettings}
            />
            :
            <div className="tree-text-layout">
                <p className={headingObj.fontbold?"font-bold":"m-t-0"}>{headingObj.heading}</p>
            </div>
        }
        {/* INDICATORS */}
        {
            !isEmptyVariable(spanObj.spanText) &&
            <span style={spanObj.spanStyle} className="span-tag">
                {spanObj.spanText}
            </span>
        }
    </div>
}

const TreeNodeNonEditable = ({headingObj,spanObj}) => {
    return <div className="tree-node-sync">
        <div className="tree-text-layout">
            <p className={headingObj.fontbold?"font-bold":"m-t-0"}>
                {
                    headingObj.nodeType === "task"
                    ? removeHtmlTags(headingObj.task)
                    : headingObj.heading
                }
                {
                    !isEmptyVariable(spanObj.spanText) &&
                    <span style={spanObj.spanStyle} className="span-tag">
                        {spanObj.spanText}
                    </span>
                }
            </p>
        </div>
    </div>
}

class HeadingTreeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader:false,

            headingMap:{},
            actionArr:[],
            syncFusionData:[],
            rootCheckListHeadingId:"",

            showCreateDialog:false,
            isEdit:false,
            createNodeType:"",
            selectedId:"",
            heading:"",
            relativeFunctionId:"",
            clearAndResetFunctionTree:true,
            idToBeExpandedAfterAddingChild:"",

            operationType:"",
            deleteReactivateId:"",
            showAlertWithRadioDialog:false,
            alertWithRadioDialogMessage:"",
            showRadio:true,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showProcessFunctionsDialog:false,
            showHeadingTreeDialog:false,

            guidanceDialogFlag:false,
            selectedChecklistTaskObj:{},

            showAlertDialog:false,
            alertDialogMessage:"",
            deleteChecklistTaskId:""
        }

        this.fields = {};
        this.headingObj = {};
    }

    componentDidMount(){
        this.fields = {
            dataSource:[],
            id:"checkListHeadingId",
            text:"heading",
            parentID:"parentId",
            child:"newChildren",
            expanded:"isExpanded"
        };

        this.setState({
            showLoader:false,

            headingMap:{},
            actionArr:[],
            syncFusionData:[],
            rootCheckListHeadingId:"",

            showCreateDialog:false,
            isEdit:false,
            createNodeType:"",
            selectedId:"",
            heading:"",
            relativeFunctionId:"",
            clearAndResetFunctionTree:true,
            idToBeExpandedAfterAddingChild:"",

            operationType:"",
            deleteReactivateId:"",
            showAlertWithRadioDialog:false,
            alertWithRadioDialogMessage:"",

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",
            showHeadingTreeDialog:false,
        },()=>{
            this.getFunctionTree();
        })
    }

    handleAlertDialogClose = () => {
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:"",
            deleteChecklistTaskId:""
        })
    }
    onDropDownItemClick = (item,dropdownObj) => {
        if(item.label === "Add/Edit Guidance"){
            this.setState({
                guidanceDialogFlag:true,
                selectedChecklistTaskObj:dropdownObj.headingObj,
            })
        }else if(item.label === "Edit Weight"){
            this.setState({
                weightDialogFlag:true,
                selectedChecklistTaskObj:dropdownObj,
            })
        }else if(item.label === "Delete"){
            this.setState({
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete this task from checklist?",
                deleteChecklistTaskId:dropdownObj.headingObj.checkListTaskId
            })
        }
    }

    handleGuidanceDialogClose = (reloadFlag) => {
        this.setState({
            guidanceDialogFlag:false,
            selectedChecklistTaskObj:{},
        },()=>{
            if(reloadFlag){
                this.getFunctionTree();
            }
        })
    }

    handleWeightDialogClose = (reloadFlag) => {
        this.setState({
            weightDialogFlag:false,
            selectedChecklistTaskObj:{},
        },()=>{
            if(reloadFlag){
                this.getFunctionTree();
            }
        })
    }

    handleShowHeadingTreeDialog = () => {
        this.setState({
            showHeadingTreeDialog:true,
        })
    }

    handleCloseHeadingTreeDialog = () => {
        this.setState({
            showHeadingTreeDialog:false,
        },()=>{
            this.getFunctionTree();
        })
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    getRootLevelFunctions = (functionMapTemp) => {
        let rootFunctionArr = [];
        Object.values(functionMapTemp).map((item)=>{
            if(item.level === 0){
                rootFunctionArr.push(item);
            }
        })
        return rootFunctionArr;
    }

    //No need of return value since the array itself is passed and updated.
    //Javascript maintains single array with pointers. it will not deep copy the array
    addParentIdandHasChildrenFlag = (tempArr, checkListHeadingId, level,parentHeadingId,prefix) => {
        tempArr[checkListHeadingId].level = level;
        tempArr[checkListHeadingId].parentHeadingId = parentHeadingId;
        tempArr[checkListHeadingId].heading = prefix+" "+tempArr[checkListHeadingId].heading;

        let newTaskList = tempArr[checkListHeadingId].taskList.map((item,index)=>{
            item.task=prefix+"."+(index+1)+" "+item.task;
            return item;
        })

        tempArr[checkListHeadingId].taskList = newTaskList;

        if(!isEmptyArray(tempArr[checkListHeadingId].children)){
            tempArr[checkListHeadingId].children.map((childNode,index) => {
                let newPrefix = prefix+"."+(index+1);
                this.addParentIdandHasChildrenFlag(tempArr,childNode.headingId,level+1,checkListHeadingId,newPrefix);
            });
        }else{
            tempArr[checkListHeadingId].hasChildren = false;
        }
    }

    /************************API CALLS **************************/
    HeadingTree = (headingObj) => {
        let spanObj = getStatusSpan(headingObj.status,this.props.themeSettings);
        return <div className={headingObj.nodeType === "task"?"dialog-tree-structure-sync border-bottom":"dialog-tree-structure-sync"}>
            {
                !isEmptyVariable(headingObj) &&
                this.props.rootNodeDetails?.allowEdit !== "Y" &&
                <TreeNodeNonEditable
                    headingObj = {headingObj}
                    spanObj = {spanObj}
                />
            }
            {
                !isEmptyVariable(headingObj) &&
                this.props.rootNodeDetails?.allowEdit === "Y" &&
                <TreeNodeEditable
                    headingObj = {headingObj}
                    checkListType = {this.props.rootNodeDetails?.checkListType}
                    spanObj = {spanObj}
                    themeSettings={this.props.themeSettings}
                    onDropDownItemClick={this.onDropDownItemClick}
                    minWeight = {this.props.rootNodeDetails.minWeight}
                    maxWeight = {this.props.rootNodeDetails.maxWeight}
                />
            }
        </div>
    }

    createSyncHRFusionData = (parentId,checkListHeadingId,headingMap,isExpanded) => {
        //Now create an object
        let headingObj = headingMap[checkListHeadingId];
        headingObj.newChildren = [];
        headingObj.isExpanded = isExpanded;
        headingObj.fontbold = true;
        
        if(parentId !== checkListHeadingId){
            headingObj.parentId = parentId;
        }

        let newTaskList = headingObj.taskList.map(item=>{
            item.nodeType="task";
            item.parentId = checkListHeadingId;
            return item;
        })
        headingObj.newChildren = [...newTaskList]

        //now add children recursively
        headingObj.children.map((item)=>{
            headingObj.newChildren.push(this.createSyncHRFusionData(checkListHeadingId,item.headingId,headingMap,true));
        });
        return headingObj;
    }

    updateSyncHRFusionData = (parentId,checkListHeadingId,headingMap) => {
        //Now create an object
        let headingObj = headingMap[checkListHeadingId];
        headingObj.newChildren = [];

        //Check the old function map
        if(!isEmptyVariable(this.state.headingMap[checkListHeadingId])){
            headingObj.isExpanded = this.state.headingMap[checkListHeadingId].isExpanded;
            headingObj.fontbold = this.state.headingMap[checkListHeadingId].fontbold;
            headingMap[checkListHeadingId].isExpanded = this.state.headingMap[checkListHeadingId].isExpanded;
        }

        if(parentId !== checkListHeadingId){
            headingObj.parentId = parentId;
        }

        let newTaskList = headingObj.taskList.map(item=>{
            item.nodeType="task";
            item.parentId = checkListHeadingId;
            return item;
        })
        headingObj.newChildren = [...newTaskList]

        //now add children recursively
        headingObj.children.map((item)=>{
            headingObj.newChildren.push(this.updateSyncHRFusionData(checkListHeadingId,item.headingId,headingMap));
        });
        return headingObj;
    }

    getFunctionTree = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetChecklistHeadings,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                checkListVersionId:this.props.rootCheckListVersionId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let temp = data.result.headingMap;
                let rootFunctionArr = this.getRootLevelFunctions(temp);
                let rootCheckListHeadingId = "";

                rootFunctionArr.map((rootNode)=>{
                    rootCheckListHeadingId = rootNode.checkListHeadingId;
                    this.addParentIdandHasChildrenFlag(temp,rootNode.checkListHeadingId,1,rootNode.checkListHeadingId,"1")
                })

                let syncFusionData = [];
                //clearAndResetFunctionTree - if it is false, then copy the isShowingChildren param 
                //from old state array, this is required because whenever the user adds a new node at the nth level
                //all the nodes are collapsed and shows only first level nodes since the API is called again
                if(!isEmptyArray(Object.keys(this.state.headingMap)) && 
                !this.state.clearAndResetFunctionTree){
                    let headingObj = this.updateSyncHRFusionData(rootCheckListHeadingId,rootCheckListHeadingId,temp);
                    headingObj.fontbold = true;
                    syncFusionData.push(headingObj);
                }else{
                    //create datastructure and Expand root level node's children by default
                    let headingObj = this.createSyncHRFusionData(rootCheckListHeadingId,rootCheckListHeadingId,temp,true);
                    temp[rootCheckListHeadingId].isExpanded = true;
                    headingObj.fontbold = true;
                    syncFusionData.push(headingObj);
                }
                //Action item arrays based on allowEdit flag
                let actionArrTemp = [];
                let actionArrRootTemp = [];

                //set the tree fields
                this.fields = {
                    dataSource:syncFusionData,
                    id:"checkListHeadingId",
                    text:"heading",
                    parentID:"parentId",
                    child:"newChildren",
                    expanded:"isExpanded"
                };

                this.setState({
                    headingMap:temp,
                    actionArr:actionArrTemp,
                    actionArrRoot:actionArrRootTemp,
                    idToBeExpandedAfterAddingChild:"",
                    syncFusionData:syncFusionData,
                    rootCheckListHeadingId:rootCheckListHeadingId,
                    showLoader:false,
                    clearAndResetFunctionTree:false
                },()=>{
                    this.reference.refresh();
                });
            }else{
                this.setState({
                    headingMap:{},
                    showLoader:false,
                    syncFusionData:[],
                    clearAndResetFunctionTree:false
                });
            }
        });
    }

    removeChecklistTask = () => {
        fetch(Constants.RemoveCheckListTask,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                checkListVersionId:this.props.rootCheckListVersionId,
                checkListTaskId:this.state.deleteChecklistTaskId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showAlertDialog:false,
                    alertDialogMessage:"",
                    deleteChecklistTaskId:""
                },()=>{
                    this.getFunctionTree();
                })
            }else{
                //TODO show alert dialog
            }

        });
    }

    expandAll = () => {
        this.reference.expandAll();
    }

    collapseAll = () => {
        this.reference.collapseAll();
    }

    nodeExpanded = (args) =>{
        this.state.headingMap[args.nodeData.id].isExpanded = true;
    }

    nodeCollapsed = (args) =>{
        this.state.headingMap[args.nodeData.id].isExpanded = false;
    }

    render() {
        // console.log(JSON.stringify(this.fields))
        let dnd = false;
        
        return(
            <div>
                <div className="body-wrapper">
                    {
                        this.state.showLoader &&
                        <div className="modal-loading-text">
                            <p
                            style={{
                                background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                            }}
                            >Loading....</p>
                        </div>
                    }
                    <div className="flex-center-layout" style={{justifyContent:"space-between"}}>
                        <div className="flex-center-layout secondary-top-bar">
                            <div className="back-btn-layout" onClick={this.props.handleHeadingTreeDialogClose}>
                                <span class="material-icons-outlined">arrow_back_ios</span>
                            </div>
                            <h6>{this.props.headingTreeChecklistName}</h6>
                        </div>
                        {
                            ((this.props.rootNodeDetails?.allowEdit === "Y" && this.props.rootNodeDetails?.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW) ||
                            (this.props.rootNodeDetails?.allowEdit === "Y" && this.props.rootNodeDetails?.allowReview === Constants.ALLOW_REVIEW_UPDATEREVIEWSTATUS) ||
                            (this.props.rootNodeDetails?.allowEdit === "Y" && this.props.rootNodeDetails?.status === activeStatus)) &&
                            <AddNewButtonLayout themeSettings={this.props.themeSettings}>
                                <a onClick={this.handleShowHeadingTreeDialog} href="javascript:void(0);">
                                    <span className="material-icons">add_circle</span>
                                    <p>Add/Remove Task</p>
                                </a>
                            </AddNewButtonLayout>
                        }
                    </div>
                    
                    <div className="card-col tree-node-bottom-border">
                        <ExpandCollapseWrapper themeSettings={this.props.themeSettings}>
                            <div className="expand-collapse-layout"
                                onClick={this.expandAll}
                                style={{marginRight:10}}
                                type="button">
                                <span class="material-icons">unfold_more</span>
                                <p>Expand All</p>
                            </div>
                            <div
                                className="expand-collapse-layout"
                                onClick={this.collapseAll}
                                style={{marginLeft:10}}
                                type="button">
                                <span class="material-icons">unfold_less</span>
                                <p>Collapse All</p>
                            </div>

                        </ExpandCollapseWrapper>
                        <TreeViewComponent 
                            fields={this.fields} 
                            allowDragAndDrop={dnd}
                            nodeTemplate={this.HeadingTree}
                            ref = {(treeNode) => {this.reference = treeNode}}
                            nodeExpanded = {this.nodeExpanded}
                            nodeCollapsed = {this.nodeCollapsed}
                        />
                    </div>
                </div>


                <AlertDialog 
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={this.props.themeSettings}
                />
                <AlertDialog 
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type= {Constants.ALERT_TYPE_WARNING}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={this.removeChecklistTask}
                    proceedBtnLabel={ "Delete" }
                    themeSettings={this.props.themeSettings}
                />

                {/* <ProcessFunctionListDialog
                    handleProcessFuncDialogClose = {this.handleProcessFuncDialogClose}
                    showProcessFunctionsDialog = {this.state.showProcessFunctionsDialog}
                    themeSettings={this.props.themeSettings}

                    currentPageNo={this.props.currentPageNo}
                    searchkey={this.props.searchkey}
                    resultSize={this.props.resultSize}
                    sort={this.props.sort}
                    sortDir={this.props.sortDir}
                    status={this.props.status}
                    handleActionDropdownItemClick={this.props.handleActionDropdownItemClick}

                    checkListItem = {this.props.rootNode}
                    departmentId={this.props.departmentId}

                    checkListHeadingId = {this.state.selectedId}
                    checkListHeading = {this.state.heading}
                /> */}

                <HeadingTree
                    showHeadingTreeDialog={this.state.showHeadingTreeDialog}
                    handleHeadingTreeDialogClose={this.handleCloseHeadingTreeDialog}

                    rootCheckListVersionId={this.props.rootCheckListVersionId}
                    themeSettings={this.props.themeSettings}
                    rootNode={this.props.rootNodeDetails}

                    currentPageNo={this.props.currentPageNo}
                    searchkey={this.props.searchkey}
                    resultSize={this.props.resultSize}
                    sort={this.props.sort}
                    sortDir={this.props.sortDir}
                    status={this.props.status}
                    departmentId= {this.props.departmentId}
                    handleActionDropdownItemClick={this.props.handleActionDropdownItemClick}
                />

                <AddEditGuidanceDialog
                    guidanceDialogFlag = {this.state.guidanceDialogFlag}
                    handleGuidanceDialogClose = {this.handleGuidanceDialogClose}
                    selectedChecklistTaskObj = {this.state.selectedChecklistTaskObj}
                    themeSettings={this.props.themeSettings}
                />
                <EditWeightDialog
                    guidanceDialogFlag = {this.state.weightDialogFlag}
                    handleWeightDialogClose = {this.handleWeightDialogClose}
                    selectedChecklistTaskObj = {this.state.selectedChecklistTaskObj}
                    themeSettings={this.props.themeSettings}
                />
            </div>
        );
    }
}

export default HeadingTreeDialog;