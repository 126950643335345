import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray } from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import RegularDropdown from "../../../common/DropdownMenus/RegularDropdown";
import { DialogSaveButton } from "../../../common/Global/globalStyles.style";
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import { toast } from "react-toastify";

const userDetails = getLocalStorageVariables();

class AddCommentDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			showLoader: false,
            selectedUserIdArr:[],
            usersList:[],

			comment: "",
			formErrors: {},
			submitClickedAtleastOnce: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showCreateDialog) {
            let users = [];
            this.props.assignmentMatrix.forEach(item=>{
                //add all the reviewers and assigned user to the task
                if( !isEmptyVariable(item.position) && (item.position.includes("Review Level") || item.id == this.props.assignmentTaskUserId)){
                    users.push(item);
                }
            })
            console.log("USERS: "+JSON.stringify(users));
            //now remove the logged in user
            let usersFiltered = users.filter(item => item.id != userDetails.userId);
            console.log("USERS F: "+JSON.stringify(usersFiltered));
			if(this.props.isEdit){
				this.setState({
					componentDidMountFlag: false,
					showLoader: false,
                    selectedUserIdArr:[],
                    usersList:usersFiltered,

					comment: this.props.selectedRemark,
					formErrors: {},
					submitClickedAtleastOnce: false,
				})
			}else{
				this.setState({
					componentDidMountFlag: false,
					showLoader: false,
                    selectedUserIdArr:[],
                    usersList:usersFiltered,
	
					comment: "",
					formErrors: {},
					submitClickedAtleastOnce: false,
				});
			}
			
		}
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

    addSelection = (args) => {
        if(!isEmptyVariable(args.itemData.id))
        {
            this.state.selectedUserIdArr.push(args.itemData.id)
        }

        let formErr = this.state.formErrors;
        formErr.usersError = "";
        this.setState({
            selectedUserIdArr:this.state.selectedUserIdArr,
            formErrors:formErr
        })

        console.log(this.state.selectedUserIdArr);
    }


    removeSelection = (args) => {
        if(!isEmptyVariable(args.itemData.id)){
            let idx = this.state.selectedUserIdArr.indexOf(args.itemData.id);
            if(idx !== -1){
                this.state.selectedUserIdArr.splice(idx,1);
            }
        }
        this.setState({
            selectedUserIdArr:this.state.selectedUserIdArr
        })
    }

	handleCreateDialogClose = () => {
		this.props.handleAddDialogClose(false);
	};

	onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
			this.handleCreateComment();
		}
	};

	handleCreateComment = () => {
		this.setState({
			showLoader: true,
			submitClickedAtleastOnce: true,
		});

		let isError = false;
		let FormErrorsTemp = {};

		if (isEmptyVariable(this.state.comment)) {
			isError = true;
			FormErrorsTemp["comment"] = "Please enter comment";
		}

        // if(isEmptyArray(this.state.selectedUserIdArr)){
        //     isError = true;
		// 	FormErrorsTemp["usersError"] = "Please select resources";
        // }

		if (isError) {
			this.setState({
				formErrors: FormErrorsTemp,
				showLoader: false,
			});
		} else {
			let url = "";

			let postParam = {};

			if(this.props.isEdit){
				url = Constants.UpdateTaskRemark;
				postParam = {
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentTaskRemarkId: this.props.selectedAssignmentTaskRemarkId,
					remark: this.state.comment,
                    toUserIds:JSON.stringify(this.state.selectedUserIdArr)
				};
			}else{
				url = Constants.SaveAssignmentTaskProperties;
				postParam = {
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
					assignmentTaskId: this.props.assignmentTaskId,
					remark: this.state.comment,
					type:this.props.type,
                    toUserIds:JSON.stringify(this.state.selectedUserIdArr)
				};
			}
			

			fetch(url, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState(
							{
								showLoader: false,
								comment: "",
								formErrors: {},
								selectedUserIdArr:[],

							},
							() => {
								this.props.handleAddDialogClose(true);
							}
						);
					} else {
						// FormErrorsTemp["apiErr"] = data.responseMessage;
						toast.error(data.responseMessage);
						this.setState({
							// formErrors: FormErrorsTemp,
							showLoader: false,
						});
					}
				});
		}
	};

	render() {
		return (
			<Modal className="custom-dialog" show={this.props.showCreateDialog} backdrop="static">
				<Modal.Header>
					<h5>{this.props.isEdit?"Edit":"Add"} {this.props.type==="Remark"?"Remarks":"Comment"}</h5>
					<button onClick={this.handleCreateDialogClose} type="button" data-dismiss="modal">
						<span class="material-icons">close</span>
					</button>
				</Modal.Header>
				<hr />
				<div class="modal-body">
					{this.state.showLoader && (
						<div className="modal-loading-text">
							<p
								style={{
									background: `rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
									color: `rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`,
								}}
							>
								Loading....
							</p>
						</div>
					)}
					<div class="addnew-modal-form">
						<div class="addnew-modal-form-group">
							<p>Comment*</p>
							<textarea
								type="text"
								placeholder="Enter Comment"
								onKeyPress={this.onEnterBtnPress}
								name="comment"
								value={this.state.comment}
								onChange={this.handleChange}
								spellCheck="true" 
							/>
							{!isEmptyVariable(this.state.formErrors["comment"]) && <span class="cm-error">{this.state.formErrors["comment"]}</span>}
							{/* {!isEmptyVariable(this.state.formErrors["apiErr"]) && <span class="cm-error">{this.state.formErrors["apiErr"]}</span>} */}
						</div>
                        {
                            !isEmptyArray(this.state.usersList) &&
                            <div className="addnew-modal-form-group">
                                <p>Resources*</p>
                                <MultiSelectComponent 
                                    id="functionCheckbox"
                                    dataSource={this.state.usersList}
                                    fields={{text:"label",value:"id"}}
                                    placeholder={"Please select resources"} 
                                    mode="CheckBox"
                                    closePopupOnSelect={false}
                                    allowFiltering={false}
                                    select={this.addSelection}
                                    removed={this.removeSelection}
                                    cssClass="clientsChecklist"
                                    value={this.state.selectedUserIdArr}
                                    showDropDownIcon={true}
                                    showClearButton={false}
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                                {
                                    !isEmptyVariable(this.state.formErrors['usersError']) &&
                                    <span className="cm-error">{this.state.formErrors['usersError']}</span>
                                }
                            </div>
                        }
					</div>
                    
					<div class="modal-close-save">
						<div class="close-save-col">
							<button onClick={this.handleCreateDialogClose} type="button" class="modal-close">
								Cancel
							</button>
							<DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.handleCreateComment} type="button" class="modal-save">
								Save
							</DialogSaveButton>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default AddCommentDialog;
