import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,DialogSaveButton,TreeNodeCheckBox} from '../../../common/Global/globalStyles.style';
import Sidebar from '../../../common/Sidebar';
import Topbar from '../../../common/Topbar';
import MasterMenuTabs from '../Common/topmenu';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../../common/AlertDialog';
import Pagination from '../../../common/Global/pagination';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable, sortTable,truncateString,
    isEmptyArray,GetOrderedMasterTabMenu,
    ifEmptyReturnEmptyStr} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {
    BrowserRouter as Router,
} from 'react-router-dom';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const sortShortName = "shortName";
const sortName = "name";
const sortDescription = "description";
const sortMasterType = "masterType";
const valueAddition = "Value Addition";

const masterTypeDefaultPlaceholder = "Select Master Type";
const masterTypeFilterDefaultPlaceholder = "All Masters";

class GeneralMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",

            masterTypeArr:[],
            masterTypeSelected:"All",
            masterFilterPlaceholder:masterTypeFilterDefaultPlaceholder,

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            deleteReactivateMasterType:"",
            alertDialogMessage:"",

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            status:"Active",
            statusArr:[
                {
                    label:"Active",
                    isSelected:true
                },
                {
                    label:"Inactive",
                    isSelected:false
                }
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,

            name:"",
            shortName:"",
            description:"",
            masterType:"",
            isNumeric:false,
            formErrors:{},
            submitClickedAtleastOnce:false,

            showDetailsDialog:false,
            selectedItem:"",
        }
        this.masterTypeFilterArr = [];
    }

    componentDidMount(){
        this.getInitData();
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){

            if(name === "shortName"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['shortName'] = "Please enter master short name";
                }else{
                    FormErrorsTemp['shortName'] = "";
                }
            }else if(name === "name"){
                console.log("Inside name");
                if(isEmptyVariable(value)){
                    console.log("Inside name value:"+value);
                    FormErrorsTemp['name'] = "Please enter master name";
                }else{
                    FormErrorsTemp['name'] = "";
                }
            }else if(name === "description"){
                if(!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH){
                    FormErrorsTemp['description'] = "Description must be less then 500 characters";
                }else{
                    FormErrorsTemp['description'] = "";
                }
            }
        }

        this.setState({ 
            [name]: value ,
            formErrors:FormErrorsTemp
        });
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getGenaralMastersList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getGenaralMastersList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleCreateDialogClose = () =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedId:"",
            name:"",
            shortName:"",
            description:"",
        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    showCreateDialog = () => {
        this.setState({
            showCreateDialog:true,
            isEdit:false,
            selectedId:"",
            name:"",
            shortName:"",
            description:"",
            isNumeric:false,
            masterType:masterTypeDefaultPlaceholder,
            submitClickedAtleastOnce:false,
            formErrors:{}
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getGenaralMastersList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getGenaralMastersList();
        })
    }

    masterFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";

        for(const [i,value] of this.masterTypeFilterArr.entries()){
            if(i === selectedIndex){
                this.masterTypeFilterArr[i].isSelected = true;
                placeholder = value.masterType;
            }else{
                this.masterTypeFilterArr[i].isSelected = false;
            }
        }
        
        this.setState({
            masterTypeSelected:placeholder,
            masterFilterPlaceholder:placeholder==="All"?masterTypeFilterDefaultPlaceholder:placeholder
        },() => {
            this.getGenaralMastersList();
        })
    }

    masterTypeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            if(isEmptyVariable(this.state.masterType) || this.state.masterType === masterTypeDefaultPlaceholder){
                FormErrorsTemp['masterType'] = "";
            }else{
                FormErrorsTemp['masterType'] = "";
            }
        }

        this.setState({
            masterType:item.masterType,
            formErrors:FormErrorsTemp
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.id,
                deleteReactivateMasterType:dropdownItem.masterType,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete master "+dropdownItem.name+"?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.id,
                deleteReactivateMasterType:dropdownItem.masterType,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate master "+dropdownItem.name+"?"
            })
        }else if(item.label === "Edit"){
            this.setState({
                showCreateDialog:true,
                isEdit:true,
                selectedId:dropdownItem.id,
                masterType:dropdownItem.masterType,
                name:dropdownItem.name,
                shortName:dropdownItem.shortName,
                description:dropdownItem.description,
                isNumeric:dropdownItem.isNumeric==="Y",
                formErrors:{}
            });
        }
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getGenaralMastersList();
        })
    }

    handleSelectionNumeric = (e) => {
        let isChecked = e.target.checked;
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp.checkListType = "";

        this.setState({
            isNumeric:isChecked,
            FormErrors:FormErrorsTemp
        })
    }
    
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetGeneralMasterCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetGeneralMasters,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    masterType:this.state.masterTypeSelected==="All"?"ALL":this.state.masterTypeSelected
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,generalRes,menuRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,generalRes,menuRes]) => {
            let masterTypeArrTemp = [];
            let datalistTemp = [];
            let totalCountTemp = 0;
            let menusTemp = {};

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    masterTypeArrTemp = masterRes.data.masterType;
            }else{
                masterTypeArrTemp = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenu(menusTemp)

            //Initialise Master Type Filter Dropdown Arr
            this.masterTypeFilterArr = JSON.parse(JSON.stringify(masterTypeArrTemp));
            let allObj = {
                masterType:"All",
                isSelected:true
            }            
            this.masterTypeFilterArr.splice(0,0,allObj);

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                masterTypeArr:masterTypeArrTemp,
                datalist:datalistTemp,
                totalCount:totalCountTemp,
                menus:menusTemp2,
            })
        })
    }

    getGenaralMastersList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetGeneralMasters,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                masterType:this.state.masterTypeSelected==="All"?"ALL":this.state.masterTypeSelected
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetGeneralMasters,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    masterType:this.state.masterTypeSelected==="All"?"ALL":this.state.masterTypeSelected
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    deleteActivateGeneralMaster = () => {
        let url = "";

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteGeneralMasters;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ReactivateGeneralMasters;
        }

        this.setState({
            showLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                masterType:this.state.deleteReactivateMasterType,
                masterDataId:this.state.deleteReactivateId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    deleteReactivateMasterType:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                },() => {
                    this.getGenaralMastersList();
                });
            }else{
                this.setState({
                    showLoader:false,
                    deleteReactivateId:"",
                    deleteReactivateMasterType:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    handleCreateMaster = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.masterType) || this.state.masterType === masterTypeDefaultPlaceholder){
            isError = true;
            FormErrorsTemp['masterType'] = "Please select master type";
        }

        if(isEmptyVariable(this.state.name)){
            isError = true;
            FormErrorsTemp['name'] = "Please enter master name";
        }

        if(isEmptyVariable(this.state.shortName)){
            isError = true;
            FormErrorsTemp['shortName'] = "Please enter master short name";
        }

        if(!isEmptyVariable(this.state.description)){
            if(this.state.description.length > Constants.DESC_STRING_LENGTH){
                isError = true;
                FormErrorsTemp['description'] = "Description must be less then 500 characters";
            }
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                shortName:this.state.shortName,
                name:this.state.name,
                description:this.state.description,
                masterType:this.state.masterType,
                masterDataId:this.state.selectedId
            }

            if(this.state.isEdit){
                url = Constants.EditGeneralMasters;
                if(this.state.masterType === valueAddition){
                    postParam.isNumeric = this.state.isNumeric?"Y":"N"
                }
            }else{
                url = Constants.CreateGeneralMasters;
                if(this.state.masterType === valueAddition){
                    postParam.isNumeric = this.state.isNumeric?"Y":"N"
                }
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showCreateDialog:false,
                        showLoader:false,
                        isEdit:false,
                        selectedId:"",
                        name:"",
                        shortName:"",
                        description:"",
                    },() => {
                        this.getGenaralMastersList();
                    });
                }else{
                    this.setState({
                        showCreateDialog:false,
                        showLoader:false,
                        isEdit:false,
                        selectedId:"",
                        name:"",
                        shortName:"",
                        description:"",

                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        const actionArr = [
            {
                icon:"edit",
                label:"Edit"
            },
            {
                icon:"delete",
                label:"Delete"
            },
        ]

        const actionArrReactivate = [
            {
                icon:"check_circle",
                label:"Reactivate"
            },
        ]
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    this.state.showLoader &&
                                    <div className="loading-layout"
                                    style={{background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`}}>
                                        <div className="loading-text-layout">
                                            <p
                                            style={{color:`rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`}}
                                            >Loading....</p>
                                        </div>
                                    </div>
                                }
                                {
                                    // Need to check component did mount flag, otherwise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                        themeSettings={themeSettings}
                                                        onClick = {this.onSearchIconClick} 
                                                        className="material-icons"> search </SearchSpan>
                                                    </div>

                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        themeSettings={themeSettings}
                                                    />
                                                    
                                                    <FilterDropDown
                                                        placeholder={this.state.masterFilterPlaceholder}
                                                        dropdownArr={this.masterTypeFilterArr}
                                                        onDropDownItemClick={this.masterFilterDropdownClick}
                                                        name={"masterType"}
                                                        themeSettings={themeSettings}
                                                    />
                                                </div>

                                                <AddNewButtonLayout themeSettings={themeSettings}>
                                                    <a onClick={this.showCreateDialog} href="javascript:void(0);">
                                                    <span className="material-icons">add_circle</span>
                                                    <p>Add New</p>
                                                </a>
                                                </AddNewButtonLayout>
                                            </div>
                                        </div>

                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th className="c-pointer" width={"22%"} onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                <div className="sort-header">Name
                                                                <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDescription)}>
                                                                <div className="sort-header">Description
                                                                <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th width={"14%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortMasterType)}>
                                                                <div className="sort-header">Type
                                                                <span className={(this.state.sort === sortMasterType)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {/* <th width={"10%"}>Users Count</th> */}
                                                            <th className="text-center"  width={"5%"}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {
                                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{truncateString(item.description,Constants.STRING_LENGTH)}</td>
                                                                    <td>{item.masterType}</td>
                                                                    <td className="text-center">
                                                                        <TableDropDown 
                                                                            actionArr={item.status === "Active"?actionArr:actionArrReactivate}
                                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                            dropDownId = {item}
                                                                            themeSettings={themeSettings}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings={themeSettings}/>
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete Master"
                                :
                                "Activate Master"
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.deleteActivateGeneralMaster}
                            proceedBtnLabel={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete"
                                :
                                "Reactivate"
                            }
                            themeSettings={themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <Modal className="custom-dialog" show={this.state.showCreateDialog} backdrop="static">
                            <Modal.Header>
                                <h5>{this.state.isEdit?"Edit Master":"Add New Master"}</h5>
                                <button 
                                    onClick={this.handleCreateDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                <div class="addnew-modal-form">
                                    {
                                        !this.state.isEdit &&
                                        <div class="addnew-modal-form-group">
                                            <p>Master Type*</p>
                                            <RegularDropdown 
                                                placeholder={this.state.masterType}
                                                dropdownArr={this.state.masterTypeArr}
                                                labelParam="masterType"
                                                onDropDownItemClick={this.masterTypeDropdownClick}
                                                isFocusRequired={true}
                                                defaultPlaceholderDropDown = {masterTypeDefaultPlaceholder}
                                            />
                                            {
                                                !isEmptyVariable(this.state.formErrors['masterType']) &&
                                                <span class="cm-error">{this.state.formErrors['masterType']}</span>
                                            }
                                        </div>
                                    }
                                    <div class="addnew-modal-form-group">
                                        <p>Short Name*</p>
                                        <input type="text" placeholder="Enter Short Name" 
                                        name="shortName" value={this.state.shortName} onChange={this.handleChange}/>
                                        {
                                            !isEmptyVariable(this.state.formErrors['shortName']) &&
                                            <span class="cm-error">{this.state.formErrors['shortName']}</span>
                                        }
                                    </div>
                                    <div class="addnew-modal-form-group">
                                        <p>Name*</p>
                                        <input type="text" placeholder="Enter Name" spellCheck="true" 
                                        name="name" value={this.state.name} onChange={this.handleChange}/>
                                        {
                                            !isEmptyVariable(this.state.formErrors['name']) &&
                                            <span class="cm-error">{this.state.formErrors['name']}</span>
                                        }
                                    </div>
                                    <div class="addnew-modal-form-group">
                                        <p>Description</p>
                                        <textarea type="text" placeholder="Enter Description" spellCheck="true" 
                                        name="description" value={this.state.description} onChange={this.handleChange}/>
                                        {
                                            !isEmptyVariable(this.state.formErrors['description']) &&
                                            <span class="cm-error">{this.state.formErrors['description']}</span>
                                        }
                                    </div>
                                    {
                                        // !this.state.isEdit &&
                                        this.state.masterType === valueAddition &&
                                        <div class="addnew-modal-form-group">
                                            <TreeNodeCheckBox
                                                style={{padding:"7px 0px",marginLeft:0}}
                                                themeSettings={themeSettings}>
                                                <input 
                                                    type="checkbox"
                                                    name="isNumeric"
                                                    id="isNumeric"
                                                    onChange={this.handleSelectionNumeric}
                                                    checked={this.state.isNumeric}
                                                />
                                                <label for="isNumeric"
                                                style={{marginLeft:6}}>is Numeric</label>
                                            </TreeNodeCheckBox>
                                        </div>
                                    }
                                    
                                </div>
                                <div class="modal-close-save">
                                    <div class="close-save-col">
                                        <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                                        <DialogSaveButton themeSettings={themeSettings} onClick={this.handleCreateMaster} type="button">Save</DialogSaveButton>
                                    </div>
                                </div>
                            </div>

                        </Modal>

                        <Modal className="custom-dialog" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
                            <Modal.Header>
                                <h5>{this.state.selectedItem.shortName}</h5>
                                <button 
                                    onClick={this.handleDetailsDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                <div className="details-table-layout">
                                    <div className="row custom-row">
                                        <div className="col-md-12 custom-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width={"24%"}>Master Type</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.masterType}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Short Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.shortName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Description</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{ifEmptyReturnEmptyStr(this.state.selectedItem.description,"-")}</td>
                                                    </tr>
                                                    {/* {
                                                        this.state.selectedItem.masterType === valueAddition &&
                                                        <tr>
                                                            <td width={"24%"}>Is Numeric</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.state.selectedItem.isNumeric === "Y"?"Yes":"No"}</td>
                                                        </tr>
                                                    } */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </section>
                </main>
            </Router>
        );
    }
}

export default GeneralMasters;