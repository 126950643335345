import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {AddNewButtonLayout} from '../../../common/Global/globalStyles.style';
import Sidebar from '../../../common/Sidebar';
import Topbar from '../../../common/Topbar';
import MasterMenuTabs from '../Common/topmenu';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../../common/AlertDialog';
import Pagination from '../../../common/Global/pagination';
import ScheduleAssignment from "./createScheduleAssignment"
import AssignAllResourcesDialog from "./assignAllResourcesDialog"
import UpdateReviewStatusDialog from './assignmentupdatereviewstatusdialog'
import ReviewHistoryDialog from '../../../common/reviewhistorydialog'
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable,sortTable,truncateString,
    GetOrderedMasterTabMenuPlanning,getStringWithSpaceAfterComma,
    isEmptyArray,getStatusSpan} from '../../../common/Global/commonFunctions';
import {getDropdownActionArrayScheduled} from '../../../common/Global/reviewFunctions';
import SelectCheckListDialog from "./selectChecklist"
import AddTasksandResources from "./addTaskandResources"
import AddTasksandResourcesChecklist from "./addTaskandResourcesChecklist"
import AddTasksandResourcesFunction from "./addTaskandResourcesFunction"
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import moment from "moment";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import ExcelJS from 'exceljs';
import { getScheduledAssignmentsInExcel } from "../Common/assignmentsExcelFunctions";
import { saveAs } from "file-saver";
import ReportDetails from "./reportDetails";
import { toast } from 'react-toastify';
import { merge } from '@syncfusion/ej2-base';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const sortCompanyName = "c.companyName";
const sortReferenceNo = "referenceNo";
const sortArea = "a.assignmentArea";
const sortDescription = "description";
const sortStartDate = "a.startDate";
const sortStatus = "a.assignmentStatus";
const sortTaskAssigned = "taskAssigned";
const sortLocation = "unitCityList";
const sortMasterType = "masterType";
const sortDeptName = "departmentName";
const sortAssignmentType = "assignmentTypeShortName";
const sortunitNameCityList = "unitNameCityList";
const sortunitAddressList = "unitAddressList";
const sortunitCityList ="unitCityList";

const companyDefaultPlaceholder = "Select Company";
const unitDefaultPlaceholder = "Select Units";
const departmentDropdownDefaultValue = "Select Department";

const assignmentTypeDefaultPlaceholder = "Select Assignment Type";
const departmentFilterDropdownDefaultValue = "All";

const activeStatus="Reviewed";
const activeStatusLabel="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";

const inProgressFilter="In Progress"
const plannedDateFilter="Planned Date"
const scheduledDateFilter="Scheduled Date"
const startAssignmentDateFilter="Start Assignment Date"
const startDateFilter="Start Date"
const endDateFilter="End Date"
const draftDateFilter="Draft Date"
const finalisedDateFilter="Finalised Date"



class OtherMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            status:activeStatus,
            deletePermission:"N",
            actionType:"",
            statusArr:[
                {
                    label:activeStatus,
                    displayLabel:activeStatusLabel,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    displayLabel:underReviewStatus,
                    isSelected:false
                },
                {
                    label:inActiveStatus,
                    displayLabel:inActiveStatus,
                    isSelected:false
                },
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            company:{},

            dateFilter:inProgressFilter,
            dateFilterPlaceholder:inProgressFilter,
            dateFilterArr:[
                {
                    label:inProgressFilter,
                    isSelected:true,
                },
                {
                    label:plannedDateFilter,
                    isSelected:false,
                },
                {
                    label:scheduledDateFilter,
                    isSelected:false,
                },
                {
                    label:startAssignmentDateFilter,
                    isSelected:false,
                },
                {
                    label:startDateFilter,
                    isSelected:false,
                },
                {
                    label:endDateFilter,
                    isSelected:false,
                },
                {
                    label:draftDateFilter,
                    isSelected:false,
                },
                {
                    label:finalisedDateFilter,
                    isSelected:false,
                },
            ],

            planDate:"",
            companies:[],
            selectedCompanyId:"",
            selectedCompanyPlaceholder:companyDefaultPlaceholder,
            
            units:[],
            companyUnits:[],
            selectedUnitIds:[],
            selectedUnitPlaceholder:unitDefaultPlaceholder,
            isUnitsDropdownEnabled:false,

            assignmentType:[],
            assignmentTypeFiltered:[],
            selectedAssignmentTypeId:"",
            selectedAssignmentTypePlaceholder:assignmentTypeDefaultPlaceholder,
            departments:[],
            departmentArr:[],
            selectedDepartmentName:departmentDropdownDefaultValue,
            selectedDepartmentId:"",
            assignmentArea:"",

            //Functions
            showSelectFunctionDialog:false,
            selectedFunctionObjArr:[],
            //Processes
            showSelectProcessDialog:false,
            selectedProcessObjArr:[],

            // formErrors:{},
            submitClickedAtleastOnce:false,

            valueAdditions:[],
            risks:[],
            unplannedReason:[],
            observationTemplates:[],
            execTaskTemplates:[],
            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            deleteReactivateMasterType:"",
            deleteReactivateName:"",
            reviewLevel:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,

            showDetailsDialog:false,
            selectedItem:"",

            showResourcesDialog:false,
            assignResourcesEdit:false,
            assignReportResourcesEdit:false,
            selectedAssignmentStatus:"",

            displayAddTasksComponent:false,
            displayAddTasksFunctionComponent:false,
            displayAddTasksChecklistComponent:false,
            checklistIdAlreadySaved:false,
            departmentIdForAddTask:"",

            showSelectChecklistFlag:false,
            selectedChecklist:"",
            changeChecklist:false,
            
            planFromDate:"",
            planToDate:"",
            isCustomDate:false,

            showSelectChecklistFlag:false,
            assignmentStartedFlag:false,
            reviewAssignmentTask:"",
            likelihoods: []
        }
        this.masterTypeFilterArr = [];
    }

    componentDidMount(){
        this.getInitData();
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getScheduledAssignmentList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getScheduledAssignmentList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag){
                this.getScheduledAssignmentList();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
        });
    }

    handleCreateDialogClose = (reloadFlag) =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedId:"",
            assignmentStartedFlag:false,
        },()=>{
            if(reloadFlag){
                this.getScheduledAssignmentList();
            }
        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    //TODO
    showCreateDialog = () => {
        this.setState({
            showCreateDialog:true,
            isEdit:false,
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.displayLabel;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getScheduledAssignmentList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getScheduledAssignmentList();
        })
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let id = "";
        let filterDeptArr = this.state.filterDeptArr;

        for(const [i,value] of filterDeptArr.entries()){
            if(i === selectedIndex){
                filterDeptArr[i].isSelected = true;
                placeholder = value.name;
                id = value.departmentId;
            }else{
                filterDeptArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo: 1,
            selectedFilterDepartmentName:placeholder,
            selectedFilterDepartmentId:id
        },() => {
            this.getScheduledAssignmentList();
        })
    }

    dateFilterDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.dateFilterArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            dateFilterArr:tempArr,
            dateFilter:item.label,
            dateFilterPlaceholder:placeholder
        }, () => {
            this.getScheduledAssignmentList();
        })
    }

    onDateFromChange = (date, dateString) => {
		let planFromDate = "";
		if (!isEmptyVariable(date)) {
			planFromDate = date.format("YYYY-MM-DD");
        }
        if(this.state.planToDate < planFromDate && !isEmptyVariable(this.state.planToDate)){
            toast.warning("From Date should not exceed To Date");
        }else{
            this.setState({
                planFromDate:planFromDate,
            },()=>{
                if(!isEmptyVariable(this.state.planFromDate) &&
                !isEmptyVariable(this.state.planToDate)){
                    this.getScheduledAssignmentList();
                }else if(isEmptyVariable(this.state.planFromDate) &&
                isEmptyVariable(this.state.planToDate)){
                    this.getScheduledAssignmentList();
                }
            })
        }
    };

    onDateToChange = (date, dateString) => {
		let planToDate = "";
		if (!isEmptyVariable(date)) {
			planToDate = date.format("YYYY-MM-DD");
        }
        if(planToDate < this.state.planFromDate){
            toast.warning("From Date should not exceed To Date");
        }else{
            this.setState({
                planToDate:planToDate,
            },()=>{
                if(!isEmptyVariable(this.state.planFromDate) &&
                !isEmptyVariable(this.state.planToDate)){
                    this.getScheduledAssignmentList();
                }else if(isEmptyVariable(this.state.planFromDate) &&
                isEmptyVariable(this.state.planToDate)){
                    this.getScheduledAssignmentList();
                }
            })
        }
    };

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Assignment",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete "+dropdownItem.referenceNo+" assignment?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Activate Master",
                proceedBtnLabel:"Reactivate",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate "+dropdownItem.referenceNo+" assignment?"
            })
        }else if(item.label === "Edit"){
            let assignmentStartedFlag= false;
    
            this.setState({
                showCreateDialog:true,
                isEdit:true,
                selectedItem:dropdownItem,
                assignmentStartedFlag:assignmentStartedFlag
            });
        }else if(item.label === "Submit For Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Submit For Review",
                proceedBtnLabel:"Submit",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to submit "+dropdownItem.referenceNo+" assignment for review?"
            })
        }else if(item.label === "Start Assignment"){
            if(dropdownItem.taskAssigned === "T" && dropdownItem.isResourceAssigned === 1 && dropdownItem.isReportResourceAssigned ===1){

                this.setState({
                    operationType:item.label,
                    alertDialogHeading:"Start Assignment",
                    proceedBtnLabel:"Start",
                    deleteReactivateId:dropdownItem.assignmentId,
                    showAlertDialog:true,
                    alertDialogMessage:"Are you sure you want to start "+dropdownItem.referenceNo+" assignment?"
                })
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Please assign Tasks, Resources and Report Resources before starting the Assignment",
                });
            }
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+dropdownItem.companyName+" assignment?"
            })
        }else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.assignmentId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.assignmentId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }else if(item.label === "Assign Tasks"){
            if(dropdownItem.assignmentArea === "CheckList"){
                if(isEmptyVariable(dropdownItem.checkList)){
                    //show checklist select dialog
                    this.setState({
                        showSelectChecklistFlag:true,
                        selectedChecklist:"",
                        departmentIdForAddTask:dropdownItem.departmentId,
                        deleteReactivateId:dropdownItem.assignmentId,
                        deleteReactivateName:dropdownItem.referenceNo,
                        checklistIdAlreadySaved:false,
                        changeChecklist:false
                        
                    })

                }else{
                    //if not empty then send the checklist version id
                    let checklistArr = dropdownItem.checkList.split(":-:");
                    this.setState({
                        displayAddTasksChecklistComponent:true,
                        deleteReactivateId:dropdownItem.assignmentId,
                        departmentIdForAddTask:dropdownItem.departmentId,
                        deleteReactivateName:dropdownItem.referenceNo,
                        selectedChecklistId:checklistArr[1],
                        checklistIdAlreadySaved:true,
                        changeChecklist:false
                    })
                }
            }else if(dropdownItem.assignmentArea === "Function"){
                this.setState({
                    displayAddTasksFunctionComponent:true,
                    deleteReactivateId:dropdownItem.assignmentId,
                    departmentIdForAddTask:dropdownItem.departmentId,
                    deleteReactivateName:dropdownItem.referenceNo,
                })
            }else{
                this.setState({
                    displayAddTasksComponent:true,
                    deleteReactivateId:dropdownItem.assignmentId,
                    departmentIdForAddTask:dropdownItem.departmentId,
                    deleteReactivateName:dropdownItem.referenceNo,
                })
            }
        }
        else if(item.label === "Change Checklist"){
            if(dropdownItem.assignmentArea === "CheckList" && !isEmptyVariable(dropdownItem.checkList) ){
                //show checklist select dialog
                let checklistArr = dropdownItem.checkList.split(":-:");
                this.setState({
                    showSelectChecklistFlag:true,
                    selectedChecklist:"",
                    departmentIdForAddTask:dropdownItem.departmentId,
                    deleteReactivateId:dropdownItem.assignmentId,
                    deleteReactivateName:dropdownItem.referenceNo,
                    checklistIdAlreadySaved:false,
                    selectedChecklistId:checklistArr[1],
                    changeChecklist:true,
                })
            }
        }else if(item.label === "Assign Resources"){
            this.setState({
                showResourcesDialog:true,
                assignResourcesEdit:dropdownItem.isResourceAssigned ===0?false:true,
                assignReportResourcesEdit:dropdownItem.isReportResourceAssigned ===0?false:true,
                deleteReactivateId:dropdownItem.assignmentId,
                selectedAssignmentStatus:dropdownItem.assignmentStatus,
                selectedObsTemplateId:dropdownItem.observationTemplateId,
                reviewAssignmentTask:dropdownItem.reviewAssignmentTask,
            })
        }else if(item.label === "Reassign Resources"){
            this.setState({
                showResourcesDialog:true,
                assignResourcesEdit:true,
                assignReportResourcesEdit:true,
                deleteReactivateId:dropdownItem.assignmentId,
                selectedAssignmentStatus:dropdownItem.assignmentStatus,
                selectedObsTemplateId:dropdownItem.observationTemplateId,
                reviewAssignmentTask:dropdownItem.reviewAssignmentTask,
            })
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.assignmentId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+dropdownItem.companyName+" assignment?",
                actionType: dropdownItem.previousStatus === "Updated"?"Update":(dropdownItem.previousStatus === "Deleted"?"Delete":"")
            })
        }
    }

    onChecklistSelectDialogClose = () => {
        this.setState({
            selectedChecklistId:"",
            displayAddTasksChecklistComponent:false,
            showSelectChecklistFlag:false,
            checklistIdAlreadySaved:false,
            deleteReactivateId:"",
            departmentIdForAddTask:"",
            deleteReactivateName:"",
            changeChecklist:false,
        },()=>{
            this.getScheduledAssignmentList();
        })
    }

    onSelectChecklistSaveClose = (checkListVersionId) => {
        //now show add task component, id and dept id are already added
        this.setState({
            selectedChecklistId:checkListVersionId,
            displayAddTasksChecklistComponent:true,
            showSelectChecklistFlag:false,
            changeChecklist:false,
        })
    }

    onClickBackBtnFromAddTasks = (reloadFlag) => {
        this.setState({
            displayAddTasksComponent:false,
            displayAddTasksChecklistComponent:false,
            displayAddTasksFunctionComponent:false,
            checklistIdAlreadySaved:false,
            deleteReactivateName:"",
            deleteReactivateId:"",
            departmentIdForAddTask:"",
        },()=>{
            if(reloadFlag) this.getScheduledAssignmentList();
        })
    }

    handleAllResourcesDialogClose = () => {
        this.setState({
            showResourcesDialog:false,
            assignResourcesEdit:false,
            deleteReactivateId:"",
            selectedAssignmentStatus:"",
            reviewAssignmentTask:"",
        },()=>{
            this.getScheduledAssignmentList();
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getScheduledAssignmentList();
        })
    }

    localDateExtractor=(date)=>{
        var date = new Date(date);
        return date.toLocaleDateString('en-GB')
    }

    removeCellBorder = (worksheet, cellName, removeArr,isBlack="N",isThin="Y") => {
        const mergedCell = worksheet.getCell(cellName);
        mergedCell.border = {}
      
        const borderStyles = ['top', 'left', 'bottom', 'right'];
      
        borderStyles.forEach((side) => {
          if (removeArr.includes(side)) {
            mergedCell.border[side] = { style:isThin=="y"?'thin':'medium', color: { argb: isBlack=="Y"?'0000000':'FFffffFF' } };
          }
        });
      
        return worksheet;
      };

      downloadAndConvertToBase64 = async (imageUrl) => {
        try {
          const response = await fetch(process.env.REACT_APP_BASE_URL_API+imageUrl);
          const blob = await response.blob();
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
        } catch (error) {
          console.error('Error downloading and converting to base64:', error);
        }
      };
      
      
      
    handleDownloadInExcelAssignmentList = async(downloadDatalist) =>{
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        worksheet.getColumn('A').width = 6;       
        worksheet.getColumn('B').width = 35;       
        worksheet.getColumn('C').width = 20;       
        worksheet.getColumn('D').width = 15;       
        worksheet.getColumn('E').width = 25;        
        worksheet.getColumn('F').width = 30;       
        worksheet.getColumn('G').width = 30;      
        worksheet.getColumn('H').width = 30;      

        if(downloadDatalist.length>0){
            let gorupSet2Name = downloadDatalist[0]['groupSetName2']
            if(gorupSet2Name){
                gorupSet2Name = gorupSet2Name.split("$-$")[1]
            }

            const uniqueUnitGroupList =[];
            const uniqueAuditCycleList = [];
            const allStartDateList =[];
            const allEndDateList =[];
            for(const item of downloadDatalist){
                if(!uniqueUnitGroupList.some(e=>e.unitGroupId==item.unitGroupId)){
                    uniqueUnitGroupList.push({
                        unitGroupId:item.unitGroupId, 
                        unitGroupName:item.unitGroupName
                    })
                }
                if(item.groupSet1){
                    const cycleNo =  parseInt(item.groupSet1.split("$-$")[1]);
                    if(!uniqueAuditCycleList.includes(cycleNo)){
                        uniqueAuditCycleList.push(cycleNo)
                    }
                }
                if(item.startDate && !allStartDateList.includes(item.startDate)){
                    allStartDateList.push(item.startDate)
                }
                if(item.endDate && !allEndDateList.includes(item.endDate)){
                    allEndDateList.push(item.endDate)
                }
            } 

            const startDateObjects = allStartDateList.map(dateString => new Date(dateString));
            const oldestStartDate = new Date(Math.min(...startDateObjects));
            const startMonthNames = new Intl.DateTimeFormat('en-US', { month: 'long' }).formatToParts(oldestStartDate).find(part => part.type === 'month').value;
            const startYear = new Intl.DateTimeFormat('en-US', { year: '2-digit' }).format(oldestStartDate);
            const formattedStartDate = `${startMonthNames}'${startYear}`;

            const endDateObjects = allEndDateList.map(dateString => new Date(dateString));
            const oldestEndDate = new Date(Math.max(...endDateObjects));
            const endMonthNames = new Intl.DateTimeFormat('en-US', { month: 'long' }).formatToParts(oldestEndDate).find(part => part.type === 'month').value;
            const endYear = new Intl.DateTimeFormat('en-US', { year: '2-digit' }).format(oldestEndDate);
            const formattedEndDate = `${endMonthNames}'${endYear}`;
            
              
            worksheet.addRow(["","","ISO 9001:2015 Internal Audit Program - Site","","","","Doc No.: QF 9001 16"])
            worksheet.addRow(["","",`Cycle No. ${uniqueAuditCycleList.join(", ")} `,"","","","Rev No.:0"])
            worksheet.addRow(["","",`Period - ${formattedStartDate} - ${formattedEndDate}`,"","","","Date: 17/01/2023"])

            worksheet.mergeCells('A1:B3')

            if(downloadDatalist[0].companyLogo){
                const imageBuffer = await  this.downloadAndConvertToBase64(downloadDatalist[0].companyLogo);
                const imageId = workbook.addImage({
                    base64: imageBuffer,
                    extension: downloadDatalist[0].companyLogo.split(".")[1], 
                    dimensions: {
                        width: 30, 
                        height: 50, 
                    },
                });
                worksheet.addImage(imageId,{
                    tl: { col: 0.5, row: 0.8 },
                    ext: { width: 120, height: 30 }
                  });
            }
            worksheet.mergeCells('C1:F1')
            worksheet.mergeCells('C2:F2')
            worksheet.mergeCells('C3:F3') 
            worksheet.mergeCells('H1:H3')

            this.removeCellBorder(worksheet,'C1',['bottom','left'])
            this.removeCellBorder(worksheet,'C2',['bottom','left'])
            this.removeCellBorder(worksheet,'C3',['left'])
            this.removeCellBorder(worksheet,'A1',['right'])
            this.removeCellBorder(worksheet,'G1',['bottom'])
            this.removeCellBorder(worksheet,'G2',['bottom'])
            
            worksheet.getColumn('A').alignment = { wrapText: true,horizontal: 'center', vertical:'middle'};
            worksheet.getColumn('B').alignment = {wrapText: true,horizontal: 'center', vertical:'middle' };
            worksheet.getColumn('C').alignment = { wrapText: true,horizontal: 'center',vertical:'middle' };
            worksheet.getColumn('D').alignment = { wrapText: true,horizontal: 'center',vertical:'middle' };
            worksheet.getColumn('E').alignment = { wrapText: true,horizontal: 'center',vertical:'middle' };
            worksheet.getColumn('F').alignment = { wrapText: true,horizontal: 'center',vertical:'middle' };
            worksheet.getColumn('G').alignment = { wrapText: true,horizontal: 'center',vertical:'middle' };
            worksheet.getColumn('H').alignment = { wrapText: true,horizontal: 'center',vertical:'middle' };

            worksheet.getCell('A1').alignment = {horizontal: 'left' };
            worksheet.getCell('H1').alignment = {horizontal: 'left' }; 
            worksheet.getCell('H2').alignment = {horizontal: 'left' }; 
            worksheet.getCell('H3').alignment = {horizontal: 'left' }; 

            let rowIndex=4;
            worksheet.addRow(['Sr.No.','Process / Project Name','Name of Auditee','Audit No.','Dates of Audit','Auditor','Trainee',gorupSet2Name])
            const row = worksheet.getRow(rowIndex);
            row.height = 25;
            rowIndex++;

            worksheet.getCell('A4').font = { bold: true };
            worksheet.getCell('B4').font = { bold: true };
            worksheet.getCell('C4').font = { bold: true };
            worksheet.getCell('D4').font = { bold: true };
            worksheet.getCell('E4').font = { bold: true };
            worksheet.getCell('F4').font = { bold: true };
            worksheet.getCell('G4').font = { bold: true };
            worksheet.getCell('C1').font = { bold: true };
            worksheet.getCell('C2').font = { bold: true };
            worksheet.getCell('C3').font = { bold: true };
            worksheet.getCell('H4').font = { bold: true };
            

            this.removeCellBorder(worksheet,'A4',['top','bottom','left'],"Y",'N')
            this.removeCellBorder(worksheet,'B4',['top','bottom'],"Y",'N')
            this.removeCellBorder(worksheet,'C4',['top','bottom'],"Y",'N',)
            this.removeCellBorder(worksheet,'D4',['top','bottom'],"Y",'N',)
            this.removeCellBorder(worksheet,'E4',['top','bottom'],"Y","N")
            this.removeCellBorder(worksheet,'F4',['top','bottom'],"Y","N",)
            this.removeCellBorder(worksheet,'G4',['top','bottom'],"Y","N")
            this.removeCellBorder(worksheet,'H4',['top','bottom','right'],"Y","N")


            let srNo =1;
            for(const item of uniqueUnitGroupList){
                worksheet.addRow([item.unitGroupName])
                worksheet.mergeCells(`A${rowIndex}:G${rowIndex}`)
                this.removeCellBorder(worksheet,`I${rowIndex}`,['left'],"Y","N",)
                worksheet.getCell(`A${rowIndex}`).alignment = {wrapText: true,horizontal: 'left',vertical:'middle' };
                worksheet.getCell(`A${rowIndex}`).font = { bold: true };
                const row = worksheet.getRow(rowIndex);
                row.height = 30;
                rowIndex++;
                const data = downloadDatalist.filter(e=>e.unitGroupId==item.unitGroupId) || [];
                for(const ele of data){
                    const unitName = `${ele.unitAddressList?.split(":-:")[0]} (${ele.unitList?.split(":-:")[0]})` || '';
                    const refNo = ele.referenceNo
                    const dateOfAudit = `${this.localDateExtractor(ele.startDate)} - ${this.localDateExtractor(ele.endDate)}`
                    const auditors = []
                    const viwers =[];   
                    for(var i=1;i<=5;i++){
                        if(ele[`L${i}UserName`]){
                            auditors.push(ele[`L${i}UserName`])
                        }
                    }
                    if(ele.memberList){
                        const memberData = ele.memberList.split(",") || [];
                        for(const i of memberData){
                            auditors.push(i.split(":")[1])
                        }
                    }
                    if(ele.viewerList){
                        const memberData = ele.viewerList.split(",") || [];
                        for(const i of memberData){
                            viwers.push(i.split(":")[1])
                        }
                    }

                    let tmpGrouSet2Value = ''
                    if(item.groupSetName2){
                        tmpGrouSet2Value= item.groupSetName2.split("$-$")[0];
                    }
                    
                    worksheet.addRow([srNo,unitName,"NA",refNo,dateOfAudit,auditors.join(", "),viwers.join(", "),tmpGrouSet2Value])
                    this.removeCellBorder(worksheet,`I${rowIndex}`,['left'],"Y","N",)
                    srNo++;
                    rowIndex++;
                }

            }

            worksheet.addRow();
            this.removeCellBorder(worksheet,`I${rowIndex}`,['left'],"Y","N",)
            rowIndex++;
           
            worksheet.addRow(["NOTE: All HOD's are requested to adhere to the Audit Scheduled."])
            worksheet.getCell(`A${rowIndex}`).alignment = {horizontal: 'left' };
            worksheet.getCell(`A${rowIndex}`).font = {bold: true };
            this.removeCellBorder(worksheet,`I${rowIndex}`,['left'],"Y","N",)
            worksheet.mergeCells(`A${rowIndex}:C${rowIndex}`)
            rowIndex++;

            worksheet.addRow(["","","","NOTE:","P","PHYSICAL AUDIT","P/R STANDS FOR BOTH OPTIONS"])
            worksheet.getCell(`D${rowIndex}`).alignment = {horizontal: 'right' };
            this.removeCellBorder(worksheet,`I${rowIndex}`,['left'],"Y","N",)
            this.removeCellBorder(worksheet,`E${rowIndex}`,['left','right','bottom','top'],"Y")
            this.removeCellBorder(worksheet,`F${rowIndex}`,['right','bottom','top'],"Y")
            this.removeCellBorder(worksheet,`G${rowIndex}`,['top','right'],"Y")
            worksheet.getCell(`D${rowIndex}`).font = {bold: true };
            rowIndex++;
            
            worksheet.addRow(["","","","","R","REMOTE AUDIT","AVAILABLE FOR AUDIT"])
            this.removeCellBorder(worksheet,`I${rowIndex}`,['left'],"Y","N",)
            this.removeCellBorder(worksheet,`E${rowIndex}`,['left','right','bottom'],"Y")
            this.removeCellBorder(worksheet,`F${rowIndex}`,['right','bottom'],"Y")
            this.removeCellBorder(worksheet,`G${rowIndex}`,['bottom','right'],"Y")
            rowIndex++;
            
            worksheet.addRow(["","","","","IA","IMPLEMENTATION AUDIT"])
            this.removeCellBorder(worksheet,`I${rowIndex}`,['left'],"Y","N",)
            this.removeCellBorder(worksheet,`E${rowIndex}`,['left','right','bottom'],"Y")
            this.removeCellBorder(worksheet,`F${rowIndex}`,['right','bottom'],"Y")
            rowIndex++;
            
            worksheet.addRow(["Giridhar R","","","","CA","CLOSURE AUDIT"])
            worksheet.mergeCells(`A${rowIndex}:B${rowIndex}`)
            worksheet.getCell(`A${rowIndex}`).alignment = {horizontal: 'left' };
            this.removeCellBorder(worksheet,`I${rowIndex}`,['left'],"Y","N",)
            worksheet.getCell(`A${rowIndex}`).font = {bold: true };
            this.removeCellBorder(worksheet,`E${rowIndex}`,['left','right','bottom'],"Y")
            this.removeCellBorder(worksheet,`F${rowIndex}`,['right','bottom'],"Y")
            rowIndex++;

            worksheet.addRow(["Management Representative - ISO 9001:2015","","","","NA","NOT APPLICABLE"])
            worksheet.mergeCells(`A${rowIndex}:B${rowIndex}`)
            worksheet.getCell(`A${rowIndex}`).alignment = {horizontal: 'left' };
            this.removeCellBorder(worksheet,`I${rowIndex}`,['left'],"Y","N",)
            worksheet.getCell(`A${rowIndex}`).font = {bold: true };
            

            this.removeCellBorder(worksheet,`A${rowIndex}`,['bottom'],"Y","N",)
            this.removeCellBorder(worksheet,`B${rowIndex}`,['bottom'],"Y","N",)
            this.removeCellBorder(worksheet,`C${rowIndex}`,['bottom'],"Y","N",)
            this.removeCellBorder(worksheet,`D${rowIndex}`,['bottom'],"Y","N",)
            this.removeCellBorder(worksheet,`E${rowIndex}`,['bottom','left','right'],"Y","N",)
            this.removeCellBorder(worksheet,`F${rowIndex}`,['bottom','right'],"Y","N",)
            this.removeCellBorder(worksheet,`G${rowIndex}`,['bottom'],"Y","N",)
            this.removeCellBorder(worksheet,`H${rowIndex}`,['bottom'],"Y","N",)
            rowIndex++;
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Scheduled.xlsx'; 
        link.click();
    }

    // handleDownloadInExcelAssignmentList = (downloadDatalist) => {
    //     const workSheetName = 'Scheduled Assignments';
	// 	const workSheetName = 'Scheduled Assignments';
	// 	const workbook = new Excel.Workbook();

    //     let excelDataList = getScheduledAssignmentsInExcel(downloadDatalist);

    //     try{
    //         const fileName = workBookName;
	// 		// creating one worksheet in workbook
	// 		const worksheet = workbook.addWorksheet(workSheetName);

    //         //add table
    //         worksheet.addTable(
    //             {
    //                 name:"table",
    //                 ref:`A1`, //starting position of table
    //                 headerRow: true,
    //                 style: {
    //                     // theme: 'TableStyleDark3',
    //                     showRowStripes: true,
    //                     showFirstColumn: true
    //                 },
    //                 columns:excelDataList.tableCols,
    //                 rows: excelDataList.tableValue
    //             }
    //         );
    //         //find max string length of column and assign it to column width
	// 		worksheet.columns.forEach((column) => {
	// 			let maxColumnLength = 0;
	// 			column.eachCell({ includeEmpty: true }, (cell) => {
	// 				maxColumnLength = Math.max(
	// 					maxColumnLength,
	// 					10,
	// 					cell.value ? (cell.value.toString().length>75?75:cell.value.toString().length) : 0
	// 				);
	// 			});
	// 			column.width = maxColumnLength + 2;
	// 		});
    //         // write the content using writeBuffer
	// 		workbook.xlsx.writeBuffer().then((buffer)=>{
	// 			saveAs(new Blob([buffer]), `Assignments.xlsx`);
	// 		});

    //     }catch(error){
    //         console.error("Error: ",error)
    //     }finally{

    //     }
    // }

    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetAssignmentCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetScheduledAssignments,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    filterDateType:this.state.dateFilter
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,generalRes,menuRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,generalRes,menuRes]) => {
            let companies = [];
            let unitsTemp = [];
            let units = [];
            let assignmentType = [];
            let datalistTemp = [];
            let totalCountTemp = 0;
            let menusTemp = {};
            let companyTemp = {};
            let deptTemp = [];
            let valueAdditions = [];
            let risks = [];
            let unplannedReason = [];
            let observationTemplates = [];
            let execTaskTemplates = [];
            let allSettings = [];
            let departments = [];
            let assignmentGroups = {};
            let likelihoods = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    companies = masterRes.data.companies;
                    unitsTemp = masterRes.data.units;
                    assignmentType = masterRes.data.assignmentType;
                    deptTemp = masterRes.data.departments;
                    valueAdditions = masterRes.data.valueAdditions;
                    risks = masterRes.data.risks;
                    unplannedReason = masterRes.data.unplannedReason;
                    observationTemplates = masterRes.data.observationTemplates;
                    execTaskTemplates = masterRes.data.executionTemplates;
                    assignmentGroups = masterRes.data.assignmentGroups;
                    likelihoods = masterRes.data.likelihoods;
            }else{
            }

            units = unitsTemp.map(item=>{
                item.unitTypeCity = item.unitType+", "+item.city+"";
                return item;
            })
            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
                allSettings = menuRes.data.allSettings;
                departments = menuRes.data.departments;
                companyTemp = menuRes.data.company;
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenuPlanning(menusTemp);
            let deletePermission = "N";

            if(menusTemp["Delete Scheduled Assignment"] === "ALL"){
                deletePermission = "Y";
            }

            //if user belongs to single dept then dont show the dropdown
            let selectedDepartmentId = "";
            let selectedDepartmentName = departmentDropdownDefaultValue;
            if(!isEmptyArray(deptTemp) && deptTemp.length===1){
                selectedDepartmentId = deptTemp[0].departmentId;
                selectedDepartmentName = deptTemp[0].name;
            }

            //Filter Dept array
            let filterDeptArr = [];
            let selectedFilterDepartmentId = "-100";
            let selectedFilterDepartmentName = "All";
            if(!isEmptyArray(deptTemp) && deptTemp.length===1){

            }else{
                let obj = {
                    departmentId:"-100",
                    name:departmentFilterDropdownDefaultValue,
                    isSelected:true
                }
                filterDeptArr = [...deptTemp];
                filterDeptArr.splice(0,0,obj);
            }

            
            this.setState({
                componentDidMountFlag:true,
                showLoader:false,
                valueAdditions:valueAdditions,
                risks:risks,
                unplannedReason:unplannedReason,
                observationTemplates:observationTemplates,
                execTaskTemplates:execTaskTemplates,
                companies:companies,
                units:units,
                assignmentType:assignmentType,
                deletePermission:deletePermission,

                datalist:datalistTemp,
                totalCount:totalCountTemp,
                menus:menusTemp2,
                company:companyTemp,
                departmentArr:deptTemp, 
                allSettings:allSettings,
                departments:departments,
                assignmentGroups:assignmentGroups, 

                filterDeptArr:filterDeptArr,
                selectedFilterDepartmentId:selectedFilterDepartmentId,
                selectedFilterDepartmentName:selectedFilterDepartmentName,

                selectedDepartmentId:selectedDepartmentId,
                selectedDepartmentName:selectedDepartmentName,
                likelihoods: likelihoods
            })
        })
    }

    getScheduledAssignmentList = () => {
        this.setState({
            showLoader:true,
        });

        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
            filterDateType:this.state.dateFilter,
        }

        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }

        if(!isEmptyVariable(this.state.planFromDate) &&
        !isEmptyVariable(this.state.planToDate)){
            postParam.startDate = this.state.planFromDate;
            postParam.endDate = this.state.planToDate;
        }

        fetch(Constants.GetScheduledAssignments,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let isCustomDate = false;
                if(!isEmptyVariable(this.state.planFromDate) &&
                !isEmptyVariable(this.state.planToDate)){
                    isCustomDate = true;
                }

                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                    isCustomDate:isCustomDate,
                });
            }else{
                let isCustomDate = false;
                if(!isEmptyVariable(this.state.planFromDate) &&
                !isEmptyVariable(this.state.planToDate)){
                    isCustomDate = true;
                }
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                    isCustomDate:isCustomDate,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            let postParam = {
                email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    filterDateType:this.state.dateFilter,
            }
    
            if(this.state.filterDeptArr.length > 1 &&
                this.state.selectedFilterDepartmentName !== "All"){
                postParam.departmentId = this.state.selectedFilterDepartmentId;
            }
            if(!isEmptyVariable(this.state.planFromDate) &&
            !isEmptyVariable(this.state.planToDate)){
                postParam.startDate = this.state.planFromDate;
                postParam.endDate = this.state.planToDate;
            }

            fetch(Constants.GetScheduledAssignments,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    let isCustomDate = false;
                    if(!isEmptyVariable(this.state.planFromDate) &&
                    !isEmptyVariable(this.state.planToDate)){
                        isCustomDate = true;
                    }
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                        isCustomDate:isCustomDate
                    });
                }else{
                    let isCustomDate = false;
                    if(!isEmptyVariable(this.state.planFromDate) &&
                    !isEmptyVariable(this.state.planToDate)){
                        isCustomDate = true;
                    }
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                        isCustomDate:isCustomDate
                    });
                }
            });
        }
    }
    
    getAssignmentListForDownload = () => {

        this.setState({
            showLoader:true,
        });
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:500,
            status:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
            filterDateType:this.state.dateFilter,
        }
        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }

        if(!isEmptyVariable(this.state.planFromDate) &&
        !isEmptyVariable(this.state.planToDate)){
            postParam.startDate = this.state.planFromDate;
            postParam.endDate = this.state.planToDate;
        }

        fetch(Constants.GetScheduledAssignments,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    
                    let downloadData = data.data.result;
                    if(!isEmptyArray(downloadData)){
                        this.handleDownloadInExcelAssignmentList(downloadData);
                    }else{
                        toast.warning("No records found.")
                    }

                    this.setState({
                        showLoader:false,
                        // datalist:data.data.result,
                        // totalCount:data.data.count,
                    });
                }else{
                    
                    // this.setState({
                    //     datalist:[],
                    //     showLoader:false,
                    //     totalCount:0,
                    // });
                }
            });

    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            assignmentId:this.state.deleteReactivateId
        }
        let callApi = true;

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteAssignment;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ReactivateAssignment;
        }else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitAssignmentforReview;
        }else if(this.state.operationType === "Start Assignment"){
            url = Constants.StartAssignment;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardChangeScheduledAssignment;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.updateReviewStatusAssignments;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Review Remarks"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
            })
        }

        if(callApi){
            this.setState({
                showLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                    },() => {
                        this.getScheduledAssignmentList();
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                            
                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar  />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                    allSettings={this.state.allSettings}
                                    departments={this.state.departments}
                                />
                                {
                                    this.state.showLoader &&
                                    <div className="loading-layout"
                                    style={{background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`}}>
                                        <div className="loading-text-layout">
                                            <p
                                            style={{color:`rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`}}
                                            >Loading....</p>
                                        </div>
                                    </div>
                                }
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    !this.state.displayAddTasksComponent &&
                                    !this.state.displayAddTasksFunctionComponent &&
                                    !this.state.displayAddTasksChecklistComponent &&
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        mleft={0}
                                                    />
                                                    <DatePicker
                                                        onChange={this.onDateFromChange}
                                                        format={"DD/MM/YYYY"}
                                                        placeholder="From Date"
                                                        style={{marginLeft:10}}
                                                        value={!isEmptyVariable(this.state.planFromDate) ? moment(this.state.planFromDate) : ""}
                                                    />

                                                    <DatePicker
                                                        onChange={this.onDateToChange}
                                                        format={"DD/MM/YYYY"}
                                                        style={{marginLeft:10}}
                                                        placeholder="To Date"
                                                        value={!isEmptyVariable(this.state.planToDate) ? moment(this.state.planToDate) : ""}
                                                    />
                                                    {/* <div className="search-col">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                        themeSettings={themeSettings}
                                                        onClick = {this.onSearchIconClick} 
                                                        className="material-icons"> search </SearchSpan>
                                                    </div> */}

                                                    <FilterDropDown
                                                        placeholder={this.state.dateFilterPlaceholder}
                                                        dropdownArr={this.state.dateFilterArr}
                                                        onDropDownItemClick={this.dateFilterDropdownClick}
                                                        themeSettings={themeSettings}
                                                        name={"label"}
                                                    />
                                                    
                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        themeSettings={themeSettings}
                                                        name={"displayLabel"}
                                                    />
                                                    {
                                                        this.state.filterDeptArr.length > 0 &&
                                                        <FilterDropDown
                                                            placeholder={this.state.selectedFilterDepartmentName}
                                                            dropdownArr={this.state.filterDeptArr}
                                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                                            name={"name"}
                                                            themeSettings={themeSettings}
                                                            dropdownWidth={"120px"}
                                                        />
                                                    }
                                                </div>
                                                <div className="flex-center-layout">
                                                    <AddNewButtonLayout themeSettings={themeSettings}>
                                                        <a onClick={this.getAssignmentListForDownload}>
                                                            <span class="material-icons" style={{fontSize:15}}> download </span>
                                                        </a>
                                                    </AddNewButtonLayout>
                                                    {
                                                        this.state.menus[Constants.SCREEN_SCHEDULED_ASSIGNMENT_ALL] ==="ALL" && (
                                                            <AddNewButtonLayout themeSettings={themeSettings} style={{marginLeft:15}}>
                                                                <a onClick={this.showCreateDialog}>
                                                                    <span className="material-icons mr-1">add_circle</span>
                                                                    <p>Add New</p>
                                                                </a>
                                                            </AddNewButtonLayout>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortReferenceNo)}>
                                                                <div className="sort-header">Ref No.
                                                                <span className={(this.state.sort === sortReferenceNo)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {/* {
                                                                this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortLocation)}>
                                                                    <div className="sort-header">Location
                                                                    <span className={(this.state.sort === sortLocation)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                    </div>
                                                                </th>
                                                            } */}
                                                            {
                                                                this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortCompanyName)}>
                                                                    <div className="sort-header">Client
                                                                    <span className={(this.state.sort === sortCompanyName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                    </div>
                                                                </th>
                                                            }
                                                            {/* <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortMasterType)}> */}
                                                            <th >
                                                                <div className="sort-header"  onClick={this.sortTableLocal.bind(this,sortunitNameCityList)}>Unit
                                                                <span className={(this.state.sort === sortunitNameCityList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {
                                                                this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                           <th >
                                                                <div className="sort-header" onClick={this.sortTableLocal.bind(this,sortunitAddressList)}>Name
                                                                <span className={(this.state.sort === sortunitAddressList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
    
                                                            }
                                                             {
                                                                this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                           <th >
                                                                <div className="sort-header" onClick={this.sortTableLocal.bind(this,sortunitCityList)}>City
                                                                <span className={(this.state.sort === sortunitCityList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
    
                                                            }
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortAssignmentType)}>
                                                                <div className="sort-header">Type
                                                                <span className={(this.state.sort === sortAssignmentType)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortArea)}>
                                                                <div className="sort-header">Scope
                                                                <span className={(this.state.sort === sortArea)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortStartDate)}>
                                                                <div className="sort-header">Start Date
                                                                <span className={(this.state.sort === sortStartDate)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortStatus)}>
                                                                <div className="sort-header">Status
                                                                <span className={(this.state.sort === sortStatus)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortTaskAssigned)}>
                                                                <div className="sort-header">Tasks
                                                                <span className={(this.state.sort === sortTaskAssigned)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {/* <th width={"10%"}>Users Count</th> */}
                                                            <th className="text-center"  width={"5%"}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {

                                                                let actionObj = getDropdownActionArrayScheduled(item.allowEdit, item.allowReview, 
                                                                    item.status, item.currentReviewStatus, "Functions", item.isResourceAssigned,
                                                                    item.isReportResourceAssigned,item.startAssignment, item.assignmentStatus,
                                                                    item.assignmentArea,item.checkList, this.state.deletePermission, item.previousStatus);
                                                                let spanObj = getStatusSpan(item.previousStatus,themeSettings);

                                                                let tempScop = [];
                                                                if(!isEmptyVariable(item.functionList)){
                                                                    tempScop = item.functionList?.split(",").map((func) => func.split(":-:")[0]);
                                                                }else if(!isEmptyVariable(item.processList)){
                                                                    console.log("true..")
                                                                    tempScop = item.processList.split(",").map((func) => func.split(":-:")[0]);
                                                                }	
                                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    <td>
                                                                        {item.referenceNo}
                                                                        {
                                                                            this.state.status === "Under Review" &&
                                                                            <span style={spanObj.spanStyle} className="span-tag">
                                                                                {spanObj.spanText}
                                                                            </span>
                                                                        }  
                                                                    </td>
                                                                
                                                                    {
                                                                        this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <td>{item.companyName}</td>
                                                                    }
                                                                    <td>
                                                                        {
                                                                            truncateString(String(getStringWithSpaceAfterComma(item.unitNameCityList)).split("(")[0],
                                                                            Constants.OTHERMASTERS_STRING_LENGTH)
                                                                        }
                                                                    </td>
                                                                   {   this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && <td>
                                                                    {isEmptyVariable(item.unitAddressList)? "-": String(item.unitAddressList).split(":-:")[0]}
                                                                    </td>}
                                                                    {   this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && <td>
                                                                    {isEmptyVariable(item.unitCityList)? "-": item.unitCityList}
                                                                    </td>}
                                                                    <td>{item.assignmentTypeShortName}</td>
                                                                    <td>{isEmptyArray(tempScop)?"-":tempScop.length==1?tempScop[0]:`${tempScop[0]} + ${tempScop.length-1}`}</td>
                                                                    <td>{moment(item.startDate).format('DD/MM/YYYY')}</td>
                                                                    <td>{item.assignmentStatus}</td>
                                                                    <td className="assignment-tick-mark">
                                                                        {item.taskAssigned==="T"
                                                                            ?<span class="material-icons-outlined">done</span>
                                                                            :<span class="material-icons">close</span>
                                                                        }
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {
                                                                            actionObj.actionArr && actionObj.actionArr.length > 0 &&
                                                                            <TableDropDown 
                                                                                actionArr={actionObj.actionArr}
                                                                                onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                                dropDownId = {item}
                                                                                dropdownWidth={actionObj.dropdownWidth}
                                                                                themeSettings={themeSettings}
                                                                            />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings={themeSettings}/>
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.displayAddTasksComponent &&
                                    <AddTasksandResources
                                        assignmentId= {this.state.deleteReactivateId}
                                        departmentId= {this.state.departmentIdForAddTask}
                                        referenceNo= {this.state.deleteReactivateName}
                                        onClickBackBtnFromAddTasks= {this.onClickBackBtnFromAddTasks}
                                    />
                                }
                                {
                                    this.state.displayAddTasksFunctionComponent &&
                                    <AddTasksandResourcesFunction
                                        assignmentId= {this.state.deleteReactivateId}
                                        departmentId= {this.state.departmentIdForAddTask}
                                        referenceNo= {this.state.deleteReactivateName}
                                        onClickBackBtnFromAddTasks= {this.onClickBackBtnFromAddTasks}
                                    />
                                }
                                {
                                    this.state.displayAddTasksChecklistComponent &&
                                    <AddTasksandResourcesChecklist
                                        assignmentId= {this.state.deleteReactivateId}
                                        departmentId= {this.state.departmentIdForAddTask}
                                        referenceNo= {this.state.deleteReactivateName}
                                        checkListVersionId= {this.state.selectedChecklistId}
                                        checklistIdAlreadySaved= {this.state.checklistIdAlreadySaved}
                                        onClickBackBtnFromAddTasks= {this.onClickBackBtnFromAddTasks}
                                    />
                                }
                            </div>
                        </div>
                        
                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                (this.state.operationType === "Review Remarks")
                                ?
                                Constants.ALERT_TYPE_ALERT
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            themeSettings={themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

                        <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {"Schedule Assignment"}
                            masterDataId = {this.state.deleteReactivateId}
                        />
                        {/* cdsh */}
                        <ScheduleAssignment
                            showCreateDialog = {this.state.showCreateDialog}
                            handleCreateDialogClose = {this.handleCreateDialogClose}
                            departmentArr = {this.state.departmentArr}
                            valueAdditions = {this.state.valueAdditions}
                            risks = {this.state.risks}
                            unplannedReason = {this.state.unplannedReason}
                            observationTemplates = {this.state.observationTemplates}
                            execTaskTemplates = {this.state.execTaskTemplates}
                            companies = {this.state.companies}
                            units = {this.state.units}
                            assignmentType = {this.state.assignmentType}
                            selectedDepartmentName = {this.state.selectedDepartmentName}
                            selectedDepartmentId = {this.state.selectedDepartmentId}
                            themeSettings = {themeSettings}
                            isEdit={this.state.isEdit}
                            selectedItem={this.state.selectedItem}
                            company={this.state.company}
                            assignmentStartedFlag = {this.state.assignmentStartedFlag}
                            allSettings = {this.state.allSettings}
                            departments = {this.state.departments}
                            assignmentGroups = {this.state.assignmentGroups}
                            likelihoods={this.state.likelihoods}
                        />

                        <AssignAllResourcesDialog
                            showResourcesDialog = {this.state.showResourcesDialog}
                            handleAllResourcesDialogClose = {this.handleAllResourcesDialogClose}
                            assignmentId = {this.state.deleteReactivateId}
                            themeSettings = {themeSettings}
                            assignResourcesEdit= {this.state.assignResourcesEdit}
                            assignReportResourcesEdit= {this.state.assignReportResourcesEdit}
                            selectedAssignmentStatus={this.state.selectedAssignmentStatus}
                            selectedObsTemplateId={this.state.selectedObsTemplateId}
                            reviewAssignmentTask={this.state.reviewAssignmentTask}
                        />

                        <SelectCheckListDialog
                            showSelectChecklistFlag={this.state.showSelectChecklistFlag}
                            departmentId= {this.state.departmentIdForAddTask}
                            onChecklistSelectDialogClose = {this.onChecklistSelectDialogClose}
                            onSelectChecklistSaveClose = {this.onSelectChecklistSaveClose}
                            themeSettings={themeSettings}
                            selectedChecklistId={this.state.selectedChecklistId}
                            isEdit={this.state.changeChecklist}
                            assignmentId={this.state.deleteReactivateId}
                        />

                        <ReportDetails
                            showDetailsDialog={this.state.showDetailsDialog}
                            handleDetailsDialogClose={this.handleDetailsDialogClose}
                            selectedItem={this.state.selectedItem}
                            themeSettings={this.props.themeSettings}
                            company={this.props.company}
                            isDraftOnward={this.props.isDraftOnward}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}

export default OtherMasters;