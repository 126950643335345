import React, { Component } from 'react'
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,getStatusSpan,isEmptyVariable, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import TreeNodeDropdown from '../../common/DropdownMenus/TreeNodeDropdown';
import AddGroupingDialog from './addrootgroupingdialog';
import AlertWithRadioDialog from '../../common/AlertDialog/alertwithradio';
import {ExpandCollapseWrapper} from '../../common/Global/globalStyles.style';

import AddUnitGroupingDialog from './addunitgroupingdialog';
import {
   
    FilterCheckBox,
  } from "../../common/Global/globalStyles.style";
const userDetails  = getLocalStorageVariables();
const activeStatus="Active";
const createdStatus="Created";
const updatedStatus="Updated";
const deletedStatus="Deleted";
const underReviewStatus="Under Review";
const AddChildDropdownObj = {
    icon:"add_circle",
    label:"Add Child"
}
const AddSiblingDropdownObj = {
    icon:"add_box",
    label:"Add Sibling"
}
const editDropdownObj = {
    icon:"edit",
    label:"Edit"
}
const delDropdownObj = {
    icon:"delete",
    label:"Delete"
}

const AddUnitDropdownObj = {
    icon:"account_box",
    label:"Add Unit"
}


const AddClientDropdownObj = {
    icon:"account_box",
    label:"Add Client"
}
export default class GroupingTreeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {

            showLoader:false,

            groupingMap:{},
            actionArr:[],
            syncFusionData:[],

            showCreateDialog:false,
            isEdit:false,
            createNodeType:"",
            selectedId:"",
            groupingName_new:"",
            relativeGroupingId:"",
            clearAndResetGroupingTree:true,
            idToBeExpandedAfterAddingChild:"",

            operationType:"",
            deleteReactivateId:"",
            showAlertWithRadioDialog:false,
            alertWithRadioDialogMessage:"",
            showRadio:true,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",
            showUnitDialog:false,

            isUnitCheckboxSelcted:"N",
           
        }
        this.fields = {};
        this.groupingObj = {};
        this.reloadListing = false;
    }

    componentDidUpdate(prevProps)
    {
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showGroupingTreeDialog){
            this.fields = {
                dataSource:[],
                id:"groupingId",
                text:"groupingName_new",
                parentID:"parentId",
                child:"newChildren",
                expanded:"isExpanded"
            };

            this.setState({
                showLoader:false,
                
                groupingMap:{},
                actionArr:[],
                syncFusionData:[],

                showCreateDialog:false,
                isEdit:false,
                createNodeType:"",
                selectedId:"",
                groupingName_new:"",
                relativeGroupingId:"",
                clearAndResetGroupingTree:true,
                idToBeExpandedAfterAddingChild:"",

                operationType:"",
                deleteReactivateId:"",
                showAlertWithRadioDialog:false,
                alertWithRadioDialogMessage:"",

                showAlertDialogInfo:false,
                alertDialogMessageInfo:"",

                isUnitCheckboxSelcted:"N",

            },()=>{
                this.getGroupingTree();
            })
            
        }
    }

    createSyncHRFusionData = (parentId,unitGroupId,groupingMap,isExpanded) => {
        //Now create an object
        let groupingObj = groupingMap[unitGroupId];
        groupingObj.newChildren = [];
        groupingObj.isExpanded = isExpanded;
        if(parentId !== unitGroupId){
            groupingObj.parentId = parentId;
        }
        //now add children recursively
        groupingObj.children.map((item)=>{
            groupingObj.newChildren.push(this.createSyncHRFusionData(unitGroupId,item.unitGroupId,groupingMap,false));
        });
        return groupingObj;
    }

    updateSyncHRFusionData = (parentId,unitGroupId,groupingMap) => {
        //Now create an object
        let groupingObj = groupingMap[unitGroupId];
        groupingObj.newChildren = [];

        //Check the old function map
        if(!isEmptyVariable(this.state.groupingMap[unitGroupId])){
            groupingObj.isExpanded = this.state.groupingMap[unitGroupId].isExpanded;
            groupingMap[unitGroupId].isExpanded = this.state.groupingMap[unitGroupId].isExpanded;
        }

        if(parentId !== unitGroupId){
            groupingObj.parentId = parentId;
        }
        //now add children recursively
        groupingObj.children.map((item)=>{
            groupingObj.newChildren.push(this.updateSyncHRFusionData(unitGroupId,item.unitGroupId,groupingMap));
        });
        return groupingObj;
    }
    
      //No need of return value since the array itself is passed and updated.
    //Javascript maintains single array with pointers. it will not deep copy the array
    addParentIdandHasChildrenFlag = (tempArr,unitGroupId, level) => {

        tempArr[unitGroupId].level = level;

        if(!isEmptyArray(tempArr[unitGroupId].children)){
            tempArr[unitGroupId].children.map(childNode => {
                this.addParentIdandHasChildrenFlag(tempArr,childNode.unitGroupId,level+1);
            });
        }else{
            tempArr[unitGroupId].hasChildren = false;
        }
    }

    moveTask = (draggedNodeId,draggedNodePid,droppedNodePid,droppedPos) => {
        fetch(Constants.MoveGrouping,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                rootUnitGroupId:this.props.rootGroupingId,
                unitGroupId:draggedNodeId,
                fromUnitGroupId:draggedNodePid,
                toUnitGroupId:droppedNodePid,
                toChildOrder:droppedPos,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            } else {
                this.reloadListing = true;
                this.getGroupingTree();
            }
        });
    }


    getRootLevelgrouping = (groupingMapTemp) => {
        let rootGroupingArr = [];
        Object.values(groupingMapTemp).map((item)=>{
            //this is backend level, it starts from 0, but front end level starts from 1
            if(item.level === 0){
                rootGroupingArr.push(item);
            }
        })
        return rootGroupingArr;
    }


    getGroupingTree = () =>{
        this.setState({
            showLoader:true
        });
        fetch(Constants.GetGroupingSubtree,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    rootunitGroupId:this.props.rootGroupingId,
                    newFlag:this.props.newFlag,
                    isShowUnitSelcted:this.state.isUnitCheckboxSelcted,
                    isClient:this.props.isClient

                })
            })
            .then(response => { return response.json(); } ).
            then(data => {
                
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }
                else if(data.responseCode === Constants.CODE_SUCCESS){
                    let temp = data.result.unitGroupMap;

                    let rootGroupingArr = this.getRootLevelgrouping(temp);
    
                    rootGroupingArr.map((rootNode)=>{
                        this.addParentIdandHasChildrenFlag(temp,rootNode.unitGroupId,1)
                    })
                    let syncFusionData = [];
                    //clearAndResetGroupingTree - if it is false, then copy the isShowingChildren param 
                   //from old state array, this is required because whenever the user adds a new node at the nth level
                   //all the nodes are collapsed and shows only first level nodes since the API is called again

                    if(!isEmptyArray(Object.keys(this.state.groupingMap)) && 
                    !this.state.clearAndResetGroupingTree){
                        let groupingObj = this.updateSyncHRFusionData(this.props.rootGroupingId,this.props.rootGroupingId,temp);
                        groupingObj.fontbold = true;
                        syncFusionData.push(groupingObj);
                    }else{
                        //create datastructure and Expand root level node's children by default
                        let groupingObj = this.createSyncHRFusionData(this.props.rootGroupingId,this.props.rootGroupingId,temp,true);
                        temp[this.props.rootGroupingId].isExpanded = true;
                        groupingObj.fontbold = true;
                        syncFusionData.push(groupingObj);
                    }

                    let actionArrTemp = [];
                    let actionArrRootTemp = [];

                    let unitClientDropDownObj = this.props.isClient==="Y"?AddClientDropdownObj:AddUnitDropdownObj;
                    
                    // if(this.props.rootNode.allowEdit === "Y"){
                        actionArrTemp = [AddChildDropdownObj,AddSiblingDropdownObj,unitClientDropDownObj,editDropdownObj,delDropdownObj]
                        actionArrRootTemp = [AddChildDropdownObj,editDropdownObj]
                    // }

                    this.fields = {
                        dataSource:syncFusionData,
                        id:"unitGroupId",
                        text:"unitGroupName",
                        parentID:"parentId",
                        child:"newChildren",
                        expanded:"isExpanded"
                    };

                    this.setState({
                        groupingMap:temp,
                        actionArr:actionArrTemp,
                        actionArrRoot:actionArrRootTemp,
                        idToBeExpandedAfterAddingChild:"",
                        syncFusionData:syncFusionData,
                        showLoader:false,
                        clearAndResetGroupingTree:false
                    },()=>{
                        this.reference.refresh();
                    });
                }
                else {
                    this.setState({
                        groupingMap:{},
                        showLoader:false,
                        syncFusionData:[],
                        clearAndResetGroupingTree:false
                    });
                }
            })

    }

    onDropDownItemClick = (item,groupingObj) => {
        if(item.label === "Add Child"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"child",
                relativeGroupingId:groupingObj.unitGroupId,
                
                isEdit:false,
            })
        }else if(item.label === "Add Sibling"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"sibling",
                relativeGroupingId:groupingObj.unitGroupId,
                isEdit:false,
            })
        }else if(item.label === "Delete"){
            let showRadio = false;
           
            Object.values(this.state.groupingMap[groupingObj.unitGroupId].children).map((item)=>{
                if(!item.isUnit)
                {
                    showRadio = true
                }
            })


            this.setState({
                operationType:item.label,
                deleteReactivateId:groupingObj.unitGroupId,
                showAlertWithRadioDialog:true,
                showRadio:showRadio,
                alertWithRadioDialogMessage:"Are you sure you want to delete "+groupingObj.unitGroupName+"?"
            })
        }else if(item.label === "Edit"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"sibling",
                relativeFunctionId:groupingObj.unitGroupId,
                
                isEdit:true,
                selectedId:groupingObj.unitGroupId,
                groupingName_new:groupingObj.unitGroupName,
            })
        }
        else if(item.label=="Add Unit")
        {
            this.setState({
                showUnitDialog:true,
                selectedId:groupingObj.unitGroupId,
            })
        }
        else if(item.label=="Add Client")
        {
            this.setState({
                showUnitDialog:true,
                selectedId:groupingObj.unitGroupId,
            })
        }
    }
    dragStop = (args)=>{
       

        //if the node is dropped above the parent node then cancel the drag
        if(args.dropLevel === 1){
            args.cancel = true;
        }
    }

    nodeDrop = (args) => {
   

        let draggedNodeId = "";
        let draggedNodePid = "";

        let droppedNodeid = "";
        let droppedNodePid = "";
        let droppedPos = "";
        let dropLevel = "";

        if(!isEmptyVariable(args.draggedNodeData) && !isEmptyVariable(args.droppedNodeData)){
            draggedNodeId = args.draggedNodeData.id;
            draggedNodePid = args.draggedNodeData.parentID;

            droppedNodeid = args.droppedNodeData.id;
            droppedNodePid = args.droppedNodeData.parentID;
            droppedPos = args.dropIndex + 1;
            dropLevel = args.dropLevel;

            // now check what is the level of drop id
            let dropRelativeNodeLevel = this.state.groupingMap[droppedNodeid].level;
            //dropped level is greater than relative node, then it is a child of that relative node
            //hence parent id is relative node id
            if(dropLevel > dropRelativeNodeLevel){
                droppedNodePid = droppedNodeid;
            }

            this.moveTask(draggedNodeId,draggedNodePid,droppedNodePid,droppedPos);
        }
    }

    nodeExpanded = (args) =>{
        this.state.groupingMap[args.nodeData.id].isExpanded = true;
    }

    nodeCollapsed = (args) =>{
        this.state.groupingMap[args.nodeData.id].isExpanded = false;
    }
    
    handleAddEditDialogClose = (reloadFlag) =>{
        let id = "";
        if(this.state.createNodeType === "child") id = this.state.relativeGroupingId;
        this.setState({
            showCreateDialog:false,
            clearAndResetFunctionTree:false,
            isEdit:false,
            createNodeType:"",
            selectedId:"",
            groupingName_new:"",
            relativeGroupingId:"",
            idToBeExpandedAfterAddingChild:id,
        },()=>{
            if(reloadFlag){
                this.reloadListing = true;
                this.getGroupingTree();
            }
        })
    }

    GroupingTree = (groupingObj) => {
        let spanObj = getStatusSpan(groupingObj.previousStatus,this.props.themeSettings);
        return <div className="dialog-tree-structure-sync">
        {
            !isEmptyVariable(groupingObj) &&
                <div className="tree-node-sync">
                    {
                        this.props.rootNode.allowEdit === "N" &&
                        <div className="tree-text-layout" >
                            <p className={groupingObj.fontbold?"font-bold":""} style={{fontSize:20}}>
                                {groupingObj.unitGroupName}
                            </p>
                            {
                                this.props.newFlag === "Y" && 
                                (groupingObj.status_new === underReviewStatus ||
                                    groupingObj.status_new === createdStatus ||
                                    groupingObj.status_new === updatedStatus ||
                                    groupingObj.status_new === deletedStatus) &&
                                <span style={spanObj.spanStyle} className="span-tag">
                                    {spanObj.spanText}
                                </span>
                            }
                        </div>
                    }
                    {
                       
                        <div className="tree-dropdown-layout-sync">
                            <TreeNodeDropdown
                                placeholder={groupingObj.unitGroupName}
                                dropdownArr={ !groupingObj.isUnit?
                                    groupingObj.level === 1
                                    ?
                                    this.state.actionArrRoot
                                    :
                                    this.state.actionArr:""
                                }
                                labelParam={"label"}
                                onDropDownItemClick={this.onDropDownItemClick}
                                dropdownId={groupingObj}
                                fontbold={groupingObj.fontbold}
                                fontColor={groupingObj.isUnit?`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`:"black"}
                                paraMT={"1.5px"}
                                themeSettings={this.props.themeSettings}
                            />
                        </div>
                    }
                    
                </div>
            }
        </div>
    }

    handleAlertWithDialogDialogClose = () =>{
        this.setState({
            showAlertWithRadioDialog:false,
            alertWithRadioDialogMessage:""
        });
    }

    deleteActivateUnitGroupingNode = (deleteChildren) => {
        let url = "";

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteGrouping;
        }

        this.setState({
            showLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                unitGroupId:this.state.deleteReactivateId,
                rootUnitGroupId:this.props.rootGroupingId,
                deleteChildren:deleteChildren,
                departmentId:this.props.departmentArr[0].departmentId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    operationType:"",
                    alertWithRadioDialogMessage:"",
                    showAlertWithRadioDialog:false,
                    clearAndResetFunctionTree:false.valueOf,
                    showLoader:false
                },() => {
                    // this.reloadListing = true;
                    this.getGroupingTree();
                });
            }else{
                this.setState({
                    showLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertWithRadioDialogMessage:"",
                    showAlertWithRadioDialog:false,
                    clearAndResetFunctionTree:false,

                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    handleUnitDialogClose =() =>{
        this.setState({
            showUnitDialog:false
        })
    }

    
    expandAll = () => {
        this.reference.expandAll();
    }

    collapseAll = () => {
        this.reference.collapseAll();
    }

    handleUnitCheckBoxClick =(e)=>{
        let tempUnitCheckboxSelcted ="";
        tempUnitCheckboxSelcted=e.target.checked?"Y":"N";
        this.setState({
            isUnitCheckboxSelcted:tempUnitCheckboxSelcted
        },()=>{
            this.getGroupingTree()
        })

        
        }

  render() {
    let dnd = true;
    let deptId = "";
    // if(!isEmptyVariable(this.props.rootNode) && this.props.rootNode.allowEdit === "Y"){
    //     dnd = true;
    // }
    if(!isEmptyVariable(this.props.rootNode)){
        deptId = this.props.rootNode.departmentId;
    }
    return (
        <div>
        <Modal className="show-functions-tree-dialog custom-dialog" 
        show={this.props.showGroupingTreeDialog} 
        backdrop="static"
        >
        <Modal.Header>
            <h5>Grouping Tree</h5>
            <button 
                onClick={this.props.handleGroupingTreeDialogClose}
                type="button" data-dismiss="modal">
                <span class="material-icons">close</span>
            </button>
        </Modal.Header>
        <hr />
        <div className="modal-body">
            {
                this.state.showLoader &&
                <div className="modal-loading-text">
                    <p
                    style={{
                        background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                        color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                    }}
                    >Loading....</p>
                </div>
            }
            <div className='row'>
                <div className='col-8'>
            <FilterCheckBox
                themeSettings={this.props.themeSettings}
                style={{ marginLeft: 0, marginBottom: 10 }}
            >
                <input
                type="checkbox"
                name={"Unit"}
                id={"Unit"}
                onChange={this.handleUnitCheckBoxClick}
                checked={
                    this.state.isUnitCheckboxSelcted==="Y"? true : false
                }
                />
                <label for={"Unit"}>
               {this.props.isClient==="Y"?"Show Client":"Show Unit"}
                </label>
            </FilterCheckBox>
            </div>
            <ExpandCollapseWrapper themeSettings={this.props.themeSettings}>
                <div className="expand-collapse-layout"
                    onClick={this.expandAll}
                    style={{marginRight:10}}
                    type="button">
                    <span class="material-icons">unfold_more</span>
                    <p>Expand All</p>
                </div>
                <div
                    className="expand-collapse-layout"
                    onClick={this.collapseAll}
                    style={{marginLeft:10}}
                    type="button">
                    <span class="material-icons">unfold_less</span>
                    <p>Collapse All</p>
                </div>

            </ExpandCollapseWrapper>
            </div>
          
{            
}            <TreeViewComponent 
                fields={this.fields} 
                allowDragAndDrop={dnd}
                nodeTemplate={this.GroupingTree}
                ref = {(treeNode) => {this.reference = treeNode}}
                nodeDragStop={this.dragStop}
                nodeDropped = {this.nodeDrop}
                nodeExpanded = {this.nodeExpanded}
                nodeCollapsed = {this.nodeCollapsed}
                class="lalala"
            />
        </div>
            
        </Modal>
        <AddGroupingDialog
            isEdit={this.state.isEdit}
            showCreateDialog={this.state.showCreateDialog}
            handleAddEditDialogClose={this.handleAddEditDialogClose}
            createNodeType={this.state.createNodeType}
            relativeGroupingId={this.state.relativeGroupingId}

            departmentId={deptId}
            rootGroupingId={this.props.rootGroupingId}

            selectedId={this.state.selectedId}
            groupingName={this.state.groupingName_new}
            themeSettings={this.props.themeSettings}
        />

       {this.state.showUnitDialog && <AddUnitGroupingDialog
        showUnitDialog={this.state.showUnitDialog}
        handleUnitDialogClose={this.handleUnitDialogClose}
        themeSettings={this.props.themeSettings}
        selectedId={this.state.selectedId}
        getGroupingTree ={this.getGroupingTree}
        isClient ={this.props.isClient}
        companyType = {this.props.companyType}
        rootGroupingId={this.props.rootGroupingId}
        />}


        <AlertWithRadioDialog
                    showAlertDialog={this.state.showAlertWithRadioDialog}
                    handleAlertDialogClose={this.handleAlertWithDialogDialogClose}
                    alertDialogHeading={"Delete"}
                    alertDialogMessage={this.state.alertWithRadioDialogMessage}
                    proceedBtnClick={this.deleteActivateUnitGroupingNode}
                    proceedBtnLabel={"Delete"}
                    showRadio={this.state.showRadio}
                />

        </div>
        
    )
  }
}
