import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,TableCheckBox,DialogSaveButton} from '../../../common/Global/globalStyles.style';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import Pagination from '../../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,SplitJoinString,
    isEmptyArray} from '../../../common/Global/commonFunctions.js';
import {Modal} from 'react-bootstrap';
import SelectFunctionDialog from './selectFunction';
import { toast } from 'react-toastify';
const userDetails  = getLocalStorageVariables();
const sortName = "d.name";
const sortDescription = "d.description";
const ActiveStatus = "Active";
const resourceDropdownPlaceholder="Select Function";

class AssignmentAuditeeFunctionDialog extends Component{
    constructor(props){
        super(props);
        this.state = {
            isComponentDidMountFlag:false,
            isDraftOnward:false,
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            oldDatalist:[],
            resultSizePlaceholder:Constants.RESULT_SIZE,
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            sort:"",
            sortDir:"",

            allEmpFuncArr:[],
            selectedEmpIds:[],
            selectedEmpFuncIds:[],
            showSelectFunctionDialog:false,
            funcMultiSelectkey: "assignFunction_" + (new Date()).getTime(),
            allFunctionArr:[],
            isShowAddMoreFunction:true,
            assignmentDetails:{}

        }
    }

    componentDidMount(){
        if(!isEmptyVariable(this.props.isDraftOnward)){
            this.state.isDraftOnward= this.props.isDraftOnward
        }
        this.getInitData();
    }

    getInitData(tmpSelectedEmpIds){
        this.setState({
            showLoader:true
        })
        let  assEmpUrl = ''
        if(this.state.isDraftOnward){
            assEmpUrl = Constants.GetDraftAssignmentEmployees
        }
        else{
            assEmpUrl = Constants.GetAssignmentEmployees
        }


        fetch(assEmpUrl, {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(
            {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                assignmentId: this.props.assignmentId,
                employeeFunctions:this.state.functionlist,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:ActiveStatus,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                unitId:this.props.unitId
            })
        })
        .then((response)=> { return response.json(); }) 
        .then((data)=> {

            let tempDatalist = [];
            let totalCountTemp =0;
            let tmpAllEmpFuncArr =[];
            let tmpAllFuncArr =[];
            let assignmentDetails = {};
            let isShowAddMoreFunction = true;
           
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                tempDatalist = data.data.result;
                tempDatalist.map((data) =>{ data.isSelected = false; });
                totalCountTemp = data.data.count;
                assignmentDetails = data.data.assignmentData;
                isShowAddMoreFunction=assignmentDetails.assignmentArea=="Function"?true:false;
                    tmpAllFuncArr = data.data.functions
                let tmpSelctedEmpIdArr =[];
                if(!isEmptyArray(tmpSelectedEmpIds)){
                    tmpSelctedEmpIdArr = tmpSelectedEmpIds
                }

                tempDatalist.map(item=>{
                    item.scope.map(e=>{
                        let tmpAllFuncArrIds = tmpAllFuncArr.map(e=>e.functionId)|| [];
                        if(!tmpAllFuncArrIds.includes(e.functionId)){
                            tmpAllFuncArr.push(e)
                        }
                    })
                })

                if(assignmentDetails.assignmentArea=="Function" && tmpAllEmpFuncArr.length>0) {
                    let allEmpFuncIds = [...new Set(tmpAllEmpFuncArr.map(e=>e.functionId))]||[];

                    let assigmentFucIds = tmpAllFuncArr.map(e=>e.functionId) ||[];
                    
                    isShowAddMoreFunction = allEmpFuncIds.filter(e=>!assigmentFucIds.includes(e)).length==0;

                }


                tempDatalist.forEach(data => {
                    if (data.scope.length>0) {
                        data.scope.forEach(func => {
                            tmpAllEmpFuncArr.push({
                                employeeId: data.employeeId,
                                functionId: func.functionId,
                            });
                        });
                    }
                });

                

                this.setState({
                    isComponentDidMountFlag: true,
                    showLoader:false,
                    totalCount:totalCountTemp,
                    datalist: tempDatalist,
                    oldDatalist: tempDatalist,
                    allEmpFuncArr:tmpAllEmpFuncArr,
                    search:this.state.search,
                    pageNo:this.state.currentPageNo,
                    allFunctionArr:tmpAllFuncArr,
                    isShowAddMoreFunction:isShowAddMoreFunction,
                    assignmentDetails:assignmentDetails
                },()=>{
                    if(!isEmptyArray(tmpSelectedEmpIds)){
                        tmpSelectedEmpIds.map(e=>this.updateEmployeeFunctionArr(e,true)
                        )
                    }
                })
                
            }else{
                totalCountTemp = 0;
                this.setState({
                    isComponentDidMountFlag: true,
                    totalCount:totalCountTemp,
                    datalist: tempDatalist,
                    showLoader:false,
                    allEmpFuncArr:[]
                })
            }
            
        })
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getInitData();
        })
    }
    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getInitData();
            })
        }
    }
    
    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getInitData();
        })
    }

    handleEmpCheckBoxSelection = (employeeId, e)=>{
        let isChecked = e.target.checked;
        let tempOldDataList = this.state.oldDatalist;
        let newDataList= [];
        let tmpSelectedEmpIds = this.state.selectedEmpIds;
        let tmpSelectedEmpFuncIds = [];
        
        if(isChecked){
            tmpSelectedEmpIds.push(employeeId)
        }else{
            tmpSelectedEmpIds = tmpSelectedEmpIds.filter(e=>e!=employeeId)
        }
        tempOldDataList.map(e=>{
            if(tmpSelectedEmpIds.includes(e.employeeId)){
                e.isSelected = true
            }else{
                e.isSelected = false
            }
        })

        if(tmpSelectedEmpIds.length>0){
           
           tmpSelectedEmpFuncIds = this.state.allEmpFuncArr.filter(e=>tmpSelectedEmpIds.includes(e.employeeId)).map(e=>e.functionId)||[];

            tempOldDataList.map(e=>{
                let empFuncids = this.state.allEmpFuncArr.filter(itm=>itm.employeeId==e.employeeId).map(e=>e.functionId)||[];
                let getArrDiff = this.getArrDiff(tmpSelectedEmpFuncIds,empFuncids)
                let getArrDiff1 = this.getArrDiff(empFuncids,tmpSelectedEmpFuncIds)

                if(getArrDiff.length==0 && getArrDiff1.length==0){
                    newDataList.push(e);
                }
            })
        }

        if(tmpSelectedEmpIds.length==0){
            newDataList =  tempOldDataList
        }

        this.setState({
            datalist:newDataList,
            totalCount:newDataList.length,
            selectedEmpIds:tmpSelectedEmpIds,
            selectedEmpFuncIds:Array.from(new Set(tmpSelectedEmpFuncIds))
        })
    }

    getArrDiff = (arr1, arr2) => {
        const set1 = new Set(arr1);
      
        for (const value of arr2) {
          set1.delete(value);
        }
      
        return Array.from(set1);
      }

    updateEmployeeFunctionArr = (employeeId,isChecked) => {
        let tempOldDataList = this.state.oldDatalist;
        let newDataList= [];
        let tmpSelectedEmpIds = this.state.selectedEmpIds;
        let tmpSelectedEmpFuncIds = [];

        if(isChecked){
            tmpSelectedEmpIds.push(employeeId)
        }else{
            tmpSelectedEmpIds = tmpSelectedEmpIds.filter(e=>e!=employeeId)
        }
        tempOldDataList.map(e=>{
            if(e.employeeId==employeeId){
                e.isSelected = isChecked
            }else{
                e.isSelected = false
            }
        })

        if(tmpSelectedEmpIds.length>0){
           
           tmpSelectedEmpFuncIds = this.state.allEmpFuncArr.filter(e=>tmpSelectedEmpIds.includes(e.employeeId)).map(e=>e.functionId)||[];

            tempOldDataList.map(e=>{
                let empFuncids = this.state.allEmpFuncArr.filter(itm=>itm.employeeId==e.employeeId).map(e=>e.functionId)||[];
                let getArrDiff = this.getArrDiff(tmpSelectedEmpFuncIds,empFuncids)
                let getArrDiff1 = this.getArrDiff(empFuncids,tmpSelectedEmpFuncIds)

                if(getArrDiff.length==0 && getArrDiff1.length==0){
                    newDataList.push(e);
                }
            })
        }

        if(tmpSelectedEmpIds.length==0){
            newDataList =  tempOldDataList
        }

        this.setState({
            datalist:newDataList,
            totalCount:newDataList.length,
            selectedEmpIds:tmpSelectedEmpIds,
            selectedEmpFuncIds:Array.from(new Set(tmpSelectedEmpFuncIds))
        })

    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            },()=>{
                this.getInitData()
            });
        }
    }

    handleSelectFunctionDialogShow = () => {
        this.setState({
            showSelectFunctionDialog:true,
        })
    }

    handleSelectFunctionDialogClose = () =>{
        let isShowAddMoreFunction = this.state.isShowAddMoreFunction;

        if(this.state.assignmentDetails.assignmentArea=="Function" && this.state.allEmpFuncArr.length>0) {

            let allEmpFuncIds = [...new Set(this.state.allEmpFuncArr.map(e=>e.functionId))]||[];
            let assigmentFucIds = this.state.allFunctionArr.map(e=>e.functionId) ||[];
            isShowAddMoreFunction = allEmpFuncIds.filter(e=>!assigmentFucIds.includes(e)).length==0;
        }
        this.setState({
            showSelectFunctionDialog:false,
            isShowAddMoreFunction:isShowAddMoreFunction

        })
    }

    handleSelectFunctionDialogSaveClose = (selectedFunctionObjArr) =>{
        let tmpSelectedEmpIds = this.state.selectedEmpIds;
        let tmpAllEmpFuncArr = [];
        let selectedFuncIds = selectedFunctionObjArr.map(e=>e.functionId)||[];
        let isShowAddMoreFunction = this.state.isShowAddMoreFunction

        if(this.state.allEmpFuncArr.length>0){
            tmpAllEmpFuncArr = this.state.allEmpFuncArr.filter(e=>!tmpSelectedEmpIds.includes(e.employeeId))||[];
        }

        tmpSelectedEmpIds.map(e=>{
            selectedFuncIds.map(func =>{
                tmpAllEmpFuncArr.push({
                    employeeId: e,
                    functionId: func
                })
            })
        })


        if(this.state.assignmentDetails.assignmentArea=="Function" && tmpAllEmpFuncArr.length>0) {
            let allEmpFuncIds = [...new Set(tmpAllEmpFuncArr.map(e=>e.functionId))]||[];
            let assigmentFucIds = this.state.allFunctionArr.map(e=>e.functionId) ||[];
            isShowAddMoreFunction = allEmpFuncIds.filter(e=>!assigmentFucIds.includes(e)).length==0;        
        }

        let tmpAllFuncArr = this.state.allFunctionArr
        // if(this.state.assignmentDetails.assignmentArea=="Function"){
            // tmpAllFuncArr = this.state.allFunctionArr;
            let selectedEmpFucIds = tmpAllFuncArr.map(e=>e.functionId) ||[];
            selectedFunctionObjArr.map(e=>{
                if(!selectedEmpFucIds.includes(e.functionId)){
                    
                    tmpAllFuncArr.push({
                        functionId:e.functionId,
                        functionName:e.functionName
                    })
                }
            }) ;
        // }

        this.setState({
            allEmpFuncArr:tmpAllEmpFuncArr,
            isShowAddMoreFunction:isShowAddMoreFunction,
            allFunctionArr:tmpAllFuncArr,
            showSelectFunctionDialog:false,
        },()=>{
            // this.saveEmployeeFunction()
            this.state.selectedEmpIds.map(e=>this.updateEmployeeFunctionArr(e,true))
            
        })
    }

    saveEmployeeFunction = () => {
        let tmpSelectedEmpIds = this.state.selectedEmpIds;

        let tmpAllEmpArr = this.state.allEmpFuncArr;
        // const uniqueArray = Array.from(new Set(array.map(JSON.stringify))).map(JSON.parse);
        // let documentFormErrTemp = "";

        if(isEmptyArray(this.state.allEmpFuncArr)){
            // documentFormErrTemp =  "Assign atleast one function to any employee";
            toast.warning("Assign atleast one function to any employee");
            // this.setState({
            //     documentFormErr:documentFormErrTemp,
            // })

            // setTimeout(function(){
            //     this.setState({
            //         documentFormErr:"",
            //     });
            // }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else {

            let addEmpFuncUrl = '';
            if(this.state.isDraftOnward){
                addEmpFuncUrl = Constants.AddDraftEmployeeFunction
            }else{
                addEmpFuncUrl = Constants.AddEmployeeFunction
            }


            fetch(addEmpFuncUrl,{
                method: "post",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.props.assignmentId,
                    employeeFunctions:JSON.stringify( Array.from(new Set(tmpAllEmpArr.map(JSON.stringify))).map(JSON.parse)),
                })
            })
            .then((response)=>{
                return response.json()
            })
            .then(async(data)=>{
                let isShowAddMoreFunction = this.state.isShowAddMoreFunction;
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                }else if (data.responseCode === Constants.CODE_SUCCESS) {
                    
                    if(this.state.assignmentDetails.assignmentArea=="Function" && this.state.allEmpFuncArr.length>0) {
                        let allEmpFuncIds = [...new Set(this.state.allEmpFuncArr.map(e=>e.functionId))]||[];
                        let assigmentFucIds = this.state.allFunctionArr.map(e=>e.functionId) ||[];
                        isShowAddMoreFunction = allEmpFuncIds.filter(e=>!assigmentFucIds.includes(e)).length==0;
                    }

                    this.setState({
                        isShowAddMoreFunction:isShowAddMoreFunction
                    },()=>{
                        this.props.handleAssignFuncDialogClose()
                        // if(this.state.assignmentDetails.assignmentArea!="Function" && !isShowAddMoreFunction){
                        //     this.getInitData(tmpSelectedEmpIds)
                        // }
                    })
                }
            })
        }
    }

    addSelection = (args) => {
        let tmpAllEmpFuncArr = this.state.allEmpFuncArr;
        let tmpSelctedFuncId = this.state.selectedEmpFuncIds;
        tmpSelctedFuncId.push(args.itemData.functionId);
        const selectedEmployees = this.state.datalist.filter(e => this.state.selectedEmpIds.includes(e.employeeId)) || [];
        for (const emp of selectedEmployees) {
            const tempEmpFuncId = this.state.allEmpFuncArr.filter(e=>e.employeeId === emp.employeeId) || []
            if(!tempEmpFuncId.includes(args.itemData.functionId)) {
                tmpAllEmpFuncArr.push({
                    employeeId: emp.employeeId,
                    functionId: args.itemData.functionId
                })
            }
        }

        this.setState({
            allEmpFuncArr: tmpAllEmpFuncArr,
            selectedEmpFuncIds: Array.from(new Set(tmpSelctedFuncId)),
            funcMultiSelectkey: "assignFunction_" + (new Date()).getTime(),
        },()=>{
            this.state.selectedEmpIds.map(e=>this.updateEmployeeFunctionArr(e,true))
        })
    }

    removeSelection = (args) => {
        let tmpSelctedFuncId = this.state.selectedEmpFuncIds;
        tmpSelctedFuncId = tmpSelctedFuncId.filter(e=>e != args.itemData.functionId);
        let tmpAllEmpFuncArr = this.state.allEmpFuncArr;
        const selectedEmployees = this.state.datalist.filter(e => this.state.selectedEmpIds.includes(e.employeeId)) || [];
        const selectedEmployeeIds = selectedEmployees.map(e => e.employeeId);
        tmpAllEmpFuncArr = tmpAllEmpFuncArr.filter(e => {
            return !(selectedEmployeeIds.includes(e.employeeId) && e.functionId === args.itemData.functionId);
          });

          this.setState({
            allEmpFuncArr: tmpAllEmpFuncArr,
            selectedEmpFuncIds: Array.from(new Set(tmpSelctedFuncId)),
            funcMultiSelectkey: "assignFunction_" + (new Date()).getTime(),
        },()=>{
            this.state.selectedEmpIds.map(e=>this.updateEmployeeFunctionArr(e,true))
        })
    }

    addMoreFunctionClick = () =>{
        this.setState({
            showSelectFunctionDialog:true,
            isShowAddMoreFunction:false
        })
    }
    handleStopPropagation = (e) => {
        e.stopPropagation();
    }

    render(){
        let fields = {text:"functionName",value:"functionId"};
        return(
            <section>
                <Modal className="task-select-table-dialog custom-dialog"
                    show={this.props.showAssignFuncDialog} 
                    onHide={this.props.handleAssignFuncDialogClose} >
                    <Modal.Header>
                        <h5>Assign Function</h5>
                        <button 
                            onClick={this.props.handleAssignFuncDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        { 
                            this.state.showLoader &&
                            <div className="modal-loading-text">
                                <p
                                style={{
                                    background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                    color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                                }}
                                >Loading....</p>
                            </div>
                        }
                        {
                            this.state.isComponentDidMountFlag &&
                            <div className="body-wrapper">
                                <div className="search-addnew-bg" style={{marginTop:0}}>
                                    <div className="search-addnew">
                                        <div className="search-filter-layout" style={{position:"relative"}}>
                                            <FilterDropDown
                                                    placeholder={this.state.resultSizePlaceholder}
                                                    dropdownArr={this.state.resultSizeArr}
                                                    onDropDownItemClick={this.resultSizeDropdownClick}
                                                    addBorder={true}
                                                    themeSettings={this.props.themeSettings}
                                                    mleft={0}
                                            />
                                            <div className="search-col m-l-10" style={{border:"1px solid #e5e5e5"}}>
                                                <input 
                                                    name="searchkey" 
                                                    type="text"
                                                    onChange={this.handleChangeSearch}
                                                    placeholder="Search" 
                                                    onKeyPress={this.onEnterBtnPress}
                                                    value={this.state.searchkey}
                                                />
                                                <SearchSpan 
                                                themeSettings={this.props.themeSettings}
                                                onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                                
                                            </div>
                                            {this.state.selectedEmpIds.length>0 && (['Process','CheckList'].includes(this.state.assignmentDetails.assignmentArea) || !this.state.isShowAddMoreFunction) && 
                                                <div style={{width:"250px"}}>
                                                    <div className="dummy-dropdown mt-1 ml-2" tabIndex={0} onKeyPress={this.onKeyBtnPress}  
                                                        onClick={this.handleSelectFunctionDialogShow}>
                                                        <p class="placeholder-text">
                                                            {resourceDropdownPlaceholder}
                                                        </p>
                                                        <span class="material-icons">keyboard_arrow_right</span>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.selectedEmpIds.length>0 && this.state.assignmentDetails.assignmentArea=="Function" && this.state.isShowAddMoreFunction &&
                                                <div style={{width:"250px",marginLeft:"5px"}}>
                                                   <MultiSelectComponent
                                                        id="assignFunction"
                                                        dataSource={this.state.allFunctionArr}
                                                        fields={fields}
                                                        mode="CheckBox"
                                                        placeholder={resourceDropdownPlaceholder}
                                                        closePopupOnSelect={false}
                                                        allowFiltering={false}
                                                        showSelectAll={true}
                                                        value={this.state.selectedEmpFuncIds}
                                                        select={this.addSelection}
                                                        removed={this.removeSelection}
                                                        cssClass="clientsChecklist dialogcl"
                                                        showDropDownIcon={true}
                                                        enableSelectionOrder={true}
                                                        showClearButton={false}
                                                        disabled={this.state.selectedEmpIds.length === 0}
                                                    >
                                                        <Inject services={[CheckBoxSelection]} />
                                                    </MultiSelectComponent>
                                                </div>
                                            }
                                            {this.state.selectedEmpIds.length>0 && this.state.assignmentDetails.assignmentArea=="Function" && this.state.isShowAddMoreFunction &&
                                                <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                                    onClick={this.addMoreFunctionClick} type="button" 
                                                    className="modal-save mb-1 ml-2">Add More Function</DialogSaveButton>
                                            }
                                         </div>
                                    </div>
                                </div>
                                <div style={{position:"relative"}}>
                                    {
                                        !isEmptyArray(this.state.datalist) && 
                                        <div className="common-tab-bg">
                                            <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th className="text-center" width={"10px"} ></th>
                                                        <th width={"18%"} className="c-pointer" >
                                                            <div className="sort-header">Name
                                                            {/* <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span> */}
                                                            </div>
                                                        </th>
                                                        <th className="c-pointer"  width={"15%"}>
                                                            <div className="sort-header">Designation
                                                            {/* <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span> */}
                                                            </div>
                                                        </th>
                                                        <th  className="c-pointer"  width={"20%"}>
                                                            <div className="sort-header">Unit Name
                                                            {/* <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span> */}
                                                            </div>
                                                        </th>
                                                        <th className="c-pointer">
                                                            <div className="sort-header">Function
                                                            {/* <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span> */}
                                                            </div>
                                                        </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                    {
                                                        !isEmptyArray(this.state.datalist) && 
                                                        this.state.datalist.map((item,idx) => {
                                                            let empFuncIds = this.state.allEmpFuncArr.filter(e=>e.employeeId==item.employeeId).map(e=>e.functionId)||[];
                                                            let empFunctionList= this.state.allFunctionArr.filter(e=>empFuncIds.includes(e.functionId)).map(e=>e.functionName).join(", ") || '';

                                                            return( 
                                                                <tr key={idx + "_" + item.employeeId}>
                                                                    <td>
                                                                        <TableCheckBox onClick={this.handleStopPropagation}
                                                                            themeSettings={this.props.themeSettings}>
                                                                            <input 
                                                                                type="checkbox"
                                                                                name={idx}
                                                                                id={item.employeeId}
                                                                                onChange={this.handleEmpCheckBoxSelection.bind(this, item.employeeId)}
                                                                                checked={item.isSelected?true:false}
                                                                            />
                                                                            <label htmlFor={item.employeeId} 
                                                                            style={{marginLeft:6}}></label>
                                                                        </TableCheckBox>
                                                                    </td>
                                                                    <td>{truncateString(SplitJoinString(item.name,",",":-:",", "),Constants.DEPT_STRING_LENGTH)}</td>
                                                                    <td>{truncateString(SplitJoinString(item.designation,",",":-:",", "),Constants.DEPT_STRING_LENGTH)}</td>
                                                                    <td>{!isEmptyArray(item.units)?item.units.map(e=>e.unitName).join(","):""}</td>
                                                                    <td style={{whiteSpace:'normal',overflowWrap:'anywhere',maxWidth:'100px'}}>{this.state.allFunctionArr.filter(e=>empFuncIds.includes(e.functionId)).map(e=>e.functionName).join(", ") || ''  }</td>
                                                                </tr> 
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.isComponentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                        <div class="no-items-layout">
                                            <div class="no-items-card">
                                                <h6>
                                                {
                                                    isEmptyVariable(this.state.searchkey)?
                                                    Constants.NO_RECORDS_WARNING
                                                    :
                                                    Constants.EMPTY_SEARCH_WARNING
                                                }
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                    <div className="pagination-layout">
                                        <div className="row custom-row">
                                            <div className="col-md-9 custom-col">
                                            {
                                                (this.state.totalCount > this.state.datalist.length) && 
                                                <Pagination 
                                                    totalLength ={this.state.totalCount} 
                                                    items={this.state.datalist} 
                                                    onChangePage={this.onChangePage} 
                                                    pageSize={this.state.resultSize}
                                                    currentPageNo = {this.state.currentPageNo}
                                                    initialPage={this.state.currentPageNo}
                                                    themeSettings={this.props.themeSettings} />
                                            }
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <p>{"Total Records: "+this.state.totalCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {
                                    !isEmptyVariable(this.state.documentFormErr) &&
                                    <span className="cm-error">{this.state.documentFormErr}</span>
                                } */}
                                <div className="modal-close-save m-t-15">
                                    <div className="close-save-col">
                                        <button onClick={this.props.handleAssignFuncDialogClose} type="button" 
                                        className="modal-close m-b-0">Cancel</button>
                                        <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                        // onClick={ this.state.assignmentDetails.assignmentArea=='Function' && this.state.isShowAddMoreFunction ?this.saveEmployeeFunction :this.props.handleAssignFuncDialogClose} 
                                        onClick={ this.saveEmployeeFunction} 
                                        type="button" 
                                        className="modal-save m-b-0">Save</DialogSaveButton>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal>

                <SelectFunctionDialog
                    showFunctionsDialog={this.state.showSelectFunctionDialog}
                    handleSelectFuncDialogClose={this.handleSelectFunctionDialogClose}
                    handleSelectFunctionDialogSaveClose={this.handleSelectFunctionDialogSaveClose}
                    selectedEmpFuncIds={this.state.selectedEmpFuncIds}
                    departmentId={this.state.assignmentDetails.departmentId}
                    themeSettings = {this.props.themeSettings}
                />
            </section>
        )
    }
}

export default AssignmentAuditeeFunctionDialog;