import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, SplitJoinString,
    ifEmptyReturnEmptyStr} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';

const userDetails  = getLocalStorageVariables();

class EmployeeDetailsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(){
    }


    render() {
        return(
            <Modal className="user-details-dialog custom-dialog" 
            show={this.props.showEmpDetailsDialog} onHide={this.props.handleEmpDetailsDialogClose}>
                <Modal.Header>
                    <h5>{this.props.employeeData.firstName+" Details"}</h5>
                    <button 
                        onClick={this.props.handleEmpDetailsDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="body-wrapper">
                        {
                            !isEmptyVariable(this.props.employeeData.firstName) && 
                            <div className="details-table-layout">
                                <div className="row custom-row">
                                    <div className="col-md-6 custom-col">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td width={"48%"}>Employee Name</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.employeeData.salutation+" "+this.props.employeeData.firstName + " "
                                                    +this.props.employeeData.middleName + " "+this.props.employeeData.lastName}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Email</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.employeeData.employeeEmail}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Phone</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{ifEmptyReturnEmptyStr(this.props.employeeData.phoneNo,"-")}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Mobile</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{ifEmptyReturnEmptyStr(this.props.employeeData.mobileNo,"-")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6 custom-col">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td width={"48%"}>Designation</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.employeeData.designation}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Is POC</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.employeeData.isPOC === "Y"?"Yes":"No"}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Unit Name</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{this.props.employeeData.unitName}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"48%"}>Function</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"48%"}>{SplitJoinString(this.props.employeeData.functionList,",",":-:",", ")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default EmployeeDetailsDialog;