import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,getThemeSettingsVariables,removeHtmlTags,ifEmptyReturn,
    GetOrderedMasterTabMenuTask,
    isEmptyVariable, isEmptyArray, isJsonString} from '../../../common/Global/commonFunctions';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import moment from "moment";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
class AddTaskMultiSelectDropdown extends Component{
    
    constructor(props) {
        super(props);
    }

    componentDidMount(){
    }
    onDateChange = (date, dateString) => {
        let dateValue = "";
		if (!isEmptyVariable(date)) {
            dateValue = date.format("YYYY-MM-DD");
        }
        this.props.onDateChange(dateValue,this.props.templateItemIndex,this.props.fieldItem)
    };

    render(){
        return (
            <DatePicker
                onChange={this.onDateChange}
                format={"DD/MM/YYYY"}
                disabled={(this.props.isNRChecked === "Y" || this.props.isDisabled === "Y")?true:false}
                placeholder={this.props.placeholderValue}
                value={!isEmptyVariable(this.props.fieldValue)?moment(this.props.fieldValue):""} 
            />
    )}
}

export default AddTaskMultiSelectDropdown;
