import styled from 'styled-components';

export const SidebarCollapseWrapper = styled.div`
    .vertical-menu{
        background:rgba(${ props => props.sidebarBg.r }, ${ props => props.sidebarBg.g }, ${ props => props.sidebarBg.b }, ${ props => props.sidebarBg.a }) !important;
        // border-right:2px solid rgba(${ props => props.sidebarBorderRight.r }, ${ props => props.sidebarBorderRight.g }, ${ props => props.sidebarBorderRight.b }, ${ props => props.sidebarBorderRight.a });

        position: sticky;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        top: 0;
        width: 80px;
        min-width: 80px;
        transition: all .5s;
        max-width: 80px;
        height: 100vh;
        min-height: 100vh;
        max-height: 100vh;
        z-index: 200;
        // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    }
    .verticalmenu-collapse .client-profile{
        justify-content: center;
        align-items: center;
        display: flex;
        margin-bottom: 0px;
        margin-top: 5px;
        cursor: pointer;
    }
    .verticalmenu-collapse .vertical-menu-profile {
        text-align: center;
    }
    .verticalmenu-collapse .vertical-menu-profile a{
        display: block;
    }
    .verticalmenu-collapse .vertical-menu-profile a{
        width: 100%;
    }
    
    .vertical-menu-col.verticalmenu-collapse{
        padding:10px;
    }
    .verticalmenu-collapse .vertical-menu-profile hr{
        display: block;
    }
    .verticalmenu-collapse .vertical-menu-list-col ul li a p{
        display: block;
    }
    .verticalmenu-collapse .vertical-menu-list a:hover{
        border-radius: 5px;
    }
    .verticalmenu-collapse{
        width: 230px !important;
        min-width: 230px !important;
        max-width: 230px !important;
        position: fixed;
        transition: all .5s;
    }
    .verticalmenu-collapse .vertical-menu-col{
        padding: 15px;
    }
    .verticalmenu-collapse .logo{
        width: 80px !important;
        margin-bottom: 10px;
    }
    .verticalmenu-collapse .vertical-menu-profile img{
        width: 85px;
        height: 85px;
        border-radius: 100%;
    }
    .account_circle{
        color:rgba(${ props => props.themeColor.r }, ${ props => props.themeColor.g }, ${ props => props.themeColor.b }, ${ props => props.themeColor.a });
        background: rgba(${ props => props.sidebarSelectedItemBg.r }, ${ props => props.sidebarSelectedItemBg.g }, ${ props => props.sidebarSelectedItemBg.b },  ${ props => props.sidebarSelectedItemBg.a});
        border-radius: 100%;
        width: 50px;
        height: 50px;
        display: inline-block;
    }
    .account_circle h6{
        font-size: 20px;
        color: #fff;
        text-align: center;
        padding: 0;
        margin: 0;
        line-height: 50px;
    }
    .account_circle_collapse{
        color:rgba(${ props => props.themeColor.r }, ${ props => props.themeColor.g }, ${ props => props.themeColor.b }, ${ props => props.themeColor.a });
        background: rgba(${ props => props.sidebarSelectedItemBg.r }, ${ props => props.sidebarSelectedItemBg.g }, ${ props => props.sidebarSelectedItemBg.b },  ${ props => props.sidebarSelectedItemBg.a});
        border-radius: 100%;
        width: 85px;
        height: 85px;
        display: inline-block;
    }
    .account_circle_collapse h6{
        font-size: 20px;
        color: #fff;
        text-align: center;
        padding: 0;
        margin: 0;
        line-height: 85px;
    }
    .verticalmenu-collapse .vertical-dropdown-submenu{
        display: block !important;
    }
    .verticalmenu-collapse .vertical-menu-list{
        margin-top: 0px;
    }
    .verticalmenu-collapse .vertical-menu-list ul li{
        list-style: none;
        margin-bottom: 2px;
    }
    .verticalmenu-collapse .vertical-menu-list a{
        border: none;
        width: 100%;
        height: auto;
        justify-content: flex-start;
    }
    .verticalmenu-collapse .vertical-menu-list a span {
        line-height: 1;
        color:rgba(${ props => props.sidebarIconColor.r }, ${ props => props.sidebarIconColor.g }, ${ props => props.sidebarIconColor.b }, ${ props => props.sidebarIconColor.a });
    }
    .verticalmenu-collapse  ul li.active a{
        border-radius: 5px !important;
    }
    .toggle-menu-bar {
        position: absolute;
        border: none;
        background:#FFF;
        line-height: 1;
        color: #fff;
        height:30px;
        width:30px;
        outline: 0 !important;
        z-index: 9999999;
        border-radius:100%;
        right:-18px;
        top: 30px;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .toggle-menu-bar span {
        font-size:15px;
        font-weight:bold;
        justify-content:center;
        text-align:center;
        color:#000;
    }
    
    .vertical-menu-wrapper{
        position: absolute;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-right-width: 1px;
        overflow: hidden;
        z-index: 10;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    .vertical-menu-content{
        flex: 1 1 auto;
        overflow-x: hidden;
        position: relative;
        overflow-y: auto;
    }
    .vertical-menu-content::-webkit-scrollbar {
        width: 3px;
    }
    
    /* Track */
    .vertical-menu-content::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    /* Handle */
    .vertical-menu-content::-webkit-scrollbar-thumb {
        background: #888;
    }
    
    /* Handle on hover */
    .vertical-menu-content::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    .vertical-menu-col{
        padding:10px;
    }
    .vertical-menu-col h1{
        font-weight: bold;
    }
    .vertical-menu-col h4{
    
    }
    .vertical-menu-col a{
        display: inline-block;
        // margin-bottom: 20px;
        color:#404040;
    }
    
    .vertical-menu-profile img{
        width:45px;
        height:45px;
        border-radius: 100%;
    }
    
    .vertical-menu-profile{
        text-align: center;
    }
    .vertical-menu-profile a{
        font-size: 14px;
        display: block;
    }
    
    .vertical-menu-profile hr{
        margin-top:15px;
        // display: none;
        margin-bottom:15px;
        border-top: 1px solid rgba(134, 134, 134, 0.1);
    }
    .client-profile-bg{
        position: relative;
        margin-bottom: 10px;
    }
    .logo{
        border-radius: 0px !important;
        height: auto !important;
        width: 100% !important;
        margin-bottom: 10px;
    }
    .client-profile{
        justify-content: center;
        align-items: center;
        display: flex;
        margin-bottom: 0px;
        margin-top: 5px;
        cursor: pointer;
        display: none;
    }
    .client-profile h6{
        color:rgba(${ props => props.sidebarTextColor.r }, ${ props => props.sidebarTextColor.g }, ${ props => props.sidebarTextColor.b }, ${ props => props.sidebarTextColor.a });
    }
    
    .client-profile span{
        line-height: 1;
        margin-left: 5px;
    }
    .vertical-menu-list{
        margin-top:20px;
    }
    .vertical-menu-list ul{
        padding-left: 0px;
    }
    .vertical-menu-list ul li{
        list-style: none;
        margin-bottom: 10px;
    }
    .vertical-menu-list ul li.active a span{
        color:rgba(${ props => props.sidebarSelectedItemIconColor.r }, ${ props => props.sidebarSelectedItemIconColor.g }, ${ props => props.sidebarSelectedItemIconColor.b }, ${ props => props.sidebarSelectedItemIconColor.a });
    }
    .vertical-menu-list ul li.active a p{
        color:rgba(${ props => props.sidebarSelectedItemTextColor.r }, ${ props => props.sidebarSelectedItemTextColor.g }, ${ props => props.sidebarSelectedItemTextColor.b }, ${ props => props.sidebarSelectedItemTextColor.a });
    }
    .vertical-menu-list ul li.active a{
        background: rgba(${ props => props.sidebarSelectedItemBg.r }, ${ props => props.sidebarSelectedItemBg.g }, ${ props => props.sidebarSelectedItemBg.b }, ${ props => props.sidebarSelectedItemBg.a }) !important;
        color: #fff;
        border-radius: 100%;
    }
    .vertical-menu-list a{
        margin: auto;
        border-radius: 100%;
        margin-bottom: 0px;
        border: 1px solid rgba(${ props => props.sidebarIconColor.r }, ${ props => props.sidebarIconColor.g }, ${ props => props.sidebarIconColor.b }, ${ props => props.sidebarIconColor.a });
        padding: 5px 5px;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .vertical-menu-list a p{
        font-size: 13px;
        margin-bottom: 0px;
        display: none;
        line-height:1;
        margin-left: 7px;
        color:rgba(${ props => props.sidebarTextColor.r }, ${ props => props.sidebarTextColor.g }, ${ props => props.sidebarTextColor.b }, ${ props => props.sidebarTextColor.a });
    }
    .vertical-menu-list a span{
        line-height: 1;
        color:rgba(${ props => props.sidebarIconColor.r }, ${ props => props.sidebarIconColor.g }, ${ props => props.sidebarIconColor.b }, ${ props => props.sidebarIconColor.a });
    }
    .vertical-menu-list a:hover{
        background:rgba(${ props => props.sidebarHoverItemBg.r }, ${ props => props.sidebarHoverItemBg.g }, ${ props => props.sidebarHoverItemBg.b }, ${ props => props.sidebarHoverItemBg.a });
        color: #fff;
    }
    .vertical-menu-list a:hover p{
        color:rgba(${ props => props.sidebarHoverItemTextColor.r }, ${ props => props.sidebarHoverItemTextColor.g }, ${ props => props.sidebarHoverItemTextColor.b }, ${ props => props.sidebarHoverItemTextColor.a });
    }
    .vertical-menu-list ul li a:hover span{
        color:rgba(${ props => props.sidebarHoverItemIconColor.r }, ${ props => props.sidebarHoverItemIconColor.g }, ${ props => props.sidebarHoverItemIconColor.b }, ${ props => props.sidebarHoverItemIconColor.a });
    }
    .vertical-menu-list img{
        height:22px;
        width:22px;
        border-radius: 0px;
    }
    .verticalmenu-collapse a:hover {
        border-radius: 5px;
        content-visibility: auto;
    }
    a.tool {
        position: relative;
    }
    a.tool:hover::after {
        content: attr(data-title);
        background-color: rgba(${ props => props.sidebarHoverItemBg.r }, ${ props => props.sidebarHoverItemBg.g }, ${ props => props.sidebarHoverItemBg.b }, ${ props => props.sidebarIconColor.a });
        color: #fff;
        padding: 7px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: bold;
        line-height: 14px;
        display: block;
        position: absolute;
        top: 110%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        z-index: 1;
    }
`;