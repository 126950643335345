
export const TEMPLATE_TYPE_TASK = "Task";
export const TEMPLATE_TYPE_OBSERVATION = "Observation";
export const TEMPLATE_TYPE_EX_TASK = "Execution";
export const TEMPLATE_TYPE_OVERALL = "Assignment Level";
export const TEMPLATE_TYPE_FOLLOWUP = "Follow Up";
export const TEMPLATE_TYPE_AUDITEE_COMMENT = "Response";
export const OBSERVATION_MAX_ID = 6;
export const EXEC_TASK_MAX_ID = 2;

export const FIELD_TYPES_ARR = [
    {value:"Text"},
    {value:"Date"},
    {value:"Dropdown"},
    {value:"Dropdown Multiselect"},
    {value:"Table"}
    
];
export const EX_TASK_FIELD_TYPES_ARR = [
    {value:"Text"},
    {value:"Date"},
    {value:"Single-select"},
    {value:"Multi-select"},
    {value:"Table"}
];
export const FIELD_TYPES_ARR_LEFT = [
    {value:"Single-select"},
    {value:"Multi-select"},
    
];
export const FOLLOWUP_FIELD_TYPES_ARR = [
    {value:"Text"},
    {value:"Date"},
    {value:"Dropdown"},
    {value:"Dropdown Multiselect"},
]
export const FIELD_TYPES_AUDITEE_COMMENT = [
    {value:"Text"},
    {value:"Date"},
    {value:"Dropdown"},
    {value:"Dropdown Multiselect"},
    {value:"Table"}
];

export const FIELD_TYPE_TEXT = "Text";
export const FIELD_TYPE_DATE = "Date";
export const FIELD_TYPE_DROPDOWN = "Dropdown";
export const FIELD_TYPE_DROPDOWN_MULTI = "Dropdown Multiselect";
export const FIELD_TYPE_TABLE = "Table";
export const FIELD_TYPE_SINGLE_SELECT = "Single-select";
export const FIELD_TYPE_MULTI_SELECT = "Multi-select";

export const FIELD_TYPE_DD_VALUE_ARR = [
    {value:"",description:"", valueNdesc:""},
    {value:"",description:"", valueNdesc:""},
];

export const ADD_FIELD_DD_ARR = [
    {
        value:"Dropdown", 
    },
    {
        value:"Dropdown Multiselect", 
    }
];

export const OBSERVATION_FIELDS = [
    {
        fieldId:"1",
        fieldName: "Observation Heading",
        fieldType: "Text",
        isNotReqSelected: "Y",
        isMandatorySelected: "N",
        placeholderValue: "Enter Observation Heading",
        canBeDeleted: "N",
        orderPos:1,
        fieldStatus:"Created",
        fieldStatus_new:"Created"
    },
    {
        fieldId:"2",
        fieldName: "Observation Details",
        fieldType: "Text",
        isNotReqSelected: "N",
        isMandatorySelected: "Y",
        placeholderValue: "Enter Observation Details",
        canBeDeleted: "N",
        orderPos:2,
        fieldStatus:"Created",
        fieldStatus_new:"Created"
    },
    {
        fieldId:"3",
        fieldName: "Executive Summary",
        fieldType: "Text",
        isNotReqSelected: "Y",
        isMandatorySelected: "N",
        placeholderValue: "Enter Executive Summary",
        canBeDeleted: "Y",
        orderPos:3,
        fieldStatus:"Created",
        fieldStatus_new:"Created"
    },
    {
        fieldId:"4",
        fieldName: "Instances",
        fieldType: "Table",
        isNotReqSelected: "Y",
        isMandatorySelected: "N",
        placeholderValue: "Enter Instance values",
        canBeDeleted: "Y",
        orderPos:4,
        fieldStatus:"Created",
        fieldStatus_new:"Created"
    },
    {
        fieldId:"5",
        fieldName: "Risk/Impact",
        fieldType: "Dropdown Multiselect",
        isNotReqSelected: "Y",
        isMandatorySelected: "N",
        placeholderValue: "Enter Risk/Impact",
        canBeDeleted: "N",
        isFromRiskMaster: true,
        dropdownValues: [],
        orderPos: 5,
        fieldStatus:"Created",
        fieldStatus_new:"Created"
    },
    {
        fieldId:"6",
        fieldName: "Suggestion",
        fieldType: "Text",
        isNotReqSelected: "Y",
        isMandatorySelected: "N",
        placeholderValue: "Enter Suggestion",
        canBeDeleted: "Y",
        orderPos:6,
        fieldStatus:"Created",
        fieldStatus_new:"Created"
    },
    
];

export const EXEC_TASK_FIELDS = [
    {
        "fieldId":"1",
        "fieldName":"Remarks",
        "fieldType":"Text",
        "isNotReqSelected":"N",
        "isMandatorySelected":"N",
        "placeholderValue":"Enter Remarks",
        "canBeDeleted":"Y",
        "addToPosition":"Right",
        "orderPos":1,
        "fieldStatus":"Created",
        "fieldStatus_new":"Created" 
    },
    {
        "fieldId":"2",
        "fieldName":"Risk/Impact",
        "fieldType":"Multi-select",
        "isNotReqSelected":"N",
        "isMandatorySelected":"N",
        "placeholderValue":"Enter Risk/Impact",
        "canBeDeleted":"N",
        "isRiskMasterEnabled":"Y",
        "addToPosition":"Right",
        "isFromRiskMaster":true,
        "dropdownValues":[],
        "orderPos":2,
        "fieldStatus":"Created",
        "fieldStatus_new":"Created"
    }
];

export const OBSERVATION_FIXED_FIELDS = {"assignRisk":"Y","assignRiskStatus":"Created","valueAddition":"N","valueAdditionStatus":"Created","annexures":"Y","annexuresStatus":"Created","backups":"Y","backupsStatus":"Created"};
export const EXEC_TASKS_FIXED_FIELDS = {"assignRisk":"N","assignRiskStatus":"Created","valueAddition":"N","valueAdditionStatus":"Created","annexures":"N","annexuresStatus":"Created","backups":"N","backupsStatus":"Created"};
export const FOLLOWUP_FIXED_FIELDS = {"backups":"N","backupsStatus":"Created"};