import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isAlphaNumericCharacter} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {DialogSaveButton} from '../../../common/Global/globalStyles.style';

const userDetails  = getLocalStorageVariables();

class AddProcessDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,

            templateName:"",
            formErrors:{},
            submitClickedAtleastOnce:false,
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAddTemplateNameDialog){
            if(this.props.isEdit){
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    templateName:this.props.templateName,
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }else{
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    templateName:"",
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleCreateDialogClose = () =>{
        this.props.handleAddEditDialogClose(false);
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.handleSaveTemplateName();
        }
    }

    handleSaveTemplateName = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.templateName)){
            isError = true;
            FormErrorsTemp['templateName'] = "Please enter template name";
        }else if(!isAlphaNumericCharacter(this.state.templateName)){
            isError = true;
            FormErrorsTemp['templateName'] = "Template name should be Alphanumeric";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            this.props.handleAddEditDialogClose(true,this.state.templateName)
        }
    }

    render() {
        return(
            <Modal className="custom-dialog" show={this.props.showAddTemplateNameDialog} backdrop="static">
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit Template Name":"Add Template Name"}</h5>
                    <button 
                        onClick={this.handleCreateDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div class="modal-body">
                    {
                        this.state.showLoader &&
                        <div className="modal-loading-text">
                            <p
                            style={{
                                background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                            }}
                            >Loading....</p>
                        </div>
                    }
                    <div class="addnew-modal-form">
                        <div class="addnew-modal-form-group">
                            <p>Template Name*</p>
                            <input type="text" placeholder="Enter Template Name" onKeyPress={this.onEnterBtnPress} spellCheck="true" 
                            name="templateName" value={this.state.templateName} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['templateName']) &&
                                <span class="cm-error">{this.state.formErrors['templateName']}</span>
                            }
                        </div>
                    </div>
                    <div class="modal-close-save">
                        <div class="close-save-col">
                            <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                            <DialogSaveButton 
                            themeSettings={this.props.themeSettings}
                            onClick={this.handleSaveTemplateName} type="button" class="modal-save">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
}

export default AddProcessDialog;