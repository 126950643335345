import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import MasterMenuTabs from './topmenu';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../common/AlertDialog';
import Pagination from '../../common/Global/pagination';
import ReviewHistoryDialog from '../../common/reviewhistorydialog'
import UpdateReviewStatusDialog from './taskupdatereviewstatusdialog'
import TaskDetailsLayout from './taskDetailsWithTemplates';
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable, sortTable,
    isEmptyArray,removeHtmlTags,GetOrderedMasterTabMenuTask,getStatusSpan,
    ifEmptyReturnEmptyStr} from '../../common/Global/commonFunctions';
import {getDropdownActionArrayProcessTasks} from '../../common/Global/reviewFunctions';
import {
    BrowserRouter as Router,
} from 'react-router-dom';

const sortRisk = "riskLevel";
const sortTask = "task";
const sortProcess = "processNameList";

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();

class ListDraftTasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            deletePermission:"N",
            actionType:"",

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            deleteReactivateMasterType:"",
            reviewLevel:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            status:"Under Review",
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,
            showDetailsDialog:false,
            selectedItem:"",

            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:"",
            user:{},

            departmentArr:[],
            selectedDeptId:"",
            deptPlaceholder:"",
        }
        this.masterTypeFilterArr = [];
    }

    componentDidMount(){
        this.getInitData();
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getTaskList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getTaskList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag){
                this.getTaskList();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
        });
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getTaskList();
        })
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let {departmentArr} = this.state;

        for(const [i,value] of this.state.departmentArr.entries()){
            if(i === selectedIndex){
                departmentArr[i].isSelected = true;
                placeholder = value.name;
            }else{
                departmentArr[i].isSelected = false;
            }
        }
        
        this.setState({
            selectedDeptId:item.departmentId,
            deptPlaceholder:placeholder
        },() => {
            this.getTaskList();
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Task",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete task "+removeHtmlTags(dropdownItem.task)+"?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Activate Task",
                proceedBtnLabel:"Reactivate",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate task "+removeHtmlTags(dropdownItem.task)+"?"
            })
        }else if(item.label === "Edit"){
            this.props.history.push(Constants.URL_EDITTASK_WITH_TEMPLATES+"/"+dropdownItem.taskId+"-"+dropdownItem.assignmentTypeId);
        }else if(item.label === "Submit For Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Submit For Review",
                proceedBtnLabel:"Submit",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to submit "+removeHtmlTags(dropdownItem.task)+" for review?"
            })
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+removeHtmlTags(dropdownItem.task)
            })
        }else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.taskId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.taskId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+removeHtmlTags(dropdownItem.task)+"?",
                actionType: dropdownItem.previousStatus === "Updated"?"Update":(dropdownItem.previousStatus === "Deleted"?"Delete":"")
            })
        }
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getTaskList();
        })
    }

    handleShowTaskDetailsLayout = (taskId) => {
        this.setState({
            showTaskDetailsLayout:true,
            selectedTaskIdForDetails:taskId
        })
    }

    handleHideTaskDetailsLayout = () => {
        this.setState({
            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:""
        })
    }
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetUnderReviewTasks,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([generalRes,menuRes]) => { 
            return Promise.all([generalRes.json(),menuRes.json()]) 
        })
        .then(([generalRes,menuRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;
            let menusTemp = {};
            let userTemp = {};
            let departmentsTemp = [];
            let placeholder = "";
            let selectedDeptId = "";

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.taskList;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
                userTemp = menuRes.data.user;
                departmentsTemp = menuRes.data.departments;

                if(!isEmptyArray(departmentsTemp)){
                    departmentsTemp[0].isSelected = true;
                    placeholder = departmentsTemp[0].name;
                    selectedDeptId = departmentsTemp[0].departmentId;
                }
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenuTask(menusTemp)
            let deletePermission = "N";
            if(menusTemp["Delete Task"] === "ALL"){
                deletePermission = "Y";
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:datalistTemp,
                totalCount:totalCountTemp,
                deletePermission:deletePermission,

                menus:menusTemp2,
                user:userTemp,
                departmentArr:departmentsTemp,
                deptPlaceholder:placeholder,
                selectedDeptId:selectedDeptId,
            })
        })
    }

    getTaskList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetUnderReviewTasks,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                departmentId:this.state.selectedDeptId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.taskList,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetUnderReviewTasks,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    departmentId:this.state.selectedDeptId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.taskList,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    deleteTask = () => {
        let url = Constants.DeleteTask;

        this.setState({
            showLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                taskId:this.state.deleteReactivateId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    deleteReactivateMasterType:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                },() => {
                    this.getTaskList();
                });
            }else{
                this.setState({
                    showLoader:false,
                    deleteReactivateId:"",
                    deleteReactivateMasterType:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            taskId:this.state.deleteReactivateId
        }
        let callApi = true;

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteTask;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ActivateTask;
        }else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitTaskForReview;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.UpdateTaskReviewStatus;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardChangesTask;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Review Remarks"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
            })
        }

        if(callApi){
            this.setState({
                showLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        actionType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                    },() => {
                        this.getTaskList();
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        deleteReactivateId:"",
                        actionType:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar  />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    this.state.showLoader &&
                                    <div className="loading-layout"
                                    style={{background:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`}}>
                                        <div className="loading-text-layout">
                                            <p
                                            style={{color:`rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`}}
                                            >Loading....</p>
                                        </div>
                                    </div>
                                }
                                {
                                    // Need to check component did mount flag, otherwise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    !this.state.showTaskDetailsLayout &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                        themeSettings={themeSettings}
                                                        onClick = {this.onSearchIconClick} 
                                                        className="material-icons"> search </SearchSpan>
                                                    </div>

                                                    
                                                    {
                                                        this.state.departmentArr.length > 1 &&
                                                        <FilterDropDown
                                                            placeholder={this.state.deptPlaceholder}
                                                            dropdownArr={this.state.departmentArr}
                                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                                            name={"name"}
                                                            themeSettings={themeSettings}
                                                            dropdownWidth={"120px"}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center" width={"5%"}>No.</th>
                                                                <th width={"60%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortTask)}>
                                                                    <div className="sort-header">
                                                                        Task
                                                                        <span className={
                                                                                this.state.sort === sortTask ? "material-icons" : "material-icons hide-sort-arrow"
                                                                            }
                                                                        >
                                                                            {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                                <th width={"10%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortRisk)}>
                                                                    <div className="sort-header">
                                                                        Risk
                                                                        <span className={
                                                                                this.state.sort === sortRisk ? "material-icons" : "material-icons hide-sort-arrow"
                                                                            }
                                                                        >
                                                                            {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                                <th width={"15%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortProcess)}>
                                                                    <div className="sort-header">
                                                                        Process
                                                                        <span className={
                                                                                this.state.sort === sortProcess ? "material-icons" : "material-icons hide-sort-arrow"
                                                                            }
                                                                        >
                                                                            {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                                {/* <th width={"10%"}>Users Count</th> */}
                                                                <th className="text-center"  width={"10%"}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {
                                                                let actionObj = getDropdownActionArrayProcessTasks(item.allowEdit, item.allowReview, 
                                                                    item.status, item.currentReviewStatus, this.state.deletePermission, item.previousStatus);
                                                                let spanObj = getStatusSpan(item.previousStatus,themeSettings);
                                                                return <tr onClick={this.handleShowTaskDetailsLayout.bind(this,item.taskId)} >
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    <td>{removeHtmlTags(item.task)}
                                                                    {
                                                                        this.state.status === "Under Review" &&
                                                                        <span style={spanObj.spanStyle} className="span-tag">
                                                                            {spanObj.spanText}
                                                                        </span>
                                                                    }
                                                                    </td>
                                                                    <td>{ifEmptyReturnEmptyStr(item.riskLevel,"-")}</td>
                                                                    <td>{item.processNameList}</td>
                                                                    <td className="text-center">
                                                                    {
                                                                        actionObj.actionArr && actionObj.actionArr.length > 0 &&
                                                                        <TableDropDown 
                                                                            actionArr={actionObj.actionArr}
                                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                            dropDownId = {item}
                                                                            dropdownWidth={actionObj.dropdownWidth}
                                                                            themeSettings={themeSettings}
                                                                        />
                                                                    }
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings={themeSettings}/>
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.showTaskDetailsLayout &&
                                    <TaskDetailsLayout
                                        taskId = {this.state.selectedTaskIdForDetails}
                                        handleHideTaskDetailsLayout = {this.handleHideTaskDetailsLayout}
                                        subscriptionType={this.state.user.subscriptionType}
                                    />
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                (this.state.operationType === "Review Remarks")
                                ?
                                Constants.ALERT_TYPE_ALERT
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            themeSettings={themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

                        <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {"Task"}
                            masterDataId = {this.state.deleteReactivateId}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}

export default ListDraftTasks;