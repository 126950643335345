import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,getStatusSpan,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import TreeNodeDropdown from '../../../common/DropdownMenus/TreeNodeDropdown';
import AddProcessDialog from './addprocessdialog'
import TargetProcessTree from './targetProcessTree'
import AlertDialog from '../../../common/AlertDialog';
import AlertWithRadioDialog from '../../../common/AlertDialog/alertwithradio';
import ProcessLinkDeleteAlert from '../../../common/AlertDialog/processlinksdeletealert';
import {ExpandCollapseWrapper} from '../../../common/Global/globalStyles.style';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-navigations/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";

const userDetails  = getLocalStorageVariables();
const activeStatus="Active";
const createdStatus="Created";
const updatedStatus="Updated";
const deletedStatus="Deleted";
const underReviewStatus="Under Review";

const AddChildDropdownObj = {
    icon:"add_circle",
    label:"Add Child"
}
const AddSiblingDropdownObj = {
    icon:"add_box",
    label:"Add Sibling"
}
const editDropdownObj = {
    icon:"edit",
    label:"Edit"
}
const delDropdownObj = {
    icon:"delete",
    label:"Delete"
}
const linkDropdownObj = {
    icon:"link",
    label:"Link"
}

const actionArrRoot = [AddChildDropdownObj,editDropdownObj];
const actionArrNode = [AddChildDropdownObj,AddSiblingDropdownObj,editDropdownObj,delDropdownObj]
const actionArrChild = [AddChildDropdownObj,AddSiblingDropdownObj,editDropdownObj,delDropdownObj]
const actionArrChildNLink = [AddChildDropdownObj,AddSiblingDropdownObj,editDropdownObj,delDropdownObj]
const actionArrRootNChild = [AddChildDropdownObj,editDropdownObj];
const actionArrRootNChildNLink = [AddChildDropdownObj,editDropdownObj];

class ProcessTreeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader:false,

            processMap:{},
            syncFusionData:[],

            showCreateDialog:false,
            isEdit:false,
            createNodeType:"",
            selectedId:"",
            processName:"",
            relativeProcessId:"",
            relativeParentProcessId:"",
            clearAndResetProcessTree:true,
            idToBeExpandedAfterAddingChild:"",

            operationType:"",
            deleteReactivateId:"",
            deleteReactivateParentId:"",
            showAlertWithRadioDialog:false,
            showLinksAlertWithRadioDialog:false,
            alertWithRadioDialogMessage:"",
            DeleteNodeShowRadio:true,

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showTargetProcessTreeDialog:false,
        }
        this.fields = {};
        this.reloadListing = false;
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showProcessTreeDialog){
            this.fields = {
                dataSource:[],
                id:"processId",
                text:"processName",
                parentID:"parentId",
                child:"newChildren",
                expanded:"isExpanded"
            };

            this.setState({
                showLoader:false,
    
                processMap:{},
                syncFusionData:[],
    
                showCreateDialog:false,
                isEdit:false,
                createNodeType:"",
                selectedId:"",
                processName:"",
                relativeProcessId:"",
                relativeParentProcessId:"",
                clearAndResetProcessTree:true,
                idToBeExpandedAfterAddingChild:"",

                operationType:"",
                deleteReactivateId:"",
                deleteReactivateParentId:"",
                showAlertWithRadioDialog:false,
                showLinksAlertWithRadioDialog:false,
                alertWithRadioDialogMessage:"",

                showAlertDialogInfo:false,
                alertDialogMessageInfo:"",

                showTargetProcessTreeDialog:false,
            },()=>{
                this.getProcessTree();
            })
            
        }
    }

    handleAddEditDialogClose = (reloadFlag) =>{
        let id = "";
        if(this.state.createNodeType === "child") id = this.state.relativeProcessId;
        this.setState({
            showCreateDialog:false,
            clearAndResetProcessTree:false,
            isEdit:false,
            createNodeType:"",
            selectedId:"",
            processName:"",
            relativeProcessId:"",
            relativeParentProcessId:"",
            idToBeExpandedAfterAddingChild:id,
        },()=>{
            if(reloadFlag){
                this.reloadListing = true;
                this.getProcessTree();
            }
        })
    }

    handleTargetProcessTreeDialogClose = (reloadFlag) => {
        this.setState({
            showTargetProcessTreeDialog:false,
            deleteReactivateId:""
        },()=>{
            if(reloadFlag){
                this.reloadListing = true;
                this.getProcessTree();
            }
        })
    }

    onDropDownItemClick = (item,processObj) => {
        if(item.label === "Add Child"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"child",
                relativeProcessId:processObj.processId,
                
                isEdit:false,
            })
        }else if(item.label === "Add Sibling"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"sibling",
                relativeProcessId:processObj.processId,
                relativeParentProcessId:processObj.parentId,
                isEdit:false,
            })
        }else if(item.label === "Delete"){
            if(this.state.processMap[processObj.processId].hasChildren){
                //Since it has children show the radio buttons, and ask user
                //whether to delete the children or move the children to parent
                this.setState({
                    operationType:item.label,
                    deleteReactivateId:processObj.processId,
                    deleteReactivateParentId:processObj.parentId,
                    alertWithRadioDialogMessage:"Are you sure you want to delete "+processObj.processName+"?",

                    showAlertWithRadioDialog:true,
                    DeleteNodeShowRadio:true,
                });
            }else{
                if(processObj.processId === this.props.rootProcessId && processObj.linkCount > 0){
                    //root process will have usually link count 0,
                    //root process is both parent and child, and it is linked to other process then
                    //it will have linkCount > 0
                }else if(processObj.linkCount > 1){
                    this.setState({
                        operationType:item.label,
                        deleteReactivateId:processObj.processId,
                        deleteReactivateParentId:processObj.parentId,
                        alertWithRadioDialogMessage:"Are you sure you want to delete "+processObj.processName+"?",
    
                        showLinksAlertWithRadioDialog:true,
                        DeleteNodeShowRadio:true,
                    });
                }else{
                    //Node with no children and no links
                    this.setState({
                        operationType:item.label,
                        deleteReactivateId:processObj.processId,
                        deleteReactivateParentId:processObj.parentId,
                        alertWithRadioDialogMessage:"Are you sure you want to delete "+processObj.processName+"?",
    
                        showAlertWithRadioDialog:true,
                        DeleteNodeShowRadio:false,
                    });
                }
            }
        }else if(item.label === "Edit"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"sibling",
                relativeProcessId:processObj.processId,
                
                isEdit:true,
                selectedId:processObj.processId,
                processName:processObj.processName,
            })
        }else if(item.label === "Link"){
            this.setState({
                showTargetProcessTreeDialog:true,
                deleteReactivateId:processObj.processId,
            })
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleAlertWithDialogDialogClose = () =>{
        this.setState({
            showAlertWithRadioDialog:false,
            alertWithRadioDialogMessage:""
        });
    }

    handleLinkAlertWithDialogDialogClose = () =>{
        this.setState({
            showLinksAlertWithRadioDialog:false,
            alertWithRadioDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    getRootLevelProcesses = (processMapTemp) => {
        let rootProcessArr = [];
        Object.values(processMapTemp).map((item)=>{
            //this is backend level, it starts from 0, but front end level starts from 1
            if(item.level === 0){
                rootProcessArr.push(item);
            }
        })
        return rootProcessArr;
    }

    //No need of return value since the array itself is passed and updated.
    //Javascript maintains single array with pointers. it will not deep copy the array
    addParentIdandHasChildrenFlag = (tempArr, processId, level) => {
        tempArr[processId].level = level;

        if(!isEmptyArray(tempArr[processId].children)){
            tempArr[processId].hasChildren = true;
            tempArr[processId].children.map(childNode => {
                this.addParentIdandHasChildrenFlag(tempArr,childNode.processId,level+1);
            });
        }else{
            tempArr[processId].hasChildren = false;
        }
    }

    createSyncHRFusionData = (parentId,processId,processMap,isExpanded,isParentLink) => {
        //Now create an object
        let processObj = processMap[processId];
        processObj.newChildren = [];
        processObj.isExpanded = isExpanded;
        processObj.isParentLink = isParentLink;

        let isLink = false;
        if(parentId !== processId){
            processObj.parentId = parentId;
            if(processObj.linkCount > 1){
                isLink = true;
            }
        }else{
            if(processObj.linkCount > 0){
                isLink = true;
            }
        }
        processObj.isLink = isLink;

        //now add children recursively
        processObj.children.map((item)=>{
            processObj.newChildren.push(this.createSyncHRFusionData(processId,item.processId,processMap,false,processObj.isLink));
        });
        return processObj;
    }

    updateSyncHRFusionData = (parentId,processId,processMap) => {
        //Now create an object
        let processObj = processMap[processId];
        processObj.newChildren = [];

        //Check the old process map
        if(!isEmptyVariable(this.state.processMap[processId])){
            processObj.isExpanded = this.state.processMap[processId].isExpanded;
            processMap[processId].isExpanded = this.state.processMap[processId].isExpanded;
        }

        if(parentId !== processId){
            processObj.parentId = parentId;
        }
        //now add children recursively
        processObj.children.map((item)=>{
            processObj.newChildren.push(this.updateSyncHRFusionData(processId,item.processId,processMap));
        });
        return processObj;
    }
    
    ProcessTree = (processObj) => {
        // console.log(JSON.stringify(processObj))
        let spanObj = getStatusSpan(processObj.previousStatus,this.props.themeSettings);
        return <div className="dialog-tree-structure-sync">
        {
            !isEmptyVariable(processObj) &&
                <div className="tree-node-sync">
                    {
                        this.props.rootNode.allowEdit === "N" &&
                        <div className="tree-text-layout">
                            <p className={processObj.fontbold?"font-bold":""}>
                                {/* {processObj.hasChildren?processObj.processName:processObj.processName+" (Tasks: "+processObj.processId+")"} */}
                                {processObj.processName}
                                {/* {processObj.processName+" "+processObj.processId} */}
                            </p>
                            {
                                this.props.activeFlag === "N" && 
                                (processObj.status === underReviewStatus ||
                                    processObj.status === createdStatus ||
                                    processObj.status === updatedStatus ||
                                    processObj.status === deletedStatus) &&
                                <span style={spanObj.spanStyle} className="span-tag">
                                    {spanObj.spanText}
                                </span>
                            }
                            {
                                (processObj.isLink || processObj.isParentLink) &&
                                <span className="link-tree-tag">Link</span>
                            }
                        </div>
                    }
                    {
                        this.props.rootNode.allowEdit === "Y" &&
                        <div className="tree-dropdown-layout-sync">
                            <TreeNodeDropdown
                                // placeholder={processObj.hasChildren?processObj.processName:processObj.processName+" (Tasks: "+processObj.taskCount+")"}
                                placeholder={processObj.processName}
                                // placeholder={processObj.processName+" "+processObj.processId}
                                dropdownArr={
                                    processObj.level === 1
                                    ?
                                    processObj.hasChildren?actionArrRoot:(processObj.linkCount > 0?actionArrRootNChildNLink:actionArrRootNChild)
                                    :
                                    processObj.hasChildren?actionArrNode:(processObj.linkCount > 1?actionArrChildNLink:actionArrChild)
                                }
                                labelParam={"label"}
                                onDropDownItemClick={this.onDropDownItemClick}
                                dropdownId={processObj}
                                fontbold={processObj.fontbold}
                                paraMT={"1.5px"}
                                themeSettings={this.props.themeSettings}
                            />
                            {
                                this.props.activeFlag === "N" && 
                                (processObj.status === underReviewStatus ||
                                    processObj.status === createdStatus ||
                                    processObj.status === updatedStatus ||
                                    processObj.status === deletedStatus) &&
                                <span style={spanObj.spanStyle} className="span-tag">
                                    {spanObj.spanText}
                                </span>
                            }
                            {
                                (processObj.isLink || processObj.isParentLink) &&
                                <span className="link-tree-tag">Link</span>
                            }
                        </div>
                    }
                    
                </div>
            }
        </div>
    }

    expandAll = () => {
        this.reference.expandAll();
    }

    collapseAll = () => {
        this.reference.collapseAll();
    }

    dragStop = (args)=>{
        // console.log("Drag Node: "+JSON.stringify(args.draggedNodeData))
        // console.log("Drop Node: "+JSON.stringify(args.droppedNodeData))
        // console.log("Drop Level: "+args.dropLevel)

        //if the node is dropped above the parent node then cancel the drag
        if(args.dropLevel === 1){
            args.cancel = true;
        }
    }
    nodeExpanded = (args) =>{
        this.state.processMap[args.nodeData.id].isExpanded = true;
    }
    nodeCollapsed = (args) =>{
        this.state.processMap[args.nodeData.id].isExpanded = false;
    }
    nodeDrop = (args) => {
        console.log("Drag Node: "+JSON.stringify(args.draggedNodeData))
        console.log("Drop Node: "+JSON.stringify(args.droppedNodeData))
        console.log("Drop Index: "+args.dropIndex);
        console.log("Drop Level: "+args.dropLevel)

        let draggedNodeId = "";
        let draggedNodePid = "";

        let droppedNodeid = "";
        let droppedNodePid = "";
        let droppedPos = "";
        let dropLevel = "";

        if(!isEmptyVariable(args.draggedNodeData) && !isEmptyVariable(args.droppedNodeData)){
            draggedNodeId = args.draggedNodeData.id;
            draggedNodePid = args.draggedNodeData.parentID;

            droppedNodeid = args.droppedNodeData.id;
            droppedNodePid = args.droppedNodeData.parentID;
            droppedPos = args.dropIndex + 1;
            dropLevel = args.dropLevel;

            // now check what is the level of drop id
            let dropRelativeNodeLevel = this.state.processMap[droppedNodeid].level;
            console.log("Drop R Level: "+dropRelativeNodeLevel)
            //dropped level is greater than relative node, then it is a child of that relative node
            //hence parent id is relative node id
            if(dropLevel > dropRelativeNodeLevel){
                droppedNodePid = droppedNodeid;
            }

            this.moveTask(draggedNodeId,draggedNodePid,droppedNodePid,droppedPos);
        }
    }
    
    /************************API CALLS **************************/
    moveTask = (draggedNodeId,draggedNodePid,droppedNodePid,droppedPos) => {
        fetch(Constants.MoveProcess,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                rootProcessId:this.props.rootProcessId,
                processId:draggedNodeId,
                fromProcessId:draggedNodePid,
                toProcessId:droppedNodePid,
                toChildOrder:droppedPos,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            } else {
                this.reloadListing = true;
                this.getProcessTree();
            }
        });
    }

    getProcessTree = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetProcessesSubtree,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                rootProcessId:this.props.rootProcessId,
                status:"ALL"
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let temp = data.result.processMap;
                let rootProcessArr = this.getRootLevelProcesses(temp);

                rootProcessArr.map((rootNode)=>{
                    this.addParentIdandHasChildrenFlag(temp,rootNode.processId,1)
                })

                let syncFusionData = [];
                //clearAndResetProcessTree - if it is false, then copy the isShowingChildren param 
                //from old state array, this is required because whenever the user adds a new node at the nth level
                //all the nodes are collapsed and shows only first level nodes since the API is called again
                if(!isEmptyArray(Object.keys(this.state.processMap)) && 
                !this.state.clearAndResetProcessTree){
                    let processObj = this.updateSyncHRFusionData(this.props.rootProcessId,this.props.rootProcessId,temp);
                    processObj.fontbold = true;
                    syncFusionData.push(processObj);
                }else{
                    //create datastructure and Expand root level node's children by default
                    let processObj = this.createSyncHRFusionData(this.props.rootProcessId,this.props.rootProcessId,temp,true,false);
                    temp[this.props.rootProcessId].isExpanded = true;
                    processObj.fontbold = true;
                    syncFusionData.push(processObj);
                }
                //set the tree fields
                this.fields = {
                    dataSource:syncFusionData,
                    id:"processId",
                    text:"processName",
                    parentID:"parentId",
                    child:"newChildren",
                    expanded:"isExpanded"
                };

                this.setState({
                    processMap:temp,
                    idToBeExpandedAfterAddingChild:"",
                    syncFusionData:syncFusionData,
                    showLoader:false,
                    clearAndResetProcessTree:false
                },()=>{
                    this.reference.refresh();
                });
            }else{
                this.setState({
                    processMap:{},
                    showLoader:false,
                    syncFusionData:[],
                    clearAndResetProcessTree:false
                });
            }
        });
    }

    deleteActivateProcessNode = (deleteChildren,deleteAllLinks) => {
        let url = "";

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteProcess;
        }

        this.setState({
            showLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                processId:this.state.deleteReactivateId,
                parentProcessId:this.state.deleteReactivateParentId,
                rootProcessId:this.props.rootProcessId,
                deleteChildren:deleteChildren,
                deleteAllLinks:deleteAllLinks
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    deleteReactivateParentId:"",
                    operationType:"",
                    alertWithRadioDialogMessage:"",
                    showAlertWithRadioDialog:false,
                    showLinksAlertWithRadioDialog:false,
                    clearAndResetProcessTree:false
                },() => {
                    this.reloadListing = true;
                    this.getProcessTree();
                });
            }else{
                this.setState({
                    showLoader:false,
                    deleteReactivateId:"",
                    deleteReactivateParentId:"",
                    operationType:"",
                    alertWithRadioDialogMessage:"",
                    showAlertWithRadioDialog:false,
                    showLinksAlertWithRadioDialog:false,
                    clearAndResetProcessTree:false,

                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    handleProcessTreeClose = () => {
        this.props.handleProcessTreeDialogClose(this.reloadListing)
    }

    render() {
        let dnd = false;
        let deptId = "";
        if(!isEmptyVariable(this.props.rootNode) && this.props.rootNode.allowEdit === "Y"){
            dnd = true;
        }
        if(!isEmptyVariable(this.props.rootNode)){
            deptId = this.props.rootNode.departmentId;
        }
        return(
            <div>
                <Modal className="show-process-tree-dialog custom-dialog" 
                show={this.props.showProcessTreeDialog} onHide={this.handleProcessTreeClose}>
                    <Modal.Header>
                        <h5>Process Tree</h5>
                        <button 
                            onClick={this.handleProcessTreeClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div className="modal-loading-text">
                                <p
                                style={{
                                    background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                    color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                                }}
                                >Loading....</p>
                            </div>
                        }
                        <ExpandCollapseWrapper  themeSettings={this.props.themeSettings}>
                            <div className="expand-collapse-layout"
                                onClick={this.expandAll}
                                style={{marginRight:10}}
                                type="button">
                                <span class="material-icons">unfold_more</span>
                                <p>Expand All</p>
                            </div>
                            <div
                                className="expand-collapse-layout"
                                onClick={this.collapseAll}
                                style={{marginLeft:10}}
                                type="button">
                                <span class="material-icons">unfold_less</span>
                                <p>Collapse All</p>
                            </div>

                        </ExpandCollapseWrapper>

                        <TreeViewComponent 
                            fields={this.fields} 
                            allowDragAndDrop={dnd}
                            nodeTemplate={this.ProcessTree}
                            ref = {(treeNode) => {this.reference = treeNode}}
                            nodeDragStop={this.dragStop}
                            nodeDropped = {this.nodeDrop}
                            nodeExpanded = {this.nodeExpanded}
                            nodeCollapsed = {this.nodeCollapsed}
                        />
                    </div>
                </Modal>

                <AddProcessDialog
                    isEdit={this.state.isEdit}
                    showCreateDialog={this.state.showCreateDialog}
                    handleAddEditDialogClose={this.handleAddEditDialogClose}
                    createNodeType={this.state.createNodeType}
                    relativeProcessId={this.state.relativeProcessId}
                    relativeParentProcessId={this.state.relativeParentProcessId}

                    departmentId={deptId}
                    rootProcessId={this.props.rootProcessId}

                    selectedId={this.state.selectedId}
                    processName={this.state.processName}
                    themeSettings={this.props.themeSettings}
                />

                <AlertWithRadioDialog
                    showAlertDialog={this.state.showAlertWithRadioDialog}
                    handleAlertDialogClose={this.handleAlertWithDialogDialogClose}
                    alertDialogHeading={"Delete"}
                    alertDialogMessage={this.state.alertWithRadioDialogMessage}
                    proceedBtnClick={this.deleteActivateProcessNode}
                    proceedBtnLabel={"Delete"}
                    showRadio={this.state.DeleteNodeShowRadio}
                />

                <ProcessLinkDeleteAlert
                    showAlertDialog={this.state.showLinksAlertWithRadioDialog}
                    handleAlertDialogClose={this.handleLinkAlertWithDialogDialogClose}
                    alertDialogHeading={"Delete"}
                    alertDialogMessage={this.state.alertWithRadioDialogMessage}
                    proceedBtnClick={this.deleteActivateProcessNode}
                    proceedBtnLabel={"Delete"}
                />

                <AlertDialog 
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={this.props.themeSettings}
                />

                <TargetProcessTree
                    showTargetProcessTreeDialog={this.state.showTargetProcessTreeDialog}
                    handleTargetProcessTreeDialogClose={this.handleTargetProcessTreeDialogClose}
                    childProcess = {this.state.deleteReactivateId}
                    rootProcessId = {this.props.rootProcessId}
                    themeSettings={this.props.themeSettings}
                />
            </div>
        );
    }
}

export default ProcessTreeDialog;