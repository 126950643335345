import {
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  Paragraph,
  WidthType,
  BorderStyle,
  TextRun,
  ImageRun,
  VerticalAlign,
} from "docx";
import { isEmptyVariable } from "../../../common/Global/commonFunctions";
import ChartJsImage from "chartjs-to-image";

export const getAuditConclusionPageContent = (
  assignmentDetails,
  processGroupDataList,
  executionTemplateValuesMap,
  companyLogo,
  isLogoAvailable,allAuditorList
) => {
  let auditConclusionContent = [];
  let unitName = String(assignmentDetails.unitAddressList).split(":-:")[0]+" ("+ JSON.stringify(assignmentDetails.unitNameCityList).split("(")[0].replace('"', "").trim()+")";
  let fontName = "Times New Roman";
  let commonSize = 17;
  let commonBorderStyle = {
    style: BorderStyle.SINGLE,
    color: "000000",
    size: 3,
  };
  let tempTableRow = [];

  /// LOGO

  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 3,

          borders: {
            bottom: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 80,
          },
          children: [
            new Paragraph({
              children: [
                !isEmptyVariable(isLogoAvailable) &&
                  new ImageRun({
                    data: companyLogo,
                    transformation: {
                      width: 100,
                      height: 30,
                    },
                  }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 15,
          borders: {
            bottom: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "\taudit conclusion",
                  size: commonSize + 2,
                  font: fontName,
                  allCaps: true,
                  bold: true,
                }),
              ],
            }),
          ],
        }),

        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 10,
          borders: {
            bottom: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "\t",
                  size: commonSize,
                  font: fontName,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// Project
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          columnSpan: 30,

          borders: {
            bottom: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Project: ",
                  bold: true,
                  font: fontName,
                  size: commonSize,
                }),
                new TextRun({
                  text: unitName,
                  font: fontName,
                  size: commonSize,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// Iso cell description...
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          columnSpan: 30,

          borders: {
            bottom: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
            right: 10,
          },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  // text: "ISO cell would like to thank the entire project team for their support in sharing the desired information to complete the internal audit process smoothly. The objective of the audit was to check conformance of ISO 9001 and Organisation own Requirement. The audit started with an opening meeting where the audit scope, criteria, methodology, sampling principle were explained. The audit was conducted at your site using the sampling method. The technique used were interview, observation, sampling and review of documents.",
                  text: "ISO cell would like to thank the entire team for their support to complete the internal audit process smoothly. The objective of the audit was to check conformance of ISO 9001 and Organisation own Requirement. The audit scope, criteria, methodology, sampling principle were explained during the audit process. The audit was conducted based on sampling method. The technique used were interview, observation, sampling, and review of documents",
                  size: commonSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// The audit description...
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          columnSpan: 30,

          borders: {
            bottom: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
            right: 10,
          },

          children: [
            new Paragraph({
              spacing: {
                before: 20,
              },
              children: [
                new TextRun({
                  // text: "The audit outcome was communicated in the closing meeting to the Project Manager / HOD and process representative.",
                  text: "The audit outcome was communicated to the process representative.",
                  size: commonSize,
                  font: fontName,
                }),
              ],
            }),
            new Paragraph({
              spacing: {
                before: 20,
              },
              children: [
                new TextRun({
                  // text: "Audit findings will be verified in the subsequent internal audit process.",
                  text: "Audit findings will be verified in the subsequent internal audit process.",
                  size: commonSize,
                  font: fontName,
                }),
              ],
            }),

            // new Paragraph({
            //   spacing: {
            //     before: 20,
            //   },
            //   children: [
            //     new TextRun({
            //       text: "The knowledge capture by the process owner is a part of this report and we expect that the same shall be shared with knowledge service department at HO for its dissemination.",
            //       size: commonSize,
            //       font: fontName,
            //     }),
            //   ],
            // }),

            new Paragraph({
              children: [
                new TextRun({
                  // text: "Quantitative approach was adopted to measure the performance of QMS across the Organisation.",
                  text: "Quantitative approach was adopted to measure the performance of QMS across the Organisation.",
                  size: commonSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  let cTotalCount = 0;
  let ncTotalCount = 0;
  let pncTotalCount = 0;
  let obsTotalCount = 0;
  let tempProcessTableRows = [];

  /// C, OBS, NC counting Algorithem
  Object.values(processGroupDataList).map((process) => {
    let cTempCount = 0;
    let ncTempCount = 0;
    let pncTempCount = 0;
    let obsTempCount = 0;

    Object.values(process.taskList).map((task) => {
      let assignmentId = task.assignmentTaskId;

      Object.entries(executionTemplateValuesMap).map(([execKey, execValue]) => {
        if (execKey == assignmentId) {
          Object.values(execValue).map((val) => {
            if (val.value === "OBS") {
              obsTempCount++;
            } else if (val.value === "NC") {
              ncTempCount++;
            } else if (val.value === "PNC") {
              pncTempCount++;
            } else if (val.value === "C") {
              cTempCount++;
            }
          });
        }
      });
    });

    tempProcessTableRows.push({
      processName: process.functionName,
      cCount: cTempCount !== 0 ? cTempCount : "0",
      ncCount: ncTempCount !== 0 ? ncTempCount : "0",
      pncCount: pncTempCount !== 0 ? pncTempCount : "0",
      obsCount: obsTempCount !== 0 ? obsTempCount : "0",

    });
    cTotalCount = cTotalCount + cTempCount;
    ncTotalCount = ncTotalCount + ncTempCount;
    pncTotalCount = pncTotalCount + pncTempCount;
    obsTotalCount = obsTotalCount + obsTempCount;
  });

  // let perfomanceQuality =
  //   (
  //     (Math.max(cTotalCount, ncTotalCount, obsTotalCount) * 100) /
  //     (cTotalCount + ncTotalCount + obsTotalCount)
  //   ).toFixed(1) + " %";
  let tempPerfomanceQuality =
    ([cTotalCount / (obsTotalCount + cTotalCount + ncTotalCount)]*100);
  let perfomanceQuality = "";
  if (tempPerfomanceQuality <= 0) {
    perfomanceQuality = "0 %";
  } else {
    perfomanceQuality = parseFloat(tempPerfomanceQuality).toFixed(2) + " %";
  }

  /// The performance
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 30,
          borders: {
            bottom: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "The performance of Quality Management System at site: ",
                  size: commonSize,
                  bold: true,
                  font: fontName,
                }),
                new TextRun({
                  text: perfomanceQuality < 0 ? "0 %" : perfomanceQuality,
                  size: 20,

                  font: fontName,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  const barChartData = new ChartJsImage();
  barChartData.setConfig({
    type: "horizontalBar",
    data: {
      labels: ["NC","OBS","C","PNC"],

      datasets: [
        {
          fontColor: "#000000",
          barPercentage: 0.5,
          data: [ncTotalCount, obsTotalCount, cTotalCount,pncTotalCount],

          backgroundColor: [
            "rgba(255,1,1,255)",
            "rgba(246,151,70,255)",
            "rgba(1,177,80,255)",
            "rgba(84,143,213,255)"
          ],
        },
      ],
    },
    options: {
      legend: {
        display: false,
      },

      scales: {
        xAxes: [
          {
            scaleFontSize: 20,
            ticks: {
              fontColor: "#000000",
              precision: 0,
              beginAtZero: true,
            },
          },
        ],

        yAxes: [
          {
            ticks: {
              scaleFontSize: 20,
              fontColor: "#000000",
            },
          },
        ],
      },
      indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
    },
  });
  let getHorizontalChart = generateBlobFromUrl(barChartData.getUrl());
  const pieChartData = new ChartJsImage();

  let neddleFormula =
    ([cTotalCount / (obsTotalCount + cTotalCount + ncTotalCount)] *100);
  let displayChartData = [];
  let backgroundColorUp = [];
  let backgroundColorDown = [];
  let pinkColor = "rgba(255,1,1,255)";
  let greenColor = "rgba(1,177,80,255)";
  let brownColor = "rgba(246,151,70,255)";
  let neddleColor = "rgba(1,1,0,255)";
  let whiteColor = "rgba(0, 0, 0, 0)";

  if (neddleFormula > 0) {
    if (neddleFormula < 50) {
      displayChartData.push(neddleFormula - 1);
      backgroundColorUp.push(pinkColor);
      backgroundColorDown.push(whiteColor);

      displayChartData.push(1);
      backgroundColorUp.push(neddleColor);
      backgroundColorDown.push(neddleColor);

      displayChartData.push(50 - neddleFormula);
      backgroundColorUp.push(pinkColor);
      backgroundColorDown.push(whiteColor);

      displayChartData.push(40);
      backgroundColorUp.push(brownColor);
      backgroundColorDown.push(whiteColor);

      displayChartData.push(10);
      backgroundColorUp.push(greenColor);
      backgroundColorDown.push(whiteColor);
    } else if (neddleFormula >= 50 && neddleFormula < 90) {
      displayChartData.push(50);
      backgroundColorUp.push(pinkColor);
      backgroundColorDown.push(whiteColor);

      displayChartData.push(neddleFormula-50-1);
      backgroundColorUp.push(brownColor);
      backgroundColorDown.push(whiteColor);

      displayChartData.push(1);
      backgroundColorUp.push(neddleColor);
      backgroundColorDown.push(neddleColor);

      displayChartData.push(90 - neddleFormula);
      backgroundColorUp.push(brownColor);
      backgroundColorDown.push(whiteColor);
      
      displayChartData.push(10);
      backgroundColorUp.push(greenColor);
      backgroundColorDown.push(whiteColor);

    } else if (neddleFormula >= 90) {
      displayChartData.push(50);
      backgroundColorUp.push(pinkColor);
      backgroundColorDown.push(whiteColor);

      displayChartData.push(40);
      backgroundColorUp.push(brownColor);
      backgroundColorDown.push(whiteColor);

      displayChartData.push(neddleFormula-90- 1);
      backgroundColorUp.push(greenColor);
      backgroundColorDown.push(whiteColor);

      displayChartData.push(1);
      backgroundColorUp.push(neddleColor);
      backgroundColorDown.push(neddleColor);

      displayChartData.push(100-neddleFormula);
      backgroundColorUp.push(greenColor);
      backgroundColorDown.push(whiteColor);
    }
  } else {
    displayChartData.push(1);
    backgroundColorUp.push(neddleColor);
    backgroundColorDown.push(neddleColor);

    displayChartData.push(49);
    backgroundColorUp.push(pinkColor);
    backgroundColorDown.push(whiteColor);

    displayChartData.push(40);
    backgroundColorUp.push(brownColor);
    backgroundColorDown.push(whiteColor);

    displayChartData.push(10);
    backgroundColorUp.push(greenColor);
    backgroundColorDown.push(whiteColor);
  }

  pieChartData.setConfig({
    type: "doughnut",
    data: {
      datasets: [
        {
          data: displayChartData,
          backgroundColor: backgroundColorUp,
          borderWidth: 0,
          datalabels: {
            color: "rgba(0,0,0,. 5)",
          },
        },
        {
          data: displayChartData,
          backgroundColor: backgroundColorDown,
          borderWidth: 0,
          datalabels: {
            color: "rgba(0,0,0,. 5)",
          },
        },
      ],
    },
    options: {
      cutoutPercentage: 0,
      rotation: -3.1415926535898,
      circumference: 3.1415926535898,
      title: {
        display: true,
        text: perfomanceQuality,
        position: "bottom",  
        fontSize:18,
        color:"black"
        
    }

    },
  });
  let getPieChart = generateBlobFromUrl(pieChartData.getUrl());

  /// bar and Pie chart
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 30,
          borders: {
            bottom: commonBorderStyle,
          },

          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.DISTRIBUTE,
              children: [
                new ImageRun({
                  data: getHorizontalChart,
                  transformation: {
                    width: 270,
                    height: 200,
                  },
                }),
                new TextRun({
                  text: "\t\t\t\t",
                }),
                new ImageRun({
                  data: getPieChart,
                  transformation: {
                    width: 270,
                    height: 180,
                  },
                }),
              ],
            }),

            new Paragraph({
              alignment: AlignmentType.RIGHT,

              children: [
                new TextRun({
                  text: "■",
                  size: 30,
                  color: '#ff0101',
                }),
                new TextRun({
                  text: " <50",
                  size: 18,
                }),
                new TextRun({
                  text: "   ■",
                  size: 30,
                  color: '#f69746',
                }),
                new TextRun({
                  text: " <90",
                  size: 18,
                }),
                new TextRun({
                  text: "   ■",
                  size: 30,
                  color: '#01b150',
                }),
                new TextRun({
                  text: " >90",
                  size: 18,
                }),
                new TextRun({
                  text: "\t\t",
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// summery of Audit finding
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 30,

          margins: {
            top: 50,
            bottom: 50,
          },
          borders: {
            bottom: commonBorderStyle,
          },
          children: [
            new Paragraph({
              spacing: {
                after: 10,
              },
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Summary of Audit findings",
                  size: commonSize,
                  bold: true,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  auditConclusionContent.push(
    new Table({
      margins: {
        bottom: 50,
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        top: { style: BorderStyle.NONE },
        bottom: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE },
        right: { style: BorderStyle.NONE },
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: tempTableRow,
    })
  );

  tempTableRow = [];
  /// S.No, Department, Audit No.
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          width: {
            type: WidthType.PERCENTAGE,
            size: 1,
          },
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 1,

          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "S.NO",
                  size: commonSize,
                  bold: true,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 19,
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Department",
                  size: commonSize,
                  font: fontName,
                  bold: true,
                }),
              ],
            }),
          ],
        }),

        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 1,
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "C",
                  size: commonSize,
                  font: fontName,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 3,
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "OBS",
                  size: commonSize,
                  font: fontName,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 2,
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "NC",
                  size: commonSize,
                  font: fontName,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 2,
          borders: {
            bottom: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "PNC",
                  size: commonSize,
                  font: fontName,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// Process Table with NC, C, OBS count
  tempProcessTableRows.map((data, idx) => {
    tempTableRow.push(
      new TableRow({
        children: [
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 1,

            borders: {
              bottom: commonBorderStyle,
              right: commonBorderStyle,
            },
            margins: {
              top: 50,
              bottom: 50,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: idx + 1,
                    size: commonSize,
                    font: fontName,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 19,
            borders: {
              bottom: commonBorderStyle,
              right: commonBorderStyle,
            },
            margins: {
              top: 50,
              bottom: 50,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.processName,
                    size: commonSize,
                    font: fontName,
                  }),
                ],
              }),
            ],
          }),

          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 1,
            borders: {
              bottom: commonBorderStyle,
              right: commonBorderStyle,
            },
            margins: {
              top: 50,
              bottom: 50,
              left: 50,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.cCount,
                    size: commonSize,
                    font: fontName,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 3,
            borders: {
              bottom: commonBorderStyle,
              right: commonBorderStyle,
            },
            margins: {
              top: 50,
              bottom: 50,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.obsCount,
                    size: commonSize,
                    font: fontName,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 2,
            borders: {
              bottom: commonBorderStyle,
              right: commonBorderStyle,
            },
            margins: {
              top: 50,
              bottom: 50,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.ncCount,
                    size: commonSize,
                    font: fontName,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 2,
            borders: {
              bottom: commonBorderStyle,
            },
            margins: {
              top: 50,
              bottom: 50,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.pncCount,
                    size: commonSize,
                    font: fontName,
                  }),
                ],
              }),
            ],
          }),
        ],
      })
    );
  });

  /// Process Table Total of NC, C, OBS count
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 20,
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Total",
                  size: commonSize,
                  font: fontName,
                  bold: true,
                  
                }),
              ],
            }),
          ],
        }),

        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 1,
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: cTotalCount !== 0 ? cTotalCount : "0",
                  size: commonSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 3,
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: obsTotalCount !== 0 ? obsTotalCount : "0",
                  size: commonSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 2,
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: ncTotalCount !== 0 ? ncTotalCount : "0",
                  size: commonSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 2,
          borders: {
            bottom: commonBorderStyle,
          },
          margins: {
            top: 50,
            bottom: 50,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: pncTotalCount !== 0 ? pncTotalCount : "0",
                  size: commonSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  auditConclusionContent.push(
    new Table({
      margins: {
        bottom: 50,
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        top: { style: BorderStyle.NONE },
        bottom: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE },
        right: { style: BorderStyle.NONE },
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: tempTableRow,
    })
  );

  tempTableRow = [];
  /// Defination
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          columnSpan: 30,

          margins: {
            top: 50,
            bottom: 50,
          },
          borders: {
            bottom: commonBorderStyle,
          },
          children: [
            new Paragraph({
              spacing: {
                after: 10,
              },
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Definition",
                  size: commonSize,
                  bold: true,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// all full form of OBS,NC,C
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          // verticalAlign: VerticalAlign.CENTER,
          columnSpan: 30,

          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          borders: {
            bottom: commonBorderStyle,
          },
          children: [
            new Paragraph({
              spacing: {
                after: 50,
              },

              children: [
                new TextRun({
                  text: "Confirmance (c): Fulfillment of a requirement.",
                  size: commonSize,
                  font: fontName,
                }),
              ],
            }),
            new Paragraph({
              spacing: {
                after: 50,
              },

              children: [
                new TextRun({
                  text: "Observation (OBS): Lapse in fulfillment of a requirement leading to potential Nonconfirmity.",
                  size: commonSize,
                  font: fontName,
                }),
              ],
            }),
            new Paragraph({
              spacing: {
                after: 50,
              },

              children: [
                new TextRun({
                  text: "NonConfirmity (NC): Nonfulfillment of a requirement.",
                  size: commonSize,
                  font: fontName,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// signature
  tempTableRow.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.BOTTOM,
          columnSpan: 30,

          margins: {
            top: 150,
            bottom: 50,
            right: 300,
          },

          children: [
            new Paragraph({
              spacing: {
                after: 20,
              },
              alignment: AlignmentType.END,
              children: [
                new TextRun({
                  text: allAuditorList.map(e=>e.userName).join(" "),
                  size: commonSize+3,
                  font: "Mistral",
                  // underline:true
                }),
              ],
            }),
            new Paragraph({
              spacing: {
                after: 20,
              },
              alignment: AlignmentType.END,
              children: [
                new TextRun({
                  text: "Signature",
                  size: commonSize ,
                  font: fontName,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  auditConclusionContent.push(
    new Table({
      margins: {
        bottom: 50,
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        top: { style: BorderStyle.NONE },
        bottom: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE },
        right: { style: BorderStyle.NONE },
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: tempTableRow,
    })
  );

  return [...auditConclusionContent];
};

export const generateBlobFromUrl = (chartData) => {
  if (isEmptyVariable(chartData)) {
    return null;
  }
  return fetch(chartData)
    .then((resp) => resp.blob())
    .then((data) => data);
};

