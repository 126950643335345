import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import moment from "moment";
import * as Constants from '../../../common/Global/constants';
import {isEmptyVariable,getStringWithSpaceAfterComma,SplitJoinString,
    isEmptyArray} from '../../../common/Global/commonFunctions';

const requestChangeCheckText="Request Change";
const activeStatus="Reviewed";
const activeStatusLabel="Active";
const inActiveStatus="Inactive";

class FollowUpDetailsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showDetailsDialog){
            console.log("come in modal....")
        }
    }

    render() {

        return(
            <Modal className="custom-dialog" show={this.props.showDetailsDialog} onHide={this.props.handleDetailsDialogClose}>
                <Modal.Header>
                    <h5>{this.props.followUpDetailsItem?.referenceNo}</h5>
                    <button 
                        onClick={this.props.handleDetailsDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div class="modal-body">
                    <div className="details-table-layout">
                        <div className="row custom-row">
                            <div className="col-md-12 custom-col">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td width={"24%"}>Month</td>
                                            <td width={"4%"}>:</td>
                                            <td width={"72%"}>{moment(this.props.followUpDetailsItem?.planDate).format('MMM - YYYY')}</td>
                                        </tr>
                                        {
                                            this.props.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                            <tr>
                                                <td width={"24%"}>Client</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.props.followUpDetailsItem?.companyName}</td>
                                            </tr>
                                        }
                                        <tr>
                                            <td width={"24%"}>Assignment Type</td>
                                            <td width={"4%"}>:</td>
                                            <td width={"72%"}>{this.props.followUpDetailsItem?.assignmentTypeName} ({this.props.followUpDetailsItem?.assignmentTypeShortName})</td>
                                        </tr>
                                        <tr>
                                            <td width={"24%"}>Units</td>
                                            <td width={"4%"}>:</td>
                                            <td width={"72%"}>
                                                {getStringWithSpaceAfterComma(this.props.followUpDetailsItem?.unitNameCityList)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width={"24%"}>Scope</td>
                                            <td width={"4%"}>:</td>
                                            <td width={"72%"}>
                                                {
                                                isEmptyVariable(this.props.followUpDetailsItem?.plannedAssignmentArea)?
                                                this.props.followUpDetailsItem?.assignmentArea:
                                                this.props.followUpDetailsItem?.plannedAssignmentArea
                                                }
                                            </td>
                                        </tr>
                                        {
                                            (this.props.followUpDetailsItem?.plannedAssignmentArea === "Process" || 
                                            this.props.followUpDetailsItem?.assignmentArea === "Process") &&
                                            !isEmptyVariable(this.props.followUpDetailsItem?.processList) &&
                                            <tr>
                                                <td width={"24%"}>Process</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{SplitJoinString(this.props.followUpDetailsItem?.processList,",",":-:",", ")}</td>
                                            </tr>
                                        }
                                        {
                                            (this.props.followUpDetailsItem?.plannedAssignmentArea === "Function" || 
                                            this.props.followUpDetailsItem?.assignmentArea === "Function") &&
                                            !isEmptyVariable(this.props.followUpDetailsItem?.functionList) &&
                                            <tr>
                                                <td width={"24%"}>Function</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{SplitJoinString(this.props.followUpDetailsItem?.functionList,",",":-:", ", ")}</td>
                                            </tr>
                                        }

                                        <tr>
                                            <td width={"24%"}>Report Format</td>
                                            <td width={"4%"}>:</td>
                                            <td width={"72%"}>{this.props.followUpDetailsItem?.reportFormat}</td>
                                        </tr>

                                        <tr>
                                            <td width={"24%"}>Status</td>
                                            <td width={"4%"}>:</td>
                                            <td width={"72%"}>
                                                {this.props.followUpDetailsItem?.status=== activeStatus
                                                ?activeStatusLabel 
                                                :this.props.followUpDetailsItem?.status}
                                                </td>
                                        </tr>
                                        {
                                            this.props.followUpDetailsItem?.status !== activeStatus &&
                                            this.props.followUpDetailsItem?.status !== inActiveStatus &&
                                            <tr>
                                                <td width={"24%"}>Review Status</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.props.followUpDetailsItem?.currentReviewStatus}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default FollowUpDetailsDialog;