import { getThemeSettingsVariables, isEmptyArray, isEmptyVariable, removeHtmlTags, } from "../../../common/Global/commonFunctions";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import Pagination from '../../../common/Global/pagination';
import { SearchSpan } from "../../../common/Global/globalStyles.style";
import * as Constants from '../../../common/Global/constants';
import * as TemplateConstants from '../../../common/Global/templateConstants';


const themeSettings = getThemeSettingsVariables();
export default class InfoCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      isComponentDidMount: false,
      tableHeaderRow: [],
      tableBodyRowData: [],
      oldTableBodyRowData: [],
      filterDropDownArr: [],
      filterPlaceholder: "",
      totalCount:0,
      currentPageNo: 1,
      resultSizePlaceholder:Constants.RESULT_SIZE,
      resultSize:Constants.RESULT_SIZE,
      searchkey:"",
      funProsPlaceholder:"All",
      funPorsFilterArray:[
        {
          label:"All",
          isSelected:true
        }
      ]
    };
  }

  componentDidMount() {
    this.getCalculatedDataValues();
  }

  componentDidUpdate =(prevPros) => { 
    if(JSON.stringify(prevPros) !== JSON.stringify(this.props)){
      this.getCalculatedDataValues();
    }
  }

  getCalculatedDataValues = () => {
    Object.values(this.props.datalist).length &&
    Object.values(this.props.datalist).map((item)=>{
      if(!this.state.funPorsFilterArray.some(ele=>ele.label==(this.props.assignmentArea=="Function"?item.functionName:item.processName)))
      {
        this.state.funPorsFilterArray.push({
          label:this.props.assignmentArea=="Function"?item.functionName:item.processName,
          isSelected:false
        })
      }
    })

    let totalSingleSelectList = [];
    let tableList = [];
    let filterDropDownArr = [];
    Object.values(this.props.execTaskTemplateFields).length &&
     Object.values(this.props.execTaskTemplateFields).map((item) => {
      if (item.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT) {
        totalSingleSelectList.push(item);
      }
      if(item.fieldType === TemplateConstants.FIELD_TYPE_TABLE){
        tableList.push(item);
      }
    });

    totalSingleSelectList.map(item => {
      filterDropDownArr.push({
        label: item.fieldName,
        isSelected: false
      })
    })
    tableList.map(item => {
      filterDropDownArr.push({
        label: item.fieldName,
        isSelected: false
      })
    })
    this.setState({
      isComponentDidMount: true,
      filterDropDownArr: filterDropDownArr,
      totalSingleSelectList: totalSingleSelectList,
    },() => {
      this.onDropDownItemSelectClick(this.state.filterDropDownArr[0]);
    });
  };

  onDropDownItemSelectClick=(selectedItem)=>{
    this.setState({
      showLoader: true
    })

    if(this.state.totalSingleSelectList.length === 0){
      this.setState({
        showLoader:false
      })
    }
    let tempFilterDropdownArray = this.state.filterDropDownArr;
    Object.values(tempFilterDropdownArray).map((arr) => {
      if (selectedItem.label === arr.label) {
        arr.isSelected = true;
      } else {
        arr.isSelected = false;
      }
    });

    Object.values(this.props.execTaskTemplateFields).length &&
     Object.values(this.props.execTaskTemplateFields).map((item) => {
       if (item.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT) {
        if (item.fieldName === selectedItem.label) {
          let tempTableHeaderRow = [];
          let tempFiledId = item.fieldId;
          let tempTableBodyRow = [];
          Object.values(this.props.datalist).map((task) => {
            let tempTotalValueList = [];
            let tempRowList = [];
            //function name add in row
            tempRowList.push(this.props.assignmentArea=="Function"?task.functionName:task.processName);
            Object.values(task.taskList).map((t) => {
              let tempassignmentTaskId = t.assignmentTaskId;
              Object.entries(this.props.executionTemplateValuesMap).map(
                ([execkey, execVal]) => {
                  if (execkey == tempassignmentTaskId) {
                    Object.values(execVal).map((val) => {
                      if (val.fieldId == tempFiledId) {
                        tempTotalValueList.push(val.value);
                      }
                    });
                  }
                }
              );
            });

            Object.values(item.dropdownValues).map((val) => {
              let count = 0;
              tempTotalValueList.map((e) => {
                if (e === val.value) {count++}
              });
              tempRowList.push(count);
            });
            tempTableBodyRow.push(tempRowList);
          });
          tempTableHeaderRow = item.dropdownValues

          let totalCountList =["Total"];
          Object.values(tempTableBodyRow).map((item,idx)=>{
            if(idx==0){
              for(var i=1;i<item.length;i++){
                totalCountList.push(0);
              }
            }

            Object.values(item).map((val,i)=>{
              if(i!==0){
                  totalCountList[i]+=val
                }
              })
          })
          
          if(!isEmptyArray(this.props.datalist)){
            tempTableBodyRow.push(totalCountList)
          }

          this.setState({
            showLoader: false,
            tableHeaderRow: tempTableHeaderRow,
            tableBodyRowData: tempTableBodyRow,
            filterPlaceholder: selectedItem.label,
            filterDropDownArr: tempFilterDropdownArray,
            resultSize:Constants.RESULT_SIZE_GRID,
            resultSizePlaceholder:Constants.RESULT_SIZE_GRID,
            currentPageNo:1,
            selectionType:item.fieldType
          });
        }
      }else if(item.fieldType === TemplateConstants.FIELD_TYPE_TABLE){
        if (item.fieldName === selectedItem.label) {
          let tempFiledId = item.fieldId;
        
          Object.values(this.state.funPorsFilterArray).map((item)=>{
            if(item.label=="All"){
              item.isSelected=true
            }else{
              item.isSelected=false
            }
          })

          let tempTableHeaderRow =[];
          let tempTableBodyRow =[]; 
          let tempTotalCount =0;

          //filter fieldType Text data for table header column
          let tempTextRow = this.props.taskTemplateFields.filter(item=> item.fieldType === TemplateConstants.FIELD_TYPE_TEXT)
          
          //set table header column - (fieldtype:-Text), Select item of filter (fieldtype:- Table) & (fieldtype:-Single-select)
          tempTableHeaderRow.push(tempTextRow[0].fieldName, selectedItem.label)
          this.state.totalSingleSelectList.map(item => {
            tempTableHeaderRow.push(item.fieldName)
          })


          Object.values(this.props.datalist).map((item)=>{
            Object.values(item.taskList).map((task)=>{
              let tempData =[];
              let tempTaskId = task.taskId;
              let tempAssignmentTaskId = task.assignmentTaskId;
              
              //set table 1st column - (fieldtype:-Text)
              Object.entries(this.props.taskTemplateValuesMap).map(([key,val])=>{
                if(key==tempTaskId){
                  Object.values(val).map((e)=>{
                    if(e.fieldId==tempTextRow[0].fieldId){
                      tempData.push(removeHtmlTags(e.value))
                    }
                  })
                }
              })
              Object.entries(this.props.executionTemplateValuesMap).map(([key,val])=>{
                if(key==tempAssignmentTaskId){
                  Object.values(val).map((e)=>{
                    //set table 2nd column - (fieldtype:-Table)
                    if(e.fieldId==tempFiledId){
                      let recordData = !isEmptyVariable(e.value)?JSON.parse(e.value):"";
                      if(!isEmptyVariable(recordData) && Object.values(recordData.tableArray).length>0){
                        if(recordData.isFirstRowTypeDisable=="Y" && ((recordData.tableArray[0][1]).toLocaleLowerCase()=="no"||recordData.tableArray[0][1].toLocaleLowerCase()=="na")){
                          tempData.push("All cell Are Filled.")
                        }else{
                          let totalLength = Object.values(recordData.tableArray).length;
                          let emptyCellCount =0;
                          Object.values(recordData.tableArray).map((array)=>{
                            if(isEmptyVariable(this.getFirstRowItemValue(array[1]))){
                              emptyCellCount++;
                            }
                          })
                          if(emptyCellCount>0){
                            tempData.push(`${emptyCellCount} Blanks Out Of ${totalLength}`)
                          }else{
                            tempData.push("All cell Are Filled.")
                          }
                        }
                      }
                    }

                    //set table 3rd column to ... - (fieldtype:-Single-select)
                    this.state.totalSingleSelectList.map(item => {
                      if(e.fieldId==item.fieldId){
                        if(!isEmptyVariable(e.value)){
                          tempData.push(e.value)
                        }else{
                          tempData.push("Pending") 
                        }
                      }
                    })
                  })

                  if(!Object.values(val).some((ele=>(ele.fieldId==tempFiledId)))){
                    tempData.push("-") 
                  }
                  this.state.totalSingleSelectList.map(item => {
                    if(!Object.values(val).some((ele=>(ele.fieldId==item.fieldId)))){
                      tempData.push("-") 
                    }
                  })
                }
              })
            
              tempTotalCount+=tempData.length
              tempTableBodyRow.push({
                name:this.props.assignmentArea=="Function"?item.functionName:item.processName,
                value:tempData
              })
            })
          })
        
          this.setState({
            showLoader:false,
            totalCount:tempTotalCount,
            tableBodyRowData:tempTableBodyRow,
            oldTableBodyRowData:tempTableBodyRow,
            tableHeaderRow:tempTableHeaderRow,
            filterPlaceholder:selectedItem.label,
            resultSize:Constants.RESULT_SIZE,
            resultSizePlaceholder:Constants.RESULT_SIZE,
            currentPageNo:1,
            funProsPlaceholder:"All",
            selectionType:item.fieldType
          })
        }
      }
    });
  }
    
  onChangePage = (page) =>{
    this.setState({
      currentPageNo:page
    })
  }

  handleChangeSearch = (e) => {
		const { name, value } = e.target;

    if(isEmptyVariable(value)){
      this.setState({
        tableBodyRowData:this.state.oldTableBodyRowData
      })
    }
		this.setState({
			[name]: value,
		});
	};

  onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
      this.onSearchIconClick()
		}
	};

  getFirstRowItemValue = (item) =>{
    let tempFirstRowList =["yes / no with disable","yes / no / na with disable","yes / no","yes / no / na","textfield","yes / no"]
    if(!tempFirstRowList.includes(item.toLocaleLowerCase())){
      return item
    }else{
      return ""
    }
  }

  onSearchIconClick = () =>{
    let tempTotalCount =0;
    let newDataList =[];
    let tempDataList = this.state.funProsPlaceholder=="All"?this.state.oldTableBodyRowData:this.state.tableBodyRowData;

    if(!isEmptyVariable(this.state.searchkey)){
      Object.values(tempDataList).map((item)=>{
        let tempObj={}
        tempObj.name=item.name
        if(String(removeHtmlTags(item.value[0])).toLocaleLowerCase().startsWith(this.state.searchkey.toLocaleLowerCase())){
          tempObj.value=item.value
          tempTotalCount+=tempDataList.length
          newDataList.push(tempObj)
        }
      })

      this.setState({
        tableBodyRowData:newDataList,
        currentPageNo:1
      })
    }else{
      this.onfunPorsDropItemSelectClick({label:this.state.funProsPlaceholder})
    }
  }

  closeInfoCard = () => {
    this.setState({
      funPorsFilterArray: []
    })
    this.props.handleSelectUploadAnnexureDialogClose()
  }

  onfunPorsDropItemSelectClick = (selectedItem) =>{
    let tempArray = this.state.funPorsFilterArray

    Object.values(tempArray).map((item)=>{
      if(item.label==selectedItem.label){
        item.isSelected=true
      }else{
        item.isSelected=false
      }
    })
    let tempDataList = [];
    if(selectedItem.label!="All"){
      Object.values(this.state.oldTableBodyRowData).map((item)=>{
        if(item.name==selectedItem.label){
          tempDataList.push(item)
        }
      }) 
    }else{
      tempDataList=this.state.oldTableBodyRowData
    }
   
    this.setState({
      funProsPlaceholder:selectedItem.label,
      tableBodyRowData:tempDataList,
      searchkey:"",
      currentPageNo:1
    })
  }

  render() {
    return (
        <Modal className="task-select-table-dialog custom-dialog" show={this.props.showUploadAnnexureDialog} onHide={this.closeInfoCard} >
          <Modal.Header>
            <h5>Info Card</h5>
            <button onClick={this.closeInfoCard} type="button" data-dismiss="modal" >
              <span class="material-icons">close</span>
            </button>
          </Modal.Header>
          <hr />
          <div className="modal-body">
            {this.state.showLoader && (
              <div className="modal-loading-text">
                <p style={
                    {
                      background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                      color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                    }
                  } >
                  Loading....
                </p>
              </div>
            )}
            {this.state.isComponentDidMount && !this.state.showLoader && (
              <div className="body-wrapper">
                <div className="search-addnew-bg" style={{ marginTop: 0 }}>
                  <div className="search-addnew">
                    <div className="search-filter-layout" style={{ position: "relative" }} >
                      <div>
                        {!isEmptyArray(this.state.filterDropDownArr) &&
                          <FilterDropDown
                            placeholder={this.state.filterPlaceholder}
                            dropdownArr={this.state.filterDropDownArr}
                            onDropDownItemClick={this.onDropDownItemSelectClick}
                            themeSettings={themeSettings}
                            name={"label"}
                            dropdownWidth="200px"
                          />
                        }
                      </div>
                      {!isEmptyArray(this.props.datalist) && !isEmptyArray(this.state.filterDropDownArr) &&
                       this.state.selectionType!=TemplateConstants.FIELD_TYPE_SINGLE_SELECT &&
                        <div className="search-col m-l-10">
                          <input 
                            name="searchkey"
                            type="text" 
                            onChange={this.handleChangeSearch} 
                            placeholder="Search Requirement"
                            onKeyPress={this.onEnterBtnPress} 
                            value={this.state.searchkey} 
                          />
                          <SearchSpan themeSettings={themeSettings} onClick={this.onSearchIconClick} className="material-icons">
                            {" "} search{" "}
                          </SearchSpan>
                        </div>
                      }
                      
                     {!isEmptyArray(this.props.datalist) && !isEmptyArray(this.state.filterDropDownArr) && 
                     this.state.selectionType!=TemplateConstants.FIELD_TYPE_SINGLE_SELECT  &&
                      <div>
                        <FilterDropDown
                          placeholder={this.state.funProsPlaceholder=="All"?this.props.assignmentArea:this.state.funProsPlaceholder}
                          dropdownArr={this.state.funPorsFilterArray}
                          onDropDownItemClick={this.onfunPorsDropItemSelectClick}
                          themeSettings={themeSettings}
                          name={"label"}
                          dropdownWidth="200px"
                        />
                      </div>}
                    </div>
                  </div>
                </div>
                { this.state.tableBodyRowData.length>0 &&
                <div style={{ position: "relative" }}>
                  <div className="common-tab-bg">
                    <div className="common-table" style={{ width: "100%", border: "1px solid #e5e5e5" }} >
                      <table className="table">
                        <thead>
                          <tr>
                          {this.state.selectionType==TemplateConstants.FIELD_TYPE_SINGLE_SELECT  &&<th width={"15%"}>{this.props.assignmentArea}</th>}
                            {Object.values(this.state.tableHeaderRow).map(
                              (item,idx) => {
                                return (
                                  <th className={"text-center"} 
                                  width={this.state.selectionType==TemplateConstants.FIELD_TYPE_SINGLE_SELECT ?"8%":idx==0?"14%":"9%"}>
                                    { this.state.selectionType==TemplateConstants.FIELD_TYPE_SINGLE_SELECT?item.value:item}
                                  </th>
                                );
                              }
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {
                          this.state.selectionType==TemplateConstants.FIELD_TYPE_SINGLE_SELECT ? Object.values(this.state.tableBodyRowData).map(
                            (item,idx) => {
                              if((idx>=(this.state.currentPageNo-1)*this.state.resultSizePlaceholder) && idx<(this.state.currentPageNo*this.state.resultSizePlaceholder))
                              {
                                return (
                                  <tr style={{background:idx%2==0?"#fff":"#f5f5f5",height:"30px",fontWeight:idx==Object.values(this.state.tableBodyRowData).length-1&&"bold"}}>
                                    {Object.values(item).map((val,idx) => {
                                      return (
                                        <td className={idx!=0?"text-center":"pl-2"}  width={idx==0?"15%":"8%"}>
                                          {val}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              }
                            }
                          ):Object.values(this.state.tableBodyRowData).map((item,idx)=>{
                            if((idx>=(this.state.currentPageNo-1)*this.state.resultSizePlaceholder) && idx<(this.state.currentPageNo*this.state.resultSizePlaceholder))
                            {
                            return (
                              <tr  style={{background:idx%2==0?"#fff":"#f5f5f5"}}>
                                {
                                  Object.values(item.value).map((val,i)=>{
                                    return <td className={i!==0 &&"text-center"}  dangerouslySetInnerHTML={{ __html: val}} style={{width:idx==0?"15%":"8%",padding:"10px"}}></td>
                                  })
                                }
                              </tr>
                            )
                            }
                          })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                }
                {isEmptyArray(this.props.datalist)? 
                <div class="no-items-layout">
                      <div class="no-items-card">
                          <h6>No Tasks</h6>
                      </div>
                  </div> 
                  :
                  isEmptyArray(this.state.tableBodyRowData)&& 
                  <div class="no-items-layout">
                      <div class="no-items-card">
                          <h6>
                            {isEmptyVariable(this.state.searchkey)?
                            Constants.NO_SINGLE_SELECT_FIELD_WARNING
                            :
                            Constants.EMPTY_SEARCH_WARNING}
                          </h6>
                      </div>
                  </div>
                }
                <div className="pagination-layout">
                  <div className="row custom-row">
                      <div className="col-md-9 custom-col">
                        {
                          (this.state.tableBodyRowData.length>0) && 
                          <Pagination 
                              totalLength ={this.state.tableBodyRowData.length} 
                              items={this.state.tableBodyRowData} 
                              onChangePage={this.onChangePage} 
                              pageSize={this.state.resultSize}
                              currentPageNo = {this.state.currentPageNo}
                              initialPage={this.state.currentPageNo}
                              themeSettings={themeSettings}
                          />
                        }
                      </div>
                      <div className="col-md-3 custom-col">
                          <p>{`Total Tasks: ${this.state.selectionType==TemplateConstants.FIELD_TYPE_SINGLE_SELECT?this.props.totalTaskCount:this.state.tableBodyRowData.length}`}</p>
                      </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
    );
  }
}
