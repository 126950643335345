import React from "react";
import Chart from "react-apexcharts";
import { getThemeSettingsVariables, isEmptyArray, isEmptyVariable, rgb2hex} from "../../common/Global/commonFunctions";
let themeSettings = getThemeSettingsVariables();

const getTickAmount = (chartData) => {
    let concatArr = [...chartData[0].data];
    let max = 0;
    if(!isEmptyArray(concatArr)){
        max = Math.max(...concatArr)
    }

    return max<6?max:"";
    
}
const Donutchart = (props) => {
	const { chartData, id, labels, colors } = props;
    themeSettings = getThemeSettingsVariables();
    const hexCompleted = rgb2hex(`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b})`)
	return (
		<Chart
			options={{
				chart: {
					id,
				},
				plotOptions: {
					bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
				},
                colors: [
                    function ({ value, seriesIndex, dataPointIndex, w }) {
                        return (isEmptyVariable(colors[dataPointIndex]) || colors[dataPointIndex] === "None")?hexCompleted:colors[dataPointIndex]
                    }
                ],
				legend: {
					show: false,
				},
				responsive: [
					{
						breakpoint: 600,
						options: {
							chart: {
								width: "100%",
								height: 250,
							},
							legend: {
								show: false,
							},
						},
					},
					{
						breakpoint: 480,
						options: {
							chart: {
								width: "100%",
							},
							legend: {
								show: false,
							},
						},
					},
				],
				labels,
                dataLabels: {
                    enabled: true,
                    style:{
                        colors:["#000000","#000000"]
                    },
                },
                tooltip: {
                    enabled: true,
                    y: {
                        title:{
                            formatter: function(value, { series, seriesIndex}) {
                                return seriesIndex == 0?"Pending":"Completed"
                            }
                        }
                    }
                },
                xaxis:{
                    tickAmount:getTickAmount(chartData),
                    labels: {
                        formatter: function(val) {
                          return val.toFixed(0)
                        }
                    },
                }
			}}
			height={"auto"}
			width={"100%"}
			series={chartData}
			type="bar"
		/>
	);
};

export default Donutchart;
