import * as Constants from '../common/Global/constants';
import {getLocalStorageVariables} from '../common/Global/commonFunctions';

const userDetails  = getLocalStorageVariables();

export default () => {
    fetch(Constants.Logout,
    {
        method: "POST",
        mode:'cors',
        body: new URLSearchParams({
            email:userDetails.email,
            accessToken:userDetails.accessToken,
        })
    })
    .then(response => { return response.json(); } )
    .then(data =>
    {
        if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
            localStorage.removeItem('loggedInUserDetails');
            localStorage.removeItem('otherUserDetails');
            localStorage.removeItem('themeSettings');
            window.location = "/";
        }else if(data.responseCode === Constants.CODE_SUCCESS){
            localStorage.removeItem('loggedInUserDetails');
            localStorage.removeItem('otherUserDetails');
            localStorage.removeItem('themeSettings');
            window.location = "/";
        }else{
            localStorage.removeItem('loggedInUserDetails');
            localStorage.removeItem('otherUserDetails');
            localStorage.removeItem('themeSettings');
            window.location = "/";
        }
    });
    
    
    
}