import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,TableCheckBox,DialogSaveButton,FilterCheckBox,TableIcon} from '../../../common/Global/globalStyles.style';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import Pagination from '../../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    isEmptyArray,ifEmptyReturnEmptyStr,removeHtmlTags} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import { toast } from 'react-toastify';
const userDetails  = getLocalStorageVariables();

class SampleCheckedList extends Component{
    constructor(props){
        super(props);
        this.state = {
            description:"",
            formErrors:{},
           
        }
    }

    componentDidMount(){

    }
    handleChange = (e) =>{
        const { name, value } = e.target;
        this.setState({
            [name]:value,
        })

    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showEditDescriptionDialog){
           
            this.setState({
                description:this.props.description,
                formErrors:{},
            })
            
        }
    }
    handleEditDescriptionDialogClose = () =>{
        this.props.handleEditDescriptionDialogClose(false);
    }
   
    /*****************************API*********************************/
  
    handleEditDescription = () => {
        this.setState({
            showLoader:true
        })
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.description)){
            isError = true;
            FormErrorsTemp['description'] = "Please enter description";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });
        }else{
            fetch(Constants.UpdateWorkingPaper, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentWorkingPaperId:this.props.assignmentWorkingPaperId,
                    description:this.state.description
                }),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.setState({
                        showLoader:false
                    })
                    this.props.handleEditDescriptionDialogClose(true);
                } else {
                    // FormErrorsTemp['apiErr'] = data.responseMessage;
                    toast.error(data.responseMessage);
                    this.setState({
                        // formErrors:FormErrorsTemp,
                        showLoader:false
                    });
                }
            });
        }
        
        
    }

    render(){
        return(
                <Modal className="custom-dialog"
                    show={this.props.showEditDescriptionDialog}
                    backdrop="static">
                    <Modal.Header>
                        <h5>Working Papers</h5>
                        <button 
                            onClick={this.handleEditDescriptionDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div className="modal-loading-text">
                                <p
                                style={{
                                    background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                    color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                                }}
                                >Loading....</p>
                            </div>
                        }
                        <div class="addnew-modal-form">
                            <div class="addnew-modal-form-group">
                                <p>Description</p>
                                <textarea 
                                    type="text" 
                                    placeholder="Enter Description" 
                                    name="description"
                                    onChange ={this.handleChange}
                                    value={this.state.description}
                                    spellCheck="true" 
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['description']) &&
                                    <span class="cm-error">{this.state.formErrors['description']}</span>
                                }
                                {/* {
                                    !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                    <span class="cm-error">{this.state.formErrors['apiErr']}</span>
                                } */}
                            </div>
                        </div>
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.handleEditDescriptionDialogClose} type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton 
                                themeSettings={this.props.themeSettings}
                                onClick={this.handleEditDescription} type="button" class="modal-save">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </Modal>
                
        )
    }
}

export default SampleCheckedList;