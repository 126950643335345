import React, { Component } from 'react';
import {getLocalStorageVariables,isEmptyVariable, 
    isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {DialogSaveButton} from '../../common/Global/globalStyles.style';
import { SketchPicker } from 'react-color';
import { toast } from 'react-toastify';

const userDetails  = getLocalStorageVariables();
const unitTypePlaceholder = "Select Unit Type";
class AddClientAdminDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            openColorPickerIdx:"",
            rankingArr:[],
            // formErrors:{}
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showRankingColorPickerDialog){
            if(this.props.isEdit){
            }else{
                let rankingArr = [];
                if(isEmptyArray(this.props.rankingArr)){
                    for(var i=this.props.minRanking;i<=this.props.maxRanking;i++){
                        let rankingObj = {};
                        rankingObj = {
                            ranking:i,
                            rankingColor:"",
                            showColorPicker:false,
                        }
                        rankingArr.push(rankingObj)
                    }
                }else{
                    rankingArr = this.props.rankingArr;
                }

                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
                    openColorPickerIdx:"",
                    rankingArr:rankingArr,
                    // formErrors:{}
                })
            }
        }
    }

    openColorPicker = (idx) => {
        let rankingArr = this.state.rankingArr;
        rankingArr[idx].showColorPicker = true;

        this.setState({
            rankingArr:rankingArr,
            openColorPickerIdx:idx
        })
    }

    handleColorPickerClose = (idx) => {
        let rankingArr = this.state.rankingArr;
        rankingArr[idx].showColorPicker = false;
        this.setState({
            rankingArr:rankingArr,
            openColorPickerIdx:""
        })
    }

    handleColorPickerChange = (color) =>{
        let rankingArr = this.state.rankingArr;
        rankingArr[this.state.openColorPickerIdx].rankingColor = color.hex;
        this.setState({
            rankingArr:rankingArr,
            // formErrors:{}
        })
    }

    addRankingColors = () => {
        let isError = false;
        // let formErrors = {};

        this.state.rankingArr.map(item=>{
            if(isEmptyVariable(item.rankingColor)){
                isError = true;
                // formErrors["allColorsErr"] = "Please choose all the colors";
            }
        })

        if(isError){
            toast.warning("Please choose all the colors");
            // this.setState({
            //     formErrors:formErrors
            // })
        }else{
            this.props.returnRankingColorCodes(this.state.rankingArr);
        }
    }

    render() {
        return(
            <Modal className="custom-dialog" 
            show={this.props.showRankingColorPickerDialog} backdrop="static">
                <Modal.Header>
                    <h5>Ranking Color Codes</h5>
                    <button 
                        onClick={this.props.handleRankingColorPickerDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    <div className="addnew-modal-form">
                    {
                        this.state.rankingArr.map((item, idx) => {
                        return <div className="row custom-row cl-ranking">
                            <div className="col-md-4 custom-col"></div>
                            <div className="col-md-2 custom-col">
                                <p>{"Ranking "+item.ranking}</p>
                            </div>
                            <div className="col-md-2 custom-col">
                                <div className="color-layout-wrapper" style={{maxWidth:80}}>
                                    <div style={{background:item.rankingColor}} className="color-layout"
                                    onClick={this.openColorPicker.bind(this,idx)} />
                                </div>
                                {

                                    item.showColorPicker &&
                                    <div className="popover">
                                        <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,idx) }/>
                                        <SketchPicker 
                                        color={item.rankingColor} 
                                        onChange={ this.handleColorPickerChange} />
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 custom-col"></div>
                        </div>
                        })
                    }
                    {/* {
                        !isEmptyVariable(this.state.formErrors['allColorsErr']) &&
                        <span 
                        style={{textAlign:"center",marginTop:0,marginBottom:10}}
                        class="cm-error">{this.state.formErrors['allColorsErr']}</span>
                    } */}
                    </div>
                    
                    <div className="modal-close-save">
                        <div className="close-save-col">
                            <button onClick={this.props.handleRankingColorPickerDialogClose} type="button" className="modal-close">Cancel</button>
                            <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.addRankingColors} type="button" className="modal-save">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default AddClientAdminDialog;