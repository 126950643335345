import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { AddNewButtonLayout } from "../../../common/Global/globalStyles.style";
import Sidebar from "../../../common/SidebarCollapse";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import Topbar from "../../../common/Topbar";
import MasterMenuTabs from "../Common/topmenu";
import AddNewWorkingPaper from './addNewWorkingPaper'
import UploadWorkingPaper from './uploadWorkingPaperSample'
import ListUploadedWorkingPapersDocuments from './listUploadedWorkingPapersDocuments'
import {
	getLocalStorageVariables,
	getThemeSettingsVariables,
	isEmptyVariable,
	isEmptyArray,
	sortTable,
	GetOrderedMasterTabMenuExecute,
} from "../../../common/Global/commonFunctions";
import { BrowserRouter as Router,withRouter } from "react-router-dom";
import AlertDialog from "../../../common/AlertDialog";
import TagObservation from "./tagObservation";
import EditDescription from "./editWorkingPapersDescription";
import TopmenuReports from "../../../common/TopmenuReports";

const sortDescription = "d.name";
const sortMasterType = "masterType";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();

const uploadSample = {
	icon: "cloud_upload",
	label: "Upload",
};
const getSamples = {
	icon: "topic",
	label: "View Documents",
};
const delDropdownObj = {
	icon: "delete",
	label: "Delete",
};
const tagObservations = {
	icon: "topic",
	label: "Tag Observation",
};
const editDescription = {
	icon: "edit",
	label: "Edit Description"
}

const actionItems = [getSamples,tagObservations]
const ongoingActionItems = [uploadSample,getSamples,editDescription,delDropdownObj,tagObservations]

class WorkingPapersList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignmentId: this.props.match.params.id,
			user: {},
			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			statusPlaceholder: "In Progress",
			resultSizePlaceholder: Constants.RESULT_SIZE ,
			sort: "",
			sortDir: "",
			assignmentStatus: "",
			departmentId: "",
			assignmentDetails: "",
			company:{},

			showAlertDialog: false,
			operationType: "",
			deleteReactivateId: "",
			deleteReactivateMasterType: "",
			reviewLevel: "",
			alertDialogMessage: "",
			alertDialogHeading: "",
			proceedBtnLabel: "",

			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",

			//Add New Dialog
			showDocumentDialog:false,
			//Upload Dialog
			showUploadDocSampleDialog:false,
			handleUploadWorkingPaperDocsClose:false,
			showObservationDialog:false,

			showEditDescriptionDialog:false,
			description:"",

		};
	}

	componentDidMount() {
		this.getInitData();
	}

	handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

	handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

	handleAddWorkingPapersDialogShow = () => {
		this.setState({
			showDocumentDialog:true,
		})
	}

	handleSelectDocDialogClose = () => {
		this.setState({
			showDocumentDialog:false,
		})
	}

	handleSelectDocDialogSaveClose = (reloadFlag) => {
		this.setState({
			showDocumentDialog:false,
		},()=>{
			if(reloadFlag) this.getWorkingPapersList();
		})
	}

	handleUploadSampleDocDialogClose = (reloadFlag) => {
		this.setState({
			showUploadDocSampleDialog:false,
			deleteReactivateId:"",
		},()=>{
			if(reloadFlag) this.getWorkingPapersList();
		})
	}
	handleUploadWorkingPaperDocsClose = () => {
		this.setState({
			showUploadWorkingPaperDocs:false,
			deleteReactivateId:"",
		},()=>{
			this.getWorkingPapersList();
		})
	}

	

	handleTagObservationDialogShow = () => {
		this.setState({
			showObservationDialog:true,
		})
	}

	handleSelectObsDialogClose = () => {
		this.setState({
			showObservationDialog:false,
		})
	}

	handleSelectObsDialogSaveClose = (reloadFlag) => {
		this.setState({
			showObservationDialog:false,
		},()=>{
			if(reloadFlag) this.getWorkingPapersList();
		})
	}

	handleEditDescriptionDialogClose = (reloadFlag) => {
		this.setState({
			showEditDescriptionDialog:false,
			deleteReactivateId:"",
			editDescription:"",
		},()=>{
			if(reloadFlag) this.getWorkingPapersList();
		})
	}

	onTableDropDownItemClick = (item, dropdownItem) => {
		if (item.label === "Delete") {
			this.setState({
				operationType: item.label,
				alertDialogHeading: "Delete Working Paper",
				proceedBtnLabel: "Delete",
				deleteReactivateId: dropdownItem.assignmentWorkingPaperId,
				showAlertDialog: true,
				alertDialogMessage: "Are you sure you want to delete working paper " + dropdownItem.name + "?",
			});
		} else if (item.label === "Upload") {
			this.setState({
				deleteReactivateId: dropdownItem.assignmentWorkingPaperId,
				showUploadDocSampleDialog:true,
			});
		} else if (item.label === "View Documents") {
			this.setState({
				deleteReactivateId: dropdownItem.assignmentWorkingPaperId,
				showUploadWorkingPaperDocs:true,
			});
		} else if (item.label === "Tag Observation") {
			this.setState({
				deleteReactivateId: dropdownItem.assignmentWorkingPaperId,
				showObservationDialog:true,
			});
		}else if (item.label === "Edit Description") {
			this.setState({
				deleteReactivateId: dropdownItem.assignmentWorkingPaperId,
				showEditDescriptionDialog:true,
				description:dropdownItem.workingPaperDescription,
			});
		}
	};

	sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getWorkingPapersList();
			}
		);
	};

	/*******************API CALLS*******************/
	getInitData = () => {
		this.setState({
			showLoader: true,
		});

		Promise.all([
			fetch(Constants.GetWorkingPapers, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
					// resultsize: this.state.resultSize,
					// status: this.state.status,
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
			fetch(Constants.GetAssignmentsDetails, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
			}),
		])
			.then(([masterRes, menuRes, assignmentRes]) => {
				return Promise.all([masterRes.json(), menuRes.json(), assignmentRes.json()]);
			})
			.then(([masterRes, menuRes, assignmentRes]) => {
				let datalistTemp = [];
				let totalCountTemp = 0;
				let deptTemp = [];
				let menusTemp = {};
				let companyTemp = {};
				let userTemp = {};
				let assignmentStatus = "";
				let departmentId = "";
				let assignmentDetails = {};

				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					datalistTemp = masterRes.data.workingPapers;
					totalCountTemp = masterRes.data.count;
				} else {
				}

				if (assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
					assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
					departmentId = assignmentRes.data.assignmentDetails.departmentId;
					assignmentDetails = assignmentRes.data.assignmentDetails;

				} else {
				}
				if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
					deptTemp = menuRes.data.departments;
					menusTemp = menuRes.data.menus;
					userTemp = menuRes.data.user;
					companyTemp = menuRes.data.company;

				} else {
					menusTemp = {};
				}
				let menusTemp2 = GetOrderedMasterTabMenuExecute(menusTemp,assignmentDetails.observationTemplateId);
				console.log(menusTemp2);

				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					datalist: datalistTemp,
					totalCount: totalCountTemp,
					menus: menusTemp2,
					assignmentStatus: assignmentStatus,
					departmentId: departmentId,
					assignmentDetails: assignmentDetails,
					company:companyTemp,
					user: userTemp,
				});
			});
	};

	getWorkingPapersList = () => {
		this.setState({
			showLoader: true,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentId: this.state.assignmentId,
			sort: this.state.sort,
			sortDir: this.state.sortDir,
		};

		fetch(Constants.GetWorkingPapers, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						datalist: data.data.workingPapers,
						// totalCount: data.data.count,
						// apiSearchKey: this.state.searchkey,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						totalCount: 0,
						componentDidMountFlag: true,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};

	onChangePage = (page) => {
		// update state with new page of items
		if (page !== this.state.currentPageNo) {
			this.setState({
				showLoader: true,
			});

			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId: this.state.assignmentId,
				pageNo: page,
			};

			fetch(Constants.GetWorkingPapers, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState({
							showLoader: false,
							datalist: data.data.observationList,
							totalCount: data.data.count,
							apiSearchKey: this.state.searchkey,
							currentPageNo: page,
						});
					} else {
						this.setState({
							datalist: [],
							showLoader: false,
							apiSearchKey: this.state.searchkey,
						});
					}
				});
		}
	};
	
	handleRecordOperation = () => {
		let url = "";
		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentWorkingPaperId: this.state.deleteReactivateId,
		};
		let callApi = true;

		if (this.state.operationType === "Delete") {
			url = Constants.Deleteworkingpaper;
			postParam.deleteReason = "test";
		}

		if (callApi) {
			this.setState({
				showLoader: true,
			});

			fetch(url, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState(
							{
								deleteReactivateId: "",
								operationType: "",
								alertDialogHeading: "",
								proceedBtnLabel: "",
								alertDialogMessage: "",
								showAlertDialog: false,
							},
							() => {
								this.getWorkingPapersList();
							}
						);
					} else {
						this.setState({
							showLoader: false,
							deleteReactivateId: "",
							operationType: "",
							alertDialogHeading: "",
							proceedBtnLabel: "",
							alertDialogMessage: "",
							showAlertDialog: false,

							showAlertDialogInfo: true,
							alertDialogMessageInfo: data.responseMessage,
						});
					}
				});
		}
	};
	/*******************API CALLS END HERE*******************/

	render() {
		return (
			<Router>
				<main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />

						<div className="dashboard-right-bg-collpase">
							<div className="dashboard-wrapper">
								<Topbar />
								{
                                    this.state.componentDidMountFlag && (
                                    <div className="d-flex align-items-center">
                                        <div className="flex-fill">
                                            <MasterMenuTabs
                                                assignmentId={this.state.assignmentId}
                                                masterMenus={this.state.menus}
                                                themeSettings={themeSettings}
                                            />
                                        </div>
                                        <div>
                                            <TopmenuReports
                                                assignmentId={this.state.assignmentId}
                                                userDetails ={userDetails}
                                                themeSettings={themeSettings}
                                                company={this.state.company}
                                            />
                                        </div>
                                    </div>
                                )}
								{this.state.showLoader && (
									<div
										className="loading-layout"
										style={{ background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)` }}
									>
										<div className="loading-text-layout">
											<p
												style={{
													color: `rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`,
												}}
											>
												Loading....
											</p>
										</div>
									</div>
								)}
								{
									// Need to check component did mount flag, other wise until api is called,
									// it shows no items found layout.
									this.state.componentDidMountFlag &&
										(
											<div className="body-wrapper">
												<div className="search-addnew-bg">
													<div className="search-addnew">
														<div></div>
                                                        {
                                                            !isEmptyVariable(this.state.assignmentStatus) &&
                                                            this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING && 
                                                            <AddNewButtonLayout themeSettings={themeSettings}>
                                                                <a onClick={this.handleAddWorkingPapersDialogShow} href="javascript:void(0);">
                                                                    <span className="material-icons">add_circle</span>
                                                                    <p>Add New</p>
                                                                </a>
                                                            </AddNewButtonLayout>
                                                        }
														
													</div>
												</div>

												{!isEmptyArray(this.state.datalist) && (
													<div className="common-tab-bg">
														<div className="common-table" style={{ width: "100%" }}>
															<table className="table">
																<thead>
																	<tr>
																		<th className="text-center" width={"5%"}>
																			No.
																		</th>
																		{/* <th width={"20%"} className="c-pointer"  onClick={this.sortTableLocal.bind(this, sortDescription)}> */}
																		<th width={"20%"} >
																			<div className="sort-header">
																				Document
																				{/* <span
																				className={
																					this.state.sort === sortDescription
																						? "material-icons"
																						: "material-icons hide-sort-arrow"
																				}
																				>
																					{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																				</span> */}
																			</div>
																		</th>
																		{/* <th width={"10%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDescription)}> */}
																		<th width={"10%"} >
																			<div className="sort-header">
																				Type
																				{/* <span
																				className={
																					this.state.sort === sortDescription
																						? "material-icons"
																						: "material-icons hide-sort-arrow"
																				}
																				>
																					{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																				</span> */}
																			</div>
																		</th>
																		{/* <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDescription)}> */}
																		<th >
																			<div className="sort-header">
																				Description
																				{/* <span
																				className={
																					this.state.sort === sortDescription
																						? "material-icons"
																						: "material-icons hide-sort-arrow"
																				}
																				>
																					{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																				</span> */}
																			</div>
																		</th>
																		{/* <th  width={"10%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDescription)}> */}
																		<th  width={"10%"} >
																			<div className="sort-header just-content">
																				Count
																				{/* <span
																				className={
																					this.state.sort === sortDescription
																						? "material-icons"
																						: "material-icons hide-sort-arrow"
																				}
																				>
																					{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																				</span> */}
																			</div>
																		</th>
																		<th className="text-center" width={"8%"}>
																			<div className="sort-header just-content"  width={"5%"}></div>
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{!isEmptyArray(this.state.datalist) &&
																		this.state.datalist.map((item, idx) => {
																			// let actionObj = getDropdownActionArrayObservationList(
																			// 	item.allowEdit,
																			// 	item.allowReview,
																			// 	item.status,
																			// 	item.currentReviewStatus,
																			// 	item.taskList
																			// );
																			return (
																				<tr>
																					<td className="text-center">
																						{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
																					</td>
																					<td>{item.name}</td>
																					<td>{item.documentType}</td>
																					<td>{isEmptyVariable(item.workingPaperDescription)?"":item.workingPaperDescription}</td>
																					<td className="text-center">{isEmptyVariable(item.uploadCount)?"0":item.uploadCount}</td>
																					<td className="text-center">
																						<TableDropDown
																							actionArr={this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING 
																								? ongoingActionItems:actionItems}
																							onDropDownItemClick={this.onTableDropDownItemClick}
																							dropDownId={item}
																							dropdownWidth={"200px"}
																							themeSettings={themeSettings}
																						/>
																					</td>
																				</tr>
																			);
																		})}
																</tbody>
															</table>
														</div>
													</div>
												)}
												{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
													<div class="no-items-layout">
														<div class="no-items-card">
															<h6>
																{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
															</h6>
														</div>
													</div>
												)}
											</div>
										)
								}
							</div>
						</div>

						<AlertDialog
							showAlertDialog={this.state.showAlertDialog}
							handleAlertDialogClose={this.handleAlertDialogClose}
							type={
								this.state.operationType === "Delete"
									? Constants.ALERT_TYPE_WARNING
									: this.state.operationType === "Review Remarks"
									? Constants.ALERT_TYPE_ALERT
									: Constants.ALERT_TYPE_INFO
							}
							alertDialogHeading={this.state.alertDialogHeading}
							alertDialogMessage={this.state.alertDialogMessage}
							proceedBtnClick={this.handleRecordOperation}
							proceedBtnLabel={this.state.proceedBtnLabel}
							themeSettings={themeSettings}
						/>

						<AlertDialog
							showAlertDialog={this.state.showAlertDialogInfo}
							handleAlertDialogClose={this.handleAlertDialogCloseInfo}
							type={Constants.ALERT_TYPE_ALERT}
							alertDialogMessage={this.state.alertDialogMessageInfo}
							proceedBtnClick={this.handleAlertDialogCloseInfo}
							proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
							themeSettings={themeSettings}
						/>

						<AddNewWorkingPaper
							showDocumentDialog= {this.state.showDocumentDialog}
							departmentId= {this.state.departmentId}
							assignmentId = {this.state.assignmentId}
							subscriptionType={this.state.user.subscriptionType}
							handleSelectDocDialogClose= {this.handleSelectDocDialogClose}
							handleSelectDocDialogSaveClose= {this.handleSelectDocDialogSaveClose}
							themeSettings={themeSettings}
						/>
						<UploadWorkingPaper
							showUploadDocSampleDialog= {this.state.showUploadDocSampleDialog}
							handleUploadSampleDocDialogClose= {this.handleUploadSampleDocDialogClose}
							assignmentId = {this.state.assignmentId}
							assignmentWorkingPaperId = {this.state.deleteReactivateId}
							themeSettings={themeSettings}
						/>
						<ListUploadedWorkingPapersDocuments
							showUploadWorkingPaperDocs= {this.state.showUploadWorkingPaperDocs}
							handleUploadWorkingPaperDocsClose= {this.handleUploadWorkingPaperDocsClose}
							assignmentId = {this.state.assignmentId}
							assignmentWorkingPaperId = {this.state.deleteReactivateId}
							themeSettings={themeSettings}
						/>

						<TagObservation
							showObservationDialog= {this.state.showObservationDialog}
							departmentId= {this.state.departmentId}
							assignmentId = {this.state.assignmentId}
							subscriptionType={this.state.user.subscriptionType}
							handleSelectObsDialogClose= {this.handleSelectObsDialogClose}
							handleSelectObsDialogSaveClose= {this.handleSelectObsDialogSaveClose}
							themeSettings={themeSettings}
							assignmentWorkingPaperId = {this.state.deleteReactivateId}
                            assignmentStatus = {this.state.assignmentStatus}
						/>
						<EditDescription 
							showEditDescriptionDialog={this.state.showEditDescriptionDialog}
							handleEditDescriptionDialogClose={this.handleEditDescriptionDialogClose}
							assignmentId = {this.state.assignmentId}
							assignmentWorkingPaperId = {this.state.deleteReactivateId}
							description = {this.state.description}
							themeSettings={themeSettings}
						/>
					</section>
				</main>
			</Router>
		);
	}
}

export default withRouter(WorkingPapersList);
