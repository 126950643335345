import { isEmptyVariable, isEmptyArray, ifEmptyReturnEmptyStr, removeHtmlTags, SplitJoinString, DoubleSplitString,isJsonString } from "../../../common/Global/commonFunctions";
import {
	Footer,
	Header,
	PageBreak,
	Packer,
	PageNumber,
	AlignmentType,
	Paragraph,
	TextRun,
	Table,
	TableRow,
	TableCell,
	VerticalAlign,
	WidthType,
	TabStopType,
	TabStopPosition,
	ImageRun
} from "docx";
import moment from "moment";

const fontName = "Arial";

export const getParagraphStyles = () => {
	return [
		{
			id: "paraSmall",
			name: "paraSmall",

			run: {
				size: 22,
				font: fontName
			},
		},
		{
			id: "paraSmallBold",
			name: "paraSmallBold",

			run: {
				size: 22,
				bold:true,
				font: fontName
			},
		},
		{
			id: "tableHeading",
			name: "tableHeading",

			run: {
				size: 22,
				bold: true,
				font: fontName
			},
		},
		{
			id: "pageHeading",
			name: "pageHeading",
			paragraph: {
				alignment: AlignmentType.CENTER,
			},
			run: {
				size: 22,
				bold: true,
				font: fontName
			},
		},
		{
			id: "commonHeading",
			name: "commonHeading",
			paragraph: {},
			run: {
				size: 22,
				bold: true,
				font: fontName
			},
		},
		{
			id: "firstPageHeading",
			name: "firstPageHeading",
			paragraph: {
				alignment: AlignmentType.CENTER,
			},
			run: {
				size: 36,
				// bold: true,
				font: fontName
			},
		},
	];
};

export const getTableCellMargin = ()=> {
	return {
			left:40
		}
} 

export const getLineBreak = (noOfLines) =>{
	return	new TextRun({
		break: noOfLines,
	})
}
export const getTableContent = (assignedTasks) =>{
    let tables=[];
    const para = new Paragraph({
		style: "pageHeading",
		children: [
			new TextRun({
				text:"TASKS",
				underline: {},
			}),
            getLineBreak(1)
		],
	});
    tables.push(para);

    assignedTasks.forEach((item)=>{
        let taskTableRows = [];
        let columnWidth = [44,44,12];
        
        // let tasktableHeading = isEmptyVariable(item.processName)
        // ?(isEmptyVariable(item.functionName)
        //     ?(item.heading)
        //     :item.functionName)
        // :item.processName;

        // let tableHeadingContent = new Paragraph({
        //     style: "commonHeading",
        //     children: [
        //         new TextRun({
        //             text:tasktableHeading,
        //         }),
        //         getLineBreak(1)
        //     ],
        // });
        // tables.push(tableHeadingContent);



        if(!isEmptyArray(item.taskList)){
            let tasktableHeading = isEmptyVariable(item.processName)
                ?(isEmptyVariable(item.functionName)
                ?(item.heading)
                :item.functionName)
                :item.processName;

            let tableHeadingContent = new Paragraph({
                style: "commonHeading",
                children: [
                    new TextRun({
                        text:tasktableHeading,
                    }),
                    getLineBreak(1)
                ],
            });
            tables.push(tableHeadingContent);

            // if(item.taskList[0].checkListType === "WR"){

            // }else if(item.taskList[0].checkListType === "R"){

            // }else{

            // }

            // table heading row
            let tableHeaderCells = [];
            
            let tableHeaderCellTaskNo = new TableCell({
                children: [
                    new Paragraph({
                        text: "No.",
                        style: "tableHeading",
                    }),
                ],
                width:{size:4,type:WidthType.PERCENTAGE},
                verticalAlign: VerticalAlign.CENTER,
            });
            tableHeaderCells.push(tableHeaderCellTaskNo);
            
            let tableHeaderCellTaskName = new TableCell({
                children: [
                    new Paragraph({
                        text: "Task",
                        style: "tableHeading",
                    }),
                ],
                width:{size:42,type:WidthType.PERCENTAGE},
                verticalAlign: VerticalAlign.CENTER,
            });
            tableHeaderCells.push(tableHeaderCellTaskName);
            
            // Both Weight & Rank heading
            if(item.taskList[0].checkListType === "WR" && !isEmptyVariable(item.heading)){
                columnWidth=[44,34,10,10,12];
                let tableHeaderCellWeight = new TableCell({
                    children: [
                        new Paragraph({
                            text: "Weight",
                            style: "tableHeading",
                        }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                });
                tableHeaderCells.push(tableHeaderCellWeight);
                
                // Rank heading
                let tableHeaderCellRank = new TableCell({
                    children: [
                        new Paragraph({
                            text: "Rank",
                            style: "tableHeading",
                        }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                });
                tableHeaderCells.push(tableHeaderCellRank);
            }
            
            // Only Rank heading
            if(item.taskList[0].checkListType === "R" && !isEmptyVariable(item.heading)){
                columnWidth=[50,38,10,12];
                let tableHeaderCellRank = new TableCell({
                    children: [
                        new Paragraph({
                            text: "Rank",
                            style: "tableHeading",
                        }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                });
                tableHeaderCells.push(tableHeaderCellRank);
            }

            //Remarks
            let tableHeaderCellRemarks = new TableCell({
                children: [
                    new Paragraph({
                        text: "Remarks",
                        style: "tableHeading",
                    }),
                ],
                width:{size:42,type:WidthType.PERCENTAGE},
                verticalAlign: VerticalAlign.CENTER,
            });
            tableHeaderCells.push(tableHeaderCellRemarks);

            //Risk Heading
            let tableHeaderCellRisk = new TableCell({
                children: [
                    new Paragraph({
                        text: "Risk",
                        style: "tableHeading",
                    }),
                ],
                width:{size:12,type:WidthType.PERCENTAGE},
                verticalAlign: VerticalAlign.CENTER,
            });
            tableHeaderCells.push(tableHeaderCellRisk);

            let tableHeaderRows = new TableRow({
				children: tableHeaderCells
			});
            
            taskTableRows.push(tableHeaderRows);
        }

        let tableRows = [];

        //table values
        item.taskList.forEach((taskItem,index)=>{
            
            // if(!isEmptyArray(taskItem.taskList)){
            let tableValueRow = [];
            
            //Number
            let tableCellTaskNo = new TableCell({
                children: [
                    new Paragraph({
                        text: `${++index}`,
                        style: "paraSmall",
                    }),
                ],
                verticalAlign: VerticalAlign.CENTER,
            });
            tableValueRow.push(tableCellTaskNo);

            //Task Name
            let tableCellTaskName = new TableCell({
                children: [
                    new Paragraph({
                        text: `${removeHtmlTags(taskItem.task)}`,
                        style: "paraSmall",
                    }),
                ],
                verticalAlign: VerticalAlign.CENTER,
            });
            tableValueRow.push(tableCellTaskName);

            
            // Both Weight, Rank 
            if(taskItem.checkListType === "WR" && !isEmptyVariable(item.heading)){
                //Weight
                let tableCellWeight = new TableCell({
                    children: [
                        new Paragraph({
                            text: `${taskItem.weight}`,
                            style: "paraSmall",
                        }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                });
                tableValueRow.push(tableCellWeight);
                //Rank
                let rank = isEmptyVariable(taskItem.rank) ?"-" : (taskItem.rank === -1 ? "N/A" : taskItem.rank)
                let tableCellRank = new TableCell({
                    children: [
                        new Paragraph({
                            text: `${rank}`,
                            style: "paraSmall",
                        }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                });
                tableValueRow.push(tableCellRank);
                
            }
            
            // Only Rank 
            if(taskItem.checkListType === "R" && !isEmptyVariable(item.heading)){
                let rank = isEmptyVariable(taskItem.rank) ?"-" : (taskItem.rank === -1 ? "N/A" : taskItem.rank)
                let tableCellRank = new TableCell({
                    children: [
                        new Paragraph({
                            text: `${rank}`,
                            style: "paraSmall",
                        }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                });
                tableValueRow.push(tableCellRank);
            }

            //Remarks
            let tableCellRemarks = new TableCell({
                children: [
                    new Paragraph({
                        text: `${ifEmptyReturnEmptyStr(taskItem.latestRemark, "")}`,
                        style: "paraSmall",
                    }),
                ],
                verticalAlign: VerticalAlign.CENTER,
            });
            tableValueRow.push(tableCellRemarks);

            //Risk
            let tableCellRisk = new TableCell({
                children: [
                    new Paragraph({
                        text: `${ifEmptyReturnEmptyStr(taskItem.riskLevel, "")}`,
                        style: "paraSmall",
                    }),
                ],
                verticalAlign: VerticalAlign.CENTER,
            });
            tableValueRow.push(tableCellRisk);
            
                let tableRow = new TableRow({
                    children: tableValueRow
                });
            taskTableRows.push(tableRow);
        });
                    // [
                    //     new TableCell({
                        //         children: [
                            //             new Paragraph({
                                //                 text: `${removeHtmlTags(taskItem.task)}`,
                                //                 style: "paraSmall",
                                //             }),
                                //         ],
                                //         verticalAlign: VerticalAlign.CENTER,
                                //     }),
                                //     new TableCell({
                    //         children: [
                        //             new Paragraph({
                            //                 text: `${ifEmptyReturnEmptyStr(taskItem.latestRemark, "")}`,
                            //                 style: "paraSmall",
                            //             }),
                            //         ],
                            //         verticalAlign: VerticalAlign.CENTER,
                            //     }),
                            //     new TableCell({
                                //         children: [
                                    //             new Paragraph({
                                        //                 text: `${ifEmptyReturnEmptyStr(taskItem.riskLevel, "")}`,
                                        //                 style: "paraSmall",
                    //             }),
                    //         ],
                    //         verticalAlign: VerticalAlign.CENTER,
                    //     }),
                    // ],
                // });
                // taskTableRows.push(tableRow);
            // }
            // });

        let taskTable = new Table({
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: columnWidth,
            margins: getTableCellMargin(),
            rows: [
                // new TableRow({
                //     children: instanceTableHeading,
                // }),
                ...taskTableRows,
            ],
        });
        tables.push(taskTable);

        tables.push(
            new Paragraph({
                children: [
                    getLineBreak(1)
                ]
            })
        )

    });
    return tables;
}