import { isEmptyVariable, isEmptyArray, ifEmptyReturnEmptyStr, removeHtmlTags, SplitJoinString, 
    DoubleSplitString,getStringWithSpaceAfterComma } from "../../../common/Global/commonFunctions";
import moment from "moment";

export const getScheduledAssignmentsInExcel = (assignmentList) => {
    
    let excelAssignmentObj={
        tableCols:[],
        tableValue:[],
    };
    let filterObj={filterButton: false};
    
    let tableCols=[
        {name:'No.',...filterObj},
        {name:'Refernece No',...filterObj},
        {name:'Client',...filterObj},
        {name:'Unit',...filterObj},
        {name:'Type',...filterObj},
        {name:'Scope',...filterObj},
        {name:'Process/Function',...filterObj},
        {name:'Status',...filterObj},
        {name:'Start Date',...filterObj},
        {name:'End Date',...filterObj},
        {name:'Report Date',...filterObj},
        {name:'Period From',...filterObj},
        {name:'Period To',...filterObj},
    ];

    excelAssignmentObj['tableCols'].push(...tableCols);
    assignmentList.forEach((item,index)=>{
        let tableValueRow = [];
        tableValueRow.push(`${++index}`);
        tableValueRow.push(`${ifEmptyReturnEmptyStr(item.referenceNo,"-")}`);
        tableValueRow.push(`${ifEmptyReturnEmptyStr(item.companyName,"-")}`);
        
        let unitList = ifEmptyReturnEmptyStr(getStringWithSpaceAfterComma(item.unitNameCityList),"-");
        tableValueRow.push(unitList);

        tableValueRow.push(`${ifEmptyReturnEmptyStr(item.assignmentTypeShortName,"-")}`);
        
        let scope = isEmptyVariable(item.plannedAssignmentArea)?
        item.assignmentArea:
        item.plannedAssignmentArea;
        tableValueRow.push(ifEmptyReturnEmptyStr(scope,"-"));
        
        if((item.plannedAssignmentArea === "Process" || 
        item.assignmentArea === "Process") &&
        !isEmptyVariable(item.processList)){
            tableValueRow.push(`${SplitJoinString(item.processList,",",":-:", ", ")}`);
        }else if((item.plannedAssignmentArea === "Function" || 
        item.assignmentArea === "Function") &&
        !isEmptyVariable(item.functionList)){
            tableValueRow.push(`${SplitJoinString(item.functionList,",",":-:", ", ")}`);
        }else{
            tableValueRow.push("-")
        }


        tableValueRow.push(`${ifEmptyReturnEmptyStr(item.assignmentStatus,"-")}`);
        
        tableValueRow.push(`${moment(item.startDate).format('DD/MM/YYYY')}`);
        tableValueRow.push(`${moment(item.endDate).format('DD/MM/YYYY')}`);
        tableValueRow.push(`${moment(item.reportDate).format('DD/MM/YYYY')}`);
        tableValueRow.push(`${moment(item.periodFromDate).format('DD/MM/YYYY')}`);
        tableValueRow.push(`${moment(item.periodToDate).format('DD/MM/YYYY')}`);

        excelAssignmentObj['tableValue'].push(tableValueRow)
    });
    return excelAssignmentObj;
}
export const getPlannedAssignmentsInExcel = (assignmentList) => {
    
    let excelAssignmentObj={
        tableCols:[],
        tableValue:[],
    };
    let filterObj={filterButton: false};
    
    let tableCols=[
        {name:'No.',...filterObj},
        {name:'Month',...filterObj},
        {name:'Client',...filterObj},
        {name:'Unit',...filterObj},
        {name:'Type',...filterObj},
        {name:'Scope',...filterObj},
        {name:'Process/Function',...filterObj},
    ];

    excelAssignmentObj['tableCols'].push(...tableCols);
    assignmentList.forEach((item,index)=>{
        let tableValueRow = [];
        tableValueRow.push(`${++index}`);
        tableValueRow.push(`${moment(item.planDate).format('DD/MM/YYYY')}`);
        tableValueRow.push(`${ifEmptyReturnEmptyStr(item.companyName,"-")}`);
        
        let unitList = ifEmptyReturnEmptyStr(getStringWithSpaceAfterComma(item.unitNameCityList),"-");
        tableValueRow.push(unitList);

        tableValueRow.push(`${ifEmptyReturnEmptyStr(item.assignmentTypeShortName,"-")}`);
        
        let scope = isEmptyVariable(item.plannedAssignmentArea)?
        item.assignmentArea:
        item.plannedAssignmentArea;
        tableValueRow.push(ifEmptyReturnEmptyStr(scope,"-"));
        
        if((item.plannedAssignmentArea === "Process" || 
        item.assignmentArea === "Process") &&
        !isEmptyVariable(item.processList)){
            tableValueRow.push(`${SplitJoinString(item.processList,",",":-:", ", ")}`);
        }else if((item.plannedAssignmentArea === "Function" || 
        item.assignmentArea === "Function") &&
        !isEmptyVariable(item.functionList)){
            tableValueRow.push(`${SplitJoinString(item.functionList,",",":-:", ", ")}`);
        }else{
            tableValueRow.push("-")
        }

        excelAssignmentObj['tableValue'].push(tableValueRow)
    });
    return excelAssignmentObj;
}