import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import * as TemplateConstants from "../../../common/Global/templateConstants";
import { DialogSaveButton, FilterCheckBox, AddNewButtonLayout, UploadSampleLayout, TableIcon } from "../../../common/Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray, getOnlyFileNameFromPath, truncateString, isJsonString } from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import RegularDropdown from "../../../common/DropdownMenus/RegularDropdown";
import moment from "moment";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import { toast } from "react-toastify";
import { CheckBoxSelection, Inject, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import AlertWithInput from "../../../common/AlertDialog/alertwithInput";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import AlertDialog from "../../../common/AlertDialog";

const userDetails = getLocalStorageVariables();
const responsibilityPlaceholderDefaultValue = "Select Responsibility";

class AssertionAssignmentList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag:false,
			showLoader:false,
            responsibilityPlaceholder:responsibilityPlaceholderDefaultValue,
            formErrors:{},
            fullNameArr:[],
            showDialogLoader:false,
            immediate:"",
            continuous:"",
            custom:"",
            customText:"",
            responseTemplateFields:[],
            responseTemplateValues:{},
            observationResponses:[],
            supportingSelectedFiles:[],
            supportings:[],
            supportingRemarksArray:[],
            supportingUpdateRemarkArray:[],
            datePickerOpen:{}
		};
	}

	componentDidMount() {}

    // Function to handle focus out of date picker
    handleFocusOut = (fieldId) => {
        const updatedDatePickersOpen = { ...this.state.datePickerOpen, [fieldId]: false };
        this.setState({ datePickerOpen: updatedDatePickersOpen });
    };

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showAddResponseDialog) {
            if(this.props.isEdit){
                let custom="";
                let tmpTemplaveValues ={};
                let fullNameTemp = [];
                let observationResponse = this.props.responseObj;
                if(!isEmptyVariable(observationResponse[0]?.customTarget)){
                    custom="Y";
                }
                if(!isEmptyArray(observationResponse)){
                    try{
                        let tmpValues = observationResponse;
                        tmpValues.map(e=>{
                            const key = e["fieldId"]
                            tmpTemplaveValues[key] = e["value"]
                        })
                    }catch(e){}
                }
                if (!isEmptyArray(this.props.employees)) {
                    this.props.employees.forEach((item) => {
                        fullNameTemp.push({
                            fullname: item.firstName+" "+item.lastName, 
                            value:item.employeeId
                        });
                    });
                }
                let supportingUpdateRemarkArray = this.props.supportings?.map(item => ({
                    fileName: item.filePath,
                    responseSupportingMapId: item.responseSupportingMapId,
                    remark:item.remarks
                })) ?? [];
                this.setState({
                    componentDidMountFlag:true,
                    showLoader:false,
                    submitClickedAtleastOnce:false,
                    showDialogLoader:false,
                    supportingSelectedFiles:[],
                    supportingRemarksArray:[],
                    formErrors:{},
                    fullNameArr:fullNameTemp,
                    observationResponses:observationResponse,
                    employeesList:this.props.employees,
                    responseTemplateFields:this.props.responseTemplateFields,
                    immediate:observationResponse[0]?.immediate ?? "N",

                    continuous:observationResponse[0]?.continuous ?? "N",
                    custom:custom,
                    customText:observationResponse[0]?.customTarget ?? "N",
                    responseTemplateValues:tmpTemplaveValues,
                    supportings:this.props.supportings,
                    supportingUpdateRemarkArray:supportingUpdateRemarkArray
                });
            }
            else{
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
                    submitClickedAtleastOnce:false,
                    supportingSelectedFiles:[],
                    observationResponses:[],
                    formErrors:{},
                    fullNameArr:[],
                    responsibilityPlaceholder:responsibilityPlaceholderDefaultValue,
                    responseTemplateValues:[],
                    showDialogLoader:false,
                    supportings:[],
                    supportingRemarksArray:[],
                    immediate:"",
                    continuous:"",
                    custom:"",
                    customText:""
                },()=>{
                    this.getInitData();
                });
            }
			
		}
	}

    handleCheck = (e) => {
		let isChecked = e.target.checked;
		let name = e.target.name;

		if (isChecked) {
			this.setState({
				[name]: "Y",
			});
		} else {
			this.setState({
				[name]: "N",
			});
            if(name == "custom"){
                this.setState({
                    ['customText']:""
                })
            }
		}
	};
    
    handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

    templateValueChange = (e) => {
		const { name, value } = e.target;

        let tmpTemplateValues = this.state.responseTemplateValues;
        tmpTemplateValues[name] = value;
		this.setState({ responseTemplateValues: tmpTemplateValues });
	};

    onTargetDateChange = (fieldId, date, dateString) => {
        let tmpTemplateValues = this.state.responseTemplateValues;
        if (!isEmptyVariable(date)) {
			tmpTemplateValues[fieldId] = date.format("YYYY-MM-DD");
        }
        const updatedDatePickersOpen = { ...this.state.datePickerOpen, [fieldId]: false };
		this.setState({ 
            responseTemplateValues: tmpTemplateValues,
            datePickerOpen: updatedDatePickersOpen
         });
    };

    handleOpenChange = (fieldId, open) => {
        const updatedDatePickersOpen = { ...this.state.datePickerOpen, [fieldId]: open };
        this.setState({ datePickerOpen: updatedDatePickersOpen });
    };

    clearDate = (fieldId) => {
        let tmpTemplateValues = this.state.responseTemplateValues;
        tmpTemplateValues[fieldId] = "";
        
        const updatedDatePickersOpen = { ...this.state.datePickerOpen, [fieldId]: false };
        this.setState({
            responseTemplateValues: tmpTemplateValues,
            datePickerOpen: updatedDatePickersOpen
        })
    }

    addSelectedValues = (fieldId,item) => {
        let tmpTemplateValues = this.state.responseTemplateValues;
        let multiSelectArray = [];
        if(!tmpTemplateValues[fieldId]){
            multiSelectArray.push(item.itemData.value)
        }else{
            multiSelectArray = JSON.parse(tmpTemplateValues[fieldId]);
            multiSelectArray.push(item.itemData.value)
        }
        
        tmpTemplateValues[fieldId]=JSON.stringify(multiSelectArray);
		this.setState({ responseTemplateValues: tmpTemplateValues });
    }

    removeSelectedValues = (fieldId,item) => {
        let multiSelectArray = JSON.parse(this.state.responseTemplateValues[fieldId]);
        multiSelectArray = multiSelectArray.filter(i => i != item.itemData.value);
        
        let tmpTemplateValues = this.state.responseTemplateValues;
        tmpTemplateValues[fieldId]=JSON.stringify(multiSelectArray);
		this.setState({ responseTemplateValues: tmpTemplateValues });
    }

    onDropDownItemClick = (item, fieldId) => {
        let tmpTemplateValues = this.state.responseTemplateValues;
        tmpTemplateValues[fieldId] = item.value;
        this.setState({
            responseTemplateValues:tmpTemplateValues
        })
    }

    onChangeSupporting = (e) => {
        e.preventDefault();
        for (let i = 0; i < e.target.files.length; i++){
            this.state.supportingRemarksArray.push({ fileName: e.target.files[i].name, remark: "" });
            this.setState({
                supportingSelectedFiles: [...this.state.supportingSelectedFiles, ...e.target.files]
            })
        }
    }

    handleSupportingOnChange = (e, index) => {
        const updatedArray = [...this.state.supportingRemarksArray];
        updatedArray[index].remark = e.target.value;
        this.setState({ supportingRemarksArray: updatedArray });
    }

    handleUploadedSupportingOnChange = (e, index) => {
        const updateRemarkArr = [...this.state.supportingUpdateRemarkArray];
        updateRemarkArr[index].remark = e.target.value;
        this.setState({ supportingUpdateRemarkArray: updateRemarkArr });
    }

    handleDeleteItemClick = (index) => {
        let supportingSelectedFilesTemp = [...this.state.supportingSelectedFiles];
        supportingSelectedFilesTemp.splice(index,1);

        let remarkArr = [...this.state.supportingRemarksArray];
        remarkArr.splice(index, 1);

        this.setState({
            supportingRemarksArray:remarkArr,
            supportingSelectedFiles:supportingSelectedFilesTemp
        })
    }

    onAddTableClick = (fieldId,index) => {
		this.setState({
			showAlertWithInputDialog: true,
			alertWithInputDialogMessage: "Enter the number of Rows and Columns",
            tableFieldId:fieldId
		});
	};

    handleAlertWithInputDialogClose = () => {
		this.setState({
			showAlertWithInputDialog: false,
			alertWithInputDialogMessage: "",
		});
	};

    handleAddIntanceTable = (rows, cols) => {
        let tableArray = [];
        for(let i=0;i<rows;i++){
            tableArray[i]=[];
            let j= 0;
            for(j=0;j<cols;j++){
                tableArray[i][j]= "";
            }
            tableArray[i][j] = "Action"
        }
        let tmpTemplateValues = this.state.responseTemplateValues;
        tmpTemplateValues[this.state.tableFieldId] = JSON.stringify(tableArray);

		this.setState({
			showAlertWithInputDialog: false,
			alertWithInputDialogMessage: "",
            responseTemplateValues:tmpTemplateValues,
            tableFieldId:""
		});
	};

    addNewColumn = (fieldId,e) => {
        let tmpTemplateValues = this.state.responseTemplateValues;
        let tableArray = JSON.parse(tmpTemplateValues[fieldId]);
        let rowLength = tableArray.length;
        let colLength = tableArray[0].length;
        let newArr = [];

        for(let i=0;i<rowLength;i++){
            let rowArray = tableArray[i];
            rowArray.splice(colLength-1,0,"")
            newArr.push(rowArray);
        }
        tmpTemplateValues[fieldId] = JSON.stringify(newArr);

        this.setState({
            responseTemplateValues:tmpTemplateValues
        })
    }

    addNewRow = (fieldId,e) => {
        let tmpTemplateValues = this.state.responseTemplateValues;
        let tableArray = JSON.parse(tmpTemplateValues[fieldId]);
        let singleRow = [];
        let colLength = tableArray[0].length;
        for(let i=0; i<colLength; i++){
            if(i === colLength-1){
                singleRow.push("Action")
            }else{
                singleRow.push("");
            }
        }
        tableArray.push(singleRow);
        tmpTemplateValues[fieldId] = JSON.stringify(tableArray);

        this.setState({
            responseTemplateValues:tmpTemplateValues
        })
    }

    handleTableInputBlur = (rowIndex, colIndex, fieldId, e) => {
		const value = e.currentTarget.textContent;
        let tmpTemplateValues = this.state.responseTemplateValues;
        let tableArray = JSON.parse(tmpTemplateValues[fieldId]);
		tableArray[rowIndex][colIndex] = value;
        tmpTemplateValues[fieldId] = JSON.stringify(tableArray);

		this.setState({
            responseTemplateValues:tmpTemplateValues
        });
	};

    deleteColumn = (colIndex, fieldId,  e) =>{
        let tmpTemplateValues = this.state.responseTemplateValues;
        let tableArray = JSON.parse(tmpTemplateValues[fieldId]);
        let tableArrayTemp = [];

        tableArray.forEach((rowItem,rowIndex)=>{
            rowItem.splice(colIndex,1);
            tableArrayTemp[rowIndex]=[...rowItem];
        });
        tmpTemplateValues[fieldId] = JSON.stringify(tableArrayTemp);

        this.setState({
            responseTemplateValues:tmpTemplateValues
        })
    }

    deleteRow = (rowIndex, fieldId,  e) =>{
        let tmpTemplateValues = this.state.responseTemplateValues;
        let tableArray = JSON.parse(tmpTemplateValues[fieldId]);
        tableArray.splice(rowIndex,1);
        tmpTemplateValues[fieldId] = JSON.stringify(tableArray);

        this.setState({
            responseTemplateValues:tmpTemplateValues
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
		let arr = dropdownItem.split(":-:");
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Supporting",
                proceedBtnLabel:"Delete",
                showAlertDialog:true,
                responseSupportingMapId:arr[0],
                alertDialogMessage:"Are you sure you want to delete supporting "+getOnlyFileNameFromPath(arr[1])+"?"
            })
        }
    }

    handleAlertDialogClose = () => {
		this.setState({
			operationType:"",
			alertDialogHeading:"",
			proceedBtnLabel:"",
			showAlertDialog:false,
			alertDialogMessage:"",
            responseSupportingMapId:""
		})
	}

    handleDeleteAlertDialogClose = () => {
        this.setState({
            showTableAlertDialog:false,
            alertDialogMessage:""
        })
    }
    handleAddResponseDialogClose = () => {
        this.props.handleAddResponseDialogClose(this.state.isDelete);
        this.setState({
            immediate:"",
            continuous:"",
            custom:"",
            customText:"",
            fullNameArr:[],
            observationResponses:[],
            responseTemplateValues:[],
            supportings:[],
            supportingSelectedFiles:[],
            supportingRemarksArray:[],
            supportingUpdateRemarkArray:[],
            isDelete:false
        })
    }

	downloadAttachment = (documentPath,e) => {
        e.stopPropagation();
        fetch(Constants.API_SERVER_PATH+documentPath)
        .then(response => { return response.blob(); } )
        .then(blob =>
        {
            let fileName = getOnlyFileNameFromPath(documentPath);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    handleDeleteTableClickAlert = (fieldName,fieldId,e) => {
        e.stopPropagation();
        this.setState({
            operationType:"Delete",
            alertDialogHeading:"Delete Field",
            proceedBtnLabel:"Delete",
            deleteFieldId:fieldId,
            showTableAlertDialog:true,
            alertDialogMessage:"Are you sure you want to delete field- "+fieldName+"?"
        })
    }

    handleDeleteTable = () => {
        let tempArr = this.state.responseTemplateValues;
        tempArr[this.state.deleteFieldId] = JSON.stringify([]);

        this.setState({
            responseTemplateValues: tempArr,
            deleteFieldId:"",
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showTableAlertDialog:false,
        })
    }
	/*****************************API*********************************/
	getInitData = () => {
		this.setState({
			showLoader: true,
			showDetailsDialog:false,
            selectedItem:""
		});

        let params = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
            assignmentId: this.props.assignmentId
		}

		if(!isEmptyVariable(this.props.observationId)){
			params.observationId = this.props.observationId
		}else if(!isEmptyVariable(this.props.assignmentTaskId)){
			params.assignmentTaskId = this.props.assignmentTaskId
		}

		fetch(Constants.GetDraftObservationResponse, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(params),
		})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			let employees = [];
            let fullNameTemp = [];
            let responseTemplateFields = [];
            let observationResponses = [];
            let supportings = [];
            if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (data.responseCode === Constants.CODE_SUCCESS) {
                employees = data.data.employees;
				observationResponses = data.data.observationResponses;
                supportings = data.data.supportings;
                if(!isEmptyVariable(data.data.responseTemplateFields)){
                    try{
                        responseTemplateFields = JSON.parse(data.data.responseTemplateFields);
                    }catch(e){}
                }
			} else {
			}
            if (!isEmptyArray(employees)) {
                employees.forEach((item) => {
                    fullNameTemp.push({
                        fullname: item.firstName+" "+item.lastName, 
                        value:item.employeeId
                    });
                });
            }
            let supportingUpdateRemarkArray = supportings.map(item => ({
                fileName: item.filePath,
                responseSupportingMapId: item.responseSupportingMapId,
                remark:item.remarks
            }))
			this.setState({
				componentDidMountFlag: true,
				showLoader: false,
				employeesList: employees,
                fullNameArr:fullNameTemp,
                responseTemplateFields:responseTemplateFields,
                observationResponses:observationResponses,
                supportings:supportings,
                supportingUpdateRemarkArray:supportingUpdateRemarkArray
			});
		});
	};

    handleCreateResponse = () => {
        this.setState({
            showDialogLoader:true,
            submitClickedAtleastOnce:true,
        });

        let isError = false;
        let FormErrorsTemp = {};

        this.state.responseTemplateFields.map(item=>{
            let fieldValue = this.state.responseTemplateValues[item.fieldId];
            if(item.isMandatorySelected==="Y"){
                switch (item.fieldType) { 
                    case TemplateConstants.FIELD_TYPE_TEXT:
                        if(isEmptyVariable(fieldValue)){
                            isError = true;
                            FormErrorsTemp[item.fieldId] = `Please enter ${item.fieldName}`;
                        }
                        break;
                    case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                        if(isEmptyVariable(fieldValue)){
                            isError = true;
                            FormErrorsTemp[item.fieldId] = `Please select ${item.fieldName}`;
                        }
                        break;
                    case TemplateConstants.FIELD_TYPE_DROPDOWN:
                        if(isEmptyVariable(fieldValue)){
                            isError = true;
                            FormErrorsTemp[item.fieldId] = `Please select a ${item.fieldName}`;
                        }
                        break;
                    case TemplateConstants.FIELD_TYPE_DATE:
                        if(!(!isEmptyVariable(fieldValue) || this.state.immediate === "Y" || this.state.continuous === "Y" || this.state.custom === "Y") && item.isTragetDate === "Y"){
                            isError = true;
                            FormErrorsTemp[item.fieldId] = `Please select a ${item.fieldName}`;
                        }
                        if(isEmptyVariable(fieldValue)){
                            isError = true;
                            FormErrorsTemp[item.fieldId] = `Please select a ${item.fieldName}`;
                        }
                        break;
                }
            }
            if(item.fieldType === TemplateConstants.FIELD_TYPE_DATE && item.isTragetDate === "Y"){
                if(this.state.immediate === "Y" && this.state.continuous === "Y" && !isEmptyVariable(fieldValue)){
                    isError = true;
                    FormErrorsTemp[item.fieldId] = "Please select either date or immediate and continuous";
                }else{
                    if(this.state.immediate === "Y" && !isEmptyVariable(fieldValue)){
                        isError = true;
                        FormErrorsTemp[item.fieldId] = "Please select either date or immediate";
                    }
                    
                    if(this.state.continuous === "Y" && !isEmptyVariable(fieldValue)){
                        isError = true;
                        FormErrorsTemp[item.fieldId] = "Please select either date or continuous";
                    }
                }
            }
        })
        if(this.state.custom === "Y" && isEmptyVariable(this.state.customText)){
            isError = true;
            FormErrorsTemp['customText'] = "Please enter the text";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showDialogLoader:false,
            })
        }else {
            const formData = new FormData();
            formData.append('email',userDetails.email);
            formData.append('accessToken',userDetails.accessToken);
            formData.append('assignmentId',this.props.assignmentId);

            if(!isEmptyVariable(this.props.observationId)){
                formData.append('observationId',this.props.observationId);
            }else if(!isEmptyVariable(this.props.assignmentTaskId)){
                formData.append('assignmentTaskId',this.props.assignmentTaskId);
            }

            let templateValues = Object.entries(this.state.responseTemplateValues)
            .filter(([key, value]) => {
                if (value === "") {
                    return false;
                  }
                if (!isJsonString(value)) {
                    return true; // Keep the value if it's not a JSON string
                  }
                  // Parse the JSON string
                    const parsedValue = JSON.parse(value);
                    // Return true if it's not an array or if it's a non-empty array
                    return !Array.isArray(parsedValue) || parsedValue.length > 0;
            }).map(([k,v])=>({
                fieldId:k,
                value:v
            }))
            formData.append('templateValues',JSON.stringify(templateValues));

            if(this.state.immediate === "Y"){
                formData.append('immediate',this.state.immediate);
            }else{
                formData.append('immediate',"N");
            }

            if(this.state.continuous === "Y"){
                formData.append('continuous',this.state.continuous);
            }else{
                formData.append('continuous',"N");
            }

            if(isEmptyVariable(this.state.customText) || this.state.custom==="N"){
                formData.append('customTarget',"");
            }else if(!isEmptyVariable(this.state.customText) ){
                formData.append('customTarget',this.state.customText);
            }

            if(!isEmptyArray(this.state.supportingSelectedFiles)){
                this.state.supportingSelectedFiles.forEach((file) => {
                    formData.append('supportings',file);
                })
            }
            const filteredUpdatedRemark = this.state.supportingUpdateRemarkArray.filter(item => item.remark !== "" || item.responseSupportingMapId !== "");

            const remarks = [...this.state.supportingRemarksArray, ...filteredUpdatedRemark];
            formData.append('remarks',JSON.stringify(remarks));

            let url = "";
            if(this.props.isDraftOnward === true){
                url = Constants.AddDraftResponse;
            }else{
                url = Constants.AddResponse;
            }

            if(this.props.isEdit){
                if(this.props.isDraftOnward === true){
                    url = Constants.UpdateDraftResponse;
                }else{
                    url = Constants.UpdateResponse;
                }
            }
            
            fetch(url,{
                method: "POST",
                mode:'cors',
                body: formData
            })
            .then(response => { return response.json(); } )
            .then(data => {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        responseTemplateValues:[],
                        formErrors:FormErrorsTemp,
                        showDialogLoader:false
                    })
                    this.props.handleAddResponseDialogClose(true);
                }else{
                    toast.error(data.responseMessage);
                    this.setState({
                        formErrors:FormErrorsTemp,
                        showDialogLoader:false
                    })
                }
            });
        }
    }

    handleDeleteSupportingFile = () => {
        fetch(Constants.DeleteResponseSupportingFile, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
                email: userDetails.email,
			    accessToken: userDetails.accessToken,
                responseSupportingMapId:this.state.responseSupportingMapId
            }),
		})
		.then((response) => {
			return response.json();
		})
		.then(data => {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                toast.success(data.responseMessage);
                this.handleAlertDialogClose();
                this.getInitData();
                this.setState({isDelete:true})
            }else{

            }
        })
    }
	/*****************************API*********************************/
	render() {
		const delDropdownObj = {
            icon:"delete",
            label:"Delete"
        }
        let actionObjAllowEdit = [delDropdownObj];
        let fields = {text:"value",value:"value"};
        let fieldsWithNDesc = {text:"valueNdesc",value:"value"};
        let fieldsWithDesc = {text:"description",value:"value"};
		return (
			<section>
                <Modal className="custom-dialog" show={this.props.showAddResponseDialog} backdrop="static">
                    <Modal.Header>
                        <h5>{this.props.isEdit?"Edit Response":"Add New Response"}</h5>
                        <button 
                            onClick={this.handleAddResponseDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        {
                            this.state.showDialogLoader &&
                            <div className="modal-loading-text">
                                <p
                                style={{
                                    background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                    color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                                }}
                                >Loading....</p>
                            </div>
                        }
                        {this.state.componentDidMountFlag &&
                        <>
                            <div class="addnew-modal-form">
                                {!isEmptyArray(this.state.responseTemplateFields) &&
                                    <>
                                        {this.state.responseTemplateFields.map((item,index)=>{
                                            let hasValueNdesc = false;
                                            let hasDescription = false;
                                            if(item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
                                                item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN){
                                                    hasValueNdesc = item.dropdownValues.some(value => value.valueNdesc && value.valueNdesc.trim());
                                                    hasDescription = item.dropdownValues.some(value => value.description && value.description.trim());
                                            }
                                            let isFieldIdCheck = isEmptyVariable(this.state.responseTemplateValues[item.fieldId]);
                                            let fieldIdValue = this.state.responseTemplateValues[item.fieldId];
                                            return (
                                                <div class="addnew-modal-form-group">
                                                    {item.fieldType === TemplateConstants.FIELD_TYPE_TEXT &&
                                                        <>
                                                            <p>{item.fieldName} {item.isMandatorySelected=="Y"?'*':''}</p>
                                                            <textarea 
                                                                type="text" 
                                                                placeholder={item.placeholderValue}
                                                                name={item.fieldId}
                                                                value={isFieldIdCheck&&!this.props.isEdit?"":fieldIdValue}
                                                                onChange={this.templateValueChange}
                                                                spellCheck="true" 
                                                            />
                                                            {
                                                                !isEmptyVariable(this.state.formErrors[item.fieldId]) &&
                                                                <span class="cm-error">{this.state.formErrors[item.fieldId]}</span>
                                                            }
                                                        </>
                                                    }
                                                    {item.fieldType === TemplateConstants.FIELD_TYPE_DATE &&
                                                        <>
                                                            <p>{item.fieldName} {item.isMandatorySelected=="Y"?'*':''}</p>
                                                            <DatePicker
                                                                placeholder={item.placeholderValue}
                                                                onClick={() => this.handleOpenChange(item.fieldId, true)}
                                                                onChange={this.onTargetDateChange.bind(this,item.fieldId)}
                                                                format={"DD/MM/YYYY"} 
                                                                onBlur={() => this.handleFocusOut(item.fieldId)}
                                                                open={this.state.datePickerOpen[item.fieldId] || false}
                                                                value={isFieldIdCheck ? "" : moment(fieldIdValue)}
                                                                clearIcon={
                                                                    <span
                                                                        className="material-icons"
                                                                        onMouseDown={()=> {
                                                                            this.clearDate(item.fieldId)
                                                                        }}
                                                                        style={{fontSize: 18}}
                                                                    >
                                                                        cancel
                                                                    </span>
                                                                }
                                                            />
                                                            {
                                                                item.isTragetDate === "Y" && 
                                                                <div class="addnew-modal-form-group mt-2">
                                                                    <FilterCheckBox themeSettings={this.props.themeSettings} style={{marginLeft:0,display:"inline-flex"}}>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="immediate"
                                                                            id="immediate"
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.immediate === "Y" ? true : false}
                                                                        />
                                                                        <label for="immediate">Immediate</label>
                                                                    </FilterCheckBox>
                                                                    <FilterCheckBox themeSettings={this.props.themeSettings} style={{marginLeft:10,display:"inline-flex"}}>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="continuous"
                                                                            id="continuous"
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.continuous === "Y" ? true : false}
                                                                        />
                                                                        <label for="continuous">Continuous</label>
                                                                    </FilterCheckBox>
                                                                    <FilterCheckBox themeSettings={this.props.themeSettings} style={{marginLeft:10,display:"inline-flex"}}>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="custom"
                                                                            id="custom"
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.custom === "Y" ? true : false}
                                                                        />
                                                                        <label for="custom">Custom</label>
                                                                    </FilterCheckBox>
                                                                    {
                                                                        this.state.custom === "Y" &&
                                                                        <div class="m-t-20">
                                                                            <p>Custom*</p>
                                                                            <textarea 
                                                                                type="text" 
                                                                                placeholder="Enter Custom text"
                                                                                name="customText" 
                                                                                value={this.state.customText}
                                                                                onChange={this.handleChange}
                                                                                spellCheck="true" 
                                                                            />
                                                                            {
                                                                                !isEmptyVariable(this.state.formErrors['customText']) &&
                                                                                <span class="cm-error">{this.state.formErrors['customText']}</span>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                (!isEmptyVariable(this.state.formErrors[item.fieldId]) &&
                                                                <span class="cm-error">{this.state.formErrors[item.fieldId]}</span>)
                                                            }
                                                        </>
                                                    }
                                                    {item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                        item.isResponsibilityField === "Y" && 
                                                        <>
                                                            <p>{item.fieldName} {item.isMandatorySelected=="Y"?'*':''}</p>
                                                            <MultiSelectComponent
                                                                templateItemIndex={"multi"+index}
                                                                dataSource={this.state.fullNameArr}
                                                                fields={{text:"fullname",value:"value"}}
                                                                placeholder={item.placeholderValue} 
                                                                mode="CheckBox"
                                                                select={this.addSelectedValues.bind(this,item.fieldId)}
                                                                removed={this.removeSelectedValues.bind(this,item.fieldId)}
                                                                closePopupOnSelect={false}
                                                                allowFiltering={false}
                                                                readOnly={true}
                                                                value={!isEmptyVariable(fieldIdValue) ? JSON.parse(fieldIdValue) : ""}
                                                                cssClass="clientsChecklist dialogcl"
                                                                showDropDownIcon={true}
                                                                showClearButton={false}
                                                            >
                                                            <Inject services={[CheckBoxSelection]} />
                                                            </MultiSelectComponent>
                                                            {
                                                                !isEmptyVariable(this.state.formErrors[item.fieldId]) &&
                                                                <span class="cm-error">{this.state.formErrors[item.fieldId]}</span>
                                                            }
                                                        </>
                                                    }
                                                    {item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                        item.isResponsibilityField !== "Y" && 
                                                        <>
                                                            <p>{item.fieldName} {item.isMandatorySelected=="Y"?'*':''}</p>
                                                            <MultiSelectComponent
                                                                templateItemIndex={"multi"+index}
                                                                dataSource={item.dropdownValues}
                                                                fields={item.showDescription === "Y" ?
                                                                    (hasValueNdesc ? fieldsWithNDesc : 
                                                                        (hasDescription ? fieldsWithDesc : fields)) :
                                                                    fields
                                                                }
                                                                placeholder={item.placeholderValue} 
                                                                mode="CheckBox"
                                                                select={this.addSelectedValues.bind(this,item.fieldId)}
                                                                removed={this.removeSelectedValues.bind(this,item.fieldId)}
                                                                closePopupOnSelect={false}
                                                                allowFiltering={false}
                                                                readOnly={true}
                                                                value={!isEmptyVariable(fieldIdValue) ? JSON.parse(fieldIdValue) : ""}
                                                                cssClass="clientsChecklist dialogcl"
                                                                showDropDownIcon={true}
                                                                showClearButton={false}
                                                            >
                                                            <Inject services={[CheckBoxSelection]} />
                                                            </MultiSelectComponent>
                                                            {
                                                                !isEmptyVariable(this.state.formErrors[item.fieldId]) &&
                                                                <span class="cm-error">{this.state.formErrors[item.fieldId]}</span>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN &&
                                                        <>
                                                            <p>{item.fieldName}{item.isMandatorySelected === "Y"?"*":""}</p>
                                                            <RegularDropdown 
                                                                placeholder={isFieldIdCheck?item.placeholderValue:fieldIdValue}
                                                                dropdownArr={item.dropdownValues}
                                                                labelParam={item.showDescription === "Y" ?
                                                                    (hasValueNdesc ? "valueNdesc" : 
                                                                        (hasDescription ? "description" : "value")) :
                                                                        "value"
                                                                }
                                                                onDropDownItemClick={(e)=>this.onDropDownItemClick(e,item.fieldId)}
                                                                defaultPlaceholderDropDown={item.placeholderValue}
                                                            />  
                                                            {
                                                                (!isEmptyVariable(this.state.formErrors[item.fieldId]) &&
                                                                <span class="cm-error">{this.state.formErrors[item.fieldId]}</span>)
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                                                        <>
                                                            <p>{item.fieldName}{item.isMandatorySelected === "Y"?"*":""}</p>
                                                            <div className="flex-center-layout" style={{justifyContent:"center"}}>
                                                                <AddNewButtonLayout themeSettings={this.props.themeSettings}>
                                                                    <a onClick={this.onAddTableClick.bind(this,item.fieldId,index)} href="javascript:void(0);">
                                                                        <span className="material-icons">add_circle</span>
                                                                        <p>Add Table</p>
                                                                    </a>
                                                                </AddNewButtonLayout>
                                                            </div>
                                                            <div>
                                                            {
                                                                isJsonString(fieldIdValue) && JSON.parse(fieldIdValue).length > 0 && 
                                                                <div
                                                                    style={{ width: "100%", marginTop: 10, boxShadow: "none" }}
                                                                    class={
                                                                        item.isNRChecked === "Y"
                                                                            ? "editor-layout editor-disabled common-table"
                                                                            : "common-table editor-layout"
                                                                    }
                                                                >
                                                                    <div className="flex-center-layout m-b-10" style={{justifyContent:"right"}}>
                                                                        <button className="flex-center-layout instances-new-row" onClick={this.addNewColumn.bind(this,item.fieldId)}>
                                                                            <span className="material-icons">add</span>
                                                                            <p>Add New Column</p>
                                                                        </button>
                                                                        <button className="flex-center-layout instances-new-row" onClick={this.addNewRow.bind(this,item.fieldId)}>
                                                                            <span className="material-icons">add</span>
                                                                            <p>Add New Row</p>
                                                                        </button>
                                                                    </div>
                                                                    <table class="table instances-table">
                                                                        {JSON.parse(fieldIdValue).map((rowItem, rowIndex) =>  {
                                                                                if(rowIndex === 0){
                                                                                    return <thead className="instances-thead">
                                                                                        <tr>
                                                                                        {
                                                                                            rowItem.map((colItem, colIndex) => {
                                                                                                return <th width={colItem === "Action"?"5%":""} className= {colItem === "Action"?"text-center":""}>
                                                                                                    <div className="instances-table-header">
                                                                                                        <p
                                                                                                            contenteditable={colItem === "Action" ? "false" : "true"}
                                                                                                            onBlur={this.handleTableInputBlur.bind(this, rowIndex, colIndex,item.fieldId)}
                                                                                                        >
                                                                                                            {colItem}
                                                                                                        </p>
                                                                                                        {colItem === "Action" ? null : (
                                                                                                            <div className="instances-table-del-column">
                                                                                                                <span
                                                                                                                    onClick={this.deleteColumn.bind(this, colIndex,item.fieldId)}
                                                                                                                    className="material-icons"
                                                                                                                    style = {{cursor:'pointer'}}
                                                                                                                >
                                                                                                                    delete
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </th>
                                                                                                
                                                                                            })
                                                                                        }
                                                                                        </tr>
                                                                                    </thead>
                                                                                        
                                                                                }else{
                                                                                    return <tbody className="instances-thead">
                                                                                        <tr>
                                                                                        {
                                                                                            rowItem.map((colItem, colIndex) => {
                                                                                                return <td
                                                                                                    className={colItem === "Action" ? "text-center" : ""}
                                                                                                    contenteditable={colItem === "Action" ? "false" : "true"}
                                                                                                    onBlur={this.handleTableInputBlur.bind(this, rowIndex, colIndex,item.fieldId)}
                                                                                                >
                                                                                                    {
                                                                                                        colItem === "Action" ? (
                                                                                                            <div className="instances-table-del-cell">
                                                                                                                <span
                                                                                                                    onClick={this.deleteRow.bind(this,rowIndex,item.fieldId)}
                                                                                                                    className="material-icons"
                                                                                                                    style = {{cursor:'pointer'}}
                                                                                                                >
                                                                                                                    delete
                                                                                                                </span>
                                                                                                            </div>
                                                                                                    ) : (
                                                                                                        colItem
                                                                                                    )}
                                                                                                </td>
                                                                                            })
                                                                                        }
                                                                                        </tr>
                                                                                    </tbody>
                                                                                }

                                                                            })
                                                                        }
                                                                        
                                                                    </table>
                                                                    <div className="addnew-row-wrapper">
                                                                        <button className="flex-center-layout instances-new-row" onClick={this.handleDeleteTableClickAlert.bind(this,item.fieldName,item.fieldId)}>
                                                                            <span className="material-icons">delete</span>
                                                                            <p>Delete Table</p>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        </>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                                <div class="addnew-modal-form-group">
                                    <p>Supporting</p>
                                    <UploadSampleLayout themeSettings={this.props.themeSettings} className="col-md-4 ml-0 px-0">
                                        <input name="file" 
                                            type="file"
                                            id={"id"}
                                            multiple
                                            onChange={this.onChangeSupporting}
                                        />
                                        <label for={"id"}>File Upload</label>
                                    </UploadSampleLayout>
                                </div>
                                {!isEmptyArray(this.state.supportingSelectedFiles) &&
                                    <div className="common-tab-bg mb-3">
                                        <div className="common-table" style={{width:"100%"}}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>File Name</th>
                                                        <th>Remarks</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.supportingSelectedFiles.map((item,index) => {
                                                        return(
                                                            <tr>
                                                                <td>{item.name}</td>
                                                                <td>
                                                                <input
                                                                    type="text"
                                                                    name={`remarks.${index}.remark`}
                                                                    value={this.state.supportingRemarksArray[index]?.remark}
                                                                    onChange={(e) => this.handleSupportingOnChange(e, index)}
                                                                    className="form-control mt-1 mb-1"
                                                                    style={{height:34, fontSize:"11.7px"}}
                                                                />
                                                                </td>
                                                                <td><span className="material-icons delete-doc-row"
                                                                onClick={this.handleDeleteItemClick.bind(this,index)}>
                                                                    delete
                                                                </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                                {!isEmptyArray(this.state.supportings) &&
                                    <div className="common-tab-bg">
                                        <div className="common-table" style={{width:"100%"}}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" width={"1%"}>No.</th>
                                                        <th width={"5%"}>File Name</th>
                                                        <th width={"10%"}>Remarks</th>
                                                        <th className="text-center" width={"1%"}>Download</th>
                                                        <th className="text-center"  width={"1%"}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.supportings.map((item,index) => {
                                                        return(
                                                            <tr>
                                                                <td className="text-center">{(index+1)}</td>
                                                                <td>{getOnlyFileNameFromPath(item.filePath)}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name={`remarks.${index}.remark`}
                                                                        value={this.state.supportingUpdateRemarkArray[index]?.remark == "" ? "" : 
                                                                            this.state.supportingUpdateRemarkArray[index]?.remark}
                                                                        onChange={(e) => this.handleUploadedSupportingOnChange(e, index)}
                                                                        className="form-control mt-1 mb-1"
                                                                        style={{height:34, fontSize:"11.7px"}}
                                                                    />
                                                                </td>
                                                                
                                                                <TableIcon themeSettings={this.props.themeSettings} className="text-center">
                                                                    <span class="material-icons" onClick={this.downloadAttachment.bind(this, item.filePath)}>get_app</span>
                                                                </TableIcon>
                                                                <td className="text-center">
                                                                    {
                                                                        <TableDropDown
                                                                            actionArr={actionObjAllowEdit}
                                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                            dropDownId = {item.responseSupportingMapId+":-:"+item.filePath}
                                                                            dropdownWidth={"130px"}
                                                                            themeSettings={this.props.themeSettings}
                                                                        />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div class="modal-close-save mt-3">
                                <div class="close-save-col">
                                    <button onClick={this.handleAddResponseDialogClose} type="button" class="modal-close">Cancel</button>
                                    <DialogSaveButton 
                                    themeSettings={this.props.themeSettings}
                                    onClick={this.handleCreateResponse} type="button">Save</DialogSaveButton>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </Modal>
                <AlertWithInput
                    showAlertDialog={this.state.showAlertWithInputDialog}
                    handleAlertDialogClose={this.handleAlertWithInputDialogClose}
                    alertDialogHeading={"Instances"}
                    alertDialogMessage={this.state.alertWithInputDialogMessage}
                    proceedBtnClick={this.handleAddIntanceTable}
                    proceedBtnLabel={"Create"}
                    themeSettings={this.props.themeSettings}
                />
                <AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={
                        (this.state.operationType === "Delete")
                        ?
                        Constants.ALERT_TYPE_WARNING
                        :
                        Constants.ALERT_TYPE_INFO
                    }
                    alertDialogHeading={this.state.alertDialogHeading}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={() => this.handleDeleteSupportingFile()}
                    proceedBtnLabel={this.state.proceedBtnLabel}
                    themeSettings={this.props.themeSettings}
                />
                <AlertDialog 
                    showAlertDialog={this.state.showTableAlertDialog}
                    handleAlertDialogClose={this.handleDeleteAlertDialogClose}
                    type={
                        (this.state.operationType === "Delete")
                        ?
                        Constants.ALERT_TYPE_WARNING
                        :
                        Constants.ALERT_TYPE_INFO
                    }
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={(this.state.operationType === "Delete")
                        ?this.handleDeleteTable:""
                    }
                    proceedBtnLabel={this.state.proceedBtnLabel}
                    themeSettings={this.props.themeSettings}
                />
            </section>
		);
	}
}

export default AssertionAssignmentList;
