import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { isEmptyVariable,getLocalStorageVariables,getStringWithSpaceAfterComma,isJsonString } from "../../../common/Global/commonFunctions";
import moment from "moment";
import ReportDetails from "./reportDetails";
import { AssigmentDetailsIconLayout } from '../../../common/Global/globalStyles.style';
import InfoCardComponent from "../../Execute/infoCard/infoCardComponent";
import {
    getCheckListScoresFlag,
    getAllReportTasks,
} from '../../Execute/Tasks/execTaskUtilFunctions';
const userDetails = getLocalStorageVariables();

class AssignmentHeading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignmentDetails:{},
            showDetailsDialog: false,
			selectedItem: "",
            showInfoCard:false,
            totalTaksCount:0,
        }
    }

    handleDetailsDialogClose = () => {
		this.setState({
			showDetailsDialog: false,
			selectedItem: "",
		});
	};

	handleDetailsDialogShow = (item) => {
		this.setState({
			showDetailsDialog: true,
			selectedItem: item,
		});
	};
    handleGetAssignmentDetails = () => {
		// this.setState({
		// 	showLoader: true,
		// });

        let url = "";
        if(this.props.isDraftOnward === true){
            url = Constants.GetDraftAssignmentsDetails;
        }else{
            url = Constants.GetAssignmentsDetails;
        }
        
        
		fetch(url, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId: this.props.assignmentId,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let assignmentDetails = {};

				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
                    if(this.props.isDraftOnward === true){
                        assignmentDetails = data.data.result;
                    }
                    else{
                        assignmentDetails = data.data.assignmentDetails;

                    }
				} else {
				}
                this.setState({
                    assignmentDetails: assignmentDetails
                },()=>{
                    this.handleDetailsDialogShow(this.state.assignmentDetails);
                });
			});
	};


    handleGetInfoCardDetials =() =>{
        let getTaskListUrl = Constants.GetReportsTasksAll;
        let getAssignmentDetailsUrl = Constants.GetAssignmentsDetails;
        Promise.all([
			fetch(getTaskListUrl, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
				}),
			}),
			fetch(getAssignmentDetailsUrl, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
				}),
			}),
		])
			.then(([taskRes, assignmentRes]) => {
				return Promise.all([taskRes.json(),assignmentRes.json()]);
			})
			.then(([taskRes,assignmentRes]) => {
				let allDatalistTemp = [];
				let myDatalistTemp = [];
				let assignmentArea = "";
				
                //Since we need assignment area lets check this first
                let selectedTaskTemplateFields = [];
                let execTaskTemplateFields = [];
                let taskTemplateValuesMap = {};
                let executionTemplateValuesMap = {};
                let executionTemplateFixedFields = {};
                let assignmentRiskMatrix = [];
                let valueAdditions = [];
                
                if (assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                        assignmentArea = assignmentRes.data.assignmentDetails.assignmentArea;

                    //TASK TEMPLATE RELATED STUFFS
                    taskTemplateValuesMap = assignmentRes.data.taskTemplateValuesMap;
                    let selectedTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.taskTemplateFields;//Template field IDs	
                    if(!isEmptyVariable(selectedTaskTemplateFieldsStr) && isJsonString(selectedTaskTemplateFieldsStr)){
                        selectedTaskTemplateFields = JSON.parse(selectedTaskTemplateFieldsStr);
                    }
                    
                    //EXEC TASK RELATED STUFFS
                    executionTemplateValuesMap = assignmentRes.data.executionTemplateValuesMap;
                    let execTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.templateFields;
                    if(!isEmptyVariable(execTaskTemplateFieldsStr) && isJsonString(execTaskTemplateFieldsStr)){
                        execTaskTemplateFields = JSON.parse(execTaskTemplateFieldsStr);
                    }

                    //EXEC TASK FIXED FIELDS
                    let fixedTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.fixedTemplateFields;
                    if(!isEmptyVariable(fixedTemplateFieldsStr) && isJsonString(fixedTemplateFieldsStr)){
                        executionTemplateFixedFields = JSON.parse(fixedTemplateFieldsStr);
                    }

                    assignmentRiskMatrix = assignmentRes.data.riskLevels;
                    valueAdditions = assignmentRes.data.valueAdditions;

				} else {
				}

                let maxRank = 0;
                let temptotalTaksCount=0;
             
				if (taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (taskRes.responseCode === Constants.CODE_SUCCESS) {
					allDatalistTemp = taskRes.data.assignedTasks;
					let allDataList = JSON.parse(JSON.stringify(allDatalistTemp))
					myDatalistTemp = getAllReportTasks(allDataList,assignmentArea,maxRank);

                     Object.values(myDatalistTemp).map((item)=>{
                        temptotalTaksCount+= Object.values(item.taskList).length
                    })
				} 
                
				this.setState({
					datalist: myDatalistTemp,
                    selectedTaskTemplateFields:selectedTaskTemplateFields,
                    taskTemplateValuesMap:taskTemplateValuesMap,
                    execTaskTemplateFields:execTaskTemplateFields,
                    executionTemplateValuesMap:executionTemplateValuesMap,
                    executionTemplateFixedFields:executionTemplateFixedFields,
                    showInfoCard:true,
                    totalTaksCount:temptotalTaksCount,
                    assignmentArea: assignmentArea
				});
			});
            
    }

    closeInfoCard = () =>{
        this.setState({
            showInfoCard:false
        })
    }
    
    render(){
        return (
            <div className="assignment-heading" style={{marginTop:10}}>
                {
                    this.props.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                    !isEmptyVariable(this.props.assignmentDetails.unitNameCityList) &&
                    <p className="m-b-0 font-bold" style={{marginRight:20}}>
                        {/* {truncateString(getStringWithSpaceAfterComma(item.unitNameCityList),Constants.OTHERMASTERS_STRING_LENGTH)} */}
                        {getStringWithSpaceAfterComma(this.props.assignmentDetails.unitNameCityList)}
                    </p>
                }
                {
                    this.props.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                    !isEmptyVariable(this.props.assignmentDetails.companyName) &&
                    <p className="m-b-0 font-bold" style={{marginRight:20}}>
                        {this.props.assignmentDetails.companyName} 
                    </p>
                }
                {!isEmptyVariable(this.props.assignmentDetails.assignmentTypeName) &&
                    <p className="m-b-0 font-bold" style={{marginRight:20}}>
                        {this.props.assignmentDetails.assignmentTypeShortName} 
                    </p>
                }
                {/* {!isEmptyVariable(this.props.assignmentDetails.assignmentArea) &&
                    <p className="m-b-0 font-bold" style={{marginRight:20}}>
                        {props.assignmentDetails.assignmentArea} 
                    </p>
                } */}
                {!isEmptyVariable(this.props.assignmentDetails.endDate) &&
                    <p className="m-b-0 font-bold" style={{marginRight:20}}>
                        End Date: {moment(this.props.assignmentDetails.endDate).format("DD/MM/YYYY")} 
                    </p>
                }
                {!isEmptyVariable(this.props.assignmentDetails.periodToDate) && !isEmptyVariable(this.props.assignmentDetails.periodFromDate) &&
                    <p className="m-b-0 font-bold" style={{marginRight:20}}>
                        {"Sample Period: "+moment(this.props.assignmentDetails.periodFromDate).format("DD/MM/YYYY")} - {moment(this.props.assignmentDetails.periodToDate).format("DD/MM/YYYY")}
                    </p>
                }
                <AssigmentDetailsIconLayout themeSettings={this.props.themeSettings}>
                    <span class="material-icons" onClick={this.handleGetInfoCardDetials}>analytics</span>
                    <span className="material-icons" onMouseEnter={this.handleGetAssignmentDetails}>info</span>
                </AssigmentDetailsIconLayout>

                <ReportDetails
                    showDetailsDialog={this.state.showDetailsDialog}
                    handleDetailsDialogClose={this.handleDetailsDialogClose}
                    selectedItem={this.state.selectedItem}
                    themeSettings={this.props.themeSettings}
                    company={this.props.company}
                    isDraftOnward={this.props.isDraftOnward}
                />
               
                {
                 this.state.showInfoCard&&   
                <InfoCardComponent 
                execTaskTemplateFields = {this.state.execTaskTemplateFields}
                taskTemplateFields ={this.state.selectedTaskTemplateFields}
                datalist = {this.state.datalist}
                showUploadAnnexureDialog ={ this.state.showInfoCard}
                themeSettings={this.props.themeSettings}
                executionTemplateValuesMap ={this.state.executionTemplateValuesMap}
                handleSelectUploadAnnexureDialogClose={this.closeInfoCard}
                totalTaskCount={this.state.totalTaksCount}
                taskTemplateValuesMap ={this.state.taskTemplateValuesMap}
                assignmentArea = {this.props.assignmentDetails.assignmentArea}
                />}
            </div>
        );
    }

}
export default AssignmentHeading;

