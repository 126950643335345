import React, { Component } from "react";
import * as Constants from "../../common/Global/constants";
import { SearchSpan, DialogSaveButton, TableCheckBox } from "../../common/Global/globalStyles.style";
import FilterDropDown from "../../common/DropdownMenus/FilterDropdown";
import Pagination from "../../common/Global/pagination";
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable,sortTable,truncateString,
    GetOrderedMasterTabMenuTask,removeHtmlTags,
    isEmptyArray,ifEmptyReturnEmptyStr } from "../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import RegularDropdown from "../../common/DropdownMenus/RegularDropdown";
import {getDropdownActionArray} from '../../common/Global/reviewFunctions';

const userDetails  = getLocalStorageVariables();

const activeStatus="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";

class listTasks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			resultSizePlaceholder: Constants.RESULT_SIZE,
			resultSizeArr: JSON.parse(JSON.stringify(Constants.RESULT_SIZE_DD_ARR)),
			sort: "",
			sortDir: "",

			processMap:[],
            processId:this.props.processId,

			allTaskIds:[],
            nextTaskId:"",
            prevTaskId:"",

            departmentArr:[],
            selectedDeptId:"",
            deptPlaceholder:"",

            statusPlaceholder:activeStatus,
			status:activeStatus,
            statusArr:[
                {
                    label:activeStatus,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    isSelected:false
                },
                {
                    label:inActiveStatus,
                    isSelected:false
                },
            ],

			// selectedId: "",

			// departmentArr: [],
			// selectedDepartmentName: departmentDropdownDefaultValue,
			// selectedDepartmentId: "",
			// formErrors: {},

			// industryName: "",
			// rootIndustryId: "",
			// showIndustryTreeDialog: false,
			// idToBeExpandedAfterAddingChild: "",
		};
	}
	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showProcessTasksDialog) {
			this.setState(
				{
					showLoader: true,

					searchkey: "",
					currentPageNo: 1,
					resultSize: JSON.parse(JSON.stringify(Constants.RESULT_SIZE)),
					resultSizeArr: JSON.parse(JSON.stringify(Constants.RESULT_SIZE_DD_ARR)),
					sort: "",
					sortDir: "",
					resultSizePlaceholder: Constants.RESULT_SIZE ,
                    processId:this.props.processId,
				},
				() => {
					this.getInitData();
				}
			);
		}
	}

	handleChangeSearch = (e) => {
		const { name, value } = e.target;

		this.setState({
			[name]: value,
		});
	};

	onSearchIconClick = () => {
		this.setState(
			{
				currentPageNo: 1,
			},
			() => {
				this.getTasksForAllProcess();
			}
		);
	};

	handleStopPropagation = (e) => {
		e.stopPropagation();
	};

	onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
			this.setState(
				{
					currentPageNo: 1,
				},
				() => {
					this.getTasksForAllProcess();
				}
			);
		}
	};
    
	sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getTasksForAllProcess();
			}
		);
	};

	resultSizeDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.resultSizeArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label ;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				resultSizeArr: tempArr,
				resultSize: item.label,
				resultSizePlaceholder: placeholder,
			},
			() => {
				// this.getIndustryList();
                this.getInitData();
			}
		);
	};

	deptFilterDropdownClick = (item, selectedIndex) => {
		let placeholder = "";
		let id = "";
		let filterDeptArr = this.state.filterDeptArr;

		for (const [i, value] of filterDeptArr.entries()) {
			if (i === selectedIndex) {
				filterDeptArr[i].isSelected = true;
				placeholder = value.deptName;
				id = value.departmentId;
			} else {
				filterDeptArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				selectedFilterDepartmentName: placeholder,
				selectedFilterDepartmentId: id,
			},
			() => {
				this.getIndustryList();
			}
		);
	};

	deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let {departmentArr} = this.state;

        for(const [i,value] of this.state.departmentArr.entries()){
            if(i === selectedIndex){
                departmentArr[i].isSelected = true;
                placeholder = value.name;
            }else{
                departmentArr[i].isSelected = false;
            }
        }
        
        this.setState({
            selectedDeptId:item.departmentId,
            deptPlaceholder:placeholder
        },() => {
            this.getTasksForAllProcess();
        })
    }

	statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getTasksForAllProcess(true);
        })
    }

	//No need of return value since the array itself is passed and updated.
    addParentIdandHasChildrenFlag = (tempArr, processId, parentProcessId, parentProcessBreadCrumb) => {
        tempArr[processId].parentProcessId = parentProcessId;
        let breadCrumb = "";
        if(isEmptyVariable(parentProcessBreadCrumb)){
            breadCrumb = tempArr[processId].processName;
            tempArr[processId].breadCrumb = breadCrumb;
        }else{
            breadCrumb = parentProcessBreadCrumb + " > " + tempArr[processId].processName;
            tempArr[processId].breadCrumb = breadCrumb;
        }

        if(!isEmptyArray(tempArr[processId].children)){
            tempArr[processId].hasChildren = true;

            tempArr[processId].children.map(childNode => {
                this.addParentIdandHasChildrenFlag(tempArr,childNode.processId,processId,breadCrumb);
            });
        }else{
            tempArr[processId].hasChildren = false;
        }
    }
	/*****************************API*********************************/

	getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetProcessesSubtree,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    rootProcessId:this.state.processId,
                    status:"ALL",
                    activeFlag:"Y"//get values from active tables
                })
            }),
            fetch(Constants.GetTaskForProcess,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    processId:this.state.processId,
                    filterTags:JSON.stringify([]),

                    pageNo:1,//initial page no of all the process taks
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,generalRes,menuRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,generalRes,menuRes]) => {
            let processMapTemp = {};
            let datalistTemp = {};
            let menusTemp = {};
            let departmentsTemp = [];
            let userTemp = {};
            let placeholder = "";
            let selectedDeptId = "";
            let allTaskIds = []

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                processMapTemp = masterRes.result.processMap;
                this.addParentIdandHasChildrenFlag(processMapTemp,this.state.processId,this.state.processId,"");
            }else{
                processMapTemp = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = generalRes.data.processList;//list of process and task
                allTaskIds = generalRes.data.allTaskIds;
                datalistTemp = {};

                datalistTempArr.map((item)=>{
                    if(!isEmptyVariable(processMapTemp[item.processId]) && !processMapTemp[item.processId].hasChildren){
                        datalistTemp[item.processId] = item;
                        datalistTemp[item.processId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                    }
                })
                
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
                userTemp = menuRes.data.user;
                departmentsTemp = menuRes.data.departments;

                if(!isEmptyArray(departmentsTemp)){
                    departmentsTemp[0].isSelected = true;
                    placeholder = departmentsTemp[0].name;
                    selectedDeptId = departmentsTemp[0].departmentId;
                }
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            // let menusTemp2 = GetOrderedMasterTabMenuTask(menusTemp)
            // let menusTemp2 = {
            //     Tasks:"ALL",
            //     Checklist:"ALL"
            // }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                processMap:processMapTemp,
                allTaskIds:allTaskIds,
                datalist:datalistTemp,
                // menus:menusTemp2,
                departmentArr:departmentsTemp,
                deptPlaceholder:placeholder,
                selectedDeptId:selectedDeptId,
                user:userTemp
            })
        })
	};

	getTasksForAllProcess = () => {
        this.setState({
            showLoader:true,
        });
        // selectedDeptId
        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            processId:this.state.processId,
            filterTags:JSON.stringify([]),
            
            pageNo:1,//get the initial page
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:this.state.status,
        }
        if(!isEmptyVariable(this.state.selectedDeptId)){
            postParams.departmentId = this.state.selectedDeptId;
        }

        fetch(Constants.GetTaskForProcess,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = data.data.processList;//list of process and task
                let datalistTemp = {};

                datalistTempArr.map((item)=>{
                    if(!this.state.processMap[item.processId].hasChildren){
                        datalistTemp[item.processId] = item;
                        datalistTemp[item.processId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                    }
                })

                this.setState({
                    showLoader:false,
                    datalist:datalistTemp,
                    allTaskIds:data.data.allTaskIds,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:{},
                    allTaskIds:[],
                    showLoader:false,
                    totalCount:0,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (processId) => {
        // update state with new page of items
        this.setState({
            showLoader:true,
        });
        let pageNo = this.state.datalist[processId].currentPageNo+1;

        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            processId:processId,
            filterTags:JSON.stringify([]),
            
            pageNo:pageNo,//get the initial page
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:this.state.status,
        }
        if(!isEmptyVariable(this.state.selectedDeptId)){
            postParams.departmentId = this.state.selectedDeptId;
        }

        fetch(Constants.GetTaskForProcess,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = data.data.processList;//list of process and task
                let dataListTemp = this.state.datalist;
                let selectedProcessObj = dataListTemp[processId];
                selectedProcessObj.currentPageNo = pageNo;
                let newTaskList = datalistTempArr[0].taskList;

                selectedProcessObj.taskList = [
                    ...selectedProcessObj.taskList,
                    ...newTaskList
                ]

                console.log(JSON.stringify(selectedProcessObj.taskList))

                dataListTemp[processId] = selectedProcessObj

                this.setState({
                    showLoader:false,
                    datalist:dataListTemp,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:{},
                    showLoader:false,
                    totalCount:0,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

	/**************************************************************/

	render() {
		return (
			<section>
				<Modal className="task-select-table-dialog custom-dialog" show={this.props.showProcessTasksDialog} onHide={this.props.handleTaskClose}>
					<Modal.Header>
						<h5>Tasks</h5>
						<button onClick={this.props.handleTaskClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						{this.state.showLoader && (
							<div
								className="loading-layout"
								style={{
									background: `rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},0.7)`,
								}}
							>
								<div className="loading-text-layout">
									<p
										style={{
											color: `rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`,
										}}
									>
										Loading....
									</p>
								</div>
							</div>
						)}

						{
							// Need to check component did mount flag, other wise until api is called,
							// it shows no items found layout.
							this.state.componentDidMountFlag && (
								<div className="body-wrapper">
										<div>
											<div className="search-addnew-bg">
												<div className="search-addnew">
												<div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={this.props.themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                        themeSettings={this.props.themeSettings}
                                                        onClick = {this.onSearchIconClick} 
                                                        className="material-icons"> search </SearchSpan>
                                                    </div>

                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        themeSettings={this.props.themeSettings}
                                                    />
                                                    
                                                    {
                                                        this.state.departmentArr.length > 1 &&
                                                        <FilterDropDown
                                                            placeholder={this.state.deptPlaceholder}
                                                            dropdownArr={this.state.departmentArr}
                                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                                            name={"name"}
                                                            themeSettings={this.props.themeSettings}
                                                            dropdownWidth={"120px"}
                                                        />
                                                    }
                                                </div>
												</div>
											</div>
											{
                                            Object.values(this.state.datalist).map((processObj,index)=>{
                                                return <div className="process-task-list">
                                                    <h6 className="c-pointer" style={{marginTop:15}}>{this.state.processMap[processObj.processId].breadCrumb}</h6>
                                                    {
                                                        !isEmptyArray(processObj.taskList) && 
                                                        <div className="common-tab-bg">
                                                            <div className="common-table" style={{width:"100%",borderRadius:4}}>
																<table className="table">
																	<thead>
																	<tr>
																		<th className="text-center" width={"5%"}>
																			No
																		</th>
																		<th width={"5%"}>
																			<div className="sort-header" ></div>
																		</th>
																		<th  width={"85%"}>
																			<div className="sort-header" >Task</div>
																		</th>
																	</tr>
																	</thead>
																	<tbody>
                                                                        {
                                                                            !isEmptyArray(processObj.taskList) && 
                                                                            processObj.taskList.map((item,idx) => {

                                                                                let actionObj = getDropdownActionArray(item.allowEdit, item.allowReview, 
                                                                                    item.status, item.currentReviewStatus, "Functions");

                                                                                return (
																					<tr>
																						<td className="text-center">{(idx+1)}</td>
																						<td>
                                                                                            {/* {ifEmptyReturnEmptyStr(item.riskLevel,"-")} */}
                                                                                            {
                                                                                                    isEmptyVariable(item.riskLevel) ? "-" : (
                                                                                                    <div className="flex-center-layout">
                                                                                                        {isEmptyVariable(item.colorCode) ? item.riskLevel:""}
                                                                                                        
                                                                                                        {
                                                                                                            !isEmptyVariable(item.colorCode) &&
                                                                                                            <div
                                                                                                                className="risk-level-color-wrapper-round"
                                                                                                                style={{ width: "38px",marginLeft: (!isEmptyVariable(item.colorCode) ?0:8)}}
                                                                                                            >
                                                                                                                <div
                                                                                                                    className="risk-level-color-round"
                                                                                                                    style={{ background: item.colorCode }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                )}
                                                                                        </td>
																						<td> <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: item.task}} /></td>
																					</tr>
																				)
                                                                            })
                                                                        }
																	</tbody>
																</table>
                                                            </div>
                                                        </div>
                                                    }
                                                    {/* <div className="loadmore-layout">
                                                        <div>
                                                        {
                                                            processObj.currentPageNo*this.state.resultSize < processObj.count &&
                                                            <LoadMoreButton 
                                                            themeSettings = {this.props.themeSettings}
                                                            onClick={this.onChangePage.bind(this,processObj.processId)} type="button">Load More</LoadMoreButton>
                                                        }
                                                        </div>
                                                        {
                                                            processObj.count > 0 &&
                                                            <p>{"Total Records: "+processObj.count}</p>
                                                        }
                                                        
                                                    </div> */}
                                                

                                                    {
                                                        this.state.componentDidMountFlag && isEmptyArray(processObj.taskList) &&
                                                        <div class="no-items-layout">
                                                            <div class="no-items-card" style={{padding:"10px"}}>
                                                                <h6 style={{margin:0}}>
                                                                {
                                                                    isEmptyVariable(this.state.apiSearchKey)?
                                                                    Constants.NO_RECORDS_WARNING
                                                                    :
                                                                    Constants.EMPTY_SEARCH_WARNING
                                                                }
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            })
                                            
                                        }

											{/* <div className="pagination-layout">
												<div className="row custom-row">
													<div className="col-md-9 custom-col">
														{this.state.resultSize < Object.values(this.state.datalist)[0].taskList.length && (
															<Pagination
																totalLength={this.state.totalCount}
																items={this.state.datalist}
																onChangePage={this.onChangePage}
																pageSize={this.state.resultSize}
																currentPageNo={this.state.currentPageNo}
																initialPage={this.state.currentPageNo}
																themeSettings={this.props.themeSettings}
															/>
														)}
													</div>
												</div>
											</div> */}
										</div>

                                        
									<div class="modal-close-save m-t-15">
										<div class="close-save-col">
											<button onClick={this.props.handleTaskClose} type="button" class="modal-close">
												Close
											</button>
											{/* <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.handleCopyIndustry} type="button">
												Save
											</DialogSaveButton> */}
										</div>
									</div>
								</div>
							)
						}
					</div>
				</Modal>
			</section>
		);
	}
}
export default listTasks;
