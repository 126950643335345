import React, { Component } from 'react';
import {Modal, Tab, Tabs} from 'react-bootstrap';
import AssignResourcesComponent from "./assignResourcesComp"
import ReassignResources from "./assignResourcesCompEdit";
import { isEmptyVariable } from '../../../common/Global/commonFunctions';

class AssignResources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            datalist:[],
            totalCount:0,
            currentPageNo: 1,
            // formErrors:{},
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showResourcesDialog){

            // if(this.props.isEdit){
            //     this.setState({
            //         componentDidMountFlag:false,
            //         showLoader:false,
            //         datalist:[],
            //         datalistReport:[],
            //         totalCount:0,
            //         currentPageNo: 1,
            //         formErrors:{},
    
            //     })
            // }else{
            //     this.setState({
            //         componentDidMountFlag:false,
            //         showLoader:false,
            //         datalist:[],
            //         totalCount:0,
            //         currentPageNo: 1,
            //         formErrors:{},
    
            //     })
            // }
        }
    }

    render() {

        // const showExecResource = this.props.selectedAssignmentStatus === "Completed";
        const showReviewCycleCheckbox = true;
        // const showReviewCycleCheckbox = !isEmptyVariable(this.props.selectedObsTemplateId);

        return(
            <Modal className="assign-resources-dialog custom-dialog" 
            show={this.props.showResourcesDialog} backdrop="static">
                <Modal.Header>
                    <h5>Assign Resources</h5>
                    <button 
                        onClick={this.props.handleAllResourcesDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    <Tabs className="report-details-tab"
                        defaultActiveKey={"teamResource"}
                        id="assignResources">
                        {
                            //if it is assign resources show this dialog
                            // (showExecResource) &&
                            <Tab eventKey="teamResource" title="Execute Resources">
                                {
                                    this.props.assignResourcesEdit
                                    ?
                                    <ReassignResources
                                        handleAllResourcesDialogClose = {this.props.handleAllResourcesDialogClose}
                                        assignmentId = {this.props.assignmentId}
                                        selectedAssignmentStatus = {this.props.selectedAssignmentStatus}
                                        themeSettings = {this.props.themeSettings}
                                        showReviewCycleCheckbox= {showReviewCycleCheckbox}
                                        reviewAssignmentTask={this.props.reviewAssignmentTask}
                                    />
                                    :
                                    <AssignResourcesComponent
                                        handleAllResourcesDialogClose = {this.props.handleAllResourcesDialogClose}
                                        assignmentId = {this.props.assignmentId}
                                        themeSettings = {this.props.themeSettings}
                                        selectedAssignmentStatus = {this.props.selectedAssignmentStatus}
                                        isEdit= {this.props.assignResourcesEdit}
                                        showReviewCycleCheckbox= {showReviewCycleCheckbox}
                                        reviewAssignmentTask={this.props.reviewAssignmentTask}
                                    />
                                }
                                
                            </Tab>
                        }
                    </Tabs>
                </div>
            </Modal>
        );
    }
}

export default AssignResources;