import {
  Table,
  TableRow,
  TableCell,
  Paragraph,
  WidthType,
  BorderStyle,
  TextRun,
  AlignmentType,
  ImageRun
} from "docx";
import { isEmptyVariable } from "../../../common/Global/commonFunctions";
let fontName = "Times New Roman";
let clausesList = [
  "4.1", "4.2", "4.3", "4.4", "5.1", "5.2", "5.3", "6.1", "6.2", "6.3", "7.1", "7.2", "7.3",
  "7.4", "7.5", "8.1", "8.2", "8.3", "8.4", "8.5", "8.6", "8.7", "9.1", "9.2",
  "9.3", "10.1", "10.2", "10.3", "C.C", "S&R", "S&C", "LOGO",
];

let clausecheckList = [
  {
    key: "Opening Meeting",
    value: []
  },
  {
    key: "Quality Excellence Cell (QxL)", value: ["4.1", "4.2", "4.3", "4.4", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.1", "8.3", "8.4", "8.7", "9.1", "9.2", "10.1", "10.2", "10.3"],
  },
  {
    key: "Planning & Document Controller", value: ["4.1", "4.2", "4.3", "4.4", "5.1", "5.2", "5.3", "6.1", "6.2", "6.3", "7.1", "7.3", "7.4", "7.5", "8.1", "8.2", "8.5", "9.2", "10.1", "10.3"],
  },
  {
    key: "QS_Subcontracts", value: ["4.1", "4.2", "4.3", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.2", "8.4", "8.5", "9.1", "9.2", "10.1", "10.3"],
  },
  {
    key: "Construction Plant and Equipment (CPE)", value: ["4.1", "4.2", "4.3", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.4", "8.5", "9.1", "9.2", "10.1", "10.3"],
  },
  {
    key: "Stores & Purchase", value: ["4.1", "4.2", "4.3", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.4", "8.5", "8.6", "9.1", "9.2", "10.1", "10.3"],
  },
  {
    key: "Administration", value: ["4.1", "4.2", "4.3", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "9.2", "10.1", "10.3"],
  },
  {
    key: "Survey", value: ["4.1", "4.2", "4.3", "4.4", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "9.2", "10.1", "10.3"],
  },
  {
    key: "Design", value: ["4.1", "4.2", "4.3", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.3", "8.4", "9.2", "10.1", "10.3"],
  },
  {
    key: "Structure", value: ["4.1", "4.2", "4.3", "4.4", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.2", "9.1", "9.2", "10.1", "10.3"],
  },
  {
    key: "Precast", value: ["4.1", "4.2", "4.3", "4.4", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.2", "9.1", "9.2", "10.1", "10.3"],
  },
  {
    key: "Building", value: ["4.1", "4.2", "4.3", "4.4", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.2", "9.1", "9.2", "10.1", "10.3"],
  },
  { key: "Tunneling", value: ["4.1", "4.2", "4.3", "4.4", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.2", "9.1", "9.2", "10.1", "10.3"], },

  {
    key: "PQC", value: ["4.1", "4.2", "4.3", "4.4", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.2", "9.1", "9.2", "10.1", "10.3"],
  },
  {
    key: "Asphalt", value: ["4.1", "4.2", "4.3", "4.4", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.2", "9.1", "9.2", "10.1", "10.3"],
  },
  {
    key: "Earthwork", value: ["4.1", "4.2", "4.3", "4.4", "5.2", "5.3", "6.2", "7.1", "7.3", "7.5", "8.2", "9.1", "9.2", "10.1", "10.3"],
  },
  {
    key: "QAQC / Execution (Fabrication)", value: ["4.1", "4.2", "4.3", "4.4", "5.2", "5.3", "6.2", "7.1", "7.2", "7.5", "8.1", "8.2", "9.2"],
  },
  {
    key: "ISO Cell - SS", value: ["6.2", "7.5", "9.1", "9.2", "9.3"],
  },
  {
    key: "BU Support Services 1", value: ["4.1", "4.2", "4.3", "5.2", "5.3", "6.2", "7.1", "7.5", "9.2"],
  },
  {
    key: "Business Development - MI", value: ["5.1", "7.5", "8.2"],
  },
  {
    key: "Business Development - ST", value: ["5.1", "7.5", "8.2"],
  },
  {
    key: "Business Development - UH", value: ["5.1", "7.5", "8.2"],
  },
  {
    key: "Business Development - UI", value: ["5.1", "7.5", "8.2"],
  },
  {
    key: "Business Development - WS", value: ["5.1", "7.5", "8.2"],
  },
  {
    key: "CPE - SS", value: ["7.1", "7.5", "8.4", "8.5", "9.1","9.2"],
  },
  {
    key: "Design - SS", value: ["8.3", "8.4"],  // Error
  },
  {
    key: "Document Control - OG", value: ["7.4","7.5"]
  },
  {
    key: "Electrical & Instrumentation", value: ["6.2", "8.1"], 
  },
  {
    key: "Engineering - OG", value: ["6.1", "7.5", "8.3", "8.4", "8.5"],
  },
  {
    key: "Execution", value: ["4.4", "7.5", "8", "8.1", "8.2"],
  },
  {
    key: "HR - SS", value: ["7.1", "7.2", "9.1"],
  },
  {
    key: "Library - SS", value: ["7.1", "8.4"],
  },
  {
    key: "Marine & Industrial", value: ["4.1", "4.2", "4.3", "5.2", "5.3", "6.2", "7.1", "7.5", "9.2"],
  },
  {
    key: "Marine Logistics - OG", value: ["4.1", "4.2", "4.3", "4.4"],
  },
  {
    key: "Mechanical Execution", value: ["6.2","8.1"], 
  },
  {
    key: "MIS", value: ["7.5", "8.4", "9.1"], 
  },
  {
    key: "Oil & Gas", value: ["4.1", "4.2", "4.3", "5.2", "5.3", "6.2", "7.1", "7.5","9.2"],
  },
  {
    key: "Piping Works (Water Supply)", value: ['6.2','8.1'],
  },
  {
    key: "Procurement - OG", value: ["7.5", "8.4"],
  },
  {
    key: "Project Planning & Co-ordination - MI", value: ['4.4', '5.1', '6.1', '6.2', '7.1', '7.5', '8.1', '8.2', '9.1'],
  },
  {
    key: "Project Planning & Co-ordination - SP", value: ['4.4', '5.1', '6.1', '6.2', '7.1', '7.5', '8.1', '8.2', '9.1'],
  },
  {
    key: "Project Planning & Co-ordination - UH", value: ['4.4', '5.1', '6.1', '6.2', '7.1', '7.5', '8.1', '8.2', '9.1'],
  },
  {
    key: "Project Planning & Co-ordination - UI", value: ['4.4', '5.1', '6.1', '6.2', '7.1', '7.5', '8.1', '8.2', '9.1'],
  },
  {
    key: "Project Planning & Co-ordination - WS", value: ['4.4', '5.1', '6.1', '6.2', '7.1', '7.5', '8.1', '8.2', '9.1'],
  },
  {
    key: "Projects", value: ["4.1", "4.2", "4.3", "5.2", "5.3", "6.2", "7.1", "7.5", "9.2"],
  },
  {
    key: "Purchase - SS", value: ["8.4"],
  },
  {
    key: "Special Projects", value: ["6.2","7.1","7.2","7.4"],
  },
  {
    key: "Subcontracts - SS", value: ['6.2', '7.5', '8.2', '8.4', '9.1'],
  },
  {
    key: "Surface Transport", value: ['4.1', '4.2', '4.3', '5.2', '5.3', '6.2', '7.1', '7.5', '9.2'],
  },
  {
    key: "Tendering & BD - OG", value: ['4.4', '5.1', '6.1', '7.5', '8.1', '8.2', '8.3', '8.4', '8.5', '9.1'],
  },
  {
    key: "Tendering & BD - MI", value: ['4.4', '5.1', '6.1', '7.5', '8.1', '8.2', '8.3', '8.4', '8.5', '9.1'],
  },
  {
    key: "Tendering & BD - ST", value: ['4.4', '5.1', '6.1', '7.5', '8.1', '8.2', '8.3', '8.4', '8.5', '9.1'],
  },
  {
    key: "Tendering & BD - WS", value: ['4.4', '5.1', '6.1', '7.5', '8.1', '8.2', '8.3', '8.4', '8.5', '9.1'],
  },
  {
    key: "Tendering & BD - UH", value: ['4.4', '5.1', '6.1', '7.5', '8.1', '8.2', '8.3', '8.4', '8.5', '9.1'],
  },
  {
    key: "Tendering & BD - UI", value: ['4.4', '5.1', '6.1', '7.5', '8.1', '8.2', '8.3', '8.4', '8.5', '9.1'],
  },
  {
    key: "Underground & Hydro", value: ['4.1', '4.2', '4.3', '5.2', '5.3', '6.2', '7.1', '7.5', '9.2'],
  },
  {
    key: "Urban Infrastructure", value: ['4.1', '4.2', '4.3', '5.2', '5.3', '6.2', '7.1', '7.5', '9.2'],
  },
  {
    key: "Water Segment", value: ['4.1', '4.2', '4.3', '5.2', '5.3', '6.2', '7.1', '7.5', '9.2']
  },
  {
    key: "Close Meeting", value: []
  }

];

export const getInternalAuditPlanPageContent = (assignmentDetails, dataList,companyLogo,isLogoAvailable,allAuditorList,allviwerList,assignmentTimeline) => {

  let internalAuditPlanContent = [];
  let clausesListContent = [];
  let tableRowContent = [];
  let commonBorderStyle ={ style: BorderStyle.SINGLE, color: "000000", size: 3 }

  let unitName = String(assignmentDetails.unitAddressList).split(":-:")[0]+" ("+ JSON.stringify(assignmentDetails.unitNameCityList).split("(")[0].replace('"', "").trim()+")";
  let fromDate = !isEmptyVariable(assignmentDetails.startDate)?localDateExtractor(assignmentDetails.startDate):"";
  let toDate =!isEmptyVariable(assignmentDetails.endDate)?localDateExtractor(assignmentDetails.endDate):"";
  let auditorName = allAuditorList.map(e=>e.userName).join(", ")
  clausesList.map((item, idx) => {
    clausesListContent.push(
      new TableCell({
        borders: {
          bottom: commonBorderStyle,
          right:
            idx !== clausesList.length - 1
              ? { style: BorderStyle.SINGLE, color: "000000", size: 3 }
              : {},
        },
        columnSpan: 1,
        margins: {
          left: 50,
          right: 50,
          top: 50,
          bottom: 50,
        },

        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: `${item}`,
                allCaps: true,
                font: fontName,
                size: 13,
              }),
            ],
          }),
        ],
      })
    );
  });

  /// logo, Internal Audit Paln, Date
  tableRowContent.push(
    new TableRow({
      children: [
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
          },
          borders: {
            bottom: commonBorderStyle,
          },
          verticalAlign: AlignmentType.CENTER,
          columnSpan: 4,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                !isEmptyVariable(isLogoAvailable) &&
                new ImageRun({
                  data: companyLogo,
                  transformation: {
                    width: 100,
                    height: 30,
                  },
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign: AlignmentType.CENTER,

          margins: {
            top: 50,
            bottom: 50,
            left: 80,
          },
          borders: {
            left: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
            bottom: commonBorderStyle,
          },

          columnSpan: 28,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Internal Audit Plan",
                  font: fontName,
                  size: 25,
                  bold: true,
                  allCaps: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          margins: {
            top: 50,
            left: 80,
            bottom: 50,
          },
          borders: {
            left: { style: BorderStyle.SINGLE, color: "000000", size: 3 },
            bottom: commonBorderStyle,
          },

          columnSpan: 5,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Doc No: ",
                  size: 18,
                  bold: true,
                  font: fontName,
                }),
                new TextRun({
                  text: "0F 9001 05",
                  size: 18,
                  font: fontName,
                }),
              ],
              spacing: {
                after: 50,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Rev No: ",
                  size: 18,
                  font: fontName,
                  bold: true,
                }),
                new TextRun({
                  text: "3",
                  size: 18,
                  font: fontName,
                }),
              ],
              spacing: {
                after: 50,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Date: ",
                  size: 18,
                  font: fontName,
                  bold: true,
                }),
                new TextRun({
                  text: "15/01/2021",
                  size: 18,
                  font: fontName,
                }),
              ],
              spacing: {
                after: 50,
              },
            }),
            // new Paragraph({
            //   children: [
            //     new TextRun({
            //       text: "Page 1 of 1",
            //       size: 18,
            //       font: fontName,
            //     }),
            //   ],
            // }),
          ],
        }),
      ],
    })
  );

  /// Auditor, Scope, Audit Period
  tableRowContent.push(
    new TableRow({
      children: [
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          columnSpan: 2,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Auditor:",
                  font: fontName,
                  size: 20,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          columnSpan: 2,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: auditorName,
                  font: fontName,
                  size: 20,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 80,
          },
          borders: {
            bottom: commonBorderStyle,
          },
          columnSpan: 2,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Scope:",
                  font: fontName,
                  size: 20,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          columnSpan: 26,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "ISO:9001 2015 - QMS REQUIREMENTS",
                  font: fontName,
                  size: 20,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          columnSpan: 5,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Audit Period:",
                  font: fontName,
                  size: 20,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// Trainee, Site, from-To Date
  tableRowContent.push(
    new TableRow({
      children: [
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          borders: {
            bottom: commonBorderStyle,
          },
          columnSpan: 2,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Trainee:",
                  font: fontName,
                  size: 20,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
          },
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          columnSpan: 2,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: allviwerList.join(", "),
                  font: fontName,
                  size: 20,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 80,
          },
          borders: {
            bottom: commonBorderStyle,
          },
          columnSpan: 3,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Site / No:",
                  font: fontName,
                  size: 20,
                  bold: true,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          columnSpan: 25,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text:unitName,
                  allCaps:true,
                  font: fontName,
                  size: 20,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          margins: {
            top: 50,
            bottom: 50,
            left: 80,
          },
          borders: {
            bottom: commonBorderStyle,
          },
          columnSpan: 5,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "From: ",
                  font: fontName,
                  size: 18,
                  bold: true,
                }),
                new TextRun({
                  text: fromDate,
                  font: fontName,
                  size: 18,
                }),
              ],
              spacing: {
                after: 50,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "To: ",
                  font: fontName,
                  size: 18,
                  bold: true,
                }),
                new TextRun({
                  text: toDate,
                  font: fontName,
                  size: 18,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// Date, Department / Function,
  tableRowContent.push(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign: AlignmentType.CENTER,
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          columnSpan: 1,
          rowSpan: 2,
          margins: {
            top: 50,
            bottom: 50,
          },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Date",
                  allCaps: true,
                  font: fontName,
                  bold: true,
                  size: 15,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          verticalAlign: AlignmentType.CENTER,
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          columnSpan: 4,
          rowSpan: 2,
          margins: {
            top: 50,
            bottom: 50,
            left: 50,
            right: 50,
          },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Department / Function",
                  allCaps: true,
                  font: fontName,
                  bold: true,
                  size: 15,
                }),
              ],
            }),
          ],
        }),
      
        new TableCell({
          borders: {
            bottom: commonBorderStyle,
            right: commonBorderStyle,
          },
          columnSpan: 28,

          margins: {
            top: 50,
            bottom: 50,
          },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "ISO 9001:2015 CLAUSES (MARK APPLICABLE CLAUSES)",
                  allCaps: true,
                  font: fontName,
                  bold: true,
                  size: 15,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          borders: {
            bottom: commonBorderStyle,
          },
          columnSpan: 4,

          margins: {
            top: 50,
            bottom: 50,
          },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Other Document",
                  allCaps: true,
                  font: fontName,
                  bold: true,
                  size: 15,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// From, To, CC, S&R, logo
  tableRowContent.push(
    new TableRow({
      children: clausesListContent,
    })
  );

  Object.values(dataList).map((item) => {
    let tempChildren = [];
    clausecheckList.map((clauses) => {

      if (clauses.key.toLocaleLowerCase().trim() == item.functionName.toLocaleLowerCase().trim()) {
        
        let date = assignmentTimeline.filter(e=>JSON.parse(e.functionId).includes(item.functionId) && e.timelineType.toLocaleLowerCase()=="Audit Work".toLocaleLowerCase()).map(e=>e.startDate)[0]||'';
        date = isEmptyVariable(date)?'':localDateExtractor(date);
        tempChildren.push(
          new TableCell({
            borders: {
              bottom: commonBorderStyle,
              right: commonBorderStyle,
            },
            columnSpan: 1,
            verticalAlign:AlignmentType.CENTER,
            margins: {
              top: 50,
              bottom: 50,
            },

            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: date,
                    font: fontName,
                    size: 14,
                  }),
                ],
              }),
            ],
          })
        )
        tempChildren.push(
          new TableCell({
            verticalAlign: AlignmentType.CENTER,
            borders: {
              bottom: commonBorderStyle,
              right: commonBorderStyle,
            },
            columnSpan: 4,
            margins: {
              top: 50,
              bottom: 50,
            },

            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: item.functionName,
                    font: fontName,
                    size: 15,
                  }),
                ],
              }),
            ],
          }),
        )
      
        clausesList.map((listItem) => {
          clauses.value.includes(listItem) ? tempChildren.push(
            new TableCell({
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
                right:
                  listItem!=="LOGO"&& { style: BorderStyle.SINGLE, color: "000000", size: 3 }
                ,
              },
              columnSpan: 1,
              margins: {
                left: 50,
                right: 50,
                top: 50,
                bottom: 50,
              },

              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `✓`,
                      allCaps: true,
                      font: fontName,
                      size: 13,
                    }),
                  ],
                }),
              ],
            })
          ) : tempChildren.push(
            new TableCell({
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
                right:
                listItem!=="LOGO"&& { style: BorderStyle.SINGLE, color: "000000", size: 3 }
                ,
              },
              columnSpan: 1,
              margins: {
                left: 50,
                right: 50,
                top: 50,
                bottom: 50,
              },

              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: " ",
                      allCaps: true,
                      font: fontName,
                      size: 13,
                    }),
                  ],
                }),
              ],
            })
          )
        })
        tableRowContent.push(
          new TableRow({
            children: tempChildren
          })
        )
      }
    })
  })



  /// Information of audit
  tableRowContent.push(
    new TableRow({
      children: [
        new TableCell({
          margins: {
            bottom: 50,
            top: 50,
            right: 80,
            left: 50,
          },
          columnSpan: 37,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "This Audit Programme has been developed by AFCONS ISO cell and is intended to enable you to assure that the appropriate personnel are available for Opening Meeting and during the Audit. The plan is based upon your Departmental / Site Structure, but may be amended by the internal Auditor after the Opening Meeting to better reflect your site / departmental needs. The timings are indicative & may change.",
                  font: fontName,
                  size: 18,
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  /// Full Forms
  tableRowContent.push(
    new TableRow({
      children: [
        new TableCell({
          borders: {
            top: commonBorderStyle,
            bottom:commonBorderStyle
          },
          columnSpan: 8,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "C.C = Customer Complaints",
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          borders: {
            top: commonBorderStyle,
            bottom:commonBorderStyle
          },
          columnSpan: 16,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "S & R = Statutory & Regulator Requirements",
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          borders: {
            top: commonBorderStyle,
            bottom:commonBorderStyle
          },
          columnSpan: 13,
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "S & C = Standards & Codes",
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  internalAuditPlanContent.push(
    new Table({
      width: {
        type: WidthType.PERCENTAGE,
        size: 100,
      },
      borders: {
        top: { style: BorderStyle.NONE, },
        bottom: { style: BorderStyle.NONE, },
        left: { style: BorderStyle.NONE, },
        right: { style: BorderStyle.NONE, },
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },

      rows: tableRowContent,
    })
  );

  return [...internalAuditPlanContent];
};


export const localDateExtractor=(date)=>{
  var date = new Date(date);
  return date.toLocaleDateString('en-GB')
}