import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {getLocalStorageVariables,isEmptyVariable,getThemeSettingsVariables} from '../../common/Global/commonFunctions';
import {TopbarWrapper} from './topbar.style'
import moment from 'moment';

let themeSettings  = getThemeSettingsVariables();
class Topbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotification:false,
            componentDidMountFlag:false,
            currentPageNo: 1,
            showLoader:false,
            resultSize:50,
            totalCount:0,
            datalist:[],
            newNotificationCount:0,
        }
        themeSettings  = getThemeSettingsVariables();
    }

    componentDidMount(){
        this.getNotifications()
    }

    handleShowNotification = (event) => {
        event.preventDefault();
        this.setState({ showNotification: true }, () => {
            document.addEventListener('click', this.hideNotification);
        });
    }

    hideNotification = () => {
        this.setState({ showNotification: false }, () => {
            document.removeEventListener('click', this.hideNotification);
        });
    }

    onClickAllNotifications = (e) => {
        e.stopPropagation();
        window.location = Constants.URL_NOTIFICATIONS;
    }

    /***************************API CALLS****************************/
    getNotifications = () => {
        const userDetails  = getLocalStorageVariables();
        fetch(Constants.GetUserNotifications,{
            method: "POST",
            mode:'cors',
            body:new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                resultsize:this.state.resultSize,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    newNotificationCount:data.data.newNotificationCount
                })
            }else{
                this.setState({
                    datalist:[],
                    totalCount:0,
                    showLoader:false,
                    componentDidMountFlag:true,
                })
            }
        })
    }

    updateNewUserNotifications = () => {
        if(this.state.newNotificationCount > 0){
            this.setState({
                newNotificationCount:0
            })

            const userDetails  = getLocalStorageVariables();
            fetch(Constants.UpdateNewUserNotifications,{
                method: "POST",
                mode:'cors',
                body:new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                }else{
                }
            })
        }
    }

    render() {
        return(
            <Router>
                <TopbarWrapper
                    topbarBg={themeSettings.topbarBg}
                    iconColor={themeSettings.topbarIconColor}
                    selectedIconBg={themeSettings.topbarSelectedIconBg}
                    badgeTextColor={themeSettings.badgeTextColor}
                    badgeBg={themeSettings.notificationBadgeBg}
                    themeColor={themeSettings.themeColor}
                    themeHoverTextColor={themeSettings.themeHoverTextColor}
                >
                    <div className="menu-icon">
                        <span className="material-icons">menu</span>
                    </div>
                    <div className="spacer"></div>
                    <div className="header-right">
                        <div className="info-header">
                            {/* <div className="contact-suppport-layout">
                                <span className="material-icons">mail</span>
                            </div> */}

                            {/* <div className="info-layout-wrapper">
                                <div className="topbar-info-layout">
                                    <img src="/assets/images/topbar-info.svg" />
                                </div>
                            </div> */}

                            <div onClick={this.handleShowNotification} className="header-notification-wrapper">
                                <div className="header-notification" onClick={this.updateNewUserNotifications}>
                                    {
                                        this.state.newNotificationCount > 0 &&
                                        <div className="noti-badge">
                                            <span>{this.state.newNotificationCount}</span>
                                        </div>
                                    }
                                    <span className="material-icons">notifications</span>
                                </div>

                                {
                                    this.state.showNotification &&
                                    <div className="notifications-dropdown">
                                        <div className="notifications-dropdown-col">
                                            <div className="notifications-dropdown-header">
                                            <p>Notifications</p>
                                            </div>
                                            <div className="notifications-dropdown-list">
                                            <ul>
                                                {
                                                    !isEmptyVariable(this.state.datalist) &&
                                                    this.state.datalist.map(item => {
                                                        return <li style={{background:item.status==="New"?"#7d3f7611":"#fff"}}>
                                                            <a href="javascript:void(0);">
                                                                {/* <img src="/assets/images/default-propic.png" /> */}
                                                                <div>
                                                                    <p 
                                                                    style={{lineHeight:1.4,marginBottom:0}}
                                                                    dangerouslySetInnerHTML={{__html: item.desc}}></p>
                                                                    {/* <p>{item.notificationType+" ["+item.screenName
                                                                    +(isEmptyVariable(item.dataType) || item.screenName.toUpperCase() === item.dataType.toUpperCase()?"]":" > "+item.dataType+"]")}</p> */}
                                                                    <span>{moment(item.createDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD-MMM-YYYY hh:mm a")}</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                            </div>
                                            <div className="notification-all-col">
                                                <a href="javascript:void(0)" onClick={this.onClickAllNotifications}>View All</a>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="vertical-line"></div>
                            <div className="info-header-wrapper">
                                <div className="ams-logo">
                                    <img src="/assets/images/sameeksha.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </TopbarWrapper>
            </Router>
        );
    }
}

export default Topbar;