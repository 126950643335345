import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import TreeNodeDropdown from '../../common/DropdownMenus/TreeNodeDropdown';
import {ExpandCollapseWrapper} from '../../common/Global/globalStyles.style';

const userDetails  = getLocalStorageVariables();

const viewTasksObj = {
    icon:"view_list",
    label:"View Tasks"
}
const addTaskObj = {
    icon:"add_box",
    label:"Add Task"
}

const actionArr = [viewTasksObj];
const actionArrChild = [viewTasksObj,addTaskObj]

const ProcessTree = (props) => {
    return <div className="dialog-tree-structure">
        {
            !isEmptyVariable(props.processObj) &&
            <div className="tree-node" style={{marginLeft:(props.level*40)+"px"}}>
                <span 
                    onClick={()=>props.onClickNode(props.processObj)}
                    class="material-icons tree-span-bg" style={{opacity:isEmptyArray(props.processObj.children)?"0":"1"}}>
                    {props.processObj.isShowingChildren?"keyboard_arrow_down":"keyboard_arrow_right"}
                </span>
                <div className="tree-dropdown-layout">
                    <TreeNodeDropdown
                        placeholder={props.processObj.processName}
                        dropdownArr={
                            props.processObj.hasChildren
                            ?
                            actionArr
                            :
                            (props.masterMenus[Constants.SCREEN_TASKS] ==="ALL" )
                            ?
                            actionArrChild
                            :
                            actionArr
                        }
                        labelParam={"label"}
                        onDropDownItemClick={props.onDropDownItemClick}
                        dropdownId={props.processObj}
                        fontbold={props.fontbold}
                        themeSettings={props.themeSettings}
                    />
                </div>
            </div>
        }

        {
            //Exit Condition
            !isEmptyVariable(props.processObj) &&
            props.processObj.isShowingChildren &&
            props.getChildNodes(props.processObj).map((child)=>{
                let levelTemp = props.level + 1;
                return <ProcessTree 
                    processObj = {child} 
                    getChildNodes = {props.getChildNodes}
                    level={levelTemp}
                    onClickNode={props.onClickNode}
                    onDropDownItemClick={props.onDropDownItemClick}
                    rootNodeStatus = {props.rootNodeStatus}
                    rootNodeAllowEdit = {props.rootNodeAllowEdit}
                    themeSettings={props.themeSettings}
                    masterMenus={props.masterMenus}
                />
            })
        }
    </div>
}

class ProcessTreeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader:false,
            processMap:{},
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showProcessTreeDialog){

            this.setState({
                showLoader:false,
                processMap:{},
            },()=>{
                this.getProcessTree();
            })
            
        }
    }

    expandCollapseAll = (processId,isChecked) => {
        this.state.processMap[processId].isShowingChildren = isChecked;

        this.state.processMap[processId].children.map(child => {
            this.expandCollapseAll(child.processId,isChecked);
        })

        this.setState({
            processMap:this.state.processMap
        })
    }

    expandAll = () => {
        this.expandCollapseAll(this.props.rootProcessId,true);
    }

    collapseAll = () => {
        this.expandCollapseAll(this.props.rootProcessId,false);
    }

    //No need of return value since the array itself is passed and updated.
    addParentIdandHasChildrenFlag = (tempArr, processId, parentProcessId) => {
        tempArr[processId].parentProcessId = parentProcessId;

        if(!isEmptyArray(tempArr[processId].children)){
            tempArr[processId].hasChildren = true;

            tempArr[processId].children.map(childNode => {
                this.addParentIdandHasChildrenFlag(tempArr,childNode.processId,processId);
            });
        }else{
            tempArr[processId].hasChildren = false;
        }
    }

    onDropDownItemClick = (item,processObj) => {
        if(item.label === "View Tasks"){
            this.props.viewTaskOnClick(processObj);
        }else if(item.label === "Add Task"){
            this.props.handleSelectAssignmentTypeDialogShow(processObj)
            // this.props.addTaskOnClick(processObj);
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    /***************************Process Dialog functions************************/

    getChildNodes = (node) => {
        if(isEmptyArray(node.children)){
            return [];
        }else{
            return node.children.map(item => this.state.processMap[item.processId]);
        }
    }

    onClickNode = (node) => {
        if(this.state.processMap[node.processId].isShowingChildren){
            this.state.processMap[node.processId].isShowingChildren = false;
        }else{
            this.state.processMap[node.processId].isShowingChildren = true;
        }
        
        this.setState({
            refreshFlag:!this.state.refreshFlag
        })
    }

    /************************API CALLS **************************/

    getProcessTree = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetProcessesSubtree,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                rootProcessId:this.props.rootProcessId,
                status:"ALL",
                activeFlag:"Y"//get values from active tables
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let temp = data.result.processMap;
                //Add Parent Id and hasChildren flag to the map
                this.addParentIdandHasChildrenFlag(temp,this.props.rootProcessId,this.props.rootProcessId);

                //Expand root level node's children by default
                if(temp[this.props.rootProcessId] && 
                    !isEmptyArray(temp[this.props.rootProcessId].children)){
                    temp[this.props.rootProcessId].isShowingChildren = true;
                }

                this.setState({
                    processMap:temp,
                    showLoader:false,
                });
            }else{
                this.setState({
                    processMap:{},
                    showLoader:false,
                });
            }
        });
    }

    render() {
        return(
            <div>
                <Modal className="show-process-tree-dialog custom-dialog" 
                show={this.props.showProcessTreeDialog} onHide={this.props.handleProcessTreeDialogClose}>
                    <Modal.Header>
                        <h5>Process Tree</h5>
                        <button 
                            onClick={this.props.handleProcessTreeDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div className="modal-loading-text">
                                <p
                                style={{
                                    background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                    color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                                }}
                                >Loading....</p>
                            </div>
                        }
                        <ExpandCollapseWrapper  themeSettings={this.props.themeSettings}>
                            <div className="expand-collapse-layout"
                                onClick={this.expandAll}
                                style={{marginRight:10}}
                                type="button">
                                <span class="material-icons">unfold_more</span>
                                <p>Expand All</p>
                            </div>
                            <div
                                className="expand-collapse-layout"
                                onClick={this.collapseAll}
                                style={{marginLeft:10}}
                                type="button">
                                <span class="material-icons">unfold_less</span>
                                <p>Collapse All</p>
                            </div>

                        </ExpandCollapseWrapper>
                        <div className="body-wrapper">
                            {
                                Object.keys(this.state.processMap).length > 0 &&
                                <ProcessTree 
                                    processObj = {this.state.processMap[this.props.rootProcessId]} 
                                    getChildNodes = {this.getChildNodes}
                                    level={0}
                                    onClickNode={this.onClickNode}  //EXPAND/COLLAPSE
                                    fontbold={true}
                                    onDropDownItemClick={this.onDropDownItemClick}
                                    rootNodeStatus={this.props.rootNode.status}
                                    rootNodeAllowEdit={this.props.rootNode.allowEdit}
                                    themeSettings={this.props.themeSettings}
                                    masterMenus={this.props.masterMenus}
                                />
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ProcessTreeDialog;