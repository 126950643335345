import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {PageSaveButton,FilterCheckBox,TaskTagButtonsLayout, AddNewButtonLayout,AlertInfo} from '../../../common/Global/globalStyles.style';
import Sidebar from '../../../common/Sidebar';
import Topbar from '../../../common/Topbar';
import MasterMenuTabs from "../Common/topmenu";
import {getLocalStorageVariables,getThemeSettingsVariables,
    GetTemplateTabMenu,isEmptyVariable, isJsonString, isEmptyArray, getStatusSpan} from '../../../common/Global/commonFunctions';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import * as TemplateConstants from "../../../common/Global/templateConstants";
import AddFieldDialog from '../Common/addFieldDialog';
import { DatePicker } from 'antd';
import AddTemplateNamedialog from '../Common/addTemplateNamedialog'
import SelectAssignments from '../Common/selectAssignments'
import AlertDialog from '../../../common/AlertDialog';
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import DifferenceDialog from '../Common/differenceDialog'
import SelectValueDialogs from '../../Tasks/Common/selectDropdownValues';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import { Spinner } from 'react-bootstrap';

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();
const addfieldTypePlaceholder = "Add Field";
const templateNamePlaceholder = "Template Name Here";
const templateAlertDialogMessage = "Any change in template may have impact on download report.";


const editDropdownObj = {icon: "edit",label: "Edit"};
const delDropdownObj = {icon: "delete",label: "Delete"};
const discardChangesObj = {icon: "backspace",label: "Discard Changes",};
const enableDropdownObj = {icon: "toggle_on",label: "Enable Field"};
const disableDropdownObj = {icon: "toggle_off",label: "Disable Field"};
const differenceDropdownObj = {icon: "compare_arrows",label: "Show Differences"};

let isDetails = false;
let isTemplateEdit = false;
let isTemplateAlertDialog =false;
class AddEditTaskTemplates extends Component{
    
    constructor(props) {
        super(props);

        let isEdit = false;
        let templateId = "";
        let assignmentTypeId = "";
        if(this.props.location.pathname.includes(Constants.URL_EDIT_EXEC_TASK_TEMPLATE)){
            isEdit = true;
            isDetails = false;
            templateId = this.props.match.params.id;
            isTemplateEdit=true;
            isTemplateAlertDialog=true;
        }else if(this.props.location.pathname.includes(Constants.URL_EXEC_TASK_TEMPLATE_DETAILS)){
            isEdit = true;
            isDetails = true;
            templateId = this.props.match.params.id;
        }else{
            assignmentTypeId = this.props.match.params.id;
            isDetails = false;
        }

        this.state = {
            menus:{},
            componentDidMountFlag:false,
            templateId:templateId,
            assignmentTypeId:assignmentTypeId,
            isEdit:isEdit,

            datalist:[],
            fixedTemplateFields:{},
            fixedTemplateFields_prev:"{}",
            taskTemplateFields:[],
            businessRisk:[],
            templateFieldsMap:{},
            fieldUsedInTasks:false,
            editTemplateItem:{},
            editTemplateItem_prev:{},
            // formErrors:{},
            templateFields_prev:"[]",
            fieldUseCountMap:{},
            updateAssignmentIds:[],
            selectedTaskFieldsOld:[],
            assignmentCount:0,

            fieldNameArray:[],
            showCreateDialog:false,
            deleteReactivateId:"",
            addfieldTypePlaceholder:addfieldTypePlaceholder,
            templateName:templateNamePlaceholder,
            
            showAddTemplateNameDialog:false,

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            deleteReactivateMasterType:"",
            alertDialogMessage:"",
            
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showSecondAlertDialog:false,
            secondAlertDialogMessage:"",
            secondAlertProceedBtnLabel:"",

            showSelectDropdownDialog:false,
            showDifferenceDialog:false,

            showSelectAssignmentDialog:false,

            showTemplateAlertDialog:isTemplateAlertDialog,
            showTemplateInfoDialog:isTemplateEdit,
            companyDetials:{}
        }
    }

    modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline','strike',{ 'color': [] }, { 'background': [] }],
            [{'list': 'ordered'}, {'list': 'bullet'},{ 'align': [] },'link'],
            ['clean']
        ],
    }
    componentDidMount(){
        this.initData();
    }


    onDragEnd = (result) => {
        const {destination, source} = result;

        //Outside the droppable
        if(!destination){
            return
        }

        //if draggable is dropped in same droppable and in same position
        if(destination.droppableId === source.droppableId &&
        destination.index === source.index){
            return
        }

        let newPosition = destination.index;
        let oldPosition = source.index;
        let datalist = this.state.datalist;

        // console.log("newPosition:"+newPosition+" oldPosition:"+oldPosition);

        let fieldObj = datalist[oldPosition];
        datalist.splice(oldPosition,1)//remove from old position
        datalist.splice(newPosition,0,fieldObj);//move the field Obj to new pos

        let datalistNew = datalist.map((item,idx)=>{
            // console.log("Index:"+(idx+1)+" OrderPos:"+item.orderPos);
            let index = idx+1;
            if(index+"" !== item.orderPos+""){
                item.fieldStatus_new = item.fieldStatus_new==="Created"?"Created":"Updated";
            }
            item.orderPos = idx+1;
            return item;
        })

        this.setState({
            datalist:datalistNew
        })

    }

    handleAddNewFieldClick = () => {
        let fieldNameArray = this.state.datalist.map((item)=>item.fieldName.trim());
        this.setState({
            showCreateDialog:true,
            addToPosition:"Right",
            fieldNameArray:fieldNameArray
        });
    }

    handleEditTemplateNameDialog = () => {
        this.setState({
            showAddTemplateNameDialog:true,
            isEditTemplateName:true
        });
    }

    handleEditTemplateNameDialogClose = (reloadFlag,templateName) => {
        if(reloadFlag){
            this.setState({
                showAddTemplateNameDialog:false,
                isEditTemplateName:false,
                templateName:templateName
            });
        }else{
            this.setState({
                showAddTemplateNameDialog:false,
                isEditTemplateName:false,
            });

        }
    }

    handleFieldItemEditIconClick = (templateItem,index,addToPosition) => {
        
            let templateItem_prev = this.state.templateFieldsMap[templateItem.fieldId];
            let fieldUsedInTasks = this.isUsedInTasks(templateItem.fieldId);
            this.setState({
                showCreateDialog:true,
                editTemplateItem:templateItem,
                editTemplateItem_prev:templateItem_prev,
                fieldUsedInTasks:fieldUsedInTasks,
                isEditField:true,
                deleteReactivateId:index,
                addToPosition:"Right"           
            });
        
    }
    //1
    handleFieldItemDeleteIconClick = (fieldItem,index,addToPosition) => {
        // e.stopPropagation();
        if(this.isUsedInAssignment()){
            //should not allow delete
            this.setState({
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"Field cannot be deleted as the template is used in ongoing assignment.",
            });
        }else{
            //show Alert
            this.setState({
                addToPosition:addToPosition,
    
                operationType:"Delete",
                alertDialogHeading:"Delete Field",
                proceedBtnLabel:"Delete",
                deleteReactivateId:index,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete field - "+fieldItem.fieldName+"?"
            })
        }
    }
    //2
    handleRecordOperation = () => {
        if(this.state.operationType === "Delete"){
            this.handleDeleteFieldItemAlert();
        }else if(this.state.operationType === "Reactive"){
            this.handleReactivateFieldItem();
        }else if(this.state.operationType === "Discard"){
            this.discardAllTemplateChanges();
        }else if(this.state.operationType === "Discard Item Change"){
            this.discardItemChange();
        }else if(this.state.operationType === "Enable Field"){
            this.enableDisableRiskField("Y");
        }else if(this.state.operationType === "Disable Field"){
            this.enableDisableRiskField("N");
        }else if(this.state.operationType === "DisableFixed"){
            this.enableDisableFixedField("N");
        }else if(this.state.operationType === "EnableFixed"){
            this.enableDisableFixedField("Y");
        }
    }
    //3
    handleDeleteFieldItemAlert = () =>{
        let isUsedInTasks = false;
            let item = this.state.datalist[this.state.deleteReactivateId]
            isUsedInTasks = this.isUsedInTasks(item.fieldId);

        if(isUsedInTasks){
            //need to show one more dialog just to confirm the delete
            this.setState({
                showSecondAlertDialog:true,
                secondAlertDialogMessage:"There is a data stored for the field. Data will also get deleted with the field. Are you sure?",
                secondAlertProceedBtnLabel:"Ok",
            })
        }else{
            this.handleDeleteFieldItem();
        }
    }
    //4
    handleDeleteFieldItem = () => {
        let datalistTemp = [];
            datalistTemp = this.state.datalist;
            let item = this.state.datalist[this.state.deleteReactivateId];

            //check the previous status, if it is just created then hard delete it
            if(item.fieldStatus === "Created"){
                datalistTemp.splice(this.state.deleteReactivateId,1);
            }else{
                item.fieldStatus_new = "Deleted";
                datalistTemp.splice(this.state.deleteReactivateId,1,item);
            }

            this.setState({
                datalist:datalistTemp,
                deleteReactivateId:"",
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
                addToPosition:"",

                showSecondAlertDialog:false,
                secondAlertDialogMessage:"",
                secondAlertProceedBtnLabel:"",
            })
        
        
    }

    enableDisableRiskField = (flag) => {
        let datalistTemp = this.state.datalist;
        let item = this.state.datalist[this.state.deleteReactivateId];
        item.isRiskMasterEnabled = flag;
        item.fieldStatus_new = item.fieldStatus_new==="Created"?"Created":"Updated";
        datalistTemp.splice(this.state.deleteReactivateId,1,item);

        this.setState({
            datalist:datalistTemp,
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
            addToPosition:"",
        })

    }

    enableDisableFixedFieldsAlert = (type) => {

        if(isDetails){
            return;
        }
        
        let fixedTemplateFields = this.state.fixedTemplateFields;

        this.setState({
            operationType:fixedTemplateFields[type] === "Y"?"DisableFixed":"EnableFixed",
            alertDialogHeading:fixedTemplateFields[type] === "Y"?"Disable Field":"Enable Field",
            proceedBtnLabel:fixedTemplateFields[type] === "Y"?"Disable":"Enable",
            deleteReactivateId:type,
            showAlertDialog:true,
            alertDialogMessage:fixedTemplateFields[type] === "Y"?
                                "Are you sure you want to disable this field?":
                                "Are you sure you want to enable this field?"
        })
    }

    enableDisableFixedField = (flag) => {
        let fixedTemplateFields = this.state.fixedTemplateFields;
        fixedTemplateFields[this.state.deleteReactivateId] = flag;
        fixedTemplateFields[this.state.deleteReactivateId+"Status"] = fixedTemplateFields[this.state.deleteReactivateId+"Status"]==="Created"?"Created":"Updated";

        this.setState({
            fixedTemplateFields:fixedTemplateFields,
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
            addToPosition:"",
        })
    }

    handleFieldItemReactivateIconClick = (fieldName,index,addToPosition) => {
        // e.stopPropagation();
        this.setState({
            addToPosition:addToPosition,

            operationType:"Reactive",
            alertDialogHeading:"Reactivate Field",
            proceedBtnLabel:"Reactivate",
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to reactivate field - "+fieldName+"?"
        })
    }

    handleFieldItemDiscardChangesIconClick = (templateItem,index,addToPosition) => {
        // e.stopPropagation();

        let templateItem_prev = {};
        try{
                templateItem_prev = JSON.parse(JSON.stringify(this.state.templateFieldsMap[templateItem.fieldId]));
        }catch(e){
            templateItem_prev = {};
        }

        this.setState({
            addToPosition:addToPosition,

            operationType:"Discard Item Change",
            alertDialogHeading:"Discard Change",
            proceedBtnLabel:"Discard",
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to discard changes to field - "+templateItem.fieldName+"?",

            editTemplateItem:templateItem,
            editTemplateItem_prev:templateItem_prev,
        })
    }

    discardItemChange = () => {
        let datalistTemp = [];
            datalistTemp = this.state.datalist;
            datalistTemp.splice(this.state.deleteReactivateId,1,this.state.editTemplateItem_prev);
            this.setState({
                datalist:datalistTemp,
                deleteReactivateId:"",
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
                addToPosition:"",
                editTemplateItem:{},
                editTemplateItem_prev:{}
            });
    }

    handleReactivateFieldItem = () =>{
        let datalistTemp = [];
            datalistTemp = this.state.datalist;
            let item = this.state.datalist[this.state.deleteReactivateId];
            item.fieldStatus_new = item.fieldStatus;
            datalistTemp.splice(this.state.deleteReactivateId,1,item);
            this.setState({
                datalist:datalistTemp,
                deleteReactivateId:"",
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
                addToPosition:"",
            })
    }


    handleCreateDialogClose = (fieldObj) => {

        let datalistTemp = [];
            datalistTemp= this.state.datalist;
        if(fieldObj){
            let taskFieldCount =this.state.taskFieldCount;
            if(this.state.isEditField){
                fieldObj.orderPos = datalistTemp[this.state.deleteReactivateId].orderPos;
                datalistTemp.splice(this.state.deleteReactivateId,1, fieldObj);
            }else{
                
                fieldObj.fieldId = ++taskFieldCount +"";
                fieldObj.orderPos = datalistTemp.length+1;
                datalistTemp.push(fieldObj);
            }

                // showTasks=datalistTemp.some((item) =>item.fieldName===fieldObj.fieldName);

                this.setState({
                    showCreateDialog:false,
                    isEditField:false,
                    datalist:datalistTemp,
                    editTemplateItem:{},
                    editTemplateItem_prev:{},
                    fieldUsedInTasks:false,
                    deleteReactivateId:"",
                    addToPosition:"",
                    taskFieldCount:taskFieldCount,
                    fieldNameArray:[]
                });
            
        }else{
            this.setState({
                showCreateDialog:false,
                isEditField:false,
                editTemplateItem:{},
                editTemplateItem_prev:{},
                deleteReactivateId:"",
                addToPosition:"",
            });
        }
    }

    // handleDeleteItemClick = () =>{
    //     let datalist = this.state.datalist;
    //     datalist.splice(this.state.deleteReactivateId,1);
    //     this.setState({
    //         datalist:datalist,
    //         deleteReactivateId:"",
    //         operationType:"",
    //         alertDialogHeading:"",
    //         proceedBtnLabel:"",
    //         alertDialogMessage:"",
    //         showAlertDialog:false,
    //     })


    // }

    // fieldTypeDropdownClick = (item) => {
    //     this.setState({
    //         addfieldTypePlaceholder:item.value,
    //         showCreateDialog:true,
    //         isFieldTypeAddedinDD:true
            
    //     })
    // }

    // handleAlertDialogClose = () =>{
    //     this.setState({
    //         showAlertDialog:false,
    //         alertDialogMessage:""
    //     });
    // }
    // handleItemDeleteClickAlert = (fieldName,index,e) => {
    //     e.stopPropagation();
    //     this.setState({
            
    //         operationType:"Delete",
    //         alertDialogHeading:"Delete Field",
    //         proceedBtnLabel:"Delete",
    //         deleteReactivateId:index,
    //         showAlertDialog:true,
    //         alertDialogMessage:"Are you sure you want to delete field- "+fieldName+"?"
    //     })
        
    // }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:"",
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
        });
    }

    handleSecondAlertDialogClose = () =>{
        this.setState({
            showSecondAlertDialog:false,
            secondAlertDialogMessage:"",
            secondAlertProceedBtnLabel:"",
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    onCancelClick = () => {
        this.props.history.push(Constants.URL_EXECUTE_TASK_TEMPLATES);
    }

    isUsedInAssignment = () => {
        //since we are creating versions if it is used in the assignment
        //there is no need to check this. lets hard code it to false
        // return this.state.assignmentCount>0?true:false;
        return false;
    }

    isUsedInTasks = (fieldId) => {
        let fieldUsageItem = this.state.fieldUseCountMap[fieldId];
        
        if(isEmptyVariable(fieldUsageItem)){
            return false;
        }

        let count = 0;
        try{
            count = parseInt(fieldUsageItem.cnt);
        }catch(e){
            count = 0;
        }

        if(count > 0){
            return true;
        }
        
        return false;
    }

    onTableDropDownItemClick = (item,dropdownItem,index,addToPosition) =>{
        if(item.label === "Edit"){
            this.handleFieldItemEditIconClick(dropdownItem,index,addToPosition);
        }else if(item.label === "Delete"){
            this.handleFieldItemDeleteIconClick(dropdownItem,index,addToPosition);
        }else if(item.label === "Discard Changes" && dropdownItem.fieldStatus_new === "Deleted"){
            this.handleFieldItemReactivateIconClick(dropdownItem.fieldName,index,addToPosition);
        }else if(item.label === "Discard Changes" && dropdownItem.fieldStatus_new === "Updated"){
            this.handleFieldItemDiscardChangesIconClick(dropdownItem,index,addToPosition);
        }else if(item.label === "Show Differences"){
            this.setState({
                showDifferenceDialog:true,
            })
        }else if(item.label === "Enable Field"){
            this.enableDisableFieldAlert(dropdownItem,index);
        }else if(item.label === "Disable Field"){
            this.enableDisableFieldAlert(dropdownItem,index);
        }
    }

    enableDisableFieldAlert = (fieldItem,index) => {
        let message = "Are you sure you want to disable field - "+fieldItem.fieldName+"?";
        let label = "Disable";
        let operationType = "Disable Field";
        if(fieldItem.isRiskMasterEnabled === "N"){
            message = "Are you sure you want to enable field - "+fieldItem.fieldName+"?";
            label = "Enable";
            operationType = "Enable Field";
        }
        this.setState({
            operationType:operationType,
            alertDialogHeading:operationType,
            proceedBtnLabel:label,
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:message
        })
    }

    getFieldActionArray = (canBeDeleted, fieldStatus_new, isFromRiskMaster, isRiskMasterEnabled) => {
        let actionObj = {};
        let actionArr = [];
        let dropdownWidth = "140px";
        
        actionArr.push(editDropdownObj);
        if(canBeDeleted === "Y" && fieldStatus_new !== "Deleted"){
            actionArr.push(delDropdownObj);
        }else if(canBeDeleted === "Y" && fieldStatus_new === "Deleted"){
            dropdownWidth = "170px";
            actionArr.push(discardChangesObj);
        }

        if(isFromRiskMaster){
            if(isRiskMasterEnabled === "N"){
                actionArr.push(enableDropdownObj);
            }else{
                actionArr.push(disableDropdownObj);
            }
        }

        if(fieldStatus_new === "Updated"){
            dropdownWidth = "170px";
            actionArr.push(discardChangesObj);
            // actionArr.push(differenceDropdownObj);
        }
        actionObj = {
            actionArr: actionArr,
            dropdownWidth: dropdownWidth,
        };

        return actionObj;
    }

    handleDifferenceDialogClose = () =>{
        this.setState({
            showDifferenceDialog:false
        })
    }

    handleCheck = (e) => {
        let isChecked = e.target.checked;
        let name = e.target.name;

        let taskFields = this.state.taskTemplateFields;
        taskFields[name].isSelectedForExecuteTask = isChecked?"Y":"N";

        this.setState({
            taskTemplateFields:taskFields
        })
    }

    handleSelectDropdownDialogShow = (item) => {
        let editTemplateItem = {...item};
        if(item.isFromRiskMaster){
            editTemplateItem.dropdownValues = this.state.businessRisk;
        }
        this.setState({
            showSelectDropdownDialog:true,
            editTemplateItem:editTemplateItem
        })
    }

    handleSelectDropdownDialogClose = () => {
        this.setState({
            showSelectDropdownDialog:false,
            editTemplateItem:{}
        })
    }

    handleSelectAssignmentDialogClose = () => {
        this.setState({
            showSelectAssignmentDialog:false,
            showLoader:false
        })
    }

    returnSelectedAssignmentIds = (selectedIds) => {
        let selectedTaskFields = [];
        this.state.taskTemplateFields.forEach(item=>{
            if(item.isSelectedForExecuteTask === "Y"){
                selectedTaskFields.push({
                    fieldId:item.fieldId,
                    fieldName:item.fieldName,
                    fieldType:item.fieldType
                });
            }
        })
        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            templateName:this.state.templateName,
            templateId:this.state.templateId,
            templateFields:JSON.stringify(this.state.datalist),
            templateFieldsMaxId:this.state.taskFieldCount,
            templateFieldsLeft:JSON.stringify([]),
            taskTemplateFields:JSON.stringify(selectedTaskFields),
            assignmentIds:JSON.stringify(selectedIds),
            fixedTemplateFields:JSON.stringify(this.state.fixedTemplateFields)
        }
        this.handleSaveTemplate(Constants.UpdateTemplate,postParams);
        
    }
    /************************API CALLS****************************/
    
    initData = () => {
        if(this.state.isEdit){
            
            Promise.all([
                fetch(Constants.GetTemplates, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                        templateType:TemplateConstants.TEMPLATE_TYPE_EX_TASK,
                        templateId:this.state.templateId,
                    }),
                }),
                fetch(Constants.GetUserMenu, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                    }),
                }),
                fetch(Constants.GetCreateExecuteTemplateMasterData,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        templateId:this.state.templateId
                    })
                }),
                fetch(Constants.GetOtherMasters,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        masterType:"Risk",
                        pageNo:1,
                        resultsize:100,
                        status:"Active"
                    })
                })
            ])
            .then(([execTemplateRes, menuRes, masterRes,otherMasterRes]) => {
                return Promise.all([execTemplateRes.json(), menuRes.json(), masterRes.json(),otherMasterRes.json()]);
            })
            .then(([execTemplateRes, menuRes, masterRes,otherMasterRes]) => {

                let menusTemp = {};
                let taskTemplateFields = [];
                let datalist = [];
                let templateObj = {};
                let templateName = "";
                let templateId = "";
                let taskFieldCount = 0;
                let templateFieldsMap = {};
                let templateFields_prev = "[]";
                let fieldUseCountMap = {};
                let assignmentCount = 0;
                let selectedTaskFields = [];
                let updateAssignmentIds = [];
                let businessRisks = [];
                let businessRiskArr = [];
                let fixedTemplateFields = [];
                let fixedTemplateFields_prev = "{}";
                let executeTableFields = [];
                let companyDetialsTemp={};

                if (execTemplateRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || execTemplateRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (execTemplateRes.responseCode === Constants.CODE_SUCCESS) {
                    templateObj = execTemplateRes.data.result[0] || {};
                    if(!isEmptyVariable(templateObj.templateFieldsNew) && isJsonString(templateObj.templateFieldsNew)){
                        datalist = JSON.parse(templateObj.templateFieldsNew);
                    }
                    if((!isEmptyVariable(templateObj.fixedTemplateFieldsNew) || !isEmptyArray(Object.keys(templateObj.fixedTemplateFieldsNew))) && isJsonString(templateObj.fixedTemplateFieldsNew)){
                        fixedTemplateFields = JSON.parse(templateObj.fixedTemplateFieldsNew);
                    }else{
                        fixedTemplateFields = TemplateConstants.EXEC_TASKS_FIXED_FIELDS;
                    }

                    if(!isEmptyVariable(templateObj.templateFields) && isJsonString(templateObj.templateFields)){
                        templateFields_prev = templateObj.templateFields;
                        let templateFields = JSON.parse(templateObj.templateFields);
                        templateFields.forEach(item=>{
                            templateFieldsMap[item.fieldId] = item;
                        })
                    }

                    if(!isEmptyVariable(templateObj.fixedTemplateFieldsNew) && isJsonString(templateObj.fixedTemplateFieldsNew)){
                        fixedTemplateFields_prev = templateObj.fixedTemplateFieldsNew;
                    }

                    templateName = templateObj.templateName;
                    templateId = templateObj.templateId;
                    taskFieldCount = templateObj.templateFieldsMaxId;

                    try{
                        assignmentCount = parseInt(templateObj.assignmentCount);
                    }catch(e){
                        assignmentCount = 0;
                    }

                    let selectedTaskTemplateFieldsStr = templateObj.taskTemplateFieldsNew;
                    if(isJsonString(selectedTaskTemplateFieldsStr)){
                        selectedTaskFields = JSON.parse(selectedTaskTemplateFieldsStr);
                    }

                    let updateAssignmentIdsStr = templateObj.updateAssignmentIds;
                    if(!isEmptyVariable(updateAssignmentIdsStr) && isJsonString(updateAssignmentIdsStr)){
                        updateAssignmentIds = JSON.parse(updateAssignmentIdsStr);
                    }

                    let fieldUseCount = execTemplateRes.data.fieldUseCount;
                    //lets create a field usage map
                    !isEmptyArray(fieldUseCount) &&
                    fieldUseCount.forEach(item=>{
                        fieldUseCountMap[item.fieldId+""] = item;
                    })

                } else {
                }

                if (otherMasterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || otherMasterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (otherMasterRes.responseCode === Constants.CODE_SUCCESS) {
                    
                    let businessRiskObj = {
                        businessRiskId:-1,
                        shortName:"",
                        name:"Other",
                        description:""
                    }
                    businessRisks = otherMasterRes.data.result;
                    businessRisks.push(businessRiskObj);
                    
                    //add property "displayname: name (description)" to businessRisk array
                    businessRisks.forEach(item=>{
                        // if(item.name !=="Other"){
                        //     item.value = item.name+" ("+item.description+")"
                        // }
                        // else{
                        //     item.value = item.name
                        // }
                        item.value = item.name;
                        businessRiskArr.push(item);
                    });

                } else {
                }
                
                if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                    menusTemp = menuRes.data.menus;
                    companyDetialsTemp = menuRes.data.company
                } else {
                }
                let menusTemp2 = GetTemplateTabMenu(menusTemp);

                if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
                    let templateFieldsStr = masterRes.data.taskTemplate.templateFields;
                    let templateFieldsLeftStr = masterRes.data.taskTemplate.templateFieldsLeft;

                    let taskTemplateFieldsTemp = [];
                    try{
                        taskTemplateFieldsTemp = JSON.parse(templateFieldsStr);
                    }catch(e){
                        taskTemplateFieldsTemp = [];
                    }
                    let taskTemplateFieldsLeftTemp = [];
                    try{
                        taskTemplateFieldsLeftTemp = JSON.parse(templateFieldsLeftStr);
                    }catch(e){
                        taskTemplateFieldsLeftTemp = [];
                    }

                    //lets mark the already selected fields
                    let taskTemplateFieldsTemp2 = [];
                    taskTemplateFieldsTemp.forEach(item=>{
                        let flag = selectedTaskFields?.some(selectedItem => selectedItem.fieldId === item.fieldId)
                        if(flag || item.fieldId == 1){
                            item.isSelectedForExecuteTask = "Y";
                        }
                        taskTemplateFieldsTemp2.push(item);

                        if(item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                            item.isUsedForExec === "Y"){
                            executeTableFields.push(item)
                        }
                    })

                    let taskTemplateFieldsLeftTemp2 = [];
                    taskTemplateFieldsLeftTemp.forEach(item=>{
                        if(selectedTaskFields.indexOf(item.fieldId) !== -1){
                            item.isSelectedForExecuteTask = "Y";
                        }
                        taskTemplateFieldsLeftTemp2.push(item);
                    })

                    taskTemplateFields = [...taskTemplateFieldsTemp2,...taskTemplateFieldsLeftTemp2];

                } else {
                }
                
                this.setState({
                    componentDidMountFlag: true,
                    showLoader: false,
                    datalist: datalist,
                    templateFieldsMap: templateFieldsMap,
                    templateFields_prev: templateFields_prev,
                    templateName:templateName,
                    templateNameOld:templateName,
                    templateId:templateId,
                    taskFieldCount:taskFieldCount,
                    menus: menusTemp2,
                    assignmentCount:assignmentCount,
                    fieldUseCountMap:fieldUseCountMap,
                    taskTemplateFields:taskTemplateFields,
                    updateAssignmentIds:updateAssignmentIds,
                    selectedTaskFieldsOld:selectedTaskFields,
                    businessRisk:businessRiskArr,
                    executeTableFields:executeTableFields,

                    fixedTemplateFields:fixedTemplateFields,
                    fixedTemplateFields_prev:fixedTemplateFields_prev,
                    companyDetials:companyDetialsTemp
                });
            });
        
        }else{

            Promise.all([
                fetch(Constants.GetUserMenu, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                    }),
                }),
                fetch(Constants.GetCreateExecuteTemplateMasterData,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        assignmentTypeId:this.state.assignmentTypeId
                    })
                }),
                fetch(Constants.GetOtherMasters,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        masterType:"Risk",
                        pageNo:1,
                        resultsize:100,
                        status:"Active"
                    })
                })
            ])
            .then(([menuRes,masterRes,otherMasterRes]) => {
                return Promise.all([menuRes.json(), masterRes.json(),otherMasterRes.json()]);
            })
            .then(([menuRes,masterRes,otherMasterRes]) => {
                let menusTemp = {};
                let datalist = [];
                let templateName = "";
                let taskFieldCount = 0;
                let taskTemplateFields = [];
                let businessRisks = [];
                let businessRiskArr = [];
                let fixedTemplateFields = [];
                let executeTableFields = [];

                datalist = TemplateConstants.EXEC_TASK_FIELDS;
                taskFieldCount = TemplateConstants.EXEC_TASK_MAX_ID;
                templateName = templateNamePlaceholder;
                fixedTemplateFields = TemplateConstants.EXEC_TASKS_FIXED_FIELDS;

                if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                    menusTemp = menuRes.data.menus;

                } else {
                }

                if (otherMasterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || otherMasterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (otherMasterRes.responseCode === Constants.CODE_SUCCESS) {
                    
                    let businessRiskObj = {
                        businessRiskId:-1,
                        shortName:"",
                        name:"Other",
                        description:""
                    }
                    businessRisks = otherMasterRes.data.result;
                    businessRisks.push(businessRiskObj);
                    
                    //add property "displayname: name (description)" to businessRisk array
                    businessRisks.forEach(item=>{
                        // if(item.name !=="Other"){
                        //     item.value = item.name+" ("+item.description+")"
                        // }
                        // else{
                        //     item.value = item.name
                        // }
                        item.value = item.name;
                        businessRiskArr.push(item);
                    });

                } else {
                }

                let menusTemp2 = GetTemplateTabMenu(menusTemp);

                if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
                    let templateFieldsStr = masterRes.data.taskTemplate.templateFields;
                    let templateFieldsLeftStr = masterRes.data.taskTemplate.templateFieldsLeft;

                    let taskTemplateFieldsTemp = [];
                    try{
                        taskTemplateFieldsTemp = JSON.parse(templateFieldsStr);
                    }catch(e){
                        taskTemplateFieldsTemp = [];
                    }
                    let taskTemplateFieldsLeftTemp = [];
                    try{
                        taskTemplateFieldsLeftTemp = JSON.parse(templateFieldsLeftStr);
                    }catch(e){
                        taskTemplateFieldsLeftTemp = [];
                    }

                    //lets mark the already selected fields
                    let taskTemplateFieldsTemp2 = [];
                    taskTemplateFieldsTemp.forEach(item=>{
                        if(item.fieldId == 1){
                            item.isSelectedForExecuteTask = "Y";
                        }
                        taskTemplateFieldsTemp2.push(item);
                        if(item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                            item.isUsedForExec === "Y"){
                            executeTableFields.push(item)
                        }
                    })

                    taskTemplateFields = [...taskTemplateFieldsTemp2,...taskTemplateFieldsLeftTemp];

                } else {
                }

                this.setState({
                    componentDidMountFlag: true,
                    showLoader: false,
                    datalist: datalist,
                    templateName:templateName,
                    templateNameOld:templateName,
                    taskFieldCount:taskFieldCount,
                    menus: menusTemp2,
                    taskTemplateFields:taskTemplateFields,
                    businessRisk:businessRiskArr,
                    fixedTemplateFields:fixedTemplateFields,
                    executeTableFields:executeTableFields
                });
    
            });
        }
    }

    handleSaveTemplateValidation = () => {
        this.setState({
            showLoader:true,
        });

        //let get all the selected tasktemplatefieldIds
        let selectedTaskFields = [];
        this.state.taskTemplateFields.forEach(item=>{
            if(item.isSelectedForExecuteTask === "Y"){
                selectedTaskFields.push({
                    fieldId:item.fieldId,
                    fieldName:item.fieldName,
                    fieldType:item.fieldType
                });
            }
        })

        let url = "";
        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            templateName:this.state.templateName,
            templateFields:JSON.stringify(this.state.datalist),
            templateFieldsMaxId:this.state.taskFieldCount,
            templateFieldsLeft:JSON.stringify([]),
            taskTemplateFields:JSON.stringify(selectedTaskFields),
            assignmentIds:JSON.stringify(this.state.updateAssignmentIds),
            fixedTemplateFields:JSON.stringify(this.state.fixedTemplateFields)
        };

        if(this.state.isEdit){
            postParams.templateId = this.state.templateId;
            url = Constants.UpdateTemplate;

            //before we call update template we need to check if anything is changed
            // console.log(this.state.selectedTaskFieldsOld)
            // console.log(this.state.selectedTaskFields)
            let callApiNameChange  = this.state.templateName !== this.state.templateNameOld;
            let callApiLeft  = JSON.stringify(selectedTaskFields) !== JSON.stringify(this.state.selectedTaskFieldsOld)
            let callApi = this.state.datalist.some(item => (item.fieldStatus_new === "Created" || 
                            item.fieldStatus_new === "Deleted" || item.fieldStatus_new === "Updated"));
            let fixedTemplateFields = this.state.fixedTemplateFields
            let callApiFixedFields = fixedTemplateFields.assignRiskStatus === "Updated" || 
                                        fixedTemplateFields.valueAdditionStatus === "Updated" || 
                                        fixedTemplateFields.annexuresStatus === "Updated" || 
                                        fixedTemplateFields.backupsStatus === "Updated" ||
                                        fixedTemplateFields.assignRiskStatus === "Created" || 
                                        fixedTemplateFields.valueAdditionStatus === "Created" || 
                                        fixedTemplateFields.annexuresStatus === "Created" || 
                                        fixedTemplateFields.backupsStatus === "Created";
            
            if(callApi || callApiLeft || callApiNameChange || callApiFixedFields){
                this.checkTemplateVersionCreation();
            }else{
                this.props.history.push(Constants.URL_EXECUTE_TASK_TEMPLATES);
            }
        }else{
            url = Constants.CreateTemplate;

            postParams.assignmentTypeId = this.state.assignmentTypeId;
            postParams.templateType = TemplateConstants.TEMPLATE_TYPE_EX_TASK;

            this.handleSaveTemplate(url,postParams);
        }
    }

    checkTemplateVersionCreation = () => {
        let selectedTaskFields = [];
        this.state.taskTemplateFields.forEach(item=>{
            if(item.isSelectedForExecuteTask === "Y"){
                selectedTaskFields.push({
                    fieldId:item.fieldId,
                    fieldName:item.fieldName,
                    fieldType:item.fieldType
                });
            }
        })
        fetch(Constants.CheckTemplateVersionCreation,{
            method:"POST",
            mode: "cors",
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                templateName:this.state.templateName,
                templateId:this.state.templateId,
                templateFields:JSON.stringify(this.state.datalist),
                templateFieldsMaxId:this.state.taskFieldCount,
                templateFieldsLeft:JSON.stringify([]),
                assignmentIds:JSON.stringify(this.state.updateAssignmentIds),
                fixedTemplateFields:JSON.stringify(this.state.fixedTemplateFields),
                taskTemplateFields:JSON.stringify(selectedTaskFields)
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let isAssignmentListNeeded = data.isAssignmentListNeeded;
                if(isAssignmentListNeeded === "Y"){
                    this.checkOngoingAssignments();
                }else{
                    this.props.history.push(Constants.URL_EXECUTE_TASK_TEMPLATES);
                }
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                    showLoader:false,
                });
            }
        });
    }

    checkOngoingAssignments = () => {
        fetch(Constants.getOngoingAssignments,{
            method:"POST",
            mode: "cors",
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                templateId:this.state.templateId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let assignments = data.data.assignments;
                if(isEmptyArray(assignments)){
                    let selectedTaskFields = [];
                    this.state.taskTemplateFields.forEach(item=>{
                        if(item.isSelectedForExecuteTask === "Y"){
                            selectedTaskFields.push({
                                fieldId:item.fieldId,
                                fieldName:item.fieldName,
                                fieldType:item.fieldType
                            });
                        }
                    })
                    let postParams = {
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        templateName:this.state.templateName,
                        templateId:this.state.templateId,
                        templateFields:JSON.stringify(this.state.datalist),
                        templateFieldsMaxId:this.state.taskFieldCount,
                        templateFieldsLeft:JSON.stringify([]),
                        taskTemplateFields:JSON.stringify(selectedTaskFields),
                        assignmentIds:JSON.stringify(this.state.updateAssignmentIds),
                        fixedTemplateFields:JSON.stringify(this.state.fixedTemplateFields)
                    }
                    this.handleSaveTemplate(Constants.UpdateTemplate,postParams);
                }else{
                    //show the list of ongoing assignments

                    this.setState({
                        assignments:assignments,
                        showSelectAssignmentDialog:true
                    })
                }
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                    showLoader:false,
                });
            }
        });
    }

    handleSaveTemplate = (url,postParams) => {
        fetch(url,{
            method:"POST",
            mode: "cors",
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {

            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.props.history.push(Constants.URL_EXECUTE_TASK_TEMPLATES);
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                    showLoader:false,
                });
            }
        });
    }

    /************************API CALLS END HERE****************************/
    handleTemplateAlertDialogClose = () =>{
        this.setState({
            showTemplateAlertDialog:false
        })
    }

    handleTemplateInfoClose = () => {
        this.setState({
            showTemplateInfoDialog:false
        })
    }

    render(){
        let fields = {text:"value",value:"value"};
        let fieldsWithDesc = {text:"valueNdesc",value:"value"};
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="m-t-10">
                                        <div className="row custom-row">
                                        <div className="col-md-4 custom-col m-t-10">
                                            <TaskTagButtonsLayout
                                            themeSettings={themeSettings}
                                            >
                                                {
                                                    this.state.taskTemplateFields.map((item, index)=>{
                                                        if(item.fieldType !== TemplateConstants.FIELD_TYPE_TABLE &&
                                                            item.isRiskMasterEnabled !== "N")
                                                        return <FilterCheckBox themeSettings={themeSettings} style={{ marginLeft: 0,marginBottom:10 }}>
                                                            <input
                                                                type="checkbox"
                                                                name={index}
                                                                id={index +":-:"+item.fieldId}
                                                                onChange={this.handleCheck}
                                                                checked={(item.isSelectedForExecuteTask === "Y")}
                                                                // checked={(item.isSelectedForExecuteTask === "Y" || item.fieldId === "1")}
                                                                disabled={item.fieldId === "1" || isDetails}
                                                            />
                                                            <label for={index +":-:"+item.fieldId}>{item.fieldName}</label>
                                                        </FilterCheckBox>
                                                    })
                                                }
                                                
                                                <ul>
                                                    <li className="flex-center-layout">
                                                        <a href="javascript:void(0)" style={{color:this.state.fixedTemplateFields.assignRisk === "Y"?"#000":"#aaa"}}>
                                                            Assign Risk
                                                            {
                                                                this.state.fixedTemplateFields.assignRiskStatus === "Updated" &&
                                                                <span className="span-tag-fixed">
                                                                    Updated
                                                                </span>
                                                            }

                                                            {
                                                                this.state.fixedTemplateFields.assignRisk !== "Y" &&
                                                                <span className="span-tag-fixed">
                                                                    Disabled
                                                                </span>
                                                            }

                                                        </a>
                                                        <span 
                                                        onClick={this.enableDisableFixedFieldsAlert.bind(this,"assignRisk")}
                                                        className={"material-icons enable-disable-span "+(this.state.fixedTemplateFields.assignRisk === "Y"?"enabled":"disabled")}>
                                                        {
                                                            this.state.fixedTemplateFields.assignRisk === "Y"?enableDropdownObj.icon:disableDropdownObj.icon
                                                        }
                                                        </span>
                                                    </li>
                                                    <li className="flex-center-layout">
                                                        <a href="javascript:void(0)" style={{color:this.state.fixedTemplateFields.valueAddition === "Y"?"#000":"#aaa"}}>
                                                            Value Addition
                                                            {
                                                                this.state.fixedTemplateFields.valueAdditionStatus === "Updated" &&
                                                                <span className="span-tag-fixed">
                                                                    Updated
                                                                </span>
                                                            }

                                                            {
                                                                this.state.fixedTemplateFields.valueAddition !== "Y" &&
                                                                <span className="span-tag-fixed">
                                                                    Disabled
                                                                </span>
                                                            }

                                                        </a>
                                                        <span 
                                                        onClick={this.enableDisableFixedFieldsAlert.bind(this,"valueAddition")}
                                                        className={"material-icons enable-disable-span "+(this.state.fixedTemplateFields.valueAddition === "Y"?"enabled":"disabled")}>
                                                        {
                                                            this.state.fixedTemplateFields.valueAddition === "Y"?enableDropdownObj.icon:disableDropdownObj.icon
                                                        }
                                                        </span>
                                                    </li>
                                                    <li className="flex-center-layout">
                                                        <a href="javascript:void(0)" style={{color:this.state.fixedTemplateFields.annexures === "Y"?"#000":"#aaa"}}>
                                                            Upload Annexure
                                                            {
                                                                this.state.fixedTemplateFields.annexuresStatus === "Updated" &&
                                                                <span className="span-tag-fixed">
                                                                    Updated
                                                                </span>
                                                            }

                                                            {
                                                                this.state.fixedTemplateFields.annexures !== "Y" &&
                                                                <span className="span-tag-fixed">
                                                                    Disabled
                                                                </span>
                                                            }

                                                        </a>
                                                        <span 
                                                        onClick={this.enableDisableFixedFieldsAlert.bind(this,"annexures")}
                                                        className={"material-icons enable-disable-span "+(this.state.fixedTemplateFields.annexures === "Y"?"enabled":"disabled")}>
                                                        {
                                                            this.state.fixedTemplateFields.annexures === "Y"?enableDropdownObj.icon:disableDropdownObj.icon
                                                        }
                                                        </span>
                                                    </li>
                                                    <li className="flex-center-layout">
                                                        <a href="javascript:void(0)" style={{color:this.state.fixedTemplateFields.backups === "Y"?"#000":"#aaa"}}>
                                                            Upload Backup
                                                            {
                                                                this.state.fixedTemplateFields.backupsStatus === "Updated" &&
                                                                <span className="span-tag-fixed">
                                                                    Updated
                                                                </span>
                                                            }

                                                            {
                                                                this.state.fixedTemplateFields.backups !== "Y" &&
                                                                <span className="span-tag-fixed">
                                                                    Disabled
                                                                </span>
                                                            }

                                                        </a>
                                                        <span 
                                                        onClick={this.enableDisableFixedFieldsAlert.bind(this,"backups")}
                                                        className={"material-icons enable-disable-span "+(this.state.fixedTemplateFields.backups === "Y"?"enabled":"disabled")}>
                                                        {
                                                            this.state.fixedTemplateFields.backups === "Y"?enableDropdownObj.icon:disableDropdownObj.icon
                                                        }
                                                        </span>
                                                    </li>
                                                </ul>

                                            </TaskTagButtonsLayout>
                                            
                                        </div>

                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                        <div className="col-md-8 custom-col add-template">
                                            {this.state.showTemplateInfoDialog && this.state.isEdit &&
                                                <AlertInfo themeSettings={themeSettings}>
                                                    <span className="material-icons info-icon" >info</span>
                                                    <p className="flex-center-layout just-content">
                                                        <p>
                                                            <strong>Important:</strong> {templateAlertDialogMessage}
                                                        </p>
                                                        <span className="material-icons close-icon" onClick={this.handleTemplateInfoClose}>close</span>
                                                    </p>
                                                </AlertInfo>
                                            }
                                            {   
                                                <div className="flex-center-layout just-content">

                                                    <p className="m-b-0"><strong>{this.state.templateName}</strong></p>
                                                    
                                                    {
                                                        !isDetails &&
                                                        <span className="material-icons template-action-icons m-b-0"
                                                            onClick={this.handleEditTemplateNameDialog}
                                                            >
                                                            edit
                                                        </span>
                                                    }
                                                </div>
                                            }

                                            {
                                                <Droppable droppableId={"RightFields"}>
                                                {(provided)=>(
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                    {
                                                        this.state.datalist.map((item,index)=>{
                                                            let actionObj = this.getFieldActionArray(item.canBeDeleted, item.fieldStatus_new,
                                                                item.isFromRiskMaster, item.isRiskMasterEnabled);
                                                            let spanObj = getStatusSpan(item.fieldStatus_new,themeSettings)
                                                            return  <Draggable draggableId={item.fieldId+""} index={index} key={item.fieldId+""}>
                                                                {(provided,snapshot)=>(
                                                                    <div class="template-field-layout"
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                    >
                                                                    <div className="editor-card" style={{flexGrow:1}}>
                                                                        <div className="task-header-layout">
                                                                            <h6 className="editor-heading m-b-0 flex-center-layout" style={{marginBottom:10}}>
                                                                                {item.fieldName}{item.isMandatorySelected === "Y"?"*":""}
                                                                                {
                                                                                    !isEmptyVariable(spanObj.spanText) &&
                                                                                    <span
                                                                                    style={spanObj.spanStyle}
                                                                                    >{spanObj.spanText}</span>
                                                                                }
                                                                                {
                                                                                    item.isRiskMasterEnabled === "N" &&
                                                                                    <span
                                                                                    style={{
                                                                                        background:"#ddd",
                                                                                        color:"#000"
                                                                                    }}
                                                                                    ><i>Disabled</i></span>
                                                                                }
                                                                            </h6>
                                                                        </div>
                                                                        {
                                                                            item.fieldType === TemplateConstants.FIELD_TYPE_TEXT &&
                                                                            <div class="row custom-row">
                                                                                <div class="col-md-12 custom-col">

                                                                                    <div class="editor-layout exec-template-input-layout">
                                                                                        
                                                                                        <input
                                                                                            placeholder={item.placeholderValue}
                                                                                            type="text"
                                                                                            name={item.fieldName}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.fieldType === TemplateConstants.FIELD_TYPE_DATE &&
                                                                            <DatePicker
                                                                                // onChange={this.onStartDateChange}
                                                                                format={"DD/MM/YYYY"}
                                                                                // disabled={true}
                                                                                placeholder={item.placeholderValue}
                                                                            />

                                                                        }
                                                                        {
                                                                            //adding TaskTagButtonsLayout for styles
                                                                            (item.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT ||
                                                                            item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT) &&
                                                                            !item.isFromRiskMaster &&
                                                                            <TaskTagButtonsLayout
                                                                            themeSettings={themeSettings}
                                                                            >
                                                                                <div class="template-field-left-layout m-b-10">
                                                                                    <p className="m-b-5 flex-center-layout" style={{marginBottom:10}}
                                                                                        onClick={this.handleSelectDropdownDialogShow.bind(this,item)}
                                                                                    >
                                                                                        {item.fieldName}{item.isMandatorySelected === "Y"?"*":""}
                                                                                    </p>
                                                                                </div>
                                                                            </TaskTagButtonsLayout>
                                                                        }
                                                                        {
                                                                            //adding TaskTagButtonsLayout for styles
                                                                            item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT &&
                                                                            item.isFromRiskMaster &&
                                                                            <TaskTagButtonsLayout
                                                                            themeSettings={themeSettings}
                                                                            >
                                                                                <div class="template-field-left-layout m-b-10">
                                                                                    <p className="m-b-5 flex-center-layout" style={{marginBottom:10}}
                                                                                        onClick={this.handleSelectDropdownDialogShow.bind(this,item)}
                                                                                    >
                                                                                        {item.fieldName}{item.isMandatorySelected === "Y"?"*":""}
                                                                                    </p>
                                                                                </div>
                                                                            </TaskTagButtonsLayout>
                                                                        }
                                                                        {
                                                                            item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                                                                            <div className="flex-center-layout border-c" style={{justifyContent:"center"}}>
                                                                                <p style={{margin:0,padding:6}}>{item.execTaskTableFieldName+" from Task"}</p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            !isDetails &&
                                                                            <TableDropDown
                                                                                actionArr={actionObj.actionArr}
                                                                                onDropDownItemClick={this.onTableDropDownItemClick}
                                                                                dropDownId={item}
                                                                                dropdownWidth={actionObj.dropdownWidth}
                                                                                themeSettings={themeSettings}
                                                                                udf1 = {index}
                                                                                udf2 = {"Right"}
                                                                            />
                                                                        }
                                                                        
                                                                    </div>
                                                                </div>
                                                            
                                                            )}
                                                            </Draggable>
                                                        })
                                                    }
                                                    {provided.placeholder} 
                                                    </div>
                                                )}
                                                </Droppable>
                                            }
                                            {
                                                !isDetails &&
                                                <div className="add-more-btn-layout" onClick={this.handleAddNewFieldClick}>
                                                    <span className="material-icons">add_circle</span>
                                                    <p>Add Field</p>
                                                </div>
                                            }
                                        </div>
                                        </DragDropContext>

                                        </div>
                                        <div className="row custom-row">
                                            <div className="col-md-12 custom-col">
                                                <div className="common-close-save m-b-20">
                                                    <div className="common-close-save-col">
                                                        <button onClick={this.onCancelClick} type="button" className="common-close">Cancel</button>
                                                        {
                                                            !isDetails &&
                                                            <PageSaveButton 
                                                            themeSettings = {themeSettings}
                                                            onClick={this.handleSaveTemplateValidation} type="button">{this.state.showLoader ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : 'Save'}</PageSaveButton>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <AddFieldDialog
                            isEdit={this.state.isEditField}
                            showCreateDialog = {this.state.showCreateDialog}
                            handleCreateDialogClose = {this.handleCreateDialogClose}
                            themeSettings={themeSettings}
                            editTemplateItem={this.state.editTemplateItem}
                            isUsedInTasks={this.state.fieldUsedInTasks}
                            editTemplateItem_prev={this.state.editTemplateItem_prev}
                            addfieldTypePlaceholder={this.state.addfieldTypePlaceholder}
                            addToPosition={this.state.addToPosition}
                            fieldNameArray={this.state.fieldNameArray}
                            templateType = {TemplateConstants.TEMPLATE_TYPE_EX_TASK}
                            executeTableFields={this.state.executeTableFields}
                        />
                        
                        <AddTemplateNamedialog
                            isEdit={this.state.isEditTemplateName}
                            showAddTemplateNameDialog={this.state.showAddTemplateNameDialog}
                            handleAddEditDialogClose={this.handleEditTemplateNameDialogClose}
                            themeSettings={themeSettings}
                            templateName={this.state.isEdit?this.state.templateName:""}
                        />

                        <AlertDialog 
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel }
                            themeSettings={themeSettings}
                        />

                        <AlertDialog 
                            showAlertDialog={this.state.showSecondAlertDialog}
                            handleAlertDialogClose={this.handleSecondAlertDialogClose}
                            type={Constants.ALERT_TYPE_WARNING}
                            alertDialogMessage={this.state.secondAlertDialogMessage}
                            proceedBtnClick={this.handleDeleteFieldItem}
                            proceedBtnLabel={this.state.secondAlertProceedBtnLabel }
                            themeSettings={themeSettings}
                        />

                        <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <AlertDialog 
                            showAlertDialog={ this.state.isEdit && this.state.showTemplateAlertDialog}
                            handleAlertDialogClose={this.handleTemplateAlertDialogClose}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={templateAlertDialogMessage}
                            proceedBtnClick={this.handleTemplateAlertDialogClose}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <SelectValueDialogs
                            showSelectDropdownDialog = {this.state.showSelectDropdownDialog}
                            handleSelectDropdownDialogClose = {this.handleSelectDropdownDialogClose}
                            fieldObj = {this.state.editTemplateItem}
                            viewOnly = {"Y"}
                            themeSettings={themeSettings}
                        />

                        <DifferenceDialog
                            showDifferenceDialog = {this.state.showDifferenceDialog}
                            handleDifferenceDialogClose = {this.handleDifferenceDialogClose}
                            themeSettings = {themeSettings}
                        />

                        <SelectAssignments
                            showSelectAssignmentDialog = {this.state.showSelectAssignmentDialog}
                            assignments = {this.state.assignments}
                            updateAssignmentIds = {this.state.updateAssignmentIds}
                            handleSelectAssignmentDialogClose = {this.handleSelectAssignmentDialogClose}
                            returnSelectedAssignmentIds = {this.returnSelectedAssignmentIds}
                            companyType = {this.state.companyDetials.companyType}
                            themeSettings = {themeSettings}

                        />
                    </section>
                </main>
            </Router>
        )
    }
}

export default AddEditTaskTemplates;