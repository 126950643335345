import React, { Component } from "react";
import * as Constants from "../../../../common/Global/constants";
import { SearchSpan, DialogSaveButton,AddNewButtonLayout } from "../../../../common/Global/globalStyles.style";
import Sidebar from "../../../../common/Sidebar";
import Topbar from "../../../../common/Topbar";
// import MasterMenuTabs from '../Common/topmenu';
import FilterDropDown from "../../../../common/DropdownMenus/FilterDropdown";
import AlertDialog from "../../../../common/AlertDialog";
import Pagination from "../../../../common/Global/pagination";
import MasterMenuTabs from "../topmenu";
import RegularDropdown from "../../../../common/DropdownMenus/RegularDropdown";
import {
	getLocalStorageVariables,
	getThemeSettingsVariables,
	GetOrderedMasterTabMenuReports,
	isEmptyVariable,
	sortTable,
	truncateString,
	isEmptyArray,
	SplitJoinString,
} from "../../../../common/Global/commonFunctions";
import TableDropDown from "../../../../common/DropdownMenus/TableDropdown";
import {getDropdownActionArrayDraftReports} from "../../../../common/Global/reviewFunctions"
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment";
import { getFirstPageContent, getSecondPageContent, getHeaderFooterContent } from "./createViewReportFunctions";
import ViewFirstPageReport from "./viewReport";

// import ReportDetails from "../Common/reportDetails";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();

class ViewReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
            companyDownloadReport:"",
            observationsDownloadReport:"",
			showLoader: false,
			datalist: [],
			firstPageContent: "",
            showFirstPage:false,
			secondPageContent: "",
			thirdPageContent: "",
			fourthPageContent: "",
			fifthPageContent: "",
			headerFooterContent: "",


			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			// statusPlaceholder: reviewedStatus,
			resultSizePlaceholder: Constants.RESULT_SIZE,
			sort: "",
			sortDir: "",

			masterTypeArr: [],
			masterTypeSelected: "All",
			masterFilterPlaceholder: "",

			departmentArr: [],
			selectedDepartmentName: "",
			selectedDepartmentId: "",

			deptOnly: "N",

			showAlertDialog: false,
			operationType: "",
			deleteReactivateId: "",
			deleteReactivateMasterType: "",
			reviewLevel: "",
			alertDialogMessage: "",
			alertDialogHeading: "",
			proceedBtnLabel: "",

			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",

			showUpdateReviewDialog: false,
			showReviewHistoryDialog: false,

			resultSizeArr: Constants.RESULT_SIZE_DD_ARR,

			name: "",
			shortName: "",
			description: "",
			masterType: "",
			// formErrors: {},
			submitClickedAtleastOnce: false,

			// showDetailsDialog:false,
            // selectedItem:"",
		};
        this.newObservationsMap = {};
        this.newObservationsArr = [];
        this.headingList = [];
	}

	componentDidMount() {
		this.getInitData();
	}

    getRootLevelFunctions = (functionMapTemp) => {
        let rootFunctionArr = [];
        Object.values(functionMapTemp).map((item)=>{
            if(item.level === 0){
                rootFunctionArr.push(item);
            }
        })
        return rootFunctionArr;
    }

    parseObservations = (nodeId, prefix, headingListPrev) => { //11,0 //14,1 ,15,2 //13,3, // 140,1.1 , 141,1.2
        let headingNode = this.newObservationsMap[nodeId];

        let isHeadingUsed = false;
        this.headingList = [...headingListPrev];

        if(prefix+"" !== "0"){
            this.headingList.push(prefix+". "+headingNode.heading);
        }

        //Add Index to each observation
        let newObsList = [];
        headingNode.obsList.map((item,idx)=>{
            if(prefix+"" === "0"){
                //root level
                item.observationHeading = (idx+1)+". "+item.observationHeading;
            }else{
                //heading is required only for first observation
                if(idx === 0){
                    isHeadingUsed = true;
                    item.reportCustomHeading = this.headingList.join(":--:");
                }
                item.observationHeading = prefix+"."+(idx+1)+". "+item.observationHeading;
            }
            newObsList.push(item);
        });
        headingNode.obsList = newObsList;
        this.newObservationsArr = [...this.newObservationsArr,...newObsList];

        if(isHeadingUsed){
            this.headingList = [];
        }

        //14.15.13 //14th children - 140,141
        headingNode.children.map((childNode,index)=>{
            let newPrefix = prefix+"" === "0"?(index+1):(prefix+"."+(index+1));
            this.parseObservations(childNode.nodeId,newPrefix,this.headingList)
        })
    }

    handleShowFirstPage = () => {
		let headerFooterContent = getHeaderFooterContent(
			this.state.companyDownloadReport?.clientName,
			this.state.companyDownloadReport?.assignmentTypeName,
			this.state.companyDownloadReport?.companyName
		)
        let firstPageContent = getFirstPageContent(
			// this.state.companyDownloadReport?.clientName, 
			this.state.companyDownloadReport?.unitNameCityList, 
			this.state.companyDownloadReport?.unitAddressList,
			this.state.companyDownloadReport?.companyName,
			this.state.companyDownloadReport?.address1,
			this.state.companyDownloadReport?.address2,
			this.state.companyDownloadReport?.city,
			this.state.companyDownloadReport?.state,
			this.state.companyDownloadReport?.pinCode,
            this.state.companyDownloadReport.companyLogo);


		let secondPageContent = getSecondPageContent(
			this.state.companyDownloadReport?.assignmentTypeName,
			this.state.companyDownloadReport?.unitList,
			this.state.companyDownloadReport?.unitAddressList,
			this.state.companyDownloadReport?.assignmentArea,
			this.state.companyDownloadReport?.processList,
			this.state.companyDownloadReport?.functionList,
			this.state.companyDownloadReport?.checkList,
			this.state.companyDownloadReport?.memberList,
			this.state.companyDownloadReport?.L1UserName,
			this.state.companyDownloadReport?.startDate,
			this.state.companyDownloadReport?.endDate,
			this.state.companyDownloadReport?.reportDate,
			this.state.companyDownloadReport?.periodFromDate,
			this.state.companyDownloadReport?.periodToDate,
			this.state.companyDownloadReport?.reportRiskLevel);

		let thirdPageContent = Object.values(this.state.observationsDownloadReport);
		let fourthPageContent = Object.values(this.state.observationsDownloadReport);
		let fifthPageContent = this.newObservationsArr;
            this.setState({
                firstPageContent:firstPageContent,
				secondPageContent:secondPageContent,
				thirdPageContent:thirdPageContent,
				fourthPageContent:fourthPageContent,
				fifthPageContent:fifthPageContent,
				headerFooterContent:headerFooterContent,
                showFirstPage:true,
            })
    }
	
    handleFirstPageClose = () =>{
		this.setState({
			showFirstPage:false,
        })
    }
	
	
	/*******************API CALLS*******************/
	getInitData = () => {
		this.setState({
			showLoader: true,
			// showDetailsDialog:false,
            // selectedItem:"",
		});

		Promise.all([
			fetch(Constants.DownloadReport, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
			        accessToken: userDetails.accessToken,
			        assignmentId:this.props.match.params.id,
                }),
            }),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
		])
			.then(([masterRes, menuRes]) => {
				return Promise.all([masterRes.json(), menuRes.json()]);
			})
			.then(([masterRes, menuRes]) => {
				let companyDownloadReport = {};
				let deptTemp = [];
				let menusTemp = {};

				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
                    this.newObservationsArr = [];
                    this.newObservationsMap = masterRes.data.observations;
                    let rootHeadings = this.getRootLevelFunctions(this.newObservationsMap);
                    companyDownloadReport= masterRes.data.company;

                    if(!isEmptyArray(rootHeadings)){                                                                                                                                                                        
                        this.parseObservations(rootHeadings[0].nodeId,0,[]);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                    }
					// this.setState({
					// 	showLoader: false,
					// 	companyDownloadReport: data.data.company,
					// 	observationsDownloadReport: this.newObservationsMap,
					// },()=>{
                        // if(reportFormat === Constants.REPORT_FORMAT_STANDARD){
                        //     this.downloadFormatOneReportContent();
                        // }else if(reportFormat === Constants.REPORT_FORMAT_TABULAR){
                        //     this.downloadFormatTwoReportContent();
                        // }
					// });
				} else {
                    this.setState({
						companyDownloadReport: "",
						observationsDownloadReport: "",
						showLoader: false,
						
					});
				}
				if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
					deptTemp = menuRes.data.departments;
					menusTemp = menuRes.data.menus;
				} else {
				}
				
				

				let menusTemp2 = GetOrderedMasterTabMenuReports(menusTemp);

				

				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					menus: menusTemp2,
                    companyDownloadReport:companyDownloadReport,
                    observationsDownloadReport:this.newObservationsMap

				},()=>{
					this.handleShowFirstPage();
				});
			});
	};

    handleDownloadReport = (reportFormat) => {
		this.setState({
			showLoader: true,
			
		});
		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentId:this.props.match.params.id,
		};

		fetch(Constants.DownloadReport, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.newObservationsArr = [];
                    this.newObservationsMap = data.data.observations;
                    let rootHeadings = this.getRootLevelFunctions(this.newObservationsMap);

                    if(!isEmptyArray(rootHeadings)){                                                                                                                                                                        
                        this.parseObservations(rootHeadings[0].nodeId,0,[]);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                    }
					this.setState({
						showLoader: false,
						companyDownloadReport: data.data.company,
						observationsDownloadReport: this.newObservationsMap,
					},()=>{
                        this.showFirstPage();
                        if(reportFormat === Constants.REPORT_FORMAT_STANDARD){
                            this.downloadFormatOneReportContent();
                        }else if(reportFormat === Constants.REPORT_FORMAT_TABULAR){
                            this.downloadFormatTwoReportContent();
                        }
					});
				} else {
					this.setState({
						companyDownloadReport: "",
						observationsDownloadReport: "",
						showLoader: false,
						
					});
				}
			});

	};

	
	/*******************API CALLS END HERE*******************/

	render() {
		return (
			<Router>
				<main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />

						<div className="dashboard-right-bg position-relative">
							<div className="dashboard-wrapper">
								<Topbar />
								<MasterMenuTabs assignmentId={this.state.assignmentId} masterMenus={this.state.menus} themeSettings={themeSettings} />
								{this.state.showLoader && (
									<div
										className="loading-layout"
										style={{ background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)` }}
									>
										<div className="loading-text-layout">
											<p
												style={{
													color: `rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`,
												}}
											>
												Loading....
											</p>
										</div>
									</div>
								)}
								{
									// Need to check component did mount flag, other wise until api is called,
									// it shows no items found layout.
									this.state.componentDidMountFlag && (
										<div className="body-wrapper">
											<div className="search-addnew-bg">
												<div className="search-addnew">
													
												</div>
											</div>
                                            
												<ViewFirstPageReport 
													themeSettings={themeSettings}
													handleFirstPageClose={this.handleFirstPageClose}
													firstPageContent={this.state.firstPageContent}
													secondPageContent={this.state.secondPageContent}
													thirdPageContent={this.state.thirdPageContent}
													fourthPageContent={this.state.fourthPageContent}
													fifthPageContent={this.state.fifthPageContent}
													headerFooterContent={this.state.headerFooterContent}

												/>
											
                                        </div>
									)
								}
							</div>
						</div>
					</section>
				</main>
			</Router>
		);
	}
}

export default ViewReport;
