import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {DialogSaveButton, FilterCheckBox} from '../../../common/Global/globalStyles.style';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdownTable';
const YN = [
    {
        id:"Yes",
        label:"Yes",
    },
    {
        id:"No",
        label:"No",
    },
]
const YNNA = [
    {
        id:"Yes",
        label:"Yes",
    },
    {
        id:"No",
        label:"No",
    },
    {
        id:"NA",
        label:"NA",
    },
]
const placeholderDefaultValue = "Please Select";

export const SecondColumn = (props) => {
    return <>
        {
            props?.firstRowType === "TEXTFIELD" &&
            props.rowIndex === 0 &&
            <td
            contenteditable = {props.assignmentTaskAllowEdit === "Y"?"true":"false"}
            onBlur={props.handleExecTableField.bind(this,props.rowIndex)}
            >
                {props.getFirstRowItemValue(props.rowValue)}
            </td>
        }
        {
            props?.firstRowType === "YN" &&
            props.rowIndex === 0 &&
            <td>
                <RegularDropdown 
                    placeholder={isEmptyVariable(props.rowValue)?placeholderDefaultValue:props.rowValue}
                    dropdownArr={YN}
                    labelParam="label"
                    onDropDownItemClick={props.firstRowDropdownClick}
                    defaultPlaceholderDropDown={placeholderDefaultValue}
                    disabled = {props.assignmentTaskAllowEdit === "Y"?false:true}
                />
            </td>
        }
        {
            props?.firstRowType === "YNNA" &&
            props.rowIndex === 0 &&
            <td>
                <RegularDropdown 
                    placeholder={isEmptyVariable(props.rowValue)?placeholderDefaultValue:props.rowValue}
                    dropdownArr={YNNA}
                    labelParam="label"
                    onDropDownItemClick={props.firstRowDropdownClick}
                    defaultPlaceholderDropDown={placeholderDefaultValue}
                    disabled = {props.assignmentTaskAllowEdit === "Y"?false:true}
                />
            </td>
        }
        {
            props.rowIndex !== 0 &&
            <td
            contenteditable = {props.assignmentTaskAllowEdit === "Y" && !props.disableTextFields?"true":"false"}
            onBlur={props.handleExecTableField.bind(this,props.rowIndex)}
            style={{background:props.assignmentTaskAllowEdit === "Y" && !props.disableTextFields?"#fff":"rgb(250,250,250)"}}
            >
                {props.rowValue}
            </td>
        }
        
    </>
}

class SelectDropdownValues extends Component{
    
    constructor(props) {
        super(props);

        this.state = {
            fieldValue:{},
            value_NR:"N"
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) 
        && this.props.showExecTableDialog) {
            let fieldObj = JSON.parse(JSON.stringify(this.props.fieldObj));
            this.setState({
                fieldValue:fieldObj.fieldValue,
                value_NR:fieldObj.fieldValueNR === "Y"?"Y":"N"
            })
        }
    }

    handleExecTableField = (rowIndex, e) => {
		const value = e.currentTarget.textContent;
        let fieldValueTemp = this.state.fieldValue;

        fieldValueTemp.tableArray[rowIndex][1] = value;
        this.setState({
            fieldValue:fieldValueTemp
        })
	};

    firstRowDropdownClick = (selectedItem) => {
        let fieldValueTemp = this.state.fieldValue;

        //always first
        fieldValueTemp.tableArray[0][1] = selectedItem.id;
        this.setState({
            fieldValue:fieldValueTemp
        })
    }

    getDisableTextFieldFlag = () => {
        try{
            if((this.state.fieldValue.firstRowType === "YN" ||
            this.state.fieldValue.firstRowType === "YNNA") &&
            this.state.fieldValue.isFirstRowTypeDisable === "Y"){
                if(this.state.fieldValue.tableArray[0][1] === "Yes"){
                    return false;
                }else{
                    return true;
                }
                
            }else{
                return false;
            }
            
        }catch(e){
            return false;
        }
    }

    returnSelectedValues = () => {
        this.props.saveTableValues(this.state.fieldValue,this.state.value_NR)
    }

    handleCheck = (e) =>{
        let isChecked = e.target.checked;

        if(isChecked){
            let fieldValueTableTemp = [];
            if(!isEmptyArray(this.state.fieldValue?.tableArray)){
                fieldValueTableTemp = this.state.fieldValue.tableArray.map(item=>{
                    item[1] = "";
                    return item;
                })
            }
            this.setState({
                value_NR:isChecked?"Y":"N",
                fieldValue:{...this.state.fieldValue,tableArray:fieldValueTableTemp}
            })
        }else{
            this.setState({
                value_NR:isChecked?"Y":"N",
            })
        }
    }

    getFirstRowItemValue = (item) =>{
        let tempFirstRowList =["yes / no with disable","yes / no / na with disable","yes / no","yes / no / na","textfield"]
        if(!tempFirstRowList.includes(item.toLocaleLowerCase()) )
        {
            return item
        }
        else
        {
            return ""
        }
    }

    render(){
        return(
            <section>
                <Modal className="task-select-dept-dialog custom-dialog"
                    show={this.props.showExecTableDialog} backdrop="static">
                    <Modal.Header>
                        <h5>{this.props.fieldObj?.fieldName}{this.props.fieldObj?.isMandatorySelected === "Y"?"*":""}</h5>
                        <button 
                            onClick={this.props.handleExecTableDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        <div className="body-wrapper">
                            {
                                this.props.fieldObj?.isNotReqSelected === "Y" &&
                                <div className='exec-not-req-layout'>
                                    <FilterCheckBox themeSettings={this.props.themeSettings}
                                    style={{marginLeft:0}}>
                                        <input 
                                            type="checkbox"
                                            name="value_NR"
                                            id="value_NR"
                                            onChange={this.handleCheck}
                                            checked={this.state.value_NR === "Y"?true:false}
                                        />
                                        <label for="value_NR">Not Applicable</label>
                                    </FilterCheckBox>
                                </div>
                            }
                            <div style={{position:"relative"}}>
                                {
                                    !isEmptyArray(this.state.fieldValue?.tableArray) && 
                                    <div className="common-tab-bg">
                                        <div className="exec-table-layout" style={{width:"100%"}}>
                                            <table className="exec-table">
                                                <tbody>
                                                    {
                                                        this.state.fieldValue?.tableArray.map((rowItem,rowIndex)=>{
                                                            return <tr>
                                                                <th width="50%">{rowItem[0]}</th>
                                                                {/* <th width="50%">{this.getFirstRowItemValue(rowItem[0])}</th> */}
                                                                <SecondColumn 
                                                                    firstRowType = {this.state.fieldValue?.firstRowType}
                                                                    rowIndex = {rowIndex}
                                                                    rowValue = {rowItem[1]}
                                                                    assignmentTaskAllowEdit = {this.props.assignmentTaskAllowEdit}
                                                                    handleExecTableField = {this.handleExecTableField}
                                                                    firstRowDropdownClick = {this.firstRowDropdownClick}
                                                                    disableTextFields = {this.getDisableTextFieldFlag()}
                                                                    getFirstRowItemValue = {this.getFirstRowItemValue}
                                                                />
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                                <div className="overlay-nr" style={{display:this.state.value_NR === "Y"?"block":"none"}}>
                                </div>
                            </div>
                            {
                                isEmptyArray(this.state.fieldValue?.tableArray) && 
                                <div class="no-items-layout">
                                    <div class="no-items-card">
                                        <h6>{Constants.EMPTY_SEARCH_WARNING}</h6>
                                    </div>
                                </div>
                            }
                            {/* {
                                !isEmptyVariable(this.state.tableErr) &&
                                <span className="cm-error">{this.state.tableErr}</span>
                            } */}
                            <div className="modal-close-save m-t-15">
                                <div className="close-save-col">
                                    <button onClick={this.props.handleExecTableDialogClose} type="button" 
                                    className="modal-close m-b-0">Cancel</button>
                                    
                                    {
                                        this.props.assignmentTaskAllowEdit !== "N" &&
                                        !isEmptyArray(this.state.fieldValue?.tableArray) &&  
                                        <DialogSaveButton  
                                        themeSettings={this.props.themeSettings} 
                                        onClick={this.props.viewOnly === "Y"?this.props.handleExecTableDialogClose:this.returnSelectedValues} 
                                        type="button" 
                                        className="modal-save m-b-0">
                                            Save
                                        </DialogSaveButton>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
        )
    }

}

export default SelectDropdownValues;