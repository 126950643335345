import React, { Component, PureComponent } from "react";
import * as Constants from "../../../common/Global/constants";
import {
	getLocalStorageVariables,
	isEmptyVariable,
	isEmptyArray,
	SplitJoinString,
	getStringWithSpaceAfterComma,
    getThemeSettingsVariables
} from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import moment from "moment";
const userDetails = getLocalStorageVariables();
const activeStatus = "Active";
const inActiveStatus = "Inactive";
const requestChangeCheckText = "Request Change";
const themeSettings = getThemeSettingsVariables();

class ReportDetailInfo extends PureComponent {
    constructor(props) {
		super(props);
	}

    render () {
        const { label, text } = this.props;

        return (
            <div className="d-flex">
                <div style={{ width: "30%" }}>{label}</div>
                <div style={{ width: "4%" }}>:</div>
                <div style={{ flex: 1 }}>{text}</div>
            </div>
        )
    }
}

class ReportDetailInfoHeading extends PureComponent {
    constructor(props) {
		super(props);
	}

    render () {
        const { label } = this.props;

        return (
            <div className="report-details-tab theme-border-bottom nav nav-tabs pl-0 mb-2">
                <span className="nav-item nav-link active">{label}</span>
            </div>
        )
    }
}

class ReportDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			showLoader: false,
			datalist: [],
			users: [],
			teamMembers: [],
			teamViewers: [],
			assignmentReviewMatrix: [],

			reportReviewersMatrix: [],
			// totalCount:0,
			// currentPageNo: 1,
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showDetailsDialog) {
			this.setState(
				{
					componentDidMountFlag: false,
					showLoader: false,
					datalist: [],
					users: [],
					teamMembers: [],
					teamViewers: [],
					assignmentReviewMatrix: [],

					reportReviewersMatrix: [],
					// totalCount:0,
					// currentPageNo: 1,
					showDetailsDialog: false,
				},
				() => {
					this.getInitData();
				}
			);
		}
	}

	/*******************API CALLS*******************/

	getInitData = () => {
		this.setState({
			showLoader: true,
			showDetailsDialog: false,
		});
		
		let masterDataUrl = "";
		let url = "";
		if(this.props.isDraftOnward === true){
			masterDataUrl = Constants.GetDraftAssignTaskMasterData;
			url = Constants.GetDraftAssignedReportResources;
		}else{
			masterDataUrl = Constants.GetAssignTaskMasterData;
			url = Constants.GetAssignedReportResources;
		}
		
		Promise.all([
			fetch(masterDataUrl, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.selectedItem.assignmentId,
				}),
			}),
			fetch(url, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.selectedItem.assignmentId,
				}),
			}),
		])
			.then(([masterRes,reviewRes]) => {
				return Promise.all([masterRes.json(),reviewRes.json()]);
			})
			.then(([masterRes,reviewRes]) => {
				let datalistTemp = [];

				let users = [];
				let teamMembers = [];
				let assignmentReviewMatrix = [];
				let teamViewers = [];
				let reportReviewersMatrix = [];

				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					assignmentReviewMatrix = masterRes.data.assignmentReviewMatrix;
					let assignmentMemberMatrix = masterRes.data.assignmentMemberMatrix;
					let assignmentViewMatrix = masterRes.data.assignmentViewMatrix;

					users = [...assignmentMemberMatrix, ...assignmentReviewMatrix, ...assignmentViewMatrix];

					//Get team members name
					assignmentMemberMatrix.forEach((item) => {
						teamMembers.push(item.fullName);
					});

					//Get viewer's name
					assignmentViewMatrix.forEach((item) => {
						teamViewers.push(item.fullName);
					});
				} else {
				}
				if (reviewRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || reviewRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (reviewRes.responseCode === Constants.CODE_SUCCESS) {
					reportReviewersMatrix = reviewRes.data.assignedReportResources;

				} else {
				}

				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					datalist: datalistTemp,

					users: users,
					teamMembers: teamMembers,
					teamViewers: teamViewers,
					assignmentReviewMatrix: assignmentReviewMatrix,
					reportReviewersMatrix:reportReviewersMatrix,
				});
			});
	};

	/*******************API CALLS END HERE*******************/

	render() {
		return (
			<Modal className="custom-dialog" show={this.props.showDetailsDialog} onHide={this.props.handleDetailsDialogClose} dialogClassName="modal-report-details">
				<Modal.Header>
					<h5>
						{this.props.selectedItem.referenceNo}
					</h5>
					<button onClick={this.props.handleDetailsDialogClose} type="button" data-dismiss="modal">
						<span class="material-icons">close</span>
					</button>
				</Modal.Header>
				<hr />
                <div className="modal-body">
                    {
                        this.state.showLoader ? (
                            <div className="loading-layout" style={{ background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`, }}>
                                <div className="loading-text-layout">
                                    <p style={{ color: `rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})` }}>
                                        Loading....
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="details-table-layout">
                                <ReportDetailInfoHeading label="Details" />
                                <div className="row custom-row mb-2">
                                    <div className="col-md-12 custom-col">
                                        <div className="row">
                                            {/* <ReportDetailInfo
                                                    label="Month"
                                                    text={moment(this.props.selectedItem.shortName).format('MMM - YYYY')}
                                                /> */}
                                            {/* {this.props.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                <ReportDetailInfo
                                                    label="Unit"
                                                    text={getStringWithSpaceAfterComma(this.props.selectedItem.unitNameCityList)}
                                                />
                                            } */}
                                            <div className="col-lg-6 theme-border-right">
                                                {
                                                    this.props.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                    <ReportDetailInfo
                                                        label="Client"
                                                        text={this.props.selectedItem.companyName}
                                                    />
                                                }
                                                <ReportDetailInfo
                                                    label="Units"
                                                    text={getStringWithSpaceAfterComma(this.props.selectedItem.unitNameCityList)}
                                                />
                                                <ReportDetailInfo
                                                    label="Assignment Type"
                                                    text={`${this.props.selectedItem.assignmentTypeName} (${this.props.selectedItem.assignmentTypeShortName})`}
                                                />
                                                <ReportDetailInfo
                                                    label="Scope"
                                                    text={isEmptyVariable(this.props.selectedItem.plannedAssignmentArea)?
                                                        this.props.selectedItem.assignmentArea:
                                                        this.props.selectedItem.plannedAssignmentArea}
                                                />
                                                {
                                                    !isEmptyVariable(this.props.selectedItem.planDate) &&
                                                    <ReportDetailInfo
                                                        label="Plan Date"
                                                        text={moment(this.props.selectedItem.planDate).format("DD/MM/YYYY")}
                                                    />
                                                }
                                                {
                                                    isEmptyVariable(this.props.selectedItem.planDate) &&
                                                    <ReportDetailInfo
                                                        label="Create Date"
                                                        text={moment(this.props.selectedItem.createDate).format("DD/MM/YYYY")}
                                                    />
                                                }
                                            </div>
                                            <div className="col-lg-6">
                                                {(this.props.selectedItem.plannedAssignmentArea === "Process" || this.props.selectedItem.assignmentArea === "Process") && (
                                                    <ReportDetailInfo
                                                        label="Process"
                                                        text={SplitJoinString(this.props.selectedItem.processList, ",", ":-:", ", ")}
                                                    />
                                                )}
                                                {(this.props.selectedItem.plannedAssignmentArea === "Function" || this.props.selectedItem.assignmentArea === "Function") && (
                                                    <ReportDetailInfo
                                                        label="Function"
                                                        text={SplitJoinString(this.props.selectedItem.functionList, ",", ":-:", ", ")}
                                                    />
                                                )}
                                                {/* <ReportDetailInfo
                                                    label="Report Format"
                                                    text={this.props.selectedItem.reportFormat}
                                                />
                                                <ReportDetailInfo
                                                    label="Status"
                                                    text={this.props.selectedItem.status}
                                                /> */}
                                                {!isEmptyVariable(this.props.selectedItem.unplannedReason) && (
                                                    <ReportDetailInfo
                                                        label="Unplanned Reason"
                                                        text={this.props.selectedItem.unplannedReason}
                                                    />
                                                )}
                                                {!isEmptyVariable(this.props.selectedItem.unplannedReason) && 
                                                !isEmptyVariable(this.props.selectedItem.unplannedRemark) && (
                                                    <ReportDetailInfo
                                                        label="Unplanned Remarks"
                                                        text={this.props.selectedItem.unplannedRemark}
                                                    />
                                                )}
                                                {/* {this.props.selectedItem.status !== activeStatus && this.props.selectedItem.status !== inActiveStatus && (
                                                    <ReportDetailInfo
                                                        label="Review Status"
                                                        text={this.props.selectedItem.currentReviewStatus}
                                                    />
                                                )} */}
                                                {this.props.selectedItem.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW &&
                                                    this.props.selectedItem.currentReviewStatus.includes(requestChangeCheckText) && (
                                                        <ReportDetailInfo
                                                            label="Review Remarks"
                                                            text={this.props.selectedItem.lastReviewRemark}
                                                        />
                                                )}
                                            </div>
                                            
                                            {/* <ReportDetailInfo
                                                    label="Company Name"
                                                    text={this.props.selectedItem.companyName}
                                                /> */}

                                            {/* <ReportDetailInfo
                                                    label="Assignment Type Name"
                                                    text={this.props.selectedItem.assignmentTypeName}
                                                /> */}
                                            
                                            {/* <ReportDetailInfo
                                                    label="Start Date"
                                                    text={moment(this.props.selectedItem.startDate).format("DD/MM/YYYY")}
                                                />
                                                <ReportDetailInfo
                                                    label="End Date"
                                                    text={this.props.selectedItem.endDate}
                                                />
                                                <ReportDetailInfo
                                                    label="Report Date"
                                                    text={this.props.selectedItem.reportDate}
                                                /> */}
                                            
                                            {/* <ReportDetailInfo
                                                    label="Risk"
                                                    text={this.props.selectedItem.riskName}
                                                /> */}
                                            {/* <ReportDetailInfo
                                                    label="Value Addition"
                                                    text={SplitJoinString(this.props.selectedItem.valueAdditionList,",",":-:",", ",1)}
                                                /> */}
                                            {/* <ReportDetailInfo
                                                    label="Period From"
                                                    text={moment(this.props.selectedItem.periodFromDate).format("DD/MM/YYYY")}
                                                />
                                                <ReportDetailInfo
                                                    label="Period To"
                                                    text={moment(this.props.selectedItem.periodToDate).format("DD/MM/YYYY")}
                                                /> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 theme-border-right">
                                                <p className="schedule-assignment-heading mt-4">Timeline</p>
                                                <div className="row custom-row">
                                                    <div className="col-md-4 custom-col">
                                                        <p className="schedule-assignment-details-sub-heading">Start Date</p>
                                                        <p className="schedule-assignment-details-text">{moment(this.props.selectedItem.startDate).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                    <div className="col-md-4 custom-col">
                                                        <p className="schedule-assignment-details-sub-heading">Report Date</p>
                                                        <p className="schedule-assignment-details-text">{moment(this.props.selectedItem.endDate).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                    <div className="col-md-4 custom-col">
                                                        <p className="schedule-assignment-details-sub-heading">End Date</p>
                                                        <p className="schedule-assignment-details-text">{moment(this.props.selectedItem.reportDate).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <p className="schedule-assignment-heading mt-4">Sample Period</p>
                                                <div className="row custom-row">
                                                    <div className="col-md-6 custom-col">
                                                        <p className="schedule-assignment-details-sub-heading">From </p>
                                                        <p className="schedule-assignment-details-text">{moment(this.props.selectedItem.periodFromDate).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                    <div className="col-md-6 custom-col">
                                                        <p className="schedule-assignment-details-sub-heading">To</p>
                                                        <p className="schedule-assignment-details-text">{moment(this.props.selectedItem.periodToDate).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ReportDetailInfoHeading label="Execute Resources" />
                                <div className="row custom-row mb-3">
                                    <div className="col-md-12 custom-col">
                                        <div className="row">
                                            <div className="col-lg-6 theme-border-color">
                                                {!isEmptyArray(this.state.teamMembers) && (
                                                    <ReportDetailInfo
                                                        label="Team Members"
                                                        text={this.state.teamMembers.map((item, index) => {
                                                            return <span key={index}>{(index ? ", " : "") + item}</span>;
                                                        })}
                                                    />
                                                )}
                                                {/* Add Team Leader row if level 1 */}
                                                {!isEmptyArray(this.state.assignmentReviewMatrix) &&
                                                    this.state.assignmentReviewMatrix.map((item, index) => {
                                                    if (item.level === 1) {
                                                        return (
                                                            <ReportDetailInfo
                                                                key={index}
                                                                label="Team Leader"
                                                                text={item.fullName}
                                                            />
                                                        );
                                                    }
                                                })}
                                            </div>
                                            <div className="col-lg-6">
                                                {!isEmptyArray(this.state.assignmentReviewMatrix) &&
                                                    this.state.assignmentReviewMatrix.map((item, index) => {
                                                        if (item.level !== 1) {
                                                            return (
                                                                <ReportDetailInfo
                                                                    key={index}
                                                                    label={`Review Level${item.level}`}
                                                                    text={item.fullName}
                                                                />
                                                            );
                                                        }
                                                    })}
                                                {!isEmptyArray(this.state.teamViewers) && (
                                                    <ReportDetailInfo
                                                        label="Viewers"
                                                        text={this.state.teamViewers.map((item, index) => {
                                                            return <span key={index}>{(index? ", " : "") + item}</span>;
                                                        })}
                                                    />
                                                )}
                                            </div>
                                            {isEmptyArray(this.state.assignmentReviewMatrix) &&
                                                <div class="no-items-layout">
                                                    <div class="no-items-card p-0 ml-3">
                                                        <h6>No Resources Allocated</h6>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <ReportDetailInfoHeading label="Report Resources" />
                                <div className="row custom-row">
                                    <div className="col-md-12 custom-col">
                                        <div className="row">
                                            {!isEmptyVariable(this.state.reportReviewersMatrix) &&
                                                this.state.reportReviewersMatrix.map((item, index) => {
                                                    return (
                                                        <div className={"col-lg-6" + (index % 2 === 0 ? " theme-border-color" : "")} key={index}>
                                                            <ReportDetailInfo
                                                                label={`Review Level${item.level}`}
                                                                text={item.fullName}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                            {isEmptyArray(this.state.reportReviewersMatrix) &&
                                                <div class="no-items-layout">
                                                    <div class="no-items-card p-0 ml-3">
                                                        <h6>No Resources Allocated</h6>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
			</Modal>
		);
	}
}

export default ReportDetails;
