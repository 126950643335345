import styled from 'styled-components';

export const TopTabsLayout = styled.div`
    margin-top: 10px;
    justify-content: space-between;
    align-items:center;
    display: flex;

    .question-tabs{
        border-radius: 5px;
        width: 100%;
        margin-right: 10px;
        text-align: center;
    }
    
    .question-tabs ul{
        // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
        padding-left: 0px;
        margin: 0px;
        display: flex;
        align-items: center;
        border-radius: 4px;
    }
    .question-tabs ul li{
        /* border-right: 1px solid #ddd; */
        line-height: 1;
        background: #fff;
        margin:0 8px 0 0;
        list-style: none;
    }
    .question-tabs ul li:last-child{
        border: none;
    }
    .question-tabs ul li a{
        padding: 10px 18px;
        display: block;
        font-size: 13px;
        
        // box-shadow: inset 0px -2px 0px 0px rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });
        border-top: 2px solid transparent;
        letter-spacing: 0.4px;
        border-radius: 4px;
    }
    .question-tabs ul li.questiontab-active a {
        border:2px solid transparent; //New
        border-bottom: 0px solid transparent;
        box-shadow: inset 0px 0px 0px 0px rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });
        opacity: 1;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;

        background:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .question-tabs ul li a:hover {
        position: relative;
        opacity:1;
        font-weight: 100 !important;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border:2px solid transparent; //New
        border-bottom: 0px solid transparent;
        box-shadow: inset 0px 0px 0px 0px rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });;

        background:rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b }, ${ props => props.themeSettings.themeHoverColor.a });
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a }) !important;  
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .infocard-icon-col{
        background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });
        padding: 6px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        border-radius: 5px;
        display: flex;
        cursor: pointer;
        align-items: center;
    }
    .infocard-icon-col:hover{
        background: rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b }, ${ props => props.themeSettings.themeHoverColor.a });
    }
    .infocard-icon-col img{
        height:24px;
    }
`;

export const FilterDropDownLayout = styled.div`
    line-height:1;

    .filter-dropdown-layout{
        min-width:60px;
        position: relative;
    }
    .filter-dropdown-layout button{
        background:#fff;
        // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        height:32px;
        display:flex;
        align-items:center;
        border-radius:3px;
        padding:0px 10px;
        cursor: pointer;
        justify-content: space-between;
        border:none;
        width:auto;
    }
    .add-border button{
        border:1px solid #e5e5e5;
    }
    .filter-dropdown-layout button p{
        font-size:12.5px;
        line-height:1;
        margin:0;
    }
    .filter-dropdown-layout button span{
        color:#444;
    }
    .filter-dropdown-layout .dropdown-toggle::after{
        display: none;
    }
    .filter-dropdown-layout .btn-primary:hover{
        background:#fff;
        color:#444;
    }
    .filter-layout span{
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a }) !important;
    }
    .filter-layout a:hover span{
        color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a }) !important;
    }
    .filter-layout .btn-primary:focus{
        border-color:#00000000 !important;
        box-shadow: none !important;
    }
    .filter-dropdown-layout .btn-primary:focus{
        background:#fff;
        color:#444;
        border-color:#bbb;
        box-shadow: 0 0 0 0.1rem rgba(173, 173, 173, 0.5);
    }
    .filter-dropdown-layout .dropdown-menu{
        background:#fff;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        width: 100%;
        border:none;
        padding:0px;
        min-width:${props => props.dropdownWidth?props.dropdownWidth:"60px"};
        max-height: 250px;
        overflow: auto;
        width:auto;
    }
    
    .filter-dropdown-item{
        display: flex !important;
        align-items: center !important;
        padding: 5px 5px !important;
        cursor: pointer !important;
    }
    .filter-dropdown-item:hover{
        background-color:#fafafa;
    }
    .filter-dropdown-item:active{
        background-color:#fafafa !important;
    }
    .filter-dropdown-item:focus{
        outline:0;
    }
    .filter-dropdown-item p{
        font-size: 12.5px;
        margin:0;
        margin-left:3px;
        line-height: 1.1;
        white-space: pre-wrap;
    }
    .filter-dropdown-item span{
        opacity: 0;
        font-size: 18px;
        width: 20px;
        flex-shrink: 0;
        color:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });
    }
    .active-dd-item span{
        opacity: 1;
    }
`;
//Search Input Icon
export const SearchSpan = styled.span`
    background: #fff;
    padding: 7px 15px;
    line-height: 1;
    height: 32px;
    color:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });
    font-size: 20px;
    cursor: pointer;

    :hover{
        color:rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b }, ${ props => props.themeSettings.themeHoverColor.a });
    }
`;
//Add New Button with icon in Listing Page 
export const AddNewButtonLayout = styled.div`
    a{
        display: flex;
        align-items: center;
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
        background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });
        border-radius: 5px;
        height: 32px;
        padding:7px 12px;
    }
    a[disabled]{
        opacity:0.3;
    }
    a:hover{
        color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
        background: rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b }, ${ props => props.themeSettings.themeHoverColor.a });
    }

    a span{
        line-height: 1;
        font-size: 20px;
    }
    
    a p{
        margin-left: 6px;
        margin-bottom: 0px;
        font-weight: 600;
        font-size: 12px;
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
    }
    a:hover p{
        color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
    }
    .filter_btn:hover {
        cursor:auto;
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a }) !important;
        background: rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b }, ${ props => props.themeSettings.themeHoverColor.a }) !important;
    }
    .filter_btn .filter_alt:hover {
        cursor: pointer;
        color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
    }
    .filter_btn .filter_alt_off:hover {
        cursor: pointer;
        color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
    }
`;
//Table Dropdown
export const TableDropDownLayout = styled.div`

    .actico-col{
        padding-left: 0px;
        display: flex;
        justify-content: center;
        margin-bottom: 0px;
    }
    .actico-col li{
        list-style: none;
        position: relative;
    }
    .table-actions{
        color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });;
        line-height: 1;
        width:25px;
        height: 25px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .table-actions span{
        line-height: 1;
        font-size: 17px;
    }
    .table-actions:hover{
        border-radius: 100%;
        background: #f3f3f3;
        color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });;;
    }
    .tableact-dropdown{
        text-align: left;
        background: #fff;
        overflow: hidden;
        right: 0px;
        position: absolute;
        width: 140px;
        top: calc(100% + 0px);
        right: 0px;
        z-index: 9999;
        border-radius: 4px;
        box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.1);
    }
    
    .tableact-dropdown-col{
        text-align: left;
    }
    .tableact-dropdown-col ul{
        padding-left: 0px;
        margin:0px;
    }
    .tableact-dropdown-col ul li{
        list-style: none;
        text-align: left;
    }
    .tableact-dropdown-col ul li a{
        display: flex;
        align-items: center;
        padding: 6px 14px;
    }
    .tableact-dropdown-col ul li a:hover{
        background:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });
    }
    .tableact-dropdown-col ul li a:hover span{
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });;
    }
    .tableact-dropdown-col ul li a:hover p{
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });;
    }
    .tableact-dropdown-col ul li a span{
        color:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });
        line-height: 1;
        font-size:18px;
    }
    .tableact-dropdown-col ul li a p{
        margin-bottom: 0px;
        margin-left: 8px;
        font-size:13.5px;
        padding:0;
        border:none;
        height:auto;
    }
`;

//Pagination Styles
export const PaginationLayout = styled.div`
    .pagination{
        margin-bottom:10px;
    }
    .pagination .page-link{
        font-size:12.5px;
    }
    .pagination .page-item.active .page-link{
        background-color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });
        border-color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, ${ props => props.themeSettings.themeColor.a });
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
    }
    .page-link:hover{
        background-color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, 0.3);
        border-color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b }, 0.3);
    }
`;

//.common-save button
export const PageSaveButton = styled.button`
    color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
    border:none;
    border-radius: 5px;
    padding: 7px 25px;
    background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    font-size: 13px;
    
    :hover{
        color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
        background: rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b },  ${ props => props.themeSettings.themeHoverColor.a});
    }
`;

//.common-save button
export const ColorSaveButton = styled.button`
    color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
    border:none;
    border-radius: 5px;
    padding: 7px 25px;
    background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    font-size: 13px;
    display:flex;
    align-items:center;
    
    :hover{
        color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
        background: rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b },  ${ props => props.themeSettings.themeHoverColor.a});
    }

    span{
        font-size:16px;
        margin-left:5px;
    }
`;
export const LoadMoreButton = styled.button`
    color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
    border:none;
    border-radius: 5px;
    padding: 3px 25px;
    background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    font-size: 13px;
    
    :hover{
        color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
        background: rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b },  ${ props => props.themeSettings.themeHoverColor.a});
    }
`;
//..modal-save
export const DialogSaveButton = styled.button`
    color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
    border:none;
    border-radius: 5px;
    padding: 7px 25px;
    background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    font-size: 13px;
    
    :hover{
        color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
        background: rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b },  ${ props => props.themeSettings.themeHoverColor.a});
    }
`;
export const ResouceRemoveButton = styled.button`
    background: none;
    font-size: 11px;
    border: none;
    box-shadow: none;
    margin-left: 10px;
    color:#333;
    border: 1px solid rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    padding: 0px 10px;
    border-radius: 4px;
    
    :hover{
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
        background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    }
`;
export const ResouceAddButton = styled.button`
    background: none;
    font-size: 11px;
    border: none;
    box-shadow: none;
    margin-left: 0px;
    color:#333;
    color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
    background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    padding: 2px 10px;
    border-radius: 4px;
    
    :hover{
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
        background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    }
`;
//user role checkbox
export const UserRoleCheckBoxLayout = styled.div`
    line-height:1;

    input[type="checkbox"]{
        display: none;
    }
    label {
        position: relative;
        cursor: pointer;
        margin-bottom:0px;
    }
    label:before {
        content:'';
        -webkit-appearance: none;
        border-radius: 100%;
        padding: 9px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;

        background-color: #f5f5f5;
        border: 1px solid #eaeaea;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        top:-2px;
        
        margin-right: 5px;
    }
    input:checked + label:before{
        background-color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    }
    input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 6px;
        width: 8px;
        height: 8px;
        border-radius:100%;
        background:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
        // border-width: 0 2px 2px 0;
    }
`;

export const InfoCardSpan = styled.span`
    font-size: 40px;
    line-height: 1;
    color:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
`;

export const AlertDialogSpan = styled.span`
    font-size: 50px;
    color:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a}) !important;
    margin:0px auto 10px;
`;

export const DialogProceedButton = styled.a`
    background:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b },  ${ props => props.themeSettings.themeTextColor.a});
    border:none;
    border-radius: 5px;
    padding: 7px 25px;
    font-size: 13px;
    
    :hover{
        background:rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b },  ${ props => props.themeSettings.themeHoverColor.a});
        color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b },  ${ props => props.themeSettings.themeHoverTextColor.a});
    }
`;
//Used in filter
//Used in add new task
export const FilterCheckBox = styled.div`
    margin-left: 10px;
    line-height: 1;
    margin-top: 3px;
    
    input[type="checkbox"]{
        display: none;
    }
    label {
        position: relative;
        cursor: pointer;
        margin-bottom:0px;
        line-height: 1;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 12.5px;
        color: #444;
    }
    label:before {
        content:'';
        -webkit-appearance: none;
        border-radius: 4px;
        padding: 9px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: none;
        margin-right: 5px;
        top:-2px;
    }
    input:checked + label:before{
        background-color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    }
    input:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: 8px;
      width: 5px;
      height: 12px;
      border:solid rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });;;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
`;

export const TreeNodeDropdownIcon = styled.span`
    color:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a}) !important;
    flex-shrink: 0;
    margin-right: 5px;
    font-size: 20px;
    width:22px;
    padding: 1px;
`;

export const ExpandCollapseWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;

    .expand-collapse-layout{
        display: flex;
        align-items: center;
        border: none;
        margin-bottom: 10px;
    }
    .expand-collapse-layout span{
        font-size: 16px;
    }
    .expand-collapse-layout:hover span{
        color:rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b },  ${ props => props.themeSettings.themeHoverColor.a});
    }
    .expand-collapse-layout:hover p{
        color:rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b },  ${ props => props.themeSettings.themeHoverColor.a});
    }
    .expand-collapse-layout p{
        line-height: 1;
        margin: 0;
        margin-left: 2px;
        font-size: 11px;
        font-weight: bold;
    }
`;

//Used Add New task - select document
//Used in create manager checkbox
//Used in create checklist percentage scoring
//Used in create scheduled assignment
export const TreeNodeCheckBox = styled.div`
    margin-left:${props => props.ml?props.ml:"10px"} ;
    line-height: 1;
    margin-top: 3px;
    
    .font-bold{
        font-weight:bold;
        font-size: 14px;
        color: #000;
        line-height:1;
    }
    input[type="checkbox"]{
        display: none;
    }
    label {
        position: relative;
        cursor: pointer;
        margin-bottom:0px;
        line-height: 1;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 12.5px;
        color: #444;
    }
    label:before {
        content:'';
        -webkit-appearance: none;
        border-radius: 4px;
        padding: 9px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: none;
        margin-right: 5px;
        top:-2px;
    }
    input:disabled + label:before{
        background:#eee;
    }
    input:checked + label:before{
        background-color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    }
    input:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: 8px;
      width: 5px;
      height: 12px;
      border:solid rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });;;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
`;
export const TableCheckBox = styled.div`
    margin-left: 10px;
    line-height: 1;
    margin-top: 3px;
    
    input[type="checkbox"]{
        display: none;
    }
    label {
        position: relative;
        cursor: pointer;
        margin-bottom:0px;
        line-height: 1;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 12.5px;
        color: #444;
    }
    label:before {
        content:'';
        -webkit-appearance: none;
        border-radius: 4px;
        padding: 9px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: none;
        margin-right: 5px;
        top:-2px;
    }
    input:checked + label:before{
        background-color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    }
    input:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: 8px;
      width: 5px;
      height: 12px;
      border:solid rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });;;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
`;
export const TableRadioButton = styled.div`
    margin-left: ${props => props.ml?props.ml:"10px"};
    line-height: 1;
    margin-top: 3px;
    
    input[type="checkbox"]{
        display: none;
    }
    input[type="radio"]{
        display: none;
    }
    label {
        position: relative;
        cursor: pointer;
        margin-bottom:0px;
        line-height: 1;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 12.5px;
        color: #444;
    }
    label:before {
        content:'';
        -webkit-appearance: none;
        border-radius: 100%;
        padding: 9px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: none;
        margin-right: 5px;
        top:-2px;
    }
    input:checked + label:before{
        background-color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    }
    input:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius:100%;
      background:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });;;
    }
`;

export const UploadSampleLayout = styled.div`
    flex-shrink: 0;
    margin-left: 10px;
    
    input{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    label{
        color: rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
        cursor: pointer;
        margin: 0px;
        width: 100%;
        font-size: 14px;
        text-align:center;
        background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
        border-radius: 5px;
        height: 32px;
        padding: 9px 25px;
        line-height: 1;
    }
    label:hover{
        color: rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
        background:rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b }, ${ props => props.themeSettings.themeHoverColor.a });
    }
`;

export const TableIcon = styled.td`
    span{
        vertical-align: middle;
        color:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
        cursor: pointer;
    }
    :hover span{
        color:rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b }, ${ props => props.themeSettings.themeHoverColor.a });
    }
    a.tool {
        position: relative;
    }
    a.tool:hover::after {
        content: attr(data-title);
        background-color: rgba(${ props => props.themeSettings.sidebarIconColor.r }, ${ props => props.themeSettings.sidebarIconColor.g }, ${ props => props.themeSettings.sidebarIconColor.b }, ${ props => props.themeSettings.sidebarIconColor.a });
        color: #fff;
        padding: 7px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: bold;
        line-height: 14px;
        display: block;
        position: absolute;
        top: 110%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        z-index: 1;
    }
`;
export const ProfileIcon = styled.div`
    background: rgba(${ props => props.themeSettings.sidebarSelectedItemBg.r }, ${ props => props.themeSettings.sidebarSelectedItemBg.g }, ${ props => props.themeSettings.sidebarSelectedItemBg.b },  ${ props => props.themeSettings.sidebarSelectedItemBg.a});
    color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
    border-radius: 100%;
    
    h6{
        border-radius: 100%;
        font-size: 60px;
        color: #fff;
        text-align: center;
        padding: 0;
        margin: 0;
        line-height: 150px;
        border-bottom: 0px;
    }
`;
export const ChangeProfilePicLayout = styled.div`
    input{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    label{
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
        background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
        cursor: pointer;
        margin-top: 10px;
        width: 100%;
        font-size: 14px;
        text-align:center;
        border-radius: 5px;
        height: 32px;
        padding: 9px 25px;
        line-height: 1;
    }

    label:hover{
        color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
        background:rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b }, ${ props => props.themeSettings.themeHoverColor.a });
    }
`;

export const TaskTagButtonsLayout = styled.div`
    min-width:200px;
    margin-right: 10px;
    position:sticky;
    position: -webkit-sticky;
    top:0px;

    ul{
        padding-left: 0px;
    }
    ul li{
        list-style: none;
        margin-bottom: 10px;
    }
    ul li a{
        background: #fff;
        // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        border:1px solid #cbcbcb;
        padding: 8px 10px;
        font-size: 14px;
        text-align: center;
        border-radius: 6px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ul li a span{
        color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
        font-size:20px;
        margin-left:7px;
    }

    ul li a:hover{
        background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
    }

    ul li a:hover span{
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
    }

    .flex-center-layout a{
        flex-grow:1;
    }

    .flex-center-layout .enable-disable-span{
        flex-shrink:1;
        margin-left:5px;
        cursor:pointer;
        padding:2px;
        border-radius:100%;

        :hover{
            background:#eee;
        }
    }

    .flex-center-layout .enabled{
        color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    }

    .flex-center-layout .disabled{
        color:#aaa;
    }

    .span-tag-fixed{
        margin-left: 10px;
        font-size: 10px;
        background: #f5f5f5;
        padding: 3px 5px;
        border-radius: 3px;
        line-height:1;
    }

    .tagLayout{
        background: #fff;
        // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        padding: 10px 10px 6px;
        border-radius: 6px;
        text-align:center;
        border:1px solid #cbcbcb;

        p{
            font-size:12.5px;
            margin-bottom:0;
            line-height: 1.45;
        }

        button{
            font-size: 12.5px;
            background: none;
            border: none;
            margin-top: 4px;
            margin-bottom: 4px;
            padding: 3px 10px;
            border-radius: 4px;
            background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
            color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });

            :hover{
                color: rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
                background:rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b }, ${ props => props.themeSettings.themeHoverColor.a });
            }
            :focus{
                outline:0;
            }
        }
    }

    .tagHeadingLayout{
        display:flex;
        align-items:center;
        justify-content: center;
        margin-bottom:4px;

        p{
            font-size:13px;
            line-height:1;
            margin-bottom:0;
            font-weight:bold;
        }
        span{
            color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
            font-size:18px;
            margin-left:7px;
        }
    }

    .template-field-left-layout{
        display: flex;
        align-items: center;

        p{
            font-size: 14px;
            justify-content: center;
            padding: 8px 10px;
            width: 100%;
            border: 1px solid #cbcbcb;
            border-radius: 6px;

            :hover{
                cursor:pointer;
                color: rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
                background:rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b }, ${ props => props.themeSettings.themeHoverColor.a });

                .template-field-tick{
                    color: rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
                }
            }
        }

        .span-tag{
            margin-left: 10px;
            font-size: 10px;
            background: #f5f5f5;
            padding: 3px 5px;
            border-radius: 3px;
            line-height:1;
        }

        .template-field-tick{
            color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
            font-size: 20px;
            margin-left: 7px;
        }

        .table-actions{
            border:none;
            margin-left:7px;

            :hover span{
                color:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
            }
        }

        .table-actions span{
            margin-left:0;
        }

        .tableact-dropdown-col{
            li a{
                border:none;
            }
            li{
                margin:0;
            }
            li a p{
                text-align:left;

                :hover{
                    background:none;
                }
            }
            li a span{
                margin-left:0;
            }
        }
    }

`;

export const CustomTableRow = styled.div`
    display:flex;
    align-items:center;
    background:${props=>props.isDragging?"#d8d8d8":"#fff"};
    outline:0;
    border-bottom: 1px solid #dee2e6;

    ${props=>props.isDragDisabled &&`
        cursor:pointer;
    `}

    :last-child{
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    :last-child p:first-child{
        border-bottom-left-radius: 4px;
    }
    :last-child p:last-child{
        border-bottom-right-radius: 4px;
    }
    .table-custom-body{
        width:100%;
    }
    p{
        border-bottom: ${props=>props.isDragging?"0px solid #dee2e6":"0px solid #dee2e6"};
        border-top: 0px solid #dee2e6;
        padding: 0px 10px;
        vertical-align: middle;
        min-height: 32px;
        vertical-align: middle;
        font-size: 12.5px;
        color: #000000;
        position: relative;
        background-clip: padding-box;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }

    .table-cell{
        // border-bottom: 1px solid #dee2e6;
        display: flex;
        align-items: center;
        height: 32px;
    }

    .guidance-layout{
        display:flex;
        align-items:center;

        span{
            font-size:18px;
            color:#666;
            flex-shrink:0;
            cursor:pointer;
        }
        span:hover{
            color:#282828;
        }

        p{
            margin-bottom:0px;
            margin-right:10px;
            padding:0;
        }
    }

    .add-guidance-btn{
        color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });
        border:none;
        border-radius: 5px;
        padding: 0px 15px;
        background: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
        font-size: 11px;
        
        :hover{
            color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
            background: rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b },  ${ props => props.themeSettings.themeHoverColor.a});
        }
    }
`;

export const AlertInfo = styled.div`
    display:flex;
    align-items:center;
    padding:10px;
    // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    // background:#fff;
    border: 1px solid #ccc;
    margin-bottom:10px;
    border-radius:5px;
    position:relative;

    .info-icon{
        flex-shrink:0;
        font-size:30px;
        color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    }
    .close-icon{
        position:absolute;
        top: 2px;
        right: 2px;
        font-size: 20px;
        color: #777;
        cursor:pointer;
        :hover{
            color:#000;
        }
    }
    p{
        margin-bottom:0;
        font-size:13px;
        margin:0px 5px;
        line-height:1.4;
    }
`;

export const TableIconsLayout = styled.div`
    background:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  0.08);
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius:3px;

    span{
        font-size:19px;
        color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    }
`;


export const TableIconsLayoutTeam = styled.div`
    background:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  0.08);
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius:3px;

    span{
        font-size:13px;
        color: rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b },  ${ props => props.themeSettings.themeColor.a});
    }
`;


export const AssigmentDetailsIconLayout = styled.div`

    display:flex;
    align-items: center;

    span{
        font-size: 32px;
        cursor: pointer;
        color:rgba(${ props => props.themeSettings.themeColor.r }, ${ props => props.themeSettings.themeColor.g }, ${ props => props.themeSettings.themeColor.b });
        // color:rgba(${ props => props.themeSettings.themeTextColor.r }, ${ props => props.themeSettings.themeTextColor.g }, ${ props => props.themeSettings.themeTextColor.b }, ${ props => props.themeSettings.themeTextColor.a });

        :hover{
            // color:rgba(${ props => props.themeSettings.themeHoverTextColor.r }, ${ props => props.themeSettings.themeHoverTextColor.g }, ${ props => props.themeSettings.themeHoverTextColor.b }, ${ props => props.themeSettings.themeHoverTextColor.a });
            color: rgba(${ props => props.themeSettings.themeHoverColor.r }, ${ props => props.themeSettings.themeHoverColor.g }, ${ props => props.themeSettings.themeHoverColor.b },  ${ props => props.themeSettings.themeHoverColor.a});
        }
    }

`;