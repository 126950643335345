import styled from 'styled-components';

export const SidebarWrapper = styled.div`
    background: #fff;
    position: fixed;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    top: 0;
    width: 230px;
    min-width: 230px;
    max-width: 230px;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    z-index: 200;
    // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);

    background:rgba(${ props => props.sidebarBg.r }, ${ props => props.sidebarBg.g }, ${ props => props.sidebarBg.b }, ${ props => props.sidebarBg.a }) !important;
    // border-right:2px solid rgba(${ props => props.sidebarBorderRight.r }, ${ props => props.sidebarBorderRight.g }, ${ props => props.sidebarBorderRight.b }, ${ props => props.sidebarBorderRight.a });

    .vertical-menu-wrapper-type1 {
        position: absolute;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-right-width: 1px;
        overflow: hidden;
        z-index: 10;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    .vertical-menu-content-type1 {
        flex: 1 1 auto;
        overflow-x: hidden;
        position: relative;
        overflow-y: auto;
    }
    
    .vertical-menu-content-type1::-webkit-scrollbar {
        width: 3px;
    }
    
    /* Track */
    .vertical-menu-content-type1::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    /* Handle */
    .vertical-menu-content-type1::-webkit-scrollbar-thumb {
        background: #888;
    }
    
    /* Handle on hover */
    .vertical-menu-content-type1::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .verticalmenu-close{
        display: none;
    }

    .vertical-menu-col-type1 {
        padding: 15px;
    }

    .vertical-menu-profile-type1{
        text-align: center;
    }
    .vertical-menu-profile-type1 a{
        font-size: 14px;
    }
    
    .vertical-menu-profile-type1 a {
        font-size: 15px;
        display: block;
        color: #404040;
    }
    .vertical-menu-profile-type1 img {
        width: 85px;
        height: 85px;
        border-radius: 100%;
    }
    .vertical-menu-profile-type1 hr {
        margin-top: 15px;
        margin-bottom: 15px;
        border-top: 1px solid rgba(134, 134, 134, 0.3);
    }

    .client-profile-bg-type1{
        position:relative;
        margin-bottom:10px;
    }

    .account_circle{
        color:rgba(${ props => props.themeColor.r }, ${ props => props.themeColor.g }, ${ props => props.themeColor.b }, ${ props => props.themeColor.a });
        background: rgba(${ props => props.sidebarSelectedItemBg.r }, ${ props => props.sidebarSelectedItemBg.g }, ${ props => props.sidebarSelectedItemBg.b },  ${ props => props.sidebarSelectedItemBg.a});
        border-radius: 100%;
        width: 85px;
        height: 85px;
        display: inline-block;
    }
    .account_circle h6{
        font-size: 29px;
        color: #fff;
        text-align: center;
        padding: 0;
        margin: 0;
        line-height: 85px;
    }

    .logo-type1{
        border-radius: 0px !important;
        height: auto !important;
        // width: 80px !important;
        margin-bottom: 10px;
        max-height:40px;
        max-width:80px;
        object-fit:contain;
    }

    .client-profile-type1 {
        justify-content: center;
        align-items: center;
        display: flex;
        margin-bottom: 0px;
        margin-top: 5px;
        cursor: pointer;
    }
    .client-profile-type1 span {
        line-height: 1;
        margin-left: 5px;
    }
    .client-profile-type1 h6{
        padding:0;
        border:none;
        color:rgba(${ props => props.sidebarTextColor.r }, ${ props => props.sidebarTextColor.g }, ${ props => props.sidebarTextColor.b }, ${ props => props.sidebarTextColor.a });
    }
    .vertical-menu-list-type1 {
        margin-top: 10px;
    }
    .vertical-menu-list-type1 ul {
        padding-left: 0px;
    }
    .vertical-menu-list-type1 ul li {
        list-style: none;
        margin-bottom: 2px;
    }
    .vertical-menu-list-type1 a {
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        padding: 5px 8px;
    }
    .vertical-menu-list-type1 a p {
        font-weight: 500;
        margin-bottom: 0px;
        margin-left: 7px;
        font-size: 12.3px;
        line-height: 1;
        color:rgba(${ props => props.sidebarTextColor.r }, ${ props => props.sidebarTextColor.g }, ${ props => props.sidebarTextColor.b }, ${ props => props.sidebarTextColor.a });
    }
    .vertical-menu-list-type1 a span {
        line-height: 1;
        color:rgba(${ props => props.sidebarIconColor.r }, ${ props => props.sidebarIconColor.g }, ${ props => props.sidebarIconColor.b }, ${ props => props.sidebarIconColor.a });
    }
    .vertical-menu-list-type1 a:hover {
        background:rgba(${ props => props.sidebarHoverItemBg.r }, ${ props => props.sidebarHoverItemBg.g }, ${ props => props.sidebarHoverItemBg.b }, ${ props => props.sidebarHoverItemBg.a });
        border-radius: 5px;
    }
    .vertical-menu-list-type1 a:hover span{
        color:rgba(${ props => props.sidebarHoverItemIconColor.r }, ${ props => props.sidebarHoverItemIconColor.g }, ${ props => props.sidebarHoverItemIconColor.b }, ${ props => props.sidebarHoverItemIconColor.a });
    }
    .vertical-menu-list-type1 a:hover p{
        color:rgba(${ props => props.sidebarHoverItemTextColor.r }, ${ props => props.sidebarHoverItemTextColor.g }, ${ props => props.sidebarHoverItemTextColor.b }, ${ props => props.sidebarHoverItemTextColor.a });
    }
    
    .vertical-menu-list-type1 ul li.active a{
        background:rgba(${ props => props.sidebarSelectedItemBg.r }, ${ props => props.sidebarSelectedItemBg.g }, ${ props => props.sidebarSelectedItemBg.b }, ${ props => props.sidebarSelectedItemBg.a });
        border-radius: 5px;
    }
    .vertical-menu-list-type1 ul li.active a p{
        color:rgba(${ props => props.sidebarSelectedItemTextColor.r }, ${ props => props.sidebarSelectedItemTextColor.g }, ${ props => props.sidebarSelectedItemTextColor.b }, ${ props => props.sidebarSelectedItemTextColor.a });
    }

    .vertical-menu-list-type1 ul li.active a span{
        color:rgba(${ props => props.sidebarSelectedItemIconColor.r }, ${ props => props.sidebarSelectedItemIconColor.g }, ${ props => props.sidebarSelectedItemIconColor.b }, ${ props => props.sidebarSelectedItemIconColor.a });
    }
`;