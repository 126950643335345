import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { SearchSpan, AddNewButtonLayout, DialogSaveButton } from "../../../common/Global/globalStyles.style";
import Sidebar from "../../../common/SidebarCollapse";
import Topbar from "../../../common/Topbar";
import MasterMenuTabs from "../Common/topmenu";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import AlertDialog from "../../../common/AlertDialog";
import Pagination from "../../../common/Global/pagination";
import RegularDropdown from "../../../common/DropdownMenus/RegularDropdown";
import UpdateReviewStatusDialog from './ObservationListupdatereviewstatusdialog'
import ReviewHistoryDialog from '../../../common/reviewhistorydialog'
import {
	getLocalStorageVariables,
	getThemeSettingsVariables,
	isEmptyVariable,
	sortTable,
	isEmptyArray,
	GetOrderedMasterTabMenuExecute,
	removeHtmlTags,
} from "../../../common/Global/commonFunctions";
import { getDropdownActionArrayObservationList } from "../../../common/Global/reviewFunctions";
import { Modal } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";
import ObservationDetailsLayout from "./ObservationDetails/observationDetails";
import AssignmentHeading from "../Common/assignmentHeadingDetails";
import TopmenuReports from "../../../common/TopmenuReports";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();

const masterTypeDefaultPlaceholder = "Select Master Type";
const masterTypeFilterDefaultPlaceholder = "All Master Types";
const departmentDropdownDefaultValue = "Select Department";
const departmentFilterDropdownDefaultValue = "All";

const sortObservationDetail = "observationDetail";
const sortRisk = "o.riskLevel";
const sortStatus = "o.status";

const allStatus = "All";
const reviewedStatus = "Reviewed";
const inProgressStatus = "In Progress";
const completedStatus = "Completed";
const underReviewStatus = "Under Review";
const inActiveStatus = "Inactive";

class ObservationList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignmentId: this.props.match.params.id,

			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			statusPlaceholder: allStatus,
			resultSizePlaceholder: Constants.RESULT_SIZE ,
			sort: "",
			sortDir: "",
			assignmentStatus: "",
			assignmentDetails: "",
			company:{},

			masterTypeArr: [],
			masterTypeSelected: "All",
			masterFilterPlaceholder: masterTypeFilterDefaultPlaceholder,

			departmentArr: [],
			selectedDepartmentName: departmentDropdownDefaultValue,
			selectedDepartmentId: "",

			deptOnly: "N",

			showAlertDialog: false,
			operationType: "",
			deleteReactivateId: "",
			deleteReactivateMasterType: "",
			reviewLevel: "",
			alertDialogMessage: "",
			alertDialogHeading: "",
			proceedBtnLabel: "",

			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",

			status: "All",
			statusArr: [
				{
					label: allStatus,
					isSelected: true,
				},
				{
					label: inProgressStatus,
					isSelected: false,
				},
				{
					label: completedStatus,
					isSelected: false,
				},
				{
					label: underReviewStatus,
					isSelected: false,
				},
				{
					label: reviewedStatus,
					isSelected: false,
				},
				{
					label: inActiveStatus,
					isSelected: false,
				},
			],
			resultSizeArr: Constants.RESULT_SIZE_DD_ARR,

			name: "",
			shortName: "",
			description: "",
			masterType: "",
			formErrors: {},
			submitClickedAtleastOnce: false,

			showObsDetailsLayout:false,
			selectedObsIdForDetails:"",
		};
		this.masterTypeFilterArr = [];
	}

	componentDidMount() {
		this.getInitData();
	}

	handleChange = (e) => {
		const { name, value } = e.target;

		let FormErrorsTemp = this.state.formErrors;

		if (this.state.submitClickedAtleastOnce) {
			if (name === "shortName") {
				if (isEmptyVariable(value)) {
					FormErrorsTemp["shortName"] = "Please enter master short name";
				} else {
					FormErrorsTemp["shortName"] = "";
				}
			} else if (name === "name") {
				console.log("Inside name");
				if (isEmptyVariable(value)) {
					console.log("Inside name value:" + value);
					FormErrorsTemp["name"] = "Please enter master name";
				} else {
					FormErrorsTemp["name"] = "";
				}
			} else if (name === "description") {
				if (!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH) {
					FormErrorsTemp["description"] = "Description must be less then 500 characters";
				} else {
					FormErrorsTemp["description"] = "";
				}
			}
		}

		this.setState({
			[name]: value,
			formErrors: FormErrorsTemp,
		});
	};

	handleCheck = (e) => {
		let isChecked = e.target.checked;
		let name = e.target.name;

		if (isChecked) {
			this.setState(
				{
					deptOnly: "Y",
				},
				() => {
					this.getObservationList();
				}
			);
		} else {
			this.setState(
				{
					deptOnly: "N",
				},
				() => {
					this.getObservationList();
				}
			);
		}
	};

	handleChangeSearch = (e) => {
		const { name, value } = e.target;

		this.setState({
			[name]: value,
		});
	};

	onSearchIconClick = () => {
		this.setState(
			{
				currentPageNo: 1,
			},
			() => {
				this.getObservationList();
			}
		);
	};

	onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
			this.setState(
				{
					currentPageNo: 1,
				},
				() => {
					this.getObservationList();
				}
			);
		}
	};

	handleAlertDialogClose = () => {
		this.setState({
			showAlertDialog: false,
			alertDialogMessage: "",
		});
	};

	handleAlertDialogCloseInfo = () => {
		this.setState({
			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
		});
	};

	handleCreateDialogClose = () => {
		this.setState({
			showCreateDialog: false,
			isEdit: false,
			selectedId: "",
			name: "",
			shortName: "",
			description: "",
		});
	};

	//TODO
	showCreateDialog = () => {
		let selectedDepartmentId = this.state.selectedDepartmentId;
		let selectedDepartmentName = this.state.selectedDepartmentName;

		if (this.state.departmentArr.length > 1) {
			selectedDepartmentId = "";
			selectedDepartmentName = departmentDropdownDefaultValue;
		}

		this.setState({
			showCreateDialog: true,
			isEdit: false,
			selectedId: "",
			name: "",
			shortName: "",
			description: "",
			masterType: masterTypeDefaultPlaceholder,
			selectedDepartmentId: selectedDepartmentId,
			selectedDepartmentName: selectedDepartmentName,
			submitClickedAtleastOnce: false,
			formErrors: {},
		});
	};

	statusDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.statusArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				statusArr: tempArr,
				status: item.label,
				statusPlaceholder: placeholder,
			},
			() => {
				this.getObservationList();
			}
		);
	};

	resultSizeDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.resultSizeArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				resultSizeArr: tempArr,
				resultSize: item.label,
				resultSizePlaceholder: placeholder,
			},
			() => {
				this.getObservationList();
			}
		);
	};

	onTableDropDownItemClick = (item, dropdownItem) => {
		let observationHeadingForAlert = "";
		if(!isEmptyVariable(dropdownItem.observationHeading) &&
        !isEmptyVariable(removeHtmlTags(dropdownItem.observationHeading))){
			observationHeadingForAlert = removeHtmlTags(dropdownItem.observationHeading);
		}else{
			observationHeadingForAlert = removeHtmlTags(dropdownItem.observationDetail);
		}

		if (item.label === "Delete") {
			this.setState({
				operationType: item.label,
				alertDialogHeading: "Delete Observation",
				proceedBtnLabel: "Delete",
				deleteReactivateId: dropdownItem.observationId,
				showAlertDialog: true,
				// alertDialogMessage: "Are you sure you want to delete observation " + removeHtmlTags(dropdownItem.observationDetail) + "?",
				alertDialogMessage: "Are you sure you want to delete observation " + observationHeadingForAlert + "?",
			});
		} else if (item.label === "Edit") {
			// this.props.history.push(Constants.URL_EDITOBSERVATION + "/" + dropdownItem.observationId + "-" + this.state.assignmentId);
			this.props.history.push(Constants.URL_EDITOBSERVATION + "/" + dropdownItem.observationId + "-" + this.state.assignmentId);
		} else if (item.label === "Reactivate") {
			this.setState({
				operationType: item.label,
				alertDialogHeading: "Activate Observation",
				proceedBtnLabel: "Reactivate",
				deleteReactivateId: dropdownItem.observationId,
				showAlertDialog: true,
				// alertDialogMessage: "Are you sure you want to reactivate observation " + removeHtmlTags(dropdownItem.observationDetail) + "?",
				alertDialogMessage: "Are you sure you want to reactivate observation " + observationHeadingForAlert + "?",
			});
		} else if (item.label === "Mark Complete") {
			this.setState({
				operationType: item.label,
				alertDialogHeading: "Mark as Completed",
				proceedBtnLabel: "Okay",
				deleteReactivateId: dropdownItem.observationId,
				showAlertDialog: true,
				// alertDialogMessage: "Are you sure you want to mark " + removeHtmlTags(dropdownItem.observationDetail) + " as completed?",
				alertDialogMessage: isEmptyVariable(dropdownItem.taskList)?
									`Observation is not tagged to task. Do you want to mark ${observationHeadingForAlert} as completed?`:
								 	"Are you sure you want to mark " + observationHeadingForAlert + " as completed?",
			});
		} else if (item.label === "Submit For Review") {
			this.setState({
				operationType: item.label,
				alertDialogHeading: "Submit For Review",
				proceedBtnLabel: "Submit",
				deleteReactivateId: dropdownItem.observationId,
				showAlertDialog: true,
				// alertDialogMessage: "Are you sure you want to submit " + removeHtmlTags(dropdownItem.observationDetail) + " for review?",
				alertDialogMessage: "Are you sure you want to submit " + observationHeadingForAlert + " for review?",
			});
		}else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.observationId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.observationId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }
    };
    
    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag){
                this.getObservationList();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
        });
    }

	sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getObservationList();
			}
		);
	};
	handleAddObservationDialogShow = () => {
		this.props.history.push(Constants.URL_ADDTASK + "/" + this.state.assignmentId);
	};

	handleShowObsDetailsLayout = (observationId) => {
        
        this.setState({
            showObsDetailsLayout:true,
            selectedObsIdForDetails:observationId,
           
        })
    }
	handleHideObsDetailsLayout = () => {
        this.setState({
            showObsDetailsLayout:false,
            selectedObsIdForDetails:""
        })
    }
	/*******************API CALLS*******************/
	getInitData = () => {
		this.setState({
			showLoader: true,
		});

		Promise.all([
			fetch(Constants.GetObservations, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
					resultsize: this.state.resultSize,
					status: this.state.status,
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
			fetch(Constants.GetAssignmentsDetails, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
			}),
		])
			.then(([masterRes, menuRes, assignmentRes]) => {
				return Promise.all([masterRes.json(), menuRes.json(), assignmentRes.json()]);
			})
			.then(([masterRes, menuRes, assignmentRes]) => {
				let datalistTemp = [];
				let totalCountTemp = 0;
				let deptTemp = [];
				let menusTemp = {};
				let companyTemp = {};
				let assignmentStatus = "";
				let assignmentDetails = {};

				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					datalistTemp = masterRes.data.observationList;
					totalCountTemp = masterRes.data.count;
				} else {
				}

				if (assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
					assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
					assignmentDetails = assignmentRes.data.assignmentDetails;

				} else {
				}
				if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
					deptTemp = menuRes.data.departments;
					menusTemp = menuRes.data.menus;
					companyTemp = menuRes.data.company;
				} else {
					menusTemp = {};
				}
				let menusTemp2 = GetOrderedMasterTabMenuExecute(menusTemp,assignmentDetails.observationTemplateId);
				console.log(menusTemp2);

				let selectedDepartmentId = "";
				let selectedDepartmentName = departmentDropdownDefaultValue;
				if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
					selectedDepartmentId = deptTemp[0].departmentId;
					selectedDepartmentName = deptTemp[0].name;
				}

				// //Filter Dept array
				// let filterDeptArr = [];
				// let selectedFilterDepartmentId = "-100";
				// // let selectedFilterDepartmentName = "All";
				// if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
				// } else {
				// 	let obj = {
				// 		departmentId: "-100",
				// 		name: departmentFilterDropdownDefaultValue,
				// 		isSelected: true,
				// 	};
				// 	filterDeptArr = [...deptTemp];
				// 	filterDeptArr.splice(0, 0, obj);
				// }

				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					datalist: datalistTemp,
					totalCount: totalCountTemp,

					// filterDeptArr: filterDeptArr,
					// selectedFilterDepartmentId: selectedFilterDepartmentId,
					// selectedFilterDepartmentName: selectedFilterDepartmentName,
					menus: menusTemp2,
					company:companyTemp,
					assignmentStatus: assignmentStatus,
					assignmentDetails: assignmentDetails,

				});
			});
	};

	getObservationList = () => {
		this.setState({
			showLoader: true,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentId: this.state.assignmentId,
			pageNo: this.state.currentPageNo,
			search: this.state.searchkey,
			resultsize: this.state.resultSize,
			status: this.state.status,
			sort: this.state.sort,
			sortDir: this.state.sortDir,
		};

		fetch(Constants.GetObservations, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						datalist: data.data.observationList,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						totalCount: 0,
						componentDidMountFlag: true,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};

	onChangePage = (page) => {
		// update state with new page of items
		if (page !== this.state.currentPageNo) {
			this.setState({
				showLoader: true,
			});

			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId: this.state.assignmentId,
				pageNo: page,
				search: this.state.searchkey,
				resultsize: this.state.resultSize,
				status: this.state.status,
				sort: this.state.sort,
				sortDir: this.state.sortDir,
			};

			fetch(Constants.GetObservations, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState({
							showLoader: false,
							datalist: data.data.observationList,
							totalCount: data.data.count,
							apiSearchKey: this.state.searchkey,
							currentPageNo: page,
						});
					} else {
						this.setState({
							datalist: [],
							showLoader: false,
							apiSearchKey: this.state.searchkey,
						});
					}
				});
		}
	};
	handleRecordOperation = () => {
		let url = "";
		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			observationId: this.state.deleteReactivateId,
		};
		let callApi = true;

		if (this.state.operationType === "Delete") {
			url = Constants.DeleteObservation;
			// postParam.deleteReason = "test";
		} else if (this.state.operationType === "Reactivate") {
			url = Constants.ActivateObservation;
		} else if (this.state.operationType === "Mark Complete") {
			url = Constants.MarkObservationComplete;
            postParam.assignmentId = this.state.assignmentId;
		}else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitObservationForReview;
        }else if(this.state.operationType === "Review Remarks"){
		    callApi = false;
		    this.setState({
		        operationType:"",
		        alertDialogHeading:"",
		        proceedBtnLabel:"",
		        alertDialogMessage:"",
		        showAlertDialog:false,
		    })
		}

		if (callApi) {
			this.setState({
				showLoader: true,
			});

			fetch(url, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState(
							{
								deleteReactivateId: "",
								operationType: "",
								alertDialogHeading: "",
								proceedBtnLabel: "",
								alertDialogMessage: "",
								showAlertDialog: false,
							},
							() => {
								this.getObservationList();
							}
						);
					} else {
						this.setState({
							showLoader: false,
							deleteReactivateId: "",
							operationType: "",
							alertDialogHeading: "",
							proceedBtnLabel: "",
							alertDialogMessage: "",
							showAlertDialog: false,
							showAlertDialogInfo: true,
							alertDialogMessageInfo: data.responseMessage,
						});
					}
				});
		}
	};
	/*******************API CALLS END HERE*******************/

	render() {
		return (
			<Router>
				<main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />

						<div className="dashboard-right-bg-collpase">
							<div className="dashboard-wrapper">
								<Topbar />
								{
                                    this.state.componentDidMountFlag && (
                                    <div className="d-flex align-items-center">
                                        <div className="flex-fill">
                                            <MasterMenuTabs
                                                assignmentId={this.state.assignmentId}
                                                masterMenus={this.state.menus}
                                                themeSettings={themeSettings}
                                            />
                                        </div>
                                        <div>
                                            <TopmenuReports
                                                assignmentId={this.state.assignmentId}
                                                selectedResourceId = {this.state.selectedResourceId}
                                                userDetails ={userDetails}
                                                themeSettings={themeSettings}
                                                company={this.state.company}
												assignmentDetails={this.state.assignmentDetails}
                                            />
                                        </div>
                                    </div>
                                    )
                                }
								{this.state.showLoader && (
									<div
										className="loading-layout"
										style={{ background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)` }}
									>
										<div className="loading-text-layout">
											<p
												style={{
													color: `rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`,
												}}
											>
												Loading....
											</p>
										</div>
									</div>
								)}
								{
									// Need to check component did mount flag, other wise until api is called,
									// it shows no items found layout.
									this.state.componentDidMountFlag &&
									!this.state.showObsDetailsLayout &&
									!isEmptyVariable(this.state.assignmentStatus) &&
									(this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING ||
                                    this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_DRAFT ||
                                    this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_COMPLETED)
                                    && (
										<div className="body-wrapper">
											<div className="search-addnew-bg">
												<div className="search-addnew">
													<div className="search-filter-layout">
														<FilterDropDown
															placeholder={this.state.resultSizePlaceholder}
															dropdownArr={this.state.resultSizeArr}
															onDropDownItemClick={this.resultSizeDropdownClick}
															themeSettings={themeSettings}
															mleft={0}
														/>
														<div className="search-col m-l-10">
															<input
																name="searchkey"
																type="text"
																onChange={this.handleChangeSearch}
																placeholder="Search"
																onKeyPress={this.onEnterBtnPress}
																value={this.state.searchkey}
															/>
															<SearchSpan themeSettings={themeSettings} onClick={this.onSearchIconClick} className="material-icons">
																{" "}
																search{" "}
															</SearchSpan>
														</div>
														<FilterDropDown
															placeholder={this.state.statusPlaceholder}
															dropdownArr={this.state.statusArr}
															onDropDownItemClick={this.statusDropdownClick}
															themeSettings={themeSettings}
															dropdownWidth={"120px"}
														/>

														

														{/* {this.state.filterDeptArr.length > 0 && (
															<FilterDropDown
																placeholder={this.state.selectedFilterDepartmentName}
																dropdownArr={this.state.filterDeptArr}
																onDropDownItemClick={this.deptFilterDropdownClick}
																name={"name"}
																themeSettings={themeSettings}
																dropdownWidth={"120px"}
															/>
														)} */}
													</div>
                                                    {
                                                        this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING &&
                                                        <AddNewButtonLayout themeSettings={themeSettings}>
                                                            <a onClick={this.handleAddObservationDialogShow} href="javascript:void(0);">
                                                                <span className="material-icons">add_circle</span>
                                                                <p>Add New</p>
                                                            </a>
                                                        </AddNewButtonLayout>
                                                    }
													
												</div>
											</div>

											{!isEmptyArray(this.state.datalist) && (
												<div className="common-tab-bg">
													<div className="common-table" style={{ width: "100%" }}>
														<table className="table">
															<thead>
																<tr>
																	<th className="text-center" width={"5%"}>
																		No.
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortObservationDetail)}>
																		<div className="sort-header">
																			Observation
																			<span className={
																					this.state.sort === sortObservationDetail ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th width={"15%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortRisk)}>
																		<div className="sort-header">Risk
																			<span className={
																					this.state.sort === sortRisk ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th width={"15%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortStatus)}>
																		<div className="sort-header">Status
																			<span className={
																					this.state.sort === sortStatus ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="text-center" width={"8%"}>
																		<div className="sort-header"  width={"5%"}></div>
																	</th>
																</tr>
															</thead>
															<tbody>
																{!isEmptyArray(this.state.datalist) &&
																	this.state.datalist.map((item, idx) => {
																		let actionObj = getDropdownActionArrayObservationList(
																			item.allowEdit,
																			item.allowReview,
																			item.status,
																			item.currentReviewStatus,
																			item.taskList
																		);
																		return (
																			<tr>
																				<td className="text-center">
																					{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
																				</td>
																				<td onClick={this.handleShowObsDetailsLayout.bind(this, item.observationId)}>
																					{!isEmptyVariable(item.observationDetail)?<div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: item.observationDetail}} />:""}
																				</td>
																				<td>{item.riskLevel}</td>
																				<td>{item.status === underReviewStatus?item.editUser:item.status}</td>
																				<td className="text-center">
																					{actionObj.actionArr && actionObj.actionArr.length > 0 && (
																						<TableDropDown
																							actionArr={actionObj.actionArr}
																							onDropDownItemClick={this.onTableDropDownItemClick}
																							dropDownId={item}
																							dropdownWidth={actionObj.dropdownWidth}
																							themeSettings={themeSettings}
																						/>
																					)}
																				</td>
																			</tr>
																		);
																	})}
															</tbody>
														</table>
													</div>
												</div>
											)}
											{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && 
											this.state.assignmentStatus !== Constants.ASSIGNMENT_STATUS_ONGOING &&(
												<div class="no-items-layout">
													<div class="no-items-card">
														<h6>
															{Constants.NO_RECORDS_WARNING}
														</h6>
													</div>
												</div>
											)}
											<div className="pagination-layout">
												<div className="row custom-row">
													<div className="col-md-9 custom-col">
														{!isEmptyArray(this.state.datalist) && this.state.totalCount > this.state.datalist.length && (
															<Pagination
																totalLength={this.state.totalCount}
																items={this.state.datalist}
																onChangePage={this.onChangePage}
																pageSize={this.state.resultSize}
																currentPageNo={this.state.currentPageNo}
																initialPage={this.state.currentPageNo}
																themeSettings={themeSettings}
															/>
														)}
													</div>
													<div className="col-md-3 custom-col">
														<p>{"Total Records: " + this.state.totalCount}</p>
													</div>
												</div>
											</div>
										</div>
									)
								}
								{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
									<div class="no-items-layout m-t-10">
										<div class="no-items-card">
											<h6>
												{Constants.NO_RECORDS_WARNING}
											</h6>
										</div>
									</div>
								)}
								{
                                    this.state.showObsDetailsLayout &&
                                    <ObservationDetailsLayout
                                        observationId = {this.state.selectedObsIdForDetails}
										assignmentId = {this.state.assignmentId}
                                        handleHideObsDetailsLayout = {this.handleHideObsDetailsLayout}
                                        // subscriptionType={this.state.user.subscriptionType}
                                        handleShowObsDetailsLayout={this.handleShowObsDetailsLayout}
                                    />
                                }
							</div>
						</div>

						<AlertDialog
							showAlertDialog={this.state.showAlertDialog}
							handleAlertDialogClose={this.handleAlertDialogClose}
							type={
								this.state.operationType === "Delete"
									? Constants.ALERT_TYPE_WARNING
									: this.state.operationType === "Review Remarks"
									? Constants.ALERT_TYPE_ALERT
									: Constants.ALERT_TYPE_INFO
							}
							alertDialogHeading={this.state.alertDialogHeading}
							alertDialogMessage={this.state.alertDialogMessage}
							proceedBtnClick={this.handleRecordOperation}
							proceedBtnLabel={this.state.proceedBtnLabel}
							themeSettings={themeSettings}
						/>

						<AlertDialog
							showAlertDialog={this.state.showAlertDialogInfo}
							handleAlertDialogClose={this.handleAlertDialogCloseInfo}
							type={Constants.ALERT_TYPE_ALERT}
							alertDialogMessage={this.state.alertDialogMessageInfo}
							proceedBtnClick={this.handleAlertDialogCloseInfo}
							proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
							themeSettings={themeSettings}
						/>

						<UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterType = {""}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

						<ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {"Observation"}
                            masterDataId = {this.state.deleteReactivateId}
                        />

						<Modal className="custom-dialog" show={this.state.showCreateDialog} backdrop="static">
							<Modal.Header>
								<h5>{this.state.isEdit ? "Edit Observation" : "Add Observation"}</h5>
								<button onClick={this.handleCreateDialogClose} type="button" data-dismiss="modal">
									<span class="material-icons">close</span>
								</button>
							</Modal.Header>
							<hr />
							<div class="modal-body">
								<div class="addnew-modal-form">
									{!this.state.isEdit && (
										<div class="addnew-modal-form-group">
											<p>Master Type*</p>
											<RegularDropdown
												placeholder={this.state.masterType}
												dropdownArr={this.state.masterTypeArr}
												labelParam="masterTypeDesc"
												onDropDownItemClick={this.masterTypeDropdownClick}
												isFocusRequired={true}
												defaultPlaceholderDropDown={masterTypeDefaultPlaceholder}

											/>
											{!isEmptyVariable(this.state.formErrors["masterType"]) && <span class="cm-error">{this.state.formErrors["masterType"]}</span>}
										</div>
									)}
									{/* {!this.state.isEdit && !isEmptyArray(this.state.departmentArr) && this.state.departmentArr.length > 1 && (
										<div class="addnew-modal-form-group">
											<p>Department*</p>
											<RegularDropdown
												placeholder={this.state.selectedDepartmentName}
												dropdownArr={this.state.departmentArr}
												labelParam="name"
												onDropDownItemClick={this.deptDropdownClick}
											/>
											{!isEmptyVariable(this.state.formErrors["deparment"]) && <span class="cm-error">{this.state.formErrors["deparment"]}</span>}
										</div>
									)} */}
									<div class="addnew-modal-form-group">
										<p>Short Name*</p>
										<input type="text" placeholder="Enter Short Name" name="shortName" value={this.state.shortName} onChange={this.handleChange} />
										{!isEmptyVariable(this.state.formErrors["shortName"]) && <span class="cm-error">{this.state.formErrors["shortName"]}</span>}
									</div>
									<div class="addnew-modal-form-group">
										<p>Name*</p>
										<input type="text" placeholder="Enter Name" name="name" value={this.state.name} onChange={this.handleChange} spellCheck="true" />
										{!isEmptyVariable(this.state.formErrors["name"]) && <span class="cm-error">{this.state.formErrors["name"]}</span>}
									</div>
									<div class="addnew-modal-form-group">
										<p>Description</p>
										<textarea
											type="text"
											placeholder="Enter Description"
											name="description"
											value={this.state.description}
											onChange={this.handleChange}
										/>
										{!isEmptyVariable(this.state.formErrors["description"]) && <span class="cm-error">{this.state.formErrors["description"]}</span>}
										{/* {!isEmptyVariable(this.state.formErrors["apiErr"]) && <span class="cm-error">{this.state.formErrors["apiErr"]}</span>} */}
									</div>
								</div>
								<div class="modal-close-save">
									<div class="close-save-col">
										<button onClick={this.handleCreateDialogClose} type="button" class="modal-close">
											Cancel
										</button>
										<DialogSaveButton themeSettings={themeSettings} onClick={this.handleCreateMaster} type="button">
											Save
										</DialogSaveButton>
									</div>
								</div>
							</div>
						</Modal>
					</section>
				</main>
			</Router>
		);
	}
}

export default ObservationList;
